import MediaQuery, {useMediaQuery} from 'react-responsive';
import Filters from 'pages/partnerDirectory/searchPanel/Filters';
import React from 'react';
import styled from 'styled-components';
import {LeftBar, TasksIcon} from 'pages/partnerDirectory/searchPanel/StyledFiltersComponents';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {AppState} from 'utils/rootReducer';
import {maxMobileWidth} from 'assets/deviceWidth';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const SearchPanelMobile = ({onClickHandler}: {onClickHandler: () => void}) => {
  const {isFilterPanelExpanded} = useSelector((state: AppState) => state.partnerDirectoryState);
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  return (
    <MediaQuery maxDeviceWidth={maxMobileWidth}>
      <MobileIconContainer>
        <LeftBar onClick={onClickHandler} mobile={isMobile} className="spark-pad-1 spark-mar-t-.5 spark-mar-b-.5">
          <TasksIcon mobile={isMobile} className={isFilterPanelExpanded ? 'spark-icon--fill' : ''} />
          <span className="spark-pad-l-2">
            {isFilterPanelExpanded ? (
              <FormattedMessage id="partnerDirectoryPage.hideFilters" />
            ) : (
              <FormattedMessage id="partnerDirectoryPage.showFilters" />
            )}
          </span>
        </LeftBar>
        <Filters />
      </MobileIconContainer>
    </MediaQuery>
  );
};

const MobileIconContainer = styled.div.attrs(() => ({
  className: 'spark-bold spark-caps spark-flex',
}))`
  width: 100%;
  min-height: 5vh;
  flex-direction: column;
  background-color: ${colors.white};
  color: ${colors.grey700};

  font-size: 1.6rem;
`;

export default SearchPanelMobile;
