import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContainer} from '@scm/proposal/pages/proposalForm/ProposalTypePage';
import PageHeader from '@scm/proposal/components/content/PageHeader';
import {ProposalContext} from '@scm/proposal/pages/proposalForm/ProposalForm';
import RedAppName from '@scm/proposal/pages/proposalForm/steps/definitionPage/definitionComponents/RedAppName';
import {ButtonContainerInsurance} from '@scm/proposal/pages/proposalForm/steps/InsurancePage';
import {Button, Message} from '@sabre/spark-react-core';
import {ButtonSize, MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import PfKeysFileSource from './definitionComponents/PfKeysFileSource';
import {FileSource, formTitleId, redAppName} from '@scm/proposal/pages/proposalForm/ProposalValues';
import {fileSourceName, pfKeyFileName, pfKeysFilesNames, PfKeysValues} from '../provider/pfKeysValues';
import {SubmitHandler, useForm} from 'react-hook-form';
import {initialValues} from '../provider/pfKeysConstants';
import {openToast} from '@scm/components/messaging/openToast';
import {Loading} from '@scm/components';
import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import ProdTesters from '@scm/scribe-scripts/definitionPage/definitionComponents/prodTesters/ProdTesters';
import {
  testersTable,
  TestersValues,
} from '@scm/scribe-scripts/definitionPage/definitionComponents/prodTesters/TestersValues';
import {initialValues as testersInitialValues} from '@scm/scribe-scripts/definitionPage/definitionComponents/prodTesters/testersConstants';
import {editedTesterName} from '@scm/product-components/pages/storefrontData/interfaces/TesterDataValues';
import {productApiBaseLink} from '@scm/product-components';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {Configuration, CreatePfKeysRequest, ProductApi} from '../generated/product';
import {pfKeysMapper} from '../mapper/pfKeysMapper';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';
import AdditionalStatement from '@scm/proposal/components/content/AdditionalStatement';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const postPfKeys = async (request: CreatePfKeysRequest) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).createPfKeys(request);

export const PfKeysDefinitionPage = () => {
  const {
    watch: proposalWatch,
    setValue: proposalSetValue,
    handleCancel,
    isSmallSpinnerLoading,
  } = useContext(ProposalContext);
  const [isNameValid, setIsNameValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const {formatMessage} = useIntl();
  const {handleSubmit, watch, setValue} = useForm<PfKeysValues>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const testersMethods = useForm<TestersValues>({
    mode: 'onChange',
    defaultValues: testersInitialValues,
  });

  useEffect(() => {
    proposalSetValue(formTitleId, 'proposal.pfKeys.title');
  }, []);

  const submitDisabled = () => {
    const isLocalFileSourceChosen = watch(fileSourceName) === FileSource['Local'];
    const isFileUploaded = !watch(pfKeyFileName) || !watch(pfKeysFilesNames).length;
    return (
      isSmallSpinnerLoading ||
      !isNameValid ||
      !!testersMethods.watch(editedTesterName) ||
      !proposalWatch(redAppName) ||
      (isLocalFileSourceChosen ? isFileUploaded : false)
    );
  };

  const onSubmit: SubmitHandler<PfKeysValues> = async data => {
    gaEvent('Submitted PF Keys');

    try {
      setIsLoading(true);
      await postPfKeys(pfKeysMapper(data, proposalWatch(redAppName), testersMethods.watch()[testersTable]));
      setIsSuccess(true);
    } catch (e) {
      openToast(
        formatMessage({id: 'definition.scribeScripts.error.title'}),
        formatMessage({id: 'definition.pfKeys.error.message'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return isSuccess ? (
    <Message
      content={formatMessage({id: 'scribeScriptsAndPfKeys.success.content'})}
      role={MessageRole.STATUS}
      status={MessageStatus.SUCCESS}
      title={formatMessage({id: 'pfKeys.success.title'})}
      type={MessageType.PAGE}
      animate
    />
  ) : isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <>
      <div className="spark-panel spark-pad-l-2">
        <PageHeader id="definition.title" />
        <AdditionalStatement id="definition.additionalStatement" />
        <RedAppName
          firstParagraph="definition.redAppName.firstParagraph.scribeScriptPfKeys"
          secondParagraph="definition.redAppName.secondParagraph"
          setIsNameValid={setIsNameValid}
        />
        <PfKeysFileSource id="definition.pfKeys.fileSource.label" watch={watch} setValue={setValue} />
      </div>
      <ProposalContainer className="spark-panel spark-pad-l-2">
        <ProdTesters {...testersMethods} />
      </ProposalContainer>
      <ButtonContainerInsurance className="spark-mar-t-1 spark-flex">
        <Button secondary size={ButtonSize.SMALL} onClick={handleCancel} className="spark-mar-r-1">
          <FormattedMessage id="common.backButton" />
        </Button>
        <Button size={ButtonSize.SMALL} type="button" onClick={handleSubmit(onSubmit)} disabled={submitDisabled()}>
          <FormattedMessage id="common.submitButton" />
        </Button>
      </ButtonContainerInsurance>
    </>
  );
};
