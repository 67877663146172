import {Tester} from '../../productDetails/productTabs/testers/TesterTable';

export const isTesterAddingName = 'isTesterAdding';
export const editedTesterName = 'editedTester';
export const testersName = 'testers';
export const isCertTesterAddingName = 'isCertTesterAdding';
export const editedCertTesterName = 'editedCertTester';
export const certTestersName = 'certTesters';

export interface TesterDataValues {
  [isTesterAddingName]: boolean;
  [editedTesterName]?: Tester;
  [testersName]: Array<Tester>;
}

export interface CertTesterDataValues {
  [isCertTesterAddingName]: boolean;
  [editedCertTesterName]?: Tester;
  [certTestersName]: Array<Tester>;
}
