/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Authorization
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IsInternalProviderResponse,
  SellerResponse,
  ShortSellerResponse,
} from '../models';
import {
    IsInternalProviderResponseFromJSON,
    IsInternalProviderResponseToJSON,
    SellerResponseFromJSON,
    SellerResponseToJSON,
    ShortSellerResponseFromJSON,
    ShortSellerResponseToJSON,
} from '../models';

export interface GetSellerRequest {
    id: string;
}

export interface IsInternalProviderRequest {
    sellerId: string;
}

/**
 * 
 */
export class SellersApi extends runtime.BaseAPI {

    /**
     */
    async getAllSellersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShortSellerResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShortSellerResponseFromJSON));
    }

    /**
     */
    async getAllSellers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShortSellerResponse>> {
        const response = await this.getAllSellersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getMySellersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SellerResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/sellers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SellerResponseFromJSON));
    }

    /**
     */
    async getMySellers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SellerResponse>> {
        const response = await this.getMySellersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSellerRaw(requestParameters: GetSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SellerResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/sellers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SellerResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSeller(requestParameters: GetSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SellerResponse> {
        const response = await this.getSellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async isInternalProviderRaw(requestParameters: IsInternalProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsInternalProviderResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling isInternalProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/sabre/sellers/{sellerId}/internal`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsInternalProviderResponseFromJSON(jsonValue));
    }

    /**
     */
    async isInternalProvider(requestParameters: IsInternalProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsInternalProviderResponse> {
        const response = await this.isInternalProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
