/* tslint:disable */
/* eslint-disable */
/**
 * Roadmap API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RoadmapRequest,
  RoadmapVotesResponse,
  UserRoadmapResponse,
  VoteRequest,
} from '../models/index';
import {
    RoadmapRequestFromJSON,
    RoadmapRequestToJSON,
    RoadmapVotesResponseFromJSON,
    RoadmapVotesResponseToJSON,
    UserRoadmapResponseFromJSON,
    UserRoadmapResponseToJSON,
    VoteRequestFromJSON,
    VoteRequestToJSON,
} from '../models/index';

export interface CreateRoadmapRequest {
    roadmapRequest?: RoadmapRequest;
}

export interface DeleteRoadmapRequest {
    id: string;
}

export interface ReadVotesForRoadmapRequest {
    id: string;
}

export interface UpdateRoadmapRequest {
    id: string;
    roadmapRequest?: RoadmapRequest;
}

export interface VoteForRoadmapRequest {
    id: string;
    voteRequest?: VoteRequest;
}

/**
 * 
 */
export class RoadmapApi extends runtime.BaseAPI {

    /**
     * Create new roadmap
     */
    async createRoadmapRaw(requestParameters: CreateRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roadmap-features`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoadmapRequestToJSON(requestParameters.roadmapRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create new roadmap
     */
    async createRoadmap(requestParameters: CreateRoadmapRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createRoadmapRaw(requestParameters, initOverrides);
    }

    /**
     * Delete selected roadmap
     */
    async deleteRoadmapRaw(requestParameters: DeleteRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRoadmap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roadmap-features/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete selected roadmap
     */
    async deleteRoadmap(requestParameters: DeleteRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRoadmapRaw(requestParameters, initOverrides);
    }

    /**
     * Get all existing roadmap entries with details for users
     */
    async readRoadmapsForUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserRoadmapResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roadmap-features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRoadmapResponseFromJSON));
    }

    /**
     * Get all existing roadmap entries with details for users
     */
    async readRoadmapsForUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserRoadmapResponse>> {
        const response = await this.readRoadmapsForUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users vote for selected roadmap
     */
    async readVotesForRoadmapRaw(requestParameters: ReadVotesForRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RoadmapVotesResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling readVotesForRoadmap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roadmap-features/{id}/vote`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoadmapVotesResponseFromJSON));
    }

    /**
     * Get users vote for selected roadmap
     */
    async readVotesForRoadmap(requestParameters: ReadVotesForRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RoadmapVotesResponse>> {
        const response = await this.readVotesForRoadmapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update selected roadmap
     */
    async updateRoadmapRaw(requestParameters: UpdateRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRoadmap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roadmap-features/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RoadmapRequestToJSON(requestParameters.roadmapRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update selected roadmap
     */
    async updateRoadmap(requestParameters: UpdateRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRoadmapRaw(requestParameters, initOverrides);
    }

    /**
     * User vote for selected roadmap
     */
    async voteForRoadmapRaw(requestParameters: VoteForRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling voteForRoadmap.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roadmap-features/{id}/vote`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VoteRequestToJSON(requestParameters.voteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * User vote for selected roadmap
     */
    async voteForRoadmap(requestParameters: VoteForRoadmapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.voteForRoadmapRaw(requestParameters, initOverrides);
    }

}
