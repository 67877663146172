const notificationSettings = {
  'notificationSettings.title': 'Notification Settings',
  'notificationSettings.proposals.title': 'Red App Proposals',
  'notificationSettings.versions.title': 'Red App Versions',
  'notificationSettings.sendNotifications.message': 'Send me notifications when:',
  'notificationSettings.sendNotifications.offOn': 'OFF/ON',
  'notificationSettings.proposals.proposalSubmitted': 'I submit a new proposal (as a confirmation)',
  'notificationSettings.proposals.proposalAccepted': 'My proposal is accepted',
  'notificationSettings.proposals.proposalRejected': 'My proposal is rejected',
  'notificationSettings.versions.validationFailed': 'My version fails validation',
  'notificationSettings.versions.certificationFailed': 'My version fails certification',
  'notificationSettings.versions.readyForTestCert': 'My version is ready for testing on CERT',
  'notificationSettings.versions.readyForTestProd': 'My version is ready for testing on PROD',
  'notificationSettings.versions.cleanup': 'My version is scheduled for cleanup',

  'notificationSettings.save.title': 'Notification settings update.',
  'notificationSettings.save.success.description': 'Notification settings were updated successfully.',
  'notificationSettings.save.failure.description': 'Notification settings update failed. Please try again.',

  'manageNotifications.title': 'Manage Notifications',
  'manageNotifications.popup.notDeveloped': 'Not developed yet',
  'manageNotifications.details.form.subject': 'Subject',
  'manageNotifications.details.form.recipients': 'Recipients',
  'manageNotifications.details.form.notificationBody': 'Notification Body',
  'manageNotifications.details.form.recipientsBcc': 'Recipients BCC',
  'manageNotifications.details.modal.warning.content':
    "Links and variables may not work. Also overall styling may not be as expected. Notification will be wrapped in Sabre layout - 'Hello' and 'Best Regards, Sabre Central Marketplace' will be added automatically.",
  'manageNotifications.details.modal.warning.noDynamicParams.content':
    'This template does not have dynamic parameters that user can edit. <strong>Change variables at your OWN risk!</strong>',
  'manageNotifications.details.tooltip': 'Click, to insert <strong>{value}</strong> into focused input',
  'manageNotifications.details.modal.warning.noDynamicParams.title':
    'Dynamic Parameters for this template are not defined!',
  'manageNotifications.details.modal.warning.title': 'This is a preview of notification.',
  'manageNotification.details.modal.content.to': 'To:',
  'manageNotification.details.modal.content.bcc': 'BCC:',
  'manageNotification.toast.success.description': 'Notification changed.',
};

export default notificationSettings;
