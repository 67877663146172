import {
  TesterDataValues,
  CertTesterDataValues,
  certTestersName,
  editedCertTesterName,
  editedTesterName,
  isCertTesterAddingName,
  isTesterAddingName,
  testersName,
} from '../interfaces/TesterDataValues';

export const testerInitialValues: TesterDataValues = {
  [isTesterAddingName]: false,
  [editedTesterName]: undefined,
  [testersName]: [],
};

export const certTesterInitialValues: CertTesterDataValues = {
  [isCertTesterAddingName]: false,
  [editedCertTesterName]: undefined,
  [certTestersName]: [],
};
