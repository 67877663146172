import logEvent from './logEvent';
import EventCategories from './EventCategories';

const onJoinUsItemClicked = () => {
  logEvent({
    category: EventCategories.JoinUs,
    action: 'Join Us button clicked',
  });
};

export default onJoinUsItemClicked;
