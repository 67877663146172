import styled from 'styled-components';
import React from 'react';
import Headers, {convertToURL} from './headers/Headers';
import ProposalProvider from '../../../provider/ProposalProvider';
import AuditLogsProvider from '../../../provider/AuditLogsProvider';
import SectionsLoading from './loading/SectionsLoading';
import {useGetSections} from './sections';
import CertBetaTestersProvider from '../../../provider/CertBetaTestersProvider';
import ProdBetaTestersProvider from '../../../provider/ProdBetaTestersProvider';
import PricingProvider from '../../../provider/PricingProvider';

const SectionsContainer = () => {
  return (
    <ProposalProvider>
      <AuditLogsProvider>
        <CertBetaTestersProvider>
          <ProdBetaTestersProvider>
            <PricingProvider>
              <SectionsLoading>
                <div className="spark-mar-r-2 spark-mar-l-2 spark-mar-t-2">
                  <Tabs className="spark-panel spark-mar-b-2">
                    <div className="spark-panel__content">
                      <Headers />
                    </div>
                    <div className="spark-pad-l-2 spark-pad-r-2 spark-pad-b-1">
                      <Sections />
                    </div>
                  </Tabs>
                </div>
              </SectionsLoading>
            </PricingProvider>
          </ProdBetaTestersProvider>
        </CertBetaTestersProvider>
      </AuditLogsProvider>
    </ProposalProvider>
  );
};

const slash = '/';

const Sections = () => {
  const sections = useGetSections();
  const activeTabName = location.pathname.slice(location.pathname.lastIndexOf(slash) + 1);

  return sections.find(section => convertToURL(section.name) === convertToURL(activeTabName))?.content ?? null;
};

const Tabs = styled.div`
  min-height: 470px;
  position: relative;
  border: none;
`;

export default SectionsContainer;
