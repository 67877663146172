import React from 'react';
import {HOME_URL} from '../utils/urls';
import LogoutLink from './LogoutLink';

export const Logout = ({to = HOME_URL}: {to?: string}) => {
  const hasClasses = to === HOME_URL;

  return (
    <div className={hasClasses ? 'spark-header__sign-in' : ''}>
      <LogoutLink to={to} className={hasClasses ? 'spark-menu__list-link' : ''} />
    </div>
  );
};
