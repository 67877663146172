import {Configuration, PublishVersionRequest, VersionResponse, VersionsApi} from '../../../../generated/certification';
import {certificationApiBaseLink} from '../../../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {useIntl} from 'react-intl';
import {useState} from 'react';
import {Form} from '../cancel/Cancel';
import {ButtonSize, MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import {Button, Message} from '@sabre/spark-react-core';
import {ButtonContainer} from '@scm/product-components/pages/productDetails/actionForms/BundleTestingForm';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';
import useGetProductC from '../../../../hooks/useGetProductC';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

enum ButtonValues {
  No = 'No',
  Yes = 'Yes',
}
export const publishVersion = async (request: PublishVersionRequest) =>
  new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).publishVersion(request);

const Confirmation = ({
  handleClose,
  latestVersion,
  canPublishVersion,
  setLoadingProductDetails,
}: {
  handleClose: () => void;
  latestVersion: VersionResponse;
  canPublishVersion: boolean;
  setLoadingProductDetails: (value: boolean) => void;
}) => {
  const {provisioningTypeC} = useGetProductC();
  const {formatMessage} = useIntl();
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false);
  const isPatchMessageFormatting = () => (!latestVersion.isPatch ? '.new' : '.patch');

  const onSubmit = async (failure: () => void) => {
    if (!isSubmitPending && latestVersion.id) {
      setIsSubmitPending(true);
      publishVersion({id: latestVersion.id})
        .then(() => {
          openToast(
            createMessageString(formatMessage, `version.publishVersion${isPatchMessageFormatting()}`, true, false),
            createMessageString(formatMessage, `version.publishVersion${isPatchMessageFormatting()}`, false, false),
            ToastType.POSITIVE,
            'spark-icon-check'
          );
        })
        .catch(err => {
          failure();
          err.response.json().catch(() => {});
          openToast(
            createMessageString(formatMessage, 'version', true, true),
            createMessageString(formatMessage, 'version.publishVersion', false, true),
            ToastType.WARNING,
            'spark-icon-alert-triangle',
            true
          );
        })
        .finally(() => {
          handleClose();
          setLoadingProductDetails(true);
          setIsSubmitPending(false);
        });
      gaEvent(`Published a ${latestVersion.isPatch ? 'new version of the ' : ''}${provisioningTypeC} Red App`);
    }
  };

  return (
    <Form>
      <Message
        content={formatMessage({id: `confirmationForm.description${isPatchMessageFormatting()}`})}
        role={MessageRole.ALERT}
        status={MessageStatus.WARNING}
        title={formatMessage({id: 'confirmationForm.title'})}
        type={MessageType.PAGE}
        titleHeadingLevel={3}
      />
      <ButtonContainer className="spark-flex">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1" aria-label="Cancel">
          {ButtonValues.No}
        </Button>
        <Button
          progress
          type="button"
          size={ButtonSize.SMALL}
          onClick={async (_, success, failure) => {
            if (success && failure) {
              await onSubmit(failure);
            }
          }}
          disabled={!canPublishVersion}
          aria-label="Submit"
          tabIndex={0}
        >
          {ButtonValues.Yes}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export default Confirmation;
