export enum StatusGroup {
  ActionRequired = 'Action Required',
  Processing = 'Processing',
  Failed = 'Failed',
  Obsolete = 'Obsolete',
  Success = 'Success',
  Draft = 'Draft',
}

type StatusesList = Array<string>;
export type HandleOpenClose = () => void;

interface StatusesComponentsMap {
  statusesList: StatusesList;
  component: JSX.Element | null;
}

export type StatusesComponentsMapList = Array<StatusesComponentsMap>;

export const versionStatuses: Map<string, StatusGroup> = new Map([
  ['Bundle Submitted', StatusGroup.Processing],
  ['Validation Failed', StatusGroup.Failed],
  ['Bundle Validated', StatusGroup.Processing],
  ['Ready for Test (CERT)', StatusGroup.ActionRequired],
  ['CERT Tests Failed', StatusGroup.Failed],
  ['Cancelled', StatusGroup.Failed],
  ['CERT Tests Passed', StatusGroup.Processing],
  ['Rejected For PROD', StatusGroup.Failed],
  ['Approved For PROD', StatusGroup.Processing],
  ['Ready for Test (PROD)', StatusGroup.ActionRequired],
  ['PROD Tests Failed', StatusGroup.Failed],
  ['PROD Tests Passed', StatusGroup.ActionRequired],
  ['Published', StatusGroup.Success],
  ['Rollback in Progress', StatusGroup.Processing],
  ['Rolled Back', StatusGroup.Failed],
]);

const statuses: Map<string, StatusGroup> = new Map([
  ['Bundle Under Review', StatusGroup.Processing],
  ['Pending For CERT', StatusGroup.Processing],
  ['Certified', StatusGroup.Processing],
  ['Blocked', StatusGroup.Failed],
  ['Released', StatusGroup.Success],
  ['Submitted', StatusGroup.Processing],
  ['Rejected', StatusGroup.Failed],
  ['InReview', StatusGroup.Processing],
  ['Draft', StatusGroup.Draft],
  ['Approved', StatusGroup.ActionRequired],
  ['In Creation', StatusGroup.ActionRequired],
  ['Waiting for First Version', StatusGroup.ActionRequired],
  ['Obsolete', StatusGroup.Obsolete],
]);

export const onboardingStatuses: Map<string, StatusGroup> = new Map([
  ['Submitted', StatusGroup.Processing],
  ['Under Review', StatusGroup.Processing],
  ['Rejected', StatusGroup.Failed],
  ['Approved', StatusGroup.Success],
  ['Obsolete', StatusGroup.Obsolete],
]);

versionStatuses.forEach((value, key) => statuses.set(key, value));

export default statuses;
