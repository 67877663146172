import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const Optional = styled.span`
  &:after {
    color: ${colors.grey500};
    margin: 0 3px;
    font-weight: 300;
    content: '(Optional)';
  }
`;

export default Optional;
