import {Version} from '../../generated/certification';
import {ProductCompatibilities} from '../../pages/storefrontData/productDetailsUtils';
import {Bundle} from '../../pages/productDetails/productTabs/ProductTabsContainer';

export const bundleMapper = (version: Version): Bundle => ({
  id: version.id ?? '',
  version: version.name ?? '',
  submitted: version.createdAt ?? '',
  lastUpdated: version.lastUpdate ?? '',
  status: version.status ?? '',
  compatibility: setProductCompatibility(version.canBeUsedInSabreRedDesktop, version.canBeUsedInSabreRedWeb),
  webCompatibility: version.isWebApp ?? false,
  lastCleanup: '',
  scheduledRemoval: '',
  removalDate: '',
  simplifiedDescription: version.simplifiedDescription ?? '',
  releaseNotes: version.releaseNotes ?? '',
  isPatch: version.isPatch ?? false,
  cleanup: version.cleanup ?? '',
});

const setProductCompatibility = (forDesktop?: boolean, forWeb?: boolean) =>
  !!forDesktop && !!forWeb
    ? ProductCompatibilities['Desktop & Web']
    : forDesktop
    ? ProductCompatibilities['Desktop']
    : forWeb
    ? ProductCompatibilities['Web']
    : undefined;
