import {ProductInfo} from '@scm/product-details/page/content/basic-info/BasicInfo';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import HrefElement from '@scm/components/messaging/HrefElement';
import React from 'react';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {SUPPORT_EMAIL} from '../../utils/urls';

const Description = () => {
  const {formatMessage} = useIntl();

  return (
    <Message className="spark-pad-b-.5 spark-bold spark-pad-2 spark-mar-r-1">
      {formatMessage(
        {id: 'companyManagement.description.content'},
        {
          email: <HrefElement email={SUPPORT_EMAIL} />,
        }
      )}
    </Message>
  );
};

const Message = styled(ProductInfo)`
  font-weight: 400;
  padding: 1rem !important;
  margin-top: -1rem;
  @media (min-width: ${deviceWidths.md}px) {
    margin-top: -3rem;
  }
`;

export default Description;
