import React, {ReactElement, useContext, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContext} from '../../../../ProposalForm';
import {
  Confirmation,
  coreDataName,
  freeTextRemarksDescriptionName,
  freeTextRemarksName,
  numberOfTransactionName,
} from '../../ServiceAndSecurityValues';
import {Checkbox, RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {Controller} from 'react-hook-form';
import {Star} from '@scm/components';
import {TextArea} from '../../../definitionPage/definitionComponents/Description';
import {createNestedObjectName} from '../servicesComponents/Services';
import {serviceAndSecurityValuesName} from '../../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import Legend from '@scm/components/accessibility/Legend';

const CoreData = () => {
  const {formatMessage} = useIntl();
  const {control, watch, errors, setValue} = useContext(ProposalContext);
  const descriptionMaxLength = 250;
  const freeTextRemarksId = 'freeTextRemarks';
  const coreDataValue = watch(createNestedObjectName(coreDataName));

  useEffect(() => {
    if (!coreDataValue) {
      setValue(createNestedObjectName(freeTextRemarksName), '', {shouldValidate: true});
    }
  }, [coreDataValue, setValue]);

  return (
    <>
      <Controller
        name={createNestedObjectName(coreDataName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (event.target.checked) {
                setValue(createNestedObjectName(freeTextRemarksDescriptionName), '', {shouldValidate: true});
                setValue(createNestedObjectName(numberOfTransactionName), '', {shouldValidate: true});
                setValue(createNestedObjectName(freeTextRemarksName), Confirmation.No, {shouldValidate: true});
              } else {
                setValue(createNestedObjectName(freeTextRemarksName), '', {shouldValidate: true});
              }
            }}
            checked={value}
            label={formatMessage({id: 'serviceAndSecurity.coreData.label'})}
            className="spark-mar-b-0"
          />
        )}
      />
      <p className="spark-mar-l-2 spark-pad-l-1 spark-small">
        <FormattedMessage id="serviceAndSecurity.coreData.additionalText" />
      </p>
      {coreDataValue && (
        <div className="spark-pad-l-2">
          <p className="spark-mar-b-1 spark-mar-r-2 spark-mar-t-0" id={freeTextRemarksId}>
            <FormattedMessage id="serviceAndSecurity.freeTextRemarks.label" />
            <Star />
          </p>
          <Controller
            name={createNestedObjectName(freeTextRemarksName)}
            control={control}
            render={({field: {onChange, name, value}}): ReactElement => (
              <RadioButtonGroup
                name={name}
                value={value}
                onChange={event => {
                  onChange(event);
                  if (event.target.value === Confirmation.No) {
                    setValue(createNestedObjectName(freeTextRemarksDescriptionName), '', {shouldValidate: true});
                    setValue(createNestedObjectName(numberOfTransactionName), '', {shouldValidate: true});
                  }
                }}
                aria-labelledby={freeTextRemarksId}
                className="spark-mar-b-1"
              >
                <Legend text={formatMessage({id: 'serviceAndSecurity.freeTextRemarks.label'})} />
                {Object.values(Confirmation).map(value => (
                  <RadioButton
                    key={value}
                    label={value}
                    value={value}
                    aria-label={formatMessage({id: 'serviceAndSecurity.confirmationButtons.label'}, {value})}
                  />
                ))}
              </RadioButtonGroup>
            )}
          />
          {watch(createNestedObjectName(freeTextRemarksName)) === Confirmation.Yes && (
            <div className="spark-pad-l-2">
              <Controller
                name={createNestedObjectName(freeTextRemarksDescriptionName)}
                control={control}
                render={({field}): ReactElement => (
                  <TextArea
                    {...field}
                    label={formatMessage(
                      {id: 'serviceAndSecurity.freeTextRemarksDescription.label'},
                      {star: word => <span className="spark-danger">{word}</span>}
                    )}
                    characterCount
                    multiLine
                    maxLength={descriptionMaxLength}
                    className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                    status={
                      errors[serviceAndSecurityValuesName]?.[freeTextRemarksDescriptionName]
                        ? MessageStatus.ERROR
                        : undefined
                    }
                    statusMessage={
                      errors[serviceAndSecurityValuesName]?.[freeTextRemarksDescriptionName] &&
                      errors[serviceAndSecurityValuesName]?.[freeTextRemarksDescriptionName]?.message
                    }
                  />
                )}
              />
              <Controller
                name={createNestedObjectName(numberOfTransactionName)}
                control={control}
                render={({field}): ReactElement => (
                  <TextArea
                    {...field}
                    label={formatMessage(
                      {id: 'serviceAndSecurity.numberOfTransaction.label'},
                      {star: word => <span className="spark-danger">{word}</span>}
                    )}
                    characterCount
                    multiLine
                    maxLength={descriptionMaxLength}
                    className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                    status={
                      errors[serviceAndSecurityValuesName]?.[numberOfTransactionName] ? MessageStatus.ERROR : undefined
                    }
                    statusMessage={
                      errors[serviceAndSecurityValuesName]?.[numberOfTransactionName] &&
                      errors[serviceAndSecurityValuesName]?.[numberOfTransactionName]?.message
                    }
                  />
                )}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CoreData;
