import {
  additionalInfoName,
  perPccepr,
  perPcceprType,
  perTransaction,
  perTransactionType,
  PricingValues,
  rowsListPccepr,
  rowsListTransaction,
} from './PricingValues';

export const initialValues: PricingValues = {
  [perPccepr]: false,
  [perTransaction]: false,
  [rowsListPccepr]: [
    {from: '1', to: '', price: '', disabledToValue: false},
    {from: '', to: 'Unlimited', price: '', disabledToValue: true},
  ],
  [rowsListTransaction]: [
    {from: '1', to: '', price: '', disabledToValue: false},
    {from: '', to: 'Unlimited', price: '', disabledToValue: true},
  ],
  [additionalInfoName]: '',
  [perPcceprType]: '',
  [perTransactionType]: '',
};
