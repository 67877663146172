import styled from 'styled-components';
import {Button, Checkbox, RadioButton, RadioButtonGroup, SelectInput, TextInput} from '@sabre/spark-react-core';
import {ButtonSize, MessageStatus} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {createContext, ReactElement, useContext, useMemo} from 'react';
import {
  additionalInfoName,
  perPccepr,
  perPcceprType,
  perTransaction,
  perTransactionType,
  pricingLevelName,
  PricingValues,
  RowData,
  rowsListPccepr,
  rowsListTransaction,
  selectedFeeRecurrenceName,
} from '@scm/product-components/pages/productDetails/productTabs/tabs/pricing/PricingValues';
import {Control, Controller, useForm, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {initialValues} from '@scm/product-components/pages/productDetails/productTabs/tabs/pricing/pricingConstants';
import {yupResolver} from '@hookform/resolvers/yup';
import {pricingSchema} from '@scm/product-components/schemas/pricingSchema';
import useGetSubmitHandler from './useGetSubmitHandler';
import {Star} from '@scm/components';
import {FeeTypes} from '@scm/product-components/pages/productDetails/productTabs/tabs/pricing/Pricing';
import {InternalProviderContext} from '../../../provider/InternalProvider';
import PriceDefinition from '@scm/product-components/pages/productDetails/productTabs/tabs/pricing/PriceDefinition';

export enum PricingLevel {
  pcc = 'PCC level',
  epr = 'EPR level',
}

type PricingControl = Control<PricingValues>;
type PricingWatch = UseFormWatch<PricingValues>;
type PricingSetValue = UseFormSetValue<PricingValues>;

interface PricingFormDefinitionValues {
  control: PricingControl;
  watch: PricingWatch;
  setValue: PricingSetValue;
}

export const PricingFormDefinitionContext = createContext<PricingFormDefinitionValues>(
  {} as unknown as PricingFormDefinitionValues
);

const PricingForm = () => {
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<PricingValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(pricingSchema),
  });
  const pricingLevel = watch(pricingLevelName);
  const starMarginTop = -6;
  const additionalInfoMaxLength = 250;

  const isValid = () => {
    const isAdditionalInfoPresentIfRequired = (isPerTransactionType: boolean, type?: string) =>
      !(isPerTransactionType || type?.includes('NEGOTIABLE')) ||
      (watch(additionalInfoName) && (watch(additionalInfoName)?.length || 0) >= 10);

    const isPriceRangesValid = (priceRanges: Array<RowData>, type?: string) => {
      if (type?.includes('FIXED PRICE')) {
        return priceRanges[0].price && !Number.isNaN(Number(priceRanges[0].price));
      } else if (type?.includes('PRICE RANGES')) {
        const areValuesPresent =
          priceRanges
            .map(range => {
              if (range.to.includes('Unlimited')) {
                return range.price !== '' && !Number.isNaN(Number(range.price));
              } else {
                return (
                  range.from !== '' &&
                  range.to !== '' &&
                  range.price !== '' &&
                  !Number.isNaN(Number(range.price)) &&
                  !Number.isNaN(Number(range.to))
                );
              }
            })
            .filter(valid => !valid).length === 0;

        const arePricesValid = (values: Array<string>) =>
          values
            .map(value => Number(value))
            .reduce((previous, current) => {
              if (previous <= current) {
                return 0;
              }
              return current;
            });

        const areFromToValuesValid = (ranges: Array<RowData>) =>
          ranges
            .map(
              range =>
                range.to.includes('Unlimited') ||
                (!Number.isNaN(Number(range.to)) && Number(range.from) < Number(range.to))
            )
            .filter(valid => !valid).length === 0;

        return (
          areValuesPresent && arePricesValid(priceRanges.map(range => range.price)) && areFromToValuesValid(priceRanges)
        );
      } else {
        return true;
      }
    };

    return (
      (watch(perPccepr) || watch(perTransaction)) && //disable button if none of pricing types is picked
      (!watch(perPccepr) || //if pricing per pcc epr is picked validate below fields
        (watch(perPcceprType) &&
          isAdditionalInfoPresentIfRequired(false, watch(perPcceprType)) &&
          pricingLevel &&
          watch(rowsListPccepr) &&
          isPriceRangesValid(watch(rowsListPccepr), watch(perPcceprType)) &&
          watch(selectedFeeRecurrenceName))) &&
      (!watch(perTransaction) || //if pricing per transaction is picked validate below fields
        (watch(perTransactionType) &&
          isAdditionalInfoPresentIfRequired(true, watch(perTransactionType)) &&
          isPriceRangesValid(watch(rowsListTransaction), watch(perTransactionType))))
    );
  };
  const submitHandler = useGetSubmitHandler(getValues);
  const {formatMessage} = useIntl();

  const changeValueOptions = useMemo(() => ({shouldValidate: true, shouldTouch: true, shouldDirty: true}), []);
  const {isInternalProvider} = useContext(InternalProviderContext);

  const getFeeTypesForInternalOrExternalApps = (isInternalProvider: boolean) =>
    !isInternalProvider
      ? Object.values(FeeTypes).map(value => ({value, label: value}))
      : [
          {value: FeeTypes['Monthly Fee'], label: FeeTypes['Monthly Fee']},
          {value: FeeTypes['One-Time Fee'], label: FeeTypes['One-Time Fee']},
        ];

  const getFeeTypeOptions = () => {
    const pccLevelFeeOptions = getFeeTypesForInternalOrExternalApps(!!isInternalProvider.isInternalProvider);
    return [
      {value: '', label: ''},
      ...(pricingLevel === PricingLevel['pcc']
        ? pccLevelFeeOptions
        : [{value: FeeTypes['Monthly Fee'], label: FeeTypes['Monthly Fee']}]),
    ];
  };

  const additionalInfoLabel = (
    <>
      {formatMessage({
        id: 'tabs.price.additionalInfo.textInput.label',
      })}
      {(watch(perTransaction) || watch(perPcceprType)?.includes('NEGOTIABLE')) && <Star marginTop={starMarginTop} />}
    </>
  ) as unknown as string;

  return (
    <PricingFormDefinitionContext.Provider value={{watch, control, setValue}}>
      <PricingContainer>
        <PricingButtons>
          <Button type="button" size={ButtonSize.SMALL} onClick={submitHandler} disabled={!isValid()}>
            <FormattedMessage id="tabs.pricing.submitButton" />
          </Button>
        </PricingButtons>
        <p className="spark-mar-l-2 spark-bold">
          <FormattedMessage id="tabs.pricing.perUser.title" />
        </p>
        <Checkbox
          className="spark-mar-t-1 spark-mar-l-2"
          label={formatMessage({
            id: 'tabs.pricing.perUser.description',
          })}
          onChange={() => {
            setValue(perPccepr, !watch(perPccepr), changeValueOptions);
            if (watch(perPcceprType) !== undefined) {
              setValue(perPcceprType, undefined, changeValueOptions);
            }
            pricingLevel
              ? setValue(pricingLevelName, undefined, changeValueOptions)
              : setValue(pricingLevelName, PricingLevel.pcc, changeValueOptions);
          }}
        />
        <Controller
          name={pricingLevelName}
          control={control}
          render={({field: {onChange, name, value}}): ReactElement => (
            <RadioButtonGroup
              name={name}
              value={value ?? ''}
              onChange={onChange}
              className="row spark-mar-l-2 spark-mar-t-2"
            >
              <Legend>
                <FormattedMessage id="tabs.pricing.level.label" />
              </Legend>
              {watch(perPccepr) && <Star hasColon marginTop={starMarginTop} />}
              {Object.values(PricingLevel).map(type => (
                <RadioButton
                  className="row spark-mar-l-2 col-xs-2"
                  key={type}
                  label={type}
                  value={type}
                  onClick={() => {
                    if (type !== pricingLevel) {
                      setValue(selectedFeeRecurrenceName, '');
                    }
                  }}
                  disabled={!watch(perPccepr)}
                />
              ))}
            </RadioButtonGroup>
          )}
        />
        <SelectInput
          className="spark-mar-l-2 col-xs-5"
          name={selectedFeeRecurrenceName}
          label={formatMessage({
            id: 'tabs.pricing.level.fee',
          })}
          options={getFeeTypeOptions()}
          onChange={(_, value): void => setValue(selectedFeeRecurrenceName, value, changeValueOptions)}
          value={watch(selectedFeeRecurrenceName)}
          ariaLabel={formatMessage({
            id: 'tabs.pricing.level.fee.ariaLabel',
          })}
          disabled={!watch(perPccepr)}
        />
        <PriceDefinition isChecked={watch(perPccepr)} pricingType="perPccepr" hasPricingFromProvider />
        <p className="spark-mar-l-2 spark-bold spark-mar-t-2">
          <FormattedMessage id="tabs.pricing.perTransaction.title" />
        </p>
        <Checkbox
          className="spark-mar-t-1 spark-mar-l-2"
          label={formatMessage({
            id: 'tabs.pricing.perTransaction.description',
          })}
          onChange={() => {
            setValue(perTransaction, !watch(perTransaction), changeValueOptions);
            if (watch(perTransactionType) !== undefined) {
              setValue(perTransactionType, undefined, changeValueOptions);
            }
          }}
        />
        <PriceDefinition isChecked={watch(perTransaction)} pricingType="perTransaction" hasPricingFromProvider />
        <p className="row spark-mar-l-2 spark-mar-t-2 spark-bold">
          <FormattedMessage id="tabs.price.additionalInfo.title" />
        </p>
        <p className="spark-mar-l-2 col-xs-11">
          <FormattedMessage id="tabs.price.additionalInfo.description" />
        </p>
        <TextInput
          className="spark-mar-b-2 spark-mar-l-2 spark-mar-r-2"
          name={additionalInfoName}
          characterCount
          maxLength={additionalInfoMaxLength}
          placeHolder={formatMessage({
            id: 'tabs.price.additionalInfo.textInput.placeholder',
          })}
          label={additionalInfoLabel}
          value={watch(additionalInfoName)}
          onChange={(_, value) => {
            setValue(additionalInfoName, value, changeValueOptions);
          }}
          status={errors && errors[additionalInfoName as keyof typeof errors] ? MessageStatus.ERROR : undefined}
          statusMessage={
            errors &&
            errors[additionalInfoName as keyof typeof errors] &&
            (errors[additionalInfoName as keyof typeof errors] as {message: string})?.message
          }
        />
        <p className="spark-mar-l-2 spark-bold">
          <FormattedMessage id="tabs.specialPricing.title" />
        </p>
        <p className="spark-mar-l-2 spark-mar-b-2 col-xs-11">
          <FormattedMessage id="tabs.specialPricing.description" />
        </p>
      </PricingContainer>
    </PricingFormDefinitionContext.Provider>
  );
};

const PricingContainer = styled.div`
  position: relative;
`;

const PricingButtons = styled.div`
  position: absolute;
  right: 5rem;
  top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Legend = styled.legend`
  display: inline;
  padding-inline-start: 0;
  padding-inline-end: 0;
  float: left;
`;

export default PricingForm;
