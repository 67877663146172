import {CcrzEProductTabCSObjectCcrzTabCEnum} from '../../generated/product';

export const tabToSequenceMap = new Map<string, number>([
  [CcrzEProductTabCSObjectCcrzTabCEnum.KeyBenefits, 1],
  [CcrzEProductTabCSObjectCcrzTabCEnum.Features, 2],
  [CcrzEProductTabCSObjectCcrzTabCEnum.Documents, 3],
  [CcrzEProductTabCSObjectCcrzTabCEnum.Support, 4],
  [CcrzEProductTabCSObjectCcrzTabCEnum.TechnicalRequirements, 5],
  [CcrzEProductTabCSObjectCcrzTabCEnum.AdditionalInformation, 6],
]);
