import styled from 'styled-components';
import React, {useContext, useRef} from 'react';
import TableLayout from './TableLayout';
import {DevelopersContext} from '../../providers/DevelopersProvider';
import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import {Loading} from '@scm/components';
import {FormattedMessage, useIntl} from 'react-intl';
import useResponsiveTable from './useResponsiveTable';
import TableWrapper from './table-wrapper/TableWrapper';
import {SearchContext} from '../../providers/SearchProvider';
import {useDebounce} from 'use-debounce';
import mapTableData from './utils/mapTableData';
import {columns} from './utils/columns';

const DevelopersTable = () => {
  const {developers, loading} = useContext(DevelopersContext);
  const {formatMessage} = useIntl();
  const {state} = useContext(SearchContext);
  const [debouncedState] = useDebounce(state, 500);

  const tableData = mapTableData(developers, debouncedState);

  const tableRef = useRef(null);

  useResponsiveTable(tableRef, loading);

  return (
    <TableWrapper tableRef={tableRef} loading={loading}>
      {loading ? (
        <SmallLoadingContainer>
          <Loading label={formatMessage({id: 'common.data.loading'})} />
        </SmallLoadingContainer>
      ) : tableData ? (
        <TableLayout columns={columns} data={tableData} />
      ) : (
        <p className="spark-text-center spark-pad-t-2 spark-large">
          <FormattedMessage id="noSellers.description" />
        </p>
      )}
    </TableWrapper>
  );
};

export const SmallLoadingContainer = styled(LoadingContainer)`
  min-height: 0;
  padding-top: 5rem;
`;

export default DevelopersTable;
