import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import ExternalLink from 'components/ExternalLink';

const FormattedLinkMessage = ({id, href}: {id: string; href: string}) => (
  <FormattedMessage
    id={id}
    values={{
      link: (value: string) => <Link to={href}>{value}</Link>,
      externalLink: (value: string) => <ExternalLink to={href}>{value}</ExternalLink>,
      emailAddress: (value: string) => (
        <a href={'mailto:' + value} aria-label={value}>
          {value}
        </a>
      ),
    }}
  />
);

export default FormattedLinkMessage;
