const proposals = {
  'proposal.breadcrumb': 'proposals',
  'proposals.companyName': 'Company Name',
  'proposals.companyCountry': 'Company Country',
  'proposals.type': 'Type',
  'proposals.submitted': 'Submitted',
  'proposals.status': 'status',
  'proposals.onboardingRequests.message': 'Below Onboarding Requests are waiting for review.',
  'proposals.onboardingRequests.error.title': 'Cannot get onboarding requests',
  'proposals.onboardingRequestDetails.error.title': 'Cannot get onboarding request',
  'proposals.onboardingRequests.error.description': 'Please try again later',
  'proposals.sendScCode.error.title': 'Cannot create CC Seller',
  'proposals.rejectOnboarding.error.title': 'Cannot reject onboarding request',
  'proposals.rejectOnboarding.success.description': 'Onboarding request rejected',
  'proposals.sendScCode.error.description': 'Please try again later',
  'proposals.sendScCode.success.title': 'Success',
  'proposals.sendScCode.success.description': 'CC Seller created',
  'proposals.onboardingRequestDetails.contact': 'Contact Details',
  'proposalDetails.firstName': 'First Name',
  'proposalDetails.lastName': 'Last Name',
  'proposalDetails.email': 'Email',
  'proposalDetails.phone': 'Phone',
  'proposalDetails.titleInCompany': 'Title In Company',
  'proposalDetails.companyName': 'Company Name',
  'proposals.onboardingRequestDetails.company': 'Company Information',
  'proposalDetails.businessProfile': 'Business Profile',
  'proposalDetails.numberOfEmployees': 'Number Of Employees',
  'proposalDetails.yearsInBusiness': 'Years In Business',
  'proposalDetails.website': 'Website',
  'proposalDetails.locationProvince': 'Location Province',
  'proposalDetails.locationCountry': 'Location Country',
  'proposals.onboardingRequestDetails.companyAddress': 'Company Address',
  'proposalDetails.line1': 'Line 1',
  'proposalDetails.line2': 'Line 2',
  'proposalDetails.country': 'Country',
  'proposalDetails.city': 'City',
  'proposalDetails.postalCode': 'Postal Code',
  'proposalDetails.province': 'Province',
  'proposals.onboardingRequestDetails.pricing': 'Pricing Details',
  'proposalDetails.redAppPricingType': 'Red App Pricing Type',
  'proposalDetails.distributionType': 'Distribution Type',
  'proposalDetails.technicalSupport': 'Technical Support',
  'proposalDetails.regions': 'Regions',
  'proposalDetails.countryCodes': 'Country Codes',
  'proposals.onboardingRequestDetails.customerAccount': 'Sabre Customer Account',
  'proposalDetails.customer.name': 'Customer Name',
  'proposalDetails.customer.pcc': 'Customer Pcc',
  'proposalDetails.user.pcc': 'Developer PCC',
  'proposalDetails.user.epr': 'Developer EPR',
  'proposals.onboardingRequestDetails.userAccount': 'Sabre User Account',
  'proposals.onboardingRequestDetails.actions': 'Manage Request',
  'proposals.onboardingRequestDetails.create': 'Create Seller',
  'proposals.onboardingRequestDetails.assignSeller': 'Assign Existing Seller',
  'proposals.onboardingRequestDetails.proposal': 'Red App Proposal',
  'proposals.onboardingRequestDetails.modalTitle': 'Create Seller',
  'proposals.onboardingRequestDetails.rejectModalTitle': 'Reject Onboarding Request',
  'proposals.onboardingRequestDetails.assignModalTitle': 'Assign existing Seller',
  'proposals.onboardingRequestDetails.assign.text':
    'Seller ID should be copied from URL of Seller view in Salesforce. Copy only the item in bold, e.g. https://sabre.lightning.force.com/lightning/r/ccrz__E_Seller__c/<strong>a646O000000oLxrQAE</strong>/view',
  'proposals.onboardingRequestDetails.assign.input': 'Seller ID',
  'proposals.onboardingRequestDetails.assign.error': 'Bad Seller ID format.',
  'proposals.onboardingRequestDetails.assign.error.message': 'Provided Seller Id not found.',
  'proposals.onboardingRequestDetails.onboarding': 'Onboarding',
  'proposals.onboardingRequestDetails.pageTitle': 'New {type}: {name}',
  'proposals.onboardingRequestDetails.rapSubmitted.title': "Action on Sabre's side.",
  'proposals.onboardingRequestDetails.rapSubmitted.content':
    "In order to approve this request, the company SC Code has to be provided - please create it or use an existing one. Next, click on 'Create Seller' button and provide the SC Code. As a result, the CC Seller object will be created in Salesforce and onboarding request will be approved. This will also allow to approve Red App proposal in the next steps.",
  'proposals.onboardingRequestDetails.rapApproved.title': 'Onboarding request approved.',
  'proposals.onboardingRequestDetails.rapApproved.content':
    'This onboarding request is already approved - related CC Seller ID will be visible below after refreshing the page. Next, please review the Red App proposal.',
  'proposals.onboardingRequestDetails.devPartnerSubmitted.title': "Action on Sabre's side.",
  'proposals.onboardingRequestDetails.devPartnerSubmitted.content':
    ' In order to approve this request, the company SC Code has to be provided - please create it or use existing one. Next, click on Create Seller button and provide the SC Code. As a result, the CC Seller object will be created in SF and onboarding request will be approved.',
  'proposals.onboardingRequestDetails.devPartnerApproved.title': 'Onboarding approved.',
  'proposals.onboardingRequestDetails.devPartnerApproved.content':
    'This onboarding request is already approved - related CC Seller ID will be visible below after refreshing the page. Next, please review the Seller object in Salesforce, provide all required information and enable the Seller.',
  'proposals.onboardingRequestDetails.rejected.title': 'Onboarding request rejected.',
  'proposals.onboardingRequestDetails.rejected.content':
    'This onboarding request was rejected. If you want to make any changes, please contact dev team <email>scm-ac-dev@sabre.com</email>.',
  'proposalDetails.sellerId': 'Seller ID',
  'proposalDetails.createSeller.message':
    'Please make sure that provided SC Code exists in Salesforce. As a result of this action, the CC Seller object will be created in Salesforce and onboarding request will be approved.',
  'proposalDetails.rejectOnboarding.message':
    'Are you sure that you want to reject this request? If so, in case you want to send some details about the reason to customer, you can add it below (it will be added to email notification).',
  'proposalDetails.reject.button': 'Reject',
  'proposalDetails.createSeller.placeholder': 'Sc Code',
  'proposalDetails.reasonRejected.placeholder': 'Reason',
  'proposalDetails.createSeller.error.message': 'Sc Code must have at least 3 characters.',
  'proposalDetails.reasonRejected.error.message': 'Reason cannot be longer than 1000 characters',
};

export default proposals;
