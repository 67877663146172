import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import HrefElement from '@scm/components/messaging/HrefElement';
import {redAppSupportEmail} from '@scm/components/utils/common';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import Tooltip from '@sabre/spark-react-core/tooltip';
import {isAllowedToEditProducts} from '@scm/authentication/utils/authentication';
import ToggleButton from '../toggle-button/ToggleButton';
import useId from '@scm/components/accessibility/hooks/useId';
import NameHeading from '../change-name/NameHeading';

const IconHeadings = ({productNameTooltipValue}: {productNameTooltipValue: string}) => {
  const disabledClickEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const isAllowedToEdit = isAllowedToEditProducts();
  const appNameId = useId();

  return (
    <ProductName>
      <NameHeading />
      {isAllowedToEdit && !!productNameTooltipValue && (
        <TooltipWrapper className="spark-mar-l-.5" onClick={disabledClickEvent} role="tooltip" id={appNameId}>
          <SmallTooltip
            anchorX={TooltipDirectionX.RIGHT}
            anchorY={TooltipDirectionY.MIDDLE}
            toggleEl={<ToggleButton />}
            className="col-xs-3"
          >
            <FormattedMessage
              id={productNameTooltipValue}
              values={{email: <HrefElement email={redAppSupportEmail} />}}
            />
          </SmallTooltip>
        </TooltipWrapper>
      )}
    </ProductName>
  );
};

const TooltipWrapper = styled.div`
  display: inline-flex;
`;

const ProductName = styled.div`
  padding: 0.5rem;
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
`;

const SmallTooltip = styled(Tooltip)`
  white-space: break-spaces !important;
  line-height: 170%;
`;

export default IconHeadings;
