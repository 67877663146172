import Modal from '@sabre/spark-react-core/modal';
import React, {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {ButtonContainer} from '../../../assign-developer/AssignForm';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {CompanyManagementApi, Configuration} from '../../../../generated/management';
import {apiBaseLink} from '../../../../assets/apiBaseLink';
import {ProductsContext} from '../../../../providers/ProductsPovider';

const deleteDeveloperFromProduct = async (sku: string, pcc: string, epr: string) =>
  await new CompanyManagementApi(
    new Configuration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() as string,
    })
  ).deleteDeveloperFromProduct({
    sku: sku,
    pccEpr: {
      pcc: pcc,
      epr: epr,
    },
  });

const RemoveDeveloperModal = ({
  sku,
  pcc,
  epr,
  firstName,
  lastName,
  productName,
  open,
  setOpen,
}: {
  sku: string;
  pcc: string;
  epr: string;
  firstName: string;
  lastName: string;
  productName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const {formatMessage} = useIntl();
  const {getProducts} = useContext(ProductsContext);

  const actionButtonHandler = () => {
    deleteDeveloperFromProduct(sku, pcc, epr)
      .then(() =>
        openToast(
          createMessageString(formatMessage, 'removeDeveloper.modal', true, false),
          createMessageString(formatMessage, 'removeDeveloper.modal', false, false),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(err => {
        err.response.json().catch(() => {});
        openToast(
          createMessageString(formatMessage, 'removeDeveloper.modal', true, true),
          createMessageString(formatMessage, 'removeDeveloper.modal', false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      })
      .finally(() => {
        onClose();
        getProducts();
      });
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      onClose={onClose}
      open={open}
      title={formatMessage({id: 'removeDeveloper.modal.title'})}
      titleHeadingLevel={2}
    >
      <Description>
        <FormattedMessage
          id="removeDeveloper.productLevel.modal.description"
          values={{
            firstName: firstName,
            lastName: lastName,
            pcc: pcc,
            epr: epr,
            productName: productName,
          }}
        />
      </Description>
      <ButtonContainer className="row spark-mar-t-2">
        <Button type="button" secondary onClick={onClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button type="submit" progress onClick={actionButtonHandler} size={ButtonSize.SMALL} className="spark-mar-r-1">
          <FormattedMessage id="common.button.confirm" />
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export const Description = styled.p`
  text-align: justify;
  text-justify: inter-word;
  white-space: normal;
  font-weight: 500;
  margin-top: 2rem;
`;

export default RemoveDeveloperModal;
