import React, {ReactElement, useContext, useState} from 'react';
import {Button, TextInput} from '@sabre/spark-react-core';
import {Controller, FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import {ButtonSize, MessageStatus} from '@sabre/spark-react-core/types';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {yupResolver} from '@hookform/resolvers/yup';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';
import {changeNameSchema, maxNameLength} from './schema/changeNameSchema';
import {ProductContext} from '../../../../provider/ProductProvider';
import useChangeProductName from '../../../../hooks/useChangeProductName';
import useHandleValidateNewName from '../../../../hooks/useHandleValidateNewName';
import FormAlertMessage from './FormAlertMessage';
import NameInfoPopover from './NameInfoPopover';

const StandardProductChangeNameForm = ({
  handleCloseModal,
  sku,
  currentName,
}: {
  handleCloseModal: () => void;
  sku: string;
  currentName: string;
}) => {
  const {formatMessage} = useIntl();
  const fieldName = 'name';
  const [smallSpinnerLoading, isSmallSpinnerLoading] = useState<boolean>(false);
  const [isNameValid, setIsNameValid] = useState<boolean>(false);
  const [isNameAvailable, setIsNameAvailable] = useState<boolean>(false);
  const {setLoading} = useContext(ProductContext);

  const {
    handleSubmit,
    control,
    clearErrors,
    setError,
    formState: {errors, isValid, isDirty},
  } = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: {
      [fieldName]: currentName,
    },
    resolver: yupResolver(changeNameSchema),
  });
  const {changeProductName} = useChangeProductName(sku, setLoading, handleCloseModal);
  const {handleValidateNewName} = useHandleValidateNewName(
    setIsNameAvailable,
    setIsNameValid,
    setError,
    isSmallSpinnerLoading,
    isNameValid,
    clearErrors,
    fieldName
  );

  const onSubmit: SubmitHandler<FieldValues> = data => {
    changeProductName(data.name);
    gaEvent('Changed Product Name');
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row spark-mar-b-2">
        <div className="col-xs-11">
          <Controller
            name={fieldName}
            control={control}
            render={({field}): ReactElement => (
              <TextInput
                {...field}
                label={formatMessage({id: 'productDetails.icon.change.label'})}
                characterCount
                maxLength={maxNameLength}
                onChange={(_, val) => {
                  field.onChange(val);
                  if (val !== currentName) {
                    handleValidateNewName(val);
                  }
                }}
                status={errors && errors[field.name as keyof typeof errors] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors &&
                  errors[field.name as keyof typeof errors] &&
                  (errors[field.name as keyof typeof errors] as {message: string})?.message
                }
                onKeyPress={e => (!isNameValid || !isNameAvailable) && e.key === 'Enter' && e.preventDefault()}
              />
            )}
          />
        </div>
        <NameInfoPopover smallSpinnerLoading={smallSpinnerLoading} />
      </div>
      <FormAlertMessage />
      <ButtonContainer className="spark-flex">
        <Button secondary type="button" onClick={handleCloseModal} size={ButtonSize.SMALL} className="spark-mar-r-1">
          {formatMessage({id: 'common.cancelButton'})}
        </Button>
        <Button
          type="submit"
          progress
          onClick={handleSubmit(onSubmit)}
          size={ButtonSize.SMALL}
          disabled={!isValid || !isNameValid || !isNameAvailable || !isDirty}
          tabIndex={0}
        >
          {formatMessage({id: 'common.submitButton'})}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  & .row {
    flex-wrap: nowrap;
    overflow: hidden;
  }
`;

const ButtonContainer = styled.div`
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export default StandardProductChangeNameForm;
