import {FormattedMessage} from 'react-intl';
import React from 'react';
import styled from 'styled-components';

const ReviewParagraphs = ({
  label,
  content,
  isListHeader,
}: {
  label: string[];
  content?: (string | Element | JSX.Element)[];
  isListHeader?: boolean;
}) => (
  <>
    {label.map((item, index) => (
      <ReviewParagraph key={index} data-list-header={isListHeader}>
        <TextLabel>
          <FormattedMessage id={item} />
          {content && ':'}
        </TextLabel>
        {content && content[index]}
      </ReviewParagraph>
    ))}
  </>
);
export const TextLabel = styled.strong`
  margin-right: 1rem;
  overflow-wrap: normal;
`;

const ReviewParagraph = styled.p`
  margin-bottom: 1rem;
  font-family: Roboto Light, Sans-Serif;
  overflow-wrap: break-word;
`;

export default ReviewParagraphs;
