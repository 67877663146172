import moment from 'moment';
import jwtDecode from 'jwt-decode';
import {
  adminRoles,
  betaTestersEditingAllowedRoles,
  productEditingAllowedRoles,
  productManageAllowedRoles,
  removeProductAllowedRoles,
  sabreEmployeeAccessRoles,
  standardProductEditingAllowedRoles,
  supportRoles,
  versionManageAllowedRoles,
} from '@scm/components/utils/common';

export const ACCESS_TOKEN_KEY = 'accessToken';

export const getAccessToken = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  return token ? String(token) : token;
};

export const setToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const getAccessTokenPayload: () => TokenPayload = () => {
  try {
    return jwtDecode<TokenPayload>(getAccessToken() || '');
  } catch (e) {
    clearTokens();
    return {sf_token: '', exp: 0};
  }
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const isLoggedIn = () => !!getAccessToken() && !isAccessTokenExpired();

const isTokenExpired = (exp: number) => {
  const tokenExpirationDate = moment.unix(exp).add(-60, 'seconds');
  return tokenExpirationDate.isBefore(moment());
};

export const isAccessTokenExpired = () => isTokenExpired(getAccessTokenPayload().exp);

export interface TokenPayload {
  sf_token: string;
  exp: number;
}

export const userData = () => (getAccessToken() ? jwtDecode<{roles: string[]}>(getAccessToken() || '') : {roles: []});

export const areTermsAndConditionsAccepted = () =>
  getAccessToken()
    ? jwtDecode<{terms_and_conditions_accepted: boolean}>(getAccessToken() || '')
    : {terms_and_conditions_accepted: false};

export const isSabreEmployeeRole = (): boolean => {
  const {roles} = userData();
  return roles.some(r => sabreEmployeeAccessRoles.includes(r));
};
export const isVersionManageAllowedRoles = (): boolean => {
  const {roles} = userData();
  return roles.some(r => versionManageAllowedRoles.includes(r));
};

export const isSupportRole = (): boolean => {
  const {roles} = userData();
  return roles.some(r => supportRoles.includes(r));
};

export const isCertificationEngineerRole = (): boolean => {
  const {roles} = userData();
  return roles.some(r => adminRoles.includes(r));
};

export const isSabreAdminRole = (): boolean => {
  const {roles} = userData();
  return roles.some(role => role === 'ROLE_SABRE_ADMIN');
};

export const isSecondLevelSupportRole = (): boolean => {
  const {roles} = userData();
  return roles.some(role => role === 'ROLE_SECOND_LEVEL_SUPPORT');
};

export const isAdpRole = (): boolean => {
  const {roles} = userData();
  return roles.some(role => role === 'ROLE_ADP');
};

export const isAdpOrSabreAdminRole = (): boolean => {
  const {roles} = userData();
  return roles.some(role => role === 'ROLE_ADP' || role === 'ROLE_SABRE_ADMIN');
};

export const isSecondLevelSupportRoleOrSabreAdmin = (): boolean => {
  const {roles} = userData();
  return roles.some(role => role === 'ROLE_SECOND_LEVEL_SUPPORT' || role === 'ROLE_SABRE_ADMIN');
};

export const isAdpOrSecondLevelSupportOrSabreAdminRole = (): boolean => {
  const {roles} = userData();
  return roles.some(role => role === 'ROLE_ADP' || role === 'ROLE_SECOND_LEVEL_SUPPORT' || role === 'ROLE_SABRE_ADMIN');
};

export const isAllowedToEditProducts = (): boolean => {
  const {roles} = userData();
  return roles.some(role => productEditingAllowedRoles.includes(role));
};

export const isAllowedToEditStandardProducts = (): boolean => {
  const {roles} = userData();
  return roles.some(role => standardProductEditingAllowedRoles.includes(role));
};

export const isAllowedToViewLimitation = (): boolean => {
  const {roles} = userData();
  return roles.some(role => productManageAllowedRoles.includes(role));
};

export const isAllowedToRemoveProductInCreation = (): boolean => {
  const {roles} = userData();
  return roles.some(role => removeProductAllowedRoles.includes(role));
};

export const isAllowedToAddRemoveTesters = (): boolean => {
  const {roles} = userData();
  return betaTestersEditingAllowedRoles.some(allowedRole => roles.includes(allowedRole));
};

export const isAgencyAdminRole = (): boolean => {
  const {roles} = userData();
  return roles.some(r => r === 'ROLE_AGENCY_ADMIN');
};

export const isRedAppDeveloperRole = (): boolean => {
  const {roles} = userData();
  return roles.some(r => r === 'ROLE_RED_APP_DEVELOPER');
};

export const isManageCompanyRole = (): boolean => {
  const {roles} = userData();
  return roles.some(r => r === 'ROLE_MANAGE_COMPANY');
};

export const isSubmitProposalRole = (): boolean => {
  const {roles} = userData();
  return roles.some(r => r === 'ROLE_SUBMIT_PROPOSAL');
};

export const userHasNoRoles = (): boolean => {
  const {roles} = userData();
  return roles.length === 0;
};
