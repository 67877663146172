import * as yup from 'yup';
import {
  commentName,
  endDateName,
  endHourName,
  environmentName,
  startDateName,
  startHourName,
} from './FreezeCalendarValues';

export const freezeCalendarSchema = yup.object().shape({
  [environmentName]: yup.array().required().min(1).max(2),
  [startDateName]: yup.string().required().min(1),
  [startHourName]: yup.string().required().min(1),
  [endDateName]: yup.string().required().min(1),
  [endHourName]: yup.string().required().min(1),
  [commentName]: yup.string().required().min(1).max(500),
});
