import React, {ReactElement, useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Star} from '@scm/components';
import {Controller} from 'react-hook-form';
import {ProposalContext} from '../../../ProposalForm';
import {Checkbox, CheckboxGroup, TextInput} from '@sabre/spark-react-core';
import {technologiesDescriptionName, technologiesName} from '../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import Legend from '@scm/components/accessibility/Legend';

export enum TechnologiesType {
  'Typescript' = 'Typescript (recommended)',
  'Web Technologies (HTML/JS)' = 'Web Technologies (HTML/JS)',
  'Native Java' = 'Native Java',
  'Sabre Scribe' = 'Sabre Scribe',
  'Other' = 'Other',
}

const Technologies = () => {
  const {formatMessage} = useIntl();
  const {control, watch, errors, setValue} = useContext(ProposalContext);
  const textFieldMaxLength = 500;

  return (
    <>
      <p className="spark-mar-b-1 spark-mar-r-2">
        <FormattedMessage id="definition.technologies.label" />
        <Star hasColon />
      </p>
      <Controller
        name={technologiesName}
        control={control}
        render={({field: {onChange, name, value}}): ReactElement => (
          <CheckboxGroup
            name={name}
            value={value}
            onChange={(_, activeItems): void => {
              onChange(activeItems);
              if (!activeItems.includes(TechnologiesType.Other)) {
                setValue(technologiesDescriptionName, '', {shouldValidate: true, shouldTouch: true, shouldDirty: true});
              }
            }}
            status={errors[name] ? MessageStatus.ERROR : undefined}
            errorMessage={errors[name] && (errors[name] as any)?.message}
          >
            <Legend text={formatMessage({id: 'definition.technologies.label'})} />
            {Object.entries(TechnologiesType).map(technology => (
              <Checkbox key={technology[0]} label={technology[1]} value={technology[0]} />
            ))}
          </CheckboxGroup>
        )}
      />
      {(watch(technologiesName) as Array<string>).includes(TechnologiesType.Other) && (
        <div className="col-xs-6 spark-mar-b-2">
          <Controller
            name={technologiesDescriptionName}
            control={control}
            render={({field}): ReactElement => (
              <TextInput
                {...field}
                label={formatMessage(
                  {id: 'definition.technologiesDescription.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                maxLength={textFieldMaxLength}
                status={errors[field.name] ? MessageStatus.ERROR : undefined}
                statusMessage={errors[field.name] && errors[field.name]?.message}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default Technologies;
