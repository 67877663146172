/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordType
 */
export interface RecordType {
    /**
     * 
     * @type {string}
     * @memberof RecordType
     */
    name?: RecordTypeNameEnum;
}


/**
 * @export
 */
export const RecordTypeNameEnum = {
    RedApp: 'Red App',
    Standard: 'Standard'
} as const;
export type RecordTypeNameEnum = typeof RecordTypeNameEnum[keyof typeof RecordTypeNameEnum];


/**
 * Check if a given object implements the RecordType interface.
 */
export function instanceOfRecordType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordTypeFromJSON(json: any): RecordType {
    return RecordTypeFromJSONTyped(json, false);
}

export function RecordTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'Name') ? undefined : json['Name'],
    };
}

export function RecordTypeToJSON(value?: RecordType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Name': value.name,
    };
}

