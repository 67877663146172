import {Thresholds, ValidationResult} from '../../../generated/certification';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

export const validationResponseName = 'validationResponse';
export const comparisonResponseName = 'comparisonResponse';
export const tasksName = 'tasks';
export const packagesName = 'packages';
export const thresholdsName = 'thresholds';
export const skipCompareName = 'skipCompare';
export const skipCertificateCheckName = 'skipCertificateCheck';
export const newItemName = 'newItem';
export interface SvsValues {
  [validationResponseName]?: ValidationResult;
  [comparisonResponseName]?: ValidationResult;
  [tasksName]: Array<string>;
  [packagesName]: Array<string>;
  [thresholdsName]: Array<Thresholds>;
  [skipCompareName]: Confirmation;
  [skipCertificateCheckName]: Confirmation;
  [newItemName]?: string;
}
