import React from 'react';
import {FormattedMessage} from 'react-intl';
import parse from 'html-react-parser';
import styled from 'styled-components';
import ContactUs from 'pages/partnerDetails/ContactUs';
import {Maybe} from 'generated/graphql';
import {useMediaQuery} from 'react-responsive';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const PartnerDescription = ({
  name,
  longDescription,
  email,
}: {
  name?: Maybe<string>;
  longDescription?: Maybe<string>;
  email?: Maybe<string>;
}) => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  return (
    <PartnerDescriptionContainer mobile={isMobile}>
      <div className="spark-alpha spark-bold spark-mar-b-1" role="heading" aria-level={1}>
        <FormattedMessage id="partnerPage.description.title" values={{partnerName: name}} />
      </div>
      <div>{parse(longDescription || '')}</div>
      <ContactUs email={email} name={name} />
    </PartnerDescriptionContainer>
  );
};

const PartnerDescriptionContainer = styled.div<{mobile?: boolean}>`
  padding: ${props => (props.mobile ? '2rem' : '3rem 4rem')};
  min-width: 70%;
  background: ${colors.blue100};
`;

export default PartnerDescription;
