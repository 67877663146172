import React, {useContext, useEffect, useState} from 'react';
import {Form} from '@scm/version/components/pages/cleanup/CleanupForm';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button} from '@sabre/spark-react-core';
import {StorefrontDataContext} from '../../../storefrontData/dataProvider/StorefrontDataProvider';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import styled from 'styled-components';
import {
  blockOrderingDescriptionName,
  blockOrderingName,
  hideProductName,
  isPaidName,
  provisioningTypeName,
  redAppIdName,
  typeName,
} from '../../../storefrontData/interfaces/AppInfoDataValues';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {Configuration, ProductsApi, SetCertCmConfigVersionRequest} from '../../../../generated/certification';
import {certificationApiBaseLink} from '../../../../assets/apiBaseLink';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import StorefrontVisibility from './StorefrontVisibility';
import PrimaryConfiguration from './PrimaryConfiguration';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const RedAppDetails = () => {
  const {methodsProductForm, submitHandler, setShowLoadingSpinner, setReloadForm} = useContext(StorefrontDataContext);
  const {cmCertVersion, setCmCertVersion} = useContext(StorefrontDataContext);
  const {watch, setValue, getValues} = methodsProductForm;
  const [isSaveActive, setIsSaveActive] = useState(false);
  const {formatMessage} = useIntl();
  const [isProvisioningTypeDisabled, setIsProvisioningTypeDisabled] = useState(false);
  const [isPricingTypeDisabled, setIsPricingTypeDisabled] = useState(false);
  const [appTypeName, setAppTypeName] = useState(watch(typeName));
  const [provisioningType, setProvisioningType] = useState(watch(provisioningTypeName));
  const [isPaid, setIsPaid] = useState(watch(isPaidName));
  const [hideProduct, setHideProduct] = useState(watch(hideProductName));
  const [blockOrdering, setBlockOrdering] = useState(watch(blockOrderingName));
  const [versionName, setVersionName] = useState(cmCertVersion ?? '');
  const initialAppTypeName = watch(typeName);
  const initialProvisioningType = watch(provisioningTypeName);
  const initialIsPaid = watch(isPaidName);
  const productDetailsName = 'productDetails.fetch';
  const [blockOrderingDescription, setBlockOrderingDescription] = useState(
    watch(blockOrderingDescriptionName)?.ccrzValueRTC ?? ''
  );

  useEffect(() => {
    const isProvisioningTypeDisabled = appTypeName !== 'Regular';
    const isPricingTypeDisabled = isProvisioningTypeDisabled || provisioningType === 'Private';
    setIsProvisioningTypeDisabled(isProvisioningTypeDisabled);
    setIsPricingTypeDisabled(isPricingTypeDisabled);
    isPricingTypeDisabled && setIsPaid('Free');
    isProvisioningTypeDisabled && setProvisioningType('Private');

    if (
      appTypeName === initialAppTypeName &&
      provisioningType === initialProvisioningType &&
      isPaid === initialIsPaid
    ) {
      setIsSaveActive(false);
    }
  }, [provisioningType, appTypeName, isPaid, isPricingTypeDisabled, isProvisioningTypeDisabled]);

  const changeVersionNameHandler = (value: string) => {
    setVersionName(value);
    setIsSaveActive(cmCertVersion !== value);
  };

  const changeHandler = (fieldName: string, newValue: string) => {
    switch (fieldName) {
      case typeName:
        setAppTypeName(newValue);
        break;
      case provisioningTypeName:
        setProvisioningType(newValue);
        break;
      case isPaidName:
        setIsPaid(newValue);
        break;
      case hideProductName:
        setHideProduct(newValue);
        break;
      case blockOrderingName:
        setBlockOrdering(newValue);
        break;
      case blockOrderingDescriptionName:
        setBlockOrderingDescription(newValue);
        break;
      default:
        throw new Error('Action does not exist');
    }
    setIsSaveActive(true);
  };

  const updateFormValues = () => {
    const shouldTouch = {shouldTouch: true};
    setValue(isPaidName, isPaid, shouldTouch);
    setValue(provisioningTypeName, provisioningType, shouldTouch);
    setValue(typeName, appTypeName, shouldTouch);
    setValue(hideProductName, hideProduct, shouldTouch);
    setValue(blockOrderingName, blockOrdering, shouldTouch);
    setValue(
      blockOrderingDescriptionName,
      {
        ...watch(blockOrderingDescriptionName),
        ccrzValueRTC: blockOrderingDescription,
      },
      shouldTouch
    );
  };

  const setCertCmConfigVersion = async (setCertCmConfigVersionRequest: SetCertCmConfigVersionRequest) =>
    await new ProductsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).setCertCmConfigVersion(setCertCmConfigVersionRequest);

  const setCertCmConfigVersionForProduct = () => {
    const setCertCmConfigVersionRequest = {
      id: watch(redAppIdName),
      versionName: versionName ?? '',
    };

    setCertCmConfigVersion(setCertCmConfigVersionRequest)
      .then(() => {
        setCmCertVersion(versionName);
        openToast(
          formatMessage({id: 'productDetails.fetch.successTitle'}),
          formatMessage({id: 'productDetails.certTesters.setNewVersionName.success'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      })
      .catch(err => {
        err.response.json().catch(() => {});
        if (err.response.status === 422) {
          openToast(
            createMessageString(formatMessage, productDetailsName, true, true),
            formatMessage({id: 'productDetails.certTesters.setNewVersionName.error'}),
            ToastType.WARNING,
            'spark-icon-alert-triangle'
          );
        }
      });
  };

  return (
    <div>
      <RedAppDetailsHeader>
        <SaveButtonContainer>
          <Button
            onClick={() => {
              setShowLoadingSpinner(true);
              updateFormValues();
              setCertCmConfigVersionForProduct();
              submitHandler(getValues(), undefined, undefined, () => setReloadForm(true));
            }}
            size={ButtonSize.SMALL}
            disabled={!isSaveActive}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </SaveButtonContainer>
      </RedAppDetailsHeader>
      <Form className="spark-mar-l-.5">
        <PrimaryConfiguration
          appTypeName={appTypeName}
          provisioningType={provisioningType}
          changeHandler={changeHandler}
          changeVersionNameHandler={changeVersionNameHandler}
          versionName={versionName}
          isPricingTypeDisabled={isPricingTypeDisabled}
          isProvisioningTypeDisabled={isProvisioningTypeDisabled}
          isPaid={isPaid}
        />
        <StorefrontVisibility
          blockOrdering={blockOrdering}
          blockOrderingDescription={blockOrderingDescription}
          hideProduct={hideProduct}
          changeHandler={changeHandler}
        />
      </Form>
    </div>
  );
};

export const Label = styled.p`
  width: 20rem;
  display: flex;
  @media (max-width: ${deviceWidths.md}px) {
    min-height: 0;
    margin-top: 2rem;
    width: 100%;
  }
`;

export const ToggleWrapper = styled.div`
  span {
    font-size: 1.3333333333rem !important;
    padding: 0.75rem 2.25rem !important;
  }
`;

export const RedAppDetailsHeader = styled.div`
  position: relative;
`;

const SaveButtonContainer = styled.div`
  position: absolute;
  right: 6rem;
  top: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export default RedAppDetails;
