import {
  FullPageModalState,
  FULL_PAGE_MODAL_OPEN,
  FULL_PAGE_MODAL_CLOSE,
} from 'components/modals/fullPageModal/store/types';
import {createReducer} from 'utils/reduxUtils';
import {AnyAction} from 'redux';

const initialState: FullPageModalState = {
  isOpen: false,
};

export const reducer = createReducer(initialState, {
  [FULL_PAGE_MODAL_OPEN]: (state, {children, onRequestClose, className, sizing}: AnyAction) => ({
    ...state,
    isOpen: true,
    children,
    onRequestClose,
    className,
    sizing,
  }),
  [FULL_PAGE_MODAL_CLOSE]: () => initialState,
});
