import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

export const LeftBar = styled.div.attrs(className => ({
  className: `${className}`,
}))<{mobile?: boolean}>`
  background: ${props => (props.mobile ? `${colors.white}` : `${colors.grey700}`)};
  height: 100% !important;

  :hover {
    cursor: pointer;
  }
`;

export const TasksIcon = styled.i.attrs<{className?: string}>(className => ({
  className: `spark-icon-sliders ${className}`,
}))<{mobile?: boolean}>`
  ${props => (props.mobile ? '' : `color: ${colors.white}`)};
  font-size: ${props => (props.mobile ? '3rem' : '2rem')};
`;
