import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import colors from '../assets/colors';
import SparkExpandPanel from '@sabre/spark/js/src/components/expand';

export const ExpandPanel = ({
  panelHeader,
  children,
  isDarkTheme,
  isOpen,
  isTextInput,
}: {
  panelHeader: string | JSX.Element;
  children: string | JSX.Element;
  isDarkTheme?: boolean;
  isOpen?: boolean;
  isTextInput?: boolean;
}) => {
  const expandRef = useRef<HTMLDivElement>(null);
  const panelId = `spark-panel--expand${Math.floor(Math.random() * 1000000)}`;

  useEffect(() => {
    const sparkAccordion = new SparkExpandPanel(expandRef.current);
    if (isOpen && !isTextInput) {
      sparkAccordion.expand();
    }

    window.addEventListener('click', () => sparkAccordion.collapse());
    return () => window.removeEventListener('click', () => sparkAccordion.collapse());
  }, [expandRef, isOpen]);

  useEffect(() => {
    const clickableElement: HTMLElement = (expandRef.current as unknown as HTMLElement).firstChild as HTMLElement;
    const element = document.querySelector(`#${panelId}`);
    const openBefore = element?.classList.contains('expanded');
    const searchInput = document.querySelector('input[name=searchText]') as HTMLInputElement;
    if (isOpen && !openBefore) {
      clickableElement.click();
      searchInput.focus();
    }
  }, [isOpen]);

  return (
    <PanelContainer
      className="spark-panel spark-panel--expand"
      id={panelId}
      ref={expandRef}
      onClick={evt => evt.stopPropagation()}
    >
      <Header
        className="spark-panel__header"
        role="heading"
        tabIndex={0}
        aria-level={2}
        aria-controls="expand-collapse--accordion_content"
        isDarkTheme={isDarkTheme}
      >
        <p>{panelHeader}</p>
      </Header>
      <ContentContainer id="expand-collapse--accordion_content" aria-hidden="true" className="spark-panel__content">
        {children}
      </ContentContainer>
    </PanelContainer>
  );
};

const Header = styled.div<{isDarkTheme?: boolean}>`
  ${props =>
    props.isDarkTheme
      ? `background-color: ${colors.neutralBlue} !important;
      color: ${colors.white} !important;
      border-color: ${colors.grey200} !important;`
      : ''}
  &::after {
    transform: translate(4px, -50%) rotate(0) !important;
  }

  .expanded &::after {
    transform: translate(4px, -50%) rotate(180deg) !important;
  }
`;
const PanelContainer = styled.div`
  width: 150%;
`;

const ContentContainer = styled.div`
  padding: 0;

  & .spark-multi-select {
    border: none !important;
  }
`;
