import {Button} from '@sabre/spark-react-core';
import Tooltip from '@sabre/spark-react-core/tooltip';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import styled from 'styled-components';

const SaveButton = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, success?: () => {}, failure?: () => {}) => void;
  disabled: boolean;
}) =>
  disabled ? (
    <ButtonWithTooltipContainer>
      <Tooltip
        toggleEl={
          <TooltipHandler>
            <Button progress onClick={onClick} size={ButtonSize.SMALL} disabled={disabled}>
              <FormattedMessage id="productDetails.submitButton" />
            </Button>
          </TooltipHandler>
        }
      >
        <FormattedMessage id="productDetails.submitButton.error" />
      </Tooltip>
    </ButtonWithTooltipContainer>
  ) : (
    <Button progress onClick={onClick} size={ButtonSize.SMALL} disabled={disabled}>
      <FormattedMessage id="productDetails.submitButton" />
    </Button>
  );

const TooltipHandler = styled.span`
  position: relative;
  display: block;
  background-color: transparent;
  z-index: initial;
  cursor: not-allowed;
`;

const ButtonWithTooltipContainer = styled.div`
  position: relative;
  background-color: transparent;
  z-index: 1;

  & button {
    z-index: -1;
  }
`;
export default SaveButton;
