import React from 'react';
import {CheckboxLabel, Content, filterList} from './MultiSelect';
import {UseFormSetValue, UseFormWatch} from 'react-hook-form';

export const SingleLevelMultiSelect = ({
  watch,
  setValue,
  firstLevelName,
  options,
  disabled,
}: {
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  firstLevelName: string;
  options: {[key: string]: string};
  disabled?: boolean;
}) => {
  return (
    <Content className="spark-multi-select__container">
      {Object.entries(options).map((item, index) => (
        <CheckboxLabel key={index} className="spark-checkbox" hasText>
          <input
            className="spark-checkbox__input"
            type="checkbox"
            value={item[0]}
            checked={watch(firstLevelName).indexOf(item[0]) !== -1}
            onChange={e => {
              const value = e.target.value;
              const currentList = () => watch(firstLevelName);
              if (currentList().includes(value)) {
                setValue(firstLevelName, filterList(value, currentList()), {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                });
              } else {
                setValue(firstLevelName, [...currentList(), value], {
                  shouldDirty: true,
                  shouldValidate: true,
                  shouldTouch: true,
                });
              }
            }}
            disabled={disabled && watch(firstLevelName).indexOf(item[0]) === -1}
            aria-label={`Option to select: ${item[0]}`}
          />
          <span className="spark-checkbox__box" />
          <span className="spark-label">{item[1]}</span>
        </CheckboxLabel>
      ))}
    </Content>
  );
};
