/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CcrzECategoryCSObject } from './CcrzECategoryCSObject';
import {
    CcrzECategoryCSObjectFromJSON,
    CcrzECategoryCSObjectFromJSONTyped,
    CcrzECategoryCSObjectToJSON,
} from './CcrzECategoryCSObject';

/**
 * 
 * @export
 * @interface CcrzEProductCategoryCSObject
 */
export interface CcrzEProductCategoryCSObject {
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCategoryCSObject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCategoryCSObject
     */
    ccrzProductC?: string;
    /**
     * 
     * @type {CcrzECategoryCSObject}
     * @memberof CcrzEProductCategoryCSObject
     */
    ccrzCategoryR?: CcrzECategoryCSObject;
}

/**
 * Check if a given object implements the CcrzEProductCategoryCSObject interface.
 */
export function instanceOfCcrzEProductCategoryCSObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CcrzEProductCategoryCSObjectFromJSON(json: any): CcrzEProductCategoryCSObject {
    return CcrzEProductCategoryCSObjectFromJSONTyped(json, false);
}

export function CcrzEProductCategoryCSObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CcrzEProductCategoryCSObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'ccrzProductC': !exists(json, 'ccrz__Product__c') ? undefined : json['ccrz__Product__c'],
        'ccrzCategoryR': !exists(json, 'ccrz__Category__r') ? undefined : CcrzECategoryCSObjectFromJSON(json['ccrz__Category__r']),
    };
}

export function CcrzEProductCategoryCSObjectToJSON(value?: CcrzEProductCategoryCSObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ccrz__Product__c': value.ccrzProductC,
        'ccrz__Category__r': CcrzECategoryCSObjectToJSON(value.ccrzCategoryR),
    };
}

