import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {ProvisioningType} from '../../../generated/proposal';
import {BlankIcon, IconContainer, ProductHeader, Text} from '@scm/version/components/VersionHeader';

const ProposalViewHeader = ({name, provisioningType}: {name?: string; provisioningType?: ProvisioningType}) => {
  return (
    <ProductHeader className="spark-flex">
      <IconContainer>
        <BlankIcon />
        <Text className="spark-flex">
          <h1>{name}</h1>
          <ProposalType>
            <FormattedMessage id="proposalView.proposalType" values={{provisioningType}} />
          </ProposalType>
        </Text>
      </IconContainer>
    </ProductHeader>
  );
};

const ProposalType = styled.p`
  &:first-letter {
    text-transform: uppercase;
  }
`;

export default ProposalViewHeader;
