/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProposalStatus = {
    Draft: 'Draft',
    Submitted: 'Submitted',
    InReview: 'InReview',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Obsolete: 'Obsolete'
} as const;
export type ProposalStatus = typeof ProposalStatus[keyof typeof ProposalStatus];


export function ProposalStatusFromJSON(json: any): ProposalStatus {
    return ProposalStatusFromJSONTyped(json, false);
}

export function ProposalStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalStatus {
    return json as ProposalStatus;
}

export function ProposalStatusToJSON(value?: ProposalStatus | null): any {
    return value as any;
}

