import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import TabName from '@scm/product-components/pages/productDetails/productTabs/TabName';
import {
  convertToURL,
  TabData,
  TabList,
  TabListItem,
} from '@scm/product-components/pages/productDetails/productTabs/ProductTabsContainer';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import Certification from './tabs/Certification';
import Troubleshooting from './tabs/Troubleshooting';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import styled from 'styled-components';
import {Configuration, Status, Version, VersionsApi} from '@scm/product-components/generated/certification';
import {certificationApiBaseLink, productApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {Loading} from '@scm/components';
import {
  Configuration as PresentationConfiguration,
  PresentationApi,
} from '@scm/product-components/generated/presentation';
import {HeaderText} from '../../components/App';

export interface CeData {
  id?: string;
  redAppName: string;
  status: string;
  version?: string;
  lastUpdate?: string;
  createdAt?: string;
  assignedTo?: string;
  environment?: string;
  scheduledDate?: string;
  submitted?: string;
  hintMessage?: string | JSX.Element;
}

export const fetchDashboardData = async (id: string) =>
  await new PresentationApi(
    new PresentationConfiguration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken(),
    })
  ).getDashboardData({id});

const CertificationEngineerDashboard = () => {
  const [versionsTroubleshooting, setVersionsTroubleshooting] = useState<Version[]>([]);
  const [versionsInCertification, setVersionsInCertification] = useState<Version[]>([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const {formatMessage} = useIntl();

  const mapStatusesToEnum = (statuses: string[]) =>
    statuses.map(status => status.toUpperCase().replaceAll(/[) ]/g, '_').replaceAll(/[(]/g, ''));

  const fetchVersionByStatuses = async (statuses: Status[]) =>
    await new VersionsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken(),
      })
    ).getVersionsByStatuses({statuses});

  const fetchData = useCallback(async () => {
    const certificationStatuses = [Status.CertTestsPassed];
    const troubleshootingStatuses = [
      Status.BundleSubmitted,
      Status.BundleValidated,
      Status.ApprovedForProd,
      Status.RollbackInProgress,
    ];

    await fetchVersionByStatuses(mapStatusesToEnum([...certificationStatuses, ...troubleshootingStatuses]))
      .then(versions => {
        setVersionsInCertification(versions.filter(version => certificationStatuses.includes(version.status)));
        setVersionsTroubleshooting(versions.filter(version => troubleshootingStatuses.includes(version.status)));
      })
      .catch(e => console.log(e));
  }, [setVersionsInCertification, setVersionsTroubleshooting]);

  useEffect(() => {
    if (location.pathname === InternalUrls.CertificationEngineerDashboard) {
      navigate(InternalUrls.CertificationEngineerDashboard + '/incertification');
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    fetchData()
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  }, [fetchData, setLoading]);

  const certificationTab = () => <Certification items={versionsInCertification} />;
  const troubleshootingTab = () => <Troubleshooting items={versionsTroubleshooting} />;

  const ceTabs: Array<TabData> = [
    {
      name: 'In Certification',
      numberOfItems: versionsInCertification.length,
      content: certificationTab,
    },
    {
      name: 'Troubleshooting',
      numberOfItems: versionsTroubleshooting.length,
      content: troubleshootingTab,
    },
  ];

  const generateHeaders = () => (
    <TabList>
      {ceTabs.map(({name, icon, numberOfItems}) => {
        const tabName = (name: string, numberOfItems: number | undefined) => `${name} (${numberOfItems})`;
        return (
          <TabListItem
            key={name}
            data-name={name}
            onClick={() => navigate(convertToURL(name))}
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                navigate(convertToURL(name));
              }
            }}
          >
            <TabName
              activeTabName={tabName(location.pathname.slice(location.pathname.lastIndexOf('/') + 1), numberOfItems)}
              tabName={tabName(name, numberOfItems)}
              icon={icon}
            />
          </TabListItem>
        );
      })}
    </TabList>
  );

  return isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <div className="spark-pad-2">
      <HeaderText className="spark-mar-b-2">
        <FormattedMessage id="certificationEngineerDashboard.title" />
      </HeaderText>
      <Tabs className="spark-panel">
        <div className="spark-panel__content">{generateHeaders()}</div>
        <Routes>
          {ceTabs.map(tab => (
            <Route key={tab.name} element={tab.content()} path={convertToURL(tab.name)} />
          ))}
        </Routes>
      </Tabs>
    </div>
  );
};

export const Tabs = styled.div`
  position: relative;
`;

export default CertificationEngineerDashboard;
