const requestSunset = {
  'requestSunset.header': 'Request a Sunset',
  'requestSunset.section1.header': 'BLOCK ORDERING & HIDE THE PRODUCT',
  'requestSunset.section2.header': 'SUNSET DATE',
  'requestSunset.section3.header': 'SEND NOTIFICATION',
  'requestSunset.section1.label': 'Immediately block product from ordering and hide or pick a specific date.',
  'requestSunset.section2.label': 'Choose as soon as possible option or pick a specific date.',
  'requestSunset.section3.label1': 'Do you want to send notification to your app users?',
  'requestSunset.section3.label2':
    'We recommend sending notification at least <b>30 days</b> before the planned sunset.',
  'requestSunset.section3.label3':
    'If you would like to add an additional message to the sunset notification, please define it below.',
  'requestSunset.section3.label4': 'Additional notification content',
  'requestSunset.section2.alert': `Selecting 'As soon as possible' or a date within a few days from today, doesn't guarantee immediate action. Our team prioritizes requests based on workload. We'll handle it at our earliest convenience. In case of questions please reach out to <a href="mailto:scm-support@sabre.com">scm-support@sabre.com</a>.`,
  'requestSunset.immediately.label': 'Immediately',
  'requestSunset.date.label': 'Pick date',
  'requestSunset.asap.label': 'As soon as possible',
  'requestSunset.blockOrdering.warning': `The “Block Ordering & Hide Product” date must be earlier than or on the same day as the sunset date.`,
  'requestSunset.notification.warning':
    'The notification date must be earlier than or on the same day as the sunset date.',
  'requestSunset.pastDate.warning': `Chosen date is from past, please select today's or future date.`,
  'requestSunset.button.sendSunsetRequest': 'Send Sunset Request',
  'requestSunset.product.sunset.title': 'Sunset',
  'requestSunset.product.sunset.description':
    'Here you can initiate a sunset request for your Red App. You have the option to specify preferred dates for sunset phases. Additionally, you can include a customized message for your customers within the sunset notification.',
  'requestSunset.product.sunset.button': 'Request a Sunset',
  'requestSunset.send.errorTitle': 'Failure.',
  'requestSunset.send.successTitle': 'Sent.',
  'requestSunset.send.success': 'Sunset request has been sent to our team.',
  'requestSunset.send.error': 'There was a problem with sending your request.',
  'requestSunset.a11y.sendNotificationConfirmation': 'Choose if want to send notification',
  'requestSunset.a11y.sunsetDate': 'Choose when to perform sunset of product',
  'requestSunset.a11y.blockOrderingDate': 'Choose when to block ordering and hide the product',
  'requestSunset.a11y.sendNotificationDate': 'Choose when to send notification',
  'requestSunset.a11y.sendNotificationYes': 'Yes, send notification',
  'requestSunset.a11y.sendNotificationNo': "No, don't send notification",
  'requestSunset.confirmation.message':
    'I am aware that I am sending a sunset request for this product. Sabre will proceed with the sunset process after receiving this request and I am aware that this product, data and resources associated with it can no longer be restored and current customers will lose access to it.',
  'productRemoval.title': 'Product Removal',
  'productRemoval.description':
    'Since this product has not yet been released, you can remove it at any time. To start the removal procedure, click on the button below.',
  'productRemoval.buttonLabel': 'Remove Product',
  'productRemoval.modal.title': 'Remove Product',
  'productRemoval.modal.message':
    'Are you sure you want to remove this product? This action is irreversible. As a result, you will lose access to the product in the Admin Centre, as well as in Sabre Red 360, both on CERT and PROD. All data will be permanently deleted within a few days.',
  'productRemoval.modal.submit': 'Confirm',
};

export default requestSunset;
