import {VersionResponse} from '../../../../generated/certification';
import {useRef, useState} from 'react';
import useGetProductC from '../../../../hooks/useGetProductC';
import useSetLayoutParams from './useSetLayoutParams';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {format} from 'date-fns';
import {dateWithHour} from '@scm/components/utils/common';
import StatusIcon from '@scm/admin-centre/src/components/styledComponents/StatusIcon';
import {ProductCompatibilities} from '@scm/product-components/pages/storefrontData/productDetailsUtils';
import RowActions from './rowActions/RowActions';

const Row = ({
  bundleDetails,
  handleOpen,
  setEditedBundle,
  setEditedReleaseNotes,
  setEditedSimplifiedDescription,
  setSvsErrors,
  setEditedCleanupDates,
  setVersionFilesDetails,
}: {
  bundleDetails: VersionResponse;
  handleOpen?: (open: boolean) => void;
  setEditedBundle?: (bundle: VersionResponse) => void;
  setEditedReleaseNotes?: (bundle: VersionResponse) => void;
  setEditedSimplifiedDescription?: (bundle: VersionResponse) => void;
  setSvsErrors?: (bundle: VersionResponse) => void;
  setEditedCleanupDates?: (bundle: VersionResponse) => void;
  setVersionFilesDetails?: (bundle: VersionResponse) => void;
}) => {
  const bundleVersion = bundleDetails.name || '';
  const {redAppTypeC, redAppIdC} = useGetProductC();
  const elRef = useRef<HTMLDivElement>(null);
  const [bottomYPositionOfThreeDots, setBottomYPositionOfThreeDots] = useState(0);
  const [showOptionsUp, setShowOptionsUp] = useState(false);

  useSetLayoutParams(
    elRef,
    setBottomYPositionOfThreeDots,
    bottomYPositionOfThreeDots,
    setShowOptionsUp,
    bundleDetails,
    redAppTypeC
  );

  const bundleLink = `${InternalUrls.MyProducts}/support/${redAppIdC}/${bundleVersion}/certify`;

  return (
    <tr>
      <TableCell>{bundleDetails?.name}</TableCell>
      <TableCell>{bundleDetails.createdAt && format(new Date(bundleDetails.createdAt), dateWithHour)}</TableCell>
      <TableCell>{bundleDetails.lastUpdate && format(new Date(bundleDetails.lastUpdate), dateWithHour)}</TableCell>
      <TableCell>
        <StatusIcon statusName={bundleDetails?.status || ''} />
        {bundleDetails?.status}
      </TableCell>
      <TableCell>
        {getProductCompatibility(bundleDetails.canBeUsedInSabreRedDesktop, bundleDetails.canBeUsedInSabreRedWeb) ?? (
          <SpanWithFixedWidth />
        )}
      </TableCell>
      <TableCell>
        <div ref={elRef}>
          <RowActions
            showOptionsUp={showOptionsUp}
            bundleLink={bundleLink}
            bundleDetails={bundleDetails}
            handleOpen={handleOpen}
            setSvsErrors={setSvsErrors}
            setEditedBundle={setEditedBundle}
            setEditedReleaseNotes={setEditedReleaseNotes}
            setEditedSimplifiedDescription={setEditedSimplifiedDescription}
            setEditedCleanupDates={setEditedCleanupDates}
            setVersionFilesDetails={setVersionFilesDetails}
          />
        </div>
      </TableCell>
    </tr>
  );
};

const TableCell = styled.td<{
  isMiddleColumn?: boolean;
}>`
  border: none !important;
  border-bottom: 1px solid ${colors.grey300} !important;
  background-image: none !important;
  min-width: ${props => (props.isMiddleColumn ? '270px !important;' : '')}

  tr:hover & {
    background-color: ${colors.grey100} !important;
  }

  & input {
    width: 50% !important;
  }
`;

const SpanWithFixedWidth = styled.span`
  display: block;
  width: 32px;
`;

const getProductCompatibility = (forDesktop?: boolean, forWeb?: boolean) =>
  !!forDesktop && !!forWeb
    ? ProductCompatibilities['Desktop & Web']
    : forDesktop
    ? ProductCompatibilities['Desktop']
    : forWeb
    ? ProductCompatibilities['Web']
    : undefined;

export default Row;
