import styled from 'styled-components';
import React from 'react';
import {FormattedMessage} from 'react-intl';

const NewPartnerBadge = ({approvalDate}: {approvalDate: string}) => {
  const addMonths = (date: Date) => {
    const baseDate = date.getDate();
    date.setMonth(date.getMonth() + NEW_THRESHOLD_MONTHS);
    if (date.getDate() !== baseDate) {
      date.setDate(0);
    }
    return date;
  };
  const isNewPartner = addMonths(new Date(approvalDate)) > new Date();

  return isNewPartner ? (
    <BadgeContainer>
      <BadgeNew>
        <FormattedMessage id="common.new" />
      </BadgeNew>
    </BadgeContainer>
  ) : null;
};

const BadgeContainer = styled.div.attrs(() => ({className: 'spark-flex spark-mar-b-1'}))`
  flex-direction: column;
  width: 100%;
  margin-top: -0.5rem;
`;

const BadgeNew = styled.span.attrs(() => ({className: 'spark-badge spark-badge--sm spark-badge--neutral'}))`
  border: none;
  margin-left: auto;
`;

const NEW_THRESHOLD_MONTHS = 6;

export default NewPartnerBadge;
