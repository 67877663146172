import {Button} from '@sabre/spark-react-core';
import Modal from '@sabre/spark-react-core/modal';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext, useState} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import AssignForm from './AssignForm';
import colors from '@scm/components/assets/colors';
import {ProductsContext} from '../../../providers/ProductsPovider';

const AssignDeveloper = ({sku}: {sku: string}) => {
  const [open, setOpen] = useState(false);
  const {products} = useContext(ProductsContext);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const productName = products.filter(product => product.sku === sku)[0].name;

  const {formatMessage} = useIntl();

  return (
    <>
      <Button textOnly onClick={handleOpen}>
        <FormattedMessage id="assignSeller.assignProduct" />
      </Button>
      {createPortal(
        <AssignModal
          onClose={handleClose}
          open={open}
          title={formatMessage({id: 'assignSeller.productLevel.title'})}
          titleHeadingLevel={2}
        >
          <Description>
            <FormattedMessage
              id="assignSeller.productLevel.description"
              values={{
                strong: (word: string) => <strong>{word}</strong>,
                redAppName: productName,
              }}
            />
          </Description>
          <AssignForm handleClose={handleClose} sku={sku} />
        </AssignModal>,
        document.body
      )}
    </>
  );
};

const AssignModal = styled(Modal)`
  .spark-modal__body h2 {
    font-size: 2rem !important;
  }

  & .spark-btn[disabled] {
    color: ${colors.highlightGrey} !important;
  }
`;

const Description = styled.p`
  margin-top: 2rem;
  font-weight: 400;
`;

export default AssignDeveloper;
