import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';
import {packagesName, skipCertificateCheckName, skipCompareName, tasksName, thresholdsName} from '../SvsValues';
import {SvsExceptionsModel, Thresholds} from '../../../../generated/certification';

export const fromSvsExceptionsMapper = (res?: SvsExceptionsModel) => ({
  [tasksName]: res?.tasks ?? [],
  [packagesName]: res?.packages ?? [],
  [thresholdsName]: res?.thresholds ?? [],
  [skipCompareName]: res?.skipCompare ? Confirmation.Yes : Confirmation.No,
  [skipCertificateCheckName]: res?.skipCertificateCheck ? Confirmation.Yes : Confirmation.No,
});

function removeZeroThresholdActions(threshold: Thresholds) {
  threshold.actions = threshold.actions?.filter(action => action.threshold !== 0);
}

const removeZeroThresholds = (thresholds: Thresholds[]): Thresholds[] => {
  const filteredThresholds = thresholds.filter(threshold => threshold.threshold !== 0);
  filteredThresholds.forEach(threshold => removeZeroThresholdActions(threshold));
  return filteredThresholds;
};

export const svsExceptionsModelMapper = (res: any, acceptedThresholds: Thresholds[]): SvsExceptionsModel => ({
  thresholds: removeZeroThresholds(acceptedThresholds),
  skipCompare: res.skipCompare === Confirmation.Yes,
  skipCertificateCheck: res.skipCertificateCheck === Confirmation.Yes,
  tasks: res.tasks,
  packages: res.packages,
});
