import React from 'react';
import SortToggle from 'pages/partnerDirectory/searchResults/SortToggle';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'utils/rootReducer';
import {FormattedMessage, useIntl} from 'react-intl';
import FormattedBoldMessage from 'components/formattedMessages/FormattedBoldMessage';
import {debounce} from 'debounce';
import EventCategories from 'googleAnalytics/EventCategories';
import {useQuery} from '@apollo/client';
import {GetPartnersDocument, GetPartnersQuery, GetPartnersQueryVariables} from 'generated/graphql';
import Input from 'spark/input/Input';
import {bindActionCreators} from 'redux';
import {searchAction} from 'pages/partnerDirectory/store/actions';
import MediaQuery, {useMediaQuery} from 'react-responsive';
import styled from 'styled-components';
import SearchResultsBlock from 'pages/partnerDirectory/searchResults/SearchResultsBlock';
import getPartnersQueryVariables from 'utils/getPartnersQueryVariables';
import {maxMobileWidth, minDesktopWidth} from 'assets/deviceWidth';
import logEvent from 'googleAnalytics/logEvent';
import {FormProvider, useForm} from 'react-hook-form';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

export const fetchPartnersBatchSize = 24;

const SearchResults = () => {
  const {formatMessage} = useIntl();
  const {
    filters: {regions, categories, tiers},
    searchTerm,
    sorting,
  } = useSelector((state: AppState) => state.partnerDirectoryState);
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const boundSearchAction = bindActionCreators(searchAction, useDispatch());

  const {refetch} = useQuery<GetPartnersQuery, GetPartnersQueryVariables>(
    GetPartnersDocument,
    getPartnersQueryVariables(sorting, regions, categories, tiers, searchTerm)
  );

  const searchForPartners = (term: string) => {
    boundSearchAction(term);
    refetch({
      searchTerm: term,
      take: fetchPartnersBatchSize,
    });
  };

  const performSearch = (term: string) => {
    logEvent({
      category: EventCategories.Directory,
      action: 'Searched for',
      label: term,
    });
    searchForPartners(term);
  };

  const performSearchDebounced = debounce(performSearch, 500);
  const methods = useForm({mode: 'onBlur'});

  return (
    <FormProvider {...methods}>
      <Container className={isMobile ? 'spark-pad-1' : 'spark-pad-2 spark-mar-2'}>
        <MediaQuery maxDeviceWidth={maxMobileWidth}>
          <Input
            name="searchTerm"
            value={searchTerm}
            placeholder={formatMessage({id: 'partnerDirectoryPage.searchTooltip'})}
            label={formatMessage({id: 'partnerDirectoryPage.search'})}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              performSearchDebounced(event.target.value)
            }
          />
          <SortToggle />
          <SearchResultsBlock />
        </MediaQuery>
        <MediaQuery minDeviceWidth={minDesktopWidth}>
          <Title className="spark-alpha spark-bold" aria-level={1}>
            <FormattedBoldMessage id="partnerDirectoryPage.title" />
          </Title>
          <div className="spark-mar-b-1">
            <FormattedMessage id="partnerDirectoryPage.description" />
          </div>
          <Input
            name="searchTerm"
            value={searchTerm}
            placeholder={formatMessage({id: 'partnerDirectoryPage.searchTooltip'})}
            label={formatMessage({id: 'partnerDirectoryPage.search'})}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              performSearchDebounced(event.target.value)
            }
          />
          <SearchResultsBlock />
        </MediaQuery>
      </Container>
    </FormProvider>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Title = styled.h4`
  color: ${colors.grey700};
`;

export default SearchResults;
