import {setLocale} from 'yup';

setLocale({
  mixed: {
    default: {key: 'validation.fieldInvalid'},
    required: {key: 'validation.fieldRequired'},
  },
  string: {
    min: ({min}: {min: any}) => ({key: 'validation.fieldTooShort', value: min}),
    max: ({max}: {max: any}) => ({key: 'validation.fieldTooLong', value: max}),
    email: {key: 'validation.invalidEmail'},
    website: {key: 'validation.invalidWebsite'},
    matches: ({regex}: {regex: any}) => ({key: 'validation.fieldDoesNotMatchPattern', value: '' + regex}),
  },
});
