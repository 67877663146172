/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RegionType = {
    Apac: 'APAC',
    Emea: 'EMEA',
    Lac: 'LAC',
    Nam: 'NAM'
} as const;
export type RegionType = typeof RegionType[keyof typeof RegionType];


export function RegionTypeFromJSON(json: any): RegionType {
    return RegionTypeFromJSONTyped(json, false);
}

export function RegionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionType {
    return json as RegionType;
}

export function RegionTypeToJSON(value?: RegionType | null): any {
    return value as any;
}

