import {FormattedMessage} from 'react-intl';
import React from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import TestResult from './TestResult';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import styled from 'styled-components';
import useGetSubmit from './useGetSubmit';

export enum TestingEnvironment {
  'CERT' = 'CERT',
  'PROD' = 'PROD',
}

const BundleTesting = ({
  handleClose,
  testingEnvironment,
  productType,
  setLoadingProductDetails,
}: {
  handleClose: () => void;
  testingEnvironment: TestingEnvironment;
  productType?: string;
  setLoadingProductDetails: (value: boolean) => void;
}) => {
  const {
    getValues,
    control,
    formState: {isDirty},
  } = useForm<FieldValues>({
    mode: 'onChange',
  });

  const onSubmit = useGetSubmit({
    handleClose,
    testingEnvironment,
    productType,
    setLoadingProductDetails,
  });

  return (
    <div>
      <p>
        <FormattedMessage id={`bundleTesting.${testingEnvironment}.description`} />
      </p>
      <TestResult control={control} testingEnvironment={testingEnvironment} />
      {testingEnvironment === TestingEnvironment.CERT && (
        <p>
          <FormattedMessage id="bundleTesting.CERT.testersInfo" />
        </p>
      )}
      <ButtonContainer className="spark-flex spark-mar-t-2">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
          <FormattedMessage id="common.cancelButton" />
        </Button>
        <Button
          progress
          type="button"
          size={ButtonSize.SMALL}
          disabled={!isDirty}
          onClick={async (_, success, failure) => {
            if (success && failure) {
              await onSubmit(getValues(), success, failure);
            }
          }}
          tabIndex={0}
        >
          <FormattedMessage id="common.submitButton" />
        </Button>
      </ButtonContainer>
    </div>
  );
};

const ButtonContainer = styled.div`
  justify-content: flex-end;
`;

export default BundleTesting;
