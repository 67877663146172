import React, {useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Icon, Modal, TextInput} from '@sabre/spark-react-core';
import {ToastType, TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import styled from 'styled-components';
import CleanupDescriptionText from './CleanupDescriptionText';
import {format, isValid} from 'date-fns';
import {dateFormat} from '@scm/components/utils/common';
import {useNavigate} from 'react-router-dom';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {Table} from '@scm/product-components/pages/productDetails/productTabs/bundles/BundlesTable';
import {TableCell} from '@scm/product-components/pages/productDetails/productTabs/bundles/BundleRow';
import colors from '@scm/components/assets/colors';
import {HeaderText} from '../../components/App';
import {CleanupTasksApi, Configuration} from '@scm/product-components/generated/certification';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {openToast} from '@scm/components/messaging/openToast';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {Loading} from '@scm/components';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

type SortColumn = 'configRemovalDate' | 'versionRemovalDate' | undefined;

interface FlattenCleanupTask {
  redAppName?: string;
  version?: string;
  versionStatus?: string;
  onHold?: string;
  withNotification: Confirmation;
  configRemovalDate?: Date;
  configRemoved: Confirmation;
  versionRemovalDate?: Date;
  redAppId?: string;
}

const fetchCleanupTasks = async () => {
  return new CleanupTasksApi(
    new Configuration({basePath: certificationApiBaseLink, accessToken: getAccessToken() || ''})
  ).getCleanupTasks();
};

const Cleanup = () => {
  const writeNumberOfItems = (items: number) => ` (${items})`;
  const navigate = useNavigate();
  const {formatMessage} = useIntl();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [sortConfigRemovalDateAsc, setSortConfigRemovalDateAsc] = useState(false);
  const [sortVersionRemovalDateAsc, setSortVersionRemovalDateAsc] = useState(false);
  const [sortColumn, setSortColumn] = useState<SortColumn>();
  const [flattenCleanupTasks, setFlattenCleanupTasks] = useState<FlattenCleanupTask[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const extendedNavigate = (item: FlattenCleanupTask, newTab = false) => {
    const link = `${InternalUrls.MyProducts}/support/${item.redAppId}/${item.version}/cleanup`;
    const externalLink = `${window.location.origin}/admin-centre${link}`;
    if (newTab) {
      window.open(externalLink, '_blank');
    } else {
      navigate(link);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchCleanupTasks()
        .then(cleanupTasks => {
          setFlattenCleanupTasks(
            cleanupTasks.map(cleanupTask => {
              return {
                redAppName: cleanupTask.redAppName,
                version: cleanupTask.name,
                versionStatus: cleanupTask.status,
                onHold: cleanupTask.cleanup?.onHold ? 'On Hold' : '',
                withNotification: cleanupTask.cleanup?.notifyBuyers ? Confirmation.Yes : Confirmation.No,
                configRemovalDate: cleanupTask.cleanup?.sabreRedConfigurationRemovalDate,
                configRemoved: cleanupTask.cleanup?.sabreRedConfigurationRemoved ? Confirmation.Yes : Confirmation.No,
                versionRemovalDate: cleanupTask.cleanup?.versionRemovalDate,
                redAppId: cleanupTask.redAppId,
              };
            })
          );
        })
        .catch(() => {
          openToast(
            formatMessage({id: 'cleanupDashboard.error.title'}),
            formatMessage({id: 'cleanupDashboard.error.description'}),
            ToastType.WARNING,
            'spark-icon-alert-triangle'
          );
          navigate(InternalUrls.Cleanup);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isLoading, navigate, formatMessage]);

  const sortedValues = useMemo(() => {
    const sortCopy = [...flattenCleanupTasks];
    if (sortColumn === 'configRemovalDate') {
      if (!sortConfigRemovalDateAsc) {
        return sortCopy.sort((a, b) => +new Date(a.configRemovalDate ?? 0) - +new Date(b.configRemovalDate ?? 0));
      } else {
        return sortCopy.sort((a, b) => +new Date(b.configRemovalDate ?? 0) - +new Date(a.configRemovalDate ?? 0));
      }
    } else if (sortColumn === 'versionRemovalDate') {
      if (!sortVersionRemovalDateAsc) {
        return sortCopy.sort((a, b) => +new Date(a.versionRemovalDate ?? 0) - +new Date(b.versionRemovalDate ?? 0));
      } else {
        return sortCopy.sort((a, b) => +new Date(b.versionRemovalDate ?? 0) - +new Date(a.versionRemovalDate ?? 0));
      }
    }

    return sortCopy;
  }, [sortColumn, sortConfigRemovalDateAsc, sortVersionRemovalDateAsc, flattenCleanupTasks]);

  const createTableHeader = () => (
    <thead>
      <tr>
        <TableColumnHeader key="redAppName">
          <strong>
            <FormattedMessage id="cleanupDashboard.redAppName" />
          </strong>
        </TableColumnHeader>
        <TableColumnHeader key="version">
          <strong>
            <FormattedMessage id="cleanupDashboard.version" />
          </strong>
        </TableColumnHeader>
        <TableColumnHeader key="versionStatus">
          <strong>
            <FormattedMessage id="cleanupDashboard.versionStatus" />
          </strong>
        </TableColumnHeader>
        <TableColumnHeader key="onHold">
          <strong>
            <FormattedMessage id="cleanupDashboard.onHold" />
          </strong>
        </TableColumnHeader>
        <TableColumnHeader key="withNotification">
          <strong>
            <FormattedMessage id="cleanupDashboard.withNotification" />
          </strong>
        </TableColumnHeader>
        <TableColumnHeader
          key="configRemovalDate"
          data-sort="desc"
          sortAsc={sortConfigRemovalDateAsc}
          onClick={() => sortByDate('configRemovalDate')}
        >
          <strong>
            <FormattedMessage id="cleanupDashboard.configRemovalDate" />
          </strong>
        </TableColumnHeader>
        <TableColumnHeader key="configRemoved">
          <strong>
            <FormattedMessage id="cleanupDashboard.configRemoved" />
          </strong>
        </TableColumnHeader>
        <TableColumnHeader
          key="versionRemovalDate"
          data-sort="desc"
          sortAsc={sortVersionRemovalDateAsc}
          onClick={() => sortByDate('versionRemovalDate')}
        >
          <strong>
            <FormattedMessage id="cleanupDashboard.versionRemovalDate" />
          </strong>
        </TableColumnHeader>
      </tr>
    </thead>
  );

  const sortByDate = (columnHeaderName: SortColumn) => {
    setSortColumn(columnHeaderName);
    if (columnHeaderName === 'configRemovalDate') {
      setSortConfigRemovalDateAsc(prevState => !prevState);
    } else if (columnHeaderName === 'versionRemovalDate') {
      setSortVersionRemovalDateAsc(prevState => !prevState);
    }
  };

  const createTableBody = () => (
    <tbody>
      {sortedValues
        .filter(item => searchPhrase === '' || JSON.stringify(item).toLowerCase().includes(searchPhrase.toLowerCase()))
        .map((item, index) => (
          <TableRow
            key={index}
            onClick={event => extendedNavigate(item, event.ctrlKey)}
            tabIndex={0}
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                extendedNavigate(item);
              }
            }}
          >
            {createTableRowContent(item)}
          </TableRow>
        ))}
    </tbody>
  );

  const createTableRowContent = (flattenCleanupTask: FlattenCleanupTask) => {
    const {redAppId, ...flattenCleanupTaskCopy} = flattenCleanupTask;
    return Object.values(flattenCleanupTaskCopy).map((value, index) => (
      <TableCell key={index}>
        {isValid(value as Date) ? <time dateTime={value as string}>{format(new Date(value), dateFormat)}</time> : value}
      </TableCell>
    ));
  };

  return isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <div className="spark-pad-2">
      <Header className="spark-flex">
        <HeaderText className="spark-mar-b-2">
          <FormattedMessage id="cleanupDashboard.title" />
          {writeNumberOfItems(sortedValues.length)}
        </HeaderText>
        <Button
          icon="spark-icon-question-mark-circle"
          onClick={handleOpen}
          ariaLabel="question mark circle"
          tooltip={formatMessage({id: 'cleanupDashboard.description.tooltip'})}
          tooltipDirectionX={TooltipDirectionX.RIGHT}
          tooltipDirectionY={TooltipDirectionY.BOTTOM}
          id="cleanupDescription"
          className="spark-icon--fill"
        />
      </Header>
      <div className="col-xs-4 spark-pad-l-0">
        <TextInput
          name="searchName"
          label={formatMessage({id: 'cleanupDashboard.searchLabel'})}
          placeHolder={formatMessage({id: 'cleanupDashboard.placeholder'})}
          value={searchPhrase}
          onChange={(evt, value) => setSearchPhrase(value)}
          className="spark-mar-b-2 spark-mar-l-0"
        >
          <Icon name="search" />
        </TextInput>
      </div>
      <Table className="spark-table">
        <div className="spark-table__scroll">
          <TableContent role="presentation" className="spark-mar-b-1">
            {createTableHeader()}
            {createTableBody()}
          </TableContent>
        </div>
      </Table>
      <Modal onClose={handleClose} open={open} title="Cleanup" titleHeadingLevel={2}>
        <CleanupDescriptionText />
      </Modal>
    </div>
  );
};

const Header = styled.div`
  justify-content: space-between;

  & button {
    height: 4rem;
    font-size: 3rem !important;
  }
`;

export const TableRow = styled.tr`
  &:hover td {
    background-color: ${colors.grey100} !important;
  }
`;

const TableContent = styled.table`
  & th {
    white-space: normal !important;
    padding: 1rem !important;
  }

  & * {
    font-size: 1rem !important;
    text-align: center !important;
  }
`;

export const TableColumnHeader = styled.th<{sortAsc?: boolean}>`
  &&& {
    font-size: 12px;
    font-weight: 400;
    background-color: ${colors.white} !important;
    color: ${colors.black} !important;
    border: none !important;
    border-bottom: 1px solid ${colors.grey300} !important;
  }

  &::after {
    margin-left: 0.5rem;
    vertical-align: 1px !important;
    transition: 0.3s;
    transform: rotate(${props => (props.sortAsc ? '180deg' : '0deg')}) !important;
  }
`;

export default Cleanup;
