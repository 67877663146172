import {FormattedMessage, useIntl} from 'react-intl';
import moment from 'moment';
import React from 'react';
import {FooterLinkListItem, LinkWithIcon} from './FooterLinkListItem';

export const Footer = ({
  externalUrls,
  terms,
  handleTermsAndConditionsOpen,
}: {
  externalUrls: {
    Sabre: string;
    PrivacyPolicy: string;
    SabreTwitter: string;
    SabreFacebook: string;
    SabreLinkedIn: string;
    SabreYoutube: string;
  };
  terms?: React.ReactNode;
  handleTermsAndConditionsOpen: () => void;
}) => {
  const {formatMessage} = useIntl();

  const currentYear = moment().year();

  const privacyPolicy = formatMessage({id: 'footer.privacyPolicy'});
  const sabre = formatMessage({id: 'footer.sabre'});
  const twitter = formatMessage({id: 'footer.twitter'});
  const facebook = formatMessage({id: 'footer.facebook'});
  const linkedIn = formatMessage({id: 'footer.linkedIn'});
  const youtube = formatMessage({id: 'footer.youtube'});

  const {Sabre, PrivacyPolicy, SabreTwitter, SabreFacebook, SabreLinkedIn, SabreYoutube} = externalUrls;

  return (
    <div id="footerContainer" className="spark-content__wrapper--sticky-footer">
      {terms}
      <footer className="spark-footer">
        <div className="container">
          <LinkWithIcon to={Sabre} className="spark-footer__logo spark-hidden--lte-sm spark-pad-l-1" ariaLabel={sabre}>
            <i className="spark-logo spark-logo--sabre spark-logo--sm" />
          </LinkWithIcon>
          <div className="spark-footer__content">
            <div className="spark-footer__copyright">{`© ${currentYear} Sabre`}</div>
            <ul className="spark-footer__list">
              <FooterLinkListItem
                to={PrivacyPolicy}
                ariaLabel={privacyPolicy}
                title={privacyPolicy}
                label={privacyPolicy}
              />
            </ul>
            <ul className="spark-footer__list">
              <li className="spark-footer__list-item">
                <span
                  tabIndex={0}
                  className="spark-link spark-footer__list-link"
                  onClick={handleTermsAndConditionsOpen}
                  onKeyDown={event => event.code === 'Space' && handleTermsAndConditionsOpen()}
                  role="button"
                >
                  <FormattedMessage id="footer.termsAndConditions" />
                </span>
              </li>
            </ul>
          </div>
          <ul className="spark-footer__list spark-footer__list--right spark-footer__list--icons">
            <FooterLinkListItem
              to={SabreTwitter}
              ariaLabel={twitter}
              title={twitter}
              icon="spark-icon-social-twitter"
            />
            <FooterLinkListItem
              to={SabreFacebook}
              ariaLabel={facebook}
              title={facebook}
              icon="spark-icon-social-facebook"
            />
            <FooterLinkListItem
              to={SabreLinkedIn}
              ariaLabel={linkedIn}
              title={linkedIn}
              icon="spark-icon-social-linkedin"
            />
            <FooterLinkListItem
              to={SabreYoutube}
              ariaLabel={youtube}
              title={youtube}
              icon="spark-icon-social-youtube"
            />
          </ul>
        </div>
      </footer>
    </div>
  );
};
