import {
  commentName,
  endDateName,
  endHourName,
  environmentName,
  FreezeCalendarValues,
  startDateName,
  startHourName,
} from './FreezeCalendarValues';

export const initialValues: FreezeCalendarValues = {
  [environmentName]: [],
  [startDateName]: '',
  [startHourName]: '',
  [endDateName]: '',
  [endHourName]: '',
  [commentName]: '',
};
