import React from 'react';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import Header from './Header';
import {useNavigate} from 'react-router-dom';
import useGetIfHasPricing from '../../../../hooks/useGetIfHasPricing';
import {useGetSections} from '../sections';

const slash = '/';

const Headers = () => {
  const navigate = useNavigate();
  const pricingName = 'Pricing';
  const activeTabName = location.pathname.slice(location.pathname.lastIndexOf(slash) + 1);
  const isPricingVisible = useGetIfHasPricing();
  const sections = useGetSections();

  return (
    <TabList>
      {sections
        .filter(tab => !tab.hideElement)
        .map(({name, icon}) =>
          name === pricingName && !isPricingVisible
            ? undefined
            : name && (
                <TabListItem
                  key={name}
                  data-name={name}
                  onClick={() => navigate(convertToURL(name))}
                  onKeyDown={evt => {
                    if (evt.key === 'Enter') {
                      navigate(convertToURL(name));
                    }
                  }}
                >
                  <Header activeTabName={activeTabName} tabName={name} icon={icon} />
                </TabListItem>
              )
        )}
    </TabList>
  );
};

export const TabList = styled.ul`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const TabListItem = styled.li`
  list-style-type: none;
  margin: 0;
  min-width: 10rem;
  text-align: center;
  @media (min-width: ${deviceWidths.xs}px) {
    float: left;
  }
`;

export const convertToURL = (str: string) => str.toLowerCase().replaceAll(' ', '');

export default Headers;
