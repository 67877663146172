import {FormattedMessage} from 'react-intl';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Developer as DeveloperValues} from '../../../../generated/management';
import RemoveDeveloperModal from './RemoveDeveloperModal';

const ProductLevelActionMenu = ({
  developer,
  sku,
  productName,
}: {
  developer: DeveloperValues;
  sku: string;
  productName: string;
}) => {
  const [removeDeveloperModalOpen, setRemoveDeveloperModalOpen] = useState(false);
  const handleRemoveDeveloperModalOpen = (): void => setRemoveDeveloperModalOpen(true);

  return (
    <ul className="spark-popover__list spark-pad-1">
      <li className="spark-popover__list-item">
        <ActionItem
          className="spark-popover__list-item spark-popover__list-link"
          onClick={handleRemoveDeveloperModalOpen}
        >
          <FormattedMessage id="companyManagement.action.remove" />
        </ActionItem>
        <RemoveDeveloperModal
          sku={sku}
          pcc={developer.pcc}
          epr={developer.epr}
          firstName={developer.firstName || ''}
          lastName={developer.lastName || ''}
          productName={productName}
          open={removeDeveloperModalOpen}
          setOpen={setRemoveDeveloperModalOpen}
        />
      </li>
    </ul>
  );
};

const ActionItem = styled.li`
  cursor: pointer;
`;

export default ProductLevelActionMenu;
