const svs = {
  'svs.data.title': 'SVS Data',
  'svs.servicesUsage.title': 'Service Usage',
  'svs.servicesUsage.serviceContent': 'Threshold: {threshold}, Metric: {metric}',
  'svs.servicesUsage.serviceContent.exceeded': ', exceeded: {exceeded}',
  'svs.servicesUsage.thresholdExceptionContent': 'Threshold exception: <strong>{count}</strong> has been accepted',
  'svs.servicesUsage.message': 'Services are not used or redapp task is excluded from validation',
  'svs.servicesUsage.actionsTitle': 'Actions:',
  'svs.newThreshold.label': 'New Threshold',
  'svs.taskExceptions.title': 'Task Exceptions',
  'svs.taskExceptions.label': 'Select tasks you want to exclude from SVS validation',
  'svs.taskExceptions.selectTasks.label': 'Select Tasks',
  'svs.packageExceptions.title': 'Package Exceptions',
  'svs.packageExceptions.label': 'Packages excluded from SVS validation',
  'svs.packageExceptions.additionalText': 'Packages selected below will be excluded from validation',
  'svs.packageExceptions.placeholder': 'Enter package name...',
  'svs.skipCertificateCheck.title': 'Skip certificate check',
  'svs.compareRequest.title': 'Compare Request',
  'svs.skipCertificateCheck.label': 'Skip certificate check',
  'svs.compareRequest.label': 'Skip compare request',
  'svs.compareRequest.additionalText': 'Compare request is sent only for versions with patch distribution',
  'svs.skipCertificateCheck.additionalText':
    'Certificate is not validate for PF Keys and Scribe Script apps by default',
  'svs.validationResponse.title': 'SVS Validation Response',
  'svs.comparisonResponse.title': 'SVS Comparison Response',
  'svs.hint.title': 'SVS and SVS exceptions',
  'svs.hint.intro': 'SVS is an external service used for new Red App version bundle validation.',
  'svs.hint.approval':
    'Adding/modifying SVS exceptions is internal use only feature and <strong>you should always have approval of SDK/SRW team</strong> before modifying any exceptions.',
  'svs.hint.exceptions':
    'There are 3 types of exceptions that can be added to SVS configuration before re-validation – threshold exception, task exceptions and package exception. When new version is added, all exceptions are automatically inherited from previous one, so there is no need for manually adding them again with every new version.',
  'svs.hint.exceptionsList1':
    'Under services usage header you can find information about all exceeded thresholds, for all of them you can define new value. It has to be be higher or equal to already defined.',
  'svs.hint.exceptionsList2':
    'Under Task Exception header you can select/deselect tasks exception. Already selected tasks are visible in the box on the right side.',
  'svs.hint.exceptionsList3':
    'Under package exceptions you can add or delete package exceptions. If app uses forbidden packages panel with package list will show up. Already added exceptions are visible in list.',
  'svs.hint.addExceptions':
    'To add exceptions and automatically resend bundle to SVS for re-validation, click Approve and send to SVS button on the bottom of the form.',
  'svs.save': 'Save SVS Exceptions',
  'svs.saveBundle': 'Send bundle to SVS',
  'svs.toast.save.success': 'SVS Exceptions saved successfully.',
  'svs.toast.send.success': 'Version resent to SVS successfully. Please wait for it to be processed.',
  'svs.toast.title': 'SVS Exceptions',
  'svs.error.title': 'Something went wrong',
  'svs.error.message': 'There is something wrong with SVS. Please try again or contact {email}.',
  'svs.save.error.message': 'There is something wrong with saving SVS exceptions. Please try again.',
  'svs.saveSvsExceptionsModal.description':
    'Be aware that by saving SVS Exceptions you are also accepting all the thresholds that were originally exceeded',
  'svs.saveSvsExceptionsModal.save': 'Save',
  'svs.saveSvsExceptionsModal.cancel': 'Cancel',
};

export default svs;
