import React, {ReactElement, useContext, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ExternalLink, Star} from '@scm/components';
import {Controller} from 'react-hook-form';
import {Checkbox, RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {ProposalContext} from '../../../../ProposalForm';
import {
  bargainFinderMaxName,
  Confirmation,
  externalServicesDescriptionName,
  externalServicesName,
  hostCommandsDescriptionName,
  newSabreRedTechnologiesDescriptionName,
  newSabreRedTechnologiesName,
  sabreServicesName,
  utilizedMethodName,
} from '../../ServiceAndSecurityValues';
import {TextArea} from '../../../definitionPage/definitionComponents/Description';
import {serviceAndSecurityValuesName} from '../../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import ExternalUrls from '@scm/components/utils/externalUrls';

const Services = () => {
  const {formatMessage} = useIntl();
  const {control, watch, setValue, errors} = useContext(ProposalContext);
  const descriptionMaxLength = 250;
  const bargainFinderMaxId = 'bargainFinderMax';
  const sabreServicesValue = watch(createNestedObjectName(sabreServicesName));

  useEffect(() => {
    if (!sabreServicesValue) {
      setValue(createNestedObjectName(bargainFinderMaxName), '', {shouldValidate: true});
    }
  }, [sabreServicesValue, setValue]);

  return (
    <>
      <p className="spark-mar-t-1 spark-mar-b-1 spark-mar-r-2">
        <FormattedMessage id="serviceAndSecurity.services.label" />
      </p>
      <Controller
        name={createNestedObjectName(sabreServicesName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (event.target.checked) {
                setValue(createNestedObjectName(hostCommandsDescriptionName), '', {shouldValidate: true});
                setValue(createNestedObjectName(bargainFinderMaxName), Confirmation.No, {shouldValidate: true});
              } else {
                setValue(createNestedObjectName(bargainFinderMaxName), '', {shouldValidate: true});
              }
            }}
            checked={value}
            label={formatMessage({id: 'serviceAndSecurity.servicesCheckbox.label'})}
          />
        )}
      />
      {sabreServicesValue && (
        <div className="spark-pad-l-2">
          <Controller
            name={createNestedObjectName(hostCommandsDescriptionName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.hostCommandsDescription.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1"
                status={
                  watch(createNestedObjectName(hostCommandsDescriptionName)).length &&
                  errors[serviceAndSecurityValuesName]?.[hostCommandsDescriptionName]
                    ? MessageStatus.ERROR
                    : undefined
                }
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[hostCommandsDescriptionName] &&
                  errors[serviceAndSecurityValuesName]?.[hostCommandsDescriptionName]?.message
                }
              />
            )}
          />
          <p className="spark-mar-b-1 spark-mar-r-2 spark-mar-t-1" id={bargainFinderMaxId}>
            <FormattedMessage id="serviceAndSecurity.bargainFinderMax.label" />
            <Star />
          </p>
          <Controller
            name={createNestedObjectName(bargainFinderMaxName)}
            control={control}
            render={({field: {onChange, name, value}}): ReactElement => (
              <RadioButtonGroup
                name={name}
                value={value}
                onChange={onChange}
                aria-labelledby={bargainFinderMaxId}
                className="spark-mar-b-1"
              >
                {Object.values(Confirmation).map(value => (
                  <RadioButton
                    key={value}
                    label={value}
                    value={value}
                    aria-label={formatMessage({id: 'serviceAndSecurity.confirmationButtons.label'}, {value})}
                  />
                ))}
              </RadioButtonGroup>
            )}
          />
        </div>
      )}
      <Controller
        name={createNestedObjectName(externalServicesName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (value) {
                setValue(createNestedObjectName(externalServicesDescriptionName), '', {shouldValidate: true});
                setValue(createNestedObjectName(utilizedMethodName), '', {shouldValidate: true});
              }
            }}
            checked={value}
            label={formatMessage({id: 'serviceAndSecurity.externalServices.label'})}
          />
        )}
      />
      {watch(createNestedObjectName(externalServicesName)) && (
        <div className="spark-pad-l-2">
          <Controller
            name={createNestedObjectName(externalServicesDescriptionName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.externalServicesDescription.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1"
                status={
                  errors[serviceAndSecurityValuesName]?.[externalServicesDescriptionName]
                    ? MessageStatus.ERROR
                    : undefined
                }
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[externalServicesDescriptionName] &&
                  errors[serviceAndSecurityValuesName]?.[externalServicesDescriptionName]?.message
                }
              />
            )}
          />
          <Controller
            name={createNestedObjectName(utilizedMethodName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.utilizedMethod.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1"
                status={errors[serviceAndSecurityValuesName]?.[utilizedMethodName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[utilizedMethodName] &&
                  errors[serviceAndSecurityValuesName]?.[utilizedMethodName]?.message
                }
              />
            )}
          />
        </div>
      )}
      <p className="spark-mar-t-2 spark-mar-b-0 spark-mar-r-2">
        <FormattedMessage id="serviceAndSecurity.sabreRed360.label" />
      </p>
      <Controller
        name={createNestedObjectName(newSabreRedTechnologiesName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (value) {
                setValue(createNestedObjectName(newSabreRedTechnologiesDescriptionName), '', {shouldValidate: true});
              }
            }}
            checked={value}
            label={formatMessage(
              {id: 'serviceAndSecurity.newSabreRedTechnologies.label'},
              {
                link: word => <ExternalLink to={ExternalUrls.DeveloperSolutionHowTo}>{word}</ExternalLink>,
              }
            )}
            className="spark-mar-t-1 spark-mar-b-2"
          />
        )}
      />
      {watch(createNestedObjectName(newSabreRedTechnologiesName)) && (
        <div className="spark-pad-l-2">
          <Controller
            name={createNestedObjectName(newSabreRedTechnologiesDescriptionName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.newSabreRedTechnologiesDescription.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1"
                status={
                  errors[serviceAndSecurityValuesName]?.[newSabreRedTechnologiesDescriptionName]
                    ? MessageStatus.ERROR
                    : undefined
                }
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[newSabreRedTechnologiesDescriptionName] &&
                  errors[serviceAndSecurityValuesName]?.[newSabreRedTechnologiesDescriptionName]?.message
                }
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export const createNestedObjectName = (child: string, parent: string = serviceAndSecurityValuesName) =>
  `${parent}.${child}`;

export default Services;
