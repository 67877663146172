import React, {useContext, useEffect, useRef, useState} from 'react';
import SparkHeader from '@sabre/spark/js/src/components/header';
import {FormattedMessage, useIntl} from 'react-intl';
import HeaderMenu from './dropdown/HeaderMenu';
import {useMediaQuery} from 'react-responsive';
import {AvatarLabel, AvatarText, SabreLogo, SparkPopoverContainer} from './styledComponents/styledComponents';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';
import {AuthenticationContext} from '@scm/authentication/provider/AuthenticationProvider';
import jwt_decode from 'jwt-decode';
import styled from 'styled-components';
import useCloseExpandPanel from './utils/useCloseExpandPanel';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {Popover} from '@sabre/spark-react-core';
import ExpandContent from './ExpandContent';

const Header = () => {
  const headerRef = useRef(null);
  const [closeHamburgerMenuRef, setCloseHamburgerMenuRef] = useState<() => void>(function () {
    // do nothing.
  });
  const {accessToken} = useContext(AuthenticationContext);
  useEffect(() => {
    const sparkHeader = new SparkHeader(headerRef.current, {fixed: false});
    setCloseHamburgerMenuRef(() => () => sparkHeader.closeHamburgerMenu());
    return () => {
      sparkHeader.remove();
    };
  }, [headerRef]);

  const {formatMessage} = useIntl();
  const viewNavigationLabel = formatMessage({id: 'header.viewNavigation'});
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const [isExpandPanelVisible, setExpandPanelVisible] = useState(false);
  const {sc_code, first_name, last_name, sub} = accessToken
    ? jwt_decode<{sc_code: string; first_name: string; last_name: string; sub: string}>(accessToken)
    : {
        sc_code: '',
        first_name: '',
        last_name: '',
        sub: '',
      };
  const spaceSign = '\u00A0';
  const getPccepr = () => sub.substring(sub.indexOf('/') + 1);
  const getUserDataHeader = () => `${first_name} ${last_name} ${spaceSign.repeat(2)} ${getPccepr()} (${sc_code})`;

  useCloseExpandPanel(isExpandPanelVisible, setExpandPanelVisible);

  return (
    <HeaderContainer
      id="headerContainer"
      className="spark-header spark-header--visible spark-header--overflow-checked"
      ref={headerRef}
      data-toast="header"
    >
      <nav className="spark-header__nav" role="navigation">
        <button
          className="spark-menu__toggle spark-header__toggle"
          aria-label={viewNavigationLabel}
          title={viewNavigationLabel}
        >
          <i className="spark-icon--fill spark-icon-menu-hamburger spark-icon--md" />
        </button>
        <SabreLogo className="spark-header__logo">
          <MainIcon className="spark-logo spark-logo--sabre spark-logo--sm">
            <FormattedMessage id="common.sabre" />
          </MainIcon>
        </SabreLogo>
        {isMobile && <span className="spark-header__title" />}
        <HeaderMenu closeHamburgerMenu={closeHamburgerMenuRef} />
        {accessToken && (
          <SparkPopoverContainer className="spark-header__sign-in spark-popover">
            <Popover
              autoFlip={false}
              anchorX={TooltipDirectionX.LEFT}
              anchorY={TooltipDirectionY.BOTTOM}
              className="spark-pad-0"
              toggleEl={
                <a
                  href={InternalUrls.Home}
                  className="spark-menu__list-link spark-menu__list-link--avatar spark-popover__toggle"
                  aria-label={sc_code}
                  onClick={event => event.preventDefault()}
                >
                  <AvatarLabel>{getUserDataHeader()}</AvatarLabel>
                  <AvatarText className="spark-avatar spark-avatar--text">
                    {createShortName(first_name, last_name)}
                  </AvatarText>
                </a>
              }
            >
              <ExpandContent />
            </Popover>
          </SparkPopoverContainer>
        )}
      </nav>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  & .spark-toast {
    width: 100% !important;
  }

  & .toast-email {
    color: inherit;
    text-decoration: underline;
  }
`;

export const createShortName = (name: string, lastName: string) =>
  `${name.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;

const MainIcon = styled.i`
  font-size: 2.5rem;
`;

export default Header;
