import {BreadcrumbRowWrapper} from '@scm/components';
import BreadcrumbLink from '@scm/admin-centre/src/components/BreadcrumbLink';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import React, {useContext} from 'react';
import {useParams} from 'react-router-dom';
import {VersionContext} from '../provider/VersionProvider';
import {CcrzEProductCSObjectRedAppTypeCEnum} from '@scm/product-components/generated/product';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import colors from '@scm/components/assets/colors';

const VersionHeader = () => {
  const {appName, version} = useParams();
  const productUrl = `${InternalUrls.MyProducts}/${appName}/versions`;
  const {product} = useContext(VersionContext);
  const name = product.ccrzEProductC?.name;
  const logo = product.ccrzEProductC?.logoURIC;
  const provisioningType = product.ccrzEProductC?.provisioningTypeC || '';
  const productTypeValue = product.ccrzEProductC?.redAppTypeC || '';

  const createAppType = () => {
    switch (productTypeValue) {
      case CcrzEProductCSObjectRedAppTypeCEnum.Regular:
        return `${provisioningType[0]?.toUpperCase() + provisioningType?.slice(1)} Red App`;
      case CcrzEProductCSObjectRedAppTypeCEnum.PfKeys:
        return 'PF Keys';
      case CcrzEProductCSObjectRedAppTypeCEnum.Scribe:
        return `${productTypeValue} Scripts`;
    }
  };

  return (
    <>
      <BreadcrumbRowWrapper>
        <BreadcrumbLink intlId="common.breadcrumb.myProducts" href={InternalUrls.MyProducts} />
        <BreadcrumbLink text={name} href={productUrl} />
        <BreadcrumbLink text={version} />
      </BreadcrumbRowWrapper>
      <ProductHeader className="spark-flex spark-pad-l-2 spark-pad-r-2 spark-pad-t-2">
        <IconContainer>
          {logo ? <AppIcon url={logo} mainIcon /> : <BlankIcon />}
          <Text className="spark-flex">
            <h1>{name}</h1>
            <p>{createAppType()}</p>
          </Text>
        </IconContainer>
      </ProductHeader>
    </>
  );
};

export const ProductHeader = styled.div`
  justify-content: space-between;
  @media (max-width: ${deviceWidths.sm}px) {
    flex-wrap: wrap;
  }
  margin-bottom: 2rem;
`;

export const IconContainer = styled.div`
  display: flex;
  height: 80px;
  @media (max-width: ${deviceWidths.xs}px) {
    transform: scale(0.8) translateX(-4rem);
  }
`;

export const AppIcon = styled.div.attrs(({url, mainIcon}: {url: string; mainIcon?: boolean}) => ({
  url,
  mainIcon,
}))`
  width: 80px;
  flex-shrink: 0;
  background-color: ${colors.transparent};
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-clip: content-box;
`;

export const Text = styled.div`
  padding: 0.5rem;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  & h1 {
    margin-bottom: 0;
  }
`;

export const ProductInfo = styled.div`
  background-color: ${colors.grey100};
  justify-content: space-between;
  @media (max-width: ${deviceWidths.sm}px) {
    flex-direction: column;
    & > div {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
`;

export const BlankIcon = styled.div`
  width: 80px;
  flex-shrink: 0;
  padding: 0.5rem;
  border-radius: 25%;
  background-color: ${colors.grey300};
  border: 1px solid ${colors.disabledGrey};
`;

export default VersionHeader;
