const joinUsPage = {
  'joinUsPage.title': 'Join Us',
  'joinUsPage.contentBox':
    '<strong>The Sabre Developer Program</strong> aims to enable third-party companies to develop and deploy solutions for Sabre Connected travel agencies or corporate customers while utilizing the Sabre Platform (Sabre API). However, authorization and prior approval must be given by Sabre. Receive dedicated support, access to our Sabre Dev Studio portfolio and the opportunity to promote your products to our agency customers, all as a partner in Sabre Developer Partner Program.',
  'joinUsPage.step1': 'General Information',
  'joinUsPage.step2': 'Terms & Conditions',
  'joinUsPage.step3': 'Pricing Details',
  'joinUsPage.step4': 'Review & Submit',
  'joinUsPage.form.button': 'Continue',
  'joinUsPage.form.endButton': 'Submit',
  'joinUsPage.form.backButton': 'Back',
  'joinUsPage.form.contactDetails.title': 'General Information',
  'joinUsPage.form.contactDetails.description':
    'Please ensure you have a potential Sabre customer using your solution before filling out the form below. Sabre may not accept applications without a potential Sabre customer.',
  'joinUsPage.form.contactDetails.firstName.placeholder': 'First Name',
  'joinUsPage.form.sabreAccountInfo.label': 'Do you currently have a Sabre EPR or PCC?',
  'joinUsPage.form.sabreAccountInfo.epr.label': 'EPR',
  'joinUsPage.form.sabreAccountInfo.pcc.label': 'PCC',
  'joinUsPage.form.contactDetails.firstName.label': 'First Name',
  'joinUsPage.form.contactDetails.lastName.placeholder': 'Last Name',
  'joinUsPage.form.contactDetails.lastName.label': 'Last Name',
  'joinUsPage.form.contactDetails.companyTitle.placeholder': 'Title In Company',
  'joinUsPage.form.contactDetails.companyTitle.label': 'Title In Company',
  'joinUsPage.form.contactDetails.phone.placeholder': 'Please insert the international dialling code',
  'joinUsPage.form.contactDetails.phone.label': 'Phone Number',
  'joinUsPage.form.contactDetails.email.placeholder': 'name@domain.com',
  'joinUsPage.form.contactDetails.email.label': 'Email Address',
  'joinUsPage.form.contactDetails.fullCompanyName.placeholder': 'Full Company Name',
  'joinUsPage.form.contactDetails.fullCompanyName.label': 'Full Company Name',
  'joinUsPage.form.registeredCompanyAddress.title': 'Company Address',
  'joinUsPage.form.companyInformation.title': 'Company Information',
  'joinUsPage.form.contactDetails.addressLine1.placeholder': 'Line 1',
  'joinUsPage.form.contactDetails.addressLine1.label': 'Line 1',
  'joinUsPage.form.contactDetails.line1.label': 'Line 1',
  'joinUsPage.form.contactDetails.line2.label': 'Line 2',
  'joinUsPage.form.contactDetails.addressLine2.placeholder': 'Line 2',
  'joinUsPage.form.contactDetails.addressLine2.label': 'Line 2',
  'joinUsPage.form.contactDetails.countryCode.label': 'Country code',
  'joinUsPage.form.contactDetails.city.placeholder': 'City',
  'joinUsPage.form.contactDetails.city.label': 'City',
  'joinUsPage.form.contactDetails.stateProvinceName.placeholder': 'State/Province',
  'joinUsPage.form.contactDetails.stateProvinceName.label': 'State/Province',
  'joinUsPage.form.contactDetails.province.label': 'State/Province',
  'joinUsPage.form.contactDetails.postalCode.placeholder': 'ZIP/Postal Code',
  'joinUsPage.form.contactDetails.postalCode.label': 'ZIP/Postal Code',
  'joinUsPage.form.contactDetails.country.label': 'Country',
  'joinUsPage.form.contactDetails.url.placeholder': 'Company URL',
  'joinUsPage.form.contactDetails.url.label': 'Company URL',
  'joinUsPage.form.contactDetails.staff.label': 'Approximately how many people are employed at your company?',
  'joinUsPage.form.contactDetails.companyYears.label': 'How many years has your company been in business?',
  'joinUsPage.form.companyLocation.title': 'Where is your company located?',
  'joinUsPage.form.contactDetails.countryLocated.label': 'Country',
  'joinUsPage.form.contactDetails.provinceLocated.placeholder': 'State/Province',
  'joinUsPage.form.contactDetails.provinceLocated.label': 'State/Province',
  'joinUsPage.form.contactDetails.solutionDescription.placeholder':
    'Briefly describe the solutions your company has developed and plans to integrate with Sabre',
  'joinUsPage.form.contactDetails.solutionDescription.label':
    'Briefly describe the solutions your company has developed and plans to integrate with Sabre',
  'joinUsPage.form.contactDetails.potentialSabreCustomer.title': 'Information of potential Sabre Customers',
  'joinUsPage.form.contactDetails.potentialSabreCustomer.name.label': 'Please provide the full name of Sabre Customer',
  'joinUsPage.form.contactDetails.potentialSabreCustomer.pcc.label':
    'Please provide the PCC (Pseudo City Code) of Sabre Customer',
  'joinUsPage.form.contactDetails.requiredError':
    'All mandatory fields must be completed before moving to the next step.',
  'joinUsPage.form.termsAndConditions.title': 'Terms & Conditions',
  'joinUsPage.form.termsAndConditions.description':
    'You must agree to Terms & Conditions in order to move to next step.',
  'joinUsPage.form.termsAndConditions.approval.label': 'I Accept Terms & Conditions',
  'joinUsPage.form.pricing.title': 'Pricing Details',
  'joinUsPage.form.pricing.description':
    'Please review the pricing options. In order to move to the next step, you cannot leave the fields blank.',
  'joinUsPage.form.pricing.api.title': 'Sabre API',
  'joinUsPage.form.pricing.api.licence.unlimited.header': 'Full License Fee<span>Unlimited Technical Support</span>',
  'joinUsPage.form.pricing.api.licence.unlimited.content': 'USD 458.33<span>per Month</span>',
  'joinUsPage.form.pricing.api.licence.perIncident.header': 'Full License Fee<span>USD 82.50 per incident log</span>',
  'joinUsPage.form.pricing.api.licence.perIncident.content': 'USD 291.66<span>per Month</span>',
  'joinUsPage.form.pricing.api.toggle.title': '<strong>Sabre API License Fees</strong>',
  'joinUsPage.form.pricing.api.toggle.description': 'Please click and select the preferred option.',
  'joinUsPage.form.api.implementationFee.label': '<strong>One-Time Implementation Fee</strong>USD 1,000',
  'joinUsPage.form.pricing.pcc.label': 'Please provide PCC',
  'joinUsPage.form.pricing.pcc.placeholder': 'Please provide PCC',
  'joinUsPage.form.pricing.pcc.error': 'The specified PCC should contain four characters',
  'joinUsPage.form.pricing.certificationFee.title': 'Certification Fee',
  'joinUsPage.form.pricing.monthlyFee.title': 'Monthly Fee',
  'joinUsPage.form.pricing.subscriptionFee.title': 'Subscription Fee',
  'joinUsPage.form.pricing.subscriptionFee.title.additional': '(payable monthly)',
  'joinUsPage.form.pricing.subscriptionFee.description':
    'Please select your distribution region. It should be geographic area in which you have received permission to market, sell or distribute your Solution',
  'joinUsPage.form.pricing.subscriptionFee.globalDistribution.header': '<strong>Global Distribution:</strong>',
  'joinUsPage.form.pricing.subscriptionFee.globalDistribution.label': 'USD 2,916.67',
  'joinUsPage.form.pricing.subscriptionFee.regionalDistribution.header': '<strong>Regional Distribution:</strong>',
  'joinUsPage.form.pricing.subscriptionFee.regionalDistribution.APAC.label': 'APAC USD 1,000',
  'joinUsPage.form.pricing.subscriptionFee.regionalDistribution.EMEA.label': 'EMEA USD 1,250',
  'joinUsPage.form.pricing.subscriptionFee.regionalDistribution.LAC.label': 'LAC USD 583.34',
  'joinUsPage.form.pricing.subscriptionFee.regionalDistribution.NAM.label': 'NAM USD 2,083.34',
  'joinUsPage.form.pricing.subscriptionFee.marketDistribution.header': '<strong>Market Distribution:</strong>',
  'joinUsPage.form.pricing.subscriptionFee.marketDistribution.expandPanel.header':
    'Select up to 2 markets excluding NAM',
  'joinUsPage.form.pricing.subscriptionFee.marketDistribution.search.label': 'Search',
  'joinUsPage.form.pricing.subscriptionFee.marketDistribution.search.placeholder': 'Search',
  'joinUsPage.form.pricing.subscriptionFee.marketDistribution.label': 'USD 416.67',
  'joinUsPage.form.pricing.subscriptionFee.marketDistribution.error': 'Select one or two markets',
  'joinUsPage.form.pricing.footer.item1':
    'Separate fee may apply for passive segments created for content outside Sabre System',
  'joinUsPage.form.pricing.footer.item2':
    '<strong>Developer Subscription Fee is free of charge for 3 months from Effective Date of Contract</strong>',
  'joinUsPage.form.pricing.footer.item3':
    'If your Solution is a Booking Tool, Subscription Fee is USD 0.50 per PNR or flat fee per month, whichever is higher',
  'joinUsPage.form.pricing.footer.item4': 'Fees are applicable per Project Scope/Solution basis',
  'joinUsPage.form.reviewAndSubmit.title': 'Review & Submit',
  'joinUsPage.form.reviewAndSubmit.description':
    'Please review the information that you have provided. You can go back to the previous steps using the “BACK” button. To come back to “Review & Submit” page, click on the “CONTINUE” button. You cannot change your acceptance of the Terms and Conditions. Finally, make sure you click on the “SUBMIT” button.',
  'joinUsPage.form.reviewAndSubmit.contactDetails.title': 'Contact Details',
  'joinUsPage.form.reviewAndSubmit.generalInformation': 'General Information',
  'joinUsPage.form.reviewAndSubmit.contactDetails.companyAddress.title': 'Company Address',
  'joinUsPage.form.reviewAndSubmit.contactDetails.companyLocation.title': 'Company Location',
  'joinUsPage.form.reviewAndSubmit.termsAndCondition.title': 'Terms & Conditions',
  'joinUsPage.form.reviewAndSubmit.termsAndCondition.description': 'Agreed',
  'joinUsPage.form.reviewAndSubmit.pricingDetails.title': 'Pricing Details',
  'joinUsPage.form.reviewAndSubmit.pricingDetails.license.unlimited.label':
    'Full License Fee with Unlimited Support (USD 458.33 per month)',
  'joinUsPage.form.reviewAndSubmit.pricingDetails.license.paidPerIncident.label':
    'Full License Fee with USD 82.50 per incident log (UDS 291.66 per month)',
  'joinUsPage.form.reviewAndSubmit.addidionalInformation.title': 'Additional Information',
  'joinUsPage.form.reviewAndSubmit.implementationFee.label': 'One-Time Implementation Fee (USD 1,000)',
  'joinUsPage.form.endMessage.title': 'Thank you for your submission',
  'joinUsPage.form.endMessage.message': 'A Sabre representative will respond to your request within 3-5 business days.',
  'joinUsPage.form.errorMessage.title': 'Something went wrong, please try again',
  'joinUsPage.form.errorMessage.message': "We can't send your data to server, please refresh this page and try again",
  'joinUsPage.form.loadingMessage.message': 'Sending data',
};

export default joinUsPage;
