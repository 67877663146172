import {useForm} from 'react-hook-form';
import {fileSourceName, pfKeyFileName, pfKeysFilesNames, PfKeysValues} from '@scm/pf-keys/provider/pfKeysValues';
import {initialValues} from '@scm/pf-keys/provider/pfKeysConstants';
import {FileSource as ProposalFileSource} from '@scm/proposal/pages/proposalForm/ProposalValues';
import PfKeysFileSource from '@scm/pf-keys/definitionPage/definitionComponents/PfKeysFileSource';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import styled from 'styled-components';
import useGetSubmit from './useGetSubmit';

enum ButtonValues {
  Cancel = 'Cancel',
  Submit = 'Submit',
}

const CreatePfKeysBundle = ({
  handleClose,
  setLoadingProductDetails,
}: {
  handleClose: () => void;
  setLoadingProductDetails: (value: boolean) => void;
}) => {
  const {watch, setValue, getValues} = useForm<PfKeysValues>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const isLocalFileSourceChosen = watch(fileSourceName) === ProposalFileSource['Local'];
  const isFileUploaded = !watch(pfKeyFileName) || !watch(pfKeysFilesNames).length;
  const submitDisabled = isLocalFileSourceChosen ? isFileUploaded : false;

  const onSubmit = useGetSubmit({handleClose, setLoadingProductDetails});

  return (
    <>
      <div className="spark-panel spark-pad-l-2">
        <PfKeysFileSource id="definition.pfKeys.fileSource.label" watch={watch} setValue={setValue as () => void} />
      </div>
      <ButtonContainer className="spark-flex spark-mar-t-2">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
          {ButtonValues.Cancel}
        </Button>
        <Button
          progress
          type="button"
          size={ButtonSize.SMALL}
          onClick={async (_, success, failure) => {
            if (success && failure) {
              await onSubmit(getValues(), success, failure);
            }
          }}
          disabled={submitDisabled}
          tabIndex={0}
        >
          {ButtonValues.Submit}
        </Button>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  justify-content: flex-end;
`;

export default CreatePfKeysBundle;
