import {ActionBadges, ExpandPanel, MultiSelect, Star} from '@scm/components';
import {MultiselectErrorMessage} from '@scm/proposal/pages/proposalForm/steps/definitionPage/definitionComponents/Category';
import {requiredArrayMessage} from '@scm/proposal/schemas/proposalConstants';
import React, {useContext} from 'react';
import {isAllowedToEditProducts} from '@scm/authentication/utils/authentication';
import {FormattedMessage} from 'react-intl';
import useGetProductC from '../../../../hooks/useGetProductC';
import {
  CcrzEProductCSObjectProvisioningTypeCEnum,
  CcrzEProductCSObjectRedAppTypeCEnum,
} from '../../../../generated/product';
import {categoriesName, ProductCategoriesContext} from '../../../../forms/ProductCategories';
import {MultiSelectMainProps} from '@scm/components/form/multiSelect/MultiSelect';
import {ProductCategories} from '@scm/components/assets/productCategories';
import styled from 'styled-components';
import useGetIsObsolete from '../../../../hooks/useGetIsObsolete';

const multiSelectSize = 3;

const Categories = () => {
  const isObsolete = useGetIsObsolete();
  const isAllowedToEdit = !isObsolete && isAllowedToEditProducts();
  const {redAppTypeC, provisioningTypeC} = useGetProductC();
  const isPublic =
    redAppTypeC === CcrzEProductCSObjectRedAppTypeCEnum.Regular &&
    provisioningTypeC === CcrzEProductCSObjectProvisioningTypeCEnum.Public;
  const {categories, isError, watch, setValue} = useContext(ProductCategoriesContext);

  const createOptions = () =>
    Object.values(ProductCategories).reduce((result, value) => Object.assign(result, {[value]: value}), {});

  const multiSelectMainProps: MultiSelectMainProps = {
    firstLevelName: categoriesName,
    setValue,
    watch,
    options: createOptions(),
  };

  const categoryDeleteHandler = (name: string) => {
    setValue(
      categoriesName,
      watch(categoriesName).filter((value: string) => value !== name),
      {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: true,
      }
    );
  };

  return (
    <>
      {isAllowedToEdit && (
        <MultiSelectContainer>
          <ExpandPanel
            panelHeader={
              <>
                <FormattedMessage id="productDetails.category.label" />
                {isPublic && <Star />}
              </>
            }
          >
            <MultiSelect
              multiSelectMainProps={multiSelectMainProps}
              size={multiSelectSize}
              disabled={!isAllowedToEdit || watch(categoriesName).length > multiSelectSize}
            />
          </ExpandPanel>
          {isError && <MultiselectErrorMessage>{requiredArrayMessage}</MultiselectErrorMessage>}
        </MultiSelectContainer>
      )}
      <ActionBadges
        badgeNames={categories}
        deleteHandler={categoryDeleteHandler}
        className="spark-pad-l-0 spark-mar-t-2"
        disabled={!isAllowedToEdit}
      />
    </>
  );
};

const MultiSelectContainer = styled.div`
  width: 17rem;
  margin: 1.5rem 0;
`;

export default Categories;
