import * as yup from 'yup';
import {
  blockOrderingAndHideProductDateName,
  blockOrderingAndHideProductImmediatelyName,
  sendNotificationConfirmationName,
  sendNotificationDateAsapName,
  sendNotificationDateName,
  sendNotificationTextName,
  sunsetDateAsapName,
  sunsetDateName,
} from './RequestSunsetValues';

export const requestSunsetSchema = yup.object().shape({
  [blockOrderingAndHideProductImmediatelyName]: yup.mixed().oneOf(['Yes', 'No']).required(),
  [blockOrderingAndHideProductDateName]: yup.string(),
  [sunsetDateAsapName]: yup.mixed().oneOf(['Yes', 'No']).required(),
  [sunsetDateName]: yup.string(),
  [sendNotificationConfirmationName]: yup.mixed().oneOf(['Yes', 'No']).required(),
  [sendNotificationDateAsapName]: yup.mixed().oneOf(['Yes', 'No']).required(),
  [sendNotificationDateName]: yup.string(),
  [sendNotificationTextName]: yup.string(),
});
