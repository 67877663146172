import React from 'react';
import styled from 'styled-components';
import ReactImageFallback from 'react-image-fallback';
import {useMediaQuery} from 'react-responsive';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';
import {Loading} from '@scm/components';

const Logo = ({
  logo,
  name,
  containerHeight = '120px',
  containerMargin = '0 0 2rem 0',
  className,
}: {
  logo: string;
  name: string;
  containerHeight?: string;
  containerMargin?: string;
  className?: string;
}) => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  return (
    <LogoContainer height={containerHeight} margin={containerMargin}>
      <StyledReactImageFallback
        className={className}
        src={logo}
        alt={name}
        fallbackImage={<LogoFallback textLength={name.length}>{name}</LogoFallback>}
        initialImage={
          <div>
            <Loading sizing="sm" />
          </div>
        }
        mobile={isMobile}
      />
    </LogoContainer>
  );
};

const StyledReactImageFallback = styled(ReactImageFallback)<{mobile?: boolean}>`
  max-height: ${props => (props.mobile ? '15vh' : '120px')};
  min-width: 10vw;
  max-width: ${props => (props.mobile ? '50vw' : '15vw')};
  object-fit: contain;
`;

const LogoContainer = styled.div<{height?: string; margin?: string}>`
  margin: ${props => `${props.margin}`};
  height: ${props => `${props.height}`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoFallback = styled.div<{textLength: number}>`
  background-color: ${colors.grey50};
  color: ${colors.grey700};
  border-bottom: 2px solid ${colors.grey150};
  padding: 1.5rem 2rem;
  margin: 1.5rem;
  font-size: ${props => Math.max(-0.25 * props.textLength + 3, 1) + 'vw'};
  border-radius: 0.25rem;
  font-weight: 400;
  user-select: none;
`;

export default Logo;
