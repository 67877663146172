const freezeCalendar = {
  'freezeCalendar.title': 'Freeze Calendar',
  'freezeCalendar.noData.label': 'There are not exist any scheduled freeze, please add new.',
  'freezeCalendar.noData.title': 'No Freeze',
  'freezeCalendar.addButton.label': 'Add new freeze',
  'freezeCalendar.modal.deleteButton.label': 'Delete',
  'freezeCalendar.modal.cancelButton.label': 'Cancel',
  'freezeCalendar.error.title': 'Something went wrong',
  'freezeCalendar.error.content': 'Please try again later.',
  'freezeCalendar.deleteError.description': "Couldn't delete entry. Please try again or contact our support team.",
  'freezeCalendar.addForm.title': 'Add new Freeze',
  'freezeCalendar.addForm.environment.label': 'Environment',
  'freezeCalendar.addForm.startDate.label': 'Start Date',
  'freezeCalendar.addForm.endDate.label': 'End Date',
  'freezeCalendar.addForm.hour.label': 'Hour',
  'freezeCalendar.addForm.comment.label': 'Description',
  'freezeCalendar.addForm.comment.header': 'Comment',
  'freezeCalendar.deleteModal.title': 'Delete Entry',
  'freezeCalendar.deleteModal.description': 'Are you sure that you want to remove this freeze calendar entry?',
  'freezeCalendar.addForm.errorTitle': 'Adding new freeze calendar entry has failed',
  'freezeCalendar.addForm.invalidRequest':
    'Invalid request. Calendar with provided date already exists or dates are mixed',
  'freezeCalendar.addForm.invalidRequest.requiredFields':
    'Invalid request. All of the fields are required to be filled',
  'freezeCalendar.calendar.button': 'Open Calendar',
  'freezeCalendar.calendar.certLabel': 'No New Apps in CERT',
  'freezeCalendar.calendar.prodLabel': 'No New Apps in PROD',
  'freezeCalendar.calendar.title': 'Scheduled Maintenance Dates for Sabre Red 360',
  'freezeCalendar.datesWarning.content':
    'Below dates are displayed in <strong>Central Standard Time (CST | UTC -6:00).</strong> Please also use this time zone when entering/editing freeze entries.\n',
};

export default freezeCalendar;
