import React, {ReactNode} from 'react';

const ExternalLink = ({
  to,
  title,
  className,
  ariaLabel,
  children,
}: {
  to: string;
  title?: string;
  className?: string;
  ariaLabel?: string;
  children?: ReactNode;
}) => (
  <a
    href={to}
    className={className}
    aria-label={`link to ${ariaLabel}`}
    title={title}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

export default ExternalLink;
