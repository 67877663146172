import MediaQuery from 'react-responsive';
import {maxMobileWidth} from 'assets/deviceWidth';
import PartnerCard from 'pages/partnerDetails/card/PartnerCard';
import PartnerDescription from 'pages/partnerDetails/PartnerDescription';
import PartnerTestimonials from 'pages/partnerDetails/PartnerTestimonials';
import React from 'react';
import styled from 'styled-components';
import {Maybe, Partner} from 'generated/graphql';
import ProductsMobile from 'pages/partnerDetails/products/ProductsMobile';

const PartnerDetailsMobile = ({partner}: {partner: Maybe<Partner> | undefined}) =>
  !partner ? (
    <div />
  ) : (
    <MediaQuery maxDeviceWidth={maxMobileWidth}>
      <MainRow>
        <PartnerCard partner={partner} />
        <PartnerDescription
          name={partner.name}
          longDescription={partner.longDescription}
          email={partner.contact.email}
        />
      </MainRow>
      {partner.products && partner.products.length > 0 && (
        <ProductsMobile products={partner.products} name={partner.name} />
      )}
      {partner.testimonials && partner.testimonials.length > 0 && <PartnerTestimonials partner={partner} />}
    </MediaQuery>
  );

const MainRow = styled.div.attrs(() => ({
  className: 'spark-flex',
}))`
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
`;

export default PartnerDetailsMobile;
