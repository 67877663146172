import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {format} from 'date-fns';
import {dateFormat} from '@scm/components/utils/common';
import StatusIcon from '@scm/admin-centre/src/components/styledComponents/StatusIcon';
import {useLocation} from 'react-router-dom';
import {VersionResponse} from '../generated/certification';
import styled from 'styled-components';

const BundleInfo = ({bundles}: {bundles: VersionResponse[]}) => {
  const location = useLocation();
  const bundleVersion = location.state?.version;

  const versionName = useMemo<string>(() => {
    const getVersionNameFromPath = () => {
      const path = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
      return path ? path.slice(path.lastIndexOf('/') + 1) : '';
    };

    return bundleVersion ?? getVersionNameFromPath() ?? '';
  }, [bundleVersion, location.pathname]);

  const chosenBundle = bundles.filter(bundle => bundle.name === versionName)[0];

  return !chosenBundle ? null : (
    <ul className="spark-list spark-list--no-bullets spark-mar-b-0">
      <InfoListItem className="spark-mar-0">
        <ProductInfoLabel>
          <FormattedMessage id="support.version" />
        </ProductInfoLabel>
        {chosenBundle.name}
      </InfoListItem>
      <InfoListItem className="spark-mar-0">
        <ProductInfoLabel>
          <FormattedMessage id="support.submitted" />
        </ProductInfoLabel>
        {chosenBundle.createdAt && format(new Date(chosenBundle.createdAt), dateFormat)}
      </InfoListItem>
      <InfoListItem className="spark-mar-0">
        <ProductInfoLabel>
          <FormattedMessage id="support.lastUpdated" />
        </ProductInfoLabel>
        {chosenBundle.lastUpdate && format(new Date(chosenBundle.lastUpdate), dateFormat)}
      </InfoListItem>
      <InfoListItem className="spark-mar-0">
        <ProductInfoLabel>
          <FormattedMessage id="support.status" />
        </ProductInfoLabel>
        <StatusIcon statusName={chosenBundle.status || ''} />
        <StatusType className="spark-inline-block spark-mar-l-1">{chosenBundle.status}</StatusType>
      </InfoListItem>
    </ul>
  );
};

export const ProductInfoLabel = styled.span`
  &::after {
    content: ':';
    display: inline-block;
    margin-right: 1rem;
  }
`;

const StatusType = styled.span`
  transform: translateX(-1rem);
`;

export const InfoListItem = styled.li`
  padding: 0.15rem 0;

  &:last-of-type {
    transform: translateY(-0.4rem);
  }

  & .spark-icon-tools {
    transform: scale(0.7);
  }
`;

export default BundleInfo;
