import React, {SetStateAction, useCallback, useMemo, useState} from 'react';
import InlineButton from '@scm/admin-centre/src/components/InlineButton';
import {ButtonLine} from '@scm/product-components/pages/productDetails/productTabs/proposalData/ProposalData';
import {UseFormWatch} from 'react-hook-form';
import {SelectInput, TextInput} from '@sabre/spark-react-core';
import {useIntl} from 'react-intl';
import {RowsGroup} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/ProposalViewDataTab';
import {Row} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {
  Configuration,
  DetailedVersionResponse,
  FileTypes,
  ResultResultEnum,
  Status,
  VersionsApi,
} from '@scm/product-components/generated/certification';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ToastType} from '@sabre/spark-react-core/types';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import CertificationTestResultModal from './CertificationTestResultModal';
import styled from 'styled-components';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

enum DistributionType {
  New = 'New',
  Patch = 'Patch',
}

enum CertificationAction {
  Approve = 'approve',
  Reject = 'reject',
}

async function downloadFile(id: string, fileType: 'Security worksheet' | 'Bundle' | 'Workflow diagram' | 'Use cases') {
  return await new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).downloadFile({id, fileType});
}

export const downloadFileAndHandleErrors = async (
  id: string,
  fileType: FileTypes,
  errorMessageTitle: string,
  errorMessageDetails: string
) => {
  try {
    const res = await downloadFile(id, fileType);
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(res);
    a.href = url;
    a.download = fileType.toLowerCase();
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (err) {
    openToast(errorMessageTitle, errorMessageDetails, ToastType.WARNING, 'spark-icon-alert-triangle', true);
  }
};

export const changeToSnakeCase = (text: string) => {
  return text.replace(' ', '_');
};

const Certification = ({
  status,
  id,
  setLoadingProductDetails,
  setLoading,
  watch,
}: {
  status: string;
  id?: string;
  setLoadingProductDetails: React.Dispatch<SetStateAction<boolean>>;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  watch: UseFormWatch<DetailedVersionResponse>;
}) => {
  const certificationEngineerName = 'assignedCertificationEngineer';
  const distributionTypeName = 'DistributionType';
  const isPatchName = 'isPatch';
  const svsMainPluginName = 'svsMainPlugin';
  const compatibilityName = 'compatibility';
  const redAppProcessName = 'processPiiOrPci';
  const productDetailsName = 'productDetails.fetch';
  const {formatMessage} = useIntl();
  const [testResult, setTestResult] = useState<ResultResultEnum>();
  const [actionName, setActionName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = (): void => setModalOpen(true);
  let errorMessageTitle = createMessageString(formatMessage, productDetailsName, true, true);
  let errorMessageDetails = createMessageString(formatMessage, productDetailsName, false, true);

  const assignUser = async (id: string, abandon?: boolean) =>
    new VersionsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).assignUser({id, abandon});

  const disableApproveAndRejectButtons = status !== Status.CertTestsPassed;

  const disableAbandonButton = !watch(certificationEngineerName);

  const assignCertificationEngineer = (isAbandon?: boolean) => {
    assignUser(id as string, isAbandon)
      .then(() =>
        openToast(
          createMessageString(formatMessage, productDetailsName, true, false),
          createMessageString(formatMessage, productDetailsName, false, false),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(() =>
        openToast(errorMessageTitle, errorMessageDetails, ToastType.WARNING, 'spark-icon-alert-triangle', true)
      )
      .finally(() => setLoadingProductDetails(true));
  };

  const certificationTestResult = (result: ResultResultEnum) => {
    if (!id) {
      return;
    }
    setTestResult(result);
    handleModalOpen();
  };

  enum Compatibility {
    'Desktop' = 'Desktop',
    'Web' = 'Web',
    'Web&Desktop' = 'Web&Desktop',
    'None' = '',
  }

  const getCompatibility = useCallback(() => {
    const desktopName = 'canBeUsedInSabreRedDesktop';
    const webName = 'canBeUsedInSabreRedWeb';
    const isDesktop = watch(desktopName);
    const isWeb = watch(webName);
    if (isDesktop && isWeb) {
      return Compatibility['Web&Desktop'];
    }

    if (isDesktop && !isWeb) {
      return Compatibility.Desktop;
    }

    if (!isDesktop && isWeb) {
      return Compatibility.Web;
    }

    return Compatibility.None;
  }, []);

  const fileTypeToFieldNameMapper = {
    [FileTypes.Bundle]: 'bundleFilename',
    [FileTypes.SecurityWorksheet]: 'securityWorksheetFilename',
    [FileTypes.UseCases]: 'useCasesFilename',
    [FileTypes.WorkflowDiagram]: 'workflowDiagramFilename',
  };

  const downloadFileButtons = useMemo(
    () => (
      <ButtonsContainer>
        {Object.values(FileTypes).map(type => (
          <Row className="button-box spark-mar-l-2 spark-mar-b-1" key={type}>
            <InlineButton
              id={`certify.actionButton.${type}`}
              clickHandler={() =>
                id &&
                downloadFileAndHandleErrors(
                  id,
                  changeToSnakeCase(type.toUpperCase()) as FileTypes,
                  errorMessageTitle,
                  errorMessageDetails
                )
              }
              type="button"
              className="button-width neutral spark-mar-r-1 spark-mar-b-1 "
              disabled={!watch(fileTypeToFieldNameMapper[type] as keyof DetailedVersionResponse)}
            />
          </Row>
        ))}
      </ButtonsContainer>
    ),
    []
  );

  return (
    <>
      <ButtonLine className="spark-mar-l-2 park-btn-group spark-btn-group--left spark-mar-b-2">
        <InlineButton
          id="certify.certification.start"
          clickHandler={() => assignCertificationEngineer()}
          type="button"
          className="spark-mar-r-1"
          secondary
        />
        <InlineButton
          id="certify.certification.abandon"
          clickHandler={() => assignCertificationEngineer(true)}
          type="button"
          className={`${disableAbandonButton ? '' : 'delete'} spark-mar-r-1 spark-mar-b-1`}
          disabled={disableAbandonButton}
        />
        <InlineButton
          id="certify.certification.approve"
          clickHandler={() => {
            certificationTestResult(ResultResultEnum.Passed);
            setActionName(CertificationAction.Approve);
          }}
          type="button"
          className="spark-mar-r-1 spark-mar-b-1"
          disabled={disableApproveAndRejectButtons}
        />
        <InlineButton
          id="certify.certification.reject"
          clickHandler={() => {
            certificationTestResult(ResultResultEnum.Failed);
            setActionName(CertificationAction.Reject);
          }}
          type="button"
          className={'spark-mar-r-1 spark-mar-b-1 ' + (disableApproveAndRejectButtons ? '' : 'error')}
          disabled={disableApproveAndRejectButtons}
        />
        {id && actionName && (
          <CertificationTestResultModal
            open={modalOpen}
            setOpen={setModalOpen}
            id={id}
            action={actionName}
            result={testResult}
            setLoading={setLoading}
            setLoadingProductDetails={setLoadingProductDetails}
          />
        )}
      </ButtonLine>
      <RowsGroup>
        <Row className="spark-mar-l-2 spark-mar-b-2">
          <TextInput
            name={certificationEngineerName}
            value={watch(certificationEngineerName)}
            label={formatMessage({id: 'certify.certification.certificationEngineer.label'})}
            disabled
          />
        </Row>
        <Row className="spark-mar-l-2 spark-mar-b-1">
          <SelectInput
            value={watch(isPatchName) ? DistributionType.Patch : DistributionType.New}
            name={distributionTypeName}
            label={formatMessage({id: 'versionDetails.distributionType.label'})}
            options={Object.values(DistributionType).map(value => ({value, label: value}))}
            disabled
          />
        </Row>
        <Row className="spark-mar-l-2 spark-mar-b-2">
          <TextInput
            name={svsMainPluginName}
            value={watch(svsMainPluginName)}
            label={formatMessage({id: 'versionDetails.svsMainPluginId.label'})}
            disabled
          />
        </Row>
        <Row className="spark-mar-l-2 spark-mar-b-1">
          <SelectInput
            value={getCompatibility()}
            name={compatibilityName}
            label={formatMessage({id: 'certify.compatibility.label'})}
            options={Object.values(Compatibility).map(value => ({value, label: value}))}
            disabled
          />
        </Row>
        <Row className="spark-mar-l-2 spark-mar-b-1">
          <SelectInput
            value={watch(redAppProcessName) ? Confirmation.Yes : Confirmation.No}
            name={redAppProcessName}
            label={formatMessage({id: 'certify.redAppProcess.label'})}
            options={Object.values(Confirmation).map(value => ({value, label: value}))}
            disabled
          />
        </Row>
        {downloadFileButtons}
      </RowsGroup>
    </>
  );
};

export const ButtonsContainer = styled.div`
  .button-width {
    width: 38rem;
  }
`;

export default Certification;
