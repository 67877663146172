import styled from 'styled-components';
import colors from '../assets/colors';

const AccessibilityProvider = ({children}: {children: JSX.Element | null}) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled.div`
  & .spark-btn[disabled]:not(.neutral, .error, .warning),
  & .spark-step-indicator__text,
  & .spark-step-indicator__item--disabled .spark-step-indicator__icon,
  & .spark-checkbox .spark-checkbox__input:disabled ~ .spark-label,
  & .spark-radio__input:disabled ~ .spark-label,
  & .spark-checkbox .spark-checkbox__input:disabled ~ .spark-label,
  & .spark-checkbox .spark-radio__input:disabled ~ .spark-label,
  & .spark-radio .spark-checkbox__input:disabled ~ .spark-label,
  & .spark-radio .spark-radio__input:disabled ~ .spark-label,
  & .spark-select.disabled .spark-label,
  & .spark-select__input:disabled ~ .spark-label,
  & .spark-toggle .spark-toggle__input:disabled ~ .spark-label:nth-child(n),
  & .spark-input__field:disabled,
  & .spark-input__field:disabled ~ .spark-label,
  & [contenteditable='false'] > p {
    color: ${colors.highlightGrey} !important;
  }

  .neutral[disabled],
  .error[disabled] {
    color: ${colors.grey200} !important;
  }

  .spark-modal__body h2 {
    font-size: 2rem !important;
  }
`;

export default AccessibilityProvider;
