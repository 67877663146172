import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {MutableRefObject} from 'react';

const TableWrapper = ({
  children,
  tableRef,
  loading,
  isProductTable,
}: {
  children: JSX.Element;
  tableRef: MutableRefObject<null>;
  loading: boolean;
  isProductTable?: boolean;
}) => (
  <TableContainer
    className="spark-mar-t-1 spark-table--condensed spark-mar-r-1"
    ref={tableRef}
    loaded={!loading}
    isProductTable={!!isProductTable}
  >
    {children}
  </TableContainer>
);

const SPARK_TABLE_BCG = '#f6f6f7';

const TableContainer = styled.div<{loaded: boolean; isProductTable: boolean}>`
  position: relative;
  background-color: ${props => (props.loaded ? colors.white : colors.transparent)};

  & :is(td, th) {
    padding: 0.5rem 0.2rem;
    border-left: none !important;
    border-right: none !important;

    &:first-of-type {
      padding-left: 1rem;
    }
  }

  ${props =>
    props.isProductTable
      ? ` & :is(td, th):nth-of-type(2),
          & :is(td, th):nth-of-type(3),
          & :is(td, th):nth-of-type(4),
          & :is(td, th):nth-of-type(5){
            text-align: center;
          }`
      : ` & :is(td, th):nth-of-type(5),
          & :is(td, th):nth-of-type(6),
          & :is(td, th):nth-of-type(7) {
            text-align: center;
          }`}
  & th {
    color: ${colors.grey900};
    background-color: ${colors.white} !important;
    border: none !important;
  }

  & .text-invisible {
    font-size: 0;
  }

  & thead {
    position: sticky !important;
    top: 0;
    z-index: 1;
    box-shadow: 0 0.25rem 0.45rem -0 rgba(0, 0, 0, 0.5) !important;
    -webkit-box-shadow: 0 0.25rem 0.45rem 0 rgba(0, 0, 0, 0.5) !important;
    -moz-box-shadow: 0 0.25rem 0.45rem 0 rgba(0, 0, 0, 0.5) !important;
  }

  & tr.active:not(:first-child) td {
    background-image: none !important;
  }

  & .spark-table tbody tr:first-child td {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 1rem) !important;
  }

  & .spark-table tbody tr:hover td {
    background-image: linear-gradient(to bottom, ${SPARK_TABLE_BCG} 0, ${SPARK_TABLE_BCG} 100%) !important;
  }

  & tr.active td {
    background-image: none !important;
    border-style: solid !important;
    border-color: ${colors.grey300} !important;
  }
`;

export default TableWrapper;
