/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSellerRequest
 */
export interface CreateSellerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSellerRequest
     */
    scCode: string;
}

/**
 * Check if a given object implements the CreateSellerRequest interface.
 */
export function instanceOfCreateSellerRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scCode" in value;

    return isInstance;
}

export function CreateSellerRequestFromJSON(json: any): CreateSellerRequest {
    return CreateSellerRequestFromJSONTyped(json, false);
}

export function CreateSellerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSellerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scCode': json['scCode'],
    };
}

export function CreateSellerRequestToJSON(value?: CreateSellerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scCode': value.scCode,
    };
}

