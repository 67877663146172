import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import HighlightedHeader from 'pages/home/highlighted/HighlightedHeader';
import HighlightedPartner from 'pages/home/highlighted/HighlightedPartner';
import {useQuery} from '@apollo/client';
import {
  GetFeaturedPartnersQuery,
  GetFeaturedPartnersQueryVariables,
  GetFeaturedPartnersDocument,
  GetPartnersDocument,
  GetFilterOptionsDocument,
} from 'generated/graphql';
import CenterItems from 'components/styledComponents/CenterItems';
import {useIntl} from 'react-intl';
import InternalUrls from 'utils/internalUrls';
import CenteredErrorMessage from 'components/styledComponents/CenteredErrorMessage';
import {fetchPartnersBatchSize} from 'pages/partnerDirectory/searchResults/SearchResults';
import {useMediaQuery} from 'react-responsive';
import ItemsCarousel from 'react-items-carousel';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery, maxTabletWidthQuery} from '@scm/components/assets/deviceWidth';
import {Loading} from '@scm/components';

const Highlighted = () => {
  const {formatMessage} = useIntl();
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const isTablet = useMediaQuery(maxTabletWidthQuery);
  const {data, loading, error, client} = useQuery<GetFeaturedPartnersQuery, GetFeaturedPartnersQueryVariables>(
    GetFeaturedPartnersDocument,
    {
      variables: {
        take: fetchPartnersBatchSize,
      },
    }
  );

  const prefetchPartnerDirectory = () => {
    client.query({query: GetFilterOptionsDocument});
    client.query({
      query: GetPartnersDocument,
      variables: {
        orderBy: {rank: true},
        take: fetchPartnersBatchSize,
        categories: [],
        regions: [],
      },
    });
  };
  useEffect(prefetchPartnerDirectory);

  const highlightedPartners = data?.getPartners.data.partners.map((partner, index) => (
    <HighlightedPartner
      key={index}
      logoUrl={partner.logoUrl!}
      name={partner.name!}
      shortDescription={partner.shortDescription!}
      partnerLink={`${InternalUrls.Partners}/${encodeURIComponent(partner.name!)}`}
    />
  ));
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const autoPlayDelayMs = 4000;

  useEffect(() => {
    const id = setInterval(() => setActiveItemIndex(activeItemIndex + 1), autoPlayDelayMs);
    return () => clearInterval(id);
  });

  if (loading)
    return (
      <CenterItems>
        <Loading label={formatMessage({id: 'common.data.fetchingPartners'})} />
      </CenterItems>
    );

  const chevronWidthPx = 40;
  const numberOfCards = isTablet ? 2 : 4;
  const gutterPx = 20;

  const createChevron = (site: string) => (
    <ChevronBlock className={`border-${site}`}>
      <CarouselChevron aria-label={`go ${site}`} className={`spark-icon spark-icon-arrow-chevron-${site}`} />
    </ChevronBlock>
  );

  return (
    <HighlightedContainer mobile={isMobile}>
      {error && <CenteredErrorMessage error={error} />}
      {highlightedPartners && !isMobile && highlightedPartners.length > 0 && (
        <Carousel chevronWidthPx={chevronWidthPx}>
          <HighlightedHeader />
          <ItemsCarousel
            requestToChangeActive={setActiveItemIndex}
            activeItemIndex={activeItemIndex}
            numberOfCards={numberOfCards}
            gutter={gutterPx}
            leftChevron={createChevron('left')}
            rightChevron={createChevron('right')}
            outsideChevron
            chevronWidth={chevronWidthPx}
          >
            {highlightedPartners}
          </ItemsCarousel>
        </Carousel>
      )}
      {isMobile && (
        <MobileHighlightedContainer>
          <HighlightedHeader />
          {highlightedPartners}
        </MobileHighlightedContainer>
      )}
    </HighlightedContainer>
  );
};

const HighlightedContainer = styled.div<{mobile?: boolean}>`
  background-color: ${colors.white};
  ${props => (props.mobile ? 'padding: 2.5rem 0;' : '')}
`;

const Carousel = styled.div<{chevronWidthPx: number}>`
  overflow: hidden;
  padding: 2rem ${props => props.chevronWidthPx}px;
`;

const ChevronBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39rem;
  transform: translateY(-5.01rem);
  &.border-left {
    box-shadow: 11px 0 8px -7px rgb(0 0 0 / 30%);
  }

  &.border-right {
    box-shadow: -11px 0 8px -7px rgb(0 0 0 / 30%);
  }
`;

export const CarouselChevron = styled.button`
  background-color: ${colors.white};
  border: none;
  font-size: 3rem;
  &:hover {
    cursor: pointer;
  }
`;

const MobileHighlightedContainer = styled.div.attrs(className => ({
  className: `${className} spark-flex`,
}))`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: ${colors.grey50};
  border-top: solid 1px ${colors.grey200};
`;

export default Highlighted;
