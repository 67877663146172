import SparkToast from '@sabre/spark/js/src/components/toast';
import {ToastType} from '@sabre/spark-react-core/types';
import {areTermsAndConditionsAccepted} from '@scm/authentication/utils/authentication';
import {redAppSupportEmail} from '@scm/components/utils/common';
import {hrefStringCreator} from './HrefElement';

export const createToast = () =>
  new SparkToast(document.querySelector('[data-toast="header"]'), {
    elHeader: document.querySelector('.spark-header__nav'),
    el: document.querySelector('.spark-header'),
  });

export const createMessageString = (
  formatMessage: (obj: {id: string}, values: {email: string}) => string,
  name: string,
  isTitle?: boolean,
  isError?: boolean
) =>
  formatMessage(
    {id: `${name}.${isError ? 'error' : 'success'}${isTitle ? 'Title' : ''}`},
    {
      email: hrefStringCreator(redAppSupportEmail),
    }
  );

export const closeCurrentToasts = () => {
  const currentMessages = Array.from(document.querySelectorAll('.spark-toast'));
  currentMessages.forEach(toast => toast.remove());
};

export const openToast = (
  title: string,
  details: string,
  type: ToastType,
  icon: string,
  noAutoDisappear?: boolean,
  forceDisplay?: boolean
) => {
  closeCurrentToasts();

  const {terms_and_conditions_accepted} = areTermsAndConditionsAccepted();
  const panelToast = createToast();
  const toastOptions = {
    title: title,
    details: details,
    icon: icon,
    type: type,
    actionIcon: 'spark-icon-close',
  };

  if (terms_and_conditions_accepted || forceDisplay)
    panelToast.open(
      noAutoDisappear
        ? {
            ...toastOptions,
            duration: Infinity,
          }
        : toastOptions
    );
};
