import Modal from '@sabre/spark-react-core/modal';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import AssignForm from './AssignForm';

const AssignCompanyLevel = ({handleClose, open}: {handleClose: () => void; open: boolean}) => {
  const {formatMessage} = useIntl();

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={formatMessage({id: 'assignSeller.companyLevel.title'})}
      titleHeadingLevel={2}
    >
      <Description>
        <FormattedMessage id="assignSeller.companyLevel.description" />
      </Description>
      <AssignForm handleClose={handleClose} />
    </Modal>
  );
};

const Description = styled.p`
  margin-top: 2rem;
`;

export default AssignCompanyLevel;
