/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Pci
 */
export interface Pci {
    /**
     * 
     * @type {Array<string>}
     * @memberof Pci
     */
    data?: Array<PciDataEnum>;
    /**
     * 
     * @type {string}
     * @memberof Pci
     */
    panProtectionComment?: string;
    /**
     * 
     * @type {string}
     * @memberof Pci
     */
    retentionPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof Pci
     */
    usedBy?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Pci
     */
    utilizingThirdPartyProcessor?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Pci
     */
    comment?: string;
}


/**
 * @export
 */
export const PciDataEnum = {
    PrimaryAccountNumber: 'Primary Account Number',
    CardholderName: 'Cardholder Name',
    ServiceCodeOrExpirationDate: 'Service Code or Expiration Date',
    MagneticStripeDataOrCvvCvcData: 'Magnetic Stripe Data or CVV/CVC Data',
    PinsPinBlocksOrAnyTrack1OrTrack2Data: 'PINs/PIN Blocks or Any Track-1 or Track-2 Data'
} as const;
export type PciDataEnum = typeof PciDataEnum[keyof typeof PciDataEnum];


/**
 * Check if a given object implements the Pci interface.
 */
export function instanceOfPci(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PciFromJSON(json: any): Pci {
    return PciFromJSONTyped(json, false);
}

export function PciFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pci {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : json['data'],
        'panProtectionComment': !exists(json, 'panProtectionComment') ? undefined : json['panProtectionComment'],
        'retentionPeriod': !exists(json, 'retentionPeriod') ? undefined : json['retentionPeriod'],
        'usedBy': !exists(json, 'usedBy') ? undefined : json['usedBy'],
        'utilizingThirdPartyProcessor': !exists(json, 'utilizingThirdPartyProcessor') ? undefined : json['utilizingThirdPartyProcessor'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PciToJSON(value?: Pci | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'panProtectionComment': value.panProtectionComment,
        'retentionPeriod': value.retentionPeriod,
        'usedBy': value.usedBy,
        'utilizingThirdPartyProcessor': value.utilizingThirdPartyProcessor,
        'comment': value.comment,
    };
}

