import styled from 'styled-components';
import colors from '../assets/colors';

const Heart = ({onClick, id}: {onClick?: () => void; id: string}) => (
  <Container
    onClick={onClick}
    onKeyDown={evt => {
      if (evt.key === 'Enter' && onClick) {
        onClick();
      }
    }}
    tabIndex={0}
    className="spark-flex"
    role="button"
  >
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      data-name="Heart"
      viewBox="0 0 122.88 109.57"
      className="spark-mar-r-.5"
      isMoved
    >
      <title>Add To Favorites</title>
      <Path
        xmlns="http://www.w3.org/2000/svg"
        d="M94.26,44.46A26.88,26.88,0,1,1,67.38,71.34,26.88,26.88,0,0,1,94.26,44.46ZM65.45,19.57l-4.52,4.74-4.52-4.43C49,12.58,43.4,7.08,31.89,6.93L30.46,7A25.75,25.75,0,0,0,13.74,13.6,22.58,22.58,0,0,0,6.63,30.17l0,1.37c.69,19.27,19.13,36.08,34.42,50,3,2.69,5.78,5.27,8.49,7.88l11.27,10.85,5.91-5.87c.68.8,1.39,1.58,2.13,2.32l0,0c.82.83,1.69,1.61,2.59,2.36l-10.59,10.5L45,94.22c-2.49-2.39-5.37-5-8.37-7.75C20.37,71.68.81,53.85,0,31.77l0-1.68A29.12,29.12,0,0,1,9.21,8.73a32.42,32.42,0,0,1,21-8.42H32c14,.18,20.45,6.34,28.8,14.55C67.66,7.54,73.39,1.55,85,.21a33.88,33.88,0,0,1,19,3.45,35.14,35.14,0,0,1,12.51,10.48,31.53,31.53,0,0,1,6.1,15.18,31,31,0,0,1-2.86,16.81l-.17-.18a36.18,36.18,0,0,0-5.14-4.27,24.26,24.26,0,0,0,1.57-11.6,25,25,0,0,0-4.83-12,28.62,28.62,0,0,0-10.14-8.5A27.27,27.27,0,0,0,85.79,6.82C76.47,7.89,71.5,13.17,65.45,19.57ZM90.29,60a3.85,3.85,0,0,1,.31-1.51l0-.06a3.92,3.92,0,0,1,.83-1.23,4,4,0,0,1,2.78-1.16h0a4.23,4.23,0,0,1,1.51.3,4.16,4.16,0,0,1,1.29.86,3.87,3.87,0,0,1,.85,1.28l0,.07A4.18,4.18,0,0,1,98.22,60V68h7.93a4,4,0,0,1,1.51.3,3.94,3.94,0,0,1,1.29.86,4,4,0,0,1,.85,1.28l0,.06a4,4,0,0,1,.28,1.38V72a3.82,3.82,0,0,1-.3,1.44,4,4,0,0,1-.86,1.29,3.88,3.88,0,0,1-1.27.85l-.07,0a4.23,4.23,0,0,1-1.43.28h-8v7.93a4,4,0,0,1-.3,1.51,4.11,4.11,0,0,1-.86,1.29l-.08.07a3.88,3.88,0,0,1-1.2.78l-.07,0a4.18,4.18,0,0,1-1.43.28h0a4,4,0,0,1-1.51-.3,3.94,3.94,0,0,1-1.29-.86,3.73,3.73,0,0,1-.85-1.27,4.05,4.05,0,0,1-.31-1.5V75.9H82.37a3.85,3.85,0,0,1-1.51-.31l-.06,0a3.92,3.92,0,0,1-1.23-.83,3.87,3.87,0,0,1-.85-1.28l0-.07A4.23,4.23,0,0,1,78.41,72v0a3.8,3.8,0,0,1,.3-1.51A3.95,3.95,0,0,1,82.34,68h8V60Z"
      />
    </Svg>
  </Container>
);

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  width: 50px;
  border-radius: 0.25rem;
  &:hover {
    background-color: ${colors.grey100};
  }
`;

export const Svg = styled.svg<{isMoved?: boolean}>`
  width: 30px;
  ${props => (props.isMoved ? 'transform: translateX(3px);' : '')}
`;

const Path = styled.path`
  fill-rule: evenodd;
  fill: ${colors.highlightGrey};
`;

export default Heart;
