import {Table, TableBody, TableCell, TableHead, TableHeaderCell, TableRow} from '@sabre/spark-react-core';
import React from 'react';
import {columns} from './utils/columns';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import AssignDeveloper from './assign-developer/AssignDeveloper';
import colors from '@scm/components/assets/colors';

const TableLayout = ({tableData}: {tableData: any[]}) => {
  const renderTableDrawer = (rowData: any): JSX.Element => {
    return <>{rowData}</>;
  };

  const drawerProps = {
    drawerExpanderAriaLabel: 'Expand/Collapse Drawer',
  };

  const renderSparkSimpleTable = (columns: any[], data: any[]): JSX.Element => {
    return (
      <ProductTable>
        <TableHead>
          <TableRow>
            {columns.map(({Header, accessor}) => (
              <TableHeaderCell key={accessor}>{Header}</TableHeaderCell>
            ))}
            <TableHeaderCell className="text-invisible">
              <FormattedMessage id="assignSeller.assignProduct" />
            </TableHeaderCell>
            <TableHeaderCell key="drawer" className="text-invisible">
              Options
            </TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((product, ind) => {
            const drawerContent = renderTableDrawer(product.children);
            return (
              <TableRow key={ind} id={ind.toString()} {...drawerProps} drawerContent={drawerContent}>
                {Object.entries(product).map(([key, value]: [string, any]) =>
                  key !== 'children' && key !== 'sku' ? <TableCell key={key}>{value}</TableCell> : null
                )}
                <TableCell className="assign-developer">
                  <AssignDeveloper sku={product.sku} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </ProductTable>
    );
  };

  return renderSparkSimpleTable(columns, tableData);
};

const ProductTable = styled(Table)`
  th:first-of-type {
    padding-left: 54px;
  }

  .spark-icon-users {
    font-size: 2.2rem;
  }

  td:not(:first-of-type) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  td.assign-developer {
    padding: 0 9px;
  }

  .hidden {
    visibility: hidden;
  }

  .spark-table__drawer__content {
    padding: 0 !important;
    background-color: ${colors.grey100};
  }
`;

export default TableLayout;
