import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext, useEffect, useState} from 'react';
import {StorefrontDataContext} from '@scm/product-components/pages/storefrontData/dataProvider/StorefrontDataProvider';
import {Configuration, PccEprsInner, ProductApi} from '@scm/product-components/generated/product';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {productApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import LimitationsDataValues, {
  eprLimitationsName,
  scCodeLimitationsName,
} from '@scm/product-components/pages/storefrontData/interfaces/LimitationsDataValues';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {Loading} from '@scm/components';
import parse from 'html-react-parser';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import CustomMessageContainer, {CustomMessageTypes} from './CustomMessageContainer';
import {useForm} from 'react-hook-form';
import limitationsMapper from './LimitationsMapper';
import LimitationScCodeInput from './LimitationScCodeInput';
import LimitationItemsDisplay from './LimitationItemsDisplay';
import LimitationEprInput from './LimitationEprInput';

export enum LimitationErrorSuccess {
  SUCCESS_ADD = 'Success Add Operation',
  SUCCESS_DELETE = 'Success Delete Operation',
  ERROR = 'Error',
}

export enum LimitationFieldType {
  SC_CODE = 'scCode',
  EPR = 'epr',
}

export interface ILimitationMessage {
  type: CustomMessageTypes;
  content: string;
}

const Limitations = () => {
  const ITEMS_OVERALL_CHARACTER_LIMIT = 131072;

  const {sku: skuFromContext} = useContext(StorefrontDataContext);
  const sku = skuFromContext ?? useParams().appName;
  const {setValue, watch, reset, getFieldState} = useForm<LimitationsDataValues>({
    mode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [scCodeLimit, setScCodeLimit] = useState('');
  const [pccLimit, setPccLimit] = useState('');
  const [eprLimit, setEprLimit] = useState('');
  const [scCodeMessage, setScCodeMessage] = useState<ILimitationMessage>();
  const [eprMessage, setEprMessage] = useState<ILimitationMessage>();
  const [defaultValues, setDefaultValues] = useState<LimitationsDataValues>();

  const formSubmitButton = 'Enter';

  const fetchOrderingLimitations = async (sku: string) =>
    new ProductApi(
      new Configuration({
        basePath: productApiBaseLink,
        accessToken: getAccessToken() ?? '',
        middleware: middleware,
      })
    ).getOrderingLimitations({sku});

  const patchEprsOrderingLimitations = async (sku: string, pccEprsInner: PccEprsInner[]) =>
    new ProductApi(
      new Configuration({
        basePath: productApiBaseLink,
        accessToken: getAccessToken() ?? '',
        middleware: middleware,
      })
    ).patchEprsOrderingLimitations({sku, pccEprsInner});

  const patchScCodeOrderingLimitations = async (sku: string, requestBody: string[]) =>
    new ProductApi(
      new Configuration({
        basePath: productApiBaseLink,
        accessToken: getAccessToken() ?? '',
        middleware: middleware,
      })
    ).patchScCodesOrderingLimitations({sku, requestBody});

  useEffect(() => {
    fetchOrderingLimitations(sku)
      .then(data => {
        limitationsMapper(setValue, data);
        setDefaultValues(watch());
        reset(watch());
      })
      .finally(() => setIsLoading(false));
  }, []);

  const {formatMessage} = useIntl();

  const handleMessage = (fieldType: LimitationFieldType, operation?: LimitationErrorSuccess) => {
    const limitationMessage = getCorrectLimitationMessage(fieldType, operation);

    if (fieldType === LimitationFieldType.EPR) {
      setEprMessage(limitationMessage);
    }
    if (fieldType === LimitationFieldType.SC_CODE) {
      setScCodeMessage(limitationMessage);
    }
  };

  const getCorrectLimitationMessage = (
    fieldType: LimitationFieldType,
    operation?: LimitationErrorSuccess
  ): ILimitationMessage => {
    switch (operation) {
      case LimitationErrorSuccess.SUCCESS_ADD:
        return {
          content: parse(
            formatMessage({id: `productDetails.limitations.storefront.${fieldType}Limitation.successAdd`})
          ) as string,
          type: CustomMessageTypes.SUCCESS,
        };
      case LimitationErrorSuccess.SUCCESS_DELETE:
        return {
          content: parse(
            formatMessage({id: `productDetails.limitations.storefront.${fieldType}Limitation.successRemove`})
          ) as string,
          type: CustomMessageTypes.SUCCESS,
        };
      case LimitationErrorSuccess.ERROR:
        return {
          content: parse(
            formatMessage({id: `productDetails.limitations.storefront.${fieldType}Limitation.error`})
          ) as string,
          type: CustomMessageTypes.ERROR,
        };
      default:
        return {content: '', type: CustomMessageTypes.ERROR};
    }
  };

  const setFocusOnInput = (input: string) => {
    const element = document.getElementById(input);
    if (element) {
      element.focus();
    }
  };

  const handleAddScCode = (scCode: string) => {
    if (
      watch(scCodeLimitationsName).includes(scCode) ||
      ITEMS_OVERALL_CHARACTER_LIMIT < watch(scCodeLimitationsName).length + scCode.length
    ) {
      handleMessage(LimitationFieldType.SC_CODE, LimitationErrorSuccess.ERROR);
      return;
    }

    handleMessage(LimitationFieldType.SC_CODE);
    setValue(scCodeLimitationsName, [...watch(scCodeLimitationsName), scCode], {
      shouldDirty: true,
      shouldTouch: true,
    });
    setScCodeLimit('');
    setFocusOnInput('scCode');
  };

  const handleRemoveScCode = (scCode: string) => {
    if (!watch(scCodeLimitationsName).includes(scCode)) {
      handleMessage(LimitationFieldType.SC_CODE, LimitationErrorSuccess.ERROR);
      return;
    }
    setValue(
      scCodeLimitationsName,
      watch(scCodeLimitationsName).filter(item => item !== scCode),
      {
        shouldDirty: true,
        shouldTouch: true,
      }
    );
    handleMessage(LimitationFieldType.SC_CODE);
    setScCodeLimit('');
  };

  const handleAddEpr = (pcc: string, epr: string) => {
    const pccEpr = `${pcc.trim()}_${epr.trim()}`;
    if (
      watch(eprLimitationsName).includes(pccEpr) ||
      ITEMS_OVERALL_CHARACTER_LIMIT < watch(eprLimitationsName).length + pccEpr.length
    ) {
      handleMessage(LimitationFieldType.EPR, LimitationErrorSuccess.ERROR);
      return;
    }
    handleMessage(LimitationFieldType.EPR);
    setValue(eprLimitationsName, [...watch(eprLimitationsName), pccEpr], {shouldDirty: true, shouldTouch: true});
    setPccLimit('');
    setEprLimit('');
    setFocusOnInput('pcc');
  };

  const handleRemoveEpr = (pcc: string, epr: string) => {
    const pccEpr = `${pcc.trim()}_${epr.trim()}`;
    if (!watch(eprLimitationsName).includes(pccEpr)) {
      handleMessage(LimitationFieldType.EPR, LimitationErrorSuccess.ERROR);
      return;
    }

    handleMessage(LimitationFieldType.EPR);
    setValue(
      eprLimitationsName,
      watch(eprLimitationsName).filter(item => item !== pccEpr),
      {
        shouldDirty: true,
        shouldTouch: true,
      }
    );
    setPccLimit('');
    setEprLimit('');
  };

  const handleSaveScCode = () => {
    setDefaultValues({...watch(), [scCodeLimitationsName]: watch(scCodeLimitationsName)});
    patchScCodeOrderingLimitations(sku, watch(scCodeLimitationsName))
      .then(() => {
        handleCancelScCode(watch(scCodeLimitationsName));
        handleMessage(LimitationFieldType.SC_CODE, LimitationErrorSuccess.SUCCESS_ADD);
      })
      .catch(() => handleMessage(LimitationFieldType.SC_CODE, LimitationErrorSuccess.ERROR));
  };

  const handleCancelScCode = (forceItems?: string[]) => {
    const eprLimitations = getFieldState(eprLimitationsName).isDirty && watch(eprLimitationsName);
    reset({
      ...watch(scCodeLimitationsName),
      [scCodeLimitationsName]: forceItems ?? defaultValues[scCodeLimitationsName],
    });
    if (eprLimitations) {
      setValue(eprLimitationsName, eprLimitations, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
    } else {
      setValue(eprLimitationsName, defaultValues[eprLimitationsName], {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false,
      });
    }
  };

  const handleSaveEpr = () => {
    setDefaultValues({...watch(), [eprLimitationsName]: watch(eprLimitationsName)});
    const pccEprsInner: PccEprsInner[] = watch(eprLimitationsName).map(item => {
      const [pcc, epr] = item.split('_');
      return {pcc, epr};
    });

    patchEprsOrderingLimitations(sku, pccEprsInner)
      .then(() => {
        handleCancelEpr(watch(eprLimitationsName));
        handleMessage(LimitationFieldType.EPR, LimitationErrorSuccess.SUCCESS_ADD);
      })
      .catch(() => handleMessage(LimitationFieldType.EPR, LimitationErrorSuccess.ERROR));
  };

  const handleCancelEpr = (forceItems?: string[]) => {
    const scCodeLimit = getFieldState(scCodeLimitationsName).isDirty && watch(scCodeLimitationsName);
    reset({...watch(eprLimitationsName), [eprLimitationsName]: forceItems ?? defaultValues[eprLimitationsName]});
    if (scCodeLimit) {
      setValue(scCodeLimitationsName, scCodeLimit, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
    } else {
      setValue(scCodeLimitationsName, defaultValues[scCodeLimitationsName], {
        shouldDirty: false,
        shouldTouch: false,
        shouldValidate: false,
      });
    }
  };

  const handleChooseScCodeItem = (value: string) => setScCodeLimit(value);

  const handleChooseEprItem = (value: string) => {
    const [pcc, epr] = value.split('_');
    setEprLimit(epr);
    setPccLimit(pcc);
  };

  return isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <div>
      <p className="spark-mar-b-1 spark-caps">
        <FormattedMessage id="productDetails.limitations.storefront.scCodeLimitation" />
      </p>
      <p className="spark-mar-b-0 spark-small">
        <FormattedMessage id="productDetails.limitations.storefront.description.scCode" />
      </p>
      <div className="row spark-mar-t-2">
        <div className="col-xs-12 row spark-mar-r-1" style={{justifyContent: 'space-between'}}>
          <LimitationScCodeInput
            scCode={scCodeLimit}
            watch={watch}
            setScCode={setScCodeLimit}
            handleAdd={handleAddScCode}
            handleRemove={handleRemoveScCode}
            formSubmitButton={formSubmitButton}
            handleCancelScCode={handleCancelScCode}
            handleSaveScCode={handleSaveScCode}
            isDirty={getFieldState(scCodeLimitationsName).isDirty}
          />

          <LimitationItemsDisplay
            items={watch(scCodeLimitationsName)}
            handleChooseItem={handleChooseScCodeItem}
            dataTestid="scCodeLimitationItems"
          />
        </div>
      </div>
      <ErrorContainer className="row spark-mar-t-2">
        <div className="col-xs-12 row col-sm-6">
          {scCodeMessage && scCodeMessage?.content !== '' && (
            <div className="col-xs-12">
              <CustomMessageContainer type={scCodeMessage.type} content={scCodeMessage.content} />
            </div>
          )}
        </div>
      </ErrorContainer>
      <p className="spark-mar-b-1 spark-caps">
        <FormattedMessage id="productDetails.limitations.storefront.eprLimitation" />
      </p>
      <p className="spark-mar-b-0 spark-small">
        <FormattedMessage id="productDetails.limitations.storefront.description.epr" />
      </p>
      <div className="row spark-mar-t-2">
        <div className="col-xs-12 row spark-mar-r-1" style={{justifyContent: 'space-between'}}>
          <LimitationEprInput
            pcc={pccLimit}
            watch={watch}
            setPcc={setPccLimit}
            formSubmitButton={formSubmitButton}
            handleAdd={handleAddEpr}
            handleRemove={handleRemoveEpr}
            epr={eprLimit}
            setEpr={setEprLimit}
            handleCancelEpr={handleCancelEpr}
            handleSaveEpr={handleSaveEpr}
            isDirty={getFieldState(eprLimitationsName).isDirty}
          />

          <LimitationItemsDisplay
            items={watch(eprLimitationsName)}
            handleChooseItem={handleChooseEprItem}
            dataTestid="eprLimitationItems"
          />
        </div>
      </div>
      <ErrorContainer className="row spark-mar-t-2">
        <div className="col-xs-12 row col-sm-6">
          {eprMessage && eprMessage?.content !== '' && (
            <div className="col-xs-12">
              <CustomMessageContainer type={eprMessage.type} content={eprMessage.content} />
            </div>
          )}
        </div>
      </ErrorContainer>
    </div>
  );
};

export const ErrorContainer = styled.div`
  min-height: 80px;
`;

export default Limitations;
