export const publicStandard = {
  'private.standard.description': 'Red App Description',
  'private.standard.diagram': 'Diagram of how an agency would use your Red App',
  'private.standard.pnr': 'If applicable, provide details about PNR documentation usage`',
  'private.standard.regionalization':
    'If applicable, list of specific countries or/and regions to which your Red App access should be limited to',
  'private.standard.payment': 'If applicable, details about payment model (no values needed)',
  'private.standard.services': 'List of services with which your Red App will communicate',
  'private.standard.technologies': 'List of Sabre Red 360 technologies will your Red App be built with',
};
