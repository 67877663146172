const insurance = {
  'insurance.intro':
    'Developer shall purchase and maintain in force and effect for the entire term of this Addendum remains in force, and any extensions thereof, insurance coverage of the types and with limits of not less than those set forth in Minimum Coverage Limits, with insurers having A.M. Best ratings of at least A VIII.  Insurance notification to Sabre for insurance certificates, non-renewal or material changes to policies shall be sent to:',
  'insurance.address.name': 'Sabre GLBL Inc.',
  'insurance.address.department': 'Attn: Treasury Department',
  'insurance.address.firstLine': '3150 Sabre Drive',
  'insurance.address.secondLine': 'Southlake, Texas 76092',
  'insurance.requirements':
    'Before starting work under this Addendum, Developer shall require all insurance companies issuing such policies of insurance to provide certificates of insurance to Sabre to confirm that such coverage are currently in effect.  The certificates of insurance shall indicate that the insurers will endeavor to provide at least thirty (30) days advance written notice of cancellation or non-renewal to Sabre.  The certificates shall also state that a waiver of subrogation in favor of Sabre has been endorsed onto Developer’s Commercial General Liability, Automobile Liability, and Workers’ Compensation/Employers’ Liability Insurance.',
  'insurance.commercialGeneralLiability':
    'With respect to Commercial General Liability and Comprehensive Automobile Liability:',
  'insurance.firstLiability': 'Sabre, its officers, agents and employees shall be named as additional insured;',
  'insurance.secondLiability':
    'A waiver of subrogation in favor of Sabre shall be endorsed onto Developer’s Commercial General Liability and Automobile Liability;',
  'insurance.thirdLiability':
    'The insurance certificate shall indicate that the liability assumed by Developer has been specifically insured under the contractual liability section of the liability insurance policies;',
  'insurance.fourthLiability':
    'The liability policy shall be primary without right of contribution from any insurance which is carried by Sabre.',
  'insurance.coverageLimits': 'MINIMUM COVERAGE LIMITS:',
  'insurance.firstLimit':
    'Commercial General Liability Insurance, including advertising and personal injury coverage, products, operational and contractual liability and broad form property damage liability coverage, shall be at least one million dollars ($1,000,000), combined single limit.',
  'insurance.secondLimit':
    'Umbrella Insurance coverage shall be carried for at least five million dollars ($5,000,000) per claim and annual aggregate.',
  'insurance.thirdLimit':
    'Comprehensive Automobile Liability Insurance shall be carried covering claims arising from all owned, hired and non-owned licensed vehicles.  A total combined limit of primary and/or excess coverage in the amount of $2,000,000 per occurrence, combined single limit, for bodily injury and property damage.',
  'insurance.fourthLimit': 'Worker’s Compensation Insurance shall be carried at least to statutory limits.',
  'insurance.fifthLimit':
    'Employer’s Liability Insurance shall be carried to at least a total combined limit of primary and/or excess coverage in the amount of:',
  'insurance.sixthLimit':
    'Employee Dishonesty and Corporate Fraud Insurance (for loss arising out of or related to any fraudulent or dishonest acts committed by Developer’s Employees, acting alone or in collusion with others) shall be carried for at least five million dollars ($5,000,000) per claim and annual aggregate.',
  'insurance.seventhLimit':
    'Errors and Omissions Insurance /Professional Liability/Data Processing Insurance shall (a) cover the liability of Developer by reason of any actual or alleged error, omission, negligent act or wrongful act of Developer committed in rendering or failing to render any products or services in accordance with this Addendum; the liability shall include damage to and loss of intangible property, including data lost or incorrectly transmitted or recorded; (b) include privacy liability and media liability coverage; (c) provide limits of not less than $1,000,000 per occurrence; and  (d) be maintained for a period of not less than two years after the expiration of this Addendum.',
  'insurance.firstAmount': 'i.U.S. $1,000,000 Each Accident',
  'insurance.secondAmount': 'i.i. U.S. $1,000,000 Policy Limit/Disease',
  'insurance.thirdAmount': 'i.i.i. U.S. $1,000,000 Each Employee/Disease',
  'insurance.termsAndCondition.label': 'I acknowledge the Insurance General Requirements',
  'insurance.requiredFields.message': 'Please accept Insurance General Requirements.',
};

export default insurance;
