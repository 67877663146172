export const countriesInRegion = {
  'EMEA-Africa': {
    DZ: 'Algeria',
    AO: 'Angola',
    BJ: 'Benin',
    BW: 'Botswana',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CM: 'Cameroon',
    CV: 'Cape Verde',
    CF: 'Central African Republic',
    TD: 'Chad',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Democratic Republic of Congo',
    DJ: 'Djibouti',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    ET: 'Ethiopia',
    GA: 'Gabon',
    GM: 'Gambia',
    GH: 'Ghana',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    CI: 'Ivory Coast',
    KE: 'Kenya',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    MG: 'Madagascar',
    MW: 'Malawi',
    ML: 'Mali',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MA: 'Morocco',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NE: 'Niger',
    NG: 'Nigeria',
    RE: 'Réunion',
    RW: 'Rwanda',
    ST: 'São Tomé & Príncipe',
    SN: 'Senegal',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SO: 'Somalia',
    ZA: 'South Africa',
    SS: 'South Sudan',
    SD: 'Sudan',
    SZ: 'Swaziland',
    TZ: 'Tanzania',
    TG: 'Togo',
    TN: 'Tunisia',
    UG: 'Uganda',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
  'EMEA-Europe': {
    AL: 'Albania',
    AD: 'Andorra',
    AM: 'Armenia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BY: 'Belarus',
    BE: 'Belgium',
    BA: 'Bosnia & Herzegovina',
    BG: 'Bulgaria',
    HR: 'Croatia',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    EE: 'Estonia',
    FO: 'Faroe Islands',
    FI: 'Finland',
    FR: 'France',
    GE: 'Georgia',
    DE: 'Germany',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    HU: 'Hungary',
    IS: 'Iceland',
    IE: 'Ireland',
    IT: 'Italy',
    KZ: 'Kazakhstan',
    KG: 'Kyrgyzstan',
    LV: 'Latvia',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MK: 'Macedonia',
    MT: 'Malta',
    MD: 'Moldova',
    ME: 'Montenegro',
    NL: 'Netherlands',
    NO: 'Norway',
    PL: 'Poland',
    PT: 'Portugal',
    RO: 'Romania',
    RU: 'Russia',
    SM: 'San Marino',
    RS: 'Serbia',
    SK: 'Slovakia',
    SI: 'Slovenia',
    ES: 'Spain',
    SE: 'Sweden',
    CH: 'Switzerland',
    TJ: 'Tajikistan',
    TR: 'Turkey',
    UA: 'Ukraine',
    GB: 'United Kingdom',
    UZ: 'Uzbekistan',
  },
  'EMEA-Middle East': {
    BH: 'Bahrain',
    EG: 'Egypt',
    IR: 'Iran',
    IQ: 'Iraq',
    IL: 'Israel',
    JO: 'Jordan',
    KW: 'Kuwait',
    LB: 'Lebanon',
    OM: 'Oman',
    PS: 'Palestinian Territories',
    QA: 'Qatar',
    SA: 'Saudi Arabia',
    SY: 'Syria',
    AE: 'United Arab Emirates',
    YE: 'Yemen',
  },
  'NAM-North America': {
    CA: 'Canada',
    US: 'United States',
  },
  'APAC-North Asia': {
    CN: 'China',
    HK: 'Hong Kong SAR China',
    JP: 'Japan',
    MO: 'Macau SAR China',
    MN: 'Mongolia',
    KR: 'South Korea',
    TW: 'Taiwan',
  },
  'APAC-Oceania': {
    AS: 'American Samoa',
    AU: 'Australia',
    CK: 'Cook Islands',
    FJ: 'Fiji',
    PF: 'French Polynesia',
    GU: 'Guam',
    KI: 'Kiribati',
    MH: 'Marshall Islands',
    FM: 'Micronesia',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    MP: 'Northern Mariana Islands',
    PW: 'Palau',
    PG: 'Papua New Guinea',
    SB: 'Solomon Islands',
    TO: 'Tonga',
    VU: 'Vanuatu',
    WF: 'Wallis & Futuna',
  },
  'LAC-South and Central America': {
    AI: 'Anguilla',
    AG: 'Antigua & Barbuda',
    AR: 'Argentina',
    AW: 'Aruba',
    BS: 'Bahamas',
    BB: 'Barbados',
    BZ: 'Belize',
    BM: 'Bermuda',
    BO: 'Bolivia',
    BR: 'Brazil',
    VG: 'British Virgin Islands',
    KY: 'Cayman Islands',
    CL: 'Chile',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CW: 'Curaçao',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    SV: 'El Salvador',
    GF: 'French Guiana',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GT: 'Guatemala',
    GY: 'Guyana',
    HT: 'Haiti',
    HN: 'Honduras',
    JM: 'Jamaica',
    MQ: 'Martinique',
    MX: 'Mexico',
    MS: 'Montserrat',
    NI: 'Nicaragua',
    PA: 'Panama',
    PY: 'Paraguay',
    PE: 'Peru',
    PR: 'Puerto Rico',
    KN: 'St. Kitts & Nevis',
    LC: 'St. Lucia',
    VC: 'St. Vincent & Grenadines',
    SX: 'St. Maarten',
    BQ: 'St. Eustatius',
    SR: 'Suriname',
    TT: 'Trinidad & Tobago',
    TC: 'Turks & Caicos Islands',
    UY: 'Uruguay',
    VE: 'Venezuela',
  },
  'APAC-South Asia': {
    AF: 'Afghanistan',
    BD: 'Bangladesh',
    BT: 'Bhutan',
    IN: 'India',
    MV: 'Maldives',
    NP: 'Nepal',
    PK: 'Pakistan',
    LK: 'Sri Lanka',
  },
  'APAC-South East Asia': {
    BN: 'Brunei',
    KH: 'Cambodia',
    ID: 'Indonesia',
    LA: 'Laos',
    MY: 'Malaysia',
    MM: 'Myanmar (Burma)',
    PH: 'Philippines',
    SG: 'Singapore',
    TH: 'Thailand',
    VN: 'Vietnam',
  },
};

export const regions = {
  'EMEA-Africa': 'Africa',
  'EMEA-Europe': 'Europe',
  'EMEA-Middle East': 'Middle East',
  'NAM-North America': 'North America',
  'APAC-North Asia': 'North Asia',
  'APAC-Oceania': 'Oceania',
  'LAC-South and Central America': 'South and Central America',
  'APAC-South Asia': 'South Asia',
  'APAC-South East Asia': 'South East Asia',
};

export type RegionNames = keyof typeof regions;

export const createCountriesWithoutRegions = (regions: Array<RegionNames | string>, countries: Array<string>) => {
  if (!regions.length || !countries.length) {
    return countries;
  }

  const countriesInRegions = regions.map(region => Object.keys(countriesInRegion[region as RegionNames])).flat();

  return countries.filter(country => !countriesInRegions.includes(country));
};

export const createCountriesInRegions = (regions: Array<RegionNames | string>, countries: Array<string>) => {
  if (!regions.length || regions.includes('Global') || regions.includes('None')) {
    return countries;
  }
  const countriesInRegions = regions.map(region => Object.keys(countriesInRegion[region as RegionNames])).flat();

  return Array.from(new Set([...countriesInRegions, ...countries]));
};
