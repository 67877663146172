import styled from 'styled-components';
import React from 'react';
import colors from '@scm/components/assets/colors';

const FormFooter = ({listItemsContent}: {listItemsContent: Array<string | JSX.Element | JSX.Element[]>}) => {
  return (
    <FormFooterContainer>
      <ol>
        {listItemsContent.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ol>
    </FormFooterContainer>
  );
};

const FormFooterContainer = styled.div`
  background-color: ${colors.grey150};
  padding: 10px 20px;
  & ol {
    margin-bottom: 0;
    font-size: 1.15rem;
  }
  & li {
    margin: 0;
    line-height: 140%;
  }
`;

export default FormFooter;
