import React, {useContext, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import TestersTable, {DeleteTesterHandlerType, Tester, testersHeaders} from './TesterTable';
import {Button, Tooltip} from '@sabre/spark-react-core';
import {Loading, Pagination} from '@scm/components';
import styled from 'styled-components';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken, isAllowedToAddRemoveTesters} from '@scm/authentication/utils/authentication';
import {Configuration, ProductsApi, RemoveBetaTesterRequest} from '@scm/product-components/generated/certification';
import {ToastType} from '@sabre/spark-react-core/types';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {StorefrontDataContext} from '../../../storefrontData/dataProvider/StorefrontDataProvider';
import {editedTesterName, isTesterAddingName, testersName} from '../../../storefrontData/interfaces/TesterDataValues';
import {redAppIdName} from '@scm/product-components/pages/storefrontData/interfaces/AppInfoDataValues';
import {LoadingContainer} from '@scm/product-components/pages/productDetails/productTabs/proposalData/ProposalData';
import {ButtonWithTooltipContainer, TooltipHandler} from '@scm/components/form/ButtonWithTooltip';
import {redAppSupportEmail} from '@scm/components/utils/common';
import {hrefStringCreator} from '@scm/components/messaging/HrefElement';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import parse from 'html-react-parser';

const ProdTesters = () => {
  const {formatMessage} = useIntl();
  const paginationInterval = 10;
  const [currentElement, setCurrentElement] = useState(0);
  const {methodsProductForm, methodsTesterForm, reloadTestersTab, setReloadTestersTab} =
    useContext(StorefrontDataContext);
  const {watch: watchTesterForm, setValue: setValueTesterForm} = methodsTesterForm;
  const {watch: watchProductForm} = methodsProductForm;
  const productDetailsName = 'productDetails.fetch';
  const allowedNumOfTesters = 50;
  const disableAddTesterBtn = watchTesterForm(testersName).length >= allowedNumOfTesters;

  async function removeBetaTester(removeBetaTesterRequest: RemoveBetaTesterRequest) {
    return await new ProductsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).removeBetaTester(removeBetaTesterRequest);
  }

  const paginationClickHandler = (evt: React.MouseEvent<HTMLAnchorElement>, moveIndex?: number) => {
    if (moveIndex === undefined) {
      setCurrentElement(
        +(
          evt.currentTarget.firstChild as unknown as {
            textContent: string;
          }
        )?.textContent - 1
      );
    } else {
      setCurrentElement(currentElement + moveIndex);
    }
  };

  const deleteTestersHandler: DeleteTesterHandlerType = (nr: string) => {
    const testerToDelete = watchTesterForm(testersName).find((tester: Tester) => tester.nr === nr);
    const removeTesterRequest: RemoveBetaTesterRequest = {
      id: watchProductForm(redAppIdName),
      betaTesterRequest: {
        pcc: testerToDelete?.pcc as string,
        epr: testerToDelete?.sabreId as string,
      },
    };

    removeBetaTester(removeTesterRequest)
      .then(() => {
        setValueTesterForm(
          testersName,
          watchTesterForm(testersName)
            .filter((tester: Tester) => tester.nr !== nr)
            .map((tester: Tester, index: number) => ({
              ...tester,
              nr: String(index + 1),
            }))
        );

        if (!(watchTesterForm(testersName).length % paginationInterval)) {
          setCurrentElement(beforePage => (!beforePage ? 0 : beforePage - 1));
        }

        setReloadTestersTab(true);
      })
      .catch(err => {
        err.response.json().catch(() => {});
        if (err.response.status === 422) {
          openToast(
            createMessageString(formatMessage, productDetailsName, true, true),
            formatMessage(
              {id: 'productDetails.fetch.testerListUpdateFailed.description'},
              {
                email: hrefStringCreator(redAppSupportEmail),
              }
            ),
            ToastType.WARNING,
            'spark-icon-alert-triangle',
            true
          );
        }
      });
  };

  return reloadTestersTab ? (
    <LoadingContainer className="sparkspark-mar-t-2">
      <Loading sizing="md" />
    </LoadingContainer>
  ) : (
    <>
      <p className="spark-mar-r-2">{parse(formatMessage({id: 'tabs.testers.description'}))}</p>
      <TestersTable
        headers={testersHeaders}
        deleteTestersHandler={deleteTestersHandler}
        currentPaginationPage={currentElement}
        isProdTestersTable={true}
        sortableHeaders={['pcc', 'created']}
      />
      {isAllowedToAddRemoveTesters() && (
        <TableUtils className="row spark-mar-b-1">
          {!watchTesterForm(isTesterAddingName) ? (
            disableAddTesterBtn ? (
              <ButtonWithTooltipContainer>
                <Tooltip
                  toggleEl={
                    <TooltipHandler style={{}}>
                      <Button textOnly className="spark-mar-l-2 spark-mar-t-1 spark-pad-l-1" disabled>
                        <FormattedMessage id="tabs.testers.addButton.label" />
                      </Button>
                    </TooltipHandler>
                  }
                >
                  <FormattedMessage id={'tabs.testers.addButton.tooltip'} />
                </Tooltip>
              </ButtonWithTooltipContainer>
            ) : (
              <Button
                textOnly
                onClick={() => {
                  setValueTesterForm(isTesterAddingName, true);
                  setValueTesterForm(editedTesterName, {
                    nr: String(watchTesterForm(testersName).length + 1),
                    pcc: '',
                    sabreId: '',
                    created: new Date(),
                  });
                }}
                className="spark-mar-l-2 spark-mar-t-1 spark-pad-l-1"
              >
                {formatMessage({id: 'tabs.testers.addButton.label'})}
              </Button>
            )
          ) : (
            <div />
          )}
          {watchTesterForm(testersName).length > paginationInterval && (
            <Pagination
              allDataLength={watchTesterForm(testersName).length}
              currentElement={currentElement}
              paginationInterval={paginationInterval}
              clickHandler={paginationClickHandler}
            />
          )}
        </TableUtils>
      )}
    </>
  );
};

export const TableUtils = styled.div`
  align-items: center;
  justify-content: space-between;
`;

export default ProdTesters;
