import {Badge, Icon} from '@sabre/spark-react-core';
import {BadgeStatus, IconSize, IconStyle, ToastType} from '@sabre/spark-react-core/types';
import React, {useContext, useState} from 'react';
import {Configuration, RoadmapApi, RoadmapStatus, UserRoadmapResponse, Vote} from '../../generated/roadmap';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {FormattedDateParts, FormattedMessage, useIntl} from 'react-intl';
import {getAccessToken, isSabreAdminRole} from '@scm/authentication/utils/authentication';
import RoadmapItemActionMenu from './RoadmapItemActionMenu';
import {RoadmapContext} from '../../components/RoadmapProvider';
import {roadmapApiBaseLink} from '../../assets/apiBaseLink';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const RoadmapItem = ({roadmap}: {roadmap: UserRoadmapResponse}) => {
  const {handleOpenVotes} = useContext(RoadmapContext);
  const {id, status, subject, description, releaseDate, userVote, votesFor, votesAgainst} = roadmap;
  const [vote, setVote] = useState<Vote | undefined>(userVote);
  const [voteCount, setVoteCount] = useState<number>((votesFor ?? 0) - (votesAgainst ?? 0));
  const isAdmin = isSabreAdminRole();
  const [isVotingDisabled, setIsVotingDisabled] = useState(
    status === RoadmapStatus.Completed || status === RoadmapStatus.InProgress
  );

  const {formatMessage} = useIntl();

  const statusMap = {
    'Needs Feedback': BadgeStatus.WARNING,
    'In Progress': BadgeStatus.NEUTRAL,
    Completed: BadgeStatus.SUCCESS,
  };

  const saveUserVote = (vote: Vote) =>
    new RoadmapApi(
      new Configuration({
        basePath: roadmapApiBaseLink,
        accessToken: getAccessToken() ?? '',
        middleware: middleware,
      })
    ).voteForRoadmap({id: id ?? '', voteRequest: {vote}});

  const handleVoteFor = () => {
    if (isVotingDisabled) return;
    setIsVotingDisabled(true);
    let userCurrentVote: Vote | undefined = Vote.For;
    if (vote === Vote.For) {
      userCurrentVote = undefined;
      setVoteCount(prevCount => prevCount - 1);
    } else {
      setVoteCount(prevCount => prevCount + (vote === Vote.Against ? 2 : 1));
    }
    saveUserVote(userCurrentVote as Vote)
      .then(() => setVote(userCurrentVote))
      .catch(() =>
        openToast(
          createMessageString(formatMessage, 'roadmap.modal', true, true),
          createMessageString(formatMessage, 'roadmap.modal', false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      )
      .finally(() => setIsVotingDisabled(false));
  };

  const handleVoteAgainst = () => {
    if (isVotingDisabled) return;
    let userCurrentVote: Vote | undefined = Vote.Against;
    if (vote === Vote.Against) {
      userCurrentVote = undefined;
      setVoteCount(prevCount => prevCount + 1);
    } else {
      setVoteCount(prevCount => prevCount - (vote === Vote.For ? 2 : 1));
    }
    saveUserVote(userCurrentVote as Vote)
      .then(() => setVote(userCurrentVote))
      .catch(() =>
        openToast(
          createMessageString(formatMessage, 'roadmap.modal', true, true),
          createMessageString(formatMessage, 'roadmap.modal', false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      );
  };

  const optionButton = isAdmin ? <RoadmapItemActionMenu roadmapId={id ?? ''} /> : null;

  return (
    <RoadmapItemWrapper className="col-xs-12 spark-flex spark-pad-.5">
      <InteractionSection className="col-xs-1 spark-flex spark-pad-.5">
        <RatingItem
          onClick={handleVoteFor}
          disabled={isVotingDisabled}
          voted={vote === Vote.For}
          className="spark-pad-t-.5 spark-pad-b-.5 spark-flex"
          role="button"
          tabIndex={0}
          onKeyDown={evt => {
            if (evt.key === 'Enter') {
              handleVoteFor();
            }
          }}
          aria-label="Vote for"
          data-testid="voteFor"
        >
          <Icon
            iconStyle={vote === Vote.For ? IconStyle.FILL : IconStyle.LINE}
            size={IconSize.LARGE}
            name="arrow-chevron-up"
          />
        </RatingItem>
        <RatesWrapper
          isAdmin={isAdmin}
          onClick={() => isAdmin && handleOpenVotes(id ?? '')}
          className="spark-flex spark-bold text-center spark-pad-1"
          role="button"
          tabIndex={0}
          onKeyDown={evt => {
            if (evt.key === 'Enter') {
              isAdmin && handleOpenVotes(id ?? '');
            }
          }}
        >
          <span data-testid="votes">
            {voteCount >= 0 && <FormattedMessage id="common.plus" />}
            {voteCount}
          </span>
        </RatesWrapper>
        <RatingItem
          onClick={handleVoteAgainst}
          disabled={isVotingDisabled}
          voted={vote === Vote.Against}
          className="spark-pad-t-.5 spark-pad-b-.5 spark-flex"
          role="button"
          tabIndex={0}
          onKeyDown={evt => {
            if (evt.key === 'Enter') {
              handleVoteAgainst();
            }
          }}
          aria-label="Vote against"
          data-testid="voteAgainst"
        >
          <Icon
            size={IconSize.LARGE}
            iconStyle={vote === Vote.Against ? IconStyle.FILL : IconStyle.LINE}
            name={'arrow-chevron-down'}
          />
        </RatingItem>
      </InteractionSection>
      <div className="divider spark-mar-.5" />
      <ContentBlock className="spark-pad-.5 spark-flex">
        <div>
          <RoadmapItemHeaderBox className="col-xs-12 spark-pad-l-0 spark-pad-r-0 spark-flex spark-align-items-center spark-mar-b-.5">
            <div className="spark-flex">
              <BadgeWrapper
                small
                className="spark-mar-r-1"
                label={status ?? ''}
                status={statusMap[status]}
                data-testid="featureStatus"
              />
              <Title className="spark-bold spark-pad-t-0 spark-mar-b-0" data-testid="featureTitle">
                {subject}
              </Title>
            </div>
            {optionButton}
          </RoadmapItemHeaderBox>
          <div className="spark-small spark-mar-r-2" data-testid="featureDescription">
            {description}
          </div>
        </div>
        <div className="blank-separator" />
        <div className="spark-small">
          {releaseDate && (
            <>
              <span className="spark-mar-r-.5">
                <FormattedMessage
                  id={
                    status === RoadmapStatus.InProgress ? 'roadmap.item.targetReleaseDate' : 'roadmap.item.releaseDate'
                  }
                />
              </span>
              <FormattedDateParts year="numeric" month="long" value={releaseDate}>
                {({0: {value: firstValue}, 2: {value: secondValue}}) => (
                  <CustomBadge status={status} className="spark-pad-.5" data-testid="featureReleaseDate">
                    {firstValue} {secondValue}
                  </CustomBadge>
                )}
              </FormattedDateParts>
            </>
          )}
        </div>
      </ContentBlock>
    </RoadmapItemWrapper>
  );
};

const BadgeWrapper = styled(Badge)`
  flex: none;
`;

const RoadmapItemWrapper = styled.div`
  display: flex;
  min-height: 155px;
  flex-direction: row;

  .divider {
    min-width: 2px;
    max-width: 2px;
    background: ${colors.grey100};
  }

  .blank-separator {
    height: 10px;
  }
`;

const InteractionSection = styled.div`
  flex-direction: column;
  justify-content: center;

  * {
    justify-content: center;
    border-radius: 10px;
  }
`;

const RoadmapItemHeaderBox = styled.div`
  justify-content: space-between;
`;

const RatingItem = styled.div<{voted?: boolean; disabled?: boolean}>`
  font-size: 2.4rem !important;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${props => (props.voted ? colors.grey100 : colors.white)} !important;

  &&:hover {
    background: ${props => !props.disabled && colors.grey50} !important;
  }
`;

const RatesWrapper = styled.div<{isAdmin: boolean}>`
  color: ${colors.grey700};
  cursor: ${props => (props.isAdmin ? 'pointer' : 'default')};

  &&:hover {
    ${props => props.isAdmin && `background: ${colors.grey100}`};
  }
`;

const ContentBlock = styled.div`
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.p`
  font-size: 1.58rem;
  line-height: 2rem;
  padding-top: 20px;
  font-weight: 700;
  color: ${colors.grey700};
`;

const CustomBadge = styled.span<{status: RoadmapStatus}>`
  background-color: ${props => (props.status === RoadmapStatus.InProgress ? colors.grey150 : colors.disabledGrey)};
  color: ${props => (props.status === RoadmapStatus.InProgress ? colors.grey1000 : colors.grey50)};
  font-weight: normal;
  border-radius: 8px;
`;
export default RoadmapItem;
