import React, {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FormProvider, Resolver, SubmitHandler, useForm, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import useYupValidationResolver, {getFieldMaxLength, isFieldRequired} from '../../components/useYupValidationResolver';
import schema from '../../../onboarding-schema/onboardDeveloperPartner.schema';
import {Fields} from '../../components/EndMessage';
import styled from 'styled-components';
import EventCategories from '@scm/partner-network/src/googleAnalytics/EventCategories';
import {GaContext} from '@scm/partner-network/src/googleAnalytics/GoogleAnalyticsProvider';
import {Button} from '@sabre/spark-react-core';
import SparkInput from '@scm/components/form/SparkInput';
import SparkDropDown from '@scm/components/form/SparkDropDown';
import countries from '@scm/components/assets/pnCountries';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {OnboardingProviderType, onboardingProviderTypeName, ProviderTypeValue} from '../../JoinUsContentBox';
import Input from '@scm/partner-network/src/spark/input/Input';
import {
  CompanyYearsInBusinessType,
  NumberOfEmployeesType,
  OnboardDeveloperPartnerRequest,
} from '../../../generated/onboardings';
import {Text} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import {makePccUppercase, PCC_LONG_MESSAGE, PCC_MAX_LENGTH} from '@scm/components/pccEpr/pccEprSchema';

const ContactDetails = ({
  saveHandler,
  initValue,
  setCurrentStep,
  watchProviderTypeValue,
  setProviderTypeValue,
}: {
  saveHandler: React.Dispatch<React.SetStateAction<OnboardDeveloperPartnerRequest>>;
  initValue: OnboardDeveloperPartnerRequest;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  watchProviderTypeValue: UseFormWatch<ProviderTypeValue>;
  setProviderTypeValue: UseFormSetValue<ProviderTypeValue>;
}) => {
  const {formatMessage} = useIntl();
  const resolver = useYupValidationResolver(
    schema.pick(['contactDetails', 'companyInformation', 'sabreCustomerAccountInfo'])
  );
  const methods = useForm<OnboardDeveloperPartnerRequest>({
    mode: 'onChange',
    resolver: resolver as Resolver<OnboardDeveloperPartnerRequest>,
  });

  const {logEvent} = useContext(GaContext);
  const formSubmitHandler: SubmitHandler<OnboardDeveloperPartnerRequest> = (data: OnboardDeveloperPartnerRequest) => {
    saveHandler(data);
    logEvent({category: EventCategories.JoinUs, action: 'Moved to T&C step'});
    setCurrentStep(prevState => prevState + 1);
  };
  const isRequired =
    watchProviderTypeValue(onboardingProviderTypeName) === OnboardingProviderType['Sabre Developer Partner'];

  const previousStep = () => {
    setCurrentStep(prevState => prevState - 1);
    setProviderTypeValue(onboardingProviderTypeName, undefined);
  };

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
        <div className="spark-panel">
          <div className="spark-panel__content">
            <StyledH2>
              <FormattedMessage id="joinUsPage.form.contactDetails.title" />
            </StyledH2>
            <Text>
              <FormattedMessage id="joinUsPage.form.contactDetails.description" />
            </Text>
            <Fields>
              <StyledH3>
                <FormattedMessage id="contactDetails.contactDetails.title" />
              </StyledH3>
              <SparkInput
                name="contactDetails.firstName"
                value={initValue.contactDetails.firstName}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.firstName.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.firstName.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.firstName?.message}
                isRequired={isFieldRequired('contactDetails.firstName', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.firstName', schema)}
              />
              <SparkInput
                name="contactDetails.lastName"
                value={initValue.contactDetails.lastName}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.lastName.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.lastName.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.lastName?.message}
                isRequired={isFieldRequired('contactDetails.lastName', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.lastName', schema)}
              />
              <SparkInput
                name="contactDetails.titleInCompany"
                value={initValue.contactDetails.titleInCompany}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.companyTitle.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.companyTitle.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.titleInCompany?.message}
                isRequired={isFieldRequired('contactDetails.titleInCompany', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.titleInCompany', schema)}
              />
              <SparkInput
                name="contactDetails.email"
                value={initValue.contactDetails.email}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.email.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.email.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.email?.message}
                isRequired={isFieldRequired('contactDetails.email', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.email', schema)}
              />
              <SparkInput
                name="contactDetails.phone"
                value={initValue.contactDetails.phone}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.phone.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.phone.placeholder'})}
                errorMessage={methods.formState.errors.contactDetails?.phone?.message}
                isRequired={isFieldRequired('contactDetails.phone', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('contactDetails.phone', schema)}
              />
              <StyledH3>
                <FormattedMessage id="joinUsPage.form.companyInformation.title" />
              </StyledH3>
              <SparkInput
                name="companyInformation.name"
                value={initValue.companyInformation.name}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.fullCompanyName.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.fullCompanyName.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.name?.message}
                isRequired={isFieldRequired('companyInformation.name', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.name', schema)}
              />
              <SparkInput
                name="companyInformation.website"
                value={initValue.companyInformation.website}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.url.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.url.placeholder'})}
                errorMessage={formatMessage({id: 'validation.fieldDoesNotMatchPattern.website'})}
                isRequired={isFieldRequired('companyInformation.website', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.website', schema)}
              />
              <SparkDropDown
                name="companyInformation.numberOfEmployees"
                defaultValue={initValue.companyInformation.numberOfEmployees ?? ''}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.staff.label'})}
                errorMessage={methods.formState.errors.companyInformation?.numberOfEmployees?.message}
                options={getOptions(NumberOfEmployeesType)}
                isRequired={isRequired}
              />
              <SparkDropDown
                name="companyInformation.yearsInBusiness"
                label={formatMessage({id: 'joinUsPage.form.contactDetails.companyYears.label'})}
                defaultValue={initValue.companyInformation.yearsInBusiness ?? ''}
                errorMessage={methods.formState.errors.companyInformation?.yearsInBusiness?.message}
                options={getOptions(CompanyYearsInBusinessType)}
                isRequired={isRequired}
              />
              <SparkInput
                name="companyInformation.businessProfile"
                value={initValue.companyInformation.businessProfile}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.solutionDescription.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.solutionDescription.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.businessProfile?.message}
                isRequired={isFieldRequired('companyInformation.businessProfile', schema)}
                hasCharacterCounter
                isMultiline
                maxLength={getFieldMaxLength('companyInformation.businessProfile', schema)}
              />
              <StyledH3>
                <FormattedMessage id="joinUsPage.form.companyLocation.title" />
              </StyledH3>
              <SparkDropDown
                name="companyInformation.locationCountry"
                defaultValue={countries.DEFAULT}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.countryLocated.label'})}
                errorMessage={methods.formState.errors.companyInformation?.locationCountry?.message}
                options={countries}
                isRequired={isRequired}
              />
              <SparkInput
                name="companyInformation.locationProvince"
                value={initValue.companyInformation.locationProvince}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.provinceLocated.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.provinceLocated.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.locationProvince?.message}
                isRequired={isRequired}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.locationProvince', schema)}
              />
              <StyledH3>
                <FormattedMessage id="joinUsPage.form.registeredCompanyAddress.title" />
              </StyledH3>
              <SparkInput
                name="companyInformation.address.line1"
                value={initValue.companyInformation.address.line1}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.addressLine1.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.addressLine1.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.address?.line1?.message}
                isRequired={isFieldRequired('companyInformation.address.line1', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.line1', schema)}
              />
              <SparkInput
                name="companyInformation.address.line2"
                value={initValue.companyInformation.address.line2}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.addressLine2.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.addressLine2.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.address?.line2?.message}
                isRequired={isFieldRequired('companyInformation.address.line2', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.line2', schema)}
              />
              <SparkInput
                name="companyInformation.address.city"
                value={initValue.companyInformation.address.city}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.city.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.city.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.address?.city?.message}
                isRequired={isFieldRequired('companyInformation.address.city', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.city', schema)}
              />
              <SparkInput
                name="companyInformation.address.province"
                value={initValue.companyInformation.address.province}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.stateProvinceName.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.stateProvinceName.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.address?.province?.message}
                isRequired={isFieldRequired('companyInformation.address.province', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.province', schema)}
              />
              <SparkInput
                name="companyInformation.address.postalCode"
                value={initValue.companyInformation.address.postalCode}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.postalCode.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.postalCode.placeholder'})}
                errorMessage={methods.formState.errors.companyInformation?.address?.postalCode?.message}
                isRequired={isFieldRequired('companyInformation.address.postalCode', schema)}
                hasCharacterCounter
                maxLength={getFieldMaxLength('companyInformation.address.postalCode', schema)}
              />
              <SparkDropDown
                name="companyInformation.address.country"
                defaultValue={countries.DEFAULT}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.country.label'})}
                errorMessage={methods.formState.errors.companyInformation?.address?.country?.message}
                options={countries}
                isRequired={isFieldRequired('companyInformation.address.country', schema)}
              />
              <StyledH3>
                <FormattedMessage id="joinUsPage.form.contactDetails.potentialSabreCustomer.title" />
              </StyledH3>
              <Input
                name="sabreCustomerAccountInfo.name"
                value={initValue.sabreCustomerAccountInfo.name}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.potentialSabreCustomer.name.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.potentialSabreCustomer.name.label'})}
                errorMessage={methods.formState.errors.sabreCustomerAccountInfo?.name?.message}
                isRequired={isRequired}
                hasCharacterCounter
                maxLength={getFieldMaxLength('sabreCustomerAccountInfo.name', schema)}
              />
              <Input
                name="sabreCustomerAccountInfo.pcc"
                value={initValue.sabreCustomerAccountInfo.pcc}
                label={formatMessage({id: 'joinUsPage.form.contactDetails.potentialSabreCustomer.pcc.label'})}
                placeholder={formatMessage({id: 'joinUsPage.form.contactDetails.potentialSabreCustomer.pcc.label'})}
                errorMessage={PCC_LONG_MESSAGE}
                isRequired={isRequired}
                hasCharacterCounter
                maxLength={PCC_MAX_LENGTH}
                onInput={evt => (evt.target.value = makePccUppercase(evt.target.value))}
              />
            </Fields>
          </div>
        </div>
        <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
          <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
            <FormattedMessage id="joinUsPage.form.backButton" />
          </Button>
          <Button type="submit" disabled={!methods.formState.isValid} size={ButtonSize.SMALL}>
            <FormattedMessage id="joinUsPage.form.button" />
          </Button>
        </nav>
      </form>
    </FormProvider>
  );
};

export const StyledH2 = styled.h2`
  font-size: 2.08rem;
  font-weight: 400;
  line-height: 3rem;
`;
export const StyledH3 = styled.h3`
  font-size: 1.58rem;
  line-height: 2rem;
  padding-top: 20px;
`;

function getOptions(object: object) {
  return Object.fromEntries(Object.values(object).map(value => [value, value]));
}

export default ContactDetails;
