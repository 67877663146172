export const hrefStringCreator = (email: string) =>
  `<a class="toast-email" href=mailto:${email}><strong>${email}</strong></a>`;

const HrefElement = ({email}: {email: string}) => (
  <a className={'toast-email-gray'} href={`mailto:${email}`}>
    <strong>{email}</strong>
  </a>
);

export default HrefElement;
