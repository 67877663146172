import React, {useContext, useState} from 'react';
import {Version} from '@scm/product-components/generated/certification';
import {Bundle} from '@scm/product-components/pages/productDetails/productTabs/ProductTabsContainer';
import {useIntl} from 'react-intl';
import {bundleMapper} from '@scm/product-components/utils/mappers/VersionMapper';
import CompatibilityForm from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/CompatibilityForm';
import PatchReleaseNotesForm from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/PatchReleaseNotesForm';
import SvsErrorsModal from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/SvsErrorsModal';
import {Modal} from '@sabre/spark-react-core';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {supportRole} from '@scm/components/utils/common';
import {StorefrontDataContext} from '../../../storefrontData/dataProvider/StorefrontDataProvider';
import {bundlesName} from '../../../storefrontData/interfaces/BundleDataValues';
import BundleRow from './BundleRow';
import ChangeSimplifiedDescriptionForm from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/ChangeSimplifiedDescriptionForm';
import {LoadingContainer} from '@scm/product-components/pages/productDetails/productTabs/proposalData/ProposalData';
import {Loading} from '@scm/components';
import {isAdpRole} from '@scm/authentication/utils/authentication';
import CleanupDatesModal from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/CleanupDatesModal';
import VersionFilesModal from './modals/VersionFilesModal';

const bundleHeaders: Array<string> = ['version', 'submitted', 'last updated', 'status', 'compatibility', ''];

const BundlesTable = () => {
  const [open, setOpen] = useState(false);
  const {methodsProductForm, reloadBundlesTab, setReloadBundlesTab, isProcessPiiOrPci} =
    useContext(StorefrontDataContext);
  const {watch} = methodsProductForm;
  const [editedBundle, setEditedBundle] = useState<Bundle | undefined>();
  const [editedReleaseNotes, setEditedReleaseNotes] = useState<Bundle | undefined>();
  const [editedSimplifiedDescription, setEditedSimplifiedDescription] = useState<Bundle | undefined>();
  const [svsErrors, setSvsErrors] = useState<Bundle | undefined>();
  const [editedCleanupDates, setEditedCleanupDates] = useState<Bundle | undefined>();
  const [versionFiles, setVersionFiles] = useState<Bundle | undefined>();

  const handleClose = () => {
    setOpen(false);
    setEditedBundle(undefined);
    setEditedReleaseNotes(undefined);
    setEditedSimplifiedDescription(undefined);
    setSvsErrors(undefined);
    setEditedCleanupDates(undefined);
    setVersionFiles(undefined);
  };
  const handleOpen = () => setOpen(true);
  const compatibilityFormId = 'compatibilityForm';
  const {formatMessage} = useIntl();

  const generateHeaders = () => (
    <thead>
      <tr>
        {bundleHeaders.map(
          (name, index, headers) =>
            name !== '' && (
              <TableHeader
                key={name}
                colSpan={headers[index + 1] === '' ? 2 : 1}
                scope={headers[index + 1] === '' ? 'colgroup' : ''}
              >
                {name}
              </TableHeader>
            )
        )}
      </tr>
    </thead>
  );
  const generateBody = () => {
    const content: Array<JSX.Element> = watch(bundlesName).map((dataItem: Version) => (
      <BundleRow
        bundleDetails={bundleMapper(dataItem)}
        key={dataItem.name}
        handleOpen={handleOpen}
        compatibilityFormId={compatibilityFormId}
        setEditedBundle={setEditedBundle}
        setEditedReleaseNotes={setEditedReleaseNotes}
        setEditedSimplifiedDescription={setEditedSimplifiedDescription}
        setSvsErrors={setSvsErrors}
        setEditedCleanupDates={setEditedCleanupDates}
        setVersionFiles={setVersionFiles}
      />
    ));
    return <tbody>{content}</tbody>;
  };

  const reloadBundles = () => {
    setReloadBundlesTab(true);
  };
  const generateModal = () => {
    if (editedBundle) {
      return (
        <CompatibilityForm
          bundleId={editedBundle.id}
          bundleCompatibility={editedBundle.compatibility}
          webCompatibility={editedBundle.webCompatibility}
          onClose={handleClose}
          reloadBundles={reloadBundles}
        />
      );
    } else if (editedReleaseNotes) {
      return (
        <PatchReleaseNotesForm
          watchBundle={watch}
          versionId={editedReleaseNotes.id}
          initialReleaseNotes={editedReleaseNotes.releaseNotes ?? ''}
          onClose={handleClose}
          reloadBundles={reloadBundles}
        />
      );
    } else if (editedSimplifiedDescription) {
      return (
        <ChangeSimplifiedDescriptionForm
          watchBundle={watch}
          versionId={editedSimplifiedDescription.id}
          initialDescription={editedSimplifiedDescription.simplifiedDescription ?? ''}
          onClose={handleClose}
          reloadBundles={reloadBundles}
          isReadOnly={isAdpRole()}
        />
      );
    } else if (editedCleanupDates) {
      return <CleanupDatesModal onClose={handleClose} cleanupDates={editedCleanupDates?.cleanup} />;
    } else if (svsErrors) {
      return <SvsErrorsModal versionId={svsErrors.id} />;
    } else if (versionFiles) {
      return (
        <VersionFilesModal
          onClose={handleClose}
          versionId={versionFiles.id}
          processPiiOrPci={isProcessPiiOrPci}
        ></VersionFilesModal>
      );
    } else {
      return <></>;
    }
  };

  return reloadBundlesTab ? (
    <LoadingContainer className="sparkspark-mar-t-2">
      <Loading sizing="md" />
    </LoadingContainer>
  ) : (
    <>
      <Table className="spark-table">
        <div className="spark-table__scroll">
          <table role="grid" className="spark-mar-b-1">
            {generateHeaders()}
            {generateBody()}
          </table>
        </div>
      </Table>
      <Modal
        onClose={handleClose}
        open={open}
        title={formatMessage(
          {
            id: (() => {
              if (editedBundle) return 'tabs.bundle.compatibilityForm.title';
              else if (editedReleaseNotes) return 'tabs.releaseNotes.patchReleaseNotesForm.title';
              else if (svsErrors) return 'tabs.bundle.svsErrorsModal.title';
              else if (editedCleanupDates) return 'tabs.cleanupDatesModal.title';
              else if (versionFiles) return 'tabs.versionFiles.title';
              else return 'tabs.simplifiedDescription.changeSimplifiedDescriptionForm.title';
            })(),
          },
          {versionId: editedCleanupDates?.version || versionFiles?.version}
        )}
      >
        {generateModal()}
      </Modal>
    </>
  );
};

export const Table = styled.section`
  border-top: 2px solid ${colors.grey300};
  ${supportRole ? 'font-size: 1rem' : ''}
`;

export const TableHeader = styled.th`
  font-size: ${supportRole ? '1rem' : '1.25rem'} !important;
  background-color: ${colors.white} !important;
  color: ${colors.black} !important;
  border: none !important;
  border-bottom: 1px solid ${colors.grey300} !important;
`;

export default BundlesTable;
