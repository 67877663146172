enum InternalUrls {
  Home = '/',
  PartnerNetwork = '/partners',
  AdminCentre = '/admin-centre',
  Directory = '/directory',
  Partners = '',
  Benefits = '/benefits',
  Tiers = '/tiers',
  JoinUs = '/join-us',
}

export default InternalUrls;
