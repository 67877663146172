/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Accounting
 */
export interface Accounting {
    /**
     * 
     * @type {Array<string>}
     * @memberof Accounting
     */
    dataType?: Array<AccountingDataTypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof Accounting
     */
    attributesDescription?: string;
}


/**
 * @export
 */
export const AccountingDataTypeEnum = {
    AccountingData: 'Accounting data',
    BackOfficeData: 'Back-office data'
} as const;
export type AccountingDataTypeEnum = typeof AccountingDataTypeEnum[keyof typeof AccountingDataTypeEnum];


/**
 * Check if a given object implements the Accounting interface.
 */
export function instanceOfAccounting(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AccountingFromJSON(json: any): Accounting {
    return AccountingFromJSONTyped(json, false);
}

export function AccountingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Accounting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataType': !exists(json, 'dataType') ? undefined : json['dataType'],
        'attributesDescription': !exists(json, 'attributesDescription') ? undefined : json['attributesDescription'],
    };
}

export function AccountingToJSON(value?: Accounting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataType': value.dataType,
        'attributesDescription': value.attributesDescription,
    };
}

