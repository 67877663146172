import {useIntl} from 'react-intl';
import {useMediaQuery} from 'react-responsive';
import React from 'react';
import ExternalUrls from '@scm/components/utils/externalUrls';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {ActiveItem, DropdownList} from '../styledComponents/styledComponents';
import DropdownItem from './DropDownItem';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';
import {domains} from '@scm/components/utils/domains';
import {config} from '@scm/authentication/config/config';

const {SabreCentral, Marketplace} = ExternalUrls;
const {
  Home,
  PartnerNetwork,
  MyProducts,
  Reports,
  Resources,
  Developers,
  PartnerProfile,
  Cleanup,
  FreezeCalendar,
  Roadmap,
} = InternalUrls;

const DropDownItems = ({onClick}: {onClick: () => void}) => {
  const {formatMessage} = useIntl();
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  const sabreCentralLabel = formatMessage({id: 'header.sabreCentral'});
  const marketPlaceLabel = formatMessage({id: 'header.marketPlace'});
  const partnerNetworkLabel = formatMessage({id: 'header.partnerNetwork'});
  const roadmapLabel = formatMessage({id: 'menu.roadmap'});
  const myProductsLabel = formatMessage({id: 'menu.myProducts'});
  const reportsLabel = formatMessage({id: 'menu.reports'});
  const resourcesLabel = formatMessage({id: 'menu.resources'});
  const developersLabel = formatMessage({id: 'menu.developers'});
  const partnerProfileLabel = formatMessage({id: 'menu.partnerProfile'});
  const certificationEngineerDashboardLabel = formatMessage({id: 'menu.certificationEngineerDashboard.shortName'});
  const cleanupLabel = formatMessage({id: 'menu.cleanup'});
  const freezeCalendarLabel = formatMessage({id: 'menu.freezeCalendar'});

  const partnerNetworkLink = domains.includes(window.location.origin) ? PartnerNetwork : Home;
  const isCertEnv = config.envName === 'cert';

  const createMenu = () => (
    <>
      <DropdownItem
        to={isCertEnv ? config.sabreCentral.url : SabreCentral}
        label={sabreCentralLabel}
        ariaLabel={sabreCentralLabel}
        onClick={onClick}
      />
      <DropdownItem
        to={isCertEnv ? config.marketplace.url : Marketplace}
        label={marketPlaceLabel}
        ariaLabel={marketPlaceLabel}
        onClick={onClick}
      />
      <DropdownItem
        to={partnerNetworkLink}
        label={partnerNetworkLabel}
        ariaLabel={partnerNetworkLabel}
        onClick={onClick}
      />
      <ActiveItem mobile={isMobile}>
        {isMobile && (
          <ul className="spark-menu__list">
            <DropdownItem
              to={MyProducts}
              label={myProductsLabel}
              ariaLabel={myProductsLabel}
              onClick={onClick}
              isExternalLink={false}
            />
            <DropdownItem
              to={Reports}
              label={reportsLabel}
              ariaLabel={reportsLabel}
              onClick={onClick}
              isExternalLink={false}
              isDisabled
            />
            <DropdownItem
              to={Resources}
              label={resourcesLabel}
              ariaLabel={resourcesLabel}
              onClick={onClick}
              isExternalLink={false}
              isDisabled
            />
            <DropdownItem
              to={Developers}
              label={developersLabel}
              ariaLabel={developersLabel}
              onClick={onClick}
              isExternalLink={false}
              isDisabled
            />
            <DropdownItem
              to={PartnerProfile}
              label={partnerProfileLabel}
              ariaLabel={partnerProfileLabel}
              onClick={onClick}
              isExternalLink={false}
              isDisabled
            />
            <DropdownItem
              to={`${InternalUrls.CertificationEngineerDashboard}/incertification`}
              label={certificationEngineerDashboardLabel}
              ariaLabel={certificationEngineerDashboardLabel}
              onClick={onClick}
              isExternalLink={false}
              isSupportOnly
            />
            <DropdownItem
              to={Cleanup}
              label={cleanupLabel}
              ariaLabel={cleanupLabel}
              onClick={onClick}
              isExternalLink={false}
              isSupportOnly
            />
            <DropdownItem
              to={FreezeCalendar}
              label={freezeCalendarLabel}
              ariaLabel={freezeCalendarLabel}
              onClick={onClick}
              isExternalLink={false}
              isSupportOnly
            />
            <DropdownItem
              to={Roadmap}
              label={roadmapLabel}
              ariaLabel={roadmapLabel}
              onClick={onClick}
              isExternalLink={false}
            />
          </ul>
        )}
      </ActiveItem>
    </>
  );

  return (
    <DropdownList className="spark-menu__list spark-menu__list--main spark-header__list spark-header__list--overflow">
      {createMenu()}
    </DropdownList>
  );
};

export default DropDownItems;
