export const certify = {
  'certify.certification.header': 'Certification',
  'certify.certification.start': 'Start',
  'certify.certification.abandon': 'Abandon',
  'certify.certification.approve': 'Approve',
  'certify.certification.approveModal.title': 'Approve Version',
  'certify.certification.approveModal.message':
    'Are you sure that you want to approve this version? This will create product and roles in Sabre Red CM PROD, assign PROD testers and start production testing phase.',
  'certify.certification.reject': 'Reject',
  'certify.certification.rejectModal.title': 'Reject Version',
  'certify.certification.rejectModal.message':
    'Are you sure that you want to reject this version? In such case, please contact the developer and explain the reason.',
  'certify.certification.comment.label': 'Add Comment',
  'certify.certification.comment.input.label': 'Comment your changes',
  'certify.certification.certificationEngineer.label': 'Certification Engineer',
  'certify.maintenance.header': 'Maintenance',
  'certify.maintenance.message.title':
    '[ADVANCED MAINTENANCE] This section is not a part of standard Certification Workflow and should be used only for critical cases - PLEASE USE IT WITH CAUTION.',
  'certify.maintenance.actionButton.reassign': 'Re-assign Beta Testers',
  'certify.maintenance.actionButton.reassign.info':
    'Beta testers can be assigned only in "Ready for test (PROD)" status',
  'certify.maintenance.actionButton.versionAssigment': 'Trigger Version Assignments Update',
  'certify.maintenance.actionButton.versionAssigment.info':
    'Option available only for "Published version", if other earlier published version exists. Will trigger reassignments in SR360 from previous latest published version (earlier than the selected one).',
  'certify.maintenance.actionButton.getCmConfigCert': 'Download CM Config for CERT',
  'certify.maintenance.actionButton.getCmConfigProd': 'Download CM Config for PROD',
  'certify.maintenance.actionButton.label': 'Maintenance Actions',
  'certify.maintenance.status.label': 'Version Status',
  'certify.maintenance.setStatus.label': 'Set Status',
  'certify.maintenance.comment': 'Comment',
  'certify.maintenance.generic.successTitle': 'Success',
  'certify.maintenance.generic.errorTitle': 'Something went wrong',
  'certify.maintenance.reassignBetaTesters.success': 'Beta testers were reassigned successfully',
  'certify.maintenance.reassignBetaTesters.error': 'Beta testers cannot be reassigned',
  'certify.maintenance.getCmConfig.error': 'An error occurred while trying to download CM config',
  'certify.maintenance.regenerateCompositeFiles.success': 'Composite files regenerated successfully',
  'certify.maintenance.regenerateCompositeFiles.error': 'An error occurred while trying to regenerate composite files',
  'certify.maintenance.updateVersionAssignments.success': 'Version assignments updated successfully',
  'certify.maintenance.updateVersionAssignments.error': 'Version assignments cannot be updated',
  'certify.maintenance.actionButton.certRegenerateCompositeFiles': 'Regenerate composite files for CERT',
  'certify.maintenance.actionButton.prodRegenerateCompositeFiles': 'Regenerate composite files for PROD',
  'certify.maintenance.actionButton.rollback': 'Rollback Version',
  'certify.maintenance.rollback.success': 'Version rolled back successfully',
  'certify.maintenance.rollback.error': 'An error occurred while trying to rollback version',
  'certify.maintenance.rollback.modal.title': 'Rollback Version',
  'certify.maintenance.rollback.modal.message.line1': 'Are you sure you want to start rollback procedure?',
  'certify.maintenance.rollback.modal.message.line2':
    'After click on CONFIRM button, system will perform below actions:',
  'certify.maintenance.rollback.modal.message.line3':
    'removal of version from SCM (this is to avoid new orders for this version)',
  'certify.maintenance.rollback.modal.message.line4':
    'removal of version configuration in CM (this is to remove user access to this version in SR360)',
  'certify.maintenance.rollback.modal.message.line5':
    'Entire procedure should take few seconds, after that version status should be changed to "Rolled Back".',
  'certify.maintenance.rollback.modal.message.line6':
    'IMPORTANT: After successful rollback please make sure that there were no new orders created after rolled back version was published. If so, please contact SCM team (recommended action is manual assignment of previous version in CM to new users).',
  'certify.maintenance.rollback.modal.button': 'Proceed',
  'certify.maintenance.rollback.tooltip': 'Rollback action is available only for Published Patch versions',
  'certify.certifyLog.date': 'Date',
  'certify.certifyLog.changedBy': 'Changed By',
  'certify.certifyLog.appliedStatus': 'Applied Status',
  'certify.error.title': 'Something went wrong.',
  'certify.error.description': 'Please try again later.',
  'certify.compatibility.label': 'Sabre Red Compatibility',
  'certify.redAppProcess.label': 'Red App Process PII or PCI',
  'certify.actionButton.Bundle': 'Download Red App Bundle',
  'certify.actionButton.Use cases': 'Download "Use Cases"',
  'certify.actionButton.Security worksheet': 'Download "Security Worksheet"',
  'certify.actionButton.Workflow diagram': 'Download "Dataflow Diagram"',
};
