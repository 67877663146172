import React, {useEffect} from 'react';
import PartnerDirectoryMobile from 'pages/partnerDirectory/PartnerDirectoryMobile';
import PartnerDirectoryDesktop from 'pages/partnerDirectory/PartnerDirectoryDesktop';

const PartnerDirectory = () => {
  useEffect(() => window.scrollTo(0, 0));
  return (
    <main>
      <PartnerDirectoryMobile />
      <PartnerDirectoryDesktop />
    </main>
  );
};

export default PartnerDirectory;
