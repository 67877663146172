import {clearTokens} from '../utils/authentication';
import {FormattedMessage} from 'react-intl';
import React, {ReactNode} from 'react';

const LogoutLink = ({className, to, children}: {className?: string; to: string; children?: ReactNode}) => (
  <a href={to} className={className} onClick={clearTokens}>
    {!!children ? children : <FormattedMessage id="authentication.SignOut" />}
  </a>
);

export default LogoutLink;
