import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import CenterItems from 'components/styledComponents/CenterItems';
import {useMediaQuery} from 'react-responsive';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const ProductsHeader = ({partnerName}: {partnerName: string}) => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  return (
    <CenterItems>
      <Icon className="spark-icon-congruent-cubes" />
      <Title mobile={isMobile}>
        <FormattedMessage id="partnerPage.products.title" values={{partnerName: partnerName}} />
      </Title>
    </CenterItems>
  );
};

const Icon = styled.i.attrs(({className}) => ({className: `${className}`}))`
  font-size: 5rem;
  padding: 2rem;
  color: ${colors.grey700};
`;

const Title = styled.div.attrs(() => ({
  className: 'spark-alpha spark-bold spark-mar-b-2',
}))<{mobile?: boolean}>`
  ${props => (props.mobile ? 'text-align: center' : '')};
`;

export default ProductsHeader;
