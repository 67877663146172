import {selectOptions} from '../Roadmap';
import {FormattedMessage} from 'react-intl';
import React, {useState} from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {IconSize, IconStyle} from '@sabre/spark-react-core/types';
import {Icon} from '@sabre/spark-react-core';
import {ButtonContainer} from './RoadmapTab';

const RoadmapSelectableTab = ({handler, name}: {handler: (name: string) => void; name: string}) => {
  const [isSelectVisible, setIsSelectVisible] = useState(false);

  return (
    <div onMouseEnter={() => setIsSelectVisible(true)} onMouseLeave={() => setIsSelectVisible(false)}>
      <ButtonContainer className="spark-pad-1 spark-mar-r-.5 spark-mar-l-.5" textOnly>
        <FormattedMessage id={name} />
        <Icon iconStyle={IconStyle.FILL} size={IconSize.SMALL} name="arrow-triangle-down" className="spark-mar-l-.5" />
      </ButtonContainer>
      {isSelectVisible && (
        <SelectContainer
          onMouseLeave={() => {
            setIsSelectVisible(false);
          }}
        >
          {selectOptions.map(option => (
            <SelectOption
              onClick={() => {
                handler(option.name);
              }}
              active={option.isActive}
              className="spark-pad-1 spark-mar-0"
            >
              <FormattedMessage id={option.name} />
            </SelectOption>
          ))}
        </SelectContainer>
      )}
    </div>
  );
};

const SelectContainer = styled.div`
  position: absolute;
  width: 160px;
  z-index: 1;
`;

const SelectOption = styled.div<{active?: boolean}>`
  border-radius: 0 !important;
  background: ${colors.grey50} !important;
  cursor: pointer;
  border-left: ${props => props.active && `5px solid ${colors.grey700}`};

  &&:hover {
    background: ${colors.grey100} !important;
  }
`;

export default RoadmapSelectableTab;
