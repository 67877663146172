/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalServices } from './ExternalServices';
import {
    ExternalServicesFromJSON,
    ExternalServicesFromJSONTyped,
    ExternalServicesToJSON,
} from './ExternalServices';

/**
 * 
 * @export
 * @interface Services
 */
export interface Services {
    /**
     * 
     * @type {ExternalServices}
     * @memberof Services
     */
    externalServices?: ExternalServices;
    /**
     * 
     * @type {string}
     * @memberof Services
     */
    sabreServicesComment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Services
     */
    useBargainFinderMax?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Services
     */
    sr360TechnologiesComment?: string;
}

/**
 * Check if a given object implements the Services interface.
 */
export function instanceOfServices(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServicesFromJSON(json: any): Services {
    return ServicesFromJSONTyped(json, false);
}

export function ServicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Services {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalServices': !exists(json, 'externalServices') ? undefined : ExternalServicesFromJSON(json['externalServices']),
        'sabreServicesComment': !exists(json, 'sabreServicesComment') ? undefined : json['sabreServicesComment'],
        'useBargainFinderMax': !exists(json, 'useBargainFinderMax') ? undefined : json['useBargainFinderMax'],
        'sr360TechnologiesComment': !exists(json, 'sr360TechnologiesComment') ? undefined : json['sr360TechnologiesComment'],
    };
}

export function ServicesToJSON(value?: Services | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalServices': ExternalServicesToJSON(value.externalServices),
        'sabreServicesComment': value.sabreServicesComment,
        'useBargainFinderMax': value.useBargainFinderMax,
        'sr360TechnologiesComment': value.sr360TechnologiesComment,
    };
}

