import {FormattedMessage} from 'react-intl';
import React, {ReactElement, useContext, useEffect, useMemo, useState} from 'react';
import {Row} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {Toggle, ToggleGroup} from '@sabre/spark-react-core';
import PricingRangesRow, {TableRow} from './PricingRangesRow';
import {rowsListPccepr, rowsListTransaction} from './PricingValues';
import {Star} from '@scm/components';
import {PricingContext} from './Pricing';
import styled from 'styled-components';
import {Controller} from 'react-hook-form';
import {PricingFormDefinitionContext} from '@scm/product-details/components/sections/pricing/PricingForm';

const enum PriceType {
  fixed = 'FIXED PRICE',
  ranges = 'PRICE RANGES',
  negotiable = 'NEGOTIABLE',
}

const PriceDefinition = ({
  isChecked,
  pricingType,
  hasPricingFromProvider,
}: {
  isChecked: boolean;
  pricingType: string;
  hasPricingFromProvider?: boolean;
}) => {
  const {watch, control, setValue} = useContext(
    !!hasPricingFromProvider ? PricingFormDefinitionContext : PricingContext
  );
  const [rangesList, setRangesList] = useState(rowsListPccepr);
  const priceTypeName = `${pricingType}Type`;
  const fixedPriceId = `${priceTypeName}${pricingType}Fixed`;
  const fixedPriceValue = `${pricingType}${PriceType['fixed']}`;
  const priceRangesId = `${priceTypeName}${pricingType}Ranges`;
  const priceRangesValue = `${pricingType}${PriceType['ranges']}`;
  const negotiablePriceId = `${priceTypeName}${pricingType}Negotiable`;
  const negotiablePriceValue = `${pricingType}${PriceType['negotiable']}`;
  const starMarginTop = -6;
  const changeValueOptions = useMemo(() => ({shouldValidate: true, shouldTouch: true, shouldDirty: true}), []);

  useEffect(() => {
    if (pricingType === 'perPccepr') {
      setRangesList(rowsListPccepr);
    }
    if (pricingType === 'perTransaction') {
      setRangesList(rowsListTransaction);
    }
  }, [pricingType]);

  const validatePrice = (index: number, providedValue: string) => {
    return !(index > 0 && parseFloat(providedValue) >= parseFloat(watch(rangesList)[index - 1].price));
  };

  const validateTo = (index: number, providedValue: string) => {
    return providedValue !== '' && providedValue !== 'Unlimited'
      ? parseInt(providedValue) > parseInt(watch(rangesList)[index].from)
      : true;
  };

  const handleAddRow = (index: number) => {
    const list = watch(rangesList);
    list.splice(index, 0, {
      from: watch(rangesList)[index - 1].to !== '' ? `${parseInt(watch(rangesList)[index - 1].to) + 1}` : '',
      to: '',
      price: '',
      disabledToValue: false,
    });
    setValue(rangesList, list, changeValueOptions);
  };

  const handleDeleteRow = (index: number) => {
    const list = watch(rangesList);
    list.splice(index, 1);
    if (index) setValue(rangesList, list, changeValueOptions);
  };

  const fixedPriceClicked = () => {
    return (
      <Row>
        <PricingRangesRow
          from="1"
          to="Unlimited"
          index={0}
          price={watch(rangesList)[0].price}
          isValidTo={true}
          isValidPrice={true}
          disabledToValue={true}
          disabled={!isChecked}
          pricingType={pricingType}
          fixedPriceClicked
          hasPricingFromProvider={hasPricingFromProvider}
        />
      </Row>
    );
  };

  const negotiableClicked = () => {
    return (
      <Row>
        <PricingRangesRow
          from="1"
          to="Unlimited"
          index={0}
          price="Negotiable"
          isValidTo={true}
          isValidPrice={true}
          disabledToValue={true}
          disabled={!isChecked}
          pricingType={pricingType}
          hasPricingFromProvider={hasPricingFromProvider}
        />
      </Row>
    );
  };

  const setFromValue = (index: number, fromValue: string) => {
    return index === watch(rangesList).length - 1 && watch(rangesList)[index - 1].to !== ''
      ? `${parseInt(watch(rangesList)[index - 1].to) + 1}`
      : fromValue;
  };

  const isAddRowButtonDisabled = () => {
    const hasUnfilled = watch(rangesList)
      .slice(0, watch(rangesList).length - 1)
      .filter(range => range.to === '' || range.price === '');
    return !!hasUnfilled.length;
  };

  const priceRangesClicked = () => {
    return (
      <>
        {watch(rangesList).map(
          (
            singleRow: {
              from: string;
              to: string;
              price: string;
              disabledToValue: boolean;
            },
            index: number
          ) => (
            <Row key={index}>
              <PricingRangesRow
                from={setFromValue(index, singleRow.from)}
                to={singleRow.to}
                index={index}
                price={singleRow.price}
                isValidTo={validateTo(index, watch(rangesList)[index].to)}
                isValidPrice={validatePrice(index, watch(rangesList)[index].price)}
                disabledToValue={singleRow.disabledToValue}
                disabled={!isChecked}
                pricingType={pricingType}
                hasPricingFromProvider={hasPricingFromProvider}
              />
              {watch(rangesList).length - 2 === index && watch(rangesList).length < 5 && (
                <button
                  className="spark-btn--icon spark-icon spark-icon-math-add spark-tooltip tooltip-initialized"
                  onClick={() => handleAddRow(index + 1)}
                  disabled={!isChecked || isAddRowButtonDisabled()}
                  aria-label="Add new row"
                />
              )}
              {watch(rangesList).length - 1 === index && watch(rangesList).length > 2 && (
                <button
                  className="spark-btn--icon spark-icon spark-icon-trash spark-tooltip tooltip-initialized"
                  onClick={() => handleDeleteRow(index - 1)}
                  disabled={!isChecked}
                  aria-label="Delete row"
                />
              )}
            </Row>
          )
        )}
      </>
    );
  };

  const priceRowByChosenToggle = new Map<string, JSX.Element>([
    [priceRangesValue, priceRangesClicked()],
    [negotiablePriceValue, negotiableClicked()],
    [fixedPriceValue, fixedPriceClicked()],
  ]);

  return (
    <div>
      <div className="row spark-mar-l-2 spark-mar-t-2">
        <FormattedMessage id="tabs.price.definition.title" />
        {isChecked && <Star hasColon marginTop={starMarginTop} />}
        <Controller
          control={control}
          name={priceTypeName}
          render={({field: {onChange, value, name}}): ReactElement => (
            <PricingToggleGroup
              name={name}
              onChange={onChange}
              value={value}
              className="col-xs-12 col-lg-8 spark-pad-l-0"
              condensed
            >
              <Toggle
                id={fixedPriceId}
                className="col-xs-4 col-sm-2"
                label={PriceType['fixed']}
                value={fixedPriceValue}
                disabled={!isChecked}
              />
              <Toggle
                id={priceRangesId}
                className="col-xs-4 col-sm-2"
                label={PriceType['ranges']}
                value={priceRangesValue}
                disabled={!isChecked}
              />
              <Toggle
                id={negotiablePriceId}
                className="col-xs-4 col-sm-2"
                label={PriceType['negotiable']}
                value={negotiablePriceValue}
                disabled={!isChecked}
              />
            </PricingToggleGroup>
          )}
        />
      </div>
      {watch(priceTypeName) !== '' && watch(priceTypeName) !== undefined && (
        <div className="spark-mar-l-2">
          <PricingTableRow className="spark-mar-l-2">
            <ColumnName className="col-xs-4">
              <FormattedMessage id="tabs.price.definition.fromLabel" />
            </ColumnName>
            <ColumnName className="col-xs-4">
              <FormattedMessage id="tabs.price.definition.toLabel" />
            </ColumnName>
            <ColumnName className="col-xs-4">
              <FormattedMessage id="tabs.price.definition.priceLabel" />
            </ColumnName>
          </PricingTableRow>
          {priceRowByChosenToggle.get(watch(priceTypeName))}
        </div>
      )}
    </div>
  );
};
export const ColumnName = styled.p`
  max-width: 11rem;
  text-align: center;
  margin-bottom: 10px;
`;

export const PricingToggleGroup = styled(ToggleGroup)`
  margin-left: 20px;
`;

export const PricingTableRow = styled(TableRow)`
  margin-top: 20px;
`;

export default PriceDefinition;
