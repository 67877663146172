import React, {useState} from 'react';
import {Modal} from '@sabre/spark-react-core';
import {FormattedMessage} from 'react-intl';
import SunsetDescriptionText from './SunsetDescriptionText';
import {Separator} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {SunsetItemButton} from './ManageProduct';
import styled from 'styled-components';

const SunsetProcedure = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <Container>
      <Separator className="spark-mar-l-2 spark-mar-b-1 spark-pad-l-0 spark-mar-r-0 spark-pad-r-0 spark-bold spark-large col-sm-10">
        <FormattedMessage id="sunset.separator.sunsetProcedure" />
      </Separator>
      <SunsetItemButton
        id="sunset.button.sunsetProcedure"
        className="button-width neutral spark-mar-l-2"
        clickHandler={() => handleOpen()}
        type="submit"
      />
      <Modal onClose={handleClose} open={open} title="Sunset Procedure">
        <SunsetDescriptionText />
      </Modal>
    </Container>
  );
};

export const Container = styled.div`
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
`;

export default SunsetProcedure;
