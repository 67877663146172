import {ButtonSize, IconSize, TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {Button, Icon, Popover} from '@sabre/spark-react-core';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext} from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {RoadmapContext} from '../../components/RoadmapProvider';

const RoadmapItemActionMenu = ({roadmapId}: {roadmapId: string}) => {
  const {formatMessage} = useIntl();
  const {handleOpenEdit, handleOpenDelete} = useContext(RoadmapContext);

  return (
    <Popover
      anchorX={TooltipDirectionX.LEFT}
      anchorY={TooltipDirectionY.BOTTOM}
      dismissible
      contentCloseElQuery={'.close-popover'}
      toggleEl={
        <Button
          size={ButtonSize.EXTRA_SMALL}
          icon="menu-ellipsis-vertical"
          className="spark-icon--fill"
          ariaLabel={formatMessage({id: 'roadmap.options.description'})}
          data-testid="itemActionMenu"
        />
      }
    >
      <ul className="spark-popover__list">
        <ActionItem
          className="spark-popover__list-item spark-flex spark-align-items-center spark-popover__list-link spark-pad-l-.5 close-popover"
          onClick={() => handleOpenEdit(roadmapId)}
          role="button"
          tabIndex={0}
          onKeyDown={evt => {
            if (evt.key === 'Enter') {
              handleOpenEdit(roadmapId);
            }
          }}
          data-testid="editFeatureItem"
        >
          <Icon name="pencil" size={IconSize.SMALL} className="spark-pad-.5 spark-mar-r-.5" />
          <FormattedMessage id="common.edit" />
        </ActionItem>
        <ActionItem
          isDelete
          className="spark-popover__list-item spark-flex spark-align-items-center spark-popover__list-link spark-pad-l-.5 close-popover"
          onClick={() => handleOpenDelete(roadmapId)}
          role="button"
          tabIndex={0}
          onKeyDown={evt => {
            if (evt.key === 'Enter') {
              handleOpenDelete(roadmapId);
            }
          }}
          data-testid="deleteFeatureItem"
        >
          <Icon name="trash" size={IconSize.SMALL} className="spark-pad-.5 spark-mar-r-.5" />
          <FormattedMessage id="common.delete" />
        </ActionItem>
      </ul>
    </Popover>
  );
};

const ActionItem = styled.li<{isDelete?: boolean}>`
  cursor: pointer;
  color: ${props => props.isDelete && colors.red};
  text-decoration: none !important;

  &&:hover {
    color: ${props => props.isDelete && colors.red};
  }

  &:focus-visible {
    text-decoration: underline !important;
  }
`;

export default RoadmapItemActionMenu;
