import RegionalizationDataValues, {
  allRegionsName,
  countryCodesName,
  globalRegionalizationCheckboxName,
  regionCodesName,
} from '../interfaces/RegionalizationDataValues';

export const regionalizationInitialValues: RegionalizationDataValues = {
  [allRegionsName]: false,
  [countryCodesName]: [],
  [regionCodesName]: [],
  [globalRegionalizationCheckboxName]: false,
};
