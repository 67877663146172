export const pricingLevelName = 'pricingLevel';
export const perPccepr = 'perPccepr';
export const perTransaction = 'perTransaction';
export const rowsListPccepr = 'rowsListPccepr';
export const rowsListTransaction = 'rowsListTransaction';

export interface RowData {
  from: string;
  to: string;
  price: string;
  disabledToValue: boolean;
}

export enum PricingLevel {
  pcc = 'Pcc level',
  epr = 'Epr level',
}

export default interface PricingDataValues {
  [pricingLevelName]?: PricingLevel;
  [perPccepr]: boolean;
  [perTransaction]: boolean;
  [rowsListPccepr]: Array<RowData>;
  [rowsListTransaction]: Array<RowData>;
}
