import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import onJoinUsItemClicked from '../../googleAnalytics/onJoinUsItemClicked';
import InternalUrls from '../../utils/internalUrls';
import {Link} from 'react-router-dom';
import FormattedLinkMessage from 'components/formattedMessages/FormattedLinkMessage';
import colors from '@scm/components/assets/colors';

const TiersContactBox = ({isMobile}: {isMobile?: boolean}) => {
  const {formatMessage} = useIntl();

  return (
    <Container mobile={isMobile}>
      <PanelContent>
        <RowContainer>
          <Icon className="spark-icon-calendar-star" />
          <div>
            <BoxTitle className="spark-delta">{formatMessage({id: 'tiers.contactBox.effectiveDate'})}</BoxTitle>
            <RollOutDate>{formatMessage({id: 'tiers.contactBox.effectiveDateSubtitle'})}</RollOutDate>
          </div>
        </RowContainer>
        <RowContainer>
          <Icon className="spark-icon-document-check" />
          <div>
            <BoxTitle className="spark-delta">{formatMessage({id: 'tiers.contactBox.newToProgram'})}</BoxTitle>
            <div className="spark-zeta">{formatMessage({id: 'tiers.contactBox.newToProgramSubtitle'})}</div>
            <Link
              to={InternalUrls.JoinUs}
              className={isMobile ? 'spark-btn spark-btn--xs' : 'spark-btn spark-btn--sm'}
              onClick={onJoinUsItemClicked}
            >
              <FormattedMessage id="tiers.contactBox.contactUsButton" />
            </Link>
          </div>
        </RowContainer>
        <RowContainer>
          <Icon className="spark-icon-message-info" />
          <div>
            <BoxTitle className="spark-epsilon">{formatMessage({id: 'tiers.contactBox.currentPartner'})}</BoxTitle>
            <div className="spark-epsilon">
              <FormattedLinkMessage id={'tiers.contactBox.currentPartnerSubtitle'} href={''} />
            </div>
          </div>
        </RowContainer>
      </PanelContent>
    </Container>
  );
};

const Container = styled.div.attrs(() => ({
  className: 'spark-panel spark-panel--card',
}))<{mobile?: boolean}>`
  ${props => `${props.mobile ? '' : 'margin-right: 6rem;;'}`};
  ${props => `${props.mobile ? '' : 'min-width: 32rem;'}`};
  align-self: flex-start;
`;

const PanelContent = styled.div.attrs(() => ({
  className: 'spark-panel__content',
}))`
  color: ${colors.grey700};
`;

const RowContainer = styled.div`
  margin-bottom: 3rem;
  display: flex;
`;

const Icon = styled.i.attrs(({className}) => ({className: `${className}`}))`
  font-size: 2rem;
  padding: 0.5rem 1rem 0 0;
`;

const BoxTitle = styled.div.attrs(({className}) => ({className: `${className}`}))`
  font-weight: 500;
`;

const RollOutDate = styled.div.attrs(() => ({
  className: 'spark-epsilon',
}))`
  font-weight: 500;
  color: ${colors.sparkGreen};
`;

export default TiersContactBox;
