import React from 'react';
import {FormattedMessage} from 'react-intl';
import {options, url} from '../assets/salesforceClient';

export const urlBuilder = (host = window.location.origin) => {
  return `${url}${options(host)}`;
};

export const LoginButton = () => (
  <div className="spark-header__sign-in">
    <a href={urlBuilder()} className="spark-menu__list-link">
      <FormattedMessage id="authentication.SignIn" />
    </a>
  </div>
);
