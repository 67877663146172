/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyInformation } from './CompanyInformation';
import {
    CompanyInformationFromJSON,
    CompanyInformationFromJSONTyped,
    CompanyInformationToJSON,
} from './CompanyInformation';
import type { ContactDetails } from './ContactDetails';
import {
    ContactDetailsFromJSON,
    ContactDetailsFromJSONTyped,
    ContactDetailsToJSON,
} from './ContactDetails';
import type { PartnerPricingDetails } from './PartnerPricingDetails';
import {
    PartnerPricingDetailsFromJSON,
    PartnerPricingDetailsFromJSONTyped,
    PartnerPricingDetailsToJSON,
} from './PartnerPricingDetails';
import type { SabreCustomerAccountInfo } from './SabreCustomerAccountInfo';
import {
    SabreCustomerAccountInfoFromJSON,
    SabreCustomerAccountInfoFromJSONTyped,
    SabreCustomerAccountInfoToJSON,
} from './SabreCustomerAccountInfo';

/**
 * 
 * @export
 * @interface OnboardDeveloperPartnerRequest
 */
export interface OnboardDeveloperPartnerRequest {
    /**
     * 
     * @type {ContactDetails}
     * @memberof OnboardDeveloperPartnerRequest
     */
    contactDetails: ContactDetails;
    /**
     * 
     * @type {CompanyInformation}
     * @memberof OnboardDeveloperPartnerRequest
     */
    companyInformation: CompanyInformation;
    /**
     * 
     * @type {SabreCustomerAccountInfo}
     * @memberof OnboardDeveloperPartnerRequest
     */
    sabreCustomerAccountInfo: SabreCustomerAccountInfo;
    /**
     * 
     * @type {PartnerPricingDetails}
     * @memberof OnboardDeveloperPartnerRequest
     */
    partnerPricingDetails?: PartnerPricingDetails;
}

/**
 * Check if a given object implements the OnboardDeveloperPartnerRequest interface.
 */
export function instanceOfOnboardDeveloperPartnerRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactDetails" in value;
    isInstance = isInstance && "companyInformation" in value;
    isInstance = isInstance && "sabreCustomerAccountInfo" in value;

    return isInstance;
}

export function OnboardDeveloperPartnerRequestFromJSON(json: any): OnboardDeveloperPartnerRequest {
    return OnboardDeveloperPartnerRequestFromJSONTyped(json, false);
}

export function OnboardDeveloperPartnerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardDeveloperPartnerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactDetails': ContactDetailsFromJSON(json['contactDetails']),
        'companyInformation': CompanyInformationFromJSON(json['companyInformation']),
        'sabreCustomerAccountInfo': SabreCustomerAccountInfoFromJSON(json['sabreCustomerAccountInfo']),
        'partnerPricingDetails': !exists(json, 'partnerPricingDetails') ? undefined : PartnerPricingDetailsFromJSON(json['partnerPricingDetails']),
    };
}

export function OnboardDeveloperPartnerRequestToJSON(value?: OnboardDeveloperPartnerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactDetails': ContactDetailsToJSON(value.contactDetails),
        'companyInformation': CompanyInformationToJSON(value.companyInformation),
        'sabreCustomerAccountInfo': SabreCustomerAccountInfoToJSON(value.sabreCustomerAccountInfo),
        'partnerPricingDetails': PartnerPricingDetailsToJSON(value.partnerPricingDetails),
    };
}

