import React from 'react';
import styled from 'styled-components';
import renderApolloErrors from 'components/error/renderApolloErrors';
import {ApolloError} from '@apollo/client';

const CenteredErrorMessage = ({error}: {error: ApolloError}) => (
  <ErrorMessageContainer>{renderApolloErrors(error)}</ErrorMessageContainer>
);

const ErrorMessageContainer = styled.div.attrs(() => ({
  className: 'spark-flex spark-pad-t-2',
}))`
  justify-content: center;
  margin: auto;
  width: 50%;
`;

export default CenteredErrorMessage;
