import React from 'react';
import Services from './servicesComponents/Services';
import PageHeader from '../../../../../components/content/PageHeader';

const ServicesContainer = () => {
  return (
    <div>
      <PageHeader id="serviceAndSecurity.title" />
      <Services />
    </div>
  );
};

export default ServicesContainer;
