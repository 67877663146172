import React, {useState} from 'react';
import Dropzone, {Accept, ErrorCode, FileRejection} from 'react-dropzone';
import styled from 'styled-components';
import {Button, Icon, ProgressIndicator} from '@sabre/spark-react-core';
import {UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {DEFAULT_MAX_FILE_SIZE} from '../utils/filesUtils';
import colors from '../assets/colors';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {openToast} from '../messaging/openToast';
import {FormattedMessage, useIntl} from 'react-intl';
import imageCompression from 'browser-image-compression';
import {imageFiles} from '../utils/common';

export interface ImageFile extends File {
  preview: string;
  shouldUpload: boolean;
}

const uploadFileMaxSizeInBytes = 1024 * 500;
const compressionOptions = {
  maxSizeMB: 0.5,
  useWebWorked: true,
};

async function prepareFilesAndCompressImages(
  files: Array<ImageFile>,
  formatMessage: (obj: {id: string}) => string
): Promise<Array<File>> {
  const filesToUpload = [];

  for (const file of files) {
    if (Object.keys(imageFiles).includes(file.type) && file.size > uploadFileMaxSizeInBytes) {
      try {
        const compressedFileAsBlob = await imageCompression(file, compressionOptions);
        const compressedFile = new File([compressedFileAsBlob], compressedFileAsBlob.name);
        Object.assign(compressedFile, {
          preview: file.preview,
          shouldUpload: true,
        });
        filesToUpload.push(compressedFile);
      } catch (error) {
        openToast(
          formatMessage({id: 'common.upload.general'}),
          formatMessage({id: 'common.upload.general.compression.error'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      }
    } else {
      Object.assign(file, {
        shouldUpload: true,
      });
      filesToUpload.push(file);
    }
  }
  return filesToUpload;
}

export const FileUpload = ({
  filesNames,
  setFiles,
  watch,
  maxFiles,
  filesType,
  maxFileSize,
  additionalText,
  isDisabled,
  dataTestId,
}: {
  filesNames: string;
  setFiles: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  maxFiles: number;
  filesType: Accept | Object;
  additionalText: string;
  maxFileSize?: number;
  isDisabled?: boolean;
  dataTestId?: string;
}) => {
  const files = watch(filesNames) || [];
  const {formatMessage} = useIntl();
  const droppedFileMaxSizeInBytes = maxFileSize ?? DEFAULT_MAX_FILE_SIZE;
  const [isSmallSpinnerLoading, setIsSmallSpinnerLoading] = useState<boolean>(false);

  const handleDrop = (acceptedFiles: Array<ImageFile>, fileRejections: FileRejection[]) => {
    if (acceptedFiles.length + files.length > maxFiles) {
      openToast(
        formatMessage({id: 'common.upload.error'}),
        formatMessage({id: `common.upload.error.maxFiles`}, {limit: maxFiles}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
      return;
    }
    acceptedFiles = acceptedFiles.filter(
      (file: ImageFile) => !files.filter((element: ImageFile) => element.name === file.name).length
    );
    acceptedFiles = acceptedFiles.map((file: ImageFile) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    const rejectionReasons = [ErrorCode.TooManyFiles, ErrorCode.FileTooLarge, ErrorCode.FileInvalidType];
    const rejectionMessageLabels = ['maxFiles', 'incorrectFileSize', 'incorrectFileType'];

    for (let i = 0; i < rejectionReasons.length; i++) {
      if (fileRejections.some(file => file.errors[0].code === rejectionReasons[i])) {
        openToast(
          formatMessage({id: 'common.upload.error'}),
          formatMessage({id: `common.upload.error.${rejectionMessageLabels[i]}`}, {limit: maxFiles}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
        break;
      }
    }

    setIsSmallSpinnerLoading(true);
    prepareFilesAndCompressImages(acceptedFiles, formatMessage)
      .then(preparedFiles => {
        setFiles(filesNames, [...files, ...preparedFiles] as Array<ImageFile>, {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        });
      })
      .catch(function () {
        //do nothing
      })
      .finally(() => setIsSmallSpinnerLoading(false));
  };

  const shouldBeDisable = watch(filesNames)?.length >= maxFiles;

  return (
    <div className="App">
      <Dropzone
        onDrop={handleDrop as <T extends File>(acceptedFiles: Array<T>, fileRejections: FileRejection[]) => void}
        maxFiles={maxFiles}
        accept={filesType as Accept}
        maxSize={droppedFileMaxSizeInBytes}
        disabled={isDisabled || shouldBeDisable}
      >
        {({getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject}) => (
          <Container
            {...getRootProps({
              className: 'dropzone',
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
            className="spark-flex"
          >
            <input {...getInputProps()} data-testid={dataTestId} />
            <Icon name="file-upload" />
            <p className=" spark-mar-t-1 spark-mar-b-1">
              <FormattedMessage id="common.upload.description" />
            </p>
            {isSmallSpinnerLoading ? (
              <ProgressIndicator type="indeterminate" size="xs" />
            ) : (
              <Button type="button" size={ButtonSize.SMALL} secondary disabled={isDisabled || shouldBeDisable}>
                <FormattedMessage id="common.upload.button" />
              </Button>
            )}
            <p className="spark-mar-t-1 spark-small">
              <FormattedMessage id={additionalText} />
            </p>
          </Container>
        )}
      </Dropzone>
    </div>
  );
};

interface DragState {
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isDragActive?: boolean;
}

const getColor = (props: DragState) => {
  if (props.isDragAccept) {
    return colors.successGreen;
  }
  if (props.isDragReject) {
    return colors.red;
  }
  if (props.isDragActive) {
    return colors.highlightBlue100;
  }
  return colors.grey200;
};

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 1rem;
  border: 2px dashed;
  border-color: ${props => getColor(props as DragState)};
  border-radius: 2px;
  background-color: ${colors.white};
  color: ${colors.black};
  outline: none;
  transition: border 0.14s ease-in-out;
`;
