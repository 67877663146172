import React, {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContext} from '../../../ProposalForm';
import {ExternalLink, FileUpload, Star} from '@scm/components';
import {Icon} from '@sabre/spark-react-core';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {textFileType} from '@scm/components/utils/common';
import {flowDiagramName} from '../../../ProposalValues';
import {MultiselectErrorMessage} from './Category';

const FlowDiagram = () => {
  const {formatMessage} = useIntl();
  const {watch, setValue, errors} = useContext(ProposalContext);
  const maxFiles = 1;

  const deleteFileHandler = () => {
    setValue(flowDiagramName, [], {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <>
      <p className="spark-mar-t-2 spark-mar-b-0 spark-caps">
        <FormattedMessage id="definition.flowDiagram.label" />
        <Star />
      </p>
      <FilesUploadContainer className="spark-mar-r-1 spark-mar-t-0">
        <FilesList className="spark-list--no-bullets spark-mar-l-0">
          {watch(flowDiagramName).map((file: File | string, index) => (
            <li key={index}>
              {typeof file === 'string' ? <ExternalLink to={file}>{file}</ExternalLink> : file.name}{' '}
              <Icon name="trash" onClick={deleteFileHandler} />
            </li>
          ))}
        </FilesList>
        <FileUpload
          filesNames={flowDiagramName}
          setFiles={setValue as () => void}
          watch={watch}
          filesType={textFileType}
          maxFiles={maxFiles}
          additionalText={formatMessage({
            id: 'definition.flowDiagram.additionalText',
          })}
        />
        {errors[flowDiagramName] && (
          <MultiselectErrorMessage>{errors[flowDiagramName]?.message}</MultiselectErrorMessage>
        )}
      </FilesUploadContainer>
    </>
  );
};

const FilesUploadContainer = styled.div`
  overflow: hidden;
`;

const FilesList = styled.ul`
  & .spark-icon {
    font-size: 1.8rem;
    vertical-align: -1px;
    color: ${colors.sparkBlue};
  }

  & .spark-icon:hover {
    cursor: pointer;
  }
`;

export default FlowDiagram;
