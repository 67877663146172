import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext, useState} from 'react';
import {Description, StyledButton} from '../Sunset';
import {ButtonSize} from '@sabre/spark-react-core/types';
import Modal from '@sabre/spark-react-core/modal';
import styled from 'styled-components';
import {Button} from '@sabre/spark-react-core';
import {getAccessToken, isAllowedToRemoveProductInCreation} from '@scm/authentication/utils/authentication';
import {apiBaseLink} from '../../../../../assets/apiBaseLink';
import {CcrzEProductCSObjectCcrzProductStatusCEnum, Configuration, ProductApi} from '../../../../../generated/product';
import {useParams} from 'react-router-dom';
import {ProductContext} from '../../../../../provider/ProductProvider';
import useGetProductC from '../../../../../hooks/useGetProductC';

const removeProduct = async (sku: string) =>
  await new ProductApi(
    new Configuration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() as string,
    })
  ).removeProductInCreation({sku});

const RemoveProduct = () => {
  const {ccrzProductStatusC, redAppTypeC} = useGetProductC();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const isRedApp = redAppTypeC !== undefined;

  const {formatMessage} = useIntl();

  const {appName} = useParams();
  const {setLoading} = useContext(ProductContext);

  return ccrzProductStatusC === CcrzEProductCSObjectCcrzProductStatusCEnum.InCreation &&
    isAllowedToRemoveProductInCreation() &&
    isRedApp ? (
    <>
      <p className="spark-mar-b-1 spark-caps spark-mar-t-2">
        <FormattedMessage id="productRemoval.title" />
      </p>
      <Description className="spark-mar-b-1">
        <FormattedMessage id="productRemoval.description" />
      </Description>
      <StyledButton
        onClick={handleOpen}
        secondary
        size={ButtonSize.SMALL}
        className="spark-mar-r-1"
        data-testid="productRemovalButton"
      >
        <FormattedMessage id="productRemoval.buttonLabel" />
      </StyledButton>
      <Modal
        onClose={handleClose}
        open={open}
        title={formatMessage({id: 'productRemoval.modal.title'})}
        titleHeadingLevel={2}
      >
        <p>
          <FormattedMessage id="productRemoval.modal.message" />
        </p>
        <ButtonContainer className="row spark-mar-t-2">
          <Button type="button" secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button
            type="button"
            size={ButtonSize.SMALL}
            className="spark-mar-r-1"
            progress
            onClick={async (_, success, failure) => {
              if (success && failure) {
                try {
                  if (appName) {
                    await removeProduct(appName);
                    handleClose();
                    setLoading(true);
                  }
                  success();
                } catch (_) {
                  failure();
                }
              }
            }}
            data-testid="productRemovalConfirmButton"
          >
            <FormattedMessage id="productRemoval.modal.submit" />
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  ) : null;
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default RemoveProduct;
