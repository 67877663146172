/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CcrzEProductMediaCSObject,
  CommentObject,
  OrderingLimitationsResponse,
  PccEprsInner,
  PfKeys,
  Pricing,
  Product,
  RequestSunsetRequest,
  RestApiError,
  ScribeScripts,
  SellerInfo,
} from '../models';
import {
    CcrzEProductMediaCSObjectFromJSON,
    CcrzEProductMediaCSObjectToJSON,
    CommentObjectFromJSON,
    CommentObjectToJSON,
    OrderingLimitationsResponseFromJSON,
    OrderingLimitationsResponseToJSON,
    PccEprsInnerFromJSON,
    PccEprsInnerToJSON,
    PfKeysFromJSON,
    PfKeysToJSON,
    PricingFromJSON,
    PricingToJSON,
    ProductFromJSON,
    ProductToJSON,
    RequestSunsetRequestFromJSON,
    RequestSunsetRequestToJSON,
    RestApiErrorFromJSON,
    RestApiErrorToJSON,
    ScribeScriptsFromJSON,
    ScribeScriptsToJSON,
    SellerInfoFromJSON,
    SellerInfoToJSON,
} from '../models';

export interface AddProductCommentRequest {
    sku: string;
    commentObject: CommentObject;
}

export interface AssignUserToFavouriteProductRequest {
    sku: string;
}

export interface CreatePfKeysRequest {
    pfKeys: PfKeys;
    pfKeysFiles?: Array<Blob>;
}

export interface CreatePricingRequest {
    sku: string;
    pricing?: Pricing;
}

export interface CreateScribeScriptsRequest {
    scribeScript: ScribeScripts;
    visibleScribeScriptFiles: Array<Blob>;
    hiddenScribeScriptFiles?: Array<Blob>;
    resources?: Array<Blob>;
    activeListeningConfig?: Blob;
}

export interface DeleteAdditionalGuidesRequest {
    sku: string;
    ccrzEProductMediaCSObject?: Array<CcrzEProductMediaCSObject>;
}

export interface DeletePricingRequest {
    sku: string;
}

export interface DeleteReferenceGuideRequest {
    sku: string;
}

export interface DeleteStorefrontImagesRequest {
    sku: string;
    ccrzEProductMediaCSObject?: Array<CcrzEProductMediaCSObject>;
}

export interface DeleteUserFromFavouriteProductRequest {
    sku: string;
}

export interface EditAdditionalGuidesRequest {
    sku: string;
    ccrzEProductMediaCSObject?: Array<CcrzEProductMediaCSObject>;
}

export interface GetOrderingLimitationsRequest {
    sku: string;
}

export interface GetPricingRequest {
    sku: string;
}

export interface GetProductRequest {
    sku: string;
}

export interface GetSellerDataRequest {
    sku: string;
}

export interface ObsoleteProductRequest {
    sku: string;
}

export interface PatchEprsOrderingLimitationsRequest {
    sku: string;
    pccEprsInner: Array<PccEprsInner>;
}

export interface PatchProductRequest {
    sku: string;
    product: Product;
    productIcon?: Blob;
    productStorefrontImages?: Array<Blob>;
    referenceGuide?: Blob;
    additionalFiles?: Array<Blob>;
}

export interface PatchScCodesOrderingLimitationsRequest {
    sku: string;
    requestBody: Array<string>;
}

export interface RemoveProductInCreationRequest {
    sku: string;
}

export interface RequestSunsetOperationRequest {
    sku: string;
    requestSunsetRequest?: RequestSunsetRequest;
}

export interface ValidateNameRequest {
    name: string;
}

/**
 * 
 */
export class ProductApi extends runtime.BaseAPI {

    /**
     * add comment to product
     */
    async addProductCommentRaw(requestParameters: AddProductCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling addProductComment.');
        }

        if (requestParameters.commentObject === null || requestParameters.commentObject === undefined) {
            throw new runtime.RequiredError('commentObject','Required parameter requestParameters.commentObject was null or undefined when calling addProductComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/comments`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentObjectToJSON(requestParameters.commentObject),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * add comment to product
     */
    async addProductComment(requestParameters: AddProductCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addProductCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Assign a user who favoured/pinned the product
     */
    async assignUserToFavouriteProductRaw(requestParameters: AssignUserToFavouriteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling assignUserToFavouriteProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/favourites`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Assign a user who favoured/pinned the product
     */
    async assignUserToFavouriteProduct(requestParameters: AssignUserToFavouriteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.assignUserToFavouriteProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createPfKeysRaw(requestParameters: CreatePfKeysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pfKeys === null || requestParameters.pfKeys === undefined) {
            throw new runtime.RequiredError('pfKeys','Required parameter requestParameters.pfKeys was null or undefined when calling createPfKeys.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.pfKeys !== undefined) {
            formParams.append('pfKeys', new Blob([JSON.stringify(PfKeysToJSON(requestParameters.pfKeys))], { type: "application/json", }));
                    }

        if (requestParameters.pfKeysFiles) {
            requestParameters.pfKeysFiles.forEach((element) => {
                formParams.append('pfKeysFiles', element as any);
            })
        }

        const response = await this.request({
            path: `/pf-keys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createPfKeys(requestParameters: CreatePfKeysRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPfKeysRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createPricingRaw(requestParameters: CreatePricingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling createPricing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/pricing`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PricingToJSON(requestParameters.pricing),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createPricing(requestParameters: CreatePricingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPricingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createScribeScriptsRaw(requestParameters: CreateScribeScriptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scribeScript === null || requestParameters.scribeScript === undefined) {
            throw new runtime.RequiredError('scribeScript','Required parameter requestParameters.scribeScript was null or undefined when calling createScribeScripts.');
        }

        if (requestParameters.visibleScribeScriptFiles === null || requestParameters.visibleScribeScriptFiles === undefined) {
            throw new runtime.RequiredError('visibleScribeScriptFiles','Required parameter requestParameters.visibleScribeScriptFiles was null or undefined when calling createScribeScripts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.scribeScript !== undefined) {
            formParams.append('scribeScript', new Blob([JSON.stringify(ScribeScriptsToJSON(requestParameters.scribeScript))], { type: "application/json", }));
                    }

        if (requestParameters.visibleScribeScriptFiles) {
            requestParameters.visibleScribeScriptFiles.forEach((element) => {
                formParams.append('visibleScribeScriptFiles', element as any);
            })
        }

        if (requestParameters.hiddenScribeScriptFiles) {
            requestParameters.hiddenScribeScriptFiles.forEach((element) => {
                formParams.append('hiddenScribeScriptFiles', element as any);
            })
        }

        if (requestParameters.resources) {
            requestParameters.resources.forEach((element) => {
                formParams.append('resources', element as any);
            })
        }

        if (requestParameters.activeListeningConfig !== undefined) {
            formParams.append('activeListeningConfig', requestParameters.activeListeningConfig as any);
        }

        const response = await this.request({
            path: `/scribe-scripts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createScribeScripts(requestParameters: CreateScribeScriptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createScribeScriptsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete given additional guides
     */
    async deleteAdditionalGuidesRaw(requestParameters: DeleteAdditionalGuidesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling deleteAdditionalGuides.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/files/additional-guides`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ccrzEProductMediaCSObject.map(CcrzEProductMediaCSObjectToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete given additional guides
     */
    async deleteAdditionalGuides(requestParameters: DeleteAdditionalGuidesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdditionalGuidesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deletePricingRaw(requestParameters: DeletePricingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling deletePricing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/pricing`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePricing(requestParameters: DeletePricingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePricingRaw(requestParameters, initOverrides);
    }

    /**
     * Delete reference guide for given product
     */
    async deleteReferenceGuideRaw(requestParameters: DeleteReferenceGuideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling deleteReferenceGuide.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/files/reference-guide`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete reference guide for given product
     */
    async deleteReferenceGuide(requestParameters: DeleteReferenceGuideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteReferenceGuideRaw(requestParameters, initOverrides);
    }

    /**
     * Delete given storefront images
     */
    async deleteStorefrontImagesRaw(requestParameters: DeleteStorefrontImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling deleteStorefrontImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/files/storefront-images`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ccrzEProductMediaCSObject.map(CcrzEProductMediaCSObjectToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete given storefront images
     */
    async deleteStorefrontImages(requestParameters: DeleteStorefrontImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteStorefrontImagesRaw(requestParameters, initOverrides);
    }

    /**
     * Deletes a user from favoured/pinned the product
     */
    async deleteUserFromFavouriteProductRaw(requestParameters: DeleteUserFromFavouriteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling deleteUserFromFavouriteProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/favourites`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Deletes a user from favoured/pinned the product
     */
    async deleteUserFromFavouriteProduct(requestParameters: DeleteUserFromFavouriteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteUserFromFavouriteProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit additional guides for given product
     */
    async editAdditionalGuidesRaw(requestParameters: EditAdditionalGuidesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling editAdditionalGuides.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/files/additional-guides`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.ccrzEProductMediaCSObject.map(CcrzEProductMediaCSObjectToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit additional guides for given product
     */
    async editAdditionalGuides(requestParameters: EditAdditionalGuidesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.editAdditionalGuidesRaw(requestParameters, initOverrides);
    }

    /**
     * Get ordering limitations for ScCodes and EPRs
     */
    async getOrderingLimitationsRaw(requestParameters: GetOrderingLimitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderingLimitationsResponse>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getOrderingLimitations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/limitations`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderingLimitationsResponseFromJSON(jsonValue));
    }

    /**
     * Get ordering limitations for ScCodes and EPRs
     */
    async getOrderingLimitations(requestParameters: GetOrderingLimitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderingLimitationsResponse> {
        const response = await this.getOrderingLimitationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPricingRaw(requestParameters: GetPricingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Pricing>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getPricing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/pricing`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PricingFromJSON(jsonValue));
    }

    /**
     */
    async getPricing(requestParameters: GetPricingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Pricing> {
        const response = await this.getPricingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductRaw(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     */
    async getProduct(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.getProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSellerDataRaw(requestParameters: GetSellerDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SellerInfo>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling getSellerData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/seller`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SellerInfoFromJSON(jsonValue));
    }

    /**
     */
    async getSellerData(requestParameters: GetSellerDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SellerInfo> {
        const response = await this.getSellerDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async obsoleteProductRaw(requestParameters: ObsoleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling obsoleteProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/statuses/obsolete`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async obsoleteProduct(requestParameters: ObsoleteProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.obsoleteProductRaw(requestParameters, initOverrides);
    }

    /**
     * Creates EPRs limitations field in SF
     */
    async patchEprsOrderingLimitationsRaw(requestParameters: PatchEprsOrderingLimitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling patchEprsOrderingLimitations.');
        }

        if (requestParameters.pccEprsInner === null || requestParameters.pccEprsInner === undefined) {
            throw new runtime.RequiredError('pccEprsInner','Required parameter requestParameters.pccEprsInner was null or undefined when calling patchEprsOrderingLimitations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/limitations/eprs`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.pccEprsInner.map(PccEprsInnerToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates EPRs limitations field in SF
     */
    async patchEprsOrderingLimitations(requestParameters: PatchEprsOrderingLimitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchEprsOrderingLimitationsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async patchProductRaw(requestParameters: PatchProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling patchProduct.');
        }

        if (requestParameters.product === null || requestParameters.product === undefined) {
            throw new runtime.RequiredError('product','Required parameter requestParameters.product was null or undefined when calling patchProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.product !== undefined) {
            formParams.append('product', new Blob([JSON.stringify(ProductToJSON(requestParameters.product))], { type: "application/json", }));
                    }

        if (requestParameters.productIcon !== undefined) {
            formParams.append('productIcon', requestParameters.productIcon as any);
        }

        if (requestParameters.productStorefrontImages) {
            requestParameters.productStorefrontImages.forEach((element) => {
                formParams.append('productStorefrontImages', element as any);
            })
        }

        if (requestParameters.referenceGuide !== undefined) {
            formParams.append('referenceGuide', requestParameters.referenceGuide as any);
        }

        if (requestParameters.additionalFiles) {
            requestParameters.additionalFiles.forEach((element) => {
                formParams.append('additionalFiles', element as any);
            })
        }

        const response = await this.request({
            path: `/products/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     */
    async patchProduct(requestParameters: PatchProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.patchProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates sc code limitations field in SF
     */
    async patchScCodesOrderingLimitationsRaw(requestParameters: PatchScCodesOrderingLimitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling patchScCodesOrderingLimitations.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling patchScCodesOrderingLimitations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/limitations/sc-codes`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates sc code limitations field in SF
     */
    async patchScCodesOrderingLimitations(requestParameters: PatchScCodesOrderingLimitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchScCodesOrderingLimitationsRaw(requestParameters, initOverrides);
    }

    /**
     * Remove product in status IN CREATION
     */
    async removeProductInCreationRaw(requestParameters: RemoveProductInCreationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling removeProductInCreation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/statuses/in-creation`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove product in status IN CREATION
     */
    async removeProductInCreation(requestParameters: RemoveProductInCreationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeProductInCreationRaw(requestParameters, initOverrides);
    }

    /**
     * Send a sunset request for the product
     */
    async requestSunsetRaw(requestParameters: RequestSunsetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling requestSunset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/sunsets`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestSunsetRequestToJSON(requestParameters.requestSunsetRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send a sunset request for the product
     */
    async requestSunset(requestParameters: RequestSunsetOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestSunsetRaw(requestParameters, initOverrides);
    }

    /**
     * Check if provided name is valid and unique among existing products and proposals.
     */
    async validateNameRaw(requestParameters: ValidateNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling validateName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{name}/validate`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Check if provided name is valid and unique among existing products and proposals.
     */
    async validateName(requestParameters: ValidateNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.validateNameRaw(requestParameters, initOverrides);
    }

}
