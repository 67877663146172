import * as yup from 'yup';
import {
  basicTextFieldMaxLength,
  basicTextFieldMinLength,
  maxArrayLength,
  minArrayLength,
  minDescriptionMessage,
  requiredArrayMessage,
  standardCorrectFieldMessage,
} from './proposalConstants';
import {
  isPaidDescriptionName,
  redAppCategoryName,
  regionalizationDescriptionName,
} from '../pages/proposalForm/ProposalValues';
import {textEditorLengthErrorName} from '@scm/product-components/schemas/updateProductSchema';

export const maxPricingLength = 250;

export const updateProposalSchema = yup.object().shape({
  [textEditorLengthErrorName]: yup.string().max(0),
  [redAppCategoryName]: yup.array().min(minArrayLength, requiredArrayMessage).max(maxArrayLength),
  [isPaidDescriptionName]: yup
    .string()
    .min(basicTextFieldMinLength, minDescriptionMessage)
    .max(maxPricingLength)
    .nullable(true),
  [regionalizationDescriptionName]: yup
    .string()
    .max(basicTextFieldMaxLength)
    .test(
      standardCorrectFieldMessage,
      minDescriptionMessage,
      function optionalTextFieldTest(this: {parent: {[key: string]: string}}, item?: string) {
        return !item?.length || item?.length >= basicTextFieldMinLength;
      }
    ),
});
