/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Notification
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotificationTemplateFields,
  NotificationTemplateResponse,
  SendNotificationToBuyersWithActiveOrdersRequest,
} from '../models';
import {
    NotificationTemplateFieldsFromJSON,
    NotificationTemplateFieldsToJSON,
    NotificationTemplateResponseFromJSON,
    NotificationTemplateResponseToJSON,
    SendNotificationToBuyersWithActiveOrdersRequestFromJSON,
    SendNotificationToBuyersWithActiveOrdersRequestToJSON,
} from '../models';

export interface GetNotificationTemplateByIdRequest {
    id: string;
}

export interface SendNotificationToBuyersWithActiveOrdersOperationRequest {
    sendNotificationToBuyersWithActiveOrdersRequest?: SendNotificationToBuyersWithActiveOrdersRequest;
}

export interface UpdateNotificationTemplateRequest {
    id: string;
    notificationTemplateFields?: NotificationTemplateFields;
}

/**
 * 
 */
export class NotificationApi extends runtime.BaseAPI {

    /**
     * Returns notification template by id
     */
    async getNotificationTemplateByIdRaw(requestParameters: GetNotificationTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NotificationTemplateFields>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNotificationTemplateById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationTemplateFieldsFromJSON(jsonValue));
    }

    /**
     * Returns notification template by id
     */
    async getNotificationTemplateById(requestParameters: GetNotificationTemplateByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NotificationTemplateFields> {
        const response = await this.getNotificationTemplateByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all available notification templates
     */
    async getNotificationTemplatesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotificationTemplateResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationTemplateResponseFromJSON));
    }

    /**
     * Returns all available notification templates
     */
    async getNotificationTemplates(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotificationTemplateResponse>> {
        const response = await this.getNotificationTemplatesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Sends email notification to buyers with active orders using red app id (sku)
     */
    async sendNotificationToBuyersWithActiveOrdersRaw(requestParameters: SendNotificationToBuyersWithActiveOrdersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/buyers-with-orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendNotificationToBuyersWithActiveOrdersRequestToJSON(requestParameters.sendNotificationToBuyersWithActiveOrdersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sends email notification to buyers with active orders using red app id (sku)
     */
    async sendNotificationToBuyersWithActiveOrders(requestParameters: SendNotificationToBuyersWithActiveOrdersOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendNotificationToBuyersWithActiveOrdersRaw(requestParameters, initOverrides);
    }

    /**
     * Updates notification template by id
     */
    async updateNotificationTemplateRaw(requestParameters: UpdateNotificationTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNotificationTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationTemplateFieldsToJSON(requestParameters.notificationTemplateFields),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates notification template by id
     */
    async updateNotificationTemplate(requestParameters: UpdateNotificationTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNotificationTemplateRaw(requestParameters, initOverrides);
    }

}
