import {useEffect} from 'react';
import {FieldValues, SubmitHandler, useForm, UseFormWatch} from 'react-hook-form';
import {Button} from '@sabre/spark-react-core';
import {ButtonContainer} from '../../../actionForms/BundleTestingForm';
import {FormattedMessage, useIntl} from 'react-intl';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {Configuration as CertificationConfiguration, VersionsApi} from '../../../../../generated/certification';
import {certificationApiBaseLink} from '../../../../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {TextEditor} from '@scm/components';
import styled from 'styled-components';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

interface FormValues {
  versionId: string;
  releaseNotesDescription: string;
}

const PatchReleaseNotesForm = ({
  watchBundle,
  versionId,
  initialReleaseNotes,
  onClose,
  reloadBundles,
}: {
  watchBundle?: UseFormWatch<FieldValues>;
  versionId: string;
  initialReleaseNotes: string;
  onClose: () => void;
  reloadBundles: () => void;
}) => {
  const {formatMessage} = useIntl();
  const initialValues: FormValues = {
    versionId: '',
    releaseNotesDescription: initialReleaseNotes,
  };
  const patchReleaseNotesMaxLength = 1000;

  const changeReleaseNotesName = 'productDetails.changeReleaseNotes';
  const releaseNotesEventName = 'releaseNotesDescription';
  const {
    handleSubmit,
    setValue,
    watch,
    formState: {isDirty},
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (watchBundle) {
      if (watchBundle('versionId') !== versionId) {
        setValue('versionId', versionId);
      }
    }
  });

  function patchReleaseNotes(versionId: string, releaseNotes: string) {
    return new VersionsApi(
      new CertificationConfiguration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken(),
        middleware: middleware,
      })
    ).setVersionReleaseNotes({
      id: versionId,
      description: {
        value: releaseNotes,
      },
    });
  }

  const onSubmit: SubmitHandler<FormValues> = async () => {
    try {
      const result = await patchReleaseNotes(versionId, watch(releaseNotesEventName));

      if (result) {
        openToast(
          createMessageString(formatMessage, changeReleaseNotesName, true),
          createMessageString(formatMessage, changeReleaseNotesName, false),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      }
    } catch (err) {
      openToast(
        createMessageString(formatMessage, changeReleaseNotesName, true, true),
        createMessageString(formatMessage, changeReleaseNotesName, false, true),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
    } finally {
      onClose();
      reloadBundles();
    }
  };

  return (
    <PatchReleaseNotesContainer>
      <p className="spark-mar-b-1 spark-small">
        <FormattedMessage id="tabs.releaseNotes.patchReleaseNotesForm.description" />
      </p>
      <TextEditor
        editorState={watch('releaseNotesDescription')}
        setValue={setValue}
        valueName={'releaseNotesDescription'}
        maxCharacters={patchReleaseNotesMaxLength}
      />
      <ButtonContainer className="spark-flex spark-mar-t-2">
        <Button secondary className="spark-mar-r-1" size={ButtonSize.SMALL} onClick={onClose}>
          <FormattedMessage id="common.cancelButton" />
        </Button>
        <Button size={ButtonSize.SMALL} type="submit" onClick={handleSubmit(onSubmit)} disabled={!isDirty} tabIndex={0}>
          <FormattedMessage id="common.save" />
        </Button>
      </ButtonContainer>
    </PatchReleaseNotesContainer>
  );
};

const PatchReleaseNotesContainer = styled.div`
  overflow: auto;
  max-width: 100rem;
`;

export default PatchReleaseNotesForm;
