import React, {ReactNode, useEffect, useRef} from 'react';
import SparkAccordion from '@sabre/spark/js/src/components/accordion';

const Accordion = ({children}: {children: ReactNode}) => {
  const accordionRef = useRef(null);

  useEffect(() => {
    const sparkAccordion = new SparkAccordion(accordionRef.current);
    return () => {
      sparkAccordion.remove();
    };
  }, [accordionRef]);

  return (
    <div className="spark-accordion" ref={accordionRef}>
      {children}
    </div>
  );
};

export default Accordion;
