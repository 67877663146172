import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import PartnerPageBackground from 'assets/images/partner_page_background.png';
import InternalUrls from 'utils/internalUrls';
import {useQuery} from '@apollo/client';
import {
  GetPartnerDetailsDocument,
  GetPartnerDetailsQuery,
  GetPartnerDetailsQueryVariables,
  Partner,
} from 'generated/graphql';
import CenterItems from 'components/styledComponents/CenterItems';
import {useParams} from 'react-router-dom';
import FormattedBoldMessage from 'components/formattedMessages/FormattedBoldMessage';
import CenteredErrorMessage from 'components/styledComponents/CenteredErrorMessage';
import PartnerDetailsDesktop from 'pages/partnerDetails/PartnerDetailsDesktop';
import PartnerDetailsMobile from 'pages/partnerDetails/PartnerDetailsMobile';
import {useMediaQuery} from 'react-responsive';
import {BreadcrumbLink, Breadcrumbs, Loading} from '@scm/components';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const {Home, Directory} = InternalUrls;

const PartnerDetails = () => {
  const {name} = useParams();
  const {formatMessage} = useIntl();
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const nameDecoded = decodeURIComponent(name ?? '');

  useEffect(() => window.scrollTo(0, 0));

  const {data, loading, error} = useQuery<GetPartnerDetailsQuery, GetPartnerDetailsQueryVariables>(
    GetPartnerDetailsDocument,
    {
      variables: {
        name: nameDecoded,
      },
    }
  );
  const partner: Partner | undefined = data?.getPartner && {...data?.getPartner, id: 'id'};
  const partnerName = partner?.name!;

  return (
    <PartnerDetailsContainer>
      <PartnerDetailsHeader textLength={partnerName?.length} mobile={isMobile}>
        <div>
          <FormattedBoldMessage id="partnerPage.title" values={{partnerName: partnerName}} />
        </div>
      </PartnerDetailsHeader>
      <Breadcrumbs>
        <BreadcrumbLink intlId="common.breadcrumb.home" href={Home} />
        <BreadcrumbLink intlId="common.breadcrumb.partnerDirectory" href={Directory} />
        <BreadcrumbLink text={partnerName} />
      </Breadcrumbs>
      {loading ? (
        <CenterItems>
          <Loading label={formatMessage({id: 'common.data.fetchingPartnerDetails'})} />
        </CenterItems>
      ) : error ? (
        <CenteredErrorMessage error={error} />
      ) : (
        <>
          <PartnerDetailsMobile partner={partner} />
          <PartnerDetailsDesktop partner={partner} />
        </>
      )}
    </PartnerDetailsContainer>
  );
};

const PartnerDetailsContainer = styled.main`
  background: ${colors.white};
  padding-bottom: 4rem;
  min-height: 85vh;
`;

const PartnerDetailsHeader = styled.div.attrs(() => ({
  className: 'spark-flex spark-text-center',
}))<{textLength: number; mobile?: boolean}>`
  justify-content: center;
  align-items: center;
  padding: 3rem;
  height: ${props => (props.mobile ? '20vh' : '18vh')};

  line-height: ${props => (props.mobile ? '120%' : '100%')};
  font-weight: 400;
  color: ${colors.white};
  background: ${props =>
    props.mobile ? `${colors.grey500}` : `url(${PartnerPageBackground}) no-repeat ${colors.grey500}`};
  background-size: cover;

  ${props =>
    props.mobile
      ? `font-size: ${props.textLength < 30 ? '2.5' : props.textLength > 60 ? '1.5' : '2'}rem`
      : `font-size: ${props.textLength < 30 ? '5' : props.textLength > 60 ? '2.25' : '3'}vw`};
`;

export default PartnerDetails;
