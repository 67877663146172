import {ButtonContainer} from '../../../actionForms/BundleTestingForm';
import {Button, Icon, TextInput} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {FileTypes} from '@scm/product-components/generated/certification';
import {Row} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import InlineButton from '@scm/admin-centre/src/components/InlineButton';
import React from 'react';
import {changeToSnakeCase, downloadFileAndHandleErrors} from '@scm/version/components/pages/certify/Certification';
import {createMessageString} from '@scm/components/messaging/openToast';
import {dateWithHour} from '@scm/components/utils/common';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {format} from 'date-fns';

const VersionFilesModal = ({
  onClose,
  versionId,
  processPiiOrPci,
  submittedAt,
  submittedBy,
  hash,
}: {
  onClose: () => void;
  versionId: string;
  processPiiOrPci: boolean;
  submittedAt: Date;
  submittedBy: string;
  hash: string;
}) => {
  const {formatMessage} = useIntl();
  const productDetailsName = 'productDetails.fetch';
  let errorMessageTitle = createMessageString(formatMessage, productDetailsName, true, true);
  let errorMessageDetails = createMessageString(formatMessage, productDetailsName, false, true);

  const handleDownload = (fileType: FileTypes) =>
    versionId &&
    downloadFileAndHandleErrors(
      versionId,
      changeToSnakeCase(fileType.toUpperCase()),
      errorMessageTitle,
      errorMessageDetails
    );

  return (
    <>
      <RowsGroup>
        <Row className="spark-mar-b-2">
          <TextArea
            name={'submittedAt'}
            value={format(new Date(submittedAt), dateWithHour)}
            defaultValue={formatMessage({id: 'tabs.versionFilesDetails.modal.notDefined'})}
            label={formatMessage({id: 'tabs.versionFilesDetails.modal.submittedAt'})}
            disabled
          >
            <Icon
              name="documents-empty"
              onClick={() => {
                navigator.clipboard.writeText(format(new Date(submittedAt), dateWithHour));
              }}
            />
          </TextArea>
        </Row>
        <Row className="spark-mar-b-2">
          <TextArea
            name={'submittedBy'}
            value={submittedBy}
            defaultValue={formatMessage({id: 'tabs.versionFilesDetails.modal.notDefined'})}
            label={formatMessage({id: 'tabs.versionFilesDetails.modal.submittedBy'})}
            disabled
          >
            <Icon
              name="documents-empty"
              onClick={() => {
                navigator.clipboard.writeText(submittedBy);
              }}
            />
          </TextArea>
        </Row>
        <Row className="spark-mar-b-2">
          <TextArea
            name={'hash'}
            value={hash}
            defaultValue={formatMessage({id: 'tabs.versionFilesDetails.modal.notCalculated'})}
            label={formatMessage({id: 'tabs.versionFilesDetails.modal.hash'})}
            disabled
          >
            <Icon
              name="documents-empty"
              onClick={() => {
                navigator.clipboard.writeText(hash);
              }}
            />
          </TextArea>
        </Row>
      </RowsGroup>

      <p className="spark-mar-b-1">
        <FormattedMessage id="tabs.versionFilesDetails.description" />
      </p>
      <DownloadButtonsContainer>
        {Object.values(FileTypes)
          .filter(
            type => processPiiOrPci || (type !== FileTypes.SecurityWorksheet && type !== FileTypes.WorkflowDiagram)
          )
          .map(type => (
            <InlineButton
              id={`certify.actionButton.${type}`}
              clickHandler={() => handleDownload(type)}
              type="button"
              className="neutral spark-mar-l-0 spark-mar-r-0 spark-mar-b-1 spark-btn col-xs-6"
            />
          ))}
      </DownloadButtonsContainer>

      <ButtonContainer className="spark-flex spark-mar-t-2">
        <Button secondary className="spark-mar-r-1" size={ButtonSize.SMALL} onClick={onClose}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </ButtonContainer>
    </>
  );
};

const DownloadButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowsGroup = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${deviceWidths.md}px) {
    width: calc(100% - 2rem);
    margin: 0 1rem;
  }
`;

export const TextArea = styled(TextInput)`
  & .spark-input__field:disabled,
  & .spark-label {
    cursor: default !important;
  }
`;

export default VersionFilesModal;
