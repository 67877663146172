import React, {useEffect, useRef} from 'react';
import SparkLoading from '@sabre/spark/js/src/components/progress-indicator';
import styled from 'styled-components';

export const Loading = ({
  label,
  sizing = 'lg',
  floatRight,
}: {
  label?: string;
  sizing?: 'sm' | 'md' | 'lg';
  floatRight?: boolean;
}) => {
  const loadingRef = useRef(null);

  useEffect(() => {
    const sparkLoading = new SparkLoading(loadingRef.current);
    return () => {
      sparkLoading.remove();
    };
  }, [loadingRef]);

  return (
    <LoadingContainer
      className={`spark-progress spark-progress--integrated spark-progress--${sizing}`}
      role="progressbar"
      ref={loadingRef}
      floatRight={floatRight}
      aria-label={label}
    >
      <progress />
      {label && (
        <span className="spark-progress__text" role="status">
          {label}
        </span>
      )}
      <span className="spark-progress__meter" />
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div<{floatRight?: boolean}>`
  min-height: auto;
  float: ${props => (props.floatRight === true ? 'right' : '')};
`;
