import {FormattedMessage, useIntl} from 'react-intl';
import React from 'react';
import {FileUpload} from '@scm/components';
import {pfKeysFileType} from '@scm/components/utils/common';
import {Icon, Popover, RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {FileNameType} from '@scm/proposal/pages/proposalForm/ProposalValues';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {PopoverText} from '@scm/proposal/pages/proposalForm/steps/definitionPage/definitionComponents/RedAppName';
import {pfKeyFileName, pfKeysFilesNames, PfKeysValues} from '../../provider/pfKeysValues';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const PfKeysFileUpload = ({
  watch,
  setValue,
}: {
  watch: UseFormWatch<PfKeysValues>;
  setValue: UseFormSetValue<PfKeysValues>;
}) => {
  const {formatMessage} = useIntl();
  const maxFiles = 20;
  const ariaLevel = 3;
  const pfKeyFileNameFieldsetLabel = 'pfKeyFileName';
  const setValueOptions = {
    shouldDirty: true,
    shouldTouch: true,
    shouldValidate: true,
  };

  const deleteFileHandler = (name?: string) => {
    if (name) {
      setValue(
        pfKeysFilesNames,
        (watch(pfKeysFilesNames) as Array<File>).filter((file: File) => file.name !== name),
        setValueOptions
      );
    } else {
      setValue(pfKeysFilesNames, [], setValueOptions);
    }
  };

  return (
    <>
      <div className="row spark-mar-r-1">
        <p className="spark-mar-l-2 spark-mar-t-1">
          <FormattedMessage
            id="definition.pfKeys.fileNameChoice.label"
            values={{star: (word: string) => <span className="spark-danger">{word}</span>}}
          />
        </p>
        {/*TODO: Incorrect type script type in spark library, wait for update*/}
        {/*@ts-ignore*/}
        <Popover
          anchorX={TooltipDirectionX.RIGHT}
          anchorY={TooltipDirectionY.MIDDLE}
          className="col-xs-2"
          closeButton
          toggleEl={
            <button
              type="button"
              aria-label={formatMessage({id: 'definition.popover.ariaLabel'})}
              className="spark-mar-l-1 spark-btn--icon spark-icon spark-icon--fill spark-icon-info-circle"
            />
          }
        >
          <h4 aria-level={ariaLevel}>
            <FormattedMessage id="definition.pfKeys.fileNameChoice.info.title" />
          </h4>
          <PopoverText>
            <FormattedMessage
              id="definition.pfKeys.fileNameChoice.info.firstParagraph"
              values={{strong: (word: string) => <strong>{word}</strong>}}
            />
          </PopoverText>
          <PopoverText>
            <FormattedMessage id="definition.pfKeys.fileNameChoice.info.secondParagraph" />
          </PopoverText>
          <PopoverText>
            <FormattedMessage
              id="definition.pfKeys.fileNameChoice.info.thirdParagraph"
              values={{
                strong: (word: string) => <strong>{word}</strong>,
                em: (word: string) => <em>{word}</em>,
              }}
            />
          </PopoverText>
          <PopoverText className="spark-text-center">
            <FormattedMessage id="definition.pfKeys.fileNameChoice.info.fourthParagraph" />
          </PopoverText>
          <PopoverText>
            <FormattedMessage
              id="definition.pfKeys.fileNameChoice.info.fifthParagraph"
              values={{
                strong: (word: string) => <strong>{word}</strong>,
                em: (word: string) => <em>{word}</em>,
              }}
            />
          </PopoverText>
        </Popover>
      </div>
      <div className="spark-mar-l-1 spark-mar-r-2 spark-mar-b-2">
        <RadioButtonGroup
          name={pfKeyFileName}
          className="spark-align-items-center"
          value={watch(pfKeyFileName)}
          onChange={(_, val) =>
            setValue(pfKeyFileName, FileNameType[val as keyof typeof FileNameType], {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            })
          }
          aria-label={pfKeyFileNameFieldsetLabel}
        >
          {Object.values(FileNameType).map(name => (
            <RadioButton key={name} label={name} value={name} name={name} className="spark-mar-l-2" />
          ))}
        </RadioButtonGroup>
        <FilesList className="spark-list--no-bullets spark-mar-l-0">
          {watch(pfKeysFilesNames).map((file: File) => (
            <li key={file.name} onClick={() => deleteFileHandler(file.name)}>
              {file.name}
              <Icon name="trash" />
            </li>
          ))}
        </FilesList>
        <FileUpload
          filesNames={pfKeysFilesNames}
          setFiles={setValue as () => void}
          watch={watch}
          filesType={pfKeysFileType}
          maxFiles={maxFiles}
          additionalText={formatMessage({
            id: 'definition.pfKeys.fileUpload.description',
          })}
        />
      </div>
    </>
  );
};

export const FilesList = styled.ul`
  & .spark-icon {
    font-size: 1.8rem;
    vertical-align: -1px;
    color: ${colors.sparkBlue};
    cursor: pointer;
  }
`;

export default PfKeysFileUpload;
