import React, {ReactElement, useContext} from 'react';
import {RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {Control, Controller, FieldValues} from 'react-hook-form';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {VersionContext} from '../../../../provider/VersionProvider';
import {TestingEnvironment} from './BundleTesting';
import Legend from '@scm/components/accessibility/Legend';
import {MissingItemsForApprovalContext} from '../../../../page/content/basic-info/MissingItemsForApprovalContext';

export enum TestValues {
  'Failed' = 'Failed',
  'Passed & Publish Now' = 'Passed & Publish Now',
  'Passed' = 'Passed',
}

const TestResult = ({
  testingEnvironment,
  control,
}: {
  testingEnvironment: TestingEnvironment;
  control: Control<FieldValues, any>;
}) => {
  const {formatMessage} = useIntl();
  const {isReady} = useContext(MissingItemsForApprovalContext);
  const {bundles} = useContext(VersionContext);
  const lastBundle = bundles[0];
  const isPatchMessageFormatting = () => (!lastBundle.isPatch ? '.new' : '.patch');
  const testValuesName = 'testingResult';

  return (
    <Controller
      name={testValuesName}
      control={control}
      render={({field: {onChange, name, value}}): ReactElement => (
        <RadioButtonGroup name={name} value={value} onChange={onChange}>
          <Legend text="Select tests results" />
          <RadioButton className="col-xs-12" label={TestValues.Passed} value={TestValues.Passed} />
          <AdditionalText className="spark-mar-b-0 spark-small">
            {formatMessage({
              id: `bundleTesting.${testingEnvironment}.passDescription`,
            })}
          </AdditionalText>
          <RadioButton
            className={`col-xs-12 ${!isReady && 'spark-hidden'}`}
            label={TestValues['Passed & Publish Now']}
            value={TestValues['Passed & Publish Now']}
          />
          {isReady && (
            <AdditionalText className="spark-mar-b-0 spark-small">
              {formatMessage({
                id: `bundleTesting.passAndPublishDescription${isPatchMessageFormatting()}`,
              })}
            </AdditionalText>
          )}
          <RadioButton className="col-xs-12" label={TestValues.Failed} value={TestValues.Failed} />
          <AdditionalText className="spark-mar-b-0 spark-small">
            {formatMessage({
              id: 'bundleTesting.failDescription',
            })}
          </AdditionalText>
        </RadioButtonGroup>
      )}
    />
  );
};

const AdditionalText = styled.p`
  padding-left: 4rem;
  transform: translateY(-1rem);
`;

export default TestResult;
