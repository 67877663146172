/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductInfoResponse } from './ProductInfoResponse';
import {
    ProductInfoResponseFromJSON,
    ProductInfoResponseFromJSONTyped,
    ProductInfoResponseToJSON,
} from './ProductInfoResponse';
import type { ProposalStatus } from './ProposalStatus';
import {
    ProposalStatusFromJSON,
    ProposalStatusFromJSONTyped,
    ProposalStatusToJSON,
} from './ProposalStatus';
import type { ProvisioningType } from './ProvisioningType';
import {
    ProvisioningTypeFromJSON,
    ProvisioningTypeFromJSONTyped,
    ProvisioningTypeToJSON,
} from './ProvisioningType';

/**
 * 
 * @export
 * @interface ProposalResponse
 */
export interface ProposalResponse {
    /**
     * 
     * @type {string}
     * @memberof ProposalResponse
     */
    proposalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalResponse
     */
    sellerid?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalResponse
     */
    submittedBy?: string;
    /**
     * 
     * @type {ProvisioningType}
     * @memberof ProposalResponse
     */
    provisioningType?: ProvisioningType;
    /**
     * 
     * @type {ProposalStatus}
     * @memberof ProposalResponse
     */
    status?: ProposalStatus;
    /**
     * 
     * @type {ProductInfoResponse}
     * @memberof ProposalResponse
     */
    productInfoResponse?: ProductInfoResponse;
    /**
     * Timestamp in ISO 8601 format.
     * @type {Date}
     * @memberof ProposalResponse
     */
    lastModifiedAt?: Date;
    /**
     * Timestamp in ISO 8601 format.
     * @type {Date}
     * @memberof ProposalResponse
     */
    createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProposalResponse
     */
    proposalStep?: number;
    /**
     * 
     * @type {string}
     * @memberof ProposalResponse
     */
    productSku?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalResponse
     */
    reasonRejected?: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalResponse
     */
    onboardingId?: string;
}

/**
 * Check if a given object implements the ProposalResponse interface.
 */
export function instanceOfProposalResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProposalResponseFromJSON(json: any): ProposalResponse {
    return ProposalResponseFromJSONTyped(json, false);
}

export function ProposalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProposalResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposalId': !exists(json, 'proposalId') ? undefined : json['proposalId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'sellerid': !exists(json, 'sellerid') ? undefined : json['sellerid'],
        'submittedBy': !exists(json, 'submittedBy') ? undefined : json['submittedBy'],
        'provisioningType': !exists(json, 'provisioningType') ? undefined : ProvisioningTypeFromJSON(json['provisioningType']),
        'status': !exists(json, 'status') ? undefined : ProposalStatusFromJSON(json['status']),
        'productInfoResponse': !exists(json, 'productInfoResponse') ? undefined : ProductInfoResponseFromJSON(json['productInfoResponse']),
        'lastModifiedAt': !exists(json, 'lastModifiedAt') ? undefined : (new Date(json['lastModifiedAt'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'proposalStep': !exists(json, 'proposalStep') ? undefined : json['proposalStep'],
        'productSku': !exists(json, 'productSku') ? undefined : json['productSku'],
        'reasonRejected': !exists(json, 'reasonRejected') ? undefined : json['reasonRejected'],
        'onboardingId': !exists(json, 'onboardingId') ? undefined : json['onboardingId'],
    };
}

export function ProposalResponseToJSON(value?: ProposalResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposalId': value.proposalId,
        'name': value.name,
        'sellerid': value.sellerid,
        'submittedBy': value.submittedBy,
        'provisioningType': ProvisioningTypeToJSON(value.provisioningType),
        'status': ProposalStatusToJSON(value.status),
        'productInfoResponse': ProductInfoResponseToJSON(value.productInfoResponse),
        'lastModifiedAt': value.lastModifiedAt === undefined ? undefined : (value.lastModifiedAt.toISOString()),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'proposalStep': value.proposalStep,
        'productSku': value.productSku,
        'reasonRejected': value.reasonRejected,
        'onboardingId': value.onboardingId,
    };
}

