import {minAdditionalInfoMessage, priceAdditionalInfoMinLength} from '@scm/proposal/schemas/proposalConstants';
import * as yup from 'yup';
import {
  additionalInfoName,
  perPccepr,
  perTransaction,
  pricingLevelName,
  PricingValues,
  rowsListPccepr,
} from '../pages/productDetails/productTabs/tabs/pricing/PricingValues';
import {PricingValidator} from '../pricingMapper/PricingValidator';

const successMessage = 'All form fields are correct';

export const pricingSchema = yup
  .object()
  .shape({
    [perPccepr]: yup.boolean(),
    [perTransaction]: yup.boolean(),
    perTransactionType: yup.string(),
    [additionalInfoName]: yup.string().min(priceAdditionalInfoMinLength, minAdditionalInfoMessage),
    [pricingLevelName]: yup.string(),
    perPcceprType: yup.string(),
    [rowsListPccepr]: yup.array(),
  })
  .test(successMessage, function (value) {
    type PricingType = typeof value & PricingValues;
    const {
      perPccepr,
      perTransaction,
      additionalInfo,
      pricingLevel,
      selectedFeeRecurrence,
      perPcceprType,
      perTransactionType,
      rowsListPccepr,
      rowsListTransaction,
    } = value as unknown as PricingType;

    if (!PricingValidator.isPricingChecked(perPccepr, perTransaction)) {
      return false;
    }

    if (
      !PricingValidator.isAdditionalInfoCorrect(perTransaction, perPcceprType?.includes('NEGOTIABLE'), additionalInfo)
    ) {
      return false;
    }

    if (!PricingValidator.isPricingLevelCorrect(perPccepr, pricingLevel)) {
      return false;
    }

    if (!PricingValidator.isBillingPeriodCorrect(perPccepr, pricingLevel, selectedFeeRecurrence)) {
      return false;
    }

    if (!PricingValidator.isPriceDefinitionCorrect(perPccepr, perPcceprType?.includes('NEGOTIABLE'), perPcceprType)) {
      return false;
    }

    if (
      !PricingValidator.isPriceDefinitionCorrect(
        perTransaction,
        perTransactionType?.includes('NEGOTIABLE'),
        perTransactionType
      )
    ) {
      return false;
    }

    if (!PricingValidator.isFixedPriceCorrect(rowsListPccepr, perPcceprType)) {
      return false;
    }

    if (!PricingValidator.isFixedPriceCorrect(rowsListTransaction, perTransactionType)) {
      return false;
    }

    if (!PricingValidator.isRangePriceCorrect(rowsListPccepr, perPcceprType)) {
      return false;
    }

    return PricingValidator.isRangePriceCorrect(rowsListTransaction, perTransactionType);
  });
