/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Accounting } from './Accounting';
import {
    AccountingFromJSON,
    AccountingFromJSONTyped,
    AccountingToJSON,
} from './Accounting';
import type { Gds } from './Gds';
import {
    GdsFromJSON,
    GdsFromJSONTyped,
    GdsToJSON,
} from './Gds';
import type { Pci } from './Pci';
import {
    PciFromJSON,
    PciFromJSONTyped,
    PciToJSON,
} from './Pci';
import type { Pii } from './Pii';
import {
    PiiFromJSON,
    PiiFromJSONTyped,
    PiiToJSON,
} from './Pii';

/**
 * 
 * @export
 * @interface Security
 */
export interface Security {
    /**
     * 
     * @type {Pci}
     * @memberof Security
     */
    pci?: Pci;
    /**
     * 
     * @type {Pii}
     * @memberof Security
     */
    pii?: Pii;
    /**
     * 
     * @type {string}
     * @memberof Security
     */
    protectionComment?: string;
    /**
     * 
     * @type {Gds}
     * @memberof Security
     */
    gds?: Gds;
    /**
     * 
     * @type {Accounting}
     * @memberof Security
     */
    accounting?: Accounting;
    /**
     * 
     * @type {string}
     * @memberof Security
     */
    patchTimeline?: string;
}

/**
 * Check if a given object implements the Security interface.
 */
export function instanceOfSecurity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecurityFromJSON(json: any): Security {
    return SecurityFromJSONTyped(json, false);
}

export function SecurityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Security {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pci': !exists(json, 'pci') ? undefined : PciFromJSON(json['pci']),
        'pii': !exists(json, 'pii') ? undefined : PiiFromJSON(json['pii']),
        'protectionComment': !exists(json, 'protectionComment') ? undefined : json['protectionComment'],
        'gds': !exists(json, 'gds') ? undefined : GdsFromJSON(json['gds']),
        'accounting': !exists(json, 'accounting') ? undefined : AccountingFromJSON(json['accounting']),
        'patchTimeline': !exists(json, 'patchTimeline') ? undefined : json['patchTimeline'],
    };
}

export function SecurityToJSON(value?: Security | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pci': PciToJSON(value.pci),
        'pii': PiiToJSON(value.pii),
        'protectionComment': value.protectionComment,
        'gds': GdsToJSON(value.gds),
        'accounting': AccountingToJSON(value.accounting),
        'patchTimeline': value.patchTimeline,
    };
}

