const menu = {
  'menu.myProducts': 'My Products',
  'menu.manageDevelopers': 'Manage Developers',
  'menu.mySalesAndReports': 'My Sales & Reports',
  'menu.myAgencyOrders': 'My Agency Orders',
  'menu.reports': 'Reports',
  'menu.resources': 'Resources',
  'menu.developers': 'Developers',
  'menu.partnerProfile': 'Partner Profile',
  'menu.certificationEngineerDashboard': 'Certification Engineer Dashboard',
  'menu.certificationEngineerDashboard.shortName': 'CE Dashboard',
  'menu.cleanup': 'Cleanup',
  'menu.users': 'Users',
  'menu.freezeCalendar': 'Freeze Calendar',
  'menu.notifications': 'Notifications',
  'menu.roadmap': 'Features Roadmap',
  'menu.certificationProposals': 'Proposals',
  'menu.messageBox.content':
    'Welcome to the new Admin Centre. We hope you will find it easy and enjoyable to use. However, if you encounter any problems, we would be grateful if you would let us know by writing to scm-support@sabre.com. Thank you.',
  'menu.successSave.title': 'Saved.',
  'menu.successSave.details': 'Your storefront changes have been saved and published.',
  'menu.successSave.testers.title': 'Saved.',
  'menu.successSave.testers.details': 'Your changes have been saved.',
  'menu.successSave.bundleTesting.title': 'Bundle Submitted.',
  'menu.successSave.bundleTesting.details': 'It will be validated and ready to test on CERT within a few seconds.',
  'menu.successSave.publish.title': 'Published.',
  'menu.successSave.publish.details': 'Thank you for submitting a new bundle',
  'menu.comingSoon': 'Coming soon',
};

export default menu;
