import React, {useContext} from 'react';
import {ProposalContext} from '../../../ProposalForm';
import SectionHeader from './SectionHeader';
import {
  allRegionsName,
  countryCodesName,
  regionalizationDescriptionName,
  regionCodesName,
} from '../../../ProposalValues';
import ReviewRow, {RowLabel} from './ReviewRow';
import {countriesInRegion} from '@scm/components/assets/regionsAndCountries';
import {FormattedMessage} from 'react-intl';

const RegionalizationReview = () => {
  const {watch} = useContext(ProposalContext);
  const regionCodes = watch(regionCodesName);

  const createRegionalization = () => {
    if (watch(allRegionsName)) {
      return <ReviewRow name={allRegionsName} />;
    } else {
      const countriesInSelectedRegions = regionCodes
        .map(regionCode => Object.keys((countriesInRegion as unknown as {[key: string]: Object})[regionCode]))
        .flat();
      const countriesOutsideSelectedRegions = watch(countryCodesName).filter(
        countryCode => !countriesInSelectedRegions.includes(countryCode)
      );

      return (
        <>
          {!!watch(regionCodesName).length && (
            <p>
              <RowLabel>
                <FormattedMessage id={`review.${regionCodesName}`} />
              </RowLabel>
              {regionCodes.join(', ')}
            </p>
          )}
          {!!countriesOutsideSelectedRegions.length && (
            <p>
              <RowLabel>
                <FormattedMessage id={`review.${countryCodesName}`} />
              </RowLabel>
              {countriesOutsideSelectedRegions.join(', ')}
            </p>
          )}
        </>
      );
    }
  };

  const regionalizationComment = watch(regionalizationDescriptionName);

  return (
    <>
      <SectionHeader id="review.regionalization.title" />
      {createRegionalization()}
      {!!regionalizationComment?.length && (
        <p>
          <RowLabel>
            <FormattedMessage id={`review.${regionalizationDescriptionName}`} />
          </RowLabel>
          {watch(regionalizationDescriptionName)}
        </p>
      )}
    </>
  );
};
export default RegionalizationReview;
