import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

const SunsetDescriptionText = () => {
  return (
    <DescriptionContainer>
      <ul>
        <li>
          <FormattedMessage id="sunset.description.item1" />
        </li>
        <li>
          <FormattedMessage id="sunset.description.item2" />
        </li>
        <SubLi>
          <FormattedMessage id="sunset.description.item2.item1" />
        </SubLi>
        <SubLi>
          <FormattedMessage id="sunset.description.item2.item2" />
        </SubLi>
        <SubLi>
          <FormattedMessage id="sunset.description.item2.item3" />
        </SubLi>
        <li>
          <FormattedMessage id="sunset.description.item3" />
        </li>
        <li>
          <FormattedMessage id="sunset.description.item4" />
        </li>
        <li>
          <FormattedMessage id="sunset.description.item5" />
        </li>
        <li>
          <FormattedMessage id="sunset.description.item6" />
        </li>
        <li>
          <FormattedMessage id="sunset.description.item7" />
        </li>
        <li>
          <FormattedMessage id="sunset.description.item8" />
        </li>
        <li>
          <FormattedMessage id="sunset.description.item9" />
        </li>
      </ul>
    </DescriptionContainer>
  );
};

const DescriptionContainer = styled.div`
  margin: 1rem 2rem 2rem 2rem;
`;

const SubLi = styled.li`
  margin-left: 2rem;
  list-style-type: circle;
`;

export default SunsetDescriptionText;
