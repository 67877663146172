import {boolean, object} from 'yup';
import {REQUIRED_EPR_SCHEMA, REQUIRED_PCC_SCHEMA} from '@scm/components/pccEpr/pccEprSchema';

export const pccEprSchema = object().shape({
  pcc: REQUIRED_PCC_SCHEMA,
  epr: REQUIRED_EPR_SCHEMA,
  canManageCompany: boolean(),
  canSubmitProposal: boolean(),
  canAccessAllProducts: boolean(),
});
