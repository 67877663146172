import thunk from 'redux-thunk';
import {applyMiddleware, combineReducers, createStore, Reducer} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {reducer} from 'components/modals/fullPageModal/store/reducer';
import {partnerDirectoryReducer} from 'pages/partnerDirectory/store/reducer';
import {PartnerDirectoryState} from 'pages/partnerDirectory/store/types';
import {FullPageModalState} from 'components/modals/fullPageModal/store/types';

export interface ReduxState {
  partnerDirectoryState: PartnerDirectoryState;
  fullPageModalState: FullPageModalState;
}

const rootReducer: Reducer<ReduxState> = combineReducers({
  partnerDirectoryState: partnerDirectoryReducer,
  fullPageModalState: reducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
}
