import React, {useEffect, useState} from 'react';
import {createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider} from 'react-router-dom';
import {detect} from 'detect-browser';
import NotSupportedBrowser from 'pages/home/supportedBrowser/NotSupportedBrowser';
import Header from 'spark/header/Header';
import CookiesConsent from 'components/CookiesConsent';
import FullPageModal from 'components/modals/fullPageModal/FullPageModal';
import Home from 'pages/home/Home';
import PartnerDirectory from 'pages/partnerDirectory/PartnerDirectory';
import PartnerDetails from 'pages/partnerDetails/PartnerDetails';
import Benefits from 'pages/benefits/Benefits';
import PartnerTiers from 'pages/partnerTiers/PartnerTiers';
import {Footer} from '@scm/components';
import ExternalUrls from '@scm/components/utils/externalUrls';
import {useAnalytics} from '../googleAnalytics/useAnalytics';
import {Login} from '@scm/authentication';
import {config} from '../config/config';
import {JoinUs as JoinUsNewFeature} from '@scm/onboarding/joinUs/JoinUs';
import ConfigProvider from '../config/ConfigProvider';
import GaProvider from '../googleAnalytics/GoogleAnalyticsProvider';
import {Helmet} from 'react-helmet';
import {ITermsAndConditionsHelper} from '@scm/terms-and-conditions/utils/ITermsAndConditionsHelper';
import {TermsAndConditionsModal} from '@scm/terms-and-conditions';

const Root = () => {
  const browser = detect();
  const isBrowserSupported = browser!.name !== 'ie';

  const AppBody = () => {
    const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);

    const handleTermsAndConditionsOpen = () => setTermsAndConditionsOpen(true);
    const handleTermsAndConditionsClose = () => setTermsAndConditionsOpen(false);

    const buildTermsAndConditionsHelper: ITermsAndConditionsHelper = {
      isOpen: termsAndConditionsOpen,
      handleOpen: handleTermsAndConditionsOpen,
      handleClose: handleTermsAndConditionsClose,
    };

    useAnalytics();
    return (
      <>
        <Helmet>
          <meta name="robots" content={config.envName === 'prod' ? 'index,follow' : 'noindex,nofollow'} />
        </Helmet>
        <Header />
        {isBrowserSupported ? (
          <div>
            <FullPageModal />
            <Outlet />
          </div>
        ) : (
          <NotSupportedBrowser />
        )}
        <CookiesConsent />
        <Footer
          externalUrls={ExternalUrls}
          terms={
            <TermsAndConditionsModal
              open={buildTermsAndConditionsHelper.isOpen}
              handleClose={buildTermsAndConditionsHelper.handleClose}
              isReadOnly
            />
          }
          handleTermsAndConditionsOpen={buildTermsAndConditionsHelper.handleOpen}
        />
      </>
    );
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppBody />}>
        <Route path="/" element={<Home />} />
        <Route path="/authCallback" element={<Login />} />
        <Route path="/directory" element={<PartnerDirectory />} />
        <Route path="/partners/:name" element={<PartnerDetails />} />
        <Route path="/benefits" element={<Benefits />} />
        <Route path="/tiers" element={<PartnerTiers />} />
        <Route
          path="/join-us"
          element={
            <ConfigProvider>
              <GaProvider>
                <JoinUsNewFeature />
              </GaProvider>
            </ConfigProvider>
          }
        />
        <Route path="/:name" element={<PartnerDetails />} />
      </Route>
    ),
    {basename: process.env.PUBLIC_URL}
  );

  useEffect(() => {
    const target = document.getElementById('root');
    const observer = new MutationObserver(() => {
      const toastContent = Array.from(document.querySelectorAll('.spark-toast__content'));
      const messageContent = Array.from(document.querySelectorAll('.spark-message__content'));

      const addAttrs = (content: Element) => {
        const header = content.querySelector('h4');
        const paragraph = content.querySelector('p');

        if (header) {
          header.setAttribute('test-id', 'banner-header');
        }

        if (paragraph) {
          paragraph.setAttribute('test-id', 'banner-content');
        }
      };

      toastContent.forEach(addAttrs);
      messageContent.forEach(addAttrs);
    });

    observer.observe(target as unknown as HTMLDivElement, {attributes: false, childList: true, subtree: true});

    return () => {
      observer.disconnect();
    };
  }, []);

  return <RouterProvider router={router} />;
};

export default Root;
