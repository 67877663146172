import {
  blockOrderingAndHideProductDateName,
  blockOrderingAndHideProductImmediatelyName,
  RequestSunsetValues,
  sendNotificationConfirmationName,
  sendNotificationDateAsapName,
  sendNotificationDateName,
  sendNotificationTextName,
  sunsetDateAsapName,
  sunsetDateName,
} from './RequestSunsetValues';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

export const initialValues: RequestSunsetValues = {
  [blockOrderingAndHideProductImmediatelyName]: null,
  [blockOrderingAndHideProductDateName]: '',
  [sunsetDateAsapName]: null,
  [sunsetDateName]: '',
  [sendNotificationConfirmationName]: null,
  [sendNotificationDateAsapName]: Confirmation.Yes,
  [sendNotificationDateName]: '',
  [sendNotificationTextName]: '',
};
