import {Icon} from '@sabre/spark-react-core';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const IncorrectProfile = () => {
  return (
    <Container>
      <Header>
        <Icon name="lock-close" className="spark-icon--lg" />
        <HeaderHeading>You are not authorized</HeaderHeading>
        <HeaderDescription>
          You need to fill in the missing information of your user profile to be able to use the SCM Admin Centre.
          Please go to <a href="https://central.sabre.com">Sabre Central</a> and complete your information, especially
          update your email from @updateyouremail to the correct address. Once your profile information is updated,
          please return to SCM Admin Centre and <a href="/#">refresh</a> the page.
        </HeaderDescription>
      </Header>
    </Container>
  );
};

export const Container = styled.main`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4% 1rem;
  max-width: 1170px;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 100%;
  --bgc-color: ${colors.grey50};
  --text-heading: ${colors.black};
  --text-card: ${colors.highlightGrey};
  --text: ${colors.grey900};
  --red: ${colors.lightRed};
  --gray: ${colors.grey50};
  --white: ${colors.white};
  --button-hover: ${colors.lightRed};
  --button-hover-text: ${colors.lightRed};
  --button-red: ${colors.lightRed};
  --button-light-red: ${colors.lightRed};
`;

export const Header = styled.header`
  text-align: center;
  max-width: 840px;
  margin: 0 auto;
`;

export const HeaderHeading = styled.h1`
  position: relative;
  font-size: 3rem;
  line-height: 3rem;
  font-feature-settings: 'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1;
  margin-bottom: 1rem;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: var(--text-heading);
  font-family: Roboto Condensed, sans-serif;
  font-weight: 700;

  ::after {
    content: '';
    position: static;
    display: block;
    height: 0.5rem;
    background: var(--red);
    width: 25%;
    min-width: 70px;
    max-width: 100px;
    margin: 1rem auto 2rem auto;
    border-radius: 0.5rem;
  }
`;

export const HeaderDescription = styled.p`
  font-feature-settings: 'kern' 1, 'liga' 0;
  font-size: 2rem;
  line-height: 2rem;
`;

export default IncorrectProfile;
