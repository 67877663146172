/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OnboardingStatus } from './OnboardingStatus';
import {
    OnboardingStatusFromJSON,
    OnboardingStatusFromJSONTyped,
    OnboardingStatusToJSON,
} from './OnboardingStatus';
import type { OnboardingType } from './OnboardingType';
import {
    OnboardingTypeFromJSON,
    OnboardingTypeFromJSONTyped,
    OnboardingTypeToJSON,
} from './OnboardingType';

/**
 * 
 * @export
 * @interface BasicDetailsOfOnboardingRequest
 */
export interface BasicDetailsOfOnboardingRequest {
    /**
     * UUID of created proposal document
     * @type {string}
     * @memberof BasicDetailsOfOnboardingRequest
     */
    id?: string;
    /**
     * Timestamp in ISO 8601 format.
     * @type {Date}
     * @memberof BasicDetailsOfOnboardingRequest
     */
    createdAt?: Date;
    /**
     * 
     * @type {OnboardingStatus}
     * @memberof BasicDetailsOfOnboardingRequest
     */
    status?: OnboardingStatus;
    /**
     * 
     * @type {OnboardingType}
     * @memberof BasicDetailsOfOnboardingRequest
     */
    type?: OnboardingType;
    /**
     * Name of the company
     * @type {string}
     * @memberof BasicDetailsOfOnboardingRequest
     */
    companyName?: string;
    /**
     * Country from company address
     * @type {string}
     * @memberof BasicDetailsOfOnboardingRequest
     */
    companyCountry?: string;
}

/**
 * Check if a given object implements the BasicDetailsOfOnboardingRequest interface.
 */
export function instanceOfBasicDetailsOfOnboardingRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BasicDetailsOfOnboardingRequestFromJSON(json: any): BasicDetailsOfOnboardingRequest {
    return BasicDetailsOfOnboardingRequestFromJSONTyped(json, false);
}

export function BasicDetailsOfOnboardingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicDetailsOfOnboardingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'status': !exists(json, 'status') ? undefined : OnboardingStatusFromJSON(json['status']),
        'type': !exists(json, 'type') ? undefined : OnboardingTypeFromJSON(json['type']),
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyCountry': !exists(json, 'companyCountry') ? undefined : json['companyCountry'],
    };
}

export function BasicDetailsOfOnboardingRequestToJSON(value?: BasicDetailsOfOnboardingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'status': OnboardingStatusToJSON(value.status),
        'type': OnboardingTypeToJSON(value.type),
        'companyName': value.companyName,
        'companyCountry': value.companyCountry,
    };
}

