import StatusIcon from '@scm/admin-centre/src/components/styledComponents/StatusIcon';
import {useContext, useLayoutEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {format} from 'date-fns';
import {dateWithHour} from '@scm/components/utils/common';
import {Popover} from '@sabre/spark-react-core';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {Link} from 'react-router-dom';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {Bundle} from '@scm/product-components/pages/productDetails/productTabs/ProductTabsContainer';
import PopoverListItem from '@scm/product-components/pages/productDetails/productTabs/bundles/PopoverListItem';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {StorefrontDataContext} from '@scm/product-components/pages/storefrontData/dataProvider/StorefrontDataProvider';
import {redAppIdName, typeName} from '@scm/product-components/pages/storefrontData/interfaces/AppInfoDataValues';
import {
  isAllowedToAddRemoveTesters,
  isAllowedToEditProducts,
  isVersionManageAllowedRoles,
} from '@scm/authentication/utils/authentication';
import {Status} from '../../../../generated/certification';

const BundleRow = ({
  bundleDetails,
  handleOpen,
  compatibilityFormId,
  setEditedBundle,
  setEditedReleaseNotes,
  setEditedSimplifiedDescription,
  setSvsErrors,
  setEditedCleanupDates,
  setVersionFiles,
}: {
  bundleDetails: Bundle;
  handleOpen?: (open: boolean) => void;
  compatibilityFormId?: string;
  setEditedBundle?: (bundle: Bundle) => void;
  setEditedReleaseNotes?: (bundle: Bundle) => void;
  setEditedSimplifiedDescription?: (bundle: Bundle) => void;
  setSvsErrors?: (bundle: Bundle) => void;
  setEditedCleanupDates?: (bundle: Bundle) => void;
  setVersionFiles?: (bundle: Bundle) => void;
}) => {
  const {formatMessage} = useIntl();
  const {methodsProductForm} = useContext(StorefrontDataContext);
  const {watch} = methodsProductForm;
  const bundleVersion = bundleDetails.version;
  const productType = watch(typeName);
  const elRef = useRef<HTMLDivElement>(null);
  const [bottomYPositionOfThreeDots, setBottomYPositionOfThreeDots] = useState(0);
  const [showOptionsUp, setShowOptionsUp] = useState(false);

  useLayoutEffect(() => {
    if (elRef.current) {
      setBottomYPositionOfThreeDots(
        elRef.current.getBoundingClientRect().y + elRef.current.getBoundingClientRect().height
      );

      if (
        calculatePopoverItemsNumber() * elRef.current.getBoundingClientRect().height + bottomYPositionOfThreeDots >
        document.documentElement.scrollHeight
      ) {
        setShowOptionsUp(true);
      }
    }
  }, [elRef, elRef.current]);

  function calculatePopoverItemsNumber() {
    let itemsNumber = 1;
    if (bundleDetails.isPatch) {
      itemsNumber += 1;
    }

    if (bundleDetails.cleanup) {
      itemsNumber += 1;
    }

    if (isAllowedToEditProducts() && productType === 'Regular') {
      if (bundleDetails.status === Status.ValidationFailed) {
        itemsNumber += 1;
      }
      itemsNumber += 1;
    }

    if (isAllowedToAddRemoveTesters()) {
      itemsNumber += 1;
    }

    if (isVersionManageAllowedRoles()) {
      itemsNumber += 1;
    }

    return itemsNumber;
  }

  const bundleLink = `${InternalUrls.MyProducts}/support/${watch(redAppIdName)}/${bundleVersion}/certify`;

  return (
    <tr>
      <TableCell>{bundleDetails?.version}</TableCell>
      <TableCell>{bundleDetails.submitted && format(new Date(bundleDetails.submitted), dateWithHour)}</TableCell>
      <TableCell>{bundleDetails.lastUpdated && format(new Date(bundleDetails.lastUpdated), dateWithHour)}</TableCell>
      <TableCell>
        <StatusIcon statusName={bundleDetails?.status} />
        {bundleDetails?.status}
      </TableCell>
      <TableCell>{bundleDetails.compatibility ?? <SpanWithFixedWidth />}</TableCell>
      <TableCell>
        <div ref={elRef}>
          <Popover
            anchorX={TooltipDirectionX.LEFT}
            anchorY={showOptionsUp ? TooltipDirectionY.TOP : TooltipDirectionY.BOTTOM}
            toggleEl={
              <button
                className="spark-btn--icon spark-icon spark-icon--fill spark-icon-menu-ellipsis-vertical"
                aria-label={formatMessage({id: 'accessibility.actionMenuLabel'})}
                type="button"
              />
            }
          >
            <ul className="spark-popover__list">
              {isVersionManageAllowedRoles() && (
                <li className="spark-popover__list-item">
                  <NoDecorationLink className="spark-popover__list-link spark-link" to={bundleLink}>
                    {formatMessage({id: 'common.manage'})}
                  </NoDecorationLink>
                </li>
              )}
              {isAllowedToEditProducts() &&
                productType === 'Regular' &&
                bundleDetails.status === Status.ValidationFailed && (
                  <PopoverListItem
                    handleOpen={handleOpen}
                    edit={setSvsErrors}
                    bundle={bundleDetails}
                    compatibilityFormId={compatibilityFormId}
                    messageId="tabs.bundle.svsErrors.openButton"
                  />
                )}
              {isAllowedToAddRemoveTesters() && (
                <PopoverListItem
                  handleOpen={handleOpen}
                  edit={setEditedBundle}
                  bundle={bundleDetails}
                  compatibilityFormId={compatibilityFormId}
                  messageId="tabs.bundle.compatibilityForm.openButton"
                  disabled={
                    bundleDetails.status === Status.BundleSubmitted || bundleDetails.status === Status.ValidationFailed
                  }
                />
              )}
              {bundleDetails.isPatch && (
                <PopoverListItem
                  handleOpen={handleOpen}
                  edit={setEditedReleaseNotes}
                  bundle={bundleDetails}
                  compatibilityFormId={compatibilityFormId}
                  messageId="tabs.bundle.compatibilityForm.releaseNotes"
                />
              )}
              <PopoverListItem
                handleOpen={handleOpen}
                edit={setEditedSimplifiedDescription}
                bundle={bundleDetails}
                compatibilityFormId={compatibilityFormId}
                messageId="tabs.bundle.simplifiedDescription.openButton"
              />
              {bundleDetails.cleanup && (
                <PopoverListItem
                  handleOpen={handleOpen}
                  edit={setEditedCleanupDates}
                  bundle={bundleDetails}
                  compatibilityFormId={compatibilityFormId}
                  messageId="tabs.bundle.cleanupDates.openButton"
                />
              )}
              {isAllowedToEditProducts() && productType === 'Regular' && (
                <PopoverListItem
                  handleOpen={handleOpen}
                  edit={setVersionFiles}
                  bundle={bundleDetails}
                  compatibilityFormId={compatibilityFormId}
                  messageId="tabs.bundle.versionFiles.openButton"
                />
              )}
            </ul>
          </Popover>
        </div>
      </TableCell>
    </tr>
  );
};

const SpanWithFixedWidth = styled.span`
  display: block;
  width: 32px;
`;

const NoDecorationLink = styled(Link)`
  text-decoration: none !important;
`;

export const TableCell = styled.td<{
  isMiddleColumn?: boolean;
}>`
  border: none !important;
  border-bottom: 1px solid ${colors.grey300} !important;
  background-image: none !important;
  min-width: ${props => (props.isMiddleColumn ? '270px !important;' : '')}

  tr:hover & {
    background-color: ${colors.grey100} !important;
  }

  & input {
    width: 50% !important;
  }
`;

export default BundleRow;
