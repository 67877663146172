/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptTermsAndConditionsRequest,
  BasicDetailsOfOnboardingRequest,
  CommentObject,
  CompanyInformation,
  ContactDetails,
  CreateSellerRequest,
  OnboardDeveloperPartnerRequest,
  OnboardingRequestResponse,
  OnboardingResponse,
  Proposal,
  RedAppPricingType,
  RejectOnboardingRequest,
  SabreAccountInfo,
} from '../models/index';
import {
    AcceptTermsAndConditionsRequestFromJSON,
    AcceptTermsAndConditionsRequestToJSON,
    BasicDetailsOfOnboardingRequestFromJSON,
    BasicDetailsOfOnboardingRequestToJSON,
    CommentObjectFromJSON,
    CommentObjectToJSON,
    CompanyInformationFromJSON,
    CompanyInformationToJSON,
    ContactDetailsFromJSON,
    ContactDetailsToJSON,
    CreateSellerRequestFromJSON,
    CreateSellerRequestToJSON,
    OnboardDeveloperPartnerRequestFromJSON,
    OnboardDeveloperPartnerRequestToJSON,
    OnboardingRequestResponseFromJSON,
    OnboardingRequestResponseToJSON,
    OnboardingResponseFromJSON,
    OnboardingResponseToJSON,
    ProposalFromJSON,
    ProposalToJSON,
    RedAppPricingTypeFromJSON,
    RedAppPricingTypeToJSON,
    RejectOnboardingRequestFromJSON,
    RejectOnboardingRequestToJSON,
    SabreAccountInfoFromJSON,
    SabreAccountInfoToJSON,
} from '../models/index';

export interface AcceptTermsAndConditionsOperationRequest {
    acceptTermsAndConditionsRequest?: AcceptTermsAndConditionsRequest;
}

export interface AddOnboardingCommentRequest {
    id: string;
    commentObject: CommentObject;
}

export interface AssignSellerRequest {
    id: string;
    sellerId: string;
}

export interface CreateSellerOperationRequest {
    id: string;
    createSellerRequest?: CreateSellerRequest;
}

export interface GetOnboardingRequestRequest {
    id: string;
}

export interface OnboardDeveloperPartnerOperationRequest {
    onboardDeveloperPartnerRequest?: OnboardDeveloperPartnerRequest;
}

export interface OnboardRedAppProviderRequest {
    contactDetails: ContactDetails;
    companyInformation: CompanyInformation;
    proposal: Proposal;
    sabreAccountInfo?: SabreAccountInfo;
    redAppPricingType?: RedAppPricingType | null;
    flowDiagram?: Blob;
}

export interface RejectOnboardingOperationRequest {
    id: string;
    rejectOnboardingRequest?: RejectOnboardingRequest;
}

/**
 * 
 */
export class OnboardingsApi extends runtime.BaseAPI {

    /**
     * Accept terms and conditions for onboarding
     */
    async acceptTermsAndConditionsRaw(requestParameters: AcceptTermsAndConditionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboardings/terms-and-conditions-acceptances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcceptTermsAndConditionsRequestToJSON(requestParameters.acceptTermsAndConditionsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Accept terms and conditions for onboarding
     */
    async acceptTermsAndConditions(requestParameters: AcceptTermsAndConditionsOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptTermsAndConditionsRaw(requestParameters, initOverrides);
    }

    /**
     * Add comment to onboarding by Sabre Admin or 2nd Level support
     */
    async addOnboardingCommentRaw(requestParameters: AddOnboardingCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addOnboardingComment.');
        }

        if (requestParameters.commentObject === null || requestParameters.commentObject === undefined) {
            throw new runtime.RequiredError('commentObject','Required parameter requestParameters.commentObject was null or undefined when calling addOnboardingComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboardings/{id}/comments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentObjectToJSON(requestParameters.commentObject),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add comment to onboarding by Sabre Admin or 2nd Level support
     */
    async addOnboardingComment(requestParameters: AddOnboardingCommentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addOnboardingCommentRaw(requestParameters, initOverrides);
    }

    /**
     * Assign seller in Salesforce
     */
    async assignSellerRaw(requestParameters: AssignSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling assignSeller.');
        }

        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling assignSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboardings/{id}/sellers/{sellerId}/assign`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign seller in Salesforce
     */
    async assignSeller(requestParameters: AssignSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignSellerRaw(requestParameters, initOverrides);
    }

    /**
     * Create seller in Salesforce
     */
    async createSellerRaw(requestParameters: CreateSellerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboardings/{id}/seller`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSellerRequestToJSON(requestParameters.createSellerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create seller in Salesforce
     */
    async createSeller(requestParameters: CreateSellerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createSellerRaw(requestParameters, initOverrides);
    }

    /**
     * Get basic data about existing onboarding requests
     */
    async getOnboardingBasicDetailsRequestsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BasicDetailsOfOnboardingRequest>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboardings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BasicDetailsOfOnboardingRequestFromJSON));
    }

    /**
     * Get basic data about existing onboarding requests
     */
    async getOnboardingBasicDetailsRequests(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BasicDetailsOfOnboardingRequest>> {
        const response = await this.getOnboardingBasicDetailsRequestsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get specific onboarding request document using id
     */
    async getOnboardingRequestRaw(requestParameters: GetOnboardingRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingRequestResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOnboardingRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboardings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingRequestResponseFromJSON(jsonValue));
    }

    /**
     * Get specific onboarding request document using id
     */
    async getOnboardingRequest(requestParameters: GetOnboardingRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingRequestResponse> {
        const response = await this.getOnboardingRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit full onboarding request for Developer Partner
     */
    async onboardDeveloperPartnerRaw(requestParameters: OnboardDeveloperPartnerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboardings/developer-partners`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardDeveloperPartnerRequestToJSON(requestParameters.onboardDeveloperPartnerRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit full onboarding request for Developer Partner
     */
    async onboardDeveloperPartner(requestParameters: OnboardDeveloperPartnerOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.onboardDeveloperPartnerRaw(requestParameters, initOverrides);
    }

    /**
     * Submit full onboarding request with proposal for Red App Provider
     */
    async onboardRedAppProviderRaw(requestParameters: OnboardRedAppProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardingResponse>> {
        if (requestParameters.contactDetails === null || requestParameters.contactDetails === undefined) {
            throw new runtime.RequiredError('contactDetails','Required parameter requestParameters.contactDetails was null or undefined when calling onboardRedAppProvider.');
        }

        if (requestParameters.companyInformation === null || requestParameters.companyInformation === undefined) {
            throw new runtime.RequiredError('companyInformation','Required parameter requestParameters.companyInformation was null or undefined when calling onboardRedAppProvider.');
        }

        if (requestParameters.proposal === null || requestParameters.proposal === undefined) {
            throw new runtime.RequiredError('proposal','Required parameter requestParameters.proposal was null or undefined when calling onboardRedAppProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.contactDetails !== undefined) {
            formParams.append('contactDetails', new Blob([JSON.stringify(ContactDetailsToJSON(requestParameters.contactDetails))], { type: "application/json", }));
                    }

        if (requestParameters.companyInformation !== undefined) {
            formParams.append('companyInformation', new Blob([JSON.stringify(CompanyInformationToJSON(requestParameters.companyInformation))], { type: "application/json", }));
                    }

        if (requestParameters.sabreAccountInfo !== undefined) {
            formParams.append('sabreAccountInfo', new Blob([JSON.stringify(SabreAccountInfoToJSON(requestParameters.sabreAccountInfo))], { type: "application/json", }));
                    }

        if (requestParameters.redAppPricingType !== undefined) {
            formParams.append('redAppPricingType', new Blob([JSON.stringify(RedAppPricingTypeToJSON(requestParameters.redAppPricingType))], { type: "application/json", }));
                    }

        if (requestParameters.proposal !== undefined) {
            formParams.append('proposal', new Blob([JSON.stringify(ProposalToJSON(requestParameters.proposal))], { type: "application/json", }));
                    }

        if (requestParameters.flowDiagram !== undefined) {
            formParams.append('flowDiagram', requestParameters.flowDiagram as any);
        }

        const response = await this.request({
            path: `/onboardings/red-app-providers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardingResponseFromJSON(jsonValue));
    }

    /**
     * Submit full onboarding request with proposal for Red App Provider
     */
    async onboardRedAppProvider(requestParameters: OnboardRedAppProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardingResponse> {
        const response = await this.onboardRedAppProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject onboarding
     */
    async rejectOnboardingRaw(requestParameters: RejectOnboardingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rejectOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboardings/{id}/statuses/rejected`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RejectOnboardingRequestToJSON(requestParameters.rejectOnboardingRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reject onboarding
     */
    async rejectOnboarding(requestParameters: RejectOnboardingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.rejectOnboardingRaw(requestParameters, initOverrides);
    }

}
