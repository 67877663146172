import {FormattedMessage, useIntl} from 'react-intl';
import React, {createContext, Dispatch, SetStateAction, useContext, useMemo, useState} from 'react';
import {CertBetaTestersContext, testerWithNumber} from '../../../provider/CertBetaTestersProvider';
import {testerWithNumber as prodTesterWithNumber} from '../../../provider/ProdBetaTestersProvider';
import {Configuration, ProductsApi, RemoveCertBetaTesterOperationRequest} from '../../../generated/certification';
import {certificationApiBaseLink} from '../../../assets/apiBaseLink';
import {getAccessToken, isAllowedToAddRemoveTesters} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {hrefStringCreator} from '@scm/components/messaging/HrefElement';
import {redAppSupportEmail} from '@scm/components/utils/common';
import {ToastType} from '@sabre/spark-react-core/types';
import useGetProductC from '../../../hooks/useGetProductC';
import {ErrorStatuses} from '../../../error/ErrorStatuses';
import styled from 'styled-components';
import {Button} from '@sabre/spark-react-core';
import Tooltip from '@sabre/spark-react-core/tooltip';
import {ButtonWithTooltipContainer, TooltipHandler} from '@scm/components/form/ButtonWithTooltip';
import {Pagination} from '@scm/components';
import TestersTable from '../../testers-table/TestersTable';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import parse from 'html-react-parser';

export const testersHeaders = ['pcc', 'sabre id', 'created', 'status'];

interface NewTesterContextValues {
  newTester: testerWithNumber | prodTesterWithNumber | null;
  setNewTester: Dispatch<SetStateAction<testerWithNumber | prodTesterWithNumber | null>>;
}

export const NewTesterContext = createContext<NewTesterContextValues>({} as unknown as NewTesterContextValues);

const CertTesters = () => {
  const {formatMessage} = useIntl();
  const paginationInterval = 10;
  const [currentElement, setCurrentElement] = useState(0);
  const productDetailsName = 'productDetails.fetch';
  const allowedNumOfTesters = 50;

  const {certBetaTesters, setLoading} = useContext(CertBetaTestersContext);
  const {redAppIdC} = useGetProductC();
  const disableAddTesterBtn = certBetaTesters.length >= allowedNumOfTesters;

  async function removeCertTester(removeCertBetaTesterOperationRequest: RemoveCertBetaTesterOperationRequest) {
    return await new ProductsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).removeCertBetaTester(removeCertBetaTesterOperationRequest);
  }

  const paginationClickHandler = (evt: React.MouseEvent<HTMLAnchorElement>, moveIndex?: number) => {
    if (moveIndex === undefined) {
      setCurrentElement(
        +(
          evt.currentTarget.firstChild as unknown as {
            textContent: string;
          }
        )?.textContent - 1
      );
    } else {
      setCurrentElement(currentElement + moveIndex);
    }
  };
  const deleteTestersHandler = async (nr: string) => {
    const testerToDelete = certBetaTesters.find(tester => tester.nr === nr);
    const removeCertBetaTesterOperationRequest: RemoveCertBetaTesterOperationRequest = {
      id: redAppIdC || '',
      removeCertBetaTesterRequest: {
        betaTester: {
          pcc: testerToDelete?.pcc || '',
          epr: testerToDelete?.epr || '',
        },
      },
    };

    try {
      await removeCertTester(removeCertBetaTesterOperationRequest);
    } catch (err) {
      err.response.json().catch(() => {});
      if (err.response.status === ErrorStatuses['Unprocessable Content']) {
        openToast(
          createMessageString(formatMessage, productDetailsName, true, true),
          formatMessage(
            {id: 'productDetails.fetch.testerListUpdateFailed.description'},
            {
              email: hrefStringCreator(redAppSupportEmail),
            }
          ),
          ToastType.WARNING,
          'spark-icon-alert-triangle',
          true
        );
      }
    } finally {
      setLoading(true);
    }
  };

  const [newTester, setNewTester] = useState<testerWithNumber | prodTesterWithNumber | null>(null);

  const contextValue = useMemo(
    () => ({
      newTester,
      setNewTester,
    }),
    [newTester]
  );

  return (
    <NewTesterContext.Provider value={contextValue}>
      <p className="spark-mar-r-2">{parse(formatMessage({id: 'tabs.certtesters.description'}))}</p>
      <TestersTable
        headers={testersHeaders}
        deleteTestersHandler={deleteTestersHandler}
        currentPaginationPage={currentElement}
        isProdTestersTable={false}
        sortableHeaders={['pcc', 'created']}
        paginationInterval={paginationInterval}
      />
      {isAllowedToAddRemoveTesters() && (
        <TableUtils className="row spark-mar-b-1">
          {!newTester ? (
            disableAddTesterBtn ? (
              <ButtonWithTooltipContainer>
                <Tooltip
                  toggleEl={
                    <TooltipHandler>
                      <Button textOnly className="spark-mar-l-2 spark-mar-t-1 spark-pad-l-1" disabled>
                        <FormattedMessage id="tabs.testers.addButton.label" />
                      </Button>
                    </TooltipHandler>
                  }
                >
                  <FormattedMessage id={'tabs.testers.addButton.tooltip'} />
                </Tooltip>
              </ButtonWithTooltipContainer>
            ) : (
              <Button
                textOnly
                onClick={() => {
                  setNewTester({
                    nr: String(certBetaTesters.length + 1),
                    pcc: '',
                    epr: '',
                    creationDateTime: new Date(),
                  });
                }}
                className="spark-mar-l-2 spark-mar-t-1 spark-pad-l-1"
              >
                {formatMessage({id: 'tabs.testers.addButton.label'})}
              </Button>
            )
          ) : (
            <div />
          )}
          {certBetaTesters.length > paginationInterval && (
            <Pagination
              allDataLength={certBetaTesters.length}
              currentElement={currentElement}
              paginationInterval={paginationInterval}
              clickHandler={paginationClickHandler}
            />
          )}
        </TableUtils>
      )}
    </NewTesterContext.Provider>
  );
};

const TableUtils = styled.div`
  align-items: center;
  justify-content: space-between;
`;

export default CertTesters;
