/* tslint:disable */
/* eslint-disable */
/**
 * Company Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AssignSmallResponse,
  ErrorResponse,
  NotificationSettings,
  PccEpr,
  Product,
  SellerDeveloper,
} from '../models';
import {
    AssignSmallResponseFromJSON,
    AssignSmallResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NotificationSettingsFromJSON,
    NotificationSettingsToJSON,
    PccEprFromJSON,
    PccEprToJSON,
    ProductFromJSON,
    ProductToJSON,
    SellerDeveloperFromJSON,
    SellerDeveloperToJSON,
} from '../models';

export interface AddDeveloperToProductRequest {
    sku: string;
    pccEpr: PccEpr;
}

export interface AddDeveloperToSellerRequest {
    id: string;
    sellerDeveloper: SellerDeveloper;
}

export interface DeleteDeveloperFromProductRequest {
    sku: string;
    pccEpr: PccEpr;
}

export interface DeleteDeveloperFromSellerRequest {
    id: string;
    pccEpr: PccEpr;
}

export interface GetDevelopersForSellerRequest {
    id: string;
}

export interface GetNotificationSettingsOfSellerRequest {
    id: string;
}

export interface GetProductsForSellerRequest {
    id: string;
}

export interface UpdateDeveloperForSellerRequest {
    id: string;
    sellerDeveloper: SellerDeveloper;
}

export interface UpdateNotificationSettingsOfSellerRequest {
    id: string;
    notificationSettings: NotificationSettings;
}

/**
 * 
 */
export class CompanyManagementApi extends runtime.BaseAPI {

    /**
     * Add a new developer for a specific product
     */
    async addDeveloperToProductRaw(requestParameters: AddDeveloperToProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssignSmallResponse>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling addDeveloperToProduct.');
        }

        if (requestParameters.pccEpr === null || requestParameters.pccEpr === undefined) {
            throw new runtime.RequiredError('pccEpr','Required parameter requestParameters.pccEpr was null or undefined when calling addDeveloperToProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/developers`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PccEprToJSON(requestParameters.pccEpr),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignSmallResponseFromJSON(jsonValue));
    }

    /**
     * Add a new developer for a specific product
     */
    async addDeveloperToProduct(requestParameters: AddDeveloperToProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssignSmallResponse> {
        const response = await this.addDeveloperToProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a new developer for a specific seller
     */
    async addDeveloperToSellerRaw(requestParameters: AddDeveloperToSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AssignSmallResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addDeveloperToSeller.');
        }

        if (requestParameters.sellerDeveloper === null || requestParameters.sellerDeveloper === undefined) {
            throw new runtime.RequiredError('sellerDeveloper','Required parameter requestParameters.sellerDeveloper was null or undefined when calling addDeveloperToSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/{id}/developers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SellerDeveloperToJSON(requestParameters.sellerDeveloper),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignSmallResponseFromJSON(jsonValue));
    }

    /**
     * Add a new developer for a specific seller
     */
    async addDeveloperToSeller(requestParameters: AddDeveloperToSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AssignSmallResponse> {
        const response = await this.addDeveloperToSellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a developer for a specific product
     */
    async deleteDeveloperFromProductRaw(requestParameters: DeleteDeveloperFromProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling deleteDeveloperFromProduct.');
        }

        if (requestParameters.pccEpr === null || requestParameters.pccEpr === undefined) {
            throw new runtime.RequiredError('pccEpr','Required parameter requestParameters.pccEpr was null or undefined when calling deleteDeveloperFromProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{sku}/developers`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: PccEprToJSON(requestParameters.pccEpr),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a developer for a specific product
     */
    async deleteDeveloperFromProduct(requestParameters: DeleteDeveloperFromProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeveloperFromProductRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a developer for a specific seller
     */
    async deleteDeveloperFromSellerRaw(requestParameters: DeleteDeveloperFromSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeveloperFromSeller.');
        }

        if (requestParameters.pccEpr === null || requestParameters.pccEpr === undefined) {
            throw new runtime.RequiredError('pccEpr','Required parameter requestParameters.pccEpr was null or undefined when calling deleteDeveloperFromSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/{id}/developers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: PccEprToJSON(requestParameters.pccEpr),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a developer for a specific seller
     */
    async deleteDeveloperFromSeller(requestParameters: DeleteDeveloperFromSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDeveloperFromSellerRaw(requestParameters, initOverrides);
    }

    /**
     * Get a list of developers for a specific seller
     */
    async getDevelopersForSellerRaw(requestParameters: GetDevelopersForSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SellerDeveloper>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDevelopersForSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/{id}/developers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SellerDeveloperFromJSON));
    }

    /**
     * Get a list of developers for a specific seller
     */
    async getDevelopersForSeller(requestParameters: GetDevelopersForSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SellerDeveloper>> {
        const response = await this.getDevelopersForSellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of notification settings for a specific seller
     */
    async getNotificationSettingsOfSellerRaw(requestParameters: GetNotificationSettingsOfSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotificationSettings>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNotificationSettingsOfSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/{id}/notification-settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationSettingsFromJSON));
    }

    /**
     * Get a list of notification settings for a specific seller
     */
    async getNotificationSettingsOfSeller(requestParameters: GetNotificationSettingsOfSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotificationSettings>> {
        const response = await this.getNotificationSettingsOfSellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of products for a specific seller
     */
    async getProductsForSellerRaw(requestParameters: GetProductsForSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Product>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProductsForSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/{id}/products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductFromJSON));
    }

    /**
     * Get a list of products for a specific seller
     */
    async getProductsForSeller(requestParameters: GetProductsForSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Product>> {
        const response = await this.getProductsForSellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a developer info for a specific seller
     */
    async updateDeveloperForSellerRaw(requestParameters: UpdateDeveloperForSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDeveloperForSeller.');
        }

        if (requestParameters.sellerDeveloper === null || requestParameters.sellerDeveloper === undefined) {
            throw new runtime.RequiredError('sellerDeveloper','Required parameter requestParameters.sellerDeveloper was null or undefined when calling updateDeveloperForSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/{id}/developers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SellerDeveloperToJSON(requestParameters.sellerDeveloper),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a developer info for a specific seller
     */
    async updateDeveloperForSeller(requestParameters: UpdateDeveloperForSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateDeveloperForSellerRaw(requestParameters, initOverrides);
    }

    /**
     * Updates notification settings for a specific seller or creates (if doesn\'t exist) seller with notification settings
     */
    async updateNotificationSettingsOfSellerRaw(requestParameters: UpdateNotificationSettingsOfSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNotificationSettingsOfSeller.');
        }

        if (requestParameters.notificationSettings === null || requestParameters.notificationSettings === undefined) {
            throw new runtime.RequiredError('notificationSettings','Required parameter requestParameters.notificationSettings was null or undefined when calling updateNotificationSettingsOfSeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sellers/{id}/notification-settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationSettingsToJSON(requestParameters.notificationSettings),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates notification settings for a specific seller or creates (if doesn\'t exist) seller with notification settings
     */
    async updateNotificationSettingsOfSeller(requestParameters: UpdateNotificationSettingsOfSellerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNotificationSettingsOfSellerRaw(requestParameters, initOverrides);
    }

}
