import {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {resources} from './resourcesConfig';
import {HeaderText} from '../../components/App';
import {ExternalLink} from '@scm/components';
import gaEvent from '../../googleAnalytics/googleAnalyticsEvent';

const Resources = memo(() => {
  const handleResourceView = () => {
    gaEvent('Viewed a Resource');
  };

  return (
    <>
      <Header className="spark-mar-b-2 spark-pad-0">
        <HeaderText className="spark-mar-2 spark-mar-b-1 spark-mar-l-0">
          <FormattedMessage id="resources.title" />
        </HeaderText>
        <h6 className="spark-mar-l-0 spark-mar-r-2" aria-level={2}>
          <FormattedMessage id="resources.subHeader" />
        </h6>
      </Header>
      <ResourceSection className="spark-mar-b-0">
        <ImageContainer>
          <img src="https://storage.googleapis.com/sab-dev-scm-main-9977-static/media/resources/main.webp" alt="" />
        </ImageContainer>
        <Description className="center main">
          <CardTitle className="spark-mar-0 spark-pad-0 spark-mar-b-1">
            <FormattedMessage id="resources.resource.referenceGuide" />
          </CardTitle>
          <RedAppResourceH2 aria-level={3}>
            <FormattedMessage id="resources.resource.referenceGuide.title" />
          </RedAppResourceH2>
          <p>
            <FormattedMessage id="resources.resource.referenceGuide.description" />
          </p>
          <p className="spark-text-right">
            <MainLink
              to="https://static.marketplace.sabre.com/documents/QuickReferenceGuide-SabreCentralMarketplaceAdminCentre.pdf"
              className="spark-btn spark-btn--sm spark-btn--secondary spark-mar-b-1 spark-mar-r-1"
              onClick={handleResourceView}
            >
              <FormattedMessage id="resources.main.link" />
            </MainLink>
          </p>
        </Description>
      </ResourceSection>
      <OtherHeader className="spark-mar-t-1 spark-pad-l-0 spark-mar-b-1" aria-level={2}>
        <FormattedMessage id="resources.other" />
      </OtherHeader>
      <ResourceSection isSpaceBetween className="spark-pad-1 spark-pad-l-0 spark-pad-r-0">
        {resources.map(({name, title, image, url}) => (
          <Card
            className="spark-pad-b-1 spark-mar-b-2 spark-mar-r-.5"
            key={title}
            to={url}
            aria-label={title}
            onClick={handleResourceView}
          >
            <CardImage>
              <img src={image} alt={name} />
            </CardImage>
            <Description>
              <CardTitle className="spark-mar-t-2 spark-mar-b-.5 spark-pad-0 spark-link">{title}</CardTitle>
              <RedAppResourceH3>
                <FormattedMessage id={`resources.resource.${name}.title`} />
              </RedAppResourceH3>
              <p className="spark-pad-b-2">
                <FormattedMessage id={`resources.resource.${name}.description`} />
              </p>
            </Description>
          </Card>
        ))}
      </ResourceSection>
    </>
  );
});

const horizontalCenter = `
  margin: 0 2rem;
`;

const mainColor = colors.sparkBlue;
const buttonColor = colors.sparkGreen;
const smallDevicesBreakpoint = 1200;

const MainLink = styled(ExternalLink)`
  border: 1px solid ${buttonColor} !important;
  box-shadow: inset 0 -2px 0 ${buttonColor} !important;
  color: ${buttonColor} !important;

  &:is(:hover, :focus) {
    text-decoration: underline;
  }
`;

const CardTitle = styled.p`
  color: ${mainColor} !important;
  font-weight: 400;
  @media (max-width: ${smallDevicesBreakpoint}px) {
    padding-top: 1rem !important;
  }
`;

const ResourceSection = styled.section<{isSpaceBetween?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  ${horizontalCenter};
  ${props => (props.isSpaceBetween ? 'gap: 0.6rem' : '')};

  & div.main {
    background-color: ${colors.white};
    border-radius: 0 0.5rem 0.5rem 0;
    @media (max-width: ${smallDevicesBreakpoint}px) {
      border-radius: 0 0 0.5rem 0.5rem;
    }
    transform: translateY(-0.3rem) scaleY(0.985);
  }
`;

const ImageContainer = styled.div`
  flex-basis: 50%;
  @media (max-width: ${smallDevicesBreakpoint}px) {
    flex-basis: 100%;
    background-color: ${colors.white};
  }

  & img {
    width: 100%;
    aspect-ratio: 14 / 9;
    border-radius: 0.5rem 0 0 0.5rem;
    @media (max-width: ${smallDevicesBreakpoint}px) {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }
`;

const Description = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 2rem;

  &.center {
    justify-content: center;
  }
`;

const Card = styled(ExternalLink)`
  display: flex;
  flex-direction: column;
  flex-basis: 24%;
  transition: 0.2s;

  &:is(:focus, :hover) {
    transform: translateY(-0.7rem);
  }

  @media (max-width: ${smallDevicesBreakpoint}px) {
    flex-basis: 48%;
    &:is(:focus, :hover) {
      transform: none;
    }
  }

  & p {
    color: ${colors.grey900};
  }

  background-color: ${colors.white};
`;

const CardImage = styled.div`
  flex-basis: auto;
  flex-grow: 0;

  & img {
    width: 100%;
    aspect-ratio: 14 / 9;
  }
`;

const RedAppResourceH2 = styled.h2`
  font-weight: 400;
`;

const RedAppResourceH3 = styled.h3`
  font-weight: 400;
`;

const OtherHeader = styled.h3`
  ${horizontalCenter};
  font-weight: 400;
`;

const Header = styled.header`
  ${horizontalCenter}
`;

export default Resources;
