/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BetaTester } from './BetaTester';
import {
    BetaTesterFromJSON,
    BetaTesterFromJSONTyped,
    BetaTesterToJSON,
} from './BetaTester';
import type { FileSource } from './FileSource';
import {
    FileSourceFromJSON,
    FileSourceFromJSONTyped,
    FileSourceToJSON,
} from './FileSource';
import type { NameConvention } from './NameConvention';
import {
    NameConventionFromJSON,
    NameConventionFromJSONTyped,
    NameConventionToJSON,
} from './NameConvention';

/**
 * 
 * @export
 * @interface PfKeys
 */
export interface PfKeys {
    /**
     * 
     * @type {string}
     * @memberof PfKeys
     */
    name: string;
    /**
     * 
     * @type {Array<BetaTester>}
     * @memberof PfKeys
     */
    betaTesters?: Array<BetaTester>;
    /**
     * 
     * @type {FileSource}
     * @memberof PfKeys
     */
    fileSource: FileSource;
    /**
     * 
     * @type {NameConvention}
     * @memberof PfKeys
     */
    nameConvention?: NameConvention;
}

/**
 * Check if a given object implements the PfKeys interface.
 */
export function instanceOfPfKeys(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "fileSource" in value;

    return isInstance;
}

export function PfKeysFromJSON(json: any): PfKeys {
    return PfKeysFromJSONTyped(json, false);
}

export function PfKeysFromJSONTyped(json: any, ignoreDiscriminator: boolean): PfKeys {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'betaTesters': !exists(json, 'betaTesters') ? undefined : ((json['betaTesters'] as Array<any>).map(BetaTesterFromJSON)),
        'fileSource': FileSourceFromJSON(json['fileSource']),
        'nameConvention': !exists(json, 'nameConvention') ? undefined : NameConventionFromJSON(json['nameConvention']),
    };
}

export function PfKeysToJSON(value?: PfKeys | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'betaTesters': value.betaTesters === undefined ? undefined : ((value.betaTesters as Array<any>).map(BetaTesterToJSON)),
        'fileSource': FileSourceToJSON(value.fileSource),
        'nameConvention': NameConventionToJSON(value.nameConvention),
    };
}

