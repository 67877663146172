import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import color from '@scm/components/assets/colors';
import RequestSunsetModal from '@scm/my-products/components/products/request-sunset-modal/RequestSunsetModal';
import {useParams} from 'react-router-dom';
import {CcrzEProductCSObjectCcrzProductStatusCEnum} from '../../../../generated/product';
import useGetProductC from '../../../../hooks/useGetProductC';

const Sunset = () => {
  const {ccrzProductStatusC, redAppTypeC} = useGetProductC();
  const handleRequestSunsetModalOpen = (): void => setRequestSunsetModalOpen(true);
  const [requestSunsetModalOpen, setRequestSunsetModalOpen] = useState(false);
  const skuFromUrl = useParams().appName ?? '';
  const isRedApp = redAppTypeC !== undefined;

  return ccrzProductStatusC === CcrzEProductCSObjectCcrzProductStatusCEnum.Released && isRedApp ? (
    <>
      <p className="spark-mar-b-1 spark-caps spark-mar-t-2">
        <FormattedMessage id="requestSunset.product.sunset.title" />
      </p>
      <Description className="spark-mar-b-1">
        <FormattedMessage id="requestSunset.product.sunset.description" />
      </Description>
      <StyledButton
        secondary
        size={ButtonSize.SMALL}
        onClick={handleRequestSunsetModalOpen}
        className="spark-mar-r-1"
        data-testid="requestSunsetButton"
      >
        <FormattedMessage id="requestSunset.product.sunset.button" />
      </StyledButton>
      <RequestSunsetModal open={requestSunsetModalOpen} setOpen={setRequestSunsetModalOpen} sku={skuFromUrl} />
    </>
  ) : null;
};
export const Description = styled.p`
  margin-bottom: 0.5rem;
`;

export const StyledButton = styled(Button)`
  color: ${color.red} !important;
  box-shadow: inset 0 -2px 0 ${color.red} !important;
  border: 1px solid ${color.red} !important;
`;

export default Sunset;
