import React, {ChangeEvent, useEffect, useRef} from 'react';
import SparkToggleSwitch from '@sabre/spark/js/src/components/toggle-switch';
import styled from 'styled-components';

const ToggleSwitch = ({
  name,
  values,
  labels,
  onChange,
  disabled,
  title,
}: {
  name: string;
  values: Array<string>;
  labels: Array<string | JSX.Element | JSX.Element[]>;
  onChange?: (event: ChangeEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  title?: string;
}) => {
  const toggleSwitchRef = useRef(null);

  useEffect(() => {
    const sparkAccordion = new SparkToggleSwitch(toggleSwitchRef.current);
    return () => {
      sparkAccordion.remove();
    };
  }, [toggleSwitchRef]);

  return (
    <div className="row spark-toggle-group spark-mar-b-2" ref={toggleSwitchRef} onChange={onChange}>
      {labels.map((item, index) => (
        <SparkLabel className=" spark-toggle" key={index}>
          <input
            className="spark-toggle__input"
            name={name}
            type="radio"
            title={title}
            value={values[index]}
            disabled={disabled}
            defaultChecked={index === 0}
          />
          <span className="spark-label">{labels[index]}</span>
        </SparkLabel>
      ))}
    </div>
  );
};

const SparkLabel = styled.label`
  width: 15rem;
`;

export default ToggleSwitch;
