/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnboardingResponse
 */
export interface OnboardingResponse {
    /**
     * UUID of created proposal document
     * @type {string}
     * @memberof OnboardingResponse
     */
    proposalId?: string;
    /**
     * UUID of created onboarding document
     * @type {string}
     * @memberof OnboardingResponse
     */
    onboardingId?: string;
}

/**
 * Check if a given object implements the OnboardingResponse interface.
 */
export function instanceOfOnboardingResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OnboardingResponseFromJSON(json: any): OnboardingResponse {
    return OnboardingResponseFromJSONTyped(json, false);
}

export function OnboardingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'proposalId': !exists(json, 'proposalId') ? undefined : json['proposalId'],
        'onboardingId': !exists(json, 'onboardingId') ? undefined : json['onboardingId'],
    };
}

export function OnboardingResponseToJSON(value?: OnboardingResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'proposalId': value.proposalId,
        'onboardingId': value.onboardingId,
    };
}

