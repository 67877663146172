const footer = {
  'footer.privacyPolicy': 'Privacy policy',
  'footer.sabre': 'Sabre Website',
  'footer.twitter': 'Twitter',
  'footer.facebook': 'Facebook',
  'footer.linkedIn': 'LinkedIn',
  'footer.youtube': 'Youtube',
  'footer.termsAndConditions': 'Terms & Conditions',
};

export default footer;
