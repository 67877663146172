import {isDevEnv} from './env';

declare const __CONFIG__: Config;

export interface Config {
  salesforceClient: {
    url: string;
    logoutUrl: string;
  };
  googleAnalyticsAc?: {
    id: string;
  };
  marketplace: {
    url: string;
  };
  ignoreSso: boolean;
  mockSso: boolean;
  envName: string;
}

export const devConfig = {
  salesforceClient: {
    url:
      'https://sabre--cert.sandbox.my.site.com/marketplace/services/oauth2/authorize?client_id=' +
      '3MVG9MX2WYUwuVwN413zXvBy1RcXJFMALxTLGslRy_qJOdPhr6dKCQ8lHyKASkMEHvAhmwFHU5p2qInmdZgQv',
    logoutUrl: 'https://sabre--cert.sandbox.my.site.com/marketplace/secur/logout.jsp',
  },
  googleAnalyticsAc: {
    id: '',
  },
  marketplace: {
    url: 'https://sabre--b2bdev6.sandbox.my.site.com',
  },
  ignoreSso: true,
  mockSso: false,
  envName: 'local',
};

export const config: Config = isDevEnv ? devConfig : __CONFIG__;
