/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CcrzEPageLabelCSObject } from './CcrzEPageLabelCSObject';
import {
    CcrzEPageLabelCSObjectFromJSON,
    CcrzEPageLabelCSObjectFromJSONTyped,
    CcrzEPageLabelCSObjectToJSON,
} from './CcrzEPageLabelCSObject';

/**
 * 
 * @export
 * @interface CcrzEProductCSObject
 */
export interface CcrzEProductCSObject {
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    ccrzSKUC?: string;
    /**
     * Identifier for the custom object record. This name appears in page layouts, related lists, lookup dialogs, search results, and key lists on tab home pages. By default, this field is added to the custom object page layout as a required field.
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    name?: string;
    /**
     * 
     * @type {CcrzEPageLabelCSObject}
     * @memberof CcrzEProductCSObject
     */
    descriptionR?: CcrzEPageLabelCSObject;
    /**
     * 
     * @type {boolean}
     * @memberof CcrzEProductCSObject
     */
    blockOrderingC?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CcrzEProductCSObject
     */
    blockOrderingOnScCodeLevelC?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CcrzEProductCSObject
     */
    sabreHideProductC?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    ccrzProductStatusC?: CcrzEProductCSObjectCcrzProductStatusCEnum;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    sabrePricingTypeC?: CcrzEProductCSObjectSabrePricingTypeCEnum;
    /**
     * 
     * @type {CcrzEPageLabelCSObject}
     * @memberof CcrzEProductCSObject
     */
    descriptionWhenOrderingBlockedR?: CcrzEPageLabelCSObject;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    orderingAvailableInCountriesC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    orderingAvailableInRegionsC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    orderingBlockedInCountriesC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    providerMessageC?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CcrzEProductCSObject
     */
    sellerConfirmationRequiredToOrderC?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    redAppIdC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    redAppTypeC?: CcrzEProductCSObjectRedAppTypeCEnum;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    provisioningTypeC?: CcrzEProductCSObjectProvisioningTypeCEnum;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    applicationPurchaseContactC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    developerC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    logoURIC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    quickReferenceGuideC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductCSObject
     */
    ccrzShortDescRTC?: string;
    /**
     * 
     * @type {Date}
     * @memberof CcrzEProductCSObject
     */
    createdDate?: Date;
}


/**
 * @export
 */
export const CcrzEProductCSObjectCcrzProductStatusCEnum = {
    Blocked: 'Blocked',
    Released: 'Released',
    InCreation: 'In Creation',
    Obsolete: 'Obsolete'
} as const;
export type CcrzEProductCSObjectCcrzProductStatusCEnum = typeof CcrzEProductCSObjectCcrzProductStatusCEnum[keyof typeof CcrzEProductCSObjectCcrzProductStatusCEnum];

/**
 * @export
 */
export const CcrzEProductCSObjectSabrePricingTypeCEnum = {
    Free: 'Free',
    Paid: 'Paid'
} as const;
export type CcrzEProductCSObjectSabrePricingTypeCEnum = typeof CcrzEProductCSObjectSabrePricingTypeCEnum[keyof typeof CcrzEProductCSObjectSabrePricingTypeCEnum];

/**
 * @export
 */
export const CcrzEProductCSObjectRedAppTypeCEnum = {
    Regular: 'Regular',
    PfKeys: 'PfKeys',
    Scribe: 'Scribe'
} as const;
export type CcrzEProductCSObjectRedAppTypeCEnum = typeof CcrzEProductCSObjectRedAppTypeCEnum[keyof typeof CcrzEProductCSObjectRedAppTypeCEnum];

/**
 * @export
 */
export const CcrzEProductCSObjectProvisioningTypeCEnum = {
    Public: 'Public',
    Private: 'Private'
} as const;
export type CcrzEProductCSObjectProvisioningTypeCEnum = typeof CcrzEProductCSObjectProvisioningTypeCEnum[keyof typeof CcrzEProductCSObjectProvisioningTypeCEnum];


/**
 * Check if a given object implements the CcrzEProductCSObject interface.
 */
export function instanceOfCcrzEProductCSObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CcrzEProductCSObjectFromJSON(json: any): CcrzEProductCSObject {
    return CcrzEProductCSObjectFromJSONTyped(json, false);
}

export function CcrzEProductCSObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CcrzEProductCSObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'ccrzSKUC': !exists(json, 'ccrz__SKU__c') ? undefined : json['ccrz__SKU__c'],
        'name': !exists(json, 'Name') ? undefined : json['Name'],
        'descriptionR': !exists(json, 'Description__r') ? undefined : CcrzEPageLabelCSObjectFromJSON(json['Description__r']),
        'blockOrderingC': !exists(json, 'Block_Ordering__c') ? undefined : json['Block_Ordering__c'],
        'blockOrderingOnScCodeLevelC': !exists(json, 'Block_Ordering_On_ScCode_Level__c') ? undefined : json['Block_Ordering_On_ScCode_Level__c'],
        'sabreHideProductC': !exists(json, 'Sabre_Hide_Product__c') ? undefined : json['Sabre_Hide_Product__c'],
        'ccrzProductStatusC': !exists(json, 'ccrz__ProductStatus__c') ? undefined : json['ccrz__ProductStatus__c'],
        'sabrePricingTypeC': !exists(json, 'Sabre_Pricing_Type__c') ? undefined : json['Sabre_Pricing_Type__c'],
        'descriptionWhenOrderingBlockedR': !exists(json, 'Description_When_Ordering_Blocked__r') ? undefined : CcrzEPageLabelCSObjectFromJSON(json['Description_When_Ordering_Blocked__r']),
        'orderingAvailableInCountriesC': !exists(json, 'Ordering_Available_In_Countries__c') ? undefined : json['Ordering_Available_In_Countries__c'],
        'orderingAvailableInRegionsC': !exists(json, 'Ordering_Available_In_Regions__c') ? undefined : json['Ordering_Available_In_Regions__c'],
        'orderingBlockedInCountriesC': !exists(json, 'Ordering_Blocked_In_Countries__c') ? undefined : json['Ordering_Blocked_In_Countries__c'],
        'providerMessageC': !exists(json, 'Provider_Message__c') ? undefined : json['Provider_Message__c'],
        'sellerConfirmationRequiredToOrderC': !exists(json, 'Seller_Confirmation_Required_To_Order__c') ? undefined : json['Seller_Confirmation_Required_To_Order__c'],
        'redAppIdC': !exists(json, 'Red_App_Id__c') ? undefined : json['Red_App_Id__c'],
        'redAppTypeC': !exists(json, 'Red_App_Type__c') ? undefined : json['Red_App_Type__c'],
        'provisioningTypeC': !exists(json, 'Provisioning_Type__c') ? undefined : json['Provisioning_Type__c'],
        'applicationPurchaseContactC': !exists(json, 'Application_Purchase_Contact__c') ? undefined : json['Application_Purchase_Contact__c'],
        'developerC': !exists(json, 'Developer__c') ? undefined : json['Developer__c'],
        'logoURIC': !exists(json, 'Logo_URI__c') ? undefined : json['Logo_URI__c'],
        'quickReferenceGuideC': !exists(json, 'Quick_Reference_Guide__c') ? undefined : json['Quick_Reference_Guide__c'],
        'ccrzShortDescRTC': !exists(json, 'ccrz__ShortDescRT__c') ? undefined : json['ccrz__ShortDescRT__c'],
        'createdDate': !exists(json, 'CreatedDate') ? undefined : (new Date(json['CreatedDate'])),
    };
}

export function CcrzEProductCSObjectToJSON(value?: CcrzEProductCSObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ccrz__SKU__c': value.ccrzSKUC,
        'Name': value.name,
        'Description__r': CcrzEPageLabelCSObjectToJSON(value.descriptionR),
        'Block_Ordering__c': value.blockOrderingC,
        'Block_Ordering_On_ScCode_Level__c': value.blockOrderingOnScCodeLevelC,
        'Sabre_Hide_Product__c': value.sabreHideProductC,
        'ccrz__ProductStatus__c': value.ccrzProductStatusC,
        'Sabre_Pricing_Type__c': value.sabrePricingTypeC,
        'Description_When_Ordering_Blocked__r': CcrzEPageLabelCSObjectToJSON(value.descriptionWhenOrderingBlockedR),
        'Ordering_Available_In_Countries__c': value.orderingAvailableInCountriesC,
        'Ordering_Available_In_Regions__c': value.orderingAvailableInRegionsC,
        'Ordering_Blocked_In_Countries__c': value.orderingBlockedInCountriesC,
        'Provider_Message__c': value.providerMessageC,
        'Seller_Confirmation_Required_To_Order__c': value.sellerConfirmationRequiredToOrderC,
        'Red_App_Id__c': value.redAppIdC,
        'Red_App_Type__c': value.redAppTypeC,
        'Provisioning_Type__c': value.provisioningTypeC,
        'Application_Purchase_Contact__c': value.applicationPurchaseContactC,
        'Developer__c': value.developerC,
        'Logo_URI__c': value.logoURIC,
        'Quick_Reference_Guide__c': value.quickReferenceGuideC,
        'ccrz__ShortDescRT__c': value.ccrzShortDescRTC,
        'CreatedDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
    };
}

