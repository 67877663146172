import {FormattedMessage, useIntl} from 'react-intl';
import Message, {MessageType} from 'spark/Message';
import React, {useState} from 'react';
import EventCategories from 'googleAnalytics/EventCategories';
import styled from 'styled-components';
import logEvent from 'googleAnalytics/logEvent';
import {useMediaQuery} from 'react-responsive';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const ContactUs = ({email, name}: {email?: string | null; name?: string | null}) => {
  const {formatMessage} = useIntl();
  const [message, setMessage] = useState(false);
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  const subject = formatMessage({id: 'partnerContact.email.subject'}, {name});
  const splitEmail = email && email.split(',');
  const partnerEmail = splitEmail ? splitEmail[0] : '';

  const onClick = () => {
    logEvent({
      category: EventCategories.PartnerDetails,
      action: 'Clicked Contact Us',
      label: name!!,
    });

    setTimeout(() => {
      setMessage(true);
    }, 2000);
  };

  return (
    <ContactUsContainer>
      <ContactUsButton
        mobile={isMobile}
        onClick={onClick}
        href={`mailto:${partnerEmail}?subject=${subject}`}
        className="spark-btn spark-mar-b-1"
      >
        <FormattedMessage id="partnerPage.description.contactUs" />
      </ContactUsButton>
      {message && (
        <ContactUsMessage
          mobile={isMobile}
          type={MessageType.info}
          size="spark-message--sm spark-pad-1"
          message={formatMessage({id: 'partnerPage.description.contactUsMessage'})}
        />
      )}
    </ContactUsContainer>
  );
};

const ContactUsContainer = styled.div.attrs(() => ({
  className: 'spark-flex spark-align-items-center spark-mar-t-2',
}))<{mobile?: boolean}>`
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 9rem;
`;

const ContactUsButton = styled.a<{mobile?: boolean}>`
  ${props => (props.mobile ? 'width: 100%' : '')}
`;

const ContactUsMessage = styled(Message)<{mobile?: boolean}>`
  background-color: ${colors.white} !important;
  max-width: ${props => (props.mobile ? '100%' : '75%')};

  ${props => (props.mobile ? 'text-align: justify' : '')};

  .spark-panel__content {
    padding: 0.5rem;
    ${props => (props.mobile ? 'align-items: center' : '')};
  }

  .spark-message {
    margin-bottom: 0 !important;
  }

  .spark-message__content {
    font-size: 1vw !important;
  }
`;

export default ContactUs;
