import {createContext, Dispatch, SetStateAction, useContext, useState} from 'react';
import {useForm, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {ProductContext} from '../provider/ProductProvider';
import getStorefrontImages, {
  ProductMedia,
} from '../components/storefront-content/content/utilities/getStorefrontImages';
import {ImageFile} from '@scm/components/files/FileUpload';
import getYtLink, {YtLinkI} from '../components/storefront-content/content/utilities/getYtLink';
import useGetProductC from '../hooks/useGetProductC';
import {CcrzEProductCSObjectProvisioningTypeCEnum, RecordTypeNameEnum} from '../generated/product';
import useGetScreenshotsAndGuideFiles from './utilities/useGetScreenshotsAndGuideFiles';
import getAdditionalFiles from '../components/storefront-content/content/utilities/getAdditionalFiles';

export const screenshotsName = 'screenshots';
export const ytLinkName = 'ytLink';
export const referenceGuideName = 'referenceGuide';
export const additionalFileName = 'additionalFile';

interface FormValues {
  [screenshotsName]: ProductMedia[];
  [ytLinkName]: YtLinkI;
  [referenceGuideName]: string | File[];
  [additionalFileName]: ProductMedia[];
}

interface MediaValues {
  watch: UseFormWatch<FormValues>;
  setValue: UseFormSetValue<FormValues>;
  setScreenshotsToDelete: Dispatch<SetStateAction<ProductMedia[]>>;
  setAdditionalFilesToDelete: Dispatch<SetStateAction<ProductMedia[]>>;
  setAdditionalFilesToEdit: Dispatch<SetStateAction<ProductMedia[]>>;
  isTouched: boolean;
  isScreenshotsError: boolean;
  isYtLinkError: boolean;
  shouldDeleteReferenceGuide: boolean;
  deleteReferenceGuide: () => void;
  screenshotsToDelete: ProductMedia[];
  additionalFilesToDelete: ProductMedia[];
  additionalFilesToEdit: ProductMedia[];
  newScreenshots: (ProductMedia | File)[];
  newAdditionalFiles: (ProductMedia | File)[];
  isYtTouched: boolean;
  reset: () => void;
  save: () => void;
  loading: boolean;
}

const ytLinkPattern = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/(watch\?v=|[a-zA-Z0-9-_=?])+$/;
const findNewScreenshots = (screenshots: Array<ProductMedia | ImageFile>) =>
  screenshots.filter(screenshot => screenshot instanceof File && screenshot.shouldUpload);

const findNewAdditionalFiles = (additionalFiles: Array<ProductMedia | ImageFile>) =>
  additionalFiles.filter(additionalFile => additionalFile instanceof File && additionalFile.shouldUpload);

const isAdditionalFilesNameTouched = (additionalFileInitValue: ProductMedia[], watch: UseFormWatch<FormValues>) =>
  additionalFileInitValue.filter(file => watch(additionalFileName).find(f => f.name === file.name)).length !==
  watch(additionalFileName).length;

export const MediaContext = createContext<MediaValues>({} as unknown as MediaValues);

const SAVE_REFERENCE_GUIDE = 'Quick Reference Guide.pdf';

const Media = ({children}: {children: JSX.Element}) => {
  const {productDetails} = useContext(ProductContext);
  const {ccrzEProductMediaC, recordType} = productDetails;
  const {quickReferenceGuideC, provisioningTypeC} = useGetProductC();
  const [quickReferenceGuideInitValue, setQuickReferenceGuideInitValue] = useState(
    (quickReferenceGuideC || '') as string | File[]
  );
  const [additionalFileInitValue, setAdditionalFileInitValue] = useState(getAdditionalFiles(ccrzEProductMediaC || []));
  const [screenshotsInitValue, setScreenshotsInitValue] = useState(getStorefrontImages(ccrzEProductMediaC || []));
  const [screenshotsToDelete, setScreenshotsToDelete] = useState<ProductMedia[]>([]);
  const [additionalFilesToDelete, setAdditionalFilesToDelete] = useState<ProductMedia[]>([]);
  const [additionalFilesToEdit, setAdditionalFilesToEdit] = useState<ProductMedia[]>([]);
  const [ytLinkInitValue, setYtLinkInitValue] = useState(getYtLink(ccrzEProductMediaC || []));
  const [shouldDeleteReferenceGuide, setDeleteReferenceGuide] = useState(false);
  const [loading, setLoading] = useState(false);

  const {watch, setValue} = useForm({
    mode: 'onChange',
    defaultValues: {
      [screenshotsName]: screenshotsInitValue,
      [ytLinkName]: ytLinkInitValue,
      [referenceGuideName]: quickReferenceGuideInitValue,
      [additionalFileName]: additionalFileInitValue,
    },
  });

  const deleteReferenceGuide = () => {
    setDeleteReferenceGuide(true);
    setValue(referenceGuideName, '', {shouldDirty: true, shouldTouch: true, shouldValidate: true});
  };

  const isScreenshotsTouched = () =>
    !!screenshotsToDelete.length || !!findNewScreenshots(watch(screenshotsName)).length;

  const isYtLinkTouched = () => (watch(ytLinkName).url ?? '') !== (ytLinkInitValue.url ?? '');

  const isAdditionalFilesTouched = () =>
    !!additionalFilesToDelete.length ||
    !!findNewAdditionalFiles(watch(additionalFileName)).length ||
    isAdditionalFilesNameTouched(additionalFileInitValue, watch);

  const calculateIsTouched = () => {
    return (
      isScreenshotsTouched() ||
      isYtLinkTouched() ||
      shouldDeleteReferenceGuide ||
      watch(referenceGuideName) !== quickReferenceGuideInitValue ||
      isAdditionalFilesTouched()
    );
  };

  const calculateScreenshotsError = () =>
    (provisioningTypeC === CcrzEProductCSObjectProvisioningTypeCEnum.Public ||
      recordType?.name === RecordTypeNameEnum.Standard) &&
    isScreenshotsTouched() &&
    !watch(screenshotsName).length &&
    (watch(ytLinkName).url === undefined || watch(ytLinkName).url === '');

  const calculateYtLinkError = () =>
    (provisioningTypeC === CcrzEProductCSObjectProvisioningTypeCEnum.Public ||
      recordType?.name === RecordTypeNameEnum.Standard) &&
    isYtLinkTouched() &&
    !watch(ytLinkName)?.url?.length &&
    !ytLinkPattern.test(watch(ytLinkName).url) &&
    !watch(screenshotsName).length;

  const reset = () => {
    setAdditionalFilesToDelete([]);
    setAdditionalFilesToEdit([]);
    setValue(additionalFileName, additionalFileInitValue, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setValue(referenceGuideName, quickReferenceGuideInitValue, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setDeleteReferenceGuide(false);
    setScreenshotsToDelete([]);
    setValue(screenshotsName, screenshotsInitValue, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setValue(ytLinkName, ytLinkInitValue, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const {fetchData} = useGetScreenshotsAndGuideFiles(setLoading);

  const save = async () => {
    setDeleteReferenceGuide(false);
    setScreenshotsToDelete([]);
    const newReferenceGuideValue = watch(referenceGuideName).length ? SAVE_REFERENCE_GUIDE : watch(referenceGuideName);
    setQuickReferenceGuideInitValue(newReferenceGuideValue);
    setValue(referenceGuideName, newReferenceGuideValue, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
    setYtLinkInitValue(watch(ytLinkName));
    setAdditionalFilesToDelete([]);
    setAdditionalFilesToEdit([]);

    const screenshotsAndGuideFiles = await fetchData();
    setScreenshotsInitValue(screenshotsAndGuideFiles[0]);
    setValue(screenshotsName, screenshotsAndGuideFiles[0], {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    setAdditionalFileInitValue(screenshotsAndGuideFiles[1]);
    setValue(additionalFileName, screenshotsAndGuideFiles[1], {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const value = {
    watch,
    setValue,
    setScreenshotsToDelete,
    additionalFilesToDelete,
    additionalFilesToEdit,
    setAdditionalFilesToDelete,
    setAdditionalFilesToEdit,
    isTouched: calculateIsTouched(),
    isYtTouched: isYtLinkTouched(),
    isScreenshotsError: calculateScreenshotsError(),
    isYtLinkError: calculateYtLinkError(),
    deleteReferenceGuide,
    shouldDeleteReferenceGuide,
    screenshotsToDelete,
    newScreenshots: findNewScreenshots(watch(screenshotsName)),
    newAdditionalFiles: findNewAdditionalFiles(watch(additionalFileName)),
    reset,
    save,
    loading,
  };

  return <MediaContext.Provider value={value}>{children}</MediaContext.Provider>;
};

export default Media;
