import * as yup from 'yup';

export default {
  name: yup.string().required().max(100),
  address: yup
    .object()
    .required()
    .shape({
      line1: yup.string().required().max(250),
      line2: yup.string().max(250),
      city: yup.string().required().max(100),
      province: yup.string().required().max(100),
      postalCode: yup.string().required().max(10),
      country: yup
        .string()
        .required()
        .matches(/^[A-Z]{2}$/),
    }),
  businessProfile: yup.string().required().max(1000),
  numberOfEmployees: yup.mixed().oneOf(['1-10', '11-50', '51-250', 'more than 250']),
  yearsInBusiness: yup.mixed().oneOf(['1-5', '6-10', '11-20', 'more than 20']),
  locationCountry: yup
    .string()
    .required()
    .matches(/^[A-Z]{2}$/),
  locationProvince: yup.string().required().max(100),
};
