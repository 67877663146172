/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Required only for DevPartner
 * @export
 */
export const CompanyYearsInBusinessType = {
    _15: '1-5',
    _610: '6-10',
    _1120: '11-20',
    MoreThan20: 'more than 20'
} as const;
export type CompanyYearsInBusinessType = typeof CompanyYearsInBusinessType[keyof typeof CompanyYearsInBusinessType];


export function CompanyYearsInBusinessTypeFromJSON(json: any): CompanyYearsInBusinessType {
    return CompanyYearsInBusinessTypeFromJSONTyped(json, false);
}

export function CompanyYearsInBusinessTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyYearsInBusinessType {
    return json as CompanyYearsInBusinessType;
}

export function CompanyYearsInBusinessTypeToJSON(value?: CompanyYearsInBusinessType | null): any {
    return value as any;
}

