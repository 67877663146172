import React, {useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import DropdownItems from './DropdownItems';
import {Link} from 'react-router-dom';
import InternalUrls from 'utils/internalUrls';
import EventCategories from 'googleAnalytics/EventCategories';
import logEvent from 'googleAnalytics/logEvent';
import {AuthenticationNavButton} from '@scm/authentication/components/AuthenticationNavButton';

const HeaderMenu = ({closeHamburgerMenu}: {closeHamburgerMenu: () => void}) => {
  const popoverRef = useRef<HTMLLIElement>(null);

  const clearDropdownActive = () => {
    popoverRef.current && popoverRef.current.classList.remove('child-active', 'active');
  };

  const onMenuItemClicked = (el: React.MouseEvent<HTMLLIElement>) => {
    logEvent({category: EventCategories.Header, action: 'Menu link clicked', label: el.currentTarget.innerText});
    el.currentTarget.classList.remove('active');
    closeHamburgerMenu();
  };

  const {formatMessage} = useIntl();
  const hideNavigationLabel = formatMessage({id: 'header.hideNavigation'});
  const expandOrCollapseLabel = formatMessage({id: 'header.expandOrCollapse'});
  const expandLabel = formatMessage({id: 'header.expand'});

  return (
    <>
      <div className="spark-menu spark-header__menu" role="navigation">
        <div className="spark-menu__header">
          <button className="spark-menu__toggle" aria-label={hideNavigationLabel} title={hideNavigationLabel}>
            <i className="spark-icon--fill spark-icon-close" />
          </button>
          <span className="spark-menu__title">
            <span className="spark-logo spark-logo--sabre spark-logo--sm">
              <FormattedMessage id="common.sabre" />
            </span>
          </span>
        </div>
        <ul className="spark-menu__list spark-menu__list--main spark-header__list " role="presentation">
          <li className="spark-menu__list-item" ref={popoverRef} onClick={clearDropdownActive}>
            <span className="spark-menu__list-links">
              <StyledHeaderItemSpan className="spark-menu__list-link">
                <FormattedMessage id="header.partnerNetwork" />
              </StyledHeaderItemSpan>
              <button
                className="spark-menu__list-expand"
                role="menuitemcheckbox"
                aria-label={expandOrCollapseLabel}
                aria-checked={false}
                title={expandLabel}
              />
            </span>
            <DropdownItems
              onClick={() => {
                clearDropdownActive();
                closeHamburgerMenu();
              }}
            />
          </li>
          <li
            className="spark-menu__list-item"
            onClick={(el: React.MouseEvent<HTMLLIElement>) => onMenuItemClicked(el)}
          >
            <Link className="spark-menu__list-link" to={InternalUrls.Directory}>
              <FormattedMessage id="header.directory" />
            </Link>
          </li>
          <li
            className="spark-menu__list-item"
            onClick={(el: React.MouseEvent<HTMLLIElement>) => onMenuItemClicked(el)}
          >
            <Link className="spark-menu__list-link" to={InternalUrls.Tiers}>
              <FormattedMessage id="header.tiers" />
            </Link>
          </li>
          <li
            className="spark-menu__list-item"
            onClick={(el: React.MouseEvent<HTMLLIElement>) => onMenuItemClicked(el)}
          >
            <Link className="spark-menu__list-link" to={InternalUrls.Benefits}>
              <FormattedMessage id="header.benefits" />
            </Link>
          </li>
          <li
            className="spark-menu__list-item"
            onClick={(el: React.MouseEvent<HTMLLIElement>) => onMenuItemClicked(el)}
            role="presentation"
          >
            <Link className="spark-menu__list-link" to={InternalUrls.JoinUs}>
              <FormattedMessage id="header.joinUs" />
            </Link>
          </li>
        </ul>
      </div>
      <AuthenticationNavButton />
    </>
  );
};

const StyledHeaderItemSpan = styled.span`
  @media (min-device-width: 796px) {
    color: white !important;
    font-size: 2rem !important;
    font-weight: 400;
    text-transform: none;
  }
`;

export default HeaderMenu;
