import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ProposalContainer} from '@scm/proposal/pages/proposalForm/ProposalTypePage';
import PageHeader from '@scm/proposal/components/content/PageHeader';
import {ProposalContext} from '@scm/proposal/pages/proposalForm/ProposalForm';
import RedAppName from '@scm/proposal/pages/proposalForm/steps/definitionPage/definitionComponents/RedAppName';
import {ButtonContainerInsurance} from '@scm/proposal/pages/proposalForm/steps/InsurancePage';
import {Button, Message} from '@sabre/spark-react-core';
import {ButtonSize, MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import ScribeScriptUpload from '@scm/scribe-scripts/definitionPage/definitionComponents/ScribeScriptUpload';
import {formTitleId, redAppName} from '@scm/proposal/pages/proposalForm/ProposalValues';
import ProdTesters from './definitionComponents/prodTesters/ProdTesters';
import {
  activeListeningFilesNames,
  activeListeningName,
  hiddenUploadedScribeScriptFilesNames,
  hiddenUploadedScribeScriptName,
  resourcesFilesNames,
  resourcesName,
  ScribeScriptsValues,
  uploadedScribeScriptFilesNames,
  uploadedScribeScriptName,
} from '@scm/scribe-scripts/provider/ScribeScriptsValues';
import {initialValues} from '@scm/scribe-scripts/provider/scribeScriptsConstants';
import {FormattedMessage, useIntl} from 'react-intl';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {scribeScriptsSchema} from '@scm/scribe-scripts/schema/scribeScriptsSchema';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {Configuration, CreateScribeScriptsRequest, ProductApi} from '../generated/product';
import {productApiBaseLink} from '@scm/product-components';
import {testersTable, TestersValues} from './definitionComponents/prodTesters/TestersValues';
import {initialValues as testersInitialValues} from '../definitionPage/definitionComponents/prodTesters/testersConstants';
import {editedTesterName} from '@scm/product-components/pages/storefrontData/interfaces/TesterDataValues';
import {openToast} from '@scm/components/messaging/openToast';
import {scribeScriptsMapper} from '../mapper/scribeScriptsMapper';
import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import {Loading} from '@scm/components';
import {redAppSupportEmail} from '@scm/components/utils/common';
import {hrefStringCreator} from '@scm/components/messaging/HrefElement';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';
import AdditionalStatement from '@scm/proposal/components/content/AdditionalStatement';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const postScribeScripts = async (request: CreateScribeScriptsRequest) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).createScribeScripts(request);

export const ScribeScriptsDefinitionPage = () => {
  const {
    watch: proposalWatch,
    setValue: proposalSetValue,
    handleCancel,
    isSmallSpinnerLoading,
  } = useContext(ProposalContext);
  const [isNameValid, setIsNameValid] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    proposalSetValue(formTitleId, 'proposal.scribeScripts.title');
  }, [formTitleId]);

  const testersMethods = useForm<TestersValues>({
    mode: 'onChange',
    defaultValues: testersInitialValues,
  });

  const {
    handleSubmit,
    watch,
    setValue,
    formState: {isValid},
  } = useForm<ScribeScriptsValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    // @ts-ignore
    resolver: yupResolver(scribeScriptsSchema),
  });

  const {formatMessage} = useIntl();

  const formProps = useMemo(() => ({watch, setValue}), []);

  const isFormDisabled =
    isSmallSpinnerLoading ||
    !isNameValid ||
    !isValid ||
    !proposalWatch(redAppName) ||
    !!testersMethods.watch(editedTesterName);

  const onSubmit: SubmitHandler<ScribeScriptsValues> = async data => {
    gaEvent('Submitted Scribe Scripts');

    try {
      setIsLoading(true);
      await postScribeScripts(
        scribeScriptsMapper(data, proposalWatch(redAppName), testersMethods.watch()[testersTable])
      );
      setIsSuccess(true);
    } catch (e) {
      openToast(
        formatMessage({id: 'definition.scribeScripts.error.title'}),
        formatMessage(
          {id: 'definition.scribeScripts.error.message'},
          {
            email: hrefStringCreator(redAppSupportEmail),
          }
        ),
        ToastType.WARNING,
        'spark-icon-alert-triangle',
        true
      );
    } finally {
      setIsLoading(false);
    }
  };

  return isSuccess ? (
    <Message
      content={formatMessage({id: 'scribeScriptsAndPfKeys.success.content'})}
      role={MessageRole.STATUS}
      status={MessageStatus.SUCCESS}
      title={formatMessage({id: 'scribeScripts.success.title'})}
      type={MessageType.PAGE}
      animate
    />
  ) : isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <>
      <ProposalContainer className="spark-panel spark-pad-l-2">
        <PageHeader id="definition.title" />
        <AdditionalStatement id="definition.additionalStatement" />
        <RedAppName
          firstParagraph="definition.redAppName.firstParagraph.scribeScriptPfKeys"
          secondParagraph="definition.redAppName.secondParagraph"
          setIsNameValid={setIsNameValid}
        />
        <ScribeScriptUpload
          id="definition.scribeScripts.fileUpload.label"
          filesNames={uploadedScribeScriptFilesNames}
          fileType="scribeScripts"
          name={uploadedScribeScriptName}
          {...formProps}
        />
        <ScribeScriptUpload
          id="definition.hiddenScribeScripts.fileUpload.label"
          filesNames={hiddenUploadedScribeScriptFilesNames}
          fileType="hiddenScribeScripts"
          name={hiddenUploadedScribeScriptName}
          radioButton
          {...formProps}
        />
        <ScribeScriptUpload
          id="definition.resources.fileUpload.label"
          filesNames={resourcesFilesNames}
          fileType="resources"
          name={resourcesName}
          radioButton
          {...formProps}
        />
        <ScribeScriptUpload
          id="definition.activeListening.fileUpload.label"
          filesNames={activeListeningFilesNames}
          fileType="activeListening"
          name={activeListeningName}
          radioButton
          {...formProps}
        />
      </ProposalContainer>
      <ProposalContainer className="spark-panel spark-pad-l-2">
        <ProdTesters {...testersMethods} />
      </ProposalContainer>
      <ButtonContainerInsurance className="spark-mar-t-1 spark-flex">
        <Button secondary size={ButtonSize.SMALL} onClick={handleCancel} className="spark-mar-r-1">
          <FormattedMessage id="common.backButton" />
        </Button>
        <Button size={ButtonSize.SMALL} type="button" onClick={handleSubmit(onSubmit)} disabled={isFormDisabled}>
          <FormattedMessage id="common.submitButton" />
        </Button>
      </ButtonContainerInsurance>
    </>
  );
};
