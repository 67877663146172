import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from '@sabre/spark-react-core';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import colors from '@scm/components/assets/colors';

const ConfigureButton = ({clickHandler}: {clickHandler: () => void}) => (
  <CleanupMessage className="spark-text-center">
    <h3 aria-level={2}>
      <FormattedMessage id="cleanup.notScheduled.message" />
    </h3>
    <SparkButton onClick={clickHandler}>
      <FormattedMessage id="cleanup.notScheduled.buttonContent" />
    </SparkButton>
  </CleanupMessage>
);

const SparkButton = styled(Button)`
  background-color: ${colors.grey700}!important;
  border: none;
  outline: none;
  &::after,
  &::before {
    display: none !important;
  }
`;

const CleanupMessage = styled.div`
  @media (min-width: ${deviceWidths.md}px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default ConfigureButton;
