import React, {useEffect, useRef} from 'react';
import SparkPopover from '@sabre/spark/js/src/components/multi-select-input';
import {useFormContext} from 'react-hook-form';
import styled from 'styled-components';
import {getError} from './SparkInput';

const MultiSelect = ({
  name,
  searchText = '',
  options,
  disabled,
  errorMessage,
  markedValues,
  hideErrorMessage,
}: {
  name: string;
  searchText?: string;
  options: {[key: string]: string};
  disabled?: boolean;
  errorMessage?: string;
  markedValues?: string[];
  hideErrorMessage?: boolean;
}) => {
  const {
    register,
    formState: {errors},
  } = useFormContext();
  const multiSelectRef = useRef(null);

  useEffect(() => {
    const sparkAccordion = new SparkPopover(multiSelectRef.current);
    return () => {
      sparkAccordion.remove();
    };
  }, [multiSelectRef]);

  const error = getError(name, errors);

  return (
    <MultiSelectContainer className="spark-multi-select" data-error aria-label="Select Options" ref={multiSelectRef}>
      <div className="spark-multi-select__container">
        {Object.entries(options).map((item, index) => (
          <label
            className="spark-checkbox"
            key={index}
            style={item[1].toLowerCase().includes(searchText) ? {display: 'flex'} : {display: 'none'}}
          >
            <input
              className="spark-checkbox__input"
              type="checkbox"
              value={item[0]}
              {...register(name)}
              disabled={disabled && markedValues?.indexOf(item[0]) === -1}
              aria-label={`Field with value ${item[0]}`}
            />
            <span className="spark-checkbox__box" />
            <span className="spark-label">{item[1]}</span>
          </label>
        ))}
      </div>
      {!hideErrorMessage && error && <span className="spark-select__message">{errorMessage}</span>}
    </MultiSelectContainer>
  );
};

const MultiSelectContainer = styled.fieldset.attrs(() => ({size: 3}))`
  &.spark-multi-select {
    margin: 0;
  }

  &.spark-multi-select .spark-multi-select__container {
    padding-right: 0;
  }

  &.spark-multi-select:hover:not([disabled])[data-error] {
    border: none;
  }
`;

export default MultiSelect;
