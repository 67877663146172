import {string} from 'yup';

export const PCC_MIN_LENGTH = 3;
export const PCC_MAX_LENGTH = 4;
export const EPR_MIN_LENGTH = 1;
export const EPR_MAX_LENGTH = 6;

export const PCC_SHORT_MESSAGE = '3-4 char required';
export const PCC_LONG_MESSAGE = 'PCC must contain 3 to 4 characters';

export const EPR_SHORT_MESSAGE = '1-6 digits needed';
export const EPR_LONG_MESSAGE = 'EPR must contain 1 to 6 digits';

export const PCC_REGEX = /^[A-Za-z0-9]{3,4}$/;

export const EPR_REGEX = /^[0-9]{1,6}$/;

export const OPTIONAL_PCC_SCHEMA = string().max(PCC_MAX_LENGTH).matches(PCC_REGEX, PCC_LONG_MESSAGE);

export const OPTIONAL_EPR_SCHEMA = string().max(EPR_MAX_LENGTH).matches(EPR_REGEX, EPR_LONG_MESSAGE);

export const REQUIRED_PCC_SCHEMA = string()
  .min(PCC_MIN_LENGTH, PCC_LONG_MESSAGE)
  .max(PCC_MAX_LENGTH, PCC_LONG_MESSAGE)
  .matches(PCC_REGEX, PCC_LONG_MESSAGE)
  .required();

export const REQUIRED_EPR_SCHEMA = string()
  .min(EPR_MIN_LENGTH, EPR_LONG_MESSAGE)
  .max(EPR_MAX_LENGTH, EPR_LONG_MESSAGE)
  .matches(EPR_REGEX, EPR_LONG_MESSAGE)
  .required();

export const makePccUppercase = (value: string) => value.toUpperCase();
