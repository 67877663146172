import {ActionCreator, AnyAction, Reducer, ReducersMapObject} from 'redux';

export const makeActionCreator = <T extends AnyAction>(type: string, ...argNames: string[]): ActionCreator<T> => {
  return (...args) => {
    const action = {type} as any;
    argNames.forEach((_, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
};

export const createReducer = <S>(initialState: S, handlers: ReducersMapObject): Reducer<S> => {
  return (state = initialState, action) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
};
