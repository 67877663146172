import React, {ChangeEvent, useContext, useEffect, useMemo, useState} from 'react';
import {TextInput} from '@sabre/spark-react-core';
import {PricingValues, RowData, rowsListPccepr, rowsListTransaction} from './PricingValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {useIntl} from 'react-intl';
import {PricingContext} from './Pricing';
import styled from 'styled-components';
import {Row} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {initialValues} from './pricingConstants';
import {PricingFormDefinitionContext} from '@scm/product-details/components/sections/pricing/PricingForm';

const PricingRangesRow = ({
  pricingType,
  from,
  to,
  index,
  price,
  isValidTo,
  isValidPrice,
  disabledToValue,
  disabled,
  fixedPriceClicked,
  hasPricingFromProvider,
}: {
  pricingType: string;
  from: string;
  to: string;
  index: number;
  price: string;
  isValidTo?: boolean;
  isValidPrice?: boolean;
  disabledToValue?: boolean;
  disabled?: boolean;
  fixedPriceClicked?: boolean;
  hasPricingFromProvider?: boolean;
}) => {
  const {formatMessage} = useIntl();
  const {watch, setValue} = useContext(hasPricingFromProvider ? PricingFormDefinitionContext : PricingContext);
  const [rangesList, setRangesList] = useState(rowsListPccepr);
  const changeValueOptions = useMemo(() => ({shouldValidate: true, shouldTouch: true, shouldDirty: true}), []);

  const toStatusMessage = !isValidTo
    ? formatMessage({
        id: 'tabs.price.definition.wrongToValueMessage',
      })
    : '';
  const priceStatusMessage = !isValidPrice
    ? formatMessage({
        id: 'tabs.price.definition.wrongPriceValueMessage',
      })
    : '';
  const fromName = 'from';
  const toName = 'to';
  const priceName = 'price';

  useEffect(() => {
    if (pricingType === 'perPccepr') {
      setRangesList(rowsListPccepr);
      if (fixedPriceClicked) {
        setValue(rowsListPccepr, initialValues[rangesList]);
      }
    }
    if (pricingType === 'perTransaction') {
      setRangesList(rowsListTransaction);
      if (fixedPriceClicked) {
        setValue(rowsListTransaction, initialValues[rangesList]);
      }
    }
  }, [pricingType, fixedPriceClicked]);

  const validateFormat = (inputName: string, value: string) => {
    return (
      (inputName === 'to' && (value.endsWith('.') || value.endsWith(','))) ||
      (inputName === 'price' && (value.charAt(value.length - 4) === '.' || value.charAt(value.length - 4) === ','))
    );
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    interface ListItem {
      rowValue: string;
    }

    const list = [...(watch(rangesList as keyof PricingValues) as unknown as Array<ListItem>)];
    list[index][name as keyof ListItem] = validateFormat(name, value)
      ? value.slice(0, -1).replace(',', '.')
      : (list[index][name as keyof ListItem] = value).replace(',', '.');
    setValue(rangesList as keyof PricingValues, list as unknown as RowData[], changeValueOptions);
  };

  return (
    <TableRow className="spark-mar-l-2">
      <TableInputs className="col-xs-4" name={fromName} value={from} disabled aria-label="From field" />
      <TableInputs
        className="col-xs-4"
        name={toName}
        disabled={disabledToValue || disabled}
        onChange={e => handleChange(e)}
        value={to}
        status={!isValidTo ? MessageStatus.ERROR : undefined}
        statusMessage={toStatusMessage}
        aria-label="To field"
      />
      <TableInputs
        className="col-xs-4"
        name={priceName}
        disabled={disabled || price === 'Negotiable'}
        onChange={e => handleChange(e)}
        value={price}
        status={!isValidPrice ? MessageStatus.ERROR : undefined}
        statusMessage={priceStatusMessage}
        aria-label="Price"
      />
    </TableRow>
  );
};

export const TableRow = styled(Row)`
  max-width: 33rem;
`;

export const TableInputs = styled(TextInput)`
  max-width: 11rem;
  input {
    max-height: 50px;
  }
  [value] {
    padding-bottom: 22px;
    text-align: center;
  }
`;

export default PricingRangesRow;
