import React from 'react';
import Message, {MessageType} from 'spark/Message';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const ApolloErrorElement = ({type}: {type: string}) => {
  const {formatMessage} = useIntl();
  const titleId = type === undefined ? 'error.title.generic' : `error.title.${type}`;
  const messageId = type === undefined ? 'error.message.generic' : `error.message.${type}`;

  return (
    <MessageContainer>
      <Message type={MessageType.error} title={formatMessage({id: titleId})} message={formatMessage({id: messageId})} />
    </MessageContainer>
  );
};

const MessageContainer = styled.div`
  width: 100%;

  .spark-message--error {
    background-color: ${colors.white} !important;
  }
`;

export default ApolloErrorElement;
