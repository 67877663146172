import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {FormProvider, useForm} from 'react-hook-form';
import {Star} from '@scm/components';
import {Button, RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {StyledH2} from '../developerPartner/ContactDetails';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import EventCategories from '@scm/partner-network/src/googleAnalytics/EventCategories';
import logEvent from '@scm/partner-network/src/googleAnalytics/logEvent';
import {ProvisioningType} from '../../../generated/onboardings';
import {Text} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import ProcessScheme from '@scm/components/processScheme/ProcessScheme';
import ProcessSchemes from '@scm/components/processScheme/schemes';
import Legend from '@scm/components/accessibility/Legend';

const RedAppType = ({
  setCurrentStep,
  isPublicRedApp,
  setIsPublicRedApp,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isPublicRedApp?: boolean;
  setIsPublicRedApp: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  const {formatMessage} = useIntl();
  const methods = useForm({mode: 'onChange'});
  const previousStep = () => setCurrentStep(prevState => prevState - 1);
  const [showProcessSchemeModal, setShowProcessSchemeModal] = useState(false);

  const formSubmitHandler = function () {
    if (isPublicRedApp !== undefined) {
      logEvent({category: EventCategories.JoinUs, action: 'Moved to Pricing step'});
      setCurrentStep(prevState => prevState + 1);
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <form autoComplete="off" onSubmit={methods.handleSubmit(formSubmitHandler)}>
          <div className="spark-panel">
            <div className="spark-panel__content">
              <StyledH2>
                <FormattedMessage id="redAppType.title" />
              </StyledH2>
              <Text>
                <FormattedMessage id="redAppType.subTitle" />
              </Text>
              <Note>
                <FormattedMessage id="redAppType.note" />
                <Star starColor={colors.red} />
              </Note>
              <RadioButtonGroup
                name="isPublicRedApp"
                value={
                  isPublicRedApp !== undefined
                    ? isPublicRedApp
                      ? ProvisioningType.Public
                      : ProvisioningType.Private
                    : ''
                }
                onChange={(_, value) => {
                  setIsPublicRedApp((value as ProvisioningType) === ProvisioningType.Public);
                }}
              >
                <Legend text={formatMessage({id: 'redAppType.title'})} />
                {Object.values(ProvisioningType).map(value => (
                  <RadioButton key={value} label={value} value={value} data-testid={value} />
                ))}
              </RadioButtonGroup>
              <ShowProcessSchemeSpan onClick={() => setShowProcessSchemeModal(true)}>
                <FormattedMessage id="processScheme.redApp.button.text" />
              </ShowProcessSchemeSpan>
            </div>
          </div>
          <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
            <Button type="button" onClick={previousStep} size={ButtonSize.SMALL} secondary>
              <FormattedMessage id="joinUsPage.form.backButton" />
            </Button>
            <Button disabled={isPublicRedApp === undefined} type="submit" size={ButtonSize.SMALL}>
              <FormattedMessage id="joinUsPage.form.button" />
            </Button>
          </nav>
        </form>
      </FormProvider>
      <ProcessScheme
        processScheme={
          isPublicRedApp === undefined || isPublicRedApp ? ProcessSchemes.PublicApp : ProcessSchemes.PrivateApp
        }
        open={showProcessSchemeModal}
        setOpen={setShowProcessSchemeModal}
        isProposal={false}
        ariaLevel={2}
      />
    </>
  );
};

export const Note = styled.p`
  font-size: 1.3333333333rem;
  margin-bottom: 0;

  &[data-margin-sm] {
    margin-bottom: 1rem;
  }
`;

const ShowProcessSchemeSpan = styled.span`
  color: ${colors.sparkBlue};
  cursor: pointer;
`;

export default RedAppType;
