const bundleTesting = {
    'bundleTesting.CERT.description':
        'Select whether this Red App bundle works as expected, based on testing at Sabre Red 360 CERT environment:',
    'bundleTesting.PROD.description':
        'Select whether this Red App bundle works as expected, based on testing at Sabre Red 360 PROD environment:',
    'bundleTesting.CERT.testersInfo': 'Create or modify testers list in section "PROD Testers" in Product Details View.',
    'bundleTesting.failDescription':
        'If the Red App version is marked as "Failed" you will be able to make necessary changes and resubmit the Red App bundle.',
    'bundleTesting.CERT.passDescription':
        'If the Red App version is marked as "Passed" you can move forward. Version will be assigned to the list of PROD Testers configured for this Red App. You can also edit this list in "PROD Testers" tab.',
    'bundleTesting.PROD.passDescription':
        'If the Red App version is marked as "Passed" you can move forward to publish it later.',
    'bundleTesting.error': 'Test result cannot be saved, please try again or contact {email}.',
    'bundleTesting.errorTitle': 'Updates failed.',
    'bundleTesting.success': 'Your bundle updates were saved.',
    'bundleTesting.successTitle': 'Saved.',
    'bundleTesting.passAndPublishDescription.patch':
        'If the Red App version is marked as "Passed" you can move forward to publish it. Select this option to publish this version immediately.',
    'bundleTesting.passAndPublishDescription.new':
        'If the Red App version is marked as "Passed" you can move forward to publish it. Select this option to publish this Red App immediately.',
    'newBundle.initialDescription.label': 'Sabre Red 360 Description',
    'newBundle.initialDescription.comment': 'Text will be used in the Sabre Red 360 Agency Admin Tool only.',
    'newBundle.upload.header': 'Upload your Red App bundle',
    'newBundle.upload.additionalText': 'Upload *.zip file up to 32MB.',
    'newBundle.info.listItem1':
        'If you upload a subsequent version of an existing Red App, be aware of the importance of the version number.',
    'newBundle.info.listItem2': 'The version number format is: major.minor.service.qualifier.',
    'newBundle.info.listItem3': 'If you increment the service or qualifier number, it is a patch.',
    'newBundle.info.listItem4':
        'Users of the prior version will receive this new patch automatically after it is processed successfully.',
    'newBundle.info.listItem5': 'If you increment the major or minor number, it is an upgrade.',
    'newBundle.info.listItem6':
        'For a new Red App or an upgrade to an existing Red App, you must submit a proposal to obtain a new Red App ID.',
    'newBundle.useCaseFile.header':
        'Upload a file that contains detailed use cases for all the functionality in your app',
    'newBundle.useCaseFile.additionalText': 'Upload *.doc, *.docx or *.pdf file up to 10MB.',
    'newBundle.patchNote.label': 'PATCH RELEASE NOTES',
    'newBundle.patchNote.placeholder': 'Describe changes in new version.',
    'confirmationForm.title': 'Confirmation',
    'confirmationForm.description.patch': 'Are you sure you want to publish a new Red App version?',
    'confirmationForm.description.new': 'Are you sure you want to publish a new Red App?',
    'cancelForm.description': 'Please confirm that you want to cancel the certification of version {version} - this version will be marked as cancelled and you will be able to submit a new version bundle.',
    'newBundle.securityWorksheet.header': 'Upload a file that contains security worksheet for your app',
    'newBundle.workflowDiagram.header': 'Upload a file that contains workflow diagram for your app',
};

export default bundleTesting;
