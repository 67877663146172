import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import CenterItems from 'components/styledComponents/CenterItems';
import InternalUrls from 'utils/internalUrls';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const HighlightedHeader = () => (
  <div className="spark-carousel__header">
    <CenterItems>
      <Title aria-level={1}>
        <FormattedMessage id="homePage.featured.header.title" />
      </Title>
      <Link to={InternalUrls.Directory}>
        <FormattedMessage id="homePage.featured.header.viewAll" />
      </Link>
    </CenterItems>
  </div>
);

const Title = styled.h3`
  color: ${colors.grey700};
`;

export default HighlightedHeader;
