import React, {ChangeEvent} from 'react';
import {
  CheckAll,
  CheckAllOptions,
  CheckboxLabel,
  Content,
  filterList,
  MultiSelectMainProps,
  SecondLevelOptions,
} from './MultiSelect';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const TwoLevelMultiSelect = ({
  multiSelectMainProps,
  enableCheckAll,
  checkAllOptions,
  secondLevelOptions,
  searchText,
  disabled,
}: {
  multiSelectMainProps: MultiSelectMainProps;
  enableCheckAll?: boolean;
  checkAllOptions: CheckAllOptions;
  secondLevelOptions: SecondLevelOptions;
  searchText: string;
  disabled?: boolean;
}) => {
  const {checkAllName, checkAllLabel} = checkAllOptions;
  const {secondLevelName, optionsSecondLevel} = secondLevelOptions;
  const {watch, setValue, firstLevelName, options} = multiSelectMainProps;
  const validationOptions = {shouldTouch: true, shouldValidate: true, shouldDirty: true};

  const setAll = (value: boolean) => setValue(checkAllName, value, validationOptions);
  const optionsKeys = Object.keys(options);
  const optionsKeysLength = optionsKeys.length;

  const firstLevelChangeHandler = (secondLevelValues: Object, event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    const currentList = () => watch(firstLevelName);
    const currentSecondLevel = () => watch(secondLevelName);
    const secondLevelKeys = Object.keys(secondLevelValues);
    if (currentList().includes(targetValue)) {
      setValue(firstLevelName, filterList(targetValue, currentList()), validationOptions);

      setAll(false);

      secondLevelKeys.map(value => {
        setValue(secondLevelName, filterList(value, currentSecondLevel()), validationOptions);
      });

      return;
    }

    setValue(firstLevelName, [...currentList(), targetValue], validationOptions);

    secondLevelKeys.map(value => {
      if (!currentSecondLevel().includes(value)) {
        setValue(secondLevelName, [...currentSecondLevel(), value], validationOptions);
      }
    });

    if (currentList().length === optionsKeysLength) {
      setAll(true);
    }
  };

  const secondLevelChangeHandler = (
    firstLevelValue: string,
    firstLevel: Entries<Object>,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const targetValue = event.target.value;
    const currentList = () => watch(secondLevelName);
    const firstLevelList = () => watch(firstLevelName);

    if (currentList().includes(targetValue)) {
      setValue(secondLevelName, filterList(targetValue, currentList()), validationOptions);
      setValue(firstLevelName, filterList(firstLevelValue, firstLevelList()), validationOptions);

      if (watch(checkAllName)) {
        setAll(false);
      }
    } else {
      const newValue = [...currentList(), targetValue];
      setValue(secondLevelName, newValue, validationOptions);

      const values = (firstLevel as []).reduce(
        (valuePrevious: Array<string>, currentValue: Array<string>) => [...valuePrevious, currentValue[0]],
        []
      );

      if (newValue.filter(value => values.includes(value)).length === values.length) {
        setValue(firstLevelName, [...firstLevelList(), firstLevelValue], validationOptions);
        if (firstLevelList().length === optionsKeysLength) {
          setAll(true);
        }
      }
    }
  };

  const checkAllHandler = () => {
    const currentValue = watch(checkAllName);
    if (!currentValue) {
      setValue(firstLevelName, optionsKeys, validationOptions);
      setValue(
        secondLevelName,
        optionsSecondLevel.reduce((previous, current) => [...(previous as Array<string>), ...Object.keys(current)], []),
        validationOptions
      );
    } else {
      [firstLevelName, secondLevelName].forEach(name => setValue(name, [], validationOptions));
    }
    setAll(!currentValue);
  };

  return (
    <>
      {enableCheckAll && (
        <CheckAll className="spark-checkbox spark-pad-1 spark-mar-b-0">
          <input
            className="spark-checkbox__input"
            type="checkbox"
            checked={watch(checkAllName)}
            onChange={checkAllHandler}
            disabled={disabled}
            aria-label="Select global"
          />
          <span className="spark-checkbox__box" />
          <span className="spark-label">{checkAllLabel}</span>
        </CheckAll>
      )}
      <Content className="spark-multi-select__container">
        {Object.entries(options).map((item, index) => (
          <div key={index}>
            <CheckboxLabel className="spark-checkbox" hasText={item[1].toLowerCase().includes(searchText)}>
              <input
                className="spark-checkbox__input"
                type="checkbox"
                value={item[0]}
                checked={watch(firstLevelName).indexOf(item[0]) !== -1}
                onChange={e => firstLevelChangeHandler(optionsSecondLevel[index], e)}
                disabled={disabled}
                aria-label={`Option to select: ${item[0]}`}
              />
              <span className="spark-checkbox__box" />
              <span className="spark-label">{item[1]}</span>
            </CheckboxLabel>
            <div>
              <div className="spark-multi-select__group">
                {Object.entries(optionsSecondLevel[index])
                  .sort((item1, item2) => item1[1].localeCompare(item2[1]))
                  .map((itemSecondLevel, index, secondLevel) => (
                    <CheckboxLabel
                      className="spark-checkbox"
                      key={index}
                      hasText={itemSecondLevel[1].toLowerCase().includes(searchText)}
                    >
                      <input
                        className="spark-checkbox__input"
                        type="checkbox"
                        value={itemSecondLevel[0]}
                        checked={watch(secondLevelName).indexOf(itemSecondLevel[0]) !== -1}
                        onChange={e => secondLevelChangeHandler(item[0], secondLevel as Entries<Object>, e)}
                        disabled={disabled}
                        aria-label={`Option to select: ${item[0]}`}
                      />
                      <span className="spark-checkbox__box" />
                      <span className="spark-label">{itemSecondLevel[1]}</span>
                    </CheckboxLabel>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </Content>
    </>
  );
};
