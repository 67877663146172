import {FormattedMessage} from 'react-intl';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import React, {ChangeEvent, useContext, useRef} from 'react';
import {isAllowedToEditProducts} from '@scm/authentication/utils/authentication';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import Tooltip from '@sabre/spark-react-core/tooltip';
import {ProductIconContext} from '../../../../forms/ProductIcon';
import ToggleButton from '../toggle-button/ToggleButton';
import useId from '@scm/components/accessibility/hooks/useId';
import useGetIsObsolete from '../../../../hooks/useGetIsObsolete';

const disabledClickEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  e.preventDefault();
  e.stopPropagation();
};

const UploadIcon = () => {
  const isAllowedToEdit = isAllowedToEditProducts();
  const isObsolete = useGetIsObsolete();
  const {icon, setIcon, setIconFile} = useContext(ProductIconContext);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputReset = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
    ((event.target as HTMLInputElement).value = '');

  const handleChangeAppIcon = () => inputRef.current?.click();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const fileObj = e.target.files;
    if (fileObj) {
      const iconFile = fileObj[0];
      const productIconUrl = URL.createObjectURL(iconFile);
      setIcon(productIconUrl);
      setIconFile(iconFile);
    }
  };

  const uploadTooltipId = useId();

  return isAllowedToEdit ? (
    <>
      <LogoUploadInput
        accept=".gif,.jpg,.jpeg,.png,.svg,.webp"
        ref={inputRef}
        type="file"
        onClick={handleInputReset}
        onChange={handleFileChange}
        aria-describedby={uploadTooltipId}
      />
      {!isObsolete && (
        <>
          <ChangeLogo
            onClick={handleChangeAppIcon}
            role="button"
            tabIndex={0}
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                handleChangeAppIcon();
              }
            }}
          >
            {!icon ? (
              <FormattedMessage id="productDetails.icon.uploadProductIcon" />
            ) : (
              <FormattedMessage id="productDetails.icon.changeProductIcon" />
            )}
          </ChangeLogo>
          <TooltipWrapper onClick={disabledClickEvent}>
            <SmallTooltip
              anchorX={TooltipDirectionX.RIGHT}
              anchorY={TooltipDirectionY.MIDDLE}
              toggleEl={<ToggleButton />}
            >
              <div className="spark-line-height-1 spark-pad-.5" role="tooltip" id={uploadTooltipId}>
                <FormattedMessage
                  id="productDetails.icon.info"
                  values={{
                    strong: (link: string) => <strong>{link}</strong>,
                    line: (link: string) => <li>{link}</li>,
                  }}
                />
              </div>
            </SmallTooltip>
          </TooltipWrapper>
        </>
      )}
    </>
  ) : null;
};

const ChangeLogo = styled.div`
  padding: 0.5rem;
  color: ${colors.sparkBlue} !important;
  cursor: pointer;
  display: inline-flex;
`;

const LogoUploadInput = styled.input`
  display: none;
`;

const TooltipWrapper = styled.div`
  display: inline-flex;
`;

const SmallTooltip = styled(Tooltip)`
  white-space: break-spaces !important;
  line-height: 170%;
`;

export default UploadIcon;
