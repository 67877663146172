import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import colors from '@scm/components/assets/colors';
import {Button} from '@sabre/spark-react-core';
import {Configuration, NotificationsSettings, NotificationsSettingsApi} from '../../../generated/user';
import {userApiBaseLink} from '../../../assets/apiLink';
import {getAccessToken} from '../../../utils/authentication';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {openToast} from '@scm/components/messaging/openToast';
import NotificationSettingsGroup from './NotificationSettingsGroup';
import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import {Loading} from '@scm/components';
import {HeaderText} from '@scm/admin-centre/src/components/App';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import color from '@scm/components/assets/colors';

const fetchNotificationsSettings = async () =>
  new NotificationsSettingsApi(
    new Configuration({basePath: userApiBaseLink, accessToken: getAccessToken(), middleware: middleware})
  ).getMyNotificationsSettings();

const updateNotificationsSettings = async (notificationsSettings: NotificationsSettings) =>
  new NotificationsSettingsApi(
    new Configuration({basePath: userApiBaseLink, accessToken: getAccessToken(), middleware: middleware})
  ).updateMyNotificationsSettings({
    notificationsSettings: notificationsSettings,
  });

const initialSettings: NotificationsSettings = {
  certificationFailed: {
    email: false,
    push: false,
  },
  proposalAccepted: {
    email: false,
    push: false,
  },
  proposalRejected: {
    email: false,
    push: false,
  },
  proposalSubmitted: {
    email: false,
    push: false,
  },
  readyForTestCert: {
    email: false,
    push: false,
  },
  readyForTestProd: {
    email: false,
    push: false,
  },
  validationFailed: {
    email: false,
    push: false,
  },
  cleanup: {
    email: false,
    push: false,
  },
};

const NotificationSettings = () => {
  const {formatMessage} = useIntl();

  const [notificationsSettings, setNotificationsSettings] = useState<NotificationsSettings>(initialSettings);
  const [updatedNotificationsSettings, setUpdatedNotificationsSettings] =
    useState<NotificationsSettings>(initialSettings);
  const [areButtonsEnabled, setAreButtonsEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchNotificationsSettings()
      .then(response => {
        setNotificationsSettings(response);
        setUpdatedNotificationsSettings(response);
      })
      .catch(function () {
        //do nothing
      })
      .finally(() => setIsLoading(false));
  }, []);

  const cancelHandler = () => {
    setUpdatedNotificationsSettings(notificationsSettings);
    setAreButtonsEnabled(false);
  };

  const saveHandler = () => {
    updateNotificationsSettings(updatedNotificationsSettings)
      .then(() => {
        openToast(
          formatMessage({id: 'notificationSettings.save.title'}),
          formatMessage({id: 'notificationSettings.save.success.description'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
        setNotificationsSettings(updatedNotificationsSettings);
      })
      .catch(error => {
        console.log(error);
        openToast(
          formatMessage({id: 'notificationSettings.save.title'}),
          formatMessage({id: 'notificationSettings.save.failure.description'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
        setUpdatedNotificationsSettings(notificationsSettings);
      });
    setAreButtonsEnabled(false);
    gaEvent('Change Notification Settings');
  };

  const switchToggleHandler = (notificationName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = event.target;
    setUpdatedNotificationsSettings(prevState => ({
      ...prevState,
      [notificationName]: {
        ...prevState[notificationName as keyof NotificationsSettings],
        email: checked,
      },
    }));

    const isSwitchToggleChangedFromLastlySaved =
      checked !== notificationsSettings[notificationName as keyof NotificationsSettings]?.email;
    setAreButtonsEnabled(isSwitchToggleChangedFromLastlySaved);
  };

  return isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <div className="spark-pad-2">
      <HeaderContainer className="spark-flex spark-pad-r-1">
        <HeaderText className="spark-mar-b-2">
          <FormattedMessage id="notificationSettings.title" />
        </HeaderText>
      </HeaderContainer>
      <NotificationSettingsContainer className="spark-panel spark-pad-l-2">
        <NotificationSettingsGroup
          isProposals={true}
          onSwitchToggle={switchToggleHandler}
          updatedNotificationsSettings={updatedNotificationsSettings}
        />
        <Separator />

        <NotificationSettingsGroup
          isProposals={false}
          onSwitchToggle={switchToggleHandler}
          updatedNotificationsSettings={updatedNotificationsSettings}
        />
        <Separator />

        <ButtonContainer>
          <ResetButton
            className="spark-mar-r-1"
            secondary
            type="button"
            disabled={!areButtonsEnabled}
            onClick={cancelHandler}
            size={ButtonSize.SMALL}
          >
            <FormattedMessage id="common.reset" />
          </ResetButton>
          <SaveButton
            disabled={!areButtonsEnabled}
            className="spark-btn-group-primary"
            onClick={saveHandler}
            type="button"
            size={ButtonSize.SMALL}
          >
            <FormattedMessage id="common.save" />
          </SaveButton>
        </ButtonContainer>
      </NotificationSettingsContainer>
    </div>
  );
};

const NotificationSettingsContainer = styled.div`
  min-height: 50vh;
`;

const HeaderContainer = styled.div`
  justify-content: space-between;
  @media (max-width: ${deviceWidths.md}px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const Separator = styled.p`
  border-bottom: 1px solid ${colors.grey150};
  margin-right: 1rem;
  padding-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
  margin-right: 2rem;
  margin-bottom: 2rem;
`;

const SaveButton = styled(Button)<{disabled?: boolean}>`
  color: ${({disabled}) => (disabled ? color.statusInfoGrey : color.white)} !important;
`;

const ResetButton = styled(Button)<{disabled?: boolean}>`
  color: ${({disabled}) => (disabled ? color.statusInfoGrey : color.sparkGreen)} !important;
`;

export default NotificationSettings;
