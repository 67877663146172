const termsAndConditions = {
  'termsAndCondition.title': '<strong>GENERAL TERMS & CONDITIONS - SABRE PRODUCT PRICING</strong>',
  'termsAndCondition.firstParagraph':
    'In the event of any conflict between these General Terms & Conditions and the Specific Terms & Conditions, the Specific Terms & Conditions shall prevail. In the event of any conflict between these General Terms & Conditions and the express terms of your Sabre Agreement, the express terms of your Sabre Agreement shall prevail in relation to the subject matter hereof. References to “User” shall mean you.',
  'termsAndCondition.secondParagraph':
    '<strong>1. LICENSE GRANT.</strong> All Sabre Products and Sabre Services are subject to license on a limited, non-exclusive, non-sub-licensable and non-transferable license basis to access and/or use the Sabre Products (subject to User’s acceptance of the terms and conditions of any Sabre Agreement associated with the relevant Sabre Products) and only in conjunction with User’s access to and use of the Sabre System. Any other use of the Sabre Products is not permitted without the express written approval of Sabre Group.',
  'termsAndCondition.thirdParagraph':
    "<strong>2. RESTRICTIONS.</strong> User acknowledges that the Sabre Products, and their pricing, structure, organization, and source code constitute valuable trade secrets of Sabre GLBL Inc. and its affiliated group of companies (“Sabre Group”). User agrees, without the express written consent of Sabre Group, not to: (i) copy, modify, adapt, alter, translate, or create derivative works from the Sabre Products; (ii) distribute, sublicense, lease, rent, or loan the Sabre Products to any third party; (iii) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code for the Sabre Products; (iv) export or re-export the Sabre Products without all required United States and foreign government licenses; or (v) use the Sabre Products except as expressly allowed herein or in any manner that is to Sabre Group's detriment or commercial disadvantage.",
  'termsAndCondition.fourthParagraph':
    '<strong>3. USER OBLIGATIONS.</strong> User shall take all necessary steps to prevent improper or unauthorized use of, or access to the Sabre Products and any related documentation outlined in section 2 above. User shall not utilize the Sabre Products with any other GDS without Sabre Group’s express written consent and will not knowingly enable its project utilizing the Sabre Products (“Project”) to interface with or pass Sabre System data to or through any application not authorized by Sabre Group. Notwithstanding the foregoing, User’s Project(s) may interface with broadly available, off-the-shelf, standard business applications, such as spreadsheet, word processing and database software, but may not interface with any application created specifically for the travel industry such as content aggregator software without Sabre Group’s express written consent. User shall be responsible for conducting reasonable inquiry to determine whether a third-party application with which User desires a Project to interface is or is not authorized by Sabre Group.',
  'termsAndCondition.fifthParagraph':
    "<strong>4. MODIFICATIONS AND CHARGES.</strong> Sabre Products are subject to Charges and Sabre may modify the Charges payable, or the description of any Sabre Products upon thirty (30) days' notice to User. This includes the right to introduce fees for new or existing Sabre Products and to change the method by which fees are charged. User agrees that any upfront fees or other one-time license fees paid by User for use of any Sabre Product shall be fully earned and non-refundable once paid. Charges payable by User exclude applicable taxes and similar assessments. User shall be responsible for any applicable taxes and similar assessments on the Charges, other than any taxes imposed on Sabre's income.",
  'termsAndCondition.sixthParagraph':
    'After your acceptance of these terms and conditions in the manner provided on the website, User will be directed to the Sabre Product Pricing page (“Pricing Page”), the contents therein are confidential and expressly subject to the confidentiality terms and conditions herein. The Pricing Page and its contents are solely for the User’s viewing and evaluation purposes only, whether to subscribe to the Sabre Products and other relevant arrangements with Sabre. User shall, not in any manner, make any copies or recordings, whether visual, audio, electronic or in writing (in any format whatsoever) of the Pricing Page or any part thereof and in particular shall not share nor disseminate the aforesaid to any third party not authorized to receive the same.',
  'termsAndCondition.seventhParagraph':
    '<strong>5. DISCLAIMERS.</strong> UNLESS OTHERWISE AGREED BY SABRE GROUP IN WRITING, ALL SABRE PRODUCTS AND CONFIDENTIAL INFORMATION ARE PROVIDED ‘AS IS’ AND WITH ALL FAULTS. SABRE GROUP AND ITS AFFILIATES, DISTRIBUTORS AND LICENSORS MAKE NO WARRANTIES WHATSOEVER, EXPRESS OR IMPLIED, IN RESPECT OF THE SABRE PRODUCTS, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF COMPLETENESS, ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR AGAINST INFRINGEMENT OF THESE IMPLIED WARRANTIES ARISING OUT OF COURSE OF PERFORMANCE, COURSE OF DEALING, USAGE AND TRADE OR ANY OTHER WARRANTY. IN NO EVENT SHALL SABRE GROUP OR ITS AFFILIATES, DISTRIBUTORS OR LICENSORS BE LIABLE FOR ANY LOSS, DAMAGE, COST, CLAIM OR EXPENSE ARISING OUT OF USER’S USE OF THE SABRE PRODUCTS.',
  'termsAndCondition.eighthParagraph':
    '<strong>6. IP RIGHTS.</strong> The Sabre Products (including any enhancements, improvements or other modifications thereto) and related documentation, if any, remain exclusively at all times the intellectual property of Sabre Group, its affiliates or licensors, as applicable, and these terms and conditions do not confer any right, title or interest in such Sabre Products to User. User agrees that certain Sabre Products may contain third party intellectual property whereby use may be subject to certain additional terms and conditions which User shall adhere to.',
  'termsAndCondition.ninthParagraph':
    '<strong>7. CONFIDENTIALITY.</strong> The Sabre Products (and source code), prices, and related documentation are Sabre Group’s confidential information and shall not be used or disclosed by User except in accordance with the terms and conditions hereof (“Confidential Information”). In the absence of any such terms, User hereby agrees the Confidential Information shall not be disclosed to any third party except to the extent disclosure is compelled under applicable law, in which case, prior to such disclosure, User shall notify Sabre Group in writing of such requirement so that Sabre Group can seek a protective order or other remedy. The Confidential Information disclosed is described generally as any and all current and future product roadmap information, marketing plans, financial information, customer and vendor related data, services and support, procedures, and all other business information including, but not limited to software, strategies, plans, documents, techniques, drawings, designs, specifications, technical or know-how data, research and development, ideas, inventions, Trade Secrets and patent disclosures, that may be disclosed between the parties, whether in written, oral, electronic, website-based, or other form, provided that (a) it is marked or accompanied by documents clearly and conspicuously designating it as “CONFIDENTIAL”, “Trade Secret” or the equivalent; or (b) it is identified by Sabre Group as confidential before, during or promptly after the presentation or communication. "Trade Secret" means information identified as such, used in Sabre Group’s business and is a formula, pattern, compilation, program, device, method, technique, or process that gives the owner a competitive advantage over persons who do not know or use it, and is the subject of reasonable efforts to maintain its secrecy.',
  'termsAndCondition.tenthParagraph':
    "User will use the same degree of care as User uses to protect its own information of a like nature, but not less than reasonable care, to prevent (a) any use of the Confidential Information not authorized herein, (b) communication of the Confidential Information to any third party, or (c) publication of the Confidential Information. User may permit access to the Confidential Information obtained hereunder only to its and its subsidiaries' and affiliates' directors, employees, contractors and advisors who need to know the Confidential Information in connection with the purpose for which it was disclosed hereunder and who are under an obligation of confidentiality at least as stringent as that set forth herein . Notwithstanding the foregoing, User will at all times remain responsible and liable for acts and omissions of any such persons with respect to the Confidential Information. User acknowledges that damages for improper disclosure of  the Information may be irreparable; therefore, Sabre Group may be entitled to seek equitable relief, including injunction and preliminary injunction, without the necessity of posting a bond, in addition to all other remedies available at law or in equity.",
  'termsAndCondition.eleventhParagraph':
    'The obligations and duties imposed by these terms and condition with respect to any Confidential Information may be enforced only by Sabre Group of such Confidential Information against any and all recipients of such Confidential Information. These obligations of confidentiality shall remain in force and effect for a period of two (2) years from the date of User’s acceptance.',
  'termsAndCondition.twelfthParagraph':
    '<strong>8. INDEMNIFICATION.</strong> User will defend, indemnify and hold harmless Sabre Group, its affiliates, distributors and licensors and their respective officers, directors, agents and employees, against all third party claims, costs, fines and expenses, including legal fees, resulting from the breach of any term or condition hereof including any unauthorized or improper use, misuse or abuse of the Sabre Product and Sabre Services.',
  'termsAndCondition.thirteenthParagraph':
    '<strong>9. ELECTRONIC ACCEPTANCE.</strong> Checking “I Accept the Terms  and Conditions” and clicking “CONTINUE” constitutes your express acceptance of these terms and conditions, the applicable Sabre Website Terms and Conditions and the Sabre Privacy Policy. You also specifically and expressly consent to our website tracking, logging and recording methods, including cookies as well safe and secure collection, processing and transmission of your personal information related to the access and/or use of the Sabre Products. These terms and conditions may be accepted in electronic form (e.g., by an electronic or other means of demonstrating assent) and your acceptance will be deemed binding between the parties. Neither party may contest the validity or enforceability of these terms and conditions, because it was accepted or signed in electronic form. Electronically maintained records when produced in hard copy form shall constitute business records and shall have the same validity as any other generally recognized business records.',
  'termsAndCondition.fourteenthParagraph':
    '<strong>10. ASSIGNMENT.</strong> These terms and conditions and any obligation or right contained herein shall not be assigned or transferred by User without the prior written consent of Sabre Group.',
};

export default termsAndConditions;
