import React from 'react';
import {UseFormWatch} from 'react-hook-form';
import {Table, TableHeader} from '@scm/product-components/pages/productDetails/productTabs/bundles/BundlesTable';
import TesterTableRow from './TesterTableRow';
import {
  DeleteTesterHandlerType,
  Tester,
} from '@scm/product-components/pages/productDetails/productTabs/testers/ProdTesters';
import {editedTester, isTesterAdding, testersTable, TestersValues} from './TestersValues';

const ProdTestersTable = ({
  headers,
  watch,
  deleteTestersHandler,
  currentPaginationPage,
  paginationInterval = 10,
}: {
  headers: Array<string>;
  watch: UseFormWatch<TestersValues>;
  deleteTestersHandler?: DeleteTesterHandlerType;
  currentPaginationPage?: number;
  paginationInterval?: number;
}) => {
  const generateHeaders = () => (
    <thead>
      <tr>
        {headers.map(
          (name, index, headers) =>
            name !== '' && (
              <TableHeader
                key={name}
                colSpan={headers[index + 1] === '' ? 2 : 1}
                scope={headers[index + 1] === '' ? 'colgroup' : ''}
              >
                {name}
              </TableHeader>
            )
        )}
      </tr>
    </thead>
  );

  const generateBody = () => {
    let content: Array<JSX.Element> = watch(testersTable)
      .slice(
        (currentPaginationPage ?? 0) * paginationInterval,
        (currentPaginationPage ?? 0) * paginationInterval + paginationInterval
      )
      .map((dataItem: Tester) => (
        <TesterTableRow
          key={dataItem.nr}
          tester={dataItem}
          deleteTestersHandler={deleteTestersHandler as (nr: string) => void}
        />
      ));
    if (watch(isTesterAdding ?? '')) {
      content.push(<TesterTableRow key={watch(editedTester)?.nr} tester={watch(editedTester) as Tester} isEditedRow />);
    }
    return <tbody>{content}</tbody>;
  };

  return (
    <Table className="spark-table" role="presentation">
      <div className="spark-table__scroll">
        <table role="grid" className="spark-mar-b-1">
          {generateHeaders()}
          {generateBody()}
        </table>
      </div>
    </Table>
  );
};

export default ProdTestersTable;
