import React from 'react';
import ProposalTypePage from './ProposalTypePage';
import InsurancePage from './steps/InsurancePage';
import DefinitionPage from './steps/definitionPage/DefinitionPage';
import RegionalizationPage from './steps/regionalization/RegionalizationPage';
import PricingPage from './steps/PricingPage';
import ServicesAndSecurityPage from './steps/serviceAndSecurity/ServicesAndSecurityPage';
import ReviewPage from './steps/reviewPage/ReviewPage';
import {ScribeScriptsDefinitionPage} from '@scm/scribe-scripts';
import {PfKeysDefinitionPage} from '@scm/pf-keys';
import SecurityPage from './steps/serviceAndSecurity/SecurityPage';

export enum ProposalType {
  'Public Red App' = 'Public Red App',
  'Private Red App' = 'Private Red App',
  'PF Keys' = 'PF Keys',
  'Scribe Scripts' = 'Scribe Scripts',
}

export const proposalTypeMap = {
  [ProposalType['Public Red App']]: {
    pages: [
      <InsurancePage />,
      <DefinitionPage />,
      <RegionalizationPage />,
      <PricingPage />,
      <ServicesAndSecurityPage />,
      <ReviewPage />,
    ],
    steps: [
      {value: 1, labelId: 'proposal.proposalType'},
      {value: 2, labelId: 'proposal.insurance'},
      {value: 3, labelId: 'proposal.definition'},
      {value: 4, labelId: 'proposal.regionalization'},
      {value: 5, labelId: 'proposal.pricing'},
      {value: 6, labelId: 'proposal.servicesAndSecurity'},
      {value: 7, labelId: 'proposal.review'},
    ],
  },
  [ProposalType['Private Red App']]: {
    pages: [<DefinitionPage />, <SecurityPage />, <ReviewPage />],
    steps: [
      {value: 1, labelId: 'proposal.proposalType'},
      {value: 2, labelId: 'proposal.definition'},
      {value: 3, labelId: 'proposal.security'},
      {value: 4, labelId: 'proposal.review'},
    ],
  },
  [ProposalType['PF Keys']]: {
    pages: [<PfKeysDefinitionPage />],
    steps: [
      {value: 1, labelId: 'proposal.proposalType'},
      {value: 2, labelId: 'proposal.definition'},
    ],
  },
  [ProposalType['Scribe Scripts']]: {
    pages: [<ScribeScriptsDefinitionPage />],
    steps: [
      {value: 1, labelId: 'proposal.proposalType'},
      {value: 2, labelId: 'proposal.definition'},
    ],
  },
};

const PagesMap = ({proposalType, currentStep}: {proposalType?: ProposalType; currentStep: number}) => {
  return proposalType && currentStep - 1 ? proposalTypeMap[proposalType].pages[currentStep - 2] : <ProposalTypePage />;
};

export default PagesMap;
