const common = {
  'common.carousel.pauseCarousel': 'Pause Carousel',
  'common.carousel.previousImage': 'Previous Image',
  'common.carousel.nextImage': 'Next Image',
  'common.carousel.moveBack': 'Move carousel back',
  'common.carousel.moveForward': 'Move carousel forward',
  'common.data.loading': 'Loading...',
  'common.data.fetchingFilters': 'Fetching filters...',
  'common.data.fetchingPartners': 'Fetching partners...',
  'common.data.fetchingPartnerDetails': 'Fetching partner details...',
  'common.breadcrumb.home': 'Home',
  'common.breadcrumb.partnerDirectory': 'Developer Partner Directory',
  'common.breadcrumb.benefits': 'Developer Partner Benefits',
  'common.breadcrumb.tiers': 'Partner Tiers',
  'common.breadcrumb.joinUs': 'Join Us',
  'common.button.close': 'Close',
  'common.sabre': 'Sabre',
  'common.accept': 'Accept',
  'common.new': 'New',
  'common.cookiesConsent':
    'We use cookies to improve performance and user experience of our website. Continue browsing as normal or click “Accept” to consent to the storage of cookies on your device. To learn more about the cookies we use, please visit our <externalLink>privacy policy</externalLink>.',
};

export default common;
