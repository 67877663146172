import React, {SetStateAction, useMemo, useState} from 'react';
import {Message, SelectInput} from '@sabre/spark-react-core';
import Tooltip from '@sabre/spark-react-core/tooltip';
import {FormattedMessage, useIntl} from 'react-intl';
import InlineButton from '@scm/admin-centre/src/components/InlineButton';
import {ButtonLine} from '@scm/product-components/pages/productDetails/productTabs/proposalData/ProposalData';
import {useForm} from 'react-hook-form';
import {versionStatuses} from '@scm/admin-centre/src/utils/statuses';
import {MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import {Row} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {
  Configuration,
  Environment,
  ProductsApi,
  StatusFromJSON,
  VersionResponse,
  VersionsApi,
} from '../../../generated/certification';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken, isCertificationEngineerRole, isSabreAdminRole} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import styled from 'styled-components';
import {ButtonsContainer} from './Certification';
import {useParams} from 'react-router-dom';
import RollbackVersionModal from './RollbackVersionModal';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

enum FormAction {
  'SetStatus' = 'SetStatus',
  'SetStatusAndTrigger' = 'SetStatusAndTrigger',
}

interface MaintenanceValues {
  versionStatus: string;
  action?: FormAction;
}

const fetchVersions = async (sku: string) =>
  new ProductsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).getVersions({id: sku});

const downloadCmConfigFile = async (sku: string, currentVersion: string, env: Environment) => {
  const xmlContent = await getCmConfig(sku, currentVersion, env);

  const xmlDoc = new DOMParser().parseFromString(xmlContent, 'application/xml');
  const xsltDoc = new DOMParser().parseFromString(
    [
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">',
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>',
    ].join('\n'),
    'application/xml'
  );

  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  const resultXml = new XMLSerializer().serializeToString(resultDoc);

  const element = document.createElement('a');
  const file = new Blob([resultXml], {type: 'application/xml'});
  element.href = URL.createObjectURL(file);
  element.download = sku + '-cmConfig-' + env.toLowerCase() + '.xml';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

function getCmConfig(sku: string, currentVersion: string, env: Environment) {
  return fetchVersions(sku).then(response => {
    const versionId = getVersionId(response, currentVersion);
    return new VersionsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).getCmConfig({id: versionId as string, env: env});
  });
}

function getVersionId(response: Array<VersionResponse>, currentVersion: string) {
  return response
    .filter(versionResponse => versionResponse.name === currentVersion)
    .map(versionResponse => versionResponse.id)[0];
}

const updateStatus = (sku: string, currentVersion: string, statusAsString: string) =>
  fetchVersions(sku).then(response => {
    const versionId = getVersionId(response, currentVersion);
    return new VersionsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).patchVersionStatus({id: versionId as string, statusObject: {status: StatusFromJSON(statusAsString)}});
  });

const reassignBetaTesters = (sku: string, currentVersion: string) =>
  fetchVersions(sku).then(response => {
    const versionId = getVersionId(response, currentVersion);
    return new VersionsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).reassignBetaTesters({id: versionId ?? ''});
  });

const updateVersionAssignments = (sku: string, currentVersion: string) =>
  fetchVersions(sku).then(response => {
    const versionId = getVersionId(response, currentVersion);
    return new VersionsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).updateVersionAssignments({id: versionId ?? '', sku: sku});
  });

const regenerateCompositeFiles = (sku: string, currentVersion: string, env: Environment) =>
  new ProductsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).regenerateCompositeFiles({
    sku,
    regenerateCompositeFilesRequest: {versionName: currentVersion, env},
  });

const getVersionNameFromPath = () => {
  const path = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
  return path ? path.slice(path.lastIndexOf('/') + 1) : '';
};

const Maintenance = ({
  setLoadingProductDetails,
  status,
  currentVersionId,
  isVersionPatch,
  setLoading,
}: {
  setLoadingProductDetails: React.Dispatch<SetStateAction<boolean>>;
  status: string;
  currentVersionId: string;
  isVersionPatch: boolean;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const {appName = ''} = useParams();
  const reassignBetaTestersName = 'certify.maintenance.reassignBetaTesters';
  const updateVersionAssignmentsName = 'certify.maintenance.updateVersionAssignments';
  const maintenanceGenericName = 'certify.maintenance.generic';

  const currentVersion = useMemo<string>(getVersionNameFromPath, [location.pathname]);

  const initialValues: MaintenanceValues = {
    versionStatus: status,
  };

  const {formatMessage} = useIntl();
  const versionStatusName = 'versionStatus';

  const {setValue, watch} = useForm<MaintenanceValues>({
    defaultValues: initialValues,
  });

  const disableReassignBetaTesters = status !== 'Ready for Test (PROD)';
  const disableUpdateVersionAssignments = status !== 'Published';
  const disableRollbackVersion = !(status === 'Published' && isVersionPatch);

  const [openRollbackVersionModal, setOpenRollbackVersionModal] = useState(false);

  const statusOption = useMemo(
    () => Array.from(versionStatuses.keys()).map(status => ({label: status, value: status})),
    []
  );

  const clickHandlerSetStatusAction = () =>
    updateStatus(appName, currentVersion, watch(versionStatusName))
      .catch(() =>
        openToast(
          createMessageString(formatMessage, maintenanceGenericName, true, true),
          createMessageString(formatMessage, reassignBetaTestersName, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      )
      .finally(() => setLoadingProductDetails(true));

  const clickHandlerReassignBetaTesters = () => {
    reassignBetaTesters(appName, currentVersion)
      .then(() =>
        openToast(
          createMessageString(formatMessage, maintenanceGenericName, true),
          createMessageString(formatMessage, reassignBetaTestersName),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(() =>
        openToast(
          createMessageString(formatMessage, maintenanceGenericName, true, true),
          createMessageString(formatMessage, reassignBetaTestersName, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      );
  };

  const clickHandlerUpdateVersionAssignments = () => {
    updateVersionAssignments(appName, currentVersion)
      .then(() =>
        openToast(
          createMessageString(formatMessage, maintenanceGenericName, true),
          createMessageString(formatMessage, updateVersionAssignmentsName),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(() =>
        openToast(
          createMessageString(formatMessage, maintenanceGenericName, true, true),
          createMessageString(formatMessage, updateVersionAssignmentsName, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      );
  };

  const clickHandlerGetCmConfigCert = () =>
    downloadCmConfigFile(appName, currentVersion, Environment.Cert).catch(() =>
      openToast(
        createMessageString(formatMessage, maintenanceGenericName, true, true),
        formatMessage({id: 'certify.maintenance.getCmConfig.error'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      )
    );

  const clickHandlerGetCmConfigProd = () =>
    downloadCmConfigFile(appName, currentVersion, Environment.Prod).catch(() =>
      openToast(
        createMessageString(formatMessage, maintenanceGenericName, true, true),
        formatMessage({id: 'certify.maintenance.getCmConfig.error'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      )
    );

  const clickHandlerRegenerateCompositeFiles = (env: Environment) =>
    regenerateCompositeFiles(appName, currentVersion, env)
      .then(() =>
        openToast(
          createMessageString(formatMessage, maintenanceGenericName, true, false),
          formatMessage({id: 'certify.maintenance.regenerateCompositeFiles.success'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(() =>
        openToast(
          createMessageString(formatMessage, maintenanceGenericName, true, true),
          formatMessage({id: 'certify.maintenance.regenerateCompositeFiles.error'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      );

  return (
    <>
      <Message
        content=""
        role={MessageRole.ALERT}
        status={MessageStatus.WARNING}
        title={formatMessage({id: 'certify.maintenance.message.title'})}
        type={MessageType.CONTAINER}
        className="spark-mar-l-2 spark-pad-r-2"
      />
      <ButtonsContainer>
        <Row>
          <ButtonLine className="spark-mar-l-2 spark-mar-t-2 spark-mar-b-2 spark-pad-b-1 button-col">
            <InlineButton
              id="certify.maintenance.actionButton.getCmConfigCert"
              clickHandler={clickHandlerGetCmConfigCert}
              type="submit"
              className="button-width neutral spark-mar-r-1 spark-mar-b-1"
            />
            <InlineButton
              id="certify.maintenance.actionButton.getCmConfigProd"
              clickHandler={clickHandlerGetCmConfigProd}
              type="submit"
              className="button-width neutral spark-mar-r-1 spark-mar-b-1"
            />
            {isSabreAdminRole() && (
              <>
                <InlineButton
                  id="certify.maintenance.actionButton.certRegenerateCompositeFiles"
                  clickHandler={() => clickHandlerRegenerateCompositeFiles(Environment.Cert)}
                  type="button"
                  className="button-width neutral spark-mar-r-1 spark-mar-b-1"
                />
                <InlineButton
                  id="certify.maintenance.actionButton.prodRegenerateCompositeFiles"
                  clickHandler={() => clickHandlerRegenerateCompositeFiles(Environment.Prod)}
                  type="button"
                  className="button-width neutral spark-mar-r-1 spark-mar-b-1"
                />
              </>
            )}
            {isCertificationEngineerRole() &&
              (disableReassignBetaTesters ? (
                <Tooltip
                  toggleEl={
                    <NonePointerEvents>
                      <InlineButton
                        id="certify.maintenance.actionButton.reassign"
                        clickHandler={clickHandlerReassignBetaTesters}
                        type="button"
                        className="button-width neutral spark-mar-r-1 spark-mar-b-1"
                        disabled={disableReassignBetaTesters}
                      />
                    </NonePointerEvents>
                  }
                >
                  <FormattedMessage id="certify.maintenance.actionButton.reassign.info" />
                </Tooltip>
              ) : (
                <InlineButton
                  id="certify.maintenance.actionButton.reassign"
                  clickHandler={clickHandlerReassignBetaTesters}
                  type="button"
                  className="button-width neutral spark-mar-r-1 spark-mar-b-1"
                />
              ))}
            {isSabreAdminRole() &&
              (disableUpdateVersionAssignments ? (
                <Tooltip
                  toggleEl={
                    <NonePointerEvents>
                      <InlineButton
                        id="certify.maintenance.actionButton.versionAssigment"
                        clickHandler={clickHandlerUpdateVersionAssignments}
                        type="submit"
                        className="button-width neutral spark-mar-r-1 spark-mar-b-1"
                      />
                    </NonePointerEvents>
                  }
                >
                  <FormattedMessage id="certify.maintenance.actionButton.versionAssigment.info" />
                </Tooltip>
              ) : (
                <InlineButton
                  id="certify.maintenance.actionButton.versionAssigment"
                  clickHandler={clickHandlerUpdateVersionAssignments}
                  type="submit"
                  className="button-width neutral spark-mar-r-1 spark-mar-b-1"
                />
              ))}
            {disableRollbackVersion ? (
              <Tooltip
                toggleEl={
                  <NonePointerEvents>
                    <InlineButton
                      id="certify.maintenance.actionButton.rollback"
                      clickHandler={() => {}}
                      type="button"
                      className="button-width error spark-mar-r-1 spark-mar-b-1"
                      disabled
                    />
                  </NonePointerEvents>
                }
              >
                <FormattedMessage id="certify.maintenance.rollback.tooltip" />
              </Tooltip>
            ) : (
              <>
                <InlineButton
                  id="certify.maintenance.actionButton.rollback"
                  clickHandler={() => setOpenRollbackVersionModal(true)}
                  type="button"
                  className="button-width error spark-mar-r-1 spark-mar-b-1"
                  disabled={!isCertificationEngineerRole()}
                />
              </>
            )}
            <RollbackVersionModal
              open={openRollbackVersionModal}
              setOpen={setOpenRollbackVersionModal}
              versionId={currentVersionId}
              setLoading={setLoading}
              setLoadingProductDetails={setLoadingProductDetails}
            />
          </ButtonLine>
        </Row>
        {isCertificationEngineerRole() && (
          <>
            <Row className="spark-mar-b-2 spark-mar-l-2 spark-flex spark-align-items-center">
              <SelectInput
                name={versionStatusName}
                value={watch(versionStatusName)}
                onChange={(evt, val) => setValue(versionStatusName, val)}
                label={formatMessage({id: 'certify.maintenance.status.label'})}
                options={statusOption}
                className="spark-mar-b-0 col-xs-6"
              />
              <ButtonLine className="spark-mar-l-2 spark-mar-t-2 park-btn-group spark-btn-group--left spark-mar-b-2">
                <InlineButton
                  id="certify.maintenance.setStatus.label"
                  clickHandler={clickHandlerSetStatusAction}
                  type="button"
                  className="neutral spark-mar-r-1"
                />
              </ButtonLine>
            </Row>
          </>
        )}
      </ButtonsContainer>
    </>
  );
};

const NonePointerEvents = styled.div`
  pointer-events: none;
`;

export default Maintenance;
