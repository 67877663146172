import {SchemaOf} from 'yup';
import {useCallback} from 'react';
import {SchemaObjectDescription} from 'yup/es/schema';
import {useIntl} from 'react-intl';

const OnboardingYupValidationResolver = (validationSchema: SchemaOf<unknown>) => {
  const {formatMessage} = useIntl();
  return useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });
        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: Array<unknown>, currentError: {path: any; type: any; message: {key: any; value: any}}) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message.key
                  ? formatMessage(
                      {
                        id: `${currentError.message.key}.${currentError.path}`,
                        defaultMessage: formatMessage(
                          {
                            id: currentError.message.key,
                            defaultMessage: formatMessage({id: 'validation.fieldInvalid'}),
                          },
                          {value: currentError.message.value}
                        ),
                      },
                      {value: currentError.message.value}
                    )
                  : currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema, formatMessage]
  );
};

interface ProvidedSchema {
  describe: () => SchemaObjectDescription;
}

export const isFieldRequired = (name: string, providedSchema: ProvidedSchema) =>
  getField(name, providedSchema).tests.findIndex(({name}) => name === 'required') >= 0;

export const getFieldMaxLength: (fieldName: string, providedSchema: ProvidedSchema) => number = (
  fieldName,
  providedSchema
) =>
  getField(fieldName, providedSchema)
    .tests.filter(({name}) => name === 'max')
    .map(({params}) => params?.max)[0] as number;

const getField = (name: string, providedSchema: ProvidedSchema) =>
  name.split('.').reduce((schema, name) => schema?.fields[name] as SchemaObjectDescription, providedSchema?.describe());

export default OnboardingYupValidationResolver;
