import React, {ReactElement, useContext, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContext} from '../../../../ProposalForm';
import {
  dataProtectionName,
  financialInformationName,
  personalInformationName,
  thirdPartyNetworkName,
} from '../../ServiceAndSecurityValues';
import {TextArea} from '../../../definitionPage/definitionComponents/Description';
import {Controller} from 'react-hook-form';
import {Star} from '@scm/components';
import {Modal} from '@sabre/spark-react-core';
import styled from 'styled-components';
import dataProtectionChart from './dataprotectionchart.jpeg';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {serviceAndSecurityValuesName} from '../../../../ProposalValues';
import {createNestedObjectName} from '../servicesComponents/Services';

const DataProtection = () => {
  const {formatMessage} = useIntl();
  const {control, errors, watch} = useContext(ProposalContext);
  const descriptionMaxLength = 500;
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const headingLevel = 3;

  return (
    <>
      {(watch(createNestedObjectName(financialInformationName)) ||
        watch(createNestedObjectName(personalInformationName)) ||
        watch(createNestedObjectName(thirdPartyNetworkName))) && (
        <div>
          <p className="spark-mar-b-.5 spark-mar-r-2 spark-mar-t-2">
            <FormattedMessage id="serviceAndSecurity.dataProtection.mainLabel" />
            <Star hasColon />
            <button
              type="button"
              onClick={handleOpen}
              aria-label={formatMessage({id: 'serviceAndSecurity.dataProtection.buttonLabel'})}
              className="spark-btn--icon spark-icon spark-icon--fill spark-icon-info-circle"
            />
          </p>
          <Controller
            name={createNestedObjectName(dataProtectionName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.dataProtection.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-2 spark-mar-t-1"
                status={errors[serviceAndSecurityValuesName]?.[dataProtectionName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[dataProtectionName] &&
                  errors[serviceAndSecurityValuesName]?.[dataProtectionName]?.message
                }
              />
            )}
          />
        </div>
      )}
      <Modal
        onClose={handleClose}
        open={open}
        title={formatMessage({id: 'serviceAndSecurity.dataProtection.pictureTitle'})}
        titleHeadingLevel={headingLevel}
      >
        <TooltipPicture
          src={dataProtectionChart}
          alt={formatMessage({id: 'serviceAndSecurity.dataProtection.pictureAltText'})}
        />
      </Modal>
    </>
  );
};

const TooltipPicture = styled.img`
  max-width: 100%;
  min-width: 100%;
  max-height: 100%;
  max-height: 100%;
  margin: 1rem;
`;

export default DataProtection;
