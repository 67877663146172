import React from 'react';
import WelcomeItem from 'pages/home/welcome/WelcomeItem';
import PartnerNetworkBanner from 'assets/images/Partner_Network_Banner.jpg';
import MediaQuery from 'react-responsive';
import {useIntl} from 'react-intl';
import {maxMobileWidth, minDesktopWidth} from 'assets/deviceWidth';

const Welcome = () => {
  const {formatMessage} = useIntl();
  const formatBoldMessage = (id: string) =>
    formatMessage({id: id}, {bold: (value: string) => <strong key={value}>{value}</strong>});
  return (
    <>
      <MediaQuery maxDeviceWidth={maxMobileWidth}>
        <WelcomeItem mobileText={formatBoldMessage('homePage.welcome.mobile.text')} />
      </MediaQuery>
      <MediaQuery minDeviceWidth={minDesktopWidth}>
        <WelcomeItem image={PartnerNetworkBanner} />
      </MediaQuery>
    </>
  );
};
export default Welcome;
