import styled from 'styled-components';
import {Icon} from '@sabre/spark-react-core';
import colors from '../assets/colors';
import {useNavigate} from 'react-router';
import InternalUrls from '../utils/adminCentreUtils/internalUrls';
import {FormattedMessage} from 'react-intl';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {useState} from 'react';

const InteractiveMessageBox = ({
  title,
  content,
  icon,
  href,
  setIsOpen,
}: {
  title: string;
  content: string;
  icon: string;
  href?: InternalUrls;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      {isVisible && (
        <InteractiveMessageBoxWrapper
          onClick={() => href && navigate(href)}
          className="col-xs-12 spark-pad-1 spark-pad-r-2 spark-pad-l-2 spark-flex"
        >
          <CloseWrapper
            onClick={event => {
              event.stopPropagation();
              setIsVisible(false);
              setIsOpen(false);
            }}
          >
            <Icon className="spark-pad-.5" size={ButtonSize.EXTRA_SMALL} name="close" />
          </CloseWrapper>
          <IconWrapper className="spark-pad-1" name={icon} />
          <div>
            <span className="spark-bold spark-mar-0">
              <FormattedMessage id={title} />
            </span>
            <span>
              <FormattedMessage id={content} />
            </span>
          </div>
        </InteractiveMessageBoxWrapper>
      )}
    </>
  );
};

const InteractiveMessageBoxWrapper = styled.div`
  background: ${colors.grey50};
  box-shadow: 0 0 3px 0 ${colors.grey700};
  cursor: pointer;
  align-items: center;

  * {
    margin: 0.5rem;
  }

  .spark-bold,
  h6 {
    color: ${colors.neutralBlue} !important;
  }

  &&:hover {
    background: ${colors.grey100};
    transition: 0.1s ease;
  }
`;

const IconWrapper = styled(Icon)`
  background: ${colors.grey700};
  color: ${colors.white};
  border-radius: 50%;
`;

const CloseWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  &&:hover {
    background: ${colors.grey150};
    border-radius: 50%;
  }
`;

export default InteractiveMessageBox;
