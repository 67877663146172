import React from 'react';
import FormattedBoldMessage from 'components/formattedMessages/FormattedBoldMessage';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const NoResultsFound = () => (
  <NoResultsFoundContainer>
    <Title aria-level={2}>
      <FormattedBoldMessage id="partnerDirectoryPage.noResultsFoundTitle" />
    </Title>
    <p>
      <FormattedMessage id="partnerDirectoryPage.noResultsFoundText" />
    </p>
  </NoResultsFoundContainer>
);

const NoResultsFoundContainer = styled.div.attrs(() => ({
  className: 'spark-flex spark-mar-t-1',
}))`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.h5`
  font-weight: 500;
  color: ${colors.grey700};
`;

export default NoResultsFound;
