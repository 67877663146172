import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import ExternalUrls from '@scm/components/utils/externalUrls';
import ExternalLink from 'components/ExternalLink';
import Logo from 'components/Logo';
import colors from '@scm/components/assets/colors';

const ProductCarouselItem = ({
  name,
  sku,
  shortDescription,
  logoUrl,
}: {
  name: string;
  sku: string;
  shortDescription: string;
  logoUrl: string;
}) => (
  <CarouselPanel>
    <LinkWithFocus to={`${ExternalUrls.ProductDetails}?sku=${sku}`} ariaLabel={name}>
      <Content>
        <div className="spark-delta spark-mar-b-1 spark-bold">
          <Logo logo={logoUrl} name={name} />
          {name}
        </div>
        <div>{parse(shortDescription || '')}</div>
      </Content>
    </LinkWithFocus>
  </CarouselPanel>
);

const CarouselPanel = styled.div.attrs(() => ({
  className: 'spark-carousel__panel',
}))`
  height: 24rem;
  width: 26vw;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 0 !important;
  text-align: left;

  overflow-y: hidden;

  :after {
    content: none;
  }
`;

const Content = styled.div`
  color: ${colors.black};
  font-weight: 300;
  height: 100%;
  padding: 2.5rem !important;
`;

const LinkWithFocus = styled(ExternalLink)`
  &:focus > div {
    text-decoration: underline;
  }
`;

export default ProductCarouselItem;
