import Modal from '@sabre/spark-react-core/modal';
import {useIntl} from 'react-intl';
import {VersionResponse} from '../../../../generated/certification';
import {createPortal} from 'react-dom';
import CompatibilityForm from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/CompatibilityForm';
import React, {useContext} from 'react';
import {ProductContext} from '../../../../provider/ProductProvider';
import {VersionContext} from '../../../../provider/VersionProvider';
import {CertificationProductContext} from '../../../../provider/CertificationProductProvider';
import PatchReleaseNotesForm from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/PatchReleaseNotesForm';
import {isAdpRole} from '@scm/authentication/utils/authentication';
import ChangeSimplifiedDescriptionForm from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/ChangeSimplifiedDescriptionForm';
import CleanupDatesModal from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/CleanupDatesModal';
import SvsErrorsModal from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/SvsErrorsModal';
import VersionFilesModal from '@scm/product-components/pages/productDetails/productTabs/bundles/modals/VersionFilesModal';
import AccessibilityProvider from '@scm/components/accessibility/AccessibilityProvider';

enum ProductCompatibilities {
  'Desktop' = 'Desktop',
  'Desktop & Web' = 'Desktop & Web',
  'Web' = 'Web',
}

const Actions = ({
  handleClose,
  open,
  editedBundle,
  editedReleaseNotes,
  editedSimplifiedDescription,
  svsErrors,
  editedCleanupDates,
  versionFilesDetails,
}: {
  handleClose: () => void;
  open: boolean;
  editedBundle?: VersionResponse;
  editedReleaseNotes?: VersionResponse;
  editedSimplifiedDescription?: VersionResponse;
  svsErrors?: VersionResponse;
  editedCleanupDates?: VersionResponse;
  versionFilesDetails?: VersionResponse;
}) => {
  const {formatMessage} = useIntl();
  const {setLoading} = useContext(ProductContext);
  const {setLoading: setVersionLoading} = useContext(VersionContext);
  const {setLoading: setCertificationProductLoading} = useContext(CertificationProductContext);

  const reloadBundles = () => {
    setLoading(true);
    setVersionLoading(true);
    setCertificationProductLoading(true);
  };

  const {certificationProduct} = useContext(CertificationProductContext);
  const {processPiiOrPci} = certificationProduct;

  return (
    <>
      {createPortal(
        <Modal
          onClose={handleClose}
          open={open}
          title={formatMessage(
            {
              id: (() => {
                if (editedBundle) return 'tabs.bundle.compatibilityForm.title';
                else if (editedReleaseNotes) return 'tabs.releaseNotes.patchReleaseNotesForm.title';
                else if (svsErrors) return 'tabs.bundle.svsErrorsModal.title';
                else if (editedCleanupDates) return 'tabs.cleanupDatesModal.title';
                else if (versionFilesDetails) return 'tabs.versionFilesDetails.title';
                else return 'tabs.simplifiedDescription.changeSimplifiedDescriptionForm.title';
              })(),
            },
            {versionId: editedCleanupDates?.name || versionFilesDetails?.name}
          )}
          titleHeadingLevel={2}
        >
          <AccessibilityProvider>
            {(() => {
              if (editedBundle)
                return (
                  <CompatibilityForm
                    bundleId={editedBundle.id || ''}
                    bundleCompatibility={getProductCompatibility(
                      editedBundle.canBeUsedInSabreRedDesktop,
                      editedBundle.canBeUsedInSabreRedWeb
                    )}
                    webCompatibility={!!editedBundle.isWebApp}
                    onClose={handleClose}
                    reloadBundles={reloadBundles}
                  />
                );
              else if (editedReleaseNotes)
                return (
                  <PatchReleaseNotesForm
                    versionId={editedReleaseNotes.id || ''}
                    initialReleaseNotes={editedReleaseNotes.releaseNotes ?? ''}
                    onClose={handleClose}
                    reloadBundles={reloadBundles}
                  />
                );
              else if (editedSimplifiedDescription)
                return (
                  <ChangeSimplifiedDescriptionForm
                    versionId={editedSimplifiedDescription.id || ''}
                    initialDescription={editedSimplifiedDescription.simplifiedDescription ?? ''}
                    onClose={handleClose}
                    reloadBundles={reloadBundles}
                    isReadOnly={isAdpRole()}
                  />
                );
              else if (svsErrors) return <SvsErrorsModal versionId={svsErrors.id || ''} />;
              else if (editedCleanupDates)
                return <CleanupDatesModal onClose={handleClose} cleanupDates={editedCleanupDates?.cleanup} />;
              else if (versionFilesDetails)
                return (
                  <VersionFilesModal
                    onClose={handleClose}
                    versionId={versionFilesDetails.id || ''}
                    processPiiOrPci={!!processPiiOrPci}
                    submittedAt={versionFilesDetails.createdAt}
                    submittedBy={versionFilesDetails.submittedBy}
                    hash={versionFilesDetails.hash}
                  />
                );
              else return null;
            })()}
          </AccessibilityProvider>
        </Modal>,
        document.body
      )}
    </>
  );
};

const getProductCompatibility = (forDesktop?: boolean, forWeb?: boolean) =>
  !!forDesktop && !!forWeb
    ? ProductCompatibilities['Desktop & Web']
    : forDesktop
    ? ProductCompatibilities['Desktop']
    : forWeb
    ? ProductCompatibilities['Web']
    : undefined;

export default Actions;
