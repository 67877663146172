import {Product} from '../myProducts/MyProducts';
import {ProductCategories} from '@scm/components/assets/productCategories';
import {
  CcrzEPageLabelCSObject,
  CcrzEProductCSObjectProvisioningTypeCEnum,
  CcrzEProductCSObjectRedAppTypeCEnum,
  CcrzEProductCSObjectSabrePricingTypeCEnum,
  CcrzEProductMediaCSObjectCcrzMediaTypeCEnum,
} from '../../generated/product';
export enum ProductCompatibilities {
  'Desktop' = 'Desktop',
  'Desktop & Web' = 'Desktop & Web',
  'Web' = 'Web',
}

export enum Confirmation {
  Yes = 'Yes',
  No = 'No',
}

export interface YtLinkI {
  url: string;
  id?: string;
}

export interface ProductDetailsI extends Product {
  redAppId: string;
  sellerId: string;
  mainPluginId: string;
  proposalDate: string;
  category: ProductCategories[];
  confirmationRequired: Confirmation;
  storefrontDescription: string;
  storefrontShortDescription: string;
  featuresDescription: string;
  keyBenefitsDescription: string;
  supportDescription: string;
  additionalInformationDescription: string;
  termsAndConditionsDescription: string;
  ytLink: YtLinkI;
  respondMessage: string;
  imageFiles: Array<ProductMedia>;
  additionalFiles: Array<ProductMedia>;
  pdfFiles: Array<File>;
  countryCodes: Array<string>;
  regionCodes: Array<string>;
  isPaid: CcrzEProductCSObjectSabrePricingTypeCEnum;
  hideProduct: string;
  blockOrdering: string;
  blockOrderingDescription?: CcrzEPageLabelCSObject;
  redAppType: CcrzEProductCSObjectRedAppTypeCEnum;
  type: CcrzEProductCSObjectProvisioningTypeCEnum;
  referenceGuideUrl?: string;
}

export interface ProductMedia {
  id: string;
  mediaType: CcrzEProductMediaCSObjectCcrzMediaTypeCEnum;
  name: string;
  uri: string;
}

export enum RedAppType {
  Public = 'Public',
  Private = 'Private',
}

export enum DevelopmentType {
  InHouseDevelopment = 'In-house Development',
  OutsourcingDevelopment = 'Outsourcing Development',
}

export enum PaymentPlan {
  oneTimeMonetaryCharge = 'A one-time monetary charge',
  recurringRevenue = 'Recurring revenue',
}
