import React, {ReactElement} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Checkbox, CheckboxGroup, TextInput, TimeTextInput} from '@sabre/spark-react-core';
import {ButtonSize, TimeFormat, ToastType} from '@sabre/spark-react-core/types';
import {DatePicker, Star} from '@scm/components';
import styled from 'styled-components';
import {parse} from 'date-fns';
import {dateFormat, dateWithHourNoSeconds} from '@scm/components/utils/common';
import {
  Configuration as ConfigurationFreezeCalendar,
  CreateFreezeCalendarEntryRequest,
  Environment,
  FreezeCalendarEntriesApi,
  FreezeCalendarEntry,
} from '@scm/product-components/generated/certification';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {cstToUtc} from '../../utils/dateFormat';
import {yupResolver} from '@hookform/resolvers/yup';
import {initialValues} from './schema/freezeCalendarConstants';
import {freezeCalendarSchema} from './schema/freezeCalendarSchema';
import {
  commentName,
  endDateName,
  endHourName,
  environmentName,
  FreezeCalendarValues,
  startDateName,
  startHourName,
} from './schema/FreezeCalendarValues';
import {ButtonContainer} from '@scm/version/components/pages/cleanup/CleanupForm';
import Legend from '@scm/components/accessibility/Legend';
import color from '@scm/components/assets/colors';

const AddNewFreezeForm = ({onClose}: {onClose: () => void}) => {
  const descriptionMaxLength = 500;
  const {
    handleSubmit,
    control,
    reset,
    formState: {isValid},
  } = useForm<FreezeCalendarValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(freezeCalendarSchema),
  });

  const postFreezeCalendar = async (request: CreateFreezeCalendarEntryRequest) =>
    await new FreezeCalendarEntriesApi(
      new ConfigurationFreezeCalendar({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
      })
    ).createFreezeCalendarEntry(request);

  const getDateTime = (date: string, hour: string) => parse(`${date} ${hour}`, dateWithHourNoSeconds, new Date());

  const isValidInputData = (data: FreezeCalendarValues) => {
    if (
      !data.environment.length ||
      data.comment === '' ||
      data.startDate === '' ||
      data.endDate === '' ||
      data.startHour === '' ||
      !data.startHour ||
      data.endHour === '' ||
      !data.endHour
    ) {
      openToast(
        createMessageString(formatMessage, 'freezeCalendar.addForm', true, true),
        formatMessage({id: 'freezeCalendar.addForm.invalidRequest.requiredFields'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
      return false;
    }
    return true;
  };

  const onSubmit: SubmitHandler<FreezeCalendarValues> = data => {
    if (isValidInputData(data)) {
      const freezeCalendarEntry: FreezeCalendarEntry = {
        environments: data.environment as Array<Environment>,
        startDateTime: cstToUtc(getDateTime(data.startDate, data.startHour as string)),
        endDateTime: cstToUtc(getDateTime(data.endDate, data.endHour as string)),
        description: data.comment,
      };

      postFreezeCalendar({freezeCalendarEntry})
        .then(() => {
          reset();
          onClose();
        })
        .catch(err => {
          onClose();
          if (err.response.status === 400) {
            openToast(
              createMessageString(formatMessage, 'freezeCalendar.addForm', true, true),
              formatMessage({id: 'freezeCalendar.addForm.invalidRequest'}),
              ToastType.WARNING,
              'spark-icon-alert-triangle'
            );
          } else {
            console.log(err);
          }
        });
    } else {
      onClose();
    }
  };

  const {formatMessage} = useIntl();
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <p className="spark-bold spark-mar-t-2 spark-mar-b-1">
        <FormattedMessage id="freezeCalendar.addForm.environment.label" />
        <Star />
      </p>
      <Controller
        name={environmentName}
        control={control}
        render={({field: {onChange, name, value}}): ReactElement => (
          <CheckboxGroup name={name} value={value} onChange={(event, activeItems): void => onChange(activeItems)}>
            <Legend text={formatMessage({id: 'freezeCalendar.addForm.environment.label'})} />
            <Checkbox label={Environment.Cert} value={Environment.Cert} />
            <Checkbox label={Environment.Prod} value={Environment.Prod} className="spark-mar-b-0" />
          </CheckboxGroup>
        )}
      />
      <p className="spark-bold spark-mar-t-2 spark-mar-b-1">
        <FormattedMessage id="freezeCalendar.addForm.startDate.label" />
        <Star />
      </p>
      <div className="spark-flex">
        <Controller
          name={startDateName}
          control={control}
          render={({field: {onChange, value}}): ReactElement => (
            <DatePicker
              changeHandler={onChange}
              label={formatMessage({id: 'freezeCalendar.addForm.startDate.label'})}
              value={value}
              className="spark-pad-0 spark-mar-0 date-picker"
              dateFormat={dateFormat}
            />
          )}
        />
        <Controller
          name={startHourName}
          control={control}
          render={({field: {onChange, value}}): ReactElement => (
            <StyledTimeTextInput
              className="time-picker"
              label={formatMessage({id: 'freezeCalendar.addForm.hour.label'})}
              onChange={onChange}
              value={value}
              hourPlaceholder="HH"
              minutePlaceholder="MM"
              timeFormat={TimeFormat.TWENTY_FOUR}
              hourAriaLabel={formatMessage({id: 'accessibility.hour'})}
              minuteAriaLabel={formatMessage({id: 'accessibility.minute'})}
            />
          )}
        />
      </div>
      <p className="spark-bold spark-mar-t-2 spark-mar-b-1">
        <FormattedMessage id="freezeCalendar.addForm.endDate.label" />
        <Star />
      </p>
      <div className="spark-flex">
        <Controller
          name={endDateName}
          control={control}
          render={({field: {onChange, value}}): ReactElement => (
            <DatePicker
              changeHandler={onChange}
              label={formatMessage({id: 'freezeCalendar.addForm.endDate.label'})}
              value={value}
              className="spark-pad-0 spark-mar-0 date-picker"
              dateFormat={dateFormat}
            />
          )}
        />
        <Controller
          name={endHourName}
          control={control}
          render={({field: {onChange, value}}): ReactElement => (
            <StyledTimeTextInput
              className="time-picker"
              label={formatMessage({id: 'freezeCalendar.addForm.hour.label'})}
              onChange={onChange}
              value={value}
              hourPlaceholder="HH"
              minutePlaceholder="MM"
              timeFormat={TimeFormat.TWENTY_FOUR}
              hourAriaLabel={formatMessage({id: 'accessibility.hour'})}
              minuteAriaLabel={formatMessage({id: 'accessibility.minute'})}
            />
          )}
        />
      </div>
      <p className="spark-bold spark-mar-t-2 spark-mar-b-1">
        <FormattedMessage id="freezeCalendar.addForm.comment.label" />
        <Star />
      </p>
      <Controller
        name={commentName}
        control={control}
        render={({field}): ReactElement => (
          <TextInput
            {...field}
            characterCount
            maxLength={descriptionMaxLength}
            label={formatMessage({id: 'freezeCalendar.addForm.comment.label'})}
            multiLine
          />
        )}
      />
      <ButtonContainer className="spark-mar-b-2 spark-mar-t-2 spark-pad-l-0">
        <Button className="col-xs-12 col-lg-4" size={ButtonSize.SMALL} onClick={onClose}>
          <FormattedMessage id="common.cancelButton" />
        </Button>
        <SubmitButton
          className="col-xs-12 col-lg-4"
          size={ButtonSize.SMALL}
          type="submit"
          disabled={!isValid}
          secondary
          tabIndex={0}
        >
          <FormattedMessage id="common.submitButton" />
        </SubmitButton>
      </ButtonContainer>
    </Form>
  );
};

const Form = styled.form`
  & .time-picker {
    margin-left: 0.5rem;
  }

  & .date-picker {
    flex-grow: 1;
  }
`;

const StyledTimeTextInput = styled(TimeTextInput)`
  & .spark-input__placeholder {
    color: ${color.grey500} !important;
  }
`;

const SubmitButton = styled(Button)<{disabled?: boolean}>`
  color: ${({disabled}) => (disabled ? color.statusInfoGrey : color.sparkGreen)} !important;
`;

export default AddNewFreezeForm;
