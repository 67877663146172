import React, {useContext, useEffect, useState} from 'react';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormattedMessage} from 'react-intl';
import {ProposalContext} from '../../ProposalForm';
import {ProposalContainer} from '../../ProposalTypePage';
import {ButtonContainerInsurance} from '../InsurancePage';
import PageHeader from '../../../../components/content/PageHeader';
import AdditionalStatement from '../../../../components/content/AdditionalStatement';
import RedAppName from './definitionComponents/RedAppName';
import Description from './definitionComponents/Description';
import Category from './definitionComponents/Category';
import FlowDiagram from './definitionComponents/FlowDiagram';
import RedAppDetailInfo from './definitionComponents/RedAppDetailInfo';
import ConnectGds from './definitionComponents/ConnectGds';
import DevelopmentType from './definitionComponents/DevelopmentType';
import Technologies from './definitionComponents/Technologies';
import {ProposalType} from '../../PagesMap';
import {
  currentStepName,
  descriptionName,
  proposalTypeName,
  redAppCategoryName,
  redAppName,
} from '@scm/proposal/pages/proposalForm/ProposalValues';
import ButtonWithTooltip from '@scm/components/form/ButtonWithTooltip';
import {nameMinLength} from '../../../../schemas/proposalConstants';
import HtmlToText from 'html-to-text';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';

const DefinitionPage = () => {
  const {
    handleContinue,
    handleCancel,
    handleSaveDraft,
    watch,
    isValid,
    setValue,
    errors,
    isSmallSpinnerLoading,
    isOnboarding,
  } = useContext(ProposalContext);
  const [isNameValid, setIsNameValid] = useState<boolean>(false);
  const isSaveDraftButtonVisible =
    !isOnboarding &&
    (watch(proposalTypeName) === ProposalType['Public Red App'] ||
      watch(proposalTypeName) === ProposalType['Private Red App']);
  const isSaveDraftButtonDisabled = watch(redAppName).length < nameMinLength || !isNameValid || isSmallSpinnerLoading;
  const isDescriptionLengthCorrect = HtmlToText.htmlToText(watch(descriptionName)).length >= 150;

  const handleDefinitionContinue = () => {
    gaEvent(`Added Red App Definition (${watch(proposalTypeName)?.split(' ')[0] || ''} App Proposal)`);
    handleContinue(false);
  };

  useEffect(() => {
    setValue(currentStepName, watch(currentStepName), {shouldDirty: true, shouldTouch: true, shouldValidate: true});
  }, []);

  return (
    <>
      <ProposalContainer className="spark-panel spark-pad-l-2">
        <PageHeader id="definition.title" />
        <AdditionalStatement id="definition.additionalStatement" />
        <RedAppName
          firstParagraph="definition.redAppName.firstParagraph"
          secondParagraph="definition.redAppName.secondParagraph"
          thirdParagraph="definition.redAppName.thirdParagraph"
          setIsNameValid={setIsNameValid}
        />
        <Description isOnboarding={isOnboarding} />
        <Category
          firstLevelName={redAppCategoryName}
          watch={watch}
          setValue={setValue}
          className="col-xs-8 spark-mar-t-0 spark-mar-b-1 spark-pad-.5"
          errors={errors}
        />
        <FlowDiagram />
        <RedAppDetailInfo />
        <ConnectGds />
        <DevelopmentType />
        <Technologies />
      </ProposalContainer>
      <ButtonContainerInsurance className="spark-mar-t-1 spark-flex">
        <Button secondary size={ButtonSize.SMALL} onClick={handleCancel} className="spark-mar-r-1">
          <FormattedMessage id="common.backButton" />
        </Button>
        <div className="row spark-mar-r-.5">
          {isSaveDraftButtonVisible && (
            <Button
              size={ButtonSize.SMALL}
              onClick={handleSaveDraft as () => {}}
              disabled={isSaveDraftButtonDisabled}
              className="spark-mar-r-1"
              secondary
            >
              <FormattedMessage id="proposal.saveDraft" />
            </Button>
          )}
          <ButtonWithTooltip
            handleContinue={handleDefinitionContinue}
            isValid={isValid && isNameValid && !isSmallSpinnerLoading && isDescriptionLengthCorrect}
            messageId="proposal.requiredFields.message"
          />
        </div>
      </ButtonContainerInsurance>
    </>
  );
};

export default DefinitionPage;
