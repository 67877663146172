import {useEffect, useRef} from 'react';

const useAddRoleToTooltip = () => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const target = (tooltipRef.current as {targetEl: HTMLDivElement})?.targetEl;
      if (target) {
        target.role = 'button';
        target.ariaLabel = 'Open tooltip';
      }
    }
  });

  return tooltipRef;
};

export default useAddRoleToTooltip;
