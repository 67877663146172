import React, {useState} from 'react';
import styled from 'styled-components';
import DeveloperPartnerFormContent from './form/developerPartner/DeveloperPartnerFormContent';
import colors from '@scm/components/assets/colors';
import {FormStepIndicator, StepIndicatorType} from '@scm/form-step-indicator';
import ProviderType from './ProviderType';
import {useForm} from 'react-hook-form';
import RedAppProviderFormContent from './form/redAppProvider/RedAppProviderFormContent';

export const onboardingProviderTypeName = 'onboardingProviderType';

export enum OnboardingProviderType {
  'Sabre Developer Partner' = 'Sabre Developer Partner',
  'Sabre Red App Certified Provider' = 'Sabre Red App Certified Provider',
}

export interface ProviderTypeValue {
  [onboardingProviderTypeName]?: OnboardingProviderType;
}

export const JoinUsContentBox = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isPublicRedApp, setIsPublicRedApp] = useState<boolean>();
  const {watch, setValue} = useForm<ProviderTypeValue>({mode: 'onChange'});
  const createStepIndicatorType = () => {
    if (watch(onboardingProviderTypeName) === OnboardingProviderType['Sabre Developer Partner']) {
      return StepIndicatorType.joinUs;
    } else if (watch(onboardingProviderTypeName) === OnboardingProviderType['Sabre Red App Certified Provider']) {
      return StepIndicatorType.onboardingJoinUs;
    } else {
      return StepIndicatorType.providerType;
    }
  };

  return (
    <ContentBox>
      <StepsPanel className="spark-mar-l-2">
        <FormStepIndicator
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          type={createStepIndicatorType()}
          isPublicRedApp={isPublicRedApp}
        />
      </StepsPanel>
      {currentStep === 0 && <ProviderType watch={watch} setValue={setValue} setCurrentStep={setCurrentStep} />}
      {currentStep !== 0 && watch(onboardingProviderTypeName) === OnboardingProviderType['Sabre Developer Partner'] && (
        <DeveloperPartnerFormContent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          watch={watch}
          setProviderTypeValue={setValue}
        />
      )}
      {currentStep !== 0 &&
        watch(onboardingProviderTypeName) === OnboardingProviderType['Sabre Red App Certified Provider'] && (
          <RedAppProviderFormContent
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setProviderTypeValue={setValue}
            isPublicRedApp={isPublicRedApp}
            setIsPublicRedApp={setIsPublicRedApp}
          />
        )}
    </ContentBox>
  );
};

const ContentBox = styled.div`
  background-color: ${colors.white};
  padding: 2rem 2vw;

  & *:not(strong, button, .spark-btn__label) {
    font-weight: 400;
  }
`;

const StepsPanel = styled.div`
  display: flex;
  width: 100%;

  & .spark-step-indicator__list {
    justify-content: flex-start;
  }

  & .spark-step-indicator__item {
    max-width: 9.3rem;
    min-width: 6.3rem;
  }
`;
