import React from 'react';
import {AppState} from 'utils/rootReducer';
import {useDispatch, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';
import {closeFullPageModal} from 'components/modals/fullPageModal/store/actions';
import Modal from 'spark/modal/Modal';

const FullPageModal = () => {
  const {isOpen, children, sizing} = useSelector((state: AppState) => state.fullPageModalState);
  const closeModal = bindActionCreators(closeFullPageModal, useDispatch());

  return (
    <Modal isOpen={isOpen} onClose={closeModal} sizing={sizing}>
      {children}
    </Modal>
  );
};

export default FullPageModal;
