import styled from 'styled-components';
import PageHeader from '@scm/proposal/components/content/PageHeader';
import {FormattedMessage} from 'react-intl';
import {Switch} from '@sabre/spark-react-core';
import {NotificationsSettings} from '../../../generated/user';
import React from 'react';
import {deviceWidths} from '@scm/components/assets/deviceWidth';

const proposalNotifications: string[] = ['proposalSubmitted', 'proposalRejected', 'proposalAccepted'];
const versionNotifications: string[] = [
  'validationFailed',
  'certificationFailed',
  'readyForTestCert',
  'readyForTestProd',
  'cleanup',
];

const NotificationSettingsGroup = ({
  isProposals,
  onSwitchToggle,
  updatedNotificationsSettings,
}: {
  isProposals: boolean;
  onSwitchToggle: (notificationName: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  updatedNotificationsSettings: NotificationsSettings;
}) => {
  let filteredNotifications: string[];
  let notificationsType: string;
  if (isProposals) {
    filteredNotifications = proposalNotifications;
    notificationsType = 'proposals';
  } else {
    filteredNotifications = versionNotifications;
    notificationsType = 'versions';
  }

  return (
    <>
      <PageHeader id={`notificationSettings.${notificationsType}.title`} />
      <Row className="spark-mar-l-0">
        <Label className="spark-bold">
          <FormattedMessage id="notificationSettings.sendNotifications.message" />
        </Label>
        <OffOnLabel className="spark-bold">
          <FormattedMessage id="notificationSettings.sendNotifications.offOn" />
        </OffOnLabel>
      </Row>
      {Object.entries(updatedNotificationsSettings).map(
        ([notificationName]) =>
          filteredNotifications.includes(notificationName) && (
            <Row className="spark-mar-l-0" key={notificationName}>
              <Label id={`notificationSettings.${notificationsType}.${notificationName}`}>
                <FormattedMessage id={`notificationSettings.${notificationsType}.${notificationName}`} />
              </Label>
              <Switch
                ariaLabelledBy={`notificationSettings.${notificationsType}.${notificationName}`}
                checked={updatedNotificationsSettings[notificationName as keyof NotificationsSettings]?.email}
                name={notificationName}
                onChange={onSwitchToggle(notificationName)}
              />
            </Row>
          )
      )}
    </>
  );
};

const Label = styled.p`
  width: 50rem;
  min-height: 4rem;
  display: flex;
  flex-grow: 0;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.2rem;
  margin-bottom: 0;
  @media (max-width: ${deviceWidths.md}px) {
    min-height: 0;
    margin-top: 2rem;
    width: 100%;
  }
`;

const OffOnLabel = styled.p`
  min-height: 4rem;
  display: flex;
  flex-grow: 0;
  align-items: center;
  margin-left: auto;
  margin-right: 2rem;
  font-size: 1.2rem;
  margin-bottom: 0;
  @media (max-width: ${deviceWidths.md}px) {
    min-height: 0;
    margin-top: 2rem;
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  @media (max-width: ${deviceWidths.md}px) {
    flex-wrap: wrap;
  }

  .spark-toggle-switch {
    margin-left: auto;
    margin-right: 2rem;
  }
`;

export default NotificationSettingsGroup;
