import * as yup from 'yup';
import {TestFunction} from 'yup';
import {currentStepName, serviceAndSecurityValuesName} from '../pages/proposalForm/ProposalValues';
import {
  basicTextFieldMaxLength,
  dataProtectionTest,
  freeTextRemarksFieldsTest,
  minDescriptionLargeMessage,
  minDescriptionMessage,
  requiredArrayMessage,
  servicesAndSecurityArrayFieldTest,
  servicesAndSecurityTextFieldTest,
  standardCorrectFieldMessage,
} from './proposalConstants';
import {
  accountingAttributesDescriptionName,
  accountingDataTypeName,
  accountingName,
  alignSecureCodingPracticesName,
  bargainFinderMaxName,
  coreDataName,
  dataAdminName,
  dataForwardedName,
  dataForwardedThirdPartyName,
  dataProtectionName,
  euDataName,
  externalServicesDescriptionName,
  externalServicesName,
  financialInformationName,
  freeTextRemarksDescriptionName,
  freeTextRemarksName,
  gdprCompliantName,
  hostCommandsDescriptionName,
  localDataName,
  newSabreRedTechnologiesDescriptionName,
  newSabreRedTechnologiesName,
  numberOfTransactionName,
  panRenderedName,
  patchTimelineName,
  pciAdditionalDetailsName,
  pciCertifiedName,
  pciDataName,
  personalInformationName,
  piiDataName,
  piiDataRetentionAdminName,
  piiDataRetentionName,
  protocolName,
  retentionPeriodName,
  sabreServicesName,
  thirdPartyNetworkName,
  utilizedMethodName,
} from '../pages/proposalForm/steps/serviceAndSecurity/ServiceAndSecurityValues';

export const publicProposalSchema = yup.object().shape({
  [currentStepName]: yup.number(),
  [serviceAndSecurityValuesName]: yup.object().shape({
    [hostCommandsDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(sabreServicesName, hostCommandsDescriptionName)
      ),
    [bargainFinderMaxName]: yup
      .string()
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(sabreServicesName, bargainFinderMaxName, true)
      ),
    [externalServicesDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(externalServicesName, externalServicesDescriptionName)
      ),
    [utilizedMethodName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(externalServicesName, utilizedMethodName)
      ),
    [newSabreRedTechnologiesName]: yup.boolean(),
    [newSabreRedTechnologiesDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(newSabreRedTechnologiesName, newSabreRedTechnologiesDescriptionName)
      ),
    [financialInformationName]: yup.boolean(),
    [pciDataName]: yup
      .array()
      .test(
        standardCorrectFieldMessage,
        `${requiredArrayMessage}`,
        servicesAndSecurityArrayFieldTest(financialInformationName, pciDataName) as TestFunction
      ),
    [pciCertifiedName]: yup
      .string()
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(financialInformationName, pciCertifiedName, true)
      ),
    [pciAdditionalDetailsName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(financialInformationName, pciAdditionalDetailsName)
      ),
    [panRenderedName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(financialInformationName, panRenderedName)
      ),
    [retentionPeriodName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(financialInformationName, retentionPeriodName)
      ),
    [dataAdminName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(financialInformationName, dataAdminName)
      ),
    [personalInformationName]: yup.boolean(),
    [piiDataName]: yup
      .array()
      .test(
        standardCorrectFieldMessage,
        `${requiredArrayMessage}`,
        servicesAndSecurityArrayFieldTest(personalInformationName, piiDataName) as TestFunction
      ),
    [dataForwardedName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(personalInformationName, dataForwardedName)
      ),
    [euDataName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(personalInformationName, euDataName)
      ),
    [gdprCompliantName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(personalInformationName, gdprCompliantName)
      ),
    [piiDataRetentionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(personalInformationName, piiDataRetentionName)
      ),
    [piiDataRetentionAdminName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(personalInformationName, piiDataRetentionAdminName)
      ),
    [localDataName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(personalInformationName, localDataName)
      ),
    [dataProtectionName]: yup
      .string()
      .test(standardCorrectFieldMessage, minDescriptionLargeMessage, dataProtectionTest),
    [thirdPartyNetworkName]: yup.boolean(),
    [dataForwardedThirdPartyName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(thirdPartyNetworkName, dataForwardedThirdPartyName)
      ),
    [protocolName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(thirdPartyNetworkName, protocolName)
      ),
    [coreDataName]: yup.boolean(),
    [freeTextRemarksName]: yup.string(),
    [freeTextRemarksDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        freeTextRemarksFieldsTest(freeTextRemarksDescriptionName)
      ),
    [numberOfTransactionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(standardCorrectFieldMessage, minDescriptionMessage, freeTextRemarksFieldsTest(numberOfTransactionName)),
    [alignSecureCodingPracticesName]: yup.boolean(),
    [patchTimelineName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(alignSecureCodingPracticesName, patchTimelineName)
      ),
    [accountingName]: yup.boolean(),
    [accountingDataTypeName]: yup
      .array()
      .test(
        standardCorrectFieldMessage,
        `${requiredArrayMessage}`,
        servicesAndSecurityArrayFieldTest(accountingName, accountingDataTypeName) as TestFunction
      ),
    [accountingAttributesDescriptionName]: yup
      .string()
      .max(basicTextFieldMaxLength)
      .test(
        standardCorrectFieldMessage,
        minDescriptionMessage,
        servicesAndSecurityTextFieldTest(accountingName, accountingAttributesDescriptionName)
      ),
  }),
});
