import {UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {BundleValues} from './bundleValues';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {createMessageString} from '@scm/components/messaging/openToast';
import {FileUpload, Star} from '@scm/components';
import {Checkbox, Icon} from '@sabre/spark-react-core';
import {downloadFileAndHandleErrors} from '@scm/version/components/pages/certify/Certification';
import {textFileType} from '@scm/components/utils/common';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {FileTypes} from '../../../../generated/certification';

const fileNameToFileTypeMapper: {[key: string]: string} = {
  useCaseFile: 'USE_CASES',
  securityWorksheet: 'SECURITY_WORKSHEET',
  workflowDiagram: 'WORKFLOW_DIAGRAM',
};

const smallDevicesBreakpoint = 1320;

const TextFile = ({
  fileName,
  watch,
  setValue,
  deleteFileHandler,
  latestVersionId,
  latestVersion,
  shouldBeCopied,
  setShouldBeCopied,
}: {
  fileName: string;
  watch: UseFormWatch<BundleValues>;
  setValue: UseFormSetValue<BundleValues>;
  deleteFileHandler: (fileName: string) => void;
  latestVersionId?: string;
  latestVersion?: string;
  shouldBeCopied: boolean;
  setShouldBeCopied: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {formatMessage} = useIntl();
  const maxFiles = 1;
  const productDetailsName = 'productDetails.fetch';
  let errorMessageTitle = createMessageString(formatMessage, productDetailsName, true, true);
  let errorMessageDetails = createMessageString(formatMessage, productDetailsName, false, true);

  return (
    <>
      <HeaderContainer className="spark-flex">
        <LongText className="spark-mar-b-2 spark-mar-t-1 spark-caps">
          <FormattedMessage id={`newBundle.${fileName}.header`} />
          <Star />
        </LongText>
      </HeaderContainer>
      {latestVersionId && (
        <CheckboxContainer className="spark-mar-t-.5">
          <Checkbox
            disabled={false}
            label={formatMessage(
              {id: 'newBundle.upload.previousVersionFile'},
              {
                version: latestVersion,
              }
            )}
            onChange={e => setShouldBeCopied(e.target.checked)}
          />
          <a
            className="spark-mar-l-.5"
            onClick={() =>
              downloadFileAndHandleErrors(
                latestVersionId,
                fileNameToFileTypeMapper[fileName] as FileTypes,
                errorMessageTitle,
                errorMessageDetails
              )
            }
            role="presentation"
          >
            {formatMessage({id: 'newBundle.upload.downloadFile'})}
          </a>
        </CheckboxContainer>
      )}
      <FilesList className="spark-list--no-bullets spark-mar-l-0" isDisabled={shouldBeCopied}>
        {(watch(fileName as keyof BundleValues) as Array<File>)?.map((file: File) => (
          <li key={file.name} onClick={() => !shouldBeCopied && deleteFileHandler(fileName)}>
            {file.name} <Icon name="trash" />
          </li>
        ))}
      </FilesList>
      <FileUpload
        filesNames={fileName}
        setFiles={setValue as () => void}
        watch={watch}
        filesType={textFileType}
        maxFiles={maxFiles}
        additionalText={formatMessage({
          id: 'newBundle.useCaseFile.additionalText',
        })}
        isDisabled={shouldBeCopied}
      />
    </>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  & a {
    flex: 1;
    box-sizing: border-box;
  }

  & a:hover {
    text-decoration: none;
  }

  @media (max-width: ${smallDevicesBreakpoint}px) {
    & a {
      flex: 0 0 100%;
      margin-left: 3rem !important;
    }
  }
`;

const FilesList = styled.ul<{isDisabled: boolean}>`
  & .spark-icon {
    font-size: 1.8rem;
    vertical-align: -1px;
    color: ${props => (props.isDisabled ? colors.grey700 : colors.sparkBlue)};
    cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  }

  & li {
    opacity: ${props => (props.isDisabled ? 0.6 : 1.0)};
  }
`;

const HeaderContainer = styled.div`
  transform: translateY(2rem);
`;

const LongText = styled.p`
  font-size: 1.2rem;
`;

export default TextFile;
