import React, {useContext, useState} from 'react';
import ProdTesterRow from './ProdTesterRow';
import {Table, TableHeader} from '../bundles/BundlesTable';
import {StorefrontDataContext} from '../../../storefrontData/dataProvider/StorefrontDataProvider';
import {
  certTestersName,
  editedCertTesterName,
  editedTesterName,
  isCertTesterAddingName,
  isTesterAddingName,
  testersName,
} from '../../../storefrontData/interfaces/TesterDataValues';
import {BetaTesterStatusEnum} from '../../../../generated/certification';
import CertTesterRow from './CertTesterRow';
import {IconSize, IconStyle} from '@sabre/spark-react-core/types';
import {Icon} from '@sabre/spark-react-core';

export type DeleteTesterHandlerType = (nr: string) => void;

export interface Tester {
  nr: string;
  pcc: string;
  sabreId: string;
  created: Date | string;
  status?: BetaTesterStatusEnum;
  errorReason?: string;
}

type Direction = 'Asc' | 'Desc';

interface SortedColumn {
  name: string;
  direction?: Direction;
}

export const testersHeaders: Array<string> = ['pcc', 'sabre id', 'created', 'status'];

const TestersTable = ({
  headers,
  deleteTestersHandler,
  currentPaginationPage,
  paginationInterval = 10,
  isProdTestersTable,
  sortableHeaders,
}: {
  headers: Array<string>;
  deleteTestersHandler?: DeleteTesterHandlerType;
  currentPaginationPage?: number;
  paginationInterval?: number;
  isProdTestersTable: boolean;
  sortableHeaders: Array<string>;
}) => {
  const {methodsTesterForm, methodsCertTesterForm} = useContext(StorefrontDataContext);
  const {watch, setValue} = methodsTesterForm;
  const {watch: watchCertTesters, setValue: setValueCertTesters} = methodsCertTesterForm;

  const getSortedColumnsInitialState = (): Array<SortedColumn> =>
    sortableHeaders.map(sortableHeader => ({
      name: sortableHeader,
      direction: 'Desc',
    }));

  const [sortedColumns, setSortedColumns] = useState<Array<SortedColumn>>(getSortedColumnsInitialState());

  const generateHeaders = () => (
    <thead>
      <tr>
        {headers.map((name, index, headers) => {
          const sortingIcon = showSortingIcon(name);
          return (
            name !== '' && (
              <TableHeader
                key={name}
                colSpan={headers[index + 1] === '' ? 2 : 1}
                scope={headers[index + 1] === '' ? 'colgroup' : ''}
                onClick={() => changeSorting(name)}
                style={{cursor: !!sortingIcon ? 'pointer' : 'auto'}}
              >
                {name} {sortingIcon}
              </TableHeader>
            )
          );
        })}
        <TableHeader />
      </tr>
    </thead>
  );

  const generateBody = () => {
    return isProdTestersTable ? generateProdTestersContent() : generateCertTestersContent();
  };

  const changeSorting = (name: string) => {
    if (!sortedColumns.some(column => column.name === name)) {
      return;
    }

    const newSortedColumns = sortedColumns.map(column => {
      if (column.name !== name) {
        return column;
      }
      return {
        ...column,
        direction: getNewDirection(column.direction),
      };
    });

    setSortedColumns(newSortedColumns);
    sortValues(name);
  };

  const getNewDirection = (currentDirection?: Direction): Direction => (currentDirection === 'Desc' ? 'Asc' : 'Desc');

  const sortValues = (byField: string) => {
    let testers = isProdTestersTable ? [...watch(testersName)] : [...watchCertTesters(certTestersName)];
    if (byField === 'pcc') {
      testers.sort((a, b) => a.pcc.localeCompare(b.pcc));
    } else if (byField === 'created') {
      testers.sort((a, b) => +a.created - +b.created);
    }

    if (sortedColumns.filter(column => column.name === byField)[0].direction === 'Desc') {
      testers.reverse();
    }

    isProdTestersTable
      ? setValue(testersName, testers, {shouldTouch: true, shouldValidate: true, shouldDirty: true})
      : setValueCertTesters(certTestersName, testers, {shouldTouch: true, shouldValidate: true, shouldDirty: true});
  };

  function showSortingIcon(name: string) {
    const column = sortedColumns.find(sortedColumn => sortedColumn.name === name);
    if (!column || !column.direction) {
      return '';
    }

    return (
      <Icon
        className="spark-mar-b-.5"
        name={column.direction === 'Desc' ? 'arrow-basic-down' : 'arrow-basic-up'}
        iconStyle={IconStyle.FILL}
        size={IconSize.SMALL}
      />
    );
  }

  function generateProdTestersContent() {
    let content = watch(testersName)
      .slice(
        (currentPaginationPage ?? 0) * paginationInterval,
        (currentPaginationPage ?? 0) * paginationInterval + paginationInterval
      )
      .map((dataItem: Tester) => (
        <ProdTesterRow
          key={dataItem.nr}
          tester={dataItem}
          deleteTestersHandler={deleteTestersHandler as DeleteTesterHandlerType}
        />
      ));
    if (watch(isTesterAddingName ?? '')) {
      content.push(
        <ProdTesterRow key={watch(editedTesterName)?.nr} tester={watch(editedTesterName) as Tester} isEditedRow />
      );
    }

    return <tbody>{content}</tbody>;
  }

  function generateCertTestersContent() {
    let content = watchCertTesters(certTestersName)
      .slice(
        (currentPaginationPage ?? 0) * paginationInterval,
        (currentPaginationPage ?? 0) * paginationInterval + paginationInterval
      )
      .map((dataItem: Tester) => (
        <CertTesterRow
          key={dataItem.nr}
          tester={dataItem}
          deleteTestersHandler={deleteTestersHandler as DeleteTesterHandlerType}
        />
      ));
    if (watchCertTesters(isCertTesterAddingName ?? '')) {
      content.push(
        <CertTesterRow
          key={watchCertTesters(editedCertTesterName)?.nr}
          tester={watchCertTesters(editedCertTesterName) as Tester}
          isEditedRow
        />
      );
    }

    return <tbody>{content}</tbody>;
  }

  return (
    <Table className="spark-table">
      <div className="spark-table__scroll">
        <table role="grid" className="spark-mar-b-1">
          {generateHeaders()}
          {generateBody()}
        </table>
      </div>
    </Table>
  );
};

export default TestersTable;
