/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BetaTester } from './BetaTester';
import {
    BetaTesterFromJSON,
    BetaTesterFromJSONTyped,
    BetaTesterToJSON,
} from './BetaTester';

/**
 * 
 * @export
 * @interface ScribeScripts
 */
export interface ScribeScripts {
    /**
     * 
     * @type {string}
     * @memberof ScribeScripts
     */
    name: string;
    /**
     * 
     * @type {Array<BetaTester>}
     * @memberof ScribeScripts
     */
    betaTesters?: Array<BetaTester>;
}

/**
 * Check if a given object implements the ScribeScripts interface.
 */
export function instanceOfScribeScripts(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ScribeScriptsFromJSON(json: any): ScribeScripts {
    return ScribeScriptsFromJSONTyped(json, false);
}

export function ScribeScriptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScribeScripts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'betaTesters': !exists(json, 'betaTesters') ? undefined : ((json['betaTesters'] as Array<any>).map(BetaTesterFromJSON)),
    };
}

export function ScribeScriptsToJSON(value?: ScribeScripts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'betaTesters': value.betaTesters === undefined ? undefined : ((value.betaTesters as Array<any>).map(BetaTesterToJSON)),
    };
}

