import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import Sorting from 'utils/sorting';
import EventCategories from 'googleAnalytics/EventCategories';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'utils/rootReducer';
import {bindActionCreators} from 'redux';
import {sortAction} from 'pages/partnerDirectory/store/actions';
import {useMediaQuery} from 'react-responsive';
import logEvent from 'googleAnalytics/logEvent';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const SortToggle = () => {
  const {sorting} = useSelector((state: AppState) => state.partnerDirectoryState);
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  return (
    <StyledSortToggle mobile={isMobile}>
      <SortingRadioButton currentSorting={sorting} sortingType={Sorting.FEATURED} textI18nKey="sorting.featured" />
      {(sorting === Sorting.A_TO_Z || sorting === Sorting.FEATURED) && (
        <SortingRadioButton currentSorting={sorting} sortingType={Sorting.A_TO_Z} textI18nKey={'sorting.a_to_z'} />
      )}
      {sorting === Sorting.Z_TO_A && (
        <SortingRadioButton currentSorting={sorting} sortingType={Sorting.Z_TO_A} textI18nKey={'sorting.z_to_a'} />
      )}
    </StyledSortToggle>
  );
};

const StyledSortToggle = styled.div.attrs(() => ({
  className: 'spark-flex spark-toggle-group',
}))<{mobile?: boolean}>`
  ${props => (props.mobile ? '' : 'margin: 2rem;')}
  ${props => (props.mobile ? '' : 'justify-content: flex-end')};
  ${props => (props.mobile ? 'width: 100%' : '')};
`;

const SortingRadioButton = ({
  currentSorting,
  sortingType,
  textI18nKey,
}: {
  currentSorting: Sorting;
  sortingType: Sorting;
  textI18nKey: string;
}) => {
  const boundSortAction = bindActionCreators(sortAction, useDispatch());
  const getTargetSorting = (sorting: Sorting, activeSorting: Sorting) =>
    sorting !== Sorting.FEATURED && sorting === activeSorting
      ? sorting === Sorting.A_TO_Z
        ? Sorting.Z_TO_A
        : Sorting.A_TO_Z
      : sorting;
  const onClickHandler = () => {
    let targetSorting = getTargetSorting(sortingType, currentSorting);
    if (currentSorting === targetSorting) {
      return;
    }
    logEvent({
      category: EventCategories.Directory,
      action: 'Changed sorting',
      label: targetSorting,
    });
    boundSortAction(targetSorting);
  };
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  return (
    <Label mobile={isMobile}>
      <input
        className="spark-toggle__input"
        type="radio"
        name="sorting-type"
        checked={currentSorting === sortingType}
        onClick={onClickHandler}
        readOnly
      />
      <span className="spark-label">
        <FormattedMessage id={textI18nKey} />
      </span>
    </Label>
  );
};

const Label = styled.label.attrs(() => ({
  className: 'spark-flex spark-toggle',
}))<{mobile?: boolean}>`
  ${props => (props.mobile ? 'width: 50%;' : 'flex: 0.2;')};
`;

export default SortToggle;
