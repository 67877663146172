const cleanupDashboard = {
  'cleanupDashboard.title': 'Cleanup',
  'cleanupDashboard.description.tooltip': 'Cleanup',
  'cleanupDashboard.description.introduction':
    'Cleanup is intended to remove old and unnecessary versions from Sabre Red CM and Admin Centre databases. It is managed by automatic jobs but also allows triggering manual deletion for some versions.',
  'cleanupDashboard.description.definitions': 'Definitions:',
  'cleanupDashboard.description.definitions.listItem1':
    'On Hold - switch that can be enabled only manually, to prevent version removal.',
  'cleanupDashboard.description.definitions.listItem2':
    'With Notification - if this switch is enabled, notification to Developers and Buyers about planned removal will be sent. Developers receive message immediately after each cleanup entry update, message to buyers is sent 30 days and 3 days before removal. By default the switch is enabled for all published versions.',
  'cleanupDashboard.description.definitions.listItem3':
    "SR360 Config Removal Date - date of planned removal from Sabre Red CM. Versions with created roles in CM have to be removed from CM first (before removing from SCM). Refers only to Versions with 'Ready to test (PROD)' status or later.",
  'cleanupDashboard.description.definitions.listItem4':
    'SR360 Config Removed – shows, if Version has been already removed from Sabre Red CM.',
  'cleanupDashboard.description.definitions.listItem5':
    'Version Removal Date – date of version removal from SCM Admin Centre databases, deletion applies to all data related to the Version, including Update Sites files.',
  'cleanupDashboard.description.scheduledAutomatically':
    'By default, cleanup entries listed on this page are scheduled automatically by a daily job. To be qualified for removal version should fulfill one of below:',
  'cleanupDashboard.description.scheduledAutomatically.listItem1':
    'Version was published and 3 newer published versions exist (4th published version)',
  'cleanupDashboard.description.scheduledAutomatically.listItem2': 'Version was failed and next version is submitted',
  'cleanupDashboard.description.versions':
    'You can find list of all Red App’s versions in Product Details view, in Versions tab, cleanup configuration for each version can be checked in the same table. As a Sabre Admin you can add and edit cleanup configuration for versions.',
  'cleanupDashboard.description.scheduleManual': 'You can schedule manual cleanup for version. Steps:',
  'cleanupDashboard.description.scheduleManual.listItem1':
    'Go to particular Red App details in My Products, go to versions tab below',
  'cleanupDashboard.description.scheduleManual.listItem2': 'Select Version and click “Manage” button',
  'cleanupDashboard.description.scheduleManual.listItem3': 'Go to Cleanup tab',
  'cleanupDashboard.description.scheduleManual.listItem4':
    'Click “Configure cleanup options”, all fields will be filled up with default values, you can also edit them',
  'cleanupDashboard.description.scheduleManual.listItem5': 'Click “Save”',
  'cleanupDashboard.description.editVersion': 'To edit already scheduled version:',
  'cleanupDashboard.description.editVersion.listItem1':
    'Go to Cleanup menu or to Cleanup tab in Red App version details',
  'cleanupDashboard.description.editVersion.listItem2': 'Edit configuration and click “Save” button',
  'cleanupDashboard.description.removeNow':
    'Versions, that were never in CM (status earlier than Ready for Test (Prod)) or that are already removed from CM (have SR360 Config Removed set to Yes) can be removed immediately by clicking on “Remove now” button.',
  'cleanupDashboard.searchLabel': 'Search everywhere...',
  'cleanupDashboard.redAppName': 'Red App Name',
  'cleanupDashboard.version': 'Version',
  'cleanupDashboard.versionStatus': 'Version Status',
  'cleanupDashboard.onHold': 'On Hold',
  'cleanupDashboard.withNotification': 'With Notification',
  'cleanupDashboard.configRemovalDate': 'SR360 Config Removal Date',
  'cleanupDashboard.configRemoved': 'SR360 Config Removed',
  'cleanupDashboard.versionRemovalDate': 'Version Removal Date',
  'cleanupDashboard.placeholder': 'Enter search phrase',
  'cleanupDashboard.error.title': "Can't get cleanup tasks",
  'cleanupDashboard.error.description': 'Please try again later',
};

export default cleanupDashboard;
