import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import React, {ReactElement, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {Button, TextInput} from '@sabre/spark-react-core';
import {Separator} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {dateFormat} from '@scm/components/utils/common';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {sunsetMessageSchema} from './schema/sunsetMessageSchema';
import {messageName, subjectName, SunsetMessageValues} from './schema/SunsetMessageValues';
import {format} from 'date-fns';
import {TextEditor} from '@scm/components';
import {textEditorLengthErrorName} from '@scm/product-components/schemas/updateProductSchema';
import {Container} from './SunsetProcedure';
import SunsetMessageModal from './SunsetMessageModal';

const SunsetMessage = ({name, sku}: {name?: string; sku?: string}) => {
  const {formatMessage} = useIntl();
  const currentDate = format(new Date(), dateFormat);
  const {
    watch,
    setValue,
    control,
    getValues,
    formState: {isValid},
  } = useForm<SunsetMessageValues>({
    mode: 'onChange',
    defaultValues: {
      [subjectName]: `Planned sunset of ${name} Red App on ${currentDate}`,
      [messageName]: `We wanted to inform you that on <b>${currentDate}</b>, the <b>${name}</b> Red App that you are using will be retired.

The purpose behind the retirement of this Red App is related to low usage volumes, thus inability to support this Red App anymore. If you have any questions, please reach out to scm-support@sabre.com.`,
    },
    resolver: yupResolver(sunsetMessageSchema),
  });
  const subjectMaxLength = 200;
  const messageMaxLength = 4000;
  const disabledSunsetMessageButton = !isValid || getValues(messageName) === '<p><br></p>';
  const [openSunsetMessageModal, setOpenSunsetMessageModal] = useState(false);

  return (
    <Container>
      <Separator className="spark-mar-l-2 spark-mar-b-1 spark-pad-l-0 spark-mar-r-0 spark-pad-r-0 spark-bold spark-large col-sm-10">
        <FormattedMessage id="sunset.separator.sendMessageToBuyers" />
      </Separator>

      <Form onSubmit={e => e.preventDefault()} className="spark-mar-l-2">
        <Controller
          name={subjectName}
          control={control}
          render={({field}): ReactElement => (
            <TextInput
              {...field}
              characterCount
              maxLength={subjectMaxLength}
              label={formatMessage({id: 'sunset.sendMessageForm.subject.label'})}
            />
          )}
        />

        <p className="spark-small spark-mar-b-0 spark-flex">
          <FormattedMessage id="sunset.sendMessageForm.message.label" />
        </p>
        <Controller
          name={messageName}
          control={control}
          render={(_): ReactElement => (
            <TextEditor
              editorState={watch(messageName)}
              setValue={setValue}
              valueName={messageName}
              maxCharacters={messageMaxLength}
              minCharacters={1}
              errorName={textEditorLengthErrorName}
              isRequired
            />
          )}
        />

        <SendButtonContainer className="spark-flex spark-mar-t-1">
          <Button
            className="spark-btn-group-primary"
            size={ButtonSize.SMALL}
            disabled={disabledSunsetMessageButton}
            onClick={() => setOpenSunsetMessageModal(true)}
            data-testid="sendSunsetMessageButton"
          >
            <FormattedMessage id="sunset.button.sendMessageToBuyers" />
          </Button>
        </SendButtonContainer>
      </Form>
      <SunsetMessageModal
        open={openSunsetMessageModal}
        setOpen={setOpenSunsetMessageModal}
        redAppId={sku as string}
        subject={getValues()[subjectName]}
        message={getValues()[messageName]}
      />
    </Container>
  );
};

const SendButtonContainer = styled.div`
  justify-content: flex-end;
`;

const Form = styled.form`
  width: 83.5%;

  @media (max-width: ${deviceWidths.md}px) {
    p {
      padding-bottom: 0;
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
  }
`;

export default SunsetMessage;
