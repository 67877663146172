import {FormattedMessage} from 'react-intl';
import React from 'react';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import styled from 'styled-components';

export enum LimitationButtonOperation {
  ADD = 'add',
  REMOVE = 'remove',
}

const LimitationActionButton = ({
  operation,
  handleAction,
  disabled,
  name,
}: {
  operation: LimitationButtonOperation;
  handleAction: () => void;
  disabled?: boolean;
  name: string;
}) => (
  <ButtonWrapper
    size={ButtonSize.EXTRA_SMALL}
    className={`col-xs-5 spark-mar-r-.5 ${operation === LimitationButtonOperation.REMOVE && 'spark-btn--negative'}`}
    onClick={handleAction}
    disabled={disabled}
    aria-label={operation === LimitationButtonOperation.ADD ? 'add code' : 'remove code'}
    name={name}
  >
    <FormattedMessage
      id={
        operation === LimitationButtonOperation.ADD
          ? 'productDetails.limitations.storefront.add'
          : 'productDetails.limitations.storefront.remove'
      }
    />
  </ButtonWrapper>
);

const ButtonWrapper = styled(Button)`
  margin-top: 15px;
  height: 30px;
`;

export default LimitationActionButton;
