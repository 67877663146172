export const companyManagement = {
  'page.title': 'Manage Company',
  'header.add': 'Assign New Developer',
  'companyManagement.description.content':
    'Here you can define which developers should have access at the company level - and whether they should have access to all Red Apps, be able to manage company and add proposals. If you want to add a developer from outside your company then please write to {email}.',
  'companyManagement.action.edit': 'Edit',
  'companyManagement.action.remove': 'Remove',
  'noSellers.description': 'No developers for specific seller',
  'noProducts.description': 'No products for specific seller',
  'assign.noAccess.errorTitle': 'Request rejected',
  'assign.noAccess.error':
    'While we are working on a permanent solution, at this point you can only assign developers from the same SC Code as yours. To assign developers from other SC Codes, please contact our support: <a href="mailto:scm-support@sabre.com">scm-support@sabre.com</a>.',
  'assignSeller.companyLevel.title': 'Assign New Developer - Company Level',
  'assignSeller.companyLevel.description': 'Specify the user who should be assigned to company level access.',
  'assignSeller.companyLevel.pcc': 'PCC',
  'assignSeller.companyLevel.epr': 'EPR',
  'assignSeller.companyLevel.canAccessAllProducts': 'Access To All Products',
  'assignSeller.companyLevel.canSubmitProposal': 'Submit Proposal',
  'assignSeller.companyLevel.canManageCompany': 'Manage Company',
  'assignSeller.companyLevel.developerAssigned': 'Developer already assigned to this seller.',
  'assignSeller.companyLevel.assignDeveloper.tooltip': 'Assign new Developer to selected company',
  'assignSeller.companyLevel.search.tooltip': 'Search by: developer name, e-mail, PCC, EPR',
  'assignSeller.companyLevel.search.product.tooltip': 'Search by: product name, developer name, e-mail, PCC, EPR',
  'assignSeller.assignProduct': 'Assign Developer',
  'assignSeller.productLevel.title': 'Assign New Developer - Product Level',
  'assignSeller.productLevel.description':
    'Specify the user who should have access to the <strong>{redAppName}</strong>.',
  'editDeveloper.companyLevel.title': 'Edit Developer - Company Level',
  'removeDeveloper.modal.title': 'Remove developer access',
  'removeDeveloper.companyLevel.modal.description':
    'Are you sure you want to remove {firstName} {lastName} (AA/{pcc}/{epr}) developer access from {sellerName} company?',
  'removeDeveloper.productLevel.modal.description':
    'Are you sure you want to remove {firstName} {lastName} (AA/{pcc}/{epr}) developer access from {productName} product?',
  'removeDeveloper.modal.successTitle': 'Success.',
  'removeDeveloper.modal.success': 'Developer access removed from product.',
  'removeDeveloper.modal.errorTitle': 'Something went wrong during developer access removal from product.',
  'removeDeveloper.modal.error': 'Error.',
};
