import {Button} from '@sabre/spark-react-core';
import React from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {FormattedMessage} from 'react-intl';
import {RoadmapTabI} from '../Roadmap';

type RoadmapTabProps = {
  tab: RoadmapTabI;
  handler: (tab: RoadmapTabI) => void;
};

const RoadmapTab = ({tab, handler}: RoadmapTabProps) => {
  const navigationTabs = new Map(
    Object.entries({
      All: 'roadmap.tab.all',
      'Needs Feedback': 'roadmap.tab.needFeedback',
      'In Progress': 'roadmap.tab.inProgress',
      Completed: 'roadmap.tab.complete',
    })
  );

  return (
    <ButtonContainer
      className="spark-pad-1 spark-mar-r-.5 spark-mar-l-.5"
      active={tab?.isActive}
      textOnly
      onClick={(_): void => handler({name: tab.name, isActive: true})}
    >
      <p aria-label={`${navigationTabs.get(tab.name)}`}>
        <FormattedMessage id={navigationTabs.get(tab.name)} />
      </p>
    </ButtonContainer>
  );
};

export const ButtonContainer = styled(Button)<{active?: boolean}>`
  color: ${props => (props.active ? colors.grey1000 : colors.grey700)} !important;
  background: ${props => (props.active ? colors.grey150 : colors.white)} !important;

  &&:hover {
    background: ${colors.grey100};
    color: ${colors.black};
  }
`;

export default RoadmapTab;
