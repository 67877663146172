import React, {useContext} from 'react';
import {ProposalContext} from '../../../ProposalForm';
import SectionHeader from './SectionHeader';
import ReviewRow from './ReviewRow';
import {isPaidDescriptionName, isPaidName, ProposalValues} from '../../../ProposalValues';

const pricingFields: Array<keyof ProposalValues> = [isPaidName, isPaidDescriptionName];

const ReviewComponent = () => {
  const {watch} = useContext(ProposalContext);
  return (
    <>
      <SectionHeader id="review.pricing.title" />
      {pricingFields.map(name => watch(name) !== undefined && <ReviewRow key={name} name={name} />)}
    </>
  );
};

export default ReviewComponent;
