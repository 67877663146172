const review = {
  'review.title': 'Review & Submit',
  'review.additionalStatement':
    'Please review the provided information. In the case you would like to change anything use buttons below (back/continue) or the proposal steps above to go to the correct step.',
  'review.definition.title': 'Definition',
  'review.insurance.title': 'Insurance',
  'review.pricing.title': 'Pricing',
  'review.servicesAndSecurity.title': 'Services & Security',
  'review.security.title': 'Security',
  'review.termsAndCondition': 'Terms & Conditions',
  'review.redAppName': 'Red App Name',
  'review.descriptionName': 'Description',
  'review.redAppCategory': 'Red App Category',
  'review.flowDiagram': 'Flow Diagram',
  'review.interactsWithEndTraveler': 'Interacts With End Traveler',
  'review.interactsWithEndTravelerDescription':
    'Please describe how will this app interact with the agency and/or traveler',
  'review.providePnrDocumentation': 'My Red App provides/executes PNR documentation',
  'review.providePnrDocumentationDescription': 'Please describe how will this app provides/executes PNR documentation',
  'review.connectToGds': 'I connect to any GDS today',
  'review.connectToGdsDescription': 'Please provide the details',
  'review.developmentType': 'My development type',
  'review.technologies': 'Select the technologies you will be using to build your Red App',
  'review.technologiesDescription': 'Provide other technologies, not listed above',
  'review.regionalization.title': 'Regionalization',
  'review.allRegions': 'Global',
  'review.regionCodes': 'Regions',
  'review.countryCodes': 'Countries',
  'review.regionalizationDescription': 'Regionalization comment',
  'review.isPaid': 'My Red App is chargeable (paid)',
  'review.isPaidDescription':
    'Please provide the details what will be the payment model of this Red App (one-time fee, recurring, negotiable, per PCC, per EPR)',
  'review.sabreServices': 'Sabre Services (Host, Web-Services)',
  'review.hostCommandsDescription':
    'Please provide which Host commands, Sabre Web-Services, etc. will be used and why they are needed?',
  'review.bargainFinderMax': 'If using APIs, does it connect to Bargain Finder Max?',
  'review.externalServices': 'External Services',
  'review.externalServicesDescription':
    'Which External services (please be specific, e.g., Google Analytics, your own services etc.) would your Red App communicate to and why are they needed?',
  'review.utilizedMethod': 'What is the method utilized to authenticate service?',
  'review.newSabreRedTechnologies':
    'My Red App will be built using new Sabre Red 360 technologies (such as Nudge, Widget, Human Tasks etc.)',
  'review.newSabreRedTechnologiesDescription': 'What Sabre Red 360 technologies will your Red App be built with?',
  'review.financialInformation':
    'My Red App will process, transmit, or store credit card, debit card, or any other financial information',
  'review.pciData': 'Please select all PCI data that Red App processes, stores or transmits',
  'review.pciCertified': 'If utilizing a third-party processor, are they PCI certified by an auditor?',
  'review.pciAdditionalDetails':
    'If necessary, please list additional details for truncation, masking or tokenization of PCI data',
  'review.panRendered': 'Please describe how the PAN is rendered unreadable everywhere it is stored',
  'review.retentionPeriod':
    'If necessary, please list additional details for truncation, masking or tokenization of PCI data',
  'review.dataAdmin': 'Who will process the data and how? Please list the names',
  'review.personalInformation':
    'My Red App will process, transmit, or store personal information such as passport number, date of birth, address, phone, etc.',
  'review.piiData': 'Please select all PII data that the Red App process stores or transmits',
  'review.dataForwarded': 'How is that data forwarded (email, Web Services call, etc.)?',
  'review.euData':
    'Will the Red App store or process data for EU nationals? If so, will the data be stored or processed outside of the EU? Please list the names of locations that will be utilized for this app',
  'review.gdprCompliant': 'Is the Red App compliant with GDPR? Please describe how compliance is managed',
  'review.piiDataRetention': 'How long will this data be stored? What’s the default data retention?',
  'review.piiDataRetentionAdmin': 'Who can manage the data retention?',
  'review.localData': 'Will any of the locally saved data be shared with a third-party service?',
  'review.thirdPartyNetwork': 'My Red App retrieves PII from GDS and forwards to a third-party network',
  'review.dataForwardedThirdParty':
    'How is this forwarded (email, web services, call to third-party hosted system, other)?',
  'review.protocol': 'What is the protocol (https/http/sftp/VPN) between the client and third-party?',
  'review.dataProtection': 'Describe how your Red App data will be protected at rest and in motion',
  'review.coreData': 'My Red App adds data not already defined, collected as core GDS information',
  'review.freeTextRemarks': 'Does it rely on a free text remarks field in the PNR?',
  'review.freeTextRemarksDescription': 'Please specify',
  'review.numberOfTransaction': 'Projected number of transactions to be processed (private use vs global)',
  'review.alignSecureCodingPractices':
    'My Red App development will align with secure coding best practices (i.e. OWASP guidelines)',
  'review.patchTimeline':
    'What is the default timeline for patching vulnerabilities discovered in the code by automated scanning tools or manual processes, identified as a high, medium or low risk?',
  'review.success.title': 'Thank you for submitting the proposal.',
  'review.success.content': 'A Sabre representative will respond to your request within 72 business hours.',
  'review.error.title': 'Your proposal cannot be submitted.',
  'review.error.content':
    'Please try to submit your proposal once again. If submitting the proposal will not be possible, please reach out to {email}.',
  'review.accounting': 'Accounting',
  'review.dataType': 'Which element will be utilized (accounting or back-office data)',
  'review.attributesDescription': 'Which specific data attributes which will be leveraged?',
  'scribeScripts.success.title': 'Thank you for submitting the Scribe Scripts.',
  'pfKeys.success.title': 'Thank you for submitting the PF Keys.',
  'scribeScriptsAndPfKeys.success.content':
    'Product should be visible in My Products list and ready for test on PROD within few minutes.',
};

export default review;
