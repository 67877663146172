import React from 'react';
import CenterItems from 'components/styledComponents/CenterItems';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

const NotSupportedBrowser = () => (
  <NotSupportedBrowserContainer>
    <CenterItems>
      <div className="spark-beta spark-mar-b-2">
        <FormattedMessage id="error.title.notSupportedBrowser" />
      </div>
      <div className="spark-delta spark-pad-b-2">
        <FormattedMessage id="error.message.notSupportedBrowser" />
      </div>
    </CenterItems>
  </NotSupportedBrowserContainer>
);

const NotSupportedBrowserContainer = styled.div.attrs(() => ({
  className: 'spark-mar-2 spark-pad-2',
}))`
  min-height: 80vh;
`;

export default NotSupportedBrowser;
