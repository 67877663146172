/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CcrzEPageLabelCSObject
 */
export interface CcrzEPageLabelCSObject {
    /**
     * 
     * @type {string}
     * @memberof CcrzEPageLabelCSObject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEPageLabelCSObject
     */
    ccrzPageLabelIdC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEPageLabelCSObject
     */
    ccrzPageNameC?: string;
    /**
     * 
     * @type {number}
     * @memberof CcrzEPageLabelCSObject
     */
    ccrzSequenceC?: number;
    /**
     * 
     * @type {string}
     * @memberof CcrzEPageLabelCSObject
     */
    ccrzStorefrontC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEPageLabelCSObject
     */
    ccrzValueRTC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEPageLabelCSObject
     */
    ccrzValueC?: string;
}

/**
 * Check if a given object implements the CcrzEPageLabelCSObject interface.
 */
export function instanceOfCcrzEPageLabelCSObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CcrzEPageLabelCSObjectFromJSON(json: any): CcrzEPageLabelCSObject {
    return CcrzEPageLabelCSObjectFromJSONTyped(json, false);
}

export function CcrzEPageLabelCSObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CcrzEPageLabelCSObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'ccrzPageLabelIdC': !exists(json, 'ccrz__PageLabelId__c') ? undefined : json['ccrz__PageLabelId__c'],
        'ccrzPageNameC': !exists(json, 'ccrz__PageName__c') ? undefined : json['ccrz__PageName__c'],
        'ccrzSequenceC': !exists(json, 'ccrz__Sequence__c') ? undefined : json['ccrz__Sequence__c'],
        'ccrzStorefrontC': !exists(json, 'ccrz__Storefront__c') ? undefined : json['ccrz__Storefront__c'],
        'ccrzValueRTC': !exists(json, 'ccrz__ValueRT__c') ? undefined : json['ccrz__ValueRT__c'],
        'ccrzValueC': !exists(json, 'ccrz__Value__c') ? undefined : json['ccrz__Value__c'],
    };
}

export function CcrzEPageLabelCSObjectToJSON(value?: CcrzEPageLabelCSObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ccrz__PageLabelId__c': value.ccrzPageLabelIdC,
        'ccrz__PageName__c': value.ccrzPageNameC,
        'ccrz__Sequence__c': value.ccrzSequenceC,
        'ccrz__Storefront__c': value.ccrzStorefrontC,
        'ccrz__ValueRT__c': value.ccrzValueRTC,
        'ccrz__Value__c': value.ccrzValueC,
    };
}

