import React from 'react';
import {useDispatch} from 'react-redux';
import {toggleFilters} from 'pages/partnerDirectory/store/actions';
import EventCategories from 'googleAnalytics/EventCategories';
import SearchPanelMobile from 'pages/partnerDirectory/searchPanel/SearchPanelMobile';
import SearchPanelDesktop from 'pages/partnerDirectory/searchPanel/SearchPanelDesktop';
import logEvent from 'googleAnalytics/logEvent';

const SearchPanel = () => {
  const dispatch = useDispatch();
  const leftBarOnClickHandler = () => {
    logEvent({category: EventCategories.Directory, action: 'Clicked toggle filters'});
    dispatch(toggleFilters());
  };

  return (
    <div className="spark-flex">
      <SearchPanelMobile onClickHandler={leftBarOnClickHandler} />
      <SearchPanelDesktop onClickHandler={leftBarOnClickHandler} />
    </div>
  );
};

export default SearchPanel;
