import React, {ReactNode, useEffect, useRef} from 'react';
import SparkModal from '@sabre/spark/js/src/components/modal';

const Modal = ({
  isOpen,
  onClose,
  children,
  sizing = 'col-xs-12 col-sm-10 col-md-8 col-lg-8 col-xl-8',
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  sizing?: string;
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const sparkModal = new SparkModal(modalRef.current);
    return () => {
      sparkModal.remove();
    };
  }, [modalRef]);

  return (
    <div ref={modalRef} className={isOpen ? 'spark-modal active' : 'spark-modal'}>
      <div className="spark-modal__scroll">
        <div className={`spark-modal__content ${sizing}`}>
          <div className="spark-modal__body">
            <span
              tabIndex={0}
              className="spark-modal__close spark-icon-close"
              aria-label="Close Modal"
              title="Close Modal"
              onClick={onClose}
            />
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
