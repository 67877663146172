export const emptyStep = '…';

export const initialValues = [
  {value: 0, labelId: 'providerType.firstStep'},
  {value: emptyStep, labelId: 'proposal.emptyStep'},
  {value: emptyStep, labelId: 'proposal.review'},
];

export const developerStepsValues = [
  {value: 1, labelId: 'joinUsPage.step1'},
  {value: 2, labelId: 'joinUsPage.step2'},
  {value: 3, labelId: 'joinUsPage.step3'},
  {value: 4, labelId: 'joinUsPage.step4'},
];

export const onboardingStepsValues = [
  {value: 1, labelId: 'onboardingJoinUsPage.step1'},
  {value: 2, labelId: 'onboardingJoinUsPage.step2'},
  {value: 3, labelId: 'onboardingJoinUsPage.step3'},
  {value: 4, labelId: 'onboardingJoinUsPage.step4'},
];

export const onboardingProposalStepsValues = (isPublicRedApp: boolean | undefined) => {
  if (isPublicRedApp === undefined)
    return [
      {value: emptyStep, labelId: 'proposal.emptyStep'},
      {value: emptyStep, labelId: 'proposal.review'},
    ];
  else
    return isPublicRedApp
      ? [
          {value: 5, labelId: 'proposal.insurance'},
          {value: 6, labelId: 'proposal.definition'},
          {value: 7, labelId: 'proposal.regionalization'},
          {value: 8, labelId: 'proposal.pricing'},
          {value: 9, labelId: 'proposal.servicesAndSecurity'},
          {value: 10, labelId: 'proposal.review'},
        ]
      : [
          {value: 5, labelId: 'proposal.definition'},
          {value: 6, labelId: 'proposal.security'},
          {value: 7, labelId: 'proposal.review'},
        ];
};
