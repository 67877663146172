import React from 'react';
import {PanelMenuContainer} from '@scm/components/navigation/PanelMenu';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const ProposalViewPanel = () => (
  <IntrapageContainer>
    <PanelMenuContainer className="spark-menu spark-menu--panel">
      <ul className="spark-menu__list">
        <li className="spark-menu__list-item active">
          <span className="spark-menu__list-link">
            <FormattedMessage id="proposalView.generalInformation.title" />
          </span>
        </li>
      </ul>
    </PanelMenuContainer>
  </IntrapageContainer>
);

const IntrapageContainer = styled.div`
  flex-basis: 20%;
  border-right: 2px solid ${colors.grey150};
`;

export default ProposalViewPanel;
