import React, {ChangeEvent, ReactElement, useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Controller} from 'react-hook-form';
import {ProposalContext} from '../../../ProposalForm';
import {Checkbox} from '@sabre/spark-react-core';
import {
  interactsWithEndTravelerDescriptionName,
  interactsWithEndTravelerName,
  ProposalValues,
  providePnrDocumentationDescriptionName,
  providePnrDocumentationName,
} from '../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {TextArea} from './Description';

const RedAppDetailInfo = () => {
  const {formatMessage} = useIntl();
  const {watch, control, errors, setValue} = useContext(ProposalContext);
  const textFieldMaxLength = 500;

  const changeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void,
    value: boolean,
    fieldName: keyof ProposalValues
  ) => {
    onChange(event);
    if (value) {
      setValue(fieldName, '', {shouldValidate: true});
    }
  };

  return (
    <>
      <p className="spark-mar-t-2 spark-mar-b-1">
        <FormattedMessage id="definition.detailInformation.RedApp.mainLabel" />
      </p>
      <Controller
        name={interactsWithEndTravelerName}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => changeHandler(event, onChange, value, interactsWithEndTravelerDescriptionName)}
            checked={value}
            label={formatMessage({id: 'definition.detailInformation.RedApp.firstLabel'})}
          />
        )}
      />
      {watch(interactsWithEndTravelerName) && (
        <div className="spark-mar-b-2 spark-mar-r-1">
          <Controller
            name={interactsWithEndTravelerDescriptionName}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'definition.detailInformationDescription.RedApp.firstLabel'},
                  {
                    star: word => <span className="spark-danger">{word}</span>,
                  }
                )}
                multiLine
                characterCount
                maxLength={textFieldMaxLength}
                status={errors[field.name] ? MessageStatus.ERROR : undefined}
                statusMessage={errors[field.name] && errors[field.name]?.message}
              />
            )}
          />
        </div>
      )}
      <Controller
        name={providePnrDocumentationName}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => changeHandler(event, onChange, value, providePnrDocumentationDescriptionName)}
            checked={value}
            label={formatMessage({id: 'definition.detailInformation.RedApp.secondLabel'})}
          />
        )}
      />
      {watch(providePnrDocumentationName) && (
        <div className="spark-mar-b-2 spark-mar-r-1">
          <Controller
            name={providePnrDocumentationDescriptionName}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'definition.detailInformationDescription.RedApp.secondLabel'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                multiLine
                characterCount
                maxLength={textFieldMaxLength}
                status={errors[field.name] ? MessageStatus.ERROR : undefined}
                statusMessage={errors[field.name] && errors[field.name]?.message}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default RedAppDetailInfo;
