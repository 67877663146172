export const pricingDetails = {
  'pricingDetails.title': 'Sabre Pricing Details',
  'pricingDetails.description.publicRedApps':
    'Please review the pricing options. To proceed to the next step, it is necessary to select the pricing option. If you would like to change it later, you will have to sign a new contract.',
  'pricingDetails.description.privateRedApps':
    'Please review the pricing for private Red Apps. To proceed to the next step, it is necessary to accept the pricing.',
  'pricingDetails.certificationFee.title': 'Certification Fee',
  'pricingDetails.certificationFee.description':
    'It is charged each time your Red App goes through the certification process, normally this is just once, unless there are any major updates to your App.',
  'pricingDetails.certificationFee.perEachRedApp': '$500 per each Red App',
  'pricingDetails.monthlyFee.title': 'Monthly Fee',
  'pricingDetails.monthlyFee.eprFee': '$3 per each Red App user (based on the users that have the app ordered)',
  'pricingDetails.monthlyFee.revenueShare': '70/30% (30% to Sabre) revenue share',
  'pricingDetails.monthlyFee.privateRedApp':
    '$3 per user for unlimited number of Red Apps (based on the users that have the app ordered)',
};
