import React from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {InternalRolesList, InternalRolesListInternalRolesEnum} from '../generated/users';
import {Button, Table, TableCell, TableRow} from '@sabre/spark-react-core';
import {UserModalAction} from '../pages/Users';
import {Loading} from '@scm/components';
import {ContentHeight} from './UsersDescription';
import NoUsers from './NoUsers';
import {deviceWidths} from '@scm/components/assets/deviceWidth';

const UserRolesList = ({
  isLoading,
  userRoles,
  handleOpen,
}: {
  isLoading: boolean;
  userRoles?: InternalRolesList;
  handleOpen: (action: UserModalAction, selectedRole?: InternalRolesListInternalRolesEnum) => void;
}) => {
  const {formatMessage} = useIntl();

  return (
    <UserRoleListContainer className="col-md-6">
      <ContentHeight className="spark-panel spark-pad-2">
        {isLoading ? (
          <CenteredLoading>
            <Loading label={formatMessage({id: 'common.data.loading'})} />
          </CenteredLoading>
        ) : userRoles ? (
          <Table className="spark-table">
            <CellWrapper>
              <div className="spark-bold">
                <FormattedMessage id="users.table.roleName" />
              </div>
            </CellWrapper>
            <ButtonsContainer>
              <CellWrapper>
                <IconButtonContainer
                  value="Add new role"
                  icon="spark-icon-math-add spark-icon--fill"
                  ariaLabel="Add new role"
                  tooltip={formatMessage({id: 'users.popup.add'})}
                  onClick={() => handleOpen(UserModalAction.ADD)}
                  tooltipDirectionX={TooltipDirectionX.RIGHT}
                  tooltipDirectionY={TooltipDirectionY.BOTTOM}
                />
              </CellWrapper>
            </ButtonsContainer>
            {userRoles.internalRoles?.map(role => (
              <TableRowContainer key={role}>
                <CellWrapper>
                  <div>{role}</div>
                </CellWrapper>
                <ButtonsContainer>
                  <CellWrapper>
                    <IconButtonContainer
                      isNegative
                      icon="spark-icon-trash spark-icon--fill"
                      ariaLabel="Trash icon"
                      tooltip={formatMessage({id: 'users.popup.delete'})}
                      onClick={() => handleOpen(UserModalAction.DELETE, role)}
                      tooltipDirectionX={TooltipDirectionX.RIGHT}
                      tooltipDirectionY={TooltipDirectionY.BOTTOM}
                    />
                  </CellWrapper>
                </ButtonsContainer>
              </TableRowContainer>
            ))}
          </Table>
        ) : (
          <NoUsers />
        )}
      </ContentHeight>
    </UserRoleListContainer>
  );
};

const CenteredLoading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const UserRoleListContainer = styled.div`
  position: relative;
  @media (min-width: ${deviceWidths.md}) {
    padding-right: 0.5rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;
`;

const IconButtonContainer = styled(Button)<{
  isNegative?: boolean;
}>`
  background-color: ${props => (props.isNegative ? colors.red : colors.primaryActionGreen)} !important;
  padding: 0 1rem 0 1rem !important;
  font-size: 14px !important;
  color: ${colors.white} !important;
`;

const TableRowContainer = styled(TableRow)`
  &&:hover {
    background-color: ${colors.grey50};
  }
`;

const CellWrapper = styled(TableCell)`
  align-content: center;
`;

export default UserRolesList;
