import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';
import {
  packagesName,
  skipCertificateCheckName,
  skipCompareName,
  SvsValues,
  tasksName,
  thresholdsName,
} from './SvsValues';

export const initialValues: SvsValues = {
  [tasksName]: [],
  [packagesName]: [],
  [thresholdsName]: [],
  [skipCompareName]: Confirmation.Yes,
  [skipCertificateCheckName]: Confirmation.Yes,
};
