import {
  PARTNER_DIRECTORY_ADD_FILTER_ACTION,
  PARTNER_DIRECTORY_REFETCH_ACTION,
  PARTNER_DIRECTORY_REMOVE_FILTER_ACTION,
  PARTNER_DIRECTORY_SEARCH_ACTION,
  PARTNER_DIRECTORY_SET_AVAILABLE_FILTER_OPTIONS_ACTION,
  PARTNER_DIRECTORY_SET_REFETCH_ACTION,
  PARTNER_DIRECTORY_SORT_ACTION,
  PARTNER_DIRECTORY_TOGGLE_FILTERS_ACTION,
  PartnerDirectoryState,
  REGIONS,
  CATEGORIES,
  TIERS,
} from 'pages/partnerDirectory/store/types';
import {createReducer} from 'utils/reduxUtils';
import {AnyAction} from 'redux';
import Sorting from 'utils/sorting';

const initialState: PartnerDirectoryState = {
  isFilterPanelExpanded: true,
  filters: {regions: new Array<string>(), categories: new Array<string>(), tiers: new Array<string>()},
  available: {regions: new Array<string>(), categories: new Array<string>()},
  sorting: Sorting.FEATURED,
};

export const partnerDirectoryReducer = createReducer(initialState, {
  [PARTNER_DIRECTORY_TOGGLE_FILTERS_ACTION]: state => ({...state, isFilterPanelExpanded: !state.isFilterPanelExpanded}),
  [PARTNER_DIRECTORY_ADD_FILTER_ACTION]: (state: PartnerDirectoryState, {filterType, filter}: AnyAction) => ({
    ...state,
    filters: addFilter(state.filters, filterType, filter),
  }),
  [PARTNER_DIRECTORY_REMOVE_FILTER_ACTION]: (state: PartnerDirectoryState, {filterType, filter}: AnyAction) => ({
    ...state,
    filters: removeFilter(state.filters, filterType, filter),
  }),
  [PARTNER_DIRECTORY_SET_REFETCH_ACTION]: (state: PartnerDirectoryState, {refetch}: AnyAction) => ({
    ...state,
    refetch,
  }),
  [PARTNER_DIRECTORY_REFETCH_ACTION]: (state: PartnerDirectoryState) => {
    const {regions, categories, tiers} = state.filters;
    state.refetch && state.refetch({regions, categories, tiers});
    return state;
  },
  [PARTNER_DIRECTORY_SET_AVAILABLE_FILTER_OPTIONS_ACTION]: (state: PartnerDirectoryState, {available}: AnyAction) => ({
    ...state,
    available,
  }),
  [PARTNER_DIRECTORY_SEARCH_ACTION]: (state: PartnerDirectoryState, {searchTerm}: AnyAction) => ({
    ...state,
    searchTerm,
  }),
  [PARTNER_DIRECTORY_SORT_ACTION]: (state: PartnerDirectoryState, {sorting}: AnyAction) => ({
    ...state,
    sorting,
  }),
});

const addFilter = (filters: {regions: Array<string>, categories: Array<string>, tiers: Array<string>}, filterType: string, filter: string) => {
  switch (filterType) {
    case REGIONS:
      return {
        regions: [...filters.regions, filter],
        categories: filters.categories,
        tiers: filters.tiers,
      };
    case CATEGORIES:
      return {
        categories: [...filters.categories, filter],
        regions: filters.regions,
        tiers: filters.tiers,
      };
    case TIERS:
      return {
        tiers: [...filters.tiers, filter],
        categories: filters.categories,
        regions: filters.regions,
      };
    default:
      return {
        tiers: filters.tiers,
        categories: filters.categories,
        regions: filters.regions,
      };
  }
};

const removeFilter = (filters: {regions: Array<string>, categories: Array<string>, tiers: Array<string>}, filterType: string, filter: any) => {
  switch (filterType) {
    case REGIONS:
      return {
        regions: filters.regions.filter(r => r !== filter),
        categories: filters.categories,
        tiers: filters.tiers,
      };
    case CATEGORIES:
      return {
        categories: filters.categories.filter(c => c !== filter),
        regions: filters.regions,
        tiers: filters.tiers,
      };
    case TIERS:
      return {
        tiers: filters.tiers.filter(t => t !== filter),
        categories: filters.categories,
        regions: filters.regions,
      };
    default:
      return {
        tiers: filters.tiers,
        categories: filters.categories,
        regions: filters.regions,
      };
  }
};
