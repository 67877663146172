const certificationEngineerDashboard = {
  'certificationEngineerDashboard.title': 'Certification Engineer Dashboard',
  'certificationEngineerDashboard.certification.message':
    'Below Versions are during certification process and require some action. Please follow the certification procedures.',
  'certificationEngineerDashboard.proposals.message': 'Below Proposals are waiting for approval decision.',
  'certificationEngineerDashboard.readyToPublish.message':
    'Below Versions are marked by developer as Ready To Be Published. Please verify all the necessary requirements (description, icon if app is Public) and publish the app.',
  'certificationEngineerDashboard.scheduledVersions.message':
    'Sabre Red configuration for below Versions has been scheduled for later due to Sabre freezes. They will be automatically processed according to the specified dates.',
  'certificationEngineerDashboard.testing.message':
    'Below Versions should be now tested by their developers and beta testers.',
  'certificationEngineerDashboard.troubleshooting.message':
    'Below Versions should be investigated as their statuses indicate that some issues occurred during certification process.',
  'certificationEngineerDashboard.provider': 'Provider',
  'certificationEngineerDashboard.redAppName': 'Red App Name',
  'certificationEngineerDashboard.version': 'Version',
  'certificationEngineerDashboard.lastUpdate': 'Last Update',
  'certificationEngineerDashboard.assignedTo': 'Assigned To',
  'certificationEngineerDashboard.status': 'Status',
  'certificationEngineerDashboard.submitted': 'Submitted',
  'certificationEngineerDashboard.environment': 'Environment',
  'certificationEngineerDashboard.scheduledDate': 'Scheduled Date',
  'certificationEngineerDashboard.hint': ' ',
  'certificationEngineerDashboard.hint.bundleSubmitted':
    '<p>The Version was submitted by user but not validated by SVS. Please check logs from time of submitting the bundle to get more details. Expected status is Validated or Validation Failed.</p> <p>Please re-trigger SVS request, to start a process again.</p>',
  'certificationEngineerDashboard.hint.bundleValidated':
    '<p>The Version was successfully validated by SVS, but Sabre Red configuration on CERT could not be completed.</p> <p>Common issues:</p> <ul><li> version can be scheduled to be processed after the freeze. Verify if there is no freeze in Freeze Calendar </li> <li> the .xml file could not be created due to missing data of Provider, Red App or Purchase Contact user </li> <li> developer (EPR) who submitted the version does not exist in Sabre Red </li> <li> the product with the same name already exists in CM </li></ul> <space>Please re-trigger SVS request, to start a process again or process CM CERT configuration manually, and then change status to Ready for Test (CERT).</space>',
  'certificationEngineerDashboard.hint.approvedForProd':
    '<p>The Version was approved in certification process, however Sabre Red configuration on PROD could not be completed.</p> <p>Common issues:</p> <ul><li>version can be scheduled to be processed after the freeze. Verify if there is no freeze in Freeze Calendar </li> <li>the .xml file could not be created due to missing data of Provider, Red App or Purchase Contact user</li><li>developer (EPR) who submitted the version does not exist in Sabre Red</li><li>the product with the same name already exists in CM</li></ul> <space> Process CM PROD configuration manually, and then change status to Ready for Test (PROD).</space>',
  'certificationEngineerDashboard.hint.rollbackInProgress':
    'Rollback operation should take few seconds. If version stuck in this status for longer, please check if version roles are still present in PROD CM and contact dev team to check the logs.',
  'certificationEngineerDashboard.hint.tooltip': 'Hint',
  'certificationEngineerDashboard.hint.title': 'Troubleshooting Tip',
};

export default certificationEngineerDashboard;
