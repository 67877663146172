import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './whyDidYouRender';
import '@sabre/spark/dist/css/spark.light.min.css';
import 'googleAnalytics/googleAnalyticsInit';
import './translations/configureYupTranslations';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import {IntlProvider} from 'react-intl';
import en from './translations/en';
import configureStore from 'utils/rootReducer';
import {Provider} from 'react-redux';
import {ApolloProvider} from '@apollo/client';
import apolloClient from 'utils/apolloClient';
import AuthenticationProvider from '@scm/authentication/provider/AuthenticationProvider';
import AccessibilityProvider from '@scm/components/accessibility/AccessibilityProvider';

const language = 'en';

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={language} key={language} messages={en}>
      <Provider store={store}>
        <AuthenticationProvider>
          <ApolloProvider client={apolloClient}>
            <AccessibilityProvider>
              <Root />
            </AccessibilityProvider>
          </ApolloProvider>
        </AuthenticationProvider>
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
