import {HeaderText} from '@scm/admin-centre/src/components/App';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useEffect, useState} from 'react';
import {Button, Modal, TextInput} from '@sabre/spark-react-core';
import {ButtonSize, MessageStatus, ToastType} from '@sabre/spark-react-core/types';
import {
  Configuration,
  InternalRolesApi,
  InternalRolesList,
  InternalRolesListInternalRolesEnum,
} from '../generated/users';
import {apiBaseLink} from '../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {openToast} from '@scm/components/messaging/openToast';
import {useForm} from 'react-hook-form';
import UserDataValues, {eprName, pccName, userRolesName} from '../interfaces/UserDataValues';
import UserRolesList from '../components/UserRolesList';
import styled from 'styled-components';
import AssignNewUserRoleModal from './modals/AssignNewUserRoleModal';
import RemoveUserRoleModal from './modals/RemoveUserRoleModal';
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom';
import {
  EPR_MAX_LENGTH,
  EPR_REGEX,
  EPR_SHORT_MESSAGE,
  makePccUppercase,
  PCC_MAX_LENGTH,
  PCC_REGEX,
  PCC_SHORT_MESSAGE,
} from '@scm/components/pccEpr/pccEprSchema';
import UsersDescription from '../components/UsersDescription';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';

const fetchUserRoles = (pcc: string, epr: string) =>
  new InternalRolesApi(
    new Configuration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() as string,
      middleware,
    })
  ).getInternalRoles({pcc, epr});

export const patchUserRoles = (pcc: string, epr: string, internalRolesList: InternalRolesList) =>
  new InternalRolesApi(
    new Configuration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() as string,
      middleware,
    })
  ).updateInternalRoles({pcc, epr, internalRolesList});

export enum UserModalAction {
  ADD = 'add',
  DELETE = 'delete',
}

export const Users = () => {
  const [searchParams] = useSearchParams();
  const {
    setValue,
    watch,
    formState: {touchedFields},
  } = useForm<UserDataValues>({
    mode: 'onChange',
    defaultValues: {
      [pccName]: searchParams.get('pcc') ?? '',
      [eprName]: searchParams.get('epr') ?? '',
    },
  });
  const [modalKey, setModalKey] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isReload, setIsReload] = useState(true);
  const [chosenModal, setChosenModal] = useState(UserModalAction.ADD);
  const [markedRole, setMarkedRole] = useState<InternalRolesListInternalRolesEnum>();
  const [isLoading, setIsLoading] = useState(false);
  const {formatMessage} = useIntl();

  const isPccInvalid = !PCC_REGEX.test(watch(pccName));
  const isEprInvalid = !EPR_REGEX.test(watch(eprName));
  const isSubmitButtonDisabled = isPccInvalid || isEprInvalid;

  const navigate = useNavigate();

  useEffect(() => {
    if (setIsReload) {
      setValue(pccName, searchParams.get('pcc') ?? '');
      setValue(eprName, searchParams.get('epr') ?? '');

      if (watch(pccName) && watch(eprName)) {
        handleSubmitClick();
      }

      setIsReload(false);
    }
  }, [isReload]);

  const handleOpen = (action: UserModalAction, selectedRole?: InternalRolesListInternalRolesEnum) => {
    setChosenModal(action);
    setMarkedRole(selectedRole);
    setModalKey(prev => prev + 1);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsReload(true);
  };

  const userModals = {
    add: <AssignNewUserRoleModal handleClose={handleClose} watch={watch} />,
    delete: <RemoveUserRoleModal handleClose={handleClose} watch={watch} selectedRole={markedRole} />,
  };

  const enterPressEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isSubmitButtonDisabled) {
      useNavigateParams();
    }
  };

  const handleSubmitClick = () => {
    setIsLoading(true);
    fetchUserRoles(watch(pccName), watch(eprName))
      .then(res => setValue(userRolesName, res))
      .catch(() =>
        openToast(
          formatMessage({id: 'users.error.title'}),
          formatMessage({id: 'users.error.description'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      )
      .finally(() => setIsLoading(false));
  };

  const useNavigateParams = () => {
    navigate({search: createSearchParams({pcc: watch(pccName), epr: watch(eprName)}).toString()});
    handleSubmitClick();
  };

  return (
    <div className="spark-pad-2">
      <Modal
        key={modalKey}
        onClose={handleClose}
        open={isOpen}
        title={formatMessage({id: `users.modal.${chosenModal}.title`}, {values: {role: markedRole}})}
        titleHeadingLevel={2}
      >
        {userModals[chosenModal]}
      </Modal>
      <HeaderText>
        <FormattedMessage id="users.title" />
      </HeaderText>
      <UsersWrapper className="spark-flex spark-mar-b-1 spark-pad-r-.5">
        <TextInput
          name="pcc"
          label={formatMessage({id: 'users.input.pcc'})}
          placeHolder={formatMessage({id: 'users.input.pcc'})}
          value={watch(pccName)}
          maxLength={PCC_MAX_LENGTH}
          characterCount
          onChange={(_, value) => setValue(pccName, makePccUppercase(value), {shouldTouch: true})}
          onInput={event =>
            ((event.target as HTMLInputElement).value = makePccUppercase((event.target as HTMLInputElement).value))
          }
          onKeyPress={enterPressEvent}
          status={isPccInvalid && touchedFields.pcc ? MessageStatus.ERROR : undefined}
          statusMessage={PCC_SHORT_MESSAGE}
          className="spark-mar-b-0 spark-mar-r-1"
        />
        <TextInput
          name="epr"
          label={formatMessage({id: 'users.input.epr'})}
          placeHolder={formatMessage({id: 'users.input.epr'})}
          value={watch(eprName)}
          maxLength={EPR_MAX_LENGTH}
          characterCount
          onChange={(_, value) => setValue(eprName, makePccUppercase(value), {shouldTouch: true})}
          onInput={event =>
            ((event.target as HTMLInputElement).value = makePccUppercase((event.target as HTMLInputElement).value))
          }
          status={isEprInvalid && touchedFields.epr ? MessageStatus.ERROR : undefined}
          statusMessage={EPR_SHORT_MESSAGE}
          onKeyPress={enterPressEvent}
          className="spark-mar-b-0 spark-mar-r-1"
        />
        <ButtonWrapper>
          <Button size={ButtonSize.SMALL} disabled={isSubmitButtonDisabled} onClick={useNavigateParams}>
            <FormattedMessage id="common.findButton" />
          </Button>
        </ButtonWrapper>
      </UsersWrapper>
      <div className="row">
        <UserRolesList isLoading={isLoading} userRoles={watch(userRolesName)} handleOpen={handleOpen} />
        <UsersDescription />
      </div>
    </div>
  );
};

const UsersWrapper = styled.div`
  width: 50%;
  max-height: 60px;

  @media (max-width: ${deviceWidths.md}px) {
    width: 100%;
  }

  & > .spark-input {
    flex-grow: 1;
    border-color: ${colors.grey300};
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 0.85rem;
`;
