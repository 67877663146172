import React, {useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button} from '@sabre/spark-react-core';
import {ProdTestersContextValue} from './ProdTestersContextValue';
import {testersTable} from './TestersValues';
import ProdTestersTable from './ProdTestersTable';
import {Pagination} from '@scm/components';
import {TableUtils} from '@scm/product-components/pages/productDetails/productTabs/testers/ProdTesters';
import {
  editedTesterName,
  isTesterAddingName,
} from '@scm/product-components/pages/storefrontData/interfaces/TesterDataValues';
import {
  DeleteTesterHandlerType,
  Tester,
  testersHeaders,
} from '@scm/product-components/pages/productDetails/productTabs/testers/TesterTable';

export const ProdTestersContext = React.createContext<ProdTestersContextValue>(
  {} as unknown as ProdTestersContextValue
);

const ProdTesters = ({
  watch: watchTesterForm,
  setValue: setValueTesterForm,
  reset: resetTesterForm,
  handleSubmit: handleSubmitTesterForm,
}: any) => {
  const {formatMessage} = useIntl();
  const paginationInterval = 10;
  const [currentElement, setCurrentElement] = useState(0);

  const paginationClickHandler = (evt: React.MouseEvent<HTMLAnchorElement>, moveIndex?: number) => {
    const evtTargetTextContent = evt.currentTarget.firstChild?.textContent as string;
    if (moveIndex === undefined) {
      setCurrentElement(+evtTargetTextContent - 1);
    } else {
      setCurrentElement(currentElement + moveIndex);
    }
  };

  const deleteTestersHandler: DeleteTesterHandlerType = (nr: string) => {
    setValueTesterForm(
      testersTable,
      watchTesterForm(testersTable)
        .filter((tester: Tester) => tester.nr !== nr)
        .map((tester: Tester, index: number) => ({
          ...tester,
          nr: String(index + 1),
        }))
    );

    if (!(watchTesterForm(testersTable).length % paginationInterval)) {
      setCurrentElement(beforePage => (!beforePage ? 0 : beforePage - 1));
    }
  };

  const headers = useMemo(() => [...testersHeaders.slice(0, 2), ''], []);

  return (
    <ProdTestersContext.Provider value={{watchTesterForm, setValueTesterForm, resetTesterForm, handleSubmitTesterForm}}>
      <p className="spark-mar-t-2 spark-mar-b-1">
        <FormattedMessage id="definition.prodTesters.title" />
      </p>
      <p className="spark-mar-t-2 spark-mar-b-1">
        <FormattedMessage id="definition.prodTesters.info" />
      </p>
      <ProdTestersTable
        headers={headers}
        watch={watchTesterForm}
        deleteTestersHandler={deleteTestersHandler}
        currentPaginationPage={currentElement}
        paginationInterval={paginationInterval}
      />
      {!watchTesterForm(isTesterAddingName) && (
        <p className="spark-mar-t-1">
          <Button
            textOnly
            onClick={() => {
              setValueTesterForm(isTesterAddingName, true);
              setValueTesterForm(editedTesterName, {
                nr: String(watchTesterForm(testersTable).length + 1),
                pcc: '',
                sabreId: '',
                created: new Date(),
              });
            }}
            className="spark-mar-r-2"
          >
            {formatMessage({id: 'definition.prodTesters.addButton.label'})}
          </Button>
        </p>
      )}

      <TableUtils className="row spark-mar-b-1">
        {watchTesterForm(testersTable).length > paginationInterval && (
          <Pagination
            allDataLength={watchTesterForm(testersTable).length}
            currentElement={currentElement}
            paginationInterval={paginationInterval}
            clickHandler={paginationClickHandler}
          />
        )}
      </TableUtils>
    </ProdTestersContext.Provider>
  );
};

export default ProdTesters;
