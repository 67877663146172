const users = {
  'users.title': 'Users',
  'users.input.pcc': 'PCC',
  'users.input.epr': 'EPR',
  'users.table.roleName': 'Role Name',
  'users.error.title': 'User not found.',
  'users.error.description': 'Provided user was not found. Please try again.',
  'users.popup.add': 'Add new role',
  'users.popup.delete': 'Delete selected role',
  'users.modal.add.title': 'Assign new user role',
  'users.modal.delete.title': 'Remove role',
  'users.modal.delete.description':
    'You are removing <strong>{role}</strong> from <strong>{pcc}/{epr}</strong>. The user will lose access to resources that require this role.',
  'users.toast.success.description': 'Role has been successfully changed.',
  'users.toast.error.description': 'Role has not been changed. Please try again.',
  'users.noUser.description':
    'Please enter the PCC and EPR in the form above, then click "find" button to view user\'s roles.',
  'users.roles.title': 'Roles Description',
  'users.adp': 'ADP role is dedicated to manage Proposal',
  'users.firstLevelSupport': 'First level support have read only access to all red apps',
  'users.secondLevelSupport': 'Second level support can mange red apps certification process',
  'users.admin': 'To request the Sabre Admin role please contact Development Team',
};

export default users;
