import {Middleware, ResponseContext} from '@scm/product-components/generated/product';
import {areTermsAndConditionsAccepted, clearTokens} from '@scm/authentication/utils/authentication';
import {redirectToLoginPage} from '../utils/redirections';

export const middleware: Middleware[] = [
  {
    post: async (context: ResponseContext): Promise<Response | void> => {
      const {terms_and_conditions_accepted} = areTermsAndConditionsAccepted();
      if (context.response.status === 401 && terms_and_conditions_accepted) {
        clearTokens();
        redirectToLoginPage();
      } else {
        return context.response;
      }
    },
  },
];
