const myProducts = {
  'myProducts.title': 'My Products',
  'myProducts.filterButton.firstLabel': 'All Products',
  'myProducts.filterButton.secondLabel': 'Action Required',
  'myProducts.linkButton.label': 'Add new product',
  'myProducts.announcement.title': 'Vote on what we should build in the Admin Centre!',
  'myProducts.announcement.description':
    'You can see what we are currently working on and have planned. Have your say on what matters most to you by voting for existing feature ideas.',
  'myProducts.searchBox.label': 'Search',
  'myProducts.searchBox.placeholder': 'Search',
  'myProducts.statuses.Waiting for First Version': 'Waiting for First Version',
  'myProducts.statuses.Bundle Under Review': 'Bundle Under Review',
  'myProducts.statuses.Bundle Validated': 'Bundle Validated',
  'myProducts.statuses.Pending For Cert': 'Pending For Cert',
  'myProducts.statuses.Certified': 'Certified',
  'myProducts.statuses.Approved For Prod': 'Approved For Prod',
  'myProducts.statuses.Validation Failed': 'Validation Failed',
  'myProducts.statuses.Failed On Cert': 'Failed On Cert',
  'myProducts.statuses.Certification Failed': 'Certification Failed',
  'myProducts.statuses.Cancelled': 'Cancelled',
  'myProducts.statuses.Rejected For Prod': 'Rejected For Prod',
  'myProducts.statuses.Failed On Prod': 'Failed On Prod',
  'myProducts.statuses.Published': 'Published',
  'myProducts.statuses.ProductObsolete': 'Obsolete',
  'myProducts.statuses.Submitted': 'Proposal Submitted',
  'myProducts.statuses.InReview': 'Proposal Under Review',
  'myProducts.statuses.Approved': 'Proposal Approved',
  'myProducts.statuses.Rejected': 'Proposal Rejected',
  'myProducts.statuses.Obsolete': 'Proposal Obsolete',
  'myProducts.statuses.Draft': 'Proposal Draft',
  'myProducts.statuses.Ready for Test (CERT)': 'Ready for Test (CERT)',
  'myProducts.statuses.Ready for Test (PROD)': 'Ready for Test (PROD)',
  'myProducts.statuses.PROD Tests Passed': 'PROD Tests Passed',
  'myProducts.statuses.Bundle Submitted': 'Bundle Submitted',
  'myProducts.statuses.CERT Tests Passed': 'CERT Tests Passed',
  'myProducts.statuses.Approved For PROD': 'Approved For PROD',
  'myProducts.statuses.CERT Tests Failed': 'CERT Tests Failed',
  'myProducts.statuses.Rejected For PROD': 'Rejected For PROD',
  'myProducts.statuses.PROD Tests Failed': 'PROD Tests Failed',
  'myProducts.statuses.Rollback in Progress': 'Rollback in Progress',
  'myProducts.statuses.Rolled Back': 'Rolled Back',
  'myProducts.action.proposal.approved.title': 'Proposal update',
  'myProducts.action.proposal.approved.description': 'Proposal was approved successfully',
  'myProducts.action.proposal.delete.notFound.title': 'Warning',
  'myProducts.action.proposal.delete.notFound.description': 'Proposal not found',
  'myProducts.action.proposal.delete.title': 'Success',
  'myProducts.action.proposal.delete.message': 'Proposal {name} has been successfully deleted.',
  'myProducts.action.proposal.remove.title': 'Success',
  'myProducts.action.proposal.remove.message': 'Proposal {name} has been successfully removed.',
  'myProducts.emptyList.message': 'No products created',
  'myProducts.favourite.add.title': 'Error',
  'myProducts.favourite.add.content': 'Cannot add item to favourites, please refresh page and try again later.',
  'myProducts.favourite.remove.title': 'Error',
  'myProducts.favourite.remove.content': 'Cannot remove item from favourites, please refresh page and try again later.',
  'myProducts.seePublishingProcess.tooltip': 'See the publishing process',
  'myProducts.seeCertificationProcess.tooltip': 'See the certification process',
};

export default myProducts;
