import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import Header from '../spark/header/Header';
import {Footer} from '@scm/components';
import styled from 'styled-components';
import MenuItems from './asideMenu/MenuItems';
import {createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider} from 'react-router-dom';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import ExternalUrls from '@scm/components/utils/externalUrls';
import Reports from '../pages/reports/Reports';
import ResourcesComponent from '../pages/resources/ResourcesComponent';
import Developers from '../pages/developers/Developers';
import PartnerProfile from '../pages/partnerProfile/PartnerProfile';
import CertificationEngineerDashboard from '../pages/certificationEngineerDashboard/CertificationEngineerDashboard';
import Cleanup from '../pages/cleanup/Cleanup';
import FreezeCalendar from '../pages/freezeCalendar/FreezeCalendar';
import {ProposalForm, ProposalViewPage} from '@scm/proposal';
import {Login} from '@scm/authentication';
import {AuthenticationContext} from '@scm/authentication/provider/AuthenticationProvider';
import {config} from '../config/config';
import {
  isAdpRole,
  isAgencyAdminRole,
  isCertificationEngineerRole,
  isSabreAdminRole,
  isSecondLevelSupportRole,
  isSecondLevelSupportRoleOrSabreAdmin,
  isSupportRole,
  userHasNoRoles,
} from '@scm/authentication/utils/authentication';
import {SellersContext} from '@scm/sellers/components/SellersProvider';
import {CertificationProposals} from '@scm/ce-proposals';
import {MockSsoLogin} from './mock/MockSsoLogin';
import {TermsAndConditionsModal} from '@scm/terms-and-conditions';
import {ITermsAndConditionsHelper} from '@scm/terms-and-conditions/utils/ITermsAndConditionsHelper';
import {useAnalytics} from '../googleAnalytics/useAnalytics';
import {redirectToLoginPage, redirectToNotAuthorizedPage} from '../utils/redirections';
import NotAuthorized from './NotAuthorized';
import {Helmet} from 'react-helmet';
import OnboardingsDetails from '@scm/ce-proposals/components/onboardingsDetails/OnboardingsDetails';
import {RoadmapPage} from '@scm/roadmap/components/RoadmapProvider';
import {MyProducts} from '@scm/my-products';
import {Version} from '@scm/version';
import {ProductDetails} from '@scm/product-details';
import NotificationSettings from '@scm/authentication/pages/notifications/user/NotificationSettings';
import ManageNotificationsList from '@scm/authentication/pages/notifications/admin/ManageNotificationsList';
import ManageNotification from '@scm/authentication/pages/notifications/admin/ManageNotification';
import {StandardProductDetails} from '@scm/standard-product-details';
import GlobalStyle from '@scm/components/utils/GlobalStyle';
import useContentHeight from '../utils/useContentHeight';
import IncorrectProfile from './IncorrectProfile';
import {ManageDevelopers} from '@scm/manage-developers';
import {Users} from '@scm/users';

const RestrictedRoute = ({isAvailable, route}: {isAvailable: boolean; route: JSX.Element}) =>
  isAvailable ? route : <Navigate to={InternalUrls.MyProducts} />;

const AppBody = () => {
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);

  const handleTermsAndConditionsOpen = () => setTermsAndConditionsOpen(true);
  const handleTermsAndConditionsClose = () => setTermsAndConditionsOpen(false);

  const buildTermsAndConditionsHelper: ITermsAndConditionsHelper = {
    isOpen: termsAndConditionsOpen,
    handleOpen: handleTermsAndConditionsOpen,
    handleClose: handleTermsAndConditionsClose,
  };
  useAnalytics();

  return (
    <>
      <Helmet>
        <meta name="robots" content={config.envName === 'prod' ? 'index,follow' : 'noindex,nofollow'} />
      </Helmet>
      <Header />
      <Outlet />
      <Footer
        externalUrls={ExternalUrls}
        terms={
          <TermsAndConditionsModal
            open={buildTermsAndConditionsHelper.isOpen}
            handleClose={buildTermsAndConditionsHelper.handleClose}
            isReadOnly
          />
        }
        handleTermsAndConditionsOpen={buildTermsAndConditionsHelper.handleOpen}
      />
    </>
  );
};

const MainWrapper = ({
  element,
  showMenu,
  isMainPage,
}: {
  element: JSX.Element;
  showMenu: boolean;
  isMainPage?: boolean;
}) => {
  const [refreshKey, setRefreshKey] = useState(0);

  const contentHeight = useContentHeight();

  const changeKey = (isCtrlPressed: boolean) => {
    if (isCtrlPressed) {
      return;
    }
    setRefreshKey(prevState => prevState + 1);
  };

  return (
    <Main isMainPage={isMainPage} contentHeight={contentHeight}>
      {showMenu && <MenuItems changeKey={changeKey} />}
      <MainContent key={refreshKey}>{element}</MainContent>
    </Main>
  );
};

const router = (accessToken: string, baseName: string, canAddNewProduct: boolean) => {
  const showMenu = !!accessToken && !userHasNoRoles();

  return createBrowserRouter(
    createRoutesFromElements(
      <Route path={InternalUrls.Home} element={<AppBody />}>
        {(config.ignoreSso || config.mockSso) && (
          <Route
            path={InternalUrls.MockSsoLogin}
            element={<MainWrapper element={<MockSsoLogin />} showMenu={showMenu} />}
          />
        )}
        <Route
          path={InternalUrls.AuthCallback}
          element={<MainWrapper element={<Login to={baseName + InternalUrls.MyProducts} />} showMenu={showMenu} />}
        />
        accessToken && (
        <Route
          path={InternalUrls.NotAuthorized}
          element={<MainWrapper element={<NotAuthorized />} showMenu={showMenu} />}
        />
        <Route
          path={InternalUrls.IncorrectProfile}
          element={<MainWrapper element={<IncorrectProfile />} showMenu={showMenu} />}
        />
        <Route
          path={InternalUrls.MyProducts}
          element={<MainWrapper element={<MyProducts />} showMenu={showMenu} isMainPage />}
        />
        <Route
          path={`${InternalUrls.MyProducts}/:appName/*`}
          element={<MainWrapper element={<ProductDetails />} showMenu={showMenu} />}
        />
        <Route
          path={`${InternalUrls.MyProducts}/standard/:appName/*`}
          element={<MainWrapper element={<StandardProductDetails />} showMenu={showMenu} />}
        />
        <Route
          path={`${InternalUrls.MyProducts}/support/:appName/:version/*`}
          element={
            <RestrictedRoute
              isAvailable={isSupportRole()}
              route={<MainWrapper element={<Version />} showMenu={showMenu} />}
            />
          }
        />
        <Route
          path={`${InternalUrls.Proposal}/:proposalId`}
          element={<MainWrapper element={<ProposalViewPage />} showMenu={showMenu} />}
        />
        <Route path={InternalUrls.Reports} element={<MainWrapper element={<Reports />} showMenu={showMenu} />} />
        <Route
          path={InternalUrls.Resources}
          element={<MainWrapper element={<ResourcesComponent />} showMenu={showMenu} />}
        />
        <Route path={InternalUrls.Developers} element={<MainWrapper element={<Developers />} showMenu={showMenu} />} />
        <Route
          path={InternalUrls.PartnerProfile}
          element={<MainWrapper element={<PartnerProfile />} showMenu={showMenu} />}
        />
        <Route path={InternalUrls.AddNewProduct}>
          <Route
            path=""
            element={
              <RestrictedRoute
                isAvailable={canAddNewProduct}
                route={<MainWrapper element={<ProposalForm />} showMenu={showMenu} />}
              />
            }
          />
          <Route
            path=":proposalId"
            element={
              <RestrictedRoute
                isAvailable={canAddNewProduct}
                route={<MainWrapper element={<ProposalForm />} showMenu={showMenu} />}
              />
            }
          />
        </Route>
        <Route
          path={`${InternalUrls.CertificationEngineerDashboard}/*`}
          element={
            <RestrictedRoute
              isAvailable={!!accessToken && (isSabreAdminRole() || isSecondLevelSupportRole())}
              route={<MainWrapper element={<CertificationEngineerDashboard />} showMenu={showMenu} />}
            />
          }
        />
        <Route
          path={InternalUrls.Cleanup}
          element={
            <RestrictedRoute
              isAvailable={!!accessToken && isCertificationEngineerRole()}
              route={<MainWrapper element={<Cleanup />} showMenu={showMenu} />}
            />
          }
        />
        <Route
          path={InternalUrls.Users}
          element={
            <RestrictedRoute
              isAvailable={!!accessToken && isSecondLevelSupportRoleOrSabreAdmin()}
              route={<MainWrapper element={<Users />} showMenu={showMenu} />}
            />
          }
        />
        <Route
          path={InternalUrls.FreezeCalendar}
          element={
            <RestrictedRoute
              isAvailable={!!accessToken && isCertificationEngineerRole()}
              route={<MainWrapper element={<FreezeCalendar />} showMenu={showMenu} />}
            />
          }
        />
        <Route
          path={InternalUrls.Roadmap}
          element={
            <RestrictedRoute
              isAvailable={!!accessToken}
              route={<MainWrapper element={<RoadmapPage />} showMenu={showMenu} />}
            />
          }
        />
        <Route
          path={`${InternalUrls.ProposalsCertificationOnboardings}/:id`}
          element={
            <RestrictedRoute
              isAvailable={!!accessToken && (isAdpRole() || isSabreAdminRole())}
              route={<MainWrapper element={<OnboardingsDetails />} showMenu={showMenu} />}
            />
          }
        />
        <Route
          path={`${InternalUrls.ProposalsCertification}/*`}
          element={
            <RestrictedRoute
              isAvailable={!!accessToken && (isAdpRole() || isSabreAdminRole())}
              route={<MainWrapper element={<CertificationProposals />} showMenu={showMenu} />}
            />
          }
        />
        <Route
          path={`${InternalUrls.ManageCompany}`}
          element={
            <RestrictedRoute
              isAvailable={!!accessToken && isSecondLevelSupportRoleOrSabreAdmin()}
              route={<MainWrapper element={<ManageDevelopers />} showMenu={showMenu} />}
            />
          }
        />
        <Route path="*" element={<Navigate to={InternalUrls.MyProducts} />} />
        <Route
          path=""
          element={
            accessToken ? <Navigate to={InternalUrls.MyProducts} /> : <Login to={baseName + InternalUrls.MyProducts} />
          }
        />
        )
        <Route
          path={InternalUrls.NotificationSettings}
          element={<MainWrapper element={<NotificationSettings />} showMenu={showMenu} />}
        />
        <Route path={InternalUrls.ManageNotifications}>
          <Route
            path=""
            element={
              <RestrictedRoute
                isAvailable={!!accessToken && isSabreAdminRole()}
                route={<MainWrapper element={<ManageNotificationsList />} showMenu={showMenu} />}
              />
            }
          />
          <Route
            path=":notificationId"
            element={
              <RestrictedRoute
                isAvailable={!!accessToken && isSabreAdminRole()}
                route={<MainWrapper element={<ManageNotification />} showMenu={showMenu} />}
              />
            }
          />
        </Route>
      </Route>
    ),
    {basename: baseName}
  );
};
const App = () => {
  const {accessToken} = useContext(AuthenticationContext);
  const {userSellers} = useContext(SellersContext);
  const baseName = '/admin-centre';
  const isLogoutPage = window.location.href.includes('/logout');
  const isCallbackPage = window.location.href.includes('/authCallback');
  const isMockSsoLoginPage = window.location.href.includes(InternalUrls.MockSsoLogin);
  const isNotAuthorizedPage = window.location.href.includes(InternalUrls.NotAuthorized);
  const canAddNewProduct = useMemo(
    () => !!accessToken && (!!userSellers.length || isAgencyAdminRole()),
    [userSellers, accessToken]
  );

  useEffect(() => {
    if (!accessToken && !isLogoutPage && !isCallbackPage && !isMockSsoLoginPage) {
      redirectToLoginPage();
    }

    if (!isNotAuthorizedPage && !!accessToken && userHasNoRoles()) {
      redirectToNotAuthorizedPage();
    }
  }, [accessToken, isLogoutPage, isCallbackPage, isMockSsoLoginPage, isNotAuthorizedPage]);
  const targetRef = useRef(null);

  useEffect(() => {
    const target = targetRef.current;
    const observer = new MutationObserver(() => {
      const toastContent = Array.from(document.querySelectorAll('.spark-toast__content'));
      const messageContent = Array.from(document.querySelectorAll('.spark-message__content'));

      const addAttrs = (content: Element) => {
        const header = content.querySelector('h4');
        const paragraph = content.querySelector('p');

        if (header) {
          header.setAttribute('test-id', 'banner-header');
        }

        if (paragraph) {
          paragraph.setAttribute('test-id', 'banner-content');
        }
      };

      toastContent.forEach(addAttrs);
      messageContent.forEach(addAttrs);
    });

    observer.observe(target as unknown as HTMLDivElement, {attributes: false, childList: true, subtree: true});

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Background ref={targetRef}>
      <GlobalStyle />
      <RouterProvider router={router(accessToken ?? '', baseName, canAddNewProduct)} />
    </Background>
  );
};

const Main = styled.div<{isMainPage?: boolean; contentHeight: number}>`
  background-color: ${colors.grey150};
  flex-grow: 1;
  display: flex;
  @media (max-width: ${deviceWidths.xs}px) {
    flex-direction: column;
  }
  height: ${({contentHeight}) => contentHeight}px;
`;

const MainContent = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const Background = styled.div`
  height: 100vh;
  background-color: ${colors.grey150};
`;

export const HeaderText = styled.h1`
  font-weight: 400;
`;

export default App;
