import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {Icon} from '@sabre/spark-react-core';

const NoUsers = () => (
  <Container>
    <Icon iconStyle="fill" name="user" size="lg" className="spark-mar-b-1" />
    <Text className="spark-bold">
      <FormattedMessage id="users.noUser.description" />
    </Text>
  </Container>
);

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;
`;

const Text = styled.p`
  font-size: 1.5rem;
`;

export default NoUsers;
