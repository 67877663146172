import {Button} from '@sabre/spark-react-core';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext} from 'react';
import styled, {keyframes} from 'styled-components';
import SaveButton from './SaveButton';
import {getAccessToken, isAllowedToEditProducts} from '@scm/authentication/utils/authentication';
import {StorefrontContext} from '../../forms/Storefront';
import {
  CcrzEProductMediaCSObject,
  CcrzEProductMediaCSObjectCcrzMediaTypeCEnum,
  CcrzEProductMediaCSObjectCcrzProductMediaSourceCEnum,
  Configuration,
  Product,
  ProductApi,
} from '../../generated/product';
import {productApiBaseLink} from '../../assets/apiBaseLink';
import {ProductCategoriesContext} from '../../forms/ProductCategories';
import {useParams} from 'react-router-dom';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ProductIconContext} from '../../forms/ProductIcon';
import {OrderingContext} from '../../forms/Ordering';
import {ProductContentContext} from '../../forms/ProductContent';
import {MediaContext, referenceGuideName, ytLinkName} from '../../forms/Media';
import {isFile} from '@scm/components/files/ImageFileGallery';
import {ImageFile} from '@scm/components/files/FileUpload';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {RegionalizationContext} from '../../forms/RegionalizationContent';
import {KeyBenefitsTabContext} from '../../forms/product-tabs-forms/KeyBenefitsTab';
import {FeaturesTabContext} from '../../forms/product-tabs-forms/FeaturesTab';
import {DocumentsTabContext} from '../../forms/product-tabs-forms/DocumentsTab';
import {SupportTabContext} from '../../forms/product-tabs-forms/SupportTab';
import {TechnicalRequirementsTabContext} from '../../forms/product-tabs-forms/TechnicalRequirementsTab';
import {AdditionalInformationTabContext} from '../../forms/product-tabs-forms/AdditionalInformationTab';
import useGetCancel from './useGetCancel';
import useGetSaveLocal from './useGetSaveLocal';
import {MissingItemsForApprovalContext} from '../../page/content/basic-info/MissingItemsForApprovalContext';
import {MissingItemsForApprovalContext as StandardMissingItemsForApprovalContext} from '@scm/standard-product-details/page/standardContent/basic-info/MissingItemsForApproval';
import {ProductMedia} from 'packages/product/pages/storefrontData/productDetailsUtils';
import colors from '@scm/components/assets/colors';

const updateProduct = async (
  id: string,
  product: Product,
  productIcon?: Blob,
  productStorefrontImages?: Blob[],
  referenceGuide?: File,
  additionalFiles?: Blob[]
) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).patchProduct({sku: id, product, productIcon, productStorefrontImages, referenceGuide, additionalFiles});

const sendDeleteReferenceGuide = (sku: string) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).deleteReferenceGuide({sku});

const deleteMedia = (sku: string, media: Array<CcrzEProductMediaCSObject>) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).deleteStorefrontImages({sku, ccrzEProductMediaCSObject: media});

const deleteAdditionalFiles = (sku: string, media: Array<CcrzEProductMediaCSObject>) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).deleteAdditionalGuides({sku, ccrzEProductMediaCSObject: media});

const editAdditionalFiles = (sku: string, media: Array<CcrzEProductMediaCSObject>) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).editAdditionalGuides({sku, ccrzEProductMediaCSObject: media});

const Submit = () => {
  const {isTouched, isError} = useContext(StorefrontContext);
  const {request: categoriesRequest} = useContext(ProductCategoriesContext);
  const {iconFile, isTouched: iconTouched} = useContext(ProductIconContext);
  const {request: orderingRequest} = useContext(OrderingContext);
  const {request: productContentRequest} = useContext(ProductContentContext);
  const {request: regionalizationRequest} = useContext(RegionalizationContext);
  const {request: keyBenefitsTabRequest} = useContext(KeyBenefitsTabContext);
  const {request: featuresTabRequest} = useContext(FeaturesTabContext);
  const {request: documentsTabRequest} = useContext(DocumentsTabContext);
  const {request: supportTabRequest} = useContext(SupportTabContext);
  const {request: technicalRequirementsTabRequest} = useContext(TechnicalRequirementsTabContext);
  const {request: additionalInformationTabRequest} = useContext(AdditionalInformationTabContext);
  const {setCheckedIfReady} = useContext(MissingItemsForApprovalContext);
  const {setStandardCheckedIfReady} = useContext(StandardMissingItemsForApprovalContext);
  const {
    shouldDeleteReferenceGuide,
    watch: mediaWatch,
    screenshotsToDelete,
    newScreenshots,
    newAdditionalFiles,
    additionalFilesToDelete,
    additionalFilesToEdit,
    isYtTouched,
    setValue,
  } = useContext(MediaContext);

  const buttonsVisible = isAllowedToEditProducts() && isTouched;

  const isSubmitPending = false;
  const cancelHandler = useGetCancel();
  const sku = useParams().appName || '';
  const {formatMessage} = useIntl();
  const productDetailsName = 'productDetails.updates';

  const saveLocal = useGetSaveLocal();

  const createMediaObject = (
    file: ProductMedia,
    mediaType: CcrzEProductMediaCSObjectCcrzMediaTypeCEnum
  ): CcrzEProductMediaCSObject => {
    return {
      id: file.id,
      ccrzFilePathC: file.name,
      ccrzProductC: file.id,
      ccrzMediaTypeC: mediaType,
      ccrzProductMediaSourceC: CcrzEProductMediaCSObjectCcrzProductMediaSourceCEnum.Uri,
      ccrzURIC: file.uri,
    };
  };

  const onSave = async (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    success: (() => {}) | undefined,
    failure: (() => {}) | undefined
  ) => {
    if (success && failure && sku) {
      try {
        let request: Product = {
          ccrzEProductMediaC: [],
          ccrzEProductTabC: [],
        };

        if (categoriesRequest !== null) {
          Object.assign(request, categoriesRequest);
        }

        Object.assign(request, orderingRequest);
        request.ccrzEProductC = {...request.ccrzEProductC, ...productContentRequest.ccrzEProductC};
        request.ccrzEProductC = {...request.ccrzEProductC, ...regionalizationRequest.ccrzEProductC};

        request.ccrzETermC = productContentRequest.ccrzETermC;

        if (keyBenefitsTabRequest.ccrzEProductTabC?.length) {
          request.ccrzEProductTabC?.push(keyBenefitsTabRequest.ccrzEProductTabC[0]);
        }

        if (featuresTabRequest.ccrzEProductTabC?.length) {
          request.ccrzEProductTabC?.push(featuresTabRequest.ccrzEProductTabC[0]);
        }

        if (documentsTabRequest.ccrzEProductTabC?.length) {
          request.ccrzEProductTabC?.push(documentsTabRequest.ccrzEProductTabC[0]);
        }

        if (supportTabRequest.ccrzEProductTabC?.length) {
          request.ccrzEProductTabC?.push(supportTabRequest.ccrzEProductTabC[0]);
        }

        if (technicalRequirementsTabRequest.ccrzEProductTabC?.length) {
          request.ccrzEProductTabC?.push(technicalRequirementsTabRequest.ccrzEProductTabC[0]);
        }

        if (additionalInformationTabRequest.ccrzEProductTabC?.length) {
          request.ccrzEProductTabC?.push(additionalInformationTabRequest.ccrzEProductTabC[0]);
        }

        if (isYtTouched) {
          const youtubeMedia: CcrzEProductMediaCSObject = {
            id: mediaWatch(ytLinkName).id,
            ccrzMediaTypeC: CcrzEProductMediaCSObjectCcrzMediaTypeCEnum.YouTubeVideo,
            ccrzURIC: mediaWatch(ytLinkName).url,
          };

          request?.ccrzEProductMediaC?.push(youtubeMedia);
        }

        if (!Object.keys(request.ccrzEProductC || {}).length) {
          delete request.ccrzEProductC;
        }

        if (!Object.keys(request.ccrzETermC || {}).length) {
          delete request.ccrzETermC;
        }

        if (!Object.keys(request.ccrzEProductTabC || {}).length) {
          delete request.ccrzEProductTabC;
        }

        let deleteScreenshotsRequest: Array<CcrzEProductMediaCSObject> = [];
        screenshotsToDelete.forEach(file => {
          if (file && (!isFile(file as unknown as ImageFile) || !(file as unknown as ImageFile).shouldUpload)) {
            deleteScreenshotsRequest = [
              ...deleteScreenshotsRequest,
              createMediaObject(file, CcrzEProductMediaCSObjectCcrzMediaTypeCEnum.StorefrontImage),
            ];
          }
        });

        let deleteAdditionalFilesRequest: Array<CcrzEProductMediaCSObject> = [];
        additionalFilesToDelete.forEach(file => {
          if (file && (!isFile(file as unknown as ImageFile) || !(file as unknown as ImageFile).shouldUpload)) {
            deleteAdditionalFilesRequest = [
              ...deleteAdditionalFilesRequest,
              createMediaObject(file, CcrzEProductMediaCSObjectCcrzMediaTypeCEnum.OwnersManuals),
            ];
          }
        });

        let editAdditionalFilesRequest: Array<CcrzEProductMediaCSObject> = [];
        additionalFilesToEdit.forEach(file => {
          if (file && !isFile(file as unknown as ImageFile)) {
            editAdditionalFilesRequest = [
              ...editAdditionalFilesRequest,
              createMediaObject(file, CcrzEProductMediaCSObjectCcrzMediaTypeCEnum.OwnersManuals),
            ];
          }
        });

        if (editAdditionalFilesRequest.length) {
          await editAdditionalFiles(sku, editAdditionalFilesRequest);
        }

        if (deleteScreenshotsRequest.length) {
          await deleteMedia(sku, deleteScreenshotsRequest);
        }

        if (deleteAdditionalFilesRequest.length) {
          await deleteAdditionalFiles(sku, deleteAdditionalFilesRequest);
        }

        if (shouldDeleteReferenceGuide) {
          await sendDeleteReferenceGuide(sku);
        }
        const updatedProduct = await updateProduct(
          sku,
          request,
          iconTouched ? iconFile : undefined,
          newScreenshots.length ? (newScreenshots as Blob[]) : undefined,
          Array.isArray(mediaWatch(referenceGuideName)) ? (mediaWatch(referenceGuideName)[0] as File) : undefined,
          newAdditionalFiles.length ? (newAdditionalFiles as Blob[]) : undefined
        );

        updateYtLink(updatedProduct);

        saveLocal();
        success?.();
        if (setCheckedIfReady) {
          setCheckedIfReady(false);
        }
        if (setStandardCheckedIfReady) {
          setStandardCheckedIfReady(false);
        }
        openToast(
          createMessageString(formatMessage, productDetailsName, true),
          createMessageString(formatMessage, productDetailsName),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      } catch (_) {
        failure?.();
        openToast(
          createMessageString(formatMessage, productDetailsName, true, true),
          createMessageString(formatMessage, productDetailsName, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      }
    }
  };

  const updateYtLink = (updatedProduct: Product) => {
    if (updatedProduct.ccrzEProductMediaC) {
      const youtubeLinkObject = updatedProduct.ccrzEProductMediaC.find(
        media => media.ccrzMediaTypeC === CcrzEProductMediaCSObjectCcrzMediaTypeCEnum.YouTubeVideo
      );
      if (youtubeLinkObject) {
        setValue(ytLinkName, {url: youtubeLinkObject.ccrzURIC, id: youtubeLinkObject.id});
      } else {
        setValue(ytLinkName, {url: '', id: undefined});
      }
    } else {
      setValue(ytLinkName, {url: '', id: undefined});
    }
  };

  return buttonsVisible ? (
    <SubmitButtonContainer className="spark-flex">
      <SubmitInfo>
        <FormattedMessage id="productDetails.submitInfo" />
      </SubmitInfo>
      <Button
        disabled={isSubmitPending}
        secondary
        type="reset"
        onClick={cancelHandler}
        size={ButtonSize.SMALL}
        className="spark-mar-r-1"
      >
        <FormattedMessage id="productDetails.resetButton" />
      </Button>
      <SaveButton onClick={onSave} disabled={isError} />
    </SubmitButtonContainer>
  ) : null;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    max-height: 500px;
  }
`;

export const SubmitButtonContainer = styled.div`
  display: flex !important;
  justify-content: right;
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 100;
  background-color: rgba(228, 228, 228, 0.8);
  padding: 2rem !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  animation: 1.5s ${fadeIn} linear;
`;

export const SubmitInfo = styled.div`
  color: ${colors.black};
  margin-right: 2rem;
`;

export default Submit;
