import React from 'react';
import MultiRowItem from './MultiRowItem';
import {Address} from 'generated/graphql';

const AddressSection = ({address}: {address: Address}) => (
  <MultiRowItem
    className="spark-mar-b-1"
    messageId="partnerPage.card.companyAddress"
    values={getFormatter(address.country)(address)}
  />
);

const getFormatter = (country: string | null | undefined) =>
  (country && formats.find(format => format.countries.includes(country.toUpperCase()))?.formatter) || defaultFormatter;

const formats: Format[] = [
  {
    countries: ['AUSTRALIA', 'CANADA', 'TAIWAN'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.city) + addPostfix(address.stateProvince) + ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['UNITED STATES'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.city, ', ') + addPostfix(address.stateProvince) + ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['BRAZIL'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.city, ' - ') + ofNullable(address.stateProvince),
      ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['INDIA'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.city, ' - ') + ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['BAHRAIN', 'MYANMAR', 'NEW ZEALAND', 'SINGAPORE', 'COLOMBIA', 'INDONESIA', 'ROMANIA'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.city) + ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['UNITED KINGDOM', 'RUSSIA', 'SERBIA', 'SOUTH AFRICA'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      ofNullable(address.city),
      ofNullable(address.stateProvince),
      ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['PERU'],
    formatter: (address: Address) => [
      addPostfix(address.streetLine, ', ') + ofNullable(address.city),
      ofNullable(address.postalCode),
      ofNullable(address.stateProvince),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['CHILE'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.postalCode) + ofNullable(address.city),
      ofNullable(address.stateProvince),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['HONG KONG SAR CHINA'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      ofNullable(address.stateProvince),
      ofNullable(address.city),
    ],
  },
  {
    countries: ['JAPAN'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.stateProvince, ', ') + addPostfix(address.city) + ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['NIGERIA', 'SOUTH KOREA'],
    formatter: (address: Address) => [
      addPostfix(address.streetLine, ', ') + ofNullable(address.city),
      addPostfix(address.stateProvince) + ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['THAILAND'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      ofNullable(address.stateProvince),
      ofNullable(address.city),
      ofNullable(address.postalCode),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['URUGUAY'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.postalCode, ' - ') + ofNullable(address.city),
      ofNullable(address.country),
    ],
  },
  {
    countries: ['VENEZUELA'],
    formatter: (address: Address) => [
      ofNullable(address.streetLine),
      addPostfix(address.city) + addPostfix(address.postalCode, ', ') + ofNullable(address.stateProvince),
      ofNullable(address.country),
    ],
  },
];

const defaultFormatter = (address: Address) => [
  ofNullable(address.streetLine),
  addPostfix(address.postalCode) + ofNullable(address.city),
  ofNullable(address.country),
];

interface Format {
  countries: string[];
  formatter: (address: Address) => string[];
}

const ofNullable = (input: string | null | undefined) => input || '';
const addPostfix = (input: string | null | undefined, postfix: string = ' ') => (input ? input + postfix : '');
export default AddressSection;
