import {ButtonSize} from '@sabre/spark-react-core/types';
import {AddButton} from '@scm/my-products/components/MyProducts';
import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import StandardTooltip from '../../tooltip/StandardTooltip';

const AddDeveloper = ({children}: {children: (handleClose: () => void, open: boolean) => JSX.Element}) => {
  const {formatMessage} = useIntl();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <StandardTooltip text={formatMessage({id: 'assignSeller.companyLevel.assignDeveloper.tooltip'})}>
        <WideAddButton onClick={handleOpen} size={ButtonSize.EXTRA_SMALL} secondary className="spark-mar-b-1">
          <FormattedMessage id="header.add" />
        </WideAddButton>
      </StandardTooltip>
      {children(handleClose, open)}
    </>
  );
};

const WideAddButton = styled(AddButton)`
  padding: 1rem 3.3rem;
`;

export default AddDeveloper;
