import {countriesInRegion} from '@scm/components/assets/regionsAndCountries';

export const options: {label: string; value: string}[] = Object.entries(
  Object.values(countriesInRegion)
    .flat()
    .reduce((a, b) => Object.assign(a, b), {})
).map(([value, label]) => ({
  label: label as string,
  value,
}));
