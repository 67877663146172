import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import SilverBadge from 'assets/images/tiers/Dark_DevPartner_Silver_2024.png';
import GoldBadge from 'assets/images/tiers/Dark_DevPartner_Gold_2024.png';
import PlatinumBadge from 'assets/images/tiers/Dark_DevPartner_Platinum_2024.png';

const TiersDescription = () => {
  const {formatMessage} = useIntl();
  let rowsCount = parseInt(formatMessage({id: 'tiers.description.noOfParagraph'}));

  return (
    <div>
      <Badges>
        <Badge src={PlatinumBadge} alt="PlatinumBadge" />
        <Badge src={GoldBadge} alt="GoldBadge" />
        <Badge src={SilverBadge} alt="SilverBadge" />
      </Badges>
      <Text>
        <FormattedMessage id="tiers.description.title" />
      </Text>
      <div>
        {Array.from(Array(rowsCount)).map((_, i) => (
          <div key={`tiers-p-${i + 1}`} className="spark-delta spark-mar-b-2">
            {formatMessage({id: `tiers.description.paragraph${i + 1}`})}
          </div>
        ))}
      </div>
    </div>
  );
};

const Badges = styled.div.attrs(() => ({
  className: 'spark-mar-b-2 spark-mar-r-2',
}))`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Badge = styled.img`
  height: 16rem;
  margin: 1rem;
`;

const Text = styled.div.attrs(() => ({className: 'spark-delta spark-mar-b-2'}))`
  font-weight: 700;
`;

export default TiersDescription;
