import {ExternalLink, SupportIcon} from '@scm/components';
import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const DropdownItem = ({
  to,
  label,
  ariaLabel,
  onClick,
  isExternalLink = true,
  isSupportOnly,
  isDisabled,
}: {
  to: string;
  label: string;
  ariaLabel: string;
  onClick?: () => void;
  isExternalLink?: boolean;
  isSupportOnly?: boolean;
  isDisabled?: boolean;
}) => {
  const disableLink = {onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.preventDefault()};

  const createLink = () =>
    isExternalLink ? (
      <ExternalLink to={to} className="spark-menu__list-link spark-caps" ariaLabel={ariaLabel}>
        {label}
      </ExternalLink>
    ) : (
      <MenuLink {...(isDisabled && disableLink)} to={to} className="spark-menu__list-link" role="link">
        {label}
        {isSupportOnly && <SupportIcon />}
      </MenuLink>
    );
  return (
    <li className="spark-menu__list-item" onClick={onClick}>
      {createLink()}
    </li>
  );
};
const MenuLink = styled(NavLink)`
  font-weight: 400 !important;
  color: ${colors.white};

  &.active {
    font-weight: bold !important;
  }
  & .spark-icon::before {
    transform: translateY(-3px);
  }
`;

export default DropdownItem;
