const header = {
  'header.viewNavigation': 'View Navigation',
  'header.hideNavigation': 'Hide Navigation',
  'header.expand': 'Expand',
  'header.expandOrCollapse': 'Expand or Collapse Menu',
  'header.sabreCentral': 'Sabre Central',
  'header.marketPlace': 'Marketplace',
  'header.redAppCentre': 'Red App Centre',
  'header.partnerNetwork': 'Developer Partner Network',
  'header.directory': 'Directory',
  'header.tiers': 'Tiers',
  'header.benefits': 'Benefits',
  'header.joinUs': 'Join Us',
  'header.adminCentre': 'Admin Centre',
};

export default header;
