import React, {useEffect} from 'react';
import BenefitsMain from 'pages/benefits/BenefitsMain';
import AuthorizationProcess from 'pages/benefits/AuthorizationProcess';
import InternalUrls from 'utils/internalUrls';
import {BreadcrumbLink, BreadcrumbRowWrapper} from '@scm/components';
import styled from 'styled-components';

const Benefits = () => {
  useEffect(() => window.scrollTo(0, 0));

  return (
    <FullHeightContainer>
      <main>
        <BreadcrumbRowWrapper>
          <BreadcrumbLink intlId="common.breadcrumb.home" href={InternalUrls.Home} />
          <BreadcrumbLink intlId="common.breadcrumb.benefits" />
        </BreadcrumbRowWrapper>
        <BenefitsMain />
        <AuthorizationProcess />
      </main>
    </FullHeightContainer>
  );
};

const FullHeightContainer = styled.div`
  min-height: 100vh;
`;

export default Benefits;
