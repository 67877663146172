import {Pricing, ProductPriceRange} from '../../../../../generated/product';
import {isCertificationEngineerRole} from '@scm/authentication/utils/authentication';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext} from 'react';
import {PricingButtons, PricingContainer, PricingContext} from './Pricing';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {PricingFormContext} from '@scm/product-details/components/sections/pricing/Pricing';

const PricingView = ({pricing, hasPricingProvider}: {pricing: Pricing; hasPricingProvider?: boolean}) => {
  const {deletePricingHandler} = useContext(hasPricingProvider ? PricingFormContext : PricingContext);
  const {formatMessage} = useIntl();
  const negotiableIndex = 1;

  const getPricingRange = (currentPrice: ProductPriceRange, range?: ProductPriceRange[]) => {
    if (range === undefined) {
      return;
    }
    const priceIndex = range?.indexOf(currentPrice);
    if (priceIndex !== range?.length - 1) {
      return formatMessage(
        {id: 'tabs.pricing.view.range'},
        {
          rangeFrom: currentPrice.rangeFrom,
          rangeTo: (range[priceIndex + 1].rangeFrom as number) - 1,
        }
      );
    } else {
      return formatMessage({id: 'tabs.pricing.view.range'}, {rangeFrom: currentPrice.rangeFrom, rangeTo: 'Unlimited'});
    }
  };

  const getPrice = (currentPrice: ProductPriceRange, isNegotiable: boolean) => {
    if (isNegotiable) {
      return formatMessage({id: 'tabs.price.definition.negotiable'});
    }
    return currentPrice.price;
  };

  return (
    <>
      {pricing && (
        <PricingContainer className="container">
          <PricingButtons>
            {isCertificationEngineerRole() && (
              <Button type="button" size={ButtonSize.SMALL} onClick={deletePricingHandler} negative>
                <FormattedMessage id="tabs.pricing.deleteButton" />
              </Button>
            )}
          </PricingButtons>
          {pricing.productPricing.isPricingPerUserEnabled && (
            <p className="spark-mar-b-1 spark-bold">
              <FormattedMessage
                id="tabs.pricing.view.header"
                values={{
                  billingPeriod: pricing.productPricing.billingPeriod,
                  pricingLevel: pricing.productPricing.pricingLevel,
                }}
              />
            </p>
          )}
          <PricingRangeContainer className="spark-pad-b-2 spark-flex">
            {pricing.userPricingRanges?.map((price, index) => (
              <PriceRange key={index} index={index} className="spark-panel spark-panel--card spark-flex spark-mar-r-1">
                <div className="spark-panel__header spark-pad-1 spark-bold pricing-header">
                  <p>
                    {getPricingRange(price, pricing.userPricingRanges)}
                    <p>
                      {formatMessage({
                        id:
                          pricing.productPricing.pricingLevel === 'PCC'
                            ? 'tabs.pricing.pricingLevel.pcc'
                            : 'tabs.pricing.pricingLevel.epr',
                      })}
                    </p>
                  </p>
                </div>
                <div className="spark-panel__content spark-pad-1 spark-align-items-center">
                  <div className="spark-delta text-center">
                    <strong className="spark-mar-r-.5">
                      {getPrice(price, pricing.productPricing.isPricingPerUserNegotiable as boolean)}
                    </strong>
                    <ValueComponent>{formatMessage({id: 'tabs.price.value'})}</ValueComponent>
                  </div>
                </div>
              </PriceRange>
            ))}
            {pricing.productPricing.isPricingPerUserNegotiable && (
              <PriceRange index={negotiableIndex} className="spark-panel spark-panel--card spark-flex spark-mar-r-1">
                <div className="spark-panel__header spark-pad-1 spark-bold pricing-header">
                  <p>
                    {formatMessage({
                      id:
                        pricing.productPricing.pricingLevel === 'PCC'
                          ? 'tabs.pricing.pricingLevel.pcc'
                          : 'tabs.pricing.pricingLevel.epr',
                    })}
                  </p>
                </div>
                <div className="spark-panel__content spark-pad-1 spark-align-items-center">
                  <div className="spark-delta text-center">
                    <strong className="spark-mar-r-.5">
                      <FormattedMessage id="tabs.pricing.negotiable.label" />
                    </strong>
                  </div>
                </div>
              </PriceRange>
            )}
          </PricingRangeContainer>
          {pricing.productPricing.isPricingPerTransactionEnabled && (
            <p className="spark-mar-b-1 spark-mar-t-2 spark-bold">
              <FormattedMessage id="tabs.pricing.view.transaction.header" />
            </p>
          )}
          <PricingRangeContainer className="spark-pad-b-2 spark-flex">
            {pricing.transactionPricingRanges && pricing.transactionPricingRanges?.length > 0 && (
              <>
                {pricing.transactionPricingRanges?.map((transactionPricing, index) => (
                  <PriceRange
                    key={index}
                    index={index}
                    className="spark-panel spark-panel--card spark-flex spark-mar-r-1"
                  >
                    <div className="spark-panel__header spark-pad-1 spark-bold pricing-header">
                      <p>
                        {getPricingRange(transactionPricing, pricing.transactionPricingRanges)}
                        <p>
                          {formatMessage({
                            id: 'tabs.pricing.transaction.label',
                          })}
                        </p>
                      </p>
                    </div>
                    <div className="spark-panel__content spark-pad-1 spark-align-items-center">
                      <div className="spark-delta text-center">
                        <strong className="spark-mar-r-.5">
                          {getPrice(
                            transactionPricing,
                            pricing.productPricing.isPricingPerTransactionNegotiable as boolean
                          )}
                        </strong>
                        <span style={{whiteSpace: 'nowrap'}}>{formatMessage({id: 'tabs.price.value'})}</span>
                      </div>
                    </div>
                  </PriceRange>
                ))}
              </>
            )}
            {pricing.productPricing.isPricingPerTransactionNegotiable && (
              <PriceRange
                index={negotiableIndex}
                className="spark-panel spark-panel--card spark-flex spark-mar-r-1 spark-mar-b-1"
              >
                <div className="spark-panel__header spark-pad-1 spark-bold pricing-header">
                  <p>
                    {formatMessage({
                      id: 'tabs.pricing.transaction.label',
                    })}
                  </p>
                </div>
                <div className="spark-panel__content spark-pad-1 spark-align-items-center">
                  <div className="spark-delta text-center">
                    <strong className="spark-mar-r-.5">
                      <FormattedMessage id="tabs.pricing.negotiable.label" />
                    </strong>
                  </div>
                </div>
              </PriceRange>
            )}
          </PricingRangeContainer>
          {pricing.productPricing.additionalInformation && (
            <div>
              <p className="spark-mar-b-1 spark-mar-t-2 spark-bold">
                {formatMessage({id: 'tabs.price.additionalInfo.textInput.label'})}
              </p>
              <p className="spark-mar-r-2">{pricing.productPricing.additionalInformation}</p>
            </div>
          )}
        </PricingContainer>
      )}
    </>
  );
};

const ValueComponent = styled.span`
  white-space: nowrap;
`;

const PricingRangeContainer = styled.div`
  white-space: normal;
`;

const PriceRange = styled.div<{index: number}>`
  flex-direction: column;
  max-width: 12vw;
  text-align: center;

  .pricing-header {
    background: ${props =>
      props.index > 1 ? colors.blue300 : props.index > 0 ? colors.blue200 : colors.highlightBlue200};
  }
`;

export default PricingView;
