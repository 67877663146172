import React, {useContext, useEffect, useState} from 'react';
import {Button, Icon, TextInput, Tooltip} from '@sabre/spark-react-core';
import {SubmitHandler, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import styled from 'styled-components';
import {format} from 'date-fns';
import {dateWithHourNoSeconds, redAppSupportEmail} from '@scm/components/utils/common';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {
  AddCertBetaTesterOperationRequest,
  Configuration,
  ProductsApi,
} from '@scm/product-components/generated/certification';
import {getAccessToken, isAllowedToAddRemoveTesters} from '@scm/authentication/utils/authentication';
import {useIntl} from 'react-intl';
import {StorefrontDataContext} from '../../../storefrontData/dataProvider/StorefrontDataProvider';
import StorefrontDataValues from '../../../storefrontData/interfaces/StorefrontDataValues';
import {
  certTestersName,
  editedCertTesterName,
  isCertTesterAddingName,
} from '../../../storefrontData/interfaces/TesterDataValues';
import {TableCell} from '../bundles/BundleRow';
import {closeCurrentToasts, createMessageString, openToast} from '@scm/components/messaging/openToast';
import colors from '@scm/components/assets/colors';
import {Loading} from '@scm/components';
import {hrefStringCreator} from '@scm/components/messaging/HrefElement';
import {DeleteTesterHandlerType, Tester} from './TesterTable';
import {certificationApiBaseLink} from '../../../../assets/apiBaseLink';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

enum FormButton {
  Cancel = 'Cancel',
  Submit = 'Submit',
}

const CertTesterRow = ({
  tester,
  deleteTestersHandler,
  isEditedRow,
}: {
  tester: Tester;
  deleteTestersHandler?: DeleteTesterHandlerType;
  isEditedRow?: boolean;
}) => {
  const {methodsCertTesterForm, sku, setReloadCertTestersTab} = useContext(StorefrontDataContext);
  const {
    watch: watchTesterForm,
    setValue: setValueTesterForm,
    handleSubmit: handleSubmitTesterForm,
  } = methodsCertTesterForm;
  const productDetailsName = 'productDetails.updates';

  const {formatMessage} = useIntl();
  const [isPccValid, setIsPccValid] = useState(false);
  const [isSabreIdValid, setIsSabreIdValid] = useState(false);
  const pccValidatePattern = '^[A-Za-z0-9]{3,4}$';
  const sabreIdValidatePattern = '^[0-9]{1,6}$';
  const pccMaxLength = 4;
  const sabreIdMaxLength = 6;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [setLoading]);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) =>
    (setValueTesterForm as UseFormSetValue<StorefrontDataValues>)(editedCertTesterName, {
      ...((watchTesterForm as UseFormWatch<StorefrontDataValues>)(editedCertTesterName) as Tester),
      [evt.target.name]: evt.target.value.toUpperCase(),
    });

  const deleteHandler = async () => {
    setLoading(true);
    new Promise(() => (deleteTestersHandler as DeleteTesterHandlerType)(tester.nr));
  };

  async function addCertTester(addCertBetaTesterOperationRequest: AddCertBetaTesterOperationRequest) {
    return new ProductsApi(
      new Configuration({basePath: certificationApiBaseLink, accessToken: getAccessToken(), middleware: middleware})
    ).addCertBetaTester(addCertBetaTesterOperationRequest);
  }

  const testerExists = (data: StorefrontDataValues) =>
    data.certTesters.some(
      (tester: Tester) => tester.pcc === data.editedCertTester?.pcc && tester.sabreId === data.editedCertTester.sabreId
    );

  const onSubmit: SubmitHandler<StorefrontDataValues> = data => {
    closeCurrentToasts();
    if (testerExists(data)) {
      openToast(
        formatMessage({id: 'productDetails.fetch.errorTitle'}),
        formatMessage({id: 'productDetails.fetch.testerExists.description'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
      return;
    }

    setLoading(true);

    const addCertBetaTesterOperationRequest: AddCertBetaTesterOperationRequest = {
      id: sku,
      addCertBetaTesterRequest: {
        versionName: '1.2', // TODO implement with US1792020
        betaTester: {
          pcc: data.editedCertTester?.pcc as string,
          epr: data.editedCertTester?.sabreId as string,
        },
      },
    };

    addCertTester(addCertBetaTesterOperationRequest)
      .then(() => {
        setValueTesterForm(isCertTesterAddingName, false);
        setValueTesterForm(certTestersName, [
          ...watchTesterForm(certTestersName),
          watchTesterForm(editedCertTesterName),
        ] as Tester[]);
        setValueTesterForm(editedCertTesterName, undefined);
        setReloadCertTestersTab(true);
      })
      .catch(err => {
        err.response.json().catch(() => {});
        if (err.response.status === 422) {
          openToast(
            createMessageString(formatMessage, productDetailsName, true, true),
            formatMessage(
              {id: 'productDetails.fetch.certTesterListUpdateFailed.description'},
              {
                email: hrefStringCreator(redAppSupportEmail),
              }
            ),
            ToastType.WARNING,
            'spark-icon-alert-triangle',
            true
          );
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <tr>
      {isEditedRow ? (
        <>
          <TableCell isMiddleColumn>
            <TesterTextInput
              value={tester.pcc}
              name="pcc"
              maxLength={pccMaxLength}
              onChange={changeHandler}
              validatePattern={pccValidatePattern}
              validateOnChange
              onValidate={setIsPccValid}
              $isInputValid={tester.pcc.length > 0 && !isPccValid}
            />
          </TableCell>
          <TableCell isMiddleColumn>
            <TesterTextInput
              value={tester.sabreId}
              name="sabreId"
              maxLength={sabreIdMaxLength}
              onChange={changeHandler}
              validatePattern={sabreIdValidatePattern}
              validateOnChange
              onValidate={setIsSabreIdValid}
              $isInputValid={tester.sabreId.length > 0 && !isSabreIdValid}
            />
          </TableCell>
          <TableCell>{tester.created && format(new Date(tester.created), dateWithHourNoSeconds)}</TableCell>
          <TableCell />
          <TableCell className="spark-table__actions spark-text-right">
            {loading ? (
              <Loading sizing="sm" floatRight />
            ) : (
              <>
                <Button
                  secondary
                  size={ButtonSize.EXTRA_SMALL}
                  className="spark-mar-r-1"
                  onClick={() => setValueTesterForm(isCertTesterAddingName, false)}
                >
                  {FormButton.Cancel}
                </Button>
                <Button
                  size={ButtonSize.EXTRA_SMALL}
                  type="button"
                  onClick={handleSubmitTesterForm(onSubmit)}
                  disabled={!isPccValid || !isSabreIdValid}
                >
                  {FormButton.Submit}
                </Button>
              </>
            )}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell isMiddleColumn>{tester.pcc}</TableCell>
          <TableCell isMiddleColumn>{tester.sabreId}</TableCell>
          <TableCell>{tester.created && format(new Date(tester.created), dateWithHourNoSeconds)}</TableCell>
          <TableCell>
            <Tooltip toggleEl={<Icon name="check" className="spark-success" iconStyle="fill" />}>
              Assigned on CERT
            </Tooltip>
          </TableCell>
          <TableCell isMiddleColumn className="spark-table__actions spark-text-right">
            {isAllowedToAddRemoveTesters() &&
              (loading ? (
                <Loading sizing="sm" floatRight />
              ) : (
                <IconContainer onClick={deleteHandler}>
                  <Icon name="trash" />
                </IconContainer>
              ))}
          </TableCell>
        </>
      )}
    </tr>
  );
};
const IconContainer = styled.span`
  padding-left: 121.356px !important;
`;

export const TesterTextInput = styled(TextInput)<{$isInputValid?: boolean}>`
  input {
    padding: 1.25rem 1rem !important;
    height: 4rem;
    text-transform: uppercase;
    font-size: 1rem;
    border-color: ${props => (props.$isInputValid ? colors.red : colors.tierSilver)};
  }
`;

export default CertTesterRow;
