import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, NextLink, Operation} from '@apollo/client';
import ReactGA from 'react-ga4';
import {graphQlUri} from 'config/env';
import {clearTokens, getAccessToken, isAccessTokenExpired, isLoggedIn} from '@scm/authentication/utils/authentication';

const timeStartLink = new ApolloLink((operation, forward) => {
  operation.setContext({start: performance.now()});
  return forward(operation);
});

const logTimeLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    const time = Math.round((performance.now() - operation.getContext().start) * 100) / 100;
    console.log(`Operation ${operation.operationName} took ${time}ms to complete`);
    ReactGA.send({hitType: 'timing', category: 'GraphQL', variable: operation.operationName, value: time});
    return data;
  });
});

const authLink = new ApolloLink((operation, forward) => {
  if (!isLoggedIn() || operation.operationName === 'GetAuthenticationTokens') {
    return forward(operation);
  }

  if (isAccessTokenExpired()) {
    clearTokens();
    return forward(operation);
  }

  return forwardOperationWithAuthHeader(operation, forward);
});

const link = timeStartLink.concat(authLink.concat(logTimeLink));

const createApolloClient = () =>
  new ApolloClient({
    link: link.concat(new HttpLink({uri: graphQlUri})),
    cache: new InMemoryCache(),
  });

const forwardOperationWithAuthHeader = (operation: Operation, forward: NextLink) => {
  const token = getAccessToken();
  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return forward(operation);
};

const apolloClient = createApolloClient();

export default apolloClient;
