import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {FormattedMessage} from 'react-intl';
import {deviceWidths} from '@scm/components/assets/deviceWidth';

const UsersDescription = () => (
  <Container className="col-md-6">
    <ContentHeight className="spark-panel spark-pad-2">
      <Header>
        <FormattedMessage id="users.roles.title" />
      </Header>
      <RolesList className="spark-mar-t-2 spark-pad-t-1 spark-pad-l-2">
        <RoleListItem>
          <FormattedMessage id="users.adp" />
        </RoleListItem>
        <RoleListItem>
          <FormattedMessage id="users.firstLevelSupport" />
        </RoleListItem>
        <RoleListItem>
          <FormattedMessage id="users.secondLevelSupport" />
        </RoleListItem>
        <RoleListItem>
          <FormattedMessage id="users.admin" />
        </RoleListItem>
      </RolesList>
    </ContentHeight>
  </Container>
);

const Container = styled.div`
  margin-top: 1rem;
  @media (min-width: ${deviceWidths.md}px) {
    padding-left: 0.5rem;
    margin-top: 0;
  }
`;

const Header = styled.h2`
  font-weight: 400;
`;

const RolesList = styled.ul`
  font-size: 1.7rem;
`;

const RoleListItem = styled.li`
  list-style: none;
  margin-bottom: 1.8rem;
  line-height: 130%;
  font-weight: 400;
`;

export const ContentHeight = styled.div`
  min-height: 410px;
  border-radius: 4px !important;
  border-color: ${colors.grey300};
`;

export default UsersDescription;
