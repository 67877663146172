/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OnboardingStatus = {
    Submitted: 'Submitted',
    UnderReview: 'Under Review',
    Rejected: 'Rejected',
    Approved: 'Approved',
    Obsolete: 'Obsolete'
} as const;
export type OnboardingStatus = typeof OnboardingStatus[keyof typeof OnboardingStatus];


export function OnboardingStatusFromJSON(json: any): OnboardingStatus {
    return OnboardingStatusFromJSONTyped(json, false);
}

export function OnboardingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingStatus {
    return json as OnboardingStatus;
}

export function OnboardingStatusToJSON(value?: OnboardingStatus | null): any {
    return value as any;
}

