import React from 'react';
import AdditionalStatement from '../../../components/content/AdditionalStatement';
import {
  Accounting,
  Configuration,
  ExternalServices,
  Gds,
  General,
  Pci,
  Pii,
  Pricing,
  ProposalResponse,
  ProposalsApi,
  ProposalStatus,
  ProvisioningType,
  RedAppInfo,
  Regionalization,
} from '../../../generated/proposal';
import ProposalViewDataDefinition, {Separator} from './proposalViewDataFields/ProposalViewDataDefinition';
import ProposalViewDataRegionalization from './proposalViewDataFields/ProposalViewDataRegionalization';
import ProposalViewDataPricing from './proposalViewDataFields/ProposalViewDataPricing';
import ProposalViewDataServices from './proposalViewDataFields/ProposalViewDataServices';
import ProposalViewDataSecurityPci from './proposalViewDataFields/proposalViewDataSecurity/ProposalViewDataSecurityPci';
import ProposalViewDataSecurityPii from './proposalViewDataFields/proposalViewDataSecurity/ProposalViewDataSecurityPii';
import ProposalViewDataSecurityGds from './proposalViewDataFields/proposalViewDataSecurity/ProposalViewDataSecurityGds';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import ProposalViewButtons from './ProposalViewButtons';
import ProposalViewDataSecurityAccounting from './proposalViewDataFields/proposalViewDataSecurity/ProposalViewDataSecurityAccounting';
import {createCountriesInRegions} from '@scm/components/assets/regionsAndCountries';
import {
  getAccessToken,
  isAdpOrSabreAdminRole,
  isAdpOrSecondLevelSupportOrSabreAdminRole,
} from '@scm/authentication/utils/authentication';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {proposalsApiBaseLink} from '@scm/ce-proposals/assets/baseUrl';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {ContentContainer} from '../proposalViewContent/ProposalViewContent';

const ProposalViewDataTab = ({
  proposal,
  setLoading,
  setIsApproved,
}: {
  proposal: ProposalResponse;
  setLoading: (flag: boolean) => void;
  setIsApproved: (flag: boolean) => void;
}) => {
  const {
    status,
    sellerid,
    provisioningType,
    name,
    productInfoResponse,
    proposalId,
    submittedBy,
    productSku,
    onboardingId,
  } = proposal;
  const {
    general,
    security: {gds, pci, pii, protectionComment, patchTimeline, accounting} = {},
    regionalization,
    pricing,
    services: {externalServices, sabreServicesComment, useBargainFinderMax, sr360TechnologiesComment} = {},
  } = productInfoResponse as RedAppInfo;
  const {
    description,
    categories,
    interactionWithEndTravelerComment,
    pnrDocumentationComment,
    developmentType,
    technologies,
  } = general as General;
  const {availableRegions, availableCountries, regionalConstraintsComment} = regionalization as Regionalization;
  const {comment} = pricing as Pricing;
  const {comment: externalServicesComment, authentication} = externalServices as ExternalServices;
  const {
    connectionToGdsComment,
    howIsForwarded: gdsHowIsForwarded,
    protocol,
    comment: gdsComment,
    numberOfTransaction,
    relyOnFreeTextRemarksInPnr,
  } = gds as Gds;
  const {
    data,
    comment: pciComment,
    panProtectionComment,
    utilizingThirdPartyProcessor,
    retentionPeriod,
    usedBy,
  } = pci as Pci;
  const {
    data: piiData,
    howIsForwarded,
    euNationals,
    complianceWithGdpr,
    retentionPeriod: piiRetentionPeriod,
    usedBy: piiUsedBy,
    sharedWithThirdParty,
  } = pii as Pii;
  const {dataType, attributesDescription} = accounting as Accounting;
  const {formatMessage} = useIntl();
  const isEditable =
    isAdpOrSabreAdminRole() &&
    status !== ProposalStatus.Obsolete &&
    status !== ProposalStatus.Rejected &&
    status !== ProposalStatus.Approved;

  const security = productInfoResponse?.security;
  const isSecuritySectionAvailable =
    (security?.accounting?.dataType !== undefined && security?.accounting?.dataType?.length > 0) ||
    security?.accounting?.attributesDescription !== undefined ||
    security?.gds?.comment !== undefined ||
    security?.gds?.connectionToGdsComment !== undefined ||
    security?.gds?.howIsForwarded !== undefined ||
    security?.gds?.numberOfTransaction !== undefined ||
    security?.gds?.protocol !== undefined ||
    security?.gds?.relyOnFreeTextRemarksInPnr !== undefined ||
    security?.pci?.comment !== undefined ||
    (security?.pci?.data !== undefined && security?.pci?.data?.length > 0) ||
    security?.pci?.panProtectionComment !== undefined ||
    security?.pci?.retentionPeriod !== undefined ||
    security?.pci?.usedBy !== undefined ||
    security?.pci?.utilizingThirdPartyProcessor !== undefined ||
    security?.pii?.complianceWithGdpr !== undefined ||
    (security?.pii?.data !== undefined && security?.pii?.data?.length > 0) ||
    security?.pii?.howIsForwarded !== undefined ||
    security?.pii?.retentionPeriod !== undefined ||
    security?.pii?.sharedWithThirdParty !== undefined ||
    security?.pii?.usedBy !== undefined ||
    security?.pii?.euNationals !== undefined ||
    security?.protectionComment !== undefined ||
    security?.patchTimeline !== undefined;

  const triggerProductCreation = async () => {
    if (proposalId) {
      await new ProposalsApi(
        new Configuration({
          basePath: proposalsApiBaseLink,
          accessToken: getAccessToken(),
          middleware: middleware,
        })
      ).createCcProductInSalesforce({id: proposalId});
    }
  };

  const handleTriggerProductCreationButtonClick = () => {
    triggerProductCreation()
      .then(() =>
        openToast(
          createMessageString(formatMessage, 'proposalView.triggerProductCreation', true),
          createMessageString(formatMessage, 'proposalView.triggerProductCreation'),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(() =>
        openToast(
          createMessageString(formatMessage, 'proposalView.triggerProductCreation', true, true),
          createMessageString(formatMessage, 'proposalView.triggerProductCreation', false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      );
  };

  return (
    <ContentContainer className="spark-panel spark-panel__content spark-pad-2">
      <AdditionalStatement id="proposalView.proposalData.additionalText" />
      {isAdpOrSabreAdminRole() && status !== ProposalStatus.Approved && status !== ProposalStatus.Obsolete && (
        <ProposalViewButtons
          status={status}
          proposalId={proposalId}
          proposalName={name}
          setLoading={setLoading}
          setIsApproved={setIsApproved}
          onboardingId={onboardingId}
        />
      )}
      {status === 'Approved' && productSku == undefined && isAdpOrSecondLevelSupportOrSabreAdminRole() && (
        <Button size={ButtonSize.SMALL} onClick={handleTriggerProductCreationButtonClick}>
          <FormattedMessage id="proposalView.button.triggerProductCreation" />
        </Button>
      )}
      <RowsGroup>
        <ProposalViewDataDefinition
          proposalName={name}
          proposalId={proposalId}
          status={status}
          sellerid={sellerid}
          submittedBy={submittedBy}
          provisioningType={provisioningType}
          name={name}
          description={description}
          categories={categories}
          interactionWithEndTravelerComment={interactionWithEndTravelerComment}
          pnrDocumentationComment={pnrDocumentationComment}
          connectionToGdsComment={connectionToGdsComment}
          developmentType={developmentType}
          technologies={technologies}
          isEditable={isEditable}
          hasFlowDiagram={(productInfoResponse as RedAppInfo)?.general?.flowDiagramUrl !== undefined}
        />
        {provisioningType === ProvisioningType['Public'] && (
          <>
            <ProposalViewDataRegionalization
              availableRegions={availableRegions}
              availableCountries={
                createCountriesInRegions(
                  availableRegions as Array<string>,
                  availableCountries as Array<string>
                ) as Array<string>
              }
              regionalConstraintsComment={regionalConstraintsComment}
              isEditable={isEditable}
            />
            <ProposalViewDataPricing comment={comment} isEditable={isEditable} />
            <ProposalViewDataServices
              externalServicesComment={externalServicesComment}
              authentication={authentication}
              sabreServicesComment={sabreServicesComment}
              useBargainFinderMax={useBargainFinderMax}
              sr360TechnologiesComment={sr360TechnologiesComment}
              isEditable={false}
            />
          </>
        )}
        {isSecuritySectionAvailable ? (
          <Separator className="spark-mar-l-2 spark-mar-b-1 spark-mar-t-2 spark-pad-t-1 spark-bold spark-large">
            <FormattedMessage id="proposalDetails.security" />
          </Separator>
        ) : undefined}
        <ProposalViewDataSecurityPci
          data={data}
          panProtectionComment={panProtectionComment}
          utilizingThirdPartyProcessor={utilizingThirdPartyProcessor}
          pciComment={pciComment}
          retentionPeriod={retentionPeriod}
          usedBy={usedBy}
          isEditable={false}
        />
        <ProposalViewDataSecurityPii
          piiData={piiData}
          howIsForwarded={howIsForwarded}
          euNationals={euNationals}
          complianceWithGdpr={complianceWithGdpr}
          retentionPeriod={piiRetentionPeriod}
          piiUsedBy={piiUsedBy}
          sharedWithThirdParty={sharedWithThirdParty}
          isEditable={false}
        />
        <ProposalViewDataSecurityGds
          gdsHowIsForwarded={gdsHowIsForwarded}
          protocol={protocol}
          protectionComment={protectionComment}
          gdsComment={gdsComment}
          numberOfTransaction={numberOfTransaction}
          relyOnFreeTextRemarksInPnr={relyOnFreeTextRemarksInPnr}
          patchTimeline={patchTimeline}
          isEditable={false}
        />
        <ProposalViewDataSecurityAccounting
          dataType={dataType || []}
          attributesDescription={attributesDescription || ''}
          isEditable={false}
        />
      </RowsGroup>
    </ContentContainer>
  );
};

export const RowsGroup = styled.div`
  width: 75%;
  @media (max-width: ${deviceWidths.md}px) {
    width: calc(100% - 2rem);
    margin: 0 1rem;
  }
`;

export default ProposalViewDataTab;
