import * as yup from 'yup';
import {
  activeListeningFilesNames,
  activeListeningName,
  hiddenUploadedScribeScriptFilesNames,
  hiddenUploadedScribeScriptName,
  resourcesFilesNames,
  resourcesName,
  ScribeScriptsValues,
  uploadedScribeScriptFilesNames,
  uploadedScribeScriptName,
} from '../provider/ScribeScriptsValues';
import {ScribeScriptValidator} from './ScribeScriptValidator';

export const scribeScriptsSchema = yup
  .object()
  .shape({
    [uploadedScribeScriptName]: yup.string(),
    [uploadedScribeScriptFilesNames]: yup.array().min(1),
    [hiddenUploadedScribeScriptName]: yup.string(),
    [hiddenUploadedScribeScriptFilesNames]: yup.array(),
    [resourcesName]: yup.string(),
    [resourcesFilesNames]: yup.array(),
    [activeListeningName]: yup.string(),
    [activeListeningFilesNames]: yup.array().max(1),
  })
  .test(ScribeScriptValidator.SUCCESS_MESSAGE, function (value) {
    const {
      hiddenUploadedScribeScript,
      hiddenUploadedScribeScriptFiles,
      resources,
      resourcesFiles,
      activeListener,
      activeListenerFiles,
    } = value as ScribeScriptsValues;

    if (!ScribeScriptValidator.isFileFieldValid(hiddenUploadedScribeScript, hiddenUploadedScribeScriptFiles)) {
      return false;
    }

    if (!ScribeScriptValidator.isFileFieldValid(resources, resourcesFiles)) {
      return false;
    }

    return ScribeScriptValidator.isFileFieldValid(activeListener, activeListenerFiles);
  });
