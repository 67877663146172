import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import React, {useContext} from 'react';
import {VersionContext} from '../provider/VersionProvider';
import BundleInfo from './BundleInfo';

const VersionInfo = () => {
  const {bundles} = useContext(VersionContext);

  return (
    <ProductInfo className="spark-flex spark-pad-1 spark-pad-b-.5 spark-mar-r-2 spark-mar-l-2">
      <BundleInfo bundles={bundles} />
    </ProductInfo>
  );
};

const ProductInfo = styled.div`
  background-color: ${colors.grey100};
  justify-content: space-between;
  @media (max-width: ${deviceWidths.sm}px) {
    flex-direction: column;
    & > div {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
`;

export default VersionInfo;
