import React from 'react';
import styled from 'styled-components';
import EventCategories from 'googleAnalytics/EventCategories';
import {useDispatch, useSelector} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addFilter, callRefetch, removeFilter} from 'pages/partnerDirectory/store/actions';
import {CATEGORIES, REGIONS, TIERS} from 'pages/partnerDirectory/store/types';
import {AppState} from 'utils/rootReducer';
import DisabledTooltip from './DisabledTooltip';
import logEvent from 'googleAnalytics/logEvent';
import colors from '@scm/components/assets/colors';
import {mapRegionName} from 'utils/regionNameMapping';

const FiltersAccordionItem = ({
  id,
  title,
  options = [],
  filterBy,
}: {
  id: string;
  title: string;
  options?: string[];
  filterBy: string;
}) => {
  const {available, filters} = useSelector((state: AppState) => state.partnerDirectoryState);

  const dispatch = useDispatch();
  const addFilterAction = bindActionCreators(addFilter, dispatch);
  const removeFilterAction = bindActionCreators(removeFilter, dispatch);
  const callRefetchAction = bindActionCreators(callRefetch, dispatch);

  const mappedOptions = options.map((option, index) => {
    const optionLabel = filterBy === REGIONS ? mapRegionName(option) : option;
    const isChecked = isFilterSelected(filters, filterBy, option);
    const isDisabled = !isChecked && !isFilterAvailable(available, filterBy, option);

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      const targetChecked = e.target.checked;

      logEvent({
        category: EventCategories.Directory,
        action: 'Selected filter',
        label: option,
        value: +targetChecked,
      });

      if (targetChecked) {
        addFilterAction(filterBy, option);
      } else {
        removeFilterAction(filterBy, option);
      }

      callRefetchAction();
    };

    return (
      <label className="col-xs-12 spark-checkbox" key={index}>
        <input
          className="spark-checkbox__input"
          type="checkbox"
          aria-label="filter options"
          onChange={onChangeHandler}
          checked={isChecked}
          disabled={isDisabled}
        />
        <span className="spark-checkbox__box" />
        <StyledOption>{optionLabel}</StyledOption>
        {isDisabled && <DisabledTooltip />}
      </label>
    );
  });

  return (
    <section className="spark-accordion__drawer">
      <StyledAccordionHeader tabIndex={0} aria-controls={id} aria-expanded="true">
        <h4 role="presentation">{title}</h4>
      </StyledAccordionHeader>
      <StyledAccordionContent id={id}>{mappedOptions}</StyledAccordionContent>
    </section>
  );
};

const isFilterSelected = (
  filters: {regions: string[]; categories: string[]; tiers: string[]},
  filterBy: string,
  option: string
) => {
  switch (filterBy) {
    case REGIONS:
      return filters.regions.includes(option);
    case CATEGORIES:
      return filters.categories.includes(option);
    case TIERS:
      return filters.tiers.includes(option);
    default:
      return false;
  }
};

const isFilterAvailable = (available: {regions: string[]; categories: string[]}, filterBy: string, option: string) => {
  switch (filterBy) {
    case REGIONS:
      return available?.regions?.includes(option);
    case CATEGORIES:
      return available?.categories?.includes(option);
    case TIERS:
    default:
      return true;
  }
};

const StyledAccordionHeader = styled.div.attrs(() => ({
  className: 'spark-accordion__header spark-caps',
  role: 'presentation',
}))`
  padding: 1.5rem 4.25rem 0.5rem 2rem;
`;

const StyledAccordionContent = styled.div.attrs(() => ({
  className: 'spark-accordion__content',
}))`
  :before,
  :after {
    background: none !important;
    margin: 0;
  }
`;

const StyledOption = styled.span.attrs(() => ({
  className: 'spark-label',
}))`
  font-size: 1.58333rem;
  font-weight: 300;
  color: ${colors.grey900}!important;
`;
export default FiltersAccordionItem;
