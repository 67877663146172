import React from 'react';
import styled from 'styled-components';
import {Icon} from '@sabre/spark-react-core';
import {UseFormWatch} from 'react-hook-form';
import colors from '../assets/colors';
import {deviceWidths} from '../assets/deviceWidth';
import {ImageFile} from './FileUpload';
import {ProductMedia} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

export const isFile = (file: ImageFile | string) => file instanceof File;

export const ImageFileGallery = ({
  filesNames,
  watch,
  deleteHandler,
  disabled = false,
}: {
  filesNames: string;
  watch: UseFormWatch<any>;
  deleteHandler: (fileName: string, isFile: boolean) => void;
  disabled?: boolean;
}) => {
  const files = watch(filesNames);

  return (
    <Container className="spark-flex spark-mar-t-0">
      {files.map((file: ImageFile & ProductMedia) => (
        <Item className="spark-pad-1 spark-flex spark-mar-b-2" key={isFile(file) ? file.name : file.uri}>
          <ImageContainer className="spark-flex">
            <Image src={file.preview ?? file.uri} alt="" />
            {!disabled && (
              <IconContainer
                className="spark-pad-l-1"
                onClick={() => deleteHandler(isFile(file) ? file.name : file.id, isFile(file))}
              >
                <Icon name="trash" />
              </IconContainer>
            )}
          </ImageContainer>
          <Text className="spark-mar-t-1">{isFile(file) ? file.name : ''}</Text>
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  flex-wrap: wrap;
`;

const Item = styled.div`
  flex-basis: 50%;
  @media (max-width: ${deviceWidths.md}px) {
    flex-basis: 100%;
  }
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  width: 150px;
  height: fit-content;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
`;

const Image = styled.img`
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
`;

const Text = styled.p`
  flex-grow: 1;
  align-items: center;
`;

const IconContainer = styled.span`
  color: ${colors.sparkBlue};
  transform: translateY(-2px);

  &:hover {
    cursor: pointer;
  }
`;
