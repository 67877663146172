import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

const StandardProductNameDescription = () => (
  <>
    <h3>
      <FormattedMessage id="productDetails.icon.tooltip.title" />
    </h3>
    <DescriptionText>
      <FormattedMessage id="productDetails.icon.tooltip.description" />
    </DescriptionText>
  </>
);

const DescriptionText = styled.p`
  overflow-wrap: anywhere;
  white-space: break-spaces;
`;

export default StandardProductNameDescription;
