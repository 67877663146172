import {
  accountingAttributesDescriptionName,
  accountingDataTypeName,
  accountingName,
  alignSecureCodingPracticesName,
  coreDataName,
  dataAdminName,
  dataForwardedName,
  dataForwardedThirdPartyName,
  dataProtectionName,
  euDataName,
  financialInformationName,
  freeTextRemarksDescriptionName,
  freeTextRemarksName,
  gdprCompliantName,
  localDataName,
  numberOfTransactionName,
  panRenderedName,
  patchTimelineName,
  pciAdditionalDetailsName,
  pciCertifiedName,
  pciDataName,
  personalInformationName,
  piiDataName,
  piiDataRetentionAdminName,
  piiDataRetentionName,
  protocolName,
  retentionPeriodName,
  thirdPartyNetworkName,
} from '../../ServiceAndSecurityValues';

export const securityInitialValues = {
  [financialInformationName]: false,
  [pciDataName]: [],
  [pciCertifiedName]: '',
  [pciAdditionalDetailsName]: '',
  [panRenderedName]: '',
  [retentionPeriodName]: '',
  [dataAdminName]: '',
  [personalInformationName]: false,
  [piiDataName]: [],
  [dataForwardedName]: '',
  [euDataName]: '',
  [gdprCompliantName]: '',
  [piiDataRetentionName]: '',
  [piiDataRetentionAdminName]: '',
  [localDataName]: '',
  [thirdPartyNetworkName]: false,
  [dataForwardedThirdPartyName]: '',
  [protocolName]: '',
  [dataProtectionName]: '',
  [coreDataName]: false,
  [freeTextRemarksName]: '',
  [freeTextRemarksDescriptionName]: '',
  [numberOfTransactionName]: '',
  [alignSecureCodingPracticesName]: false,
  [patchTimelineName]: '',
  [accountingName]: false,
  [accountingDataTypeName]: [],
  [accountingAttributesDescriptionName]: '',
};
