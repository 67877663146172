import React from 'react';
import styled from 'styled-components';
import {supportRole} from '@scm/components/utils/common';
import colors from '@scm/components/assets/colors';

const versionsHeaders = ['version', 'submitted', 'last updated', 'status', 'compatibility', ''] as const;
const Headers = () => (
  <thead>
    <tr>
      {versionsHeaders.map(
        (name, index, headers) =>
          name !== '' && (
            <TableHeader
              key={name}
              colSpan={headers[index + 1] === '' ? 2 : 1}
              scope={headers[index + 1] === '' ? 'colgroup' : ''}
            >
              {name}
            </TableHeader>
          )
      )}
    </tr>
  </thead>
);

const TableHeader = styled.th`
  font-size: ${supportRole ? '1rem' : '1.25rem'} !important;
  background-color: ${colors.white} !important;
  color: ${colors.black} !important;
  border: none !important;
  border-bottom: 1px solid ${colors.grey300} !important;
`;

export default Headers;
