import React, {useEffect, useState} from 'react';
import {BreadcrumbLink, BreadcrumbRowWrapper, Loading} from '@scm/components';
import {FormattedMessage, IntlProvider, useIntl} from 'react-intl';
import styled from 'styled-components';
import {FieldError, SubmitHandler, useForm} from 'react-hook-form';
import PagesMap, {ProposalType} from './PagesMap';
import {useNavigate, useParams} from 'react-router-dom';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {
  currentStepName,
  flowDiagramName,
  formTitleId,
  proposalTypeName,
  ProposalValues,
  providerName,
} from './ProposalValues';
import {initialValues} from './proposalConstants';
import {ProposalContextValue} from './ProposalContextValue';
import {Message} from '@sabre/spark-react-core';
import {MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import {proposalMapper} from './mappers/proposalMapper';
import {proposalApiBaseLink} from '../../assets/apiBaseLink';
import ProposalIndicator from '../../components/proposalIndicator/ProposalIndicator';
import ProposalItemsList from '../../components/proposalIndicator/ProposalItemsList';
import proposal from '../../translations/proposal';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {yupResolver} from '@hookform/resolvers/yup';
import {basicProposalSchema} from '../../schemas/basicProposalSchema';
import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import {fromProposalMapper} from './mappers/fromProposalMapper';
import {createToast} from '@scm/components/messaging/openToast';
import {redAppSupportEmail} from '@scm/components/utils/common';
import HrefElement from '@scm/components/messaging/HrefElement';
import {HeaderText} from '@scm/admin-centre/src/components/App';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';
import {Configuration, Proposal, ProposalsApi} from '../../generated/proposal';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const fetchProposal = async (id: string) => {
  return new ProposalsApi(
    new Configuration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).getProposal({id});
};

async function postData(proposal: Proposal, flowDiagram: Blob) {
  return await new ProposalsApi(
    new Configuration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).createDraftProposal({
    proposal,
    flowDiagram,
  });
}

async function updateData(id: string, proposal: Proposal, flowDiagram?: Blob) {
  return await new ProposalsApi(
    new Configuration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).updateDraftProposal({
    id,
    proposal,
    flowDiagram,
  });
}

async function submitData(id: string) {
  return await new ProposalsApi(
    new Configuration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).submit({
    id,
  });
}

async function handleData(
  proposal: Proposal,
  flowDiagram: Blob,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean | null>>,
  id: string | undefined
) {
  try {
    proposal.proposalStep = null;
    const response = id ? await updateData(id, proposal, flowDiagram) : await postData(proposal, flowDiagram);
    const responseIdMessage = await submitData(response?.proposalId ?? id ?? '');
    if (responseIdMessage) {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
  } catch (e) {
    setIsSuccess(false);
  } finally {
    setIsLoading(false);
  }
}

const language = 'en';

export const ProposalContext = React.createContext<ProposalContextValue>({} as unknown as ProposalContextValue);

const ProposalFormContent = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    clearErrors,
    setError,
    formState: {errors, isValid},
  } = useForm<ProposalValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(basicProposalSchema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [open, toggleOpen] = useState(false);
  const [openError, toggleOpenError] = useState(false);
  const [handleProposalApprove, shouldHandleProposalApprove] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState<string | undefined>(useParams().proposalId);
  const [isSmallSpinnerLoading, setIsSmallSpinnerLoading] = useState<boolean>(false);
  const isDraft = useState<boolean>(!!useParams().proposalId);

  useEffect(() => {
    if (id && isDraft[0]) {
      setIsLoading(true);
      fetchProposal(id || '')
        .then(proposal => fromProposalMapper(proposal))
        .then(proposal =>
          Object.entries(proposal).forEach(([key, value]) => setValue(key as keyof ProposalValues, value as string))
        )
        .then(() => setIsLoading(false));
    }
  }, [id]);

  useEffect(() => {
    const toastMessage = createToast();

    if (open) {
      toastMessage.open({
        title: formatMessage({id: 'proposal.saveDraft.success.title'}),
        details: formatMessage({id: 'proposal.saveDraft.success.description'}),
        type: ToastType.POSITIVE,
        icon: 'spark-icon-check',
        actionIcon: 'spark-icon-close',
      });
      toggleOpen(false);
    }

    if (openError) {
      toastMessage.open({
        title: formatMessage({id: 'proposal.saveDraft.error.title'}),
        details: formatMessage({id: 'proposal.saveDraft.error.description'}),
        type: ToastType.WARNING,
        icon: 'spark-icon-alert-triangle',
        actionIcon: 'spark-icon-close',
      });
      toggleOpenError(false);
    }
  }, [open, openError]);

  const onSubmit: SubmitHandler<ProposalValues> = async proposalValues => {
    setIsLoading(true);
    await handleData(
      proposalMapper(proposalValues) as unknown as Proposal,
      watch(flowDiagramName)[0],
      setIsLoading,
      setIsSuccess,
      id
    );
  };

  const handleContinue = (shouldResetSteps: boolean) => {
    forceCloseToasts();
    if (shouldResetSteps) {
      const proposalType = watch(proposalTypeName);
      const provider = watch(providerName);
      reset();
      setValue(proposalTypeName, proposalType as ProposalType, {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      });
      setValue(providerName, provider, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
      setValue(currentStepName, 2, {shouldDirty: true, shouldValidate: true, shouldTouch: true});
    } else {
      setValue(currentStepName, watch(currentStepName) + 1);
    }
    window.scrollTo(0, 0);
  };

  const handleCancel = () => {
    if (watch(currentStepName) === 1) {
      navigate(InternalUrls.MyProducts);
    }
    forceCloseToasts();
    setValue(currentStepName, watch(currentStepName) - 1);
  };

  const forceCloseToasts = () => {
    toggleOpen(false);
    toggleOpenError(false);
  };

  const proposalValues = () => proposalMapper(getValues()) as Proposal;

  const handleSaveDraft = async (e: MouseEvent) => {
    (e.target as HTMLButtonElement).blur();
    gaEvent('Saved Proposal Draft');

    try {
      if (id) {
        await updateData(
          id,
          proposalValues(),
          typeof watch(flowDiagramName)[0] === 'string' ? undefined : watch(flowDiagramName)[0]
        );
      } else {
        const response = await postData(proposalValues(), watch(flowDiagramName)[0]);
        if (response.proposalId) {
          setId(response.proposalId);
        }
      }

      toggleOpen(true);
    } catch (err) {
      toggleOpenError(true);
    }
  };

  const {formatMessage} = useIntl();
  const createPageContent = () => {
    if (isSuccess !== null) {
      return (
        <Message
          content={
            formatMessage(
              {id: isSuccess ? 'review.success.content' : 'review.error.content'},
              {
                email: <HrefElement email={redAppSupportEmail} />,
              }
            ) as string
          }
          role={isSuccess ? MessageRole.STATUS : MessageRole.ALERT}
          status={isSuccess ? MessageStatus.SUCCESS : MessageStatus.ERROR}
          title={formatMessage({id: isSuccess ? 'review.success.title' : 'review.error.title'})}
          type={MessageType.PAGE}
          titleHeadingLevel={2}
        />
      );
    } else if (isLoading) {
      return (
        <LoadingContainer>
          <Loading label={formatMessage({id: 'common.data.loading'})} />
        </LoadingContainer>
      );
    } else {
      return (
        <>
          <ProposalIndicator>
            <ProposalItemsList proposalType={watch(proposalTypeName)} currentStep={watch(currentStepName)} />
          </ProposalIndicator>
          <PagesMap proposalType={watch(proposalTypeName)} currentStep={watch(currentStepName)} />
        </>
      );
    }
  };

  return (
    <React.StrictMode>
      <IntlProvider locale={language} key={language} messages={proposal}>
        <ProposalContext.Provider
          value={{
            control,
            watch,
            setValue,
            errors: errors as FieldError,
            isLoading,
            setError,
            clearErrors,
            isValid,
            handleContinue,
            handleCancel,
            handleSaveDraft,
            shouldHandleProposalApprove,
            handleProposalApprove,
            isSmallSpinnerLoading,
            setIsSmallSpinnerLoading,
          }}
        >
          <BreadcrumbRowWrapper>
            <BreadcrumbLink intlId="common.breadcrumb.myProducts" href={InternalUrls.MyProducts} />
            <BreadcrumbLink intlId="proposal.breadcrumb" />
          </BreadcrumbRowWrapper>
          <form onSubmit={handleSubmit(onSubmit)} className="spark-pad-2">
            <HeaderText className="spark-mar-b-2">
              <FormattedMessage id={watch(formTitleId)} />
            </HeaderText>
            <Page className="spark-panel spark-pad-2">{createPageContent()}</Page>
          </form>
        </ProposalContext.Provider>
      </IntlProvider>
    </React.StrictMode>
  );
};

export const ProposalForm = () => (
  <React.StrictMode>
    <IntlProvider locale={language} key={language} messages={proposal}>
      <ProposalFormContent />
    </IntlProvider>
  </React.StrictMode>
);

export const Page = styled.div`
  position: relative;

  & *:not(strong, button) {
    font-weight: 400;
  }

  & .spark-step-indicator__list {
    justify-content: flex-start;
  }

  & .spark-step-indicator__item:first-of-type {
    margin-left: 0;
    padding-left: 0;
  }

  & .spark-step-indicator__item {
    max-width: 9.3rem;
    min-width: 6.3rem;
  }
`;
