import React from 'react';
import styled from 'styled-components';
import colors from '../assets/colors';

export const ActionBadges = ({
  badgeNames,
  deleteHandler,
  className,
  disabled,
}: {
  badgeNames: Array<string>;
  deleteHandler: (name: string) => void;
  className?: string;
  disabled?: boolean;
}) => {
  const deleteButtonSign = 'X';
  const createBadges = () =>
    badgeNames.map(name => (
      <Badge key={name} className="spark-badge spark-badge--neutral spark-mar-b-1 spark-mar-r-2">
        {name}
        {!disabled && (
          <DeleteButton
            className="spark-mar-l-1 spark-pad-r-0"
            type="button"
            aria-label="delete button"
            onClick={() => deleteHandler(name)}
            disabled={disabled}
          >
            {deleteButtonSign}
          </DeleteButton>
        )}
      </Badge>
    ));
  return (
    <div className={className ?? 'row spark-pad-l-2 spark-pad-r-2 badgesRow'} onClick={evt => evt.stopPropagation()}>
      <div className={className ?? 'col-xs-12'}>{createBadges()}</div>
    </div>
  );
};

const Badge = styled.span`
  background-color: ${colors.grey300};
  border: 2px solid ${colors.grey200};
  color: ${colors.highlightGrey};
  overflow-y: hidden;
`;
const DeleteButton = styled.button`
  border: none;
  border-left: 2px solid ${colors.grey200};
  line-height: 250%;
  padding-left: 0.75rem;
  font-size: 1.4rem;
  background-color: ${colors.grey300};
  color: ${colors.highlightGrey};

  &:hover {
    cursor: pointer;
  }
`;
