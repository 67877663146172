import React, {ReactNode, useEffect, useRef} from 'react';
import SparkStepIndicator from '@sabre/spark/js/src/components/step-indicator';

const StepIndicator = ({children}: {children: ReactNode}) => {
  const indicatorRef = useRef(null);

  useEffect(() => {
    const sparkAccordion = new SparkStepIndicator(indicatorRef.current);
    return () => {
      sparkAccordion.remove();
    };
  }, [indicatorRef]);

  return (
    <div className="spark-step-indicator" date-type="large" ref={indicatorRef}>
      <div className="spark-step-indicator__body">
        <div className="spark-step-indicator__list">{children}</div>
      </div>
    </div>
  );
};

export default StepIndicator;
