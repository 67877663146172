import React from 'react';

type CheckboxProps = {
  label: string;
  id?: string;
  isChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  error?: string;
  disabled?: boolean;
  value?: string;
  testId?: string;
};

const Checkbox = ({
  label,
  id,
  isChecked,
  onChange,
  name,
  forwardedRef,
  error,
  disabled,
  value,
  testId,
}: CheckboxProps & {
  forwardedRef?: ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<HTMLInputElement | null> | null;
}) => {
  return (
    <div className="spark-checkbox-group" {...(error && {'data-error': ''})}>
      {error && <span className="spark-checkbox-group__message">{error}</span>}
      <label className="spark-checkbox">
        <input
          id={id}
          className="spark-checkbox__input"
          type="checkbox"
          name={name}
          ref={forwardedRef}
          checked={isChecked}
          onChange={onChange}
          disabled={disabled}
          value={value}
        />
        <span className="spark-checkbox__box" />
        <span className="spark-label" data-testid={testId}>
          {label}
        </span>
      </label>
    </div>
  );
};

export default React.forwardRef(
  (
    props: CheckboxProps,
    ref: ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<HTMLInputElement | null> | null
  ) => <Checkbox {...props} forwardedRef={ref} />
);
