import * as yup from 'yup';
import contactDetails from './schema-parts/contactDetails.schema';
import companyInformation from './schema-parts/companyInformation.schema';
import {EPR_MAX_LENGTH, PCC_MAX_LENGTH} from '@scm/components/pccEpr/pccEprSchema';

// this is fixed schema of OnboardRedAppProvider request body, it must be changed manually, accordingly to changes in onboardings-api.yaml,
// because script that generates yup definitions cannot process multipart/mixed requestBody, only application/json

export default yup.object().shape({
  contactDetails,
  companyInformation: yup
    .object()
    .required()
    .shape({
      ...companyInformation,
      website: yup.string().max(100),
    }),
  sabreAccountInfo: yup.object().shape({
    pcc: yup.string().max(PCC_MAX_LENGTH),
    epr: yup
      .string()
      .matches(/^[0-9]{0,6}$/)
      .max(EPR_MAX_LENGTH),
  }),
  redAppPricingType: yup.mixed().oneOf(['EPR Fee', 'Revenue Share']),
  proposal: yup.object().shape({
    name: yup
      .string()
      .required()
      .matches(/^[a-zA-Z0-9 :().,@'-]{3,50}$/),
    sellerId: yup.string().required(),
    provisioningType: yup.mixed().oneOf(['Public', 'Private']).required(),
    productInfo: yup.object().shape({
      productType: yup.string().required(),
      general: yup.object().shape({
        flowDiagramUrl: yup.string(),
        description: yup.string(),
        categories: yup.array().max(100).of(yup.string()),
        interactionWithEndTravelerComment: yup.string().max(500),
        pnrDocumentationComment: yup.string().max(500),
        developmentType: yup.mixed().oneOf(['In-house', 'Outsourcing', 'None']),
        technologies: yup.array().max(5).of(yup.string()),
      }),
      pricing: yup.object().shape({
        comment: yup.string().max(250),
      }),
      regionalization: yup.object().shape({
        availableRegions: yup.array().of(yup.string()),
        availableCountries: yup.array().of(yup.string().matches(/^[A-Z]{2}$/)),
        regionalConstraintsComment: yup.string().max(250),
      }),
      services: yup.object().shape({
        externalServices: yup.object().shape({
          comment: yup.string().max(250),
          authentication: yup.string().max(250),
        }),
        sabreServicesComment: yup.string().max(250),
        useBargainFinderMax: yup.boolean(),
        sr360TechnologiesComment: yup.string().max(250),
      }),
      security: yup.object().shape({
        pci: yup.object().shape({
          data: yup
            .array()
            .of(
              yup
                .mixed()
                .oneOf([
                  'Primary Account Number',
                  'Cardholder Name',
                  'Service Code or Expiration Date',
                  'Magnetic Stripe Data or CVV/CVC Data',
                  'PINs/PIN Blocks or Any Track-1 or Track-2 Data',
                ])
            ),
          panProtectionComment: yup.string().max(250),
          retentionPeriod: yup.string().max(250),
          usedBy: yup.string().max(250),
          utilizingThirdPartyProcessor: yup.boolean(),
          comment: yup.string().max(250),
        }),
        pii: yup.object().shape({
          data: yup
            .array()
            .of(
              yup
                .mixed()
                .oneOf([
                  'Social Security Number',
                  'Driver’s License Number',
                  'Passport Number',
                  'Date of Birth',
                  'Address, phone number, country, or National Identification, etc',
                ])
            ),
          howIsForwarded: yup.string().max(250),
          euNationals: yup.string().max(250),
          complianceWithGdpr: yup.string().max(250),
          retentionPeriod: yup.string().max(250),
          usedBy: yup.string().max(250),
          sharedWithThirdParty: yup.string().max(250),
        }),
        protectionComment: yup.string().max(500),
        gds: yup.object().shape({
          howIsForwarded: yup.string().max(250),
          protocol: yup.string().max(250),
          numberOfTransaction: yup.string().max(250),
          comment: yup.string().max(250),
          connectionToGdsComment: yup.string().max(250),
          relyOnFreeTextRemarksInPnr: yup.boolean(),
        }),
        accounting: yup.object().shape({
          dataType: yup.array().of(yup.mixed().oneOf(['Accounting data', 'Back-office data'])),
          attributesDescription: yup.string().max(250),
        }),
        patchTimeline: yup.string().max(250),
      }),
    }),
    proposalStep: yup.number(),
  }),
  flowDiagram: yup.string(),
});
