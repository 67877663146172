import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import PackageExceptions from './svsSections/PackageExceptions';
import {Button} from '@sabre/spark-react-core';
import Modal from '@sabre/spark-react-core/modal';
import TaskExceptions from './svsSections/TaskExceptions';
import CompareRequest from './svsSections/CompareRequest';
import ServiceUsage from './svsSections/ServiceUsage';
import ResponseData from './svsSections/ResponseData';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import {ButtonSize, ToastType, TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {Separator} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {
  Configuration as CertificationConfiguration,
  SVSExceptionsApi,
  SvsExceptionsModel,
  Thresholds,
  UsedService,
  VersionsApi,
} from '../../../generated/certification';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {fromSvsExceptionsMapper, svsExceptionsModelMapper} from './mapper/svsExceptionsMapper';
import SkipCertificateCheck from './svsSections/SkipCertificateCheck';
import {openToast} from '@scm/components/messaging/openToast';
import {validationResultMapper} from './mapper/validationResultMapper';
import {redAppSupportEmail} from '@scm/components/utils/common';
import {hrefStringCreator} from '@scm/components/messaging/HrefElement';
import SaveSvsExceptionsModal from './SaveSvsExceptionsModal';
import {SvsValues, thresholdsName} from './SvsValues';
import {initialValues} from './svsConstants';
import SvsDescription from './SvsDescription';
import {useParams} from 'react-router-dom';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const RESEND_BUTTON_TIMEOUT = 20000;

const Svs = ({chosenVersionId, hideButtons}: {chosenVersionId?: string; hideButtons?: boolean}) => {
  const {formatMessage} = useIntl();
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [openSaveExceptionsModal, setOpenSaveExceptionsModal] = useState(false);
  const [error, setError] = useState(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleCloseSaveExceptionsModal = () => setOpenSaveExceptionsModal(false);
  const modalId = 'hintMessage';
  const svsFormMethods = useForm<SvsValues>({defaultValues: initialValues});
  const {setValue, watch} = svsFormMethods;
  const {appName = ''} = useParams();
  const [services, setServices] = useState<UsedService[]>([]);
  const [acceptedThresholds, setAcceptedThresholds] = useState<Thresholds[]>([]);

  const fetchSvsExceptions = async (id: string) =>
    await new SVSExceptionsApi(
      new CertificationConfiguration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() ?? '',
        middleware: middleware,
      })
    ).getSvsExceptions({id});

  const saveSvsExceptions = async (id: string, svsExceptionsModel?: SvsExceptionsModel) => {
    await new SVSExceptionsApi(
      new CertificationConfiguration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() ?? '',
        middleware: middleware,
      })
    ).updateSvsExceptions({
      id,
      svsExceptionsModel,
    });
  };

  const revalidateBundle = async (version: string) =>
    await new VersionsApi(
      new CertificationConfiguration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() ?? '',
        middleware: middleware,
      })
    ).revalidateBundle({id: version});

  const fetchValidationResult = async (id: string) =>
    await new VersionsApi(
      new CertificationConfiguration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() ?? '',
        middleware: middleware,
      })
    ).getValidationResults({id});

  const isThereAnyExceededThreshold = () => {
    return services
      .map(service => service.actions)
      .flat()
      .some(action => action?.thresholdExceeded);
  };

  const openModalOrSaveSvsExceptionsDirectly = () => {
    if (isThereAnyExceededThreshold() && watch(thresholdsName).length === 0) {
      setOpenSaveExceptionsModal(true);
    } else {
      saveSvsExceptionsHandler(watch());
    }
  };

  const createInitialThresholds = (services: UsedService[]) => {
    const servicesWithExceededThresholds = services.filter(usedService =>
      usedService.actions?.some(action => action?.thresholdExceeded)
    );
    if (servicesWithExceededThresholds.length !== 0) {
      setAcceptedThresholds(servicesWithExceededThresholds.map(service => mapServiceToThreshold(service)));
    }
  };

  const mapActionsToThresholds = (service: UsedService): Thresholds => {
    const actions = service.actions
      ?.filter(action => action.thresholdExceeded)
      .map(action => {
        return {name: action.name, threshold: action.threshold};
      });

    return {
      service: service.name,
      metric: service.actions!![0].metric,
      actions: actions,
    };
  };

  const mapServiceToThreshold = (service: UsedService): Thresholds => {
    if (service.actions?.length === 1) {
      return {
        service: service.name,
        metric: service.actions[0].metric,
        threshold: service.actions[0].threshold,
      };
    } else {
      return mapActionsToThresholds(service);
    }
  };

  useEffect(() => {
    if (error) {
      openToast(
        formatMessage({id: 'svs.error.title'}),
        formatMessage(
          {id: 'svs.error.message'},
          {
            email: hrefStringCreator(redAppSupportEmail),
          }
        ),
        ToastType.WARNING,
        'spark-icon-alert-triangle',
        true
      );
    }
  }, [error]);

  useEffect(() => {
    fetchSvsExceptions(appName)
      .then(svs => {
        Object.entries(fromSvsExceptionsMapper(svs)).forEach(([key, value]) => setValue(key as keyof SvsValues, value));
      })
      .catch(() => setError(true));
  }, [appName, setValue]);

  useEffect(() => {
    if (chosenVersionId) {
      fetchValidationResult(chosenVersionId)
        .then(res => {
          validationResultMapper(res, setValue);
          setServices(res.validation?.usedServices ?? []);
          if (watch(thresholdsName).length === 0) {
            createInitialThresholds(res.validation?.usedServices ?? []);
          }
        })
        .catch(() => setError(true));
    }
  }, [chosenVersionId, setValue]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const saveSvsExceptionsHandler = (data: SvsValues) => {
    const result = svsExceptionsModelMapper(
      data,
      watch(thresholdsName).length === 0 ? acceptedThresholds : watch(thresholdsName)
    );
    saveSvsExceptions(appName ?? '', result)
      .then(() => {
        openToast(
          formatMessage({id: 'svs.toast.title'}),
          formatMessage({id: 'svs.toast.save.success'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      })
      .catch(() => {
        setError(true);
        openToast(
          formatMessage({id: 'svs.toast.title'}),
          formatMessage({id: 'svs.save.error.message'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      });
  };

  const sendBundleToSvs = () => {
    revalidateBundle(chosenVersionId as string)
      .then(() => {
        setIsResendButtonDisabled(true);
        setTimer(
          setTimeout(() => {
            setIsResendButtonDisabled(false);
          }, RESEND_BUTTON_TIMEOUT)
        );
        openToast(
          formatMessage({id: 'svs.toast.title'}),
          formatMessage({id: 'svs.toast.send.success'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      })
      .catch(() => {
        setError(true);
        openToast(
          formatMessage({id: 'svs.toast.title'}),
          formatMessage(
            {id: 'svs.error.message'},
            {
              email: hrefStringCreator(redAppSupportEmail),
            }
          ),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      });
  };

  return (
    <>
      <Button
        icon="spark-icon-question-mark-circle"
        onClick={handleOpen}
        ariaLabel={formatMessage({id: 'accessibility.hintIcon.tooltip'})}
        tooltip={formatMessage({id: 'svs.hint.title'})}
        tooltipDirectionX={TooltipDirectionX.RIGHT}
        tooltipDirectionY={TooltipDirectionY.BOTTOM}
        id={modalId}
        className="spark-icon--fill spark-pull-right spark-pad-t-0 spark-mar-r-1"
      />
      <span className="spark-clearfix" />
      <ResponseData formMethods={svsFormMethods} />
      <Separator className="spark-mar-l-2 spark-mar-b-2 spark-pad-b-1 spark-bold spark-large">
        <FormattedMessage id="svs.servicesUsage.title" />
      </Separator>
      <ServiceUsage formMethods={svsFormMethods} services={services} acceptedThresholds={acceptedThresholds} />
      <Separator className="spark-mar-l-2 spark-mar-b-2 spark-pad-b-1 spark-bold spark-large">
        <FormattedMessage id="svs.taskExceptions.title" />
      </Separator>
      <TaskExceptions formMethods={svsFormMethods} />
      <Separator className="spark-mar-l-2 spark-mar-b-2 spark-pad-b-1 spark-bold spark-large">
        <FormattedMessage id="svs.packageExceptions.title" />
      </Separator>
      <PackageExceptions formMethods={svsFormMethods} />
      <Separator className="spark-mar-l-2 spark-mar-b-2 spark-pad-b-1 spark-bold spark-large">
        <FormattedMessage id="svs.skipCertificateCheck.title" />
      </Separator>
      <SkipCertificateCheck formMethods={svsFormMethods} />
      <Separator className="spark-mar-l-2 spark-mar-b-2 spark-pad-b-1 spark-bold spark-large">
        <FormattedMessage id="svs.compareRequest.title" />
      </Separator>
      <CompareRequest formMethods={svsFormMethods} />
      {!hideButtons && (
        <ButtonContainer>
          <Button
            className="col-xs-12 col-lg-3"
            size={ButtonSize.SMALL}
            onClick={() => openModalOrSaveSvsExceptionsDirectly()}
          >
            <FormattedMessage id="svs.save" />
          </Button>
          <Button
            className="col-xs-12 col-lg-3"
            size={ButtonSize.SMALL}
            onClick={() => sendBundleToSvs()}
            disabled={isResendButtonDisabled}
          >
            <FormattedMessage id="svs.saveBundle" />
          </Button>
        </ButtonContainer>
      )}
      <Modal onClose={handleClose} open={open} title={formatMessage({id: 'svs.hint.title'})} titleHeadingLevel={2}>
        <SvsDescription />
      </Modal>
      <SaveSvsExceptionsModal
        handleClose={handleCloseSaveExceptionsModal}
        open={openSaveExceptionsModal}
        saveExceptions={() => saveSvsExceptionsHandler(watch())}
      />
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
`;
export default Svs;
