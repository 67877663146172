export const onboardingContent = {
  'onboardingJoinUsPage.step1': 'General Information',
  'onboardingJoinUsPage.step2': 'Terms and Conditions',
  'onboardingJoinUsPage.step3': 'Red App Type',
  'onboardingJoinUsPage.step4': 'Sabre Pricing',
  'onboardingJoinUsPage.step5': 'Proposal',
  'providerType.introduction':
    'If you are interested in being both a Sabre Developer Partner and Sabre Red App Certified Provider, please complete the onboarding process for Sabre Developer Partner, and when we contact you, let us know that you also want to offer Sabre Red Apps.',
  'providerType.chooseType': 'Please indicate which provider you want to onboard as?',
  'providerType.title': 'Choose Provider Type',
  'providerType.subTitle': 'Please indicate which provider you want to onboard as',
  'providerType.benefits': 'Benefits: ',
  'providerType.targetUsers.sdp': 'Target users of Developer Partners’ solution: ',
  'providerType.targetUsers.certifyProvider': 'Target users of Red App Providers’ solution: ',
  'providerType.sdp.description':
    'Sabre Developer Partner Program enables third-party companies to develop and deploy solutions for Sabre connected travel agencies while utilizing Sabre’s technology, authorization and prior approval is required by Sabre.',
  'providerType.certifyProvider.description':
    'Sabre Red App Certified Provider Program enables third-party developers to create a Sabre Red App for Sabre connected travel agencies using Sabre Red 360, authorization and prior approval is required by Sabre.',
  'providerType.sdp.benefits':
    'You have access to the Best-in-Class technology – a robust collection of APIs available through the Sabre Dev Studio portal. Also, you would receive direct API support and technical consultancy services to ensure seamless integration. In addition, you will promote your products to agency customers through Sabre Central Marketplace and Developer Partner Network.',
  'providerType.certifyProvider.benefits':
    'You have access to over 370,000 agents in 135 countries using Sabre Red 360, a user interface that simplifies booking travel and improves workflow efficiency. Sabre Red Apps expand and integrate customized productivity tools to simplify agency workflow. The Sabre Red 360 SDK provides all the tools you’ll need to build your Red App. Tailor Sabre Red 360 in infinite ways to fit your business needs. In addition, you will promote your products to agency customers through Sabre Central Marketplace.',
  'providerType.sdp.target': 'travel agencies, corporations, hoteliers.',
  'providerType.certifyProvider.target': 'travel agencies using Sabre Red 360.',
  'providerType.firstStep': 'Choose Provider Type',
  'contactDetails.description':
    'Provide contact information for your Purchase Contact account. This user will receive messages from agency buyers interested in purchasing your Red App(s). Please complete this form honestly and to the best of your knowledge. Ensure to complete all mandatory fields before moving to the next step.',
  'contactDetails.provider.description':
    'Please provide information about your company and how we can contact you. Before moving to the next step where you will be asked for your Red App proposal, please make sure all mandatory fields are completed.',
  'contactDetails.contactDetails.title': 'Contact Details',
  'contactDetails.companyInformation.title': 'Company Information',
  'contactDetails.companyInformation.address.label': 'Company Address:',
  'contactDetails.businessProfile.label': 'Business Profile',
  'contactDetails.businessProfile.placeholder':
    'Provide a brief description of your company. What do you specialize in? What makes your business unique?',
  'contactDetails.additionalInfo.title': 'Additional Information',
  'contactDetails.additionalInfo.description': 'If applicable, please provide your EPR or PCC.',
  'contactDetails.pccEpr.label': 'Do you currently have a Sabre PCC?',
  'contactDetails.pcc.label': 'PCC',
  'contactDetails.pcc.note': 'The PCC should consist of four characters.',
  'contactDetails.epr.placeholder': 'EPR',
  'contactDetails.epr.note':
    'If you have a Sabre EPR, please share it below. The EPR can be up to 6 characters long and only numbers are acceptable.',
  'redAppType.title': 'Red App Type',
  'redAppType.subTitle': `Please indicate access option for your first Red App. Public Red Apps appear to all in the Marketplace. Private Red Apps do not appear in the Marketplace and are available to your agency's internal users only.`,
  'redAppType.note': 'Choose Red App Type',
  'summary.success.title':
    'Thank you for your submission. A Sabre representative will respond to your request within 72 business hours.',
  'summary.success.content.paragraph1':
    'Thank you for your interest in working with Sabre and the Sabre Red App program, which provides access to over 370,000 agents in 135 countries, most of whom use Sabre Red 360. Sabre Red 360 is an end-to-end sales workspace that sells over $100 billion in travel inventory every year.',
  'summary.success.content.paragraph2':
    'Once Sabre approves a Red App developer and their proposal, the developer can start creating a Red App.',
  'summary.success.content.paragraph3':
    "As a Certified Provider, you will get an access to Sabre Red 360 Developer Toolkit, which is a robust toolkit with sample plug-ins, documentation, source code, and utilities that developers need to build Sabre Red Apps that run seamlessly in the Sabre Red 360. Grow your business and open new sources of revenue by reaching a global marketplace through travel's most trusted leader.",
  'summary.success.content.resources': 'Resources:',
  'summary.success.content.resources.item1':
    '• Download now the useful references and latest <a href="https://developer.sabre.com/guides/travel-agency/sdks/sabre-red-360-resources" target="_blank">Sabre Red 360 Developer toolkit</a>',
  'summary.success.content.resources.item2':
    '• Please check <a href="https://developer.sabre.com/solutions/how-to-videos" target="_blank">Sabre Dev Studio</a> for useful video resources',
  'summary.success.content.resources.item3':
    '• Learn how to <a href="https://www.youtube.com/watch?app=desktop&v=ZgwbISnB35Y&feature=youtu.be&ab_channel=SabreTravelNetwork" target="_blank">install Sabre Red 360 Developer Toolkit</a>',
  'summary.error.title': 'Your form cannot be submitted.',
  'summary.error.content':
    'Please try to submit your form once again. If submitting the form will not be possible, please reach out to <a href="mailto:scm-support@sabre.com">scm-support@sabre.com</a>.',
};
