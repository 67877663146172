import {FormattedMessage} from 'react-intl';
import React from 'react';
import {Bundle} from '../ProductTabsContainer';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const PopoverListItem = ({
  handleOpen,
  edit,
  bundle,
  compatibilityFormId,
  messageId,
  disabled,
}: {
  handleOpen?: (open: boolean) => void;
  edit?: (bundle: Bundle) => void;
  bundle: Bundle;
  compatibilityFormId?: string;
  messageId: string;
  disabled?: boolean;
}) => {
  const button = disabled ? (
    <InactiveListItem className="spark-popover__list-link spark-link" aria-disabled>
      <FormattedMessage id={messageId} />
    </InactiveListItem>
  ) : (
    <span
      onClick={() => {
        edit?.(bundle);
        handleOpen?.(true);
      }}
      className="spark-popover__list-link spark-link"
      role="button"
      id={compatibilityFormId}
    >
      <FormattedMessage id={messageId} />
    </span>
  );
  return (
    <>
      <li className="spark-popover__list-item">{button}</li>
    </>
  );
};

const InactiveListItem = styled.span`
  color: ${colors.disabledGrey};
  cursor: not-allowed;
`;
export default PopoverListItem;
