import React from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const LimitationItemsDisplay = ({
  items,
  handleChooseItem,
  dataTestid,
}: {
  items: string[];
  handleChooseItem: (value: string) => void;
  dataTestid: string;
}) => (
  <LimitationItemBox className="spark-flex" data-testid={dataTestid}>
    {items?.map((item, index) => (
      <LimitationItem
        key={index}
        className="spark-mar-t-.5 spark-mar-b-.5 spark-pad-1"
        onClick={() => handleChooseItem(item)}
      >
        {item}
      </LimitationItem>
    ))}
  </LimitationItemBox>
);

const LimitationItemBox = styled.div`
  height: 200px;
  width: 66%;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid ${colors.grey200};
  border-radius: 0.5rem;
`;

const LimitationItem = styled.div`
  cursor: pointer;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  &&:hover {
    background-color: ${colors.grey100};
    border-radius: 0.5rem;
    transition: 0.1s ease;
  }
`;

export default LimitationItemsDisplay;
