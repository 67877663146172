import React, {useContext} from 'react';
import SectionHeader from './SectionHeader';
import ReviewRow from './ReviewRow';
import {
  connectToGdsDescriptionName,
  connectToGdsName,
  descriptionName,
  developmentTypeName,
  flowDiagramName,
  interactsWithEndTravelerDescriptionName,
  interactsWithEndTravelerName,
  ProposalValues,
  providePnrDocumentationDescriptionName,
  providePnrDocumentationName,
  redAppCategoryName,
  redAppName,
  technologiesDescriptionName,
  technologiesName,
} from '../../../ProposalValues';
import {ProposalContext} from '../../../ProposalForm';

const definitionFields: Array<keyof ProposalValues> = [
  redAppName,
  descriptionName,
  redAppCategoryName,
  flowDiagramName,
  interactsWithEndTravelerName,
  interactsWithEndTravelerDescriptionName,
  providePnrDocumentationName,
  providePnrDocumentationDescriptionName,
  connectToGdsName,
  connectToGdsDescriptionName,
  developmentTypeName,
  technologiesName,
  technologiesDescriptionName,
];

const DefinitionReview = () => {
  const {watch} = useContext(ProposalContext);

  return (
    <>
      <SectionHeader id="review.definition.title" />
      {definitionFields.map(name => watch(name) !== undefined && <ReviewRow key={name} name={name} />)}
    </>
  );
};
export default DefinitionReview;
