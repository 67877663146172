import React, {ReactElement, useEffect} from 'react';
import {ProductCompatibilities} from '../../../../storefrontData/productDetailsUtils';
import {Controller, useForm} from 'react-hook-form';
import {Button, RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {ButtonContainer} from '../../../actionForms/BundleTestingForm';
import {FormattedMessage, useIntl} from 'react-intl';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {Configuration as CertificationConfiguration, VersionsApi} from '../../../../../generated/certification';
import {certificationApiBaseLink} from '../../../../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {openToast} from '@scm/components/messaging/openToast';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

interface FormValues {
  id?: string;
  compatibility?: ProductCompatibilities;
}

function patchCompatibility(id: string, canBeUsedInSabreRedDesktop: boolean, canBeUsedInSabreRedWeb: boolean) {
  return new VersionsApi(
    new CertificationConfiguration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).setVersionCompatibility({id, compatibilityRequest: {canBeUsedInSabreRedDesktop, canBeUsedInSabreRedWeb}});
}

const CompatibilityForm = ({
  bundleId,
  bundleCompatibility,
  webCompatibility,
  onClose,
  reloadBundles,
}: {
  bundleId: string;
  bundleCompatibility?: ProductCompatibilities;
  webCompatibility: boolean;
  onClose: () => void;
  reloadBundles: () => void;
}) => {
  const {formatMessage} = useIntl();
  const compatibilityName = 'compatibility';
  const bundleIdName = 'id';
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: {isDirty},
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (watch(bundleIdName) !== bundleId) {
      setValue(bundleIdName, bundleId);
      setValue(compatibilityName, bundleCompatibility);
    }
  });

  const onSubmit = () => {
    const comp = getValues().compatibility;
    const desktopComp = comp === ProductCompatibilities.Desktop || comp === ProductCompatibilities['Desktop & Web'];
    const webComp = comp === ProductCompatibilities.Web || comp === ProductCompatibilities['Desktop & Web'];

    patchCompatibility(bundleId, desktopComp, webComp)
      .then(() => {
        onClose();
        reloadBundles();
        openToast(
          formatMessage({id: 'tabs.bundle.compatibilityForm.success.title'}),
          formatMessage({id: 'tabs.bundle.compatibilityForm.success.description'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      })
      .catch(() => {
        openToast(
          formatMessage({id: 'tabs.bundle.compatibilityForm.error.title'}),
          formatMessage({id: 'tabs.bundle.compatibilityForm.error.description'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      });
  };

  return (
    <form>
      <p className="spark-mar-b-1 spark-small">
        <FormattedMessage id="tabs.bundle.compatibilityForm.description" />
      </p>
      <Controller
        name={compatibilityName}
        control={control}
        render={({field: {onChange, name, value}}): ReactElement => (
          <RadioButtonGroup name={name} value={value as string} onChange={onChange} className="row">
            <RadioButton
              label={ProductCompatibilities['Desktop']}
              value={ProductCompatibilities['Desktop']}
              className="col-xs-12"
            />
            <RadioButton
              label={ProductCompatibilities['Desktop & Web']}
              value={ProductCompatibilities['Desktop & Web']}
              disabled={!webCompatibility}
              className="col-xs-12"
            />
            <RadioButton
              label={ProductCompatibilities['Web']}
              value={ProductCompatibilities['Web']}
              disabled={!webCompatibility}
              className="col-xs-12"
            />
          </RadioButtonGroup>
        )}
      />
      <ButtonContainer className="spark-flex spark-mar-t-2">
        <Button secondary className="spark-mar-r-1" size={ButtonSize.SMALL} type="button" onClick={onClose}>
          <FormattedMessage id="common.cancelButton" />
        </Button>
        <Button size={ButtonSize.SMALL} type="button" onClick={onSubmit} disabled={!isDirty} tabIndex={0}>
          <FormattedMessage id="common.save" />
        </Button>
      </ButtonContainer>
    </form>
  );
};

export default CompatibilityForm;
