export const uploadedScribeScriptName = 'uploadedScribeScript';
export const uploadedScribeScriptFilesNames = 'uploadedScribeScriptFiles';
export const hiddenUploadedScribeScriptName = 'hiddenUploadedScribeScript';
export const hiddenUploadedScribeScriptFilesNames = 'hiddenUploadedScribeScriptFiles';
export const resourcesName = 'resources';
export const resourcesFilesNames = 'resourcesFiles';
export const activeListeningName = 'activeListener';
export const activeListeningFilesNames = 'activeListenerFiles';

export interface ScribeScriptsValues {
  [uploadedScribeScriptName]: string;
  [uploadedScribeScriptFilesNames]: Array<File>;
  [hiddenUploadedScribeScriptName]: string;
  [hiddenUploadedScribeScriptFilesNames]: Array<File>;
  [resourcesName]: string;
  [resourcesFilesNames]: Array<File>;
  [activeListeningName]: string;
  [activeListeningFilesNames]: Array<File>;
}
