/* tslint:disable */
/* eslint-disable */
/**
 * Certification API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuditLog,
} from '../models';
import {
    AuditLogFromJSON,
    AuditLogToJSON,
} from '../models';

export interface GetAuditsRequest {
    objectType: GetAuditsObjectTypeEnum;
    id: string;
}

/**
 * 
 */
export class AuditApi extends runtime.BaseAPI {

    /**
     * Get audit related to given object type and id.
     */
    async getAuditsRaw(requestParameters: GetAuditsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuditLog>>> {
        if (requestParameters.objectType === null || requestParameters.objectType === undefined) {
            throw new runtime.RequiredError('objectType','Required parameter requestParameters.objectType was null or undefined when calling getAudits.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAudits.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/audit/{objectType}/{id}`.replace(`{${"objectType"}}`, encodeURIComponent(String(requestParameters.objectType))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuditLogFromJSON));
    }

    /**
     * Get audit related to given object type and id.
     */
    async getAudits(requestParameters: GetAuditsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuditLog>> {
        const response = await this.getAuditsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetAuditsObjectTypeEnum = {
    Proposal: 'proposal',
    Product: 'product',
    Version: 'version',
    Onboarding: 'onboarding'
} as const;
export type GetAuditsObjectTypeEnum = typeof GetAuditsObjectTypeEnum[keyof typeof GetAuditsObjectTypeEnum];
