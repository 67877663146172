import React, {ReactNode, useEffect, useRef} from 'react';
import StepIndicator from '@sabre/spark/js/src/components/step-indicator';

const ProposalIndicator = ({children}: {children: ReactNode}) => {
  const indicatorRef = useRef(null);
  useEffect(() => {
    const sparkStepIndicator = new StepIndicator(indicatorRef.current, {type: 'large'});
    return () => {
      sparkStepIndicator.remove();
    };
  }, [indicatorRef]);

  return (
    <div className="spark-step-indicator" ref={indicatorRef}>
      <div className="spark-step-indicator__body">
        <div className="spark-step-indicator__list">{children}</div>
      </div>
    </div>
  );
};

export default ProposalIndicator;
