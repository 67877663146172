import React, {ReactElement, SetStateAction, useContext, useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Checkbox, RadioButton, RadioButtonGroup, SelectInput, TextInput} from '@sabre/spark-react-core';
import {
  Configuration as ProductConfiguration,
  Configuration,
  Pricing as IPricing,
  ProductApi,
} from '../../../../../generated/product';
import {Controller, useForm} from 'react-hook-form';
import {ButtonSize, MessageStatus, ToastType} from '@sabre/spark-react-core/types';
import PriceDefinition from './PriceDefinition';
import {
  additionalInfoName,
  perPccepr,
  perPcceprType,
  perTransaction,
  perTransactionType,
  pricingLevelName,
  PricingValues,
  RowData,
  rowsListPccepr,
  rowsListTransaction,
  selectedFeeRecurrenceName,
} from './PricingValues';
import {Loading, Star} from '@scm/components';
import {initialValues} from './pricingConstants';
import {PricingContextValue} from './PricingContextValue';
import {Pricing as PricingApiBody} from '../../../../../generated/product/models/Pricing';
import {certificationApiBaseLink, productApiBaseLink} from '../../../../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {yupResolver} from '@hookform/resolvers/yup';
import {pricingSchema} from '../../../../../schemas/pricingSchema';
import {pricingMapper} from '../../../../../pricingMapper/pricingMapper';
import PricingView from './PricingView';
import {StorefrontDataContext} from '../../../../storefrontData/dataProvider/StorefrontDataProvider';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {Bundle} from '@scm/product-components/pages/productDetails/productTabs/ProductTabsContainer';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {
  Configuration as CertificationConfiguration,
  GetMissingItemsForPublishingRequest,
  VersionsApi,
} from '../../../../../generated/certification';

export enum PricingLevel {
  pcc = 'PCC level',
  epr = 'EPR level',
}

export enum FeeTypes {
  'Monthly Fee' = 'Monthly Fee',
  'One-Time Fee' = 'One-Time Fee',
  'Annual Fee' = 'Annual Fee',
}

export const PricingContext = React.createContext<PricingContextValue>({} as unknown as PricingContextValue);

const getFeeTypesForInternalOrExternalApps = (isInternalProvider: boolean) =>
  !isInternalProvider
    ? Object.values(FeeTypes).map(value => ({value, label: value}))
    : [
        {value: FeeTypes['Monthly Fee'], label: FeeTypes['Monthly Fee']},
        {value: FeeTypes['One-Time Fee'], label: FeeTypes['One-Time Fee']},
      ];

const postPricing = async (sku: string, pricing: PricingApiBody) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).createPricing({sku, pricing});

const deletePricing = async (sku: string) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).deletePricing({sku});

const fetchPricing = async (sku: string) => {
  return new ProductApi(
    new ProductConfiguration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).getPricing({sku});
};

const Pricing = ({
  latestVersion,
  setCanPublishVersion,
  setMissingItemsForPublishing,
}: {
  latestVersion?: Bundle;
  setCanPublishVersion: React.Dispatch<SetStateAction<boolean>>;
  setMissingItemsForPublishing: React.Dispatch<SetStateAction<string[]>>;
}) => {
  const {sku, isInternalProvider} = useContext(StorefrontDataContext);
  const [error, setError] = useState(false);
  const [isLoading, setIsIsLoading] = useState(true);
  const [productPricing, setProductPricing] = useState<IPricing>();
  const {formatMessage} = useIntl();
  const starMarginTop = -6;
  const additionalInfoMaxLength = 250;
  const skuFromUrl = useParams().appName ?? '';
  const productDetailsName = 'productDetails.updates';
  const {
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: {errors},
  } = useForm<PricingValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(pricingSchema),
  });

  const pricingLevel = watch(pricingLevelName);

  const openErrorMessage = () =>
    openToast(
      createMessageString(formatMessage, productDetailsName, true, true),
      createMessageString(formatMessage, productDetailsName, false, true),
      ToastType.WARNING,
      'spark-icon-alert-triangle',
      true
    );

  const openSuccessMessage = () =>
    openToast(
      createMessageString(formatMessage, productDetailsName, true),
      createMessageString(formatMessage, productDetailsName),
      ToastType.POSITIVE,
      'spark-icon-check'
    );

  useEffect(() => {
    if (error) {
      openErrorMessage();
      setError(false);
    }
  }, [error]);

  useEffect(() => {
    if (isLoading && productPricing === undefined) {
      fetchPricing(sku)
        .then(pricing => setProductPricing(pricing))
        .catch(err => err.response.status === 404 || setError(true))
        .finally(() => setIsIsLoading(false));
    }
  }, [productPricing, setProductPricing, isLoading]);

  const submitHandler = async () => {
    try {
      const pricing = pricingMapper(getValues());
      setProductPricing(pricing);
      setIsIsLoading(true);
      await postPricing(skuFromUrl, pricing);
      setIsIsLoading(false);
      openSuccessMessage();
      checkIfVersionReadyForPublishing({id: latestVersion?.id})
        .then(res => {
          setCanPublishVersion(res.length === 0);
          setMissingItemsForPublishing(res);
        })
        .catch(() => setCanPublishVersion(false));
    } catch (e) {
      openErrorMessage();
    }
  };

  const deletePricingHandler = async () => {
    try {
      reset();
      setIsIsLoading(true);
      await deletePricing(skuFromUrl);
      setIsIsLoading(false);
      setProductPricing(undefined);
      openSuccessMessage();
      checkIfVersionReadyForPublishing({id: latestVersion?.id})
        .then(res => {
          setCanPublishVersion(res.length === 0);
          setMissingItemsForPublishing(res);
        })
        .catch(() => setCanPublishVersion(false));
    } catch (e) {
      openErrorMessage();
    }
  };

  const getFeeTypeOptions = () => {
    const pccLevelFeeOptions = getFeeTypesForInternalOrExternalApps(isInternalProvider);
    return [
      {value: '', label: ''},
      ...(pricingLevel === PricingLevel['pcc']
        ? pccLevelFeeOptions
        : [{value: FeeTypes['Monthly Fee'], label: FeeTypes['Monthly Fee']}]),
    ];
  };

  const loadingContent = () => (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  );

  const additionalInfoLabel = (
    <>
      {formatMessage({
        id: 'tabs.price.additionalInfo.textInput.label',
      })}
      {(watch(perTransaction) || watch(perPcceprType)?.includes('NEGOTIABLE')) && <Star marginTop={starMarginTop} />}
    </>
  ) as unknown as string;

  const changeValueOptions = useMemo(() => ({shouldValidate: true, shouldTouch: true, shouldDirty: true}), []);

  const isValid = () => {
    const isAdditionalInfoPresentIfRequired = (isPerTransactionType: boolean, type?: string) =>
      !(isPerTransactionType || type?.includes('NEGOTIABLE')) ||
      (watch(additionalInfoName) && watch(additionalInfoName).length >= 10);

    const isPriceRangesValid = (priceRanges: Array<RowData>, type?: string) => {
      if (type?.includes('FIXED PRICE')) {
        return priceRanges[0].price && !Number.isNaN(Number(priceRanges[0].price));
      } else if (type?.includes('PRICE RANGES')) {
        const areValuesPresent =
          priceRanges
            .map(range => {
              if (range.to.includes('Unlimited')) {
                return range.price !== '' && !Number.isNaN(Number(range.price));
              } else {
                return (
                  range.from !== '' &&
                  range.to !== '' &&
                  range.price !== '' &&
                  !Number.isNaN(Number(range.price)) &&
                  !Number.isNaN(Number(range.to))
                );
              }
            })
            .filter(valid => !valid).length === 0;

        const arePricesValid = (values: Array<string>) =>
          values
            .map(value => Number(value))
            .reduce((previous, current) => {
              if (previous <= current) {
                return 0;
              }
              return current;
            });

        const areFromToValuesValid = (ranges: Array<RowData>) =>
          ranges
            .map(
              range =>
                range.to.includes('Unlimited') ||
                (!Number.isNaN(Number(range.to)) && Number(range.from) < Number(range.to))
            )
            .filter(valid => !valid).length === 0;

        return (
          areValuesPresent && arePricesValid(priceRanges.map(range => range.price)) && areFromToValuesValid(priceRanges)
        );
      } else {
        return true;
      }
    };

    return (
      (watch(perPccepr) || watch(perTransaction)) && //disable button if none of pricing types is picked
      (!watch(perPccepr) || //if pricing per pcc epr is picked validate below fields
        (watch(perPcceprType) &&
          isAdditionalInfoPresentIfRequired(false, watch(perPcceprType)) &&
          pricingLevel &&
          watch(rowsListPccepr) &&
          isPriceRangesValid(watch(rowsListPccepr), watch(perPcceprType)) &&
          watch(selectedFeeRecurrenceName))) &&
      (!watch(perTransaction) || //if pricing per transaction is picked validate below fields
        (watch(perTransactionType) &&
          isAdditionalInfoPresentIfRequired(true, watch(perTransactionType)) &&
          isPriceRangesValid(watch(rowsListTransaction), watch(perTransactionType))))
    );
  };

  return (
    <PricingContext.Provider value={{control, watch, setValue, deletePricingHandler}}>
      {isLoading ? (
        loadingContent()
      ) : productPricing !== undefined ? (
        <PricingView pricing={productPricing} />
      ) : (
        <PricingContainer>
          <PricingButtons>
            <Button type="button" size={ButtonSize.SMALL} onClick={submitHandler} disabled={!isValid()}>
              <FormattedMessage id="tabs.pricing.submitButton" />
            </Button>
          </PricingButtons>
          <p className="spark-mar-l-2 spark-bold">
            <FormattedMessage id="tabs.pricing.perUser.title" />
          </p>
          <Checkbox
            className="spark-mar-t-1 spark-mar-l-2"
            label={formatMessage({
              id: 'tabs.pricing.perUser.description',
            })}
            onChange={() => {
              setValue(perPccepr, !watch(perPccepr), changeValueOptions);
              if (watch(perPcceprType) !== undefined) {
                setValue(perPcceprType, undefined, changeValueOptions);
              }
              pricingLevel
                ? setValue(pricingLevelName, undefined, changeValueOptions)
                : setValue(pricingLevelName, PricingLevel.pcc, changeValueOptions);
            }}
          />
          <Controller
            name={pricingLevelName}
            control={control}
            render={({field: {onChange, name, value}}): ReactElement => (
              <RadioButtonGroup
                name={name}
                value={value ?? ''}
                onChange={onChange}
                className="row spark-mar-l-2 spark-mar-t-2"
              >
                <FormattedMessage id="tabs.pricing.level.label" />
                {watch(perPccepr) && <Star hasColon marginTop={starMarginTop} />}
                {Object.values(PricingLevel).map(type => (
                  <RadioButton
                    className="row spark-mar-l-2 col-xs-2"
                    key={type}
                    label={type}
                    value={type}
                    onClick={() => {
                      if (type !== pricingLevel) {
                        setValue(selectedFeeRecurrenceName, '');
                      }
                    }}
                    disabled={!watch(perPccepr)}
                  />
                ))}
              </RadioButtonGroup>
            )}
          />
          <SelectInput
            className="spark-mar-l-2 col-xs-5"
            name={selectedFeeRecurrenceName}
            label={formatMessage({
              id: 'tabs.pricing.level.fee',
            })}
            options={getFeeTypeOptions()}
            onChange={(_, value): void => setValue(selectedFeeRecurrenceName, value, changeValueOptions)}
            value={watch(selectedFeeRecurrenceName)}
            ariaLabel={formatMessage({
              id: 'tabs.pricing.level.fee.ariaLabel',
            })}
            disabled={!watch(perPccepr)}
          />
          <PriceDefinition isChecked={watch(perPccepr)} pricingType="perPccepr" />
          <p className="spark-mar-l-2 spark-bold spark-mar-t-2">
            <FormattedMessage id="tabs.pricing.perTransaction.title" />
          </p>
          <Checkbox
            className="spark-mar-t-1 spark-mar-l-2"
            label={formatMessage({
              id: 'tabs.pricing.perTransaction.description',
            })}
            onChange={() => {
              setValue(perTransaction, !watch(perTransaction), changeValueOptions);
              if (watch(perTransactionType) !== undefined) {
                setValue(perTransactionType, undefined, changeValueOptions);
              }
            }}
          />
          <PriceDefinition isChecked={watch(perTransaction)} pricingType="perTransaction" />
          <p className="row spark-mar-l-2 spark-mar-t-2 spark-bold">
            <FormattedMessage id="tabs.price.additionalInfo.title" />
          </p>
          <p className="spark-mar-l-2 col-xs-11">
            <FormattedMessage id="tabs.price.additionalInfo.description" />
          </p>
          <TextInput
            className="spark-mar-b-2 spark-mar-l-2 spark-mar-r-2"
            name={additionalInfoName}
            characterCount
            maxLength={additionalInfoMaxLength}
            placeHolder={formatMessage({
              id: 'tabs.price.additionalInfo.textInput.placeholder',
            })}
            label={additionalInfoLabel}
            value={watch(additionalInfoName)}
            onChange={(_, value) => {
              setValue(additionalInfoName, value, changeValueOptions);
            }}
            status={errors && errors[additionalInfoName as keyof typeof errors] ? MessageStatus.ERROR : undefined}
            statusMessage={
              errors &&
              errors[additionalInfoName as keyof typeof errors] &&
              (errors[additionalInfoName as keyof typeof errors] as {message: string})?.message
            }
          />
          <p className="spark-mar-l-2 spark-bold">
            <FormattedMessage id="tabs.specialPricing.title" />
          </p>
          <p className="spark-mar-l-2 spark-mar-b-2 col-xs-11">
            <FormattedMessage id="tabs.specialPricing.description" />
          </p>
        </PricingContainer>
      )}
    </PricingContext.Provider>
  );
};

export const checkIfVersionReadyForPublishing = async (request: GetMissingItemsForPublishingRequest) =>
  new VersionsApi(
    new CertificationConfiguration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).getMissingItemsForPublishing(request);

export const PricingContainer = styled.div`
  position: relative;
`;

export const PricingButtons = styled.div`
  position: absolute;
  right: 5rem;
  top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export default Pricing;
