import React, {Key, MouseEventHandler, useEffect, useRef} from 'react';
import '@sabre/spark/js/src/components/menu';
import SparkPanelMenu from '@sabre/spark/js/src/components/menu';
import styled from 'styled-components';
import {EnumType} from 'typescript';

export const PanelMenu = ({
  labels,
  onClick,
  isAllowedToViewLimitation,
  isRegularApp,
}: {
  labels: Array<string | EnumType>;
  onClick: MouseEventHandler<HTMLAnchorElement>;
  isAllowedToViewLimitation: boolean;
  isRegularApp: boolean;
}) => {
  const panelMenuRef = useRef(null);

  useEffect(() => {
    const sparkPanelMenu = new SparkPanelMenu(panelMenuRef.current);
    return () => {
      sparkPanelMenu.remove();
    };
  }, [panelMenuRef]);

  const buildPanelMenuWithPermissions = (label: string) => {
    return label === 'SC Code & EPR Limitation' ? isAllowedToViewLimitation : true;
  };

  const createList = () =>
    labels.map((label, index) => (
      <li key={label as Key} className={`spark-menu__list-item ${!index && 'active'}`}>
        {buildPanelMenuWithPermissions(label as string) && (
          <span
            className="spark-menu__list-link"
            onClick={onClick}
            tabIndex={0}
            role="button"
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                onClick(evt as unknown as React.MouseEvent<HTMLAnchorElement, MouseEvent>);
              }
            }}
            data-testid={'panelMenuItem' + label}
          >
            {label}
          </span>
        )}
      </li>
    ));

  return (
    <PanelMenuContainer
      className="spark-menu spark-menu--panel"
      aria-label="Product Page Navigation"
      ref={panelMenuRef}
    >
      <ul className="spark-menu__list">{createList()}</ul>
    </PanelMenuContainer>
  );
};

export const PanelMenuContainer = styled.nav`
  border: none;

  & span:hover {
    cursor: pointer;
  }
`;
