import React from 'react';
import {Maybe, Product} from 'generated/graphql';
import ProductMobileItem from 'pages/partnerDetails/products/ProductMobileItem';
import styled from 'styled-components';
import ProductsHeader from 'pages/partnerDetails/products/ProductsHeader';

const ProductsMobile = ({products, name}: {products: Maybe<Array<Maybe<Product>>>; name?: Maybe<string>}) => {
  const productTiles = products?.map((product, index) => <ProductMobileItem key={index} product={product!} />);

  return (
    <Products>
      <ProductsHeader partnerName={name!} />
      {productTiles}
    </Products>
  );
};

const Products = styled.div.attrs(() => ({
  className: 'spark-flex',
}))`
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
`;

export default ProductsMobile;
