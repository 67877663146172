import styled from 'styled-components';
import {FormattedMessage, useIntl} from 'react-intl';
import React from 'react';
import Star from '@scm/partner-network/src/components/Star';
import colors from '@scm/components/assets/colors';
import {UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {Button, Message, RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {OnboardingProviderType, onboardingProviderTypeName, ProviderTypeValue} from './JoinUsContentBox';
import {ButtonSize, MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import parse from 'html-react-parser';
import {Text as TextLabel} from '@scm/proposal/pages/proposalForm/steps/reviewPage/ReviewPage';
import OnboardingInfo from '../../components/onboardingInfo/OnboardingInfo';
import Legend from '@scm/components/accessibility/Legend';

const ProviderType = ({
  watch,
  setValue,
  setCurrentStep,
}: {
  watch: UseFormWatch<ProviderTypeValue>;
  setValue: UseFormSetValue<ProviderTypeValue>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const {formatMessage} = useIntl();

  const createId = (firstId: string, secondId: string) =>
    watch(onboardingProviderTypeName) === OnboardingProviderType['Sabre Developer Partner'] ? firstId : secondId;
  const isRedAppProvider =
    watch(onboardingProviderTypeName) === OnboardingProviderType['Sabre Red App Certified Provider'];

  return (
    <>
      <TextLabel className="spark-mar-t-2">{parse(formatMessage({id: 'providerType.introduction'}))}</TextLabel>
      <div className="spark-panel spark-mar-t-1">
        <div className="spark-panel__content">
          <div className="row">
            <div className="col-xs-5">
              <StyledH2>
                <FormattedMessage id="providerType.title" />
              </StyledH2>
              <Text>
                <FormattedMessage id="providerType.subTitle" />
                <Star starColor={colors.red} />
              </Text>
              <RadioButtonGroup
                name={onboardingProviderTypeName}
                value={watch(onboardingProviderTypeName) || ''}
                onChange={(_, value) => setValue(onboardingProviderTypeName, value as OnboardingProviderType)}
              >
                <Legend text={formatMessage({id: 'providerType.title'})} />
                {Object.values(OnboardingProviderType).map(value => (
                  <RadioButton key={value} label={value} value={value} />
                ))}
              </RadioButtonGroup>
            </div>
            <div className="col-xs-7">
              {watch(onboardingProviderTypeName) && (
                <>
                  <Message
                    content=""
                    role={MessageRole.STATUS}
                    status={MessageStatus.INFO}
                    title={watch(onboardingProviderTypeName)}
                    type={MessageType.CONTAINER}
                  />
                  <p className="spark-mar-t-2">
                    <FormattedMessage
                      id={createId('providerType.sdp.description', 'providerType.certifyProvider.description')}
                    />
                  </p>
                  <p className="spark-mar-t-2">
                    <strong>
                      <FormattedMessage
                        id={createId('providerType.targetUsers.sdp', 'providerType.targetUsers.certifyProvider')}
                      />
                    </strong>
                    <FormattedMessage id={createId('providerType.sdp.target', 'providerType.certifyProvider.target')} />
                  </p>
                  <p className="spark-mar-t-2">
                    <strong>
                      <FormattedMessage id="providerType.benefits" />
                    </strong>
                    <FormattedMessage
                      id={createId('providerType.sdp.benefits', 'providerType.certifyProvider.benefits')}
                    />
                  </p>
                </>
              )}
            </div>
          </div>
          <nav className="spark-btn-group spark-btn-group spark-mar-t-2">
            {isRedAppProvider && <OnboardingInfo />}
            <Button
              onClick={() => setCurrentStep(prevState => prevState + 1)}
              disabled={!watch(onboardingProviderTypeName)}
              size={ButtonSize.SMALL}
              color={colors.highlightGrey}
            >
              <FormattedMessage id="joinUsPage.form.button" />
            </Button>
          </nav>
        </div>
      </div>
    </>
  );
};

const StyledH2 = styled.h2`
  font-size: 2.08rem;
  font-weight: 400;
  line-height: 3rem;
`;

const Text = styled.p`
  color: ${colors.grey700};
  font-weight: bold;
  font-size: 1.1rem;

  &[data-margin-sm] {
    margin-bottom: 1rem;
  }
`;

export default ProviderType;
