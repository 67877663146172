import React, {useContext} from 'react';
import {SellersSelectContext} from '../../../providers/SellersProvider';
import {DevelopersContext} from '../../../providers/DevelopersProvider';
import {ProductsContext} from '../../../providers/ProductsPovider';
import Select, {ControlProps, CSSObjectWithLabel, OptionProps, StylesConfig} from 'react-select';
import {Loading} from '@scm/components';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const customStyles: StylesConfig = {
  control: (baseStyles: CSSObjectWithLabel, state: ControlProps) => ({
    ...baseStyles,
    height: '48px',
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
    },
  }),
  option: (baseStyles: CSSObjectWithLabel, state: OptionProps) => ({
    ...baseStyles,
    backgroundColor:
      (state.isSelected && `${colors.grey500}`) || (state.isFocused && `${colors.grey200}`) || `${colors.transparent}`,

    '&:hover': {
      backgroundColor: `${colors.grey200}`,
    },
  }),
};

const Seller = () => {
  const {sellers, setCurrentSeller, currentSeller, sellersLoaded} = useContext(SellersSelectContext);
  const {loading} = useContext(DevelopersContext);
  const {loading: loadingProducts} = useContext(ProductsContext);

  return (
    <SellerWrapper className={`col-xs-12 col-lg-4 spark-pad-b-1 ${sellers.length <= 1 ? 'hidden' : ''}`}>
      {sellersLoaded ? (
        <Select
          defaultValue={currentSeller !== undefined ? {value: currentSeller.name, label: currentSeller.name} : null}
          options={sellers.map(seller => ({value: seller.name, label: seller.name}))}
          onChange={singleValue =>
            setCurrentSeller(sellers.find(seller => seller.name === singleValue.value) || sellers[0])
          }
          isDisabled={!sellersLoaded || loading || loadingProducts}
          isSearchable
          name="Seller Select"
          aria-label="Select seller for developers or products"
          styles={customStyles}
        />
      ) : (
        <Loading sizing="sm" />
      )}
    </SellerWrapper>
  );
};

const SellerWrapper = styled.div`
  position: relative;
  height: 48px;

  &.hidden {
    visibility: hidden;
  }
`;

export default Seller;
