import {object, string} from 'yup';

const minNameLength = 3;
export const maxNameLength = 50;

const minDescriptionMessage = (value: number) => `This field must have at least ${value} characters.`;
const maxDescriptionMessage = (value: number) => `This field can have maximum ${value} characters.`;

export const changeNameSchema = object().shape({
  name: string()
    .min(minNameLength, minDescriptionMessage(minNameLength))
    .max(maxNameLength, maxDescriptionMessage(maxNameLength))
    .required(),
});
