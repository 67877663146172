/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CcrzEProductCSObject } from './CcrzEProductCSObject';
import {
    CcrzEProductCSObjectFromJSON,
    CcrzEProductCSObjectFromJSONTyped,
    CcrzEProductCSObjectToJSON,
} from './CcrzEProductCSObject';
import type { CcrzEProductCategoryCSObject } from './CcrzEProductCategoryCSObject';
import {
    CcrzEProductCategoryCSObjectFromJSON,
    CcrzEProductCategoryCSObjectFromJSONTyped,
    CcrzEProductCategoryCSObjectToJSON,
} from './CcrzEProductCategoryCSObject';
import type { CcrzEProductMediaCSObject } from './CcrzEProductMediaCSObject';
import {
    CcrzEProductMediaCSObjectFromJSON,
    CcrzEProductMediaCSObjectFromJSONTyped,
    CcrzEProductMediaCSObjectToJSON,
} from './CcrzEProductMediaCSObject';
import type { CcrzEProductTabCSObject } from './CcrzEProductTabCSObject';
import {
    CcrzEProductTabCSObjectFromJSON,
    CcrzEProductTabCSObjectFromJSONTyped,
    CcrzEProductTabCSObjectToJSON,
} from './CcrzEProductTabCSObject';
import type { CcrzETermCSObject } from './CcrzETermCSObject';
import {
    CcrzETermCSObjectFromJSON,
    CcrzETermCSObjectFromJSONTyped,
    CcrzETermCSObjectToJSON,
} from './CcrzETermCSObject';
import type { RecordType } from './RecordType';
import {
    RecordTypeFromJSON,
    RecordTypeFromJSONTyped,
    RecordTypeToJSON,
} from './RecordType';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {CcrzEProductCSObject}
     * @memberof Product
     */
    ccrzEProductC?: CcrzEProductCSObject;
    /**
     * 
     * @type {Array<CcrzEProductCategoryCSObject>}
     * @memberof Product
     */
    ccrzEProductCategoryC?: Array<CcrzEProductCategoryCSObject>;
    /**
     * 
     * @type {Array<CcrzEProductTabCSObject>}
     * @memberof Product
     */
    ccrzEProductTabC?: Array<CcrzEProductTabCSObject>;
    /**
     * 
     * @type {Array<CcrzEProductMediaCSObject>}
     * @memberof Product
     */
    ccrzEProductMediaC?: Array<CcrzEProductMediaCSObject>;
    /**
     * 
     * @type {CcrzETermCSObject}
     * @memberof Product
     */
    ccrzETermC?: CcrzETermCSObject;
    /**
     * 
     * @type {RecordType}
     * @memberof Product
     */
    recordType?: RecordType;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ccrzEProductC': !exists(json, 'ccrz__E_Product__c') ? undefined : CcrzEProductCSObjectFromJSON(json['ccrz__E_Product__c']),
        'ccrzEProductCategoryC': !exists(json, 'ccrz__E_ProductCategory__c') ? undefined : ((json['ccrz__E_ProductCategory__c'] as Array<any>).map(CcrzEProductCategoryCSObjectFromJSON)),
        'ccrzEProductTabC': !exists(json, 'ccrz__E_ProductTab__c') ? undefined : ((json['ccrz__E_ProductTab__c'] as Array<any>).map(CcrzEProductTabCSObjectFromJSON)),
        'ccrzEProductMediaC': !exists(json, 'ccrz__E_ProductMedia__c') ? undefined : ((json['ccrz__E_ProductMedia__c'] as Array<any>).map(CcrzEProductMediaCSObjectFromJSON)),
        'ccrzETermC': !exists(json, 'ccrz__E_Term__c') ? undefined : CcrzETermCSObjectFromJSON(json['ccrz__E_Term__c']),
        'recordType': !exists(json, 'recordType') ? undefined : RecordTypeFromJSON(json['recordType']),
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ccrz__E_Product__c': CcrzEProductCSObjectToJSON(value.ccrzEProductC),
        'ccrz__E_ProductCategory__c': value.ccrzEProductCategoryC === undefined ? undefined : ((value.ccrzEProductCategoryC as Array<any>).map(CcrzEProductCategoryCSObjectToJSON)),
        'ccrz__E_ProductTab__c': value.ccrzEProductTabC === undefined ? undefined : ((value.ccrzEProductTabC as Array<any>).map(CcrzEProductTabCSObjectToJSON)),
        'ccrz__E_ProductMedia__c': value.ccrzEProductMediaC === undefined ? undefined : ((value.ccrzEProductMediaC as Array<any>).map(CcrzEProductMediaCSObjectToJSON)),
        'ccrz__E_Term__c': CcrzETermCSObjectToJSON(value.ccrzETermC),
        'recordType': RecordTypeToJSON(value.recordType),
    };
}

