const pricing = {
  'pricing.title': 'Pricing',
  'pricing.additionalStatement': 'You should determine the exact pricing of your Red App before publishing it.',
  'pricing.isPaid.label': 'My Red App is chargeable (paid)',
  'pricing.isPaid.description.label':
    "Please provide the details what will be the payment model of this Red App (one-time fee or monthly; negotiable, fixed or with pricing ranges; per PCC or per EPR). You don't have to provide specific values <star>*</star>",
  'pricing.details.label': 'Comments',
  'pricing.details.placeholder': 'Put your comments here.',
};

export default pricing;
