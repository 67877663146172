/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Pii
 */
export interface Pii {
    /**
     * 
     * @type {Array<string>}
     * @memberof Pii
     */
    data?: Array<PiiDataEnum>;
    /**
     * 
     * @type {string}
     * @memberof Pii
     */
    howIsForwarded?: string;
    /**
     * 
     * @type {string}
     * @memberof Pii
     */
    euNationals?: string;
    /**
     * 
     * @type {string}
     * @memberof Pii
     */
    complianceWithGdpr?: string;
    /**
     * 
     * @type {string}
     * @memberof Pii
     */
    retentionPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof Pii
     */
    usedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Pii
     */
    sharedWithThirdParty?: string;
}


/**
 * @export
 */
export const PiiDataEnum = {
    SocialSecurityNumber: 'Social Security Number',
    DriversLicenseNumber: 'Driver’s License Number',
    PassportNumber: 'Passport Number',
    DateOfBirth: 'Date of Birth',
    AddressPhoneNumberCountryOrNationalIdentificationEtc: 'Address, phone number, country, or National Identification, etc'
} as const;
export type PiiDataEnum = typeof PiiDataEnum[keyof typeof PiiDataEnum];


/**
 * Check if a given object implements the Pii interface.
 */
export function instanceOfPii(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PiiFromJSON(json: any): Pii {
    return PiiFromJSONTyped(json, false);
}

export function PiiFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pii {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : json['data'],
        'howIsForwarded': !exists(json, 'howIsForwarded') ? undefined : json['howIsForwarded'],
        'euNationals': !exists(json, 'euNationals') ? undefined : json['euNationals'],
        'complianceWithGdpr': !exists(json, 'complianceWithGdpr') ? undefined : json['complianceWithGdpr'],
        'retentionPeriod': !exists(json, 'retentionPeriod') ? undefined : json['retentionPeriod'],
        'usedBy': !exists(json, 'usedBy') ? undefined : json['usedBy'],
        'sharedWithThirdParty': !exists(json, 'sharedWithThirdParty') ? undefined : json['sharedWithThirdParty'],
    };
}

export function PiiToJSON(value?: Pii | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'howIsForwarded': value.howIsForwarded,
        'euNationals': value.euNationals,
        'complianceWithGdpr': value.complianceWithGdpr,
        'retentionPeriod': value.retentionPeriod,
        'usedBy': value.usedBy,
        'sharedWithThirdParty': value.sharedWithThirdParty,
    };
}

