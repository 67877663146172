import React from 'react';
import {Accordion, Drawer} from '@sabre/spark-react-core';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {useIntl} from 'react-intl';
import {UseFormReturn} from 'react-hook-form';
import {comparisonResponseName, SvsValues, validationResponseName} from '../SvsValues';

const ResponseData = ({formMethods}: {formMethods: UseFormReturn<SvsValues>}) => {
  const {getValues} = formMethods;
  const {formatMessage} = useIntl();

  return (
    <Response>
      <Accordion singleExpand>
        <Drawer title={formatMessage({id: 'svs.validationResponse.title'})} headingLevel="h2">
          <pre>{JSON.stringify(getValues(validationResponseName), null, 4)}</pre>
        </Drawer>
        <Drawer title={formatMessage({id: 'svs.comparisonResponse.title'})} headingLevel="h2">
          <pre>{JSON.stringify(getValues(comparisonResponseName), null, 4)}</pre>
        </Drawer>
      </Accordion>
    </Response>
  );
};

const textStyles = `
    color: ${colors.grey900};
    font-weight: bold;
`;

const Response = styled.div`
  padding: 0 1rem 1rem 2rem;
  .spark-accordion__drawer {
    border: none;
    border-bottom: 1px solid ${colors.grey150};
  }
  .spark-accordion__header {
    padding: 1rem 1rem 1rem 0;
    ${textStyles}
  }

  h4 {
    ${textStyles}
  }
`;

export default ResponseData;
