import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import Headers from './Headers';
import {useContext, useState} from 'react';
import {CertBetaTestersContext} from '../../provider/CertBetaTestersProvider';
import {ProdBetaTestersContext} from '../../provider/ProdBetaTestersProvider';
import Rows from './Rows';

type Direction = 'Asc' | 'Desc';

export interface SortedColumn {
  name: string;
  direction?: Direction;
}

const TestersTable = ({
  headers,
  sortableHeaders,
  isProdTestersTable,
  currentPaginationPage,
  paginationInterval,
  deleteTestersHandler,
}: {
  headers: string[];
  sortableHeaders: string[];
  isProdTestersTable: boolean;
  currentPaginationPage?: number;
  paginationInterval?: number;
  deleteTestersHandler: (nr: string) => void;
}) => {
  const getSortedColumnsInitialState = (): Array<SortedColumn> =>
    sortableHeaders.map(sortableHeader => ({
      name: sortableHeader,
      direction: 'Desc',
    }));

  const [sortedColumns, setSortedColumns] = useState<SortedColumn[]>(getSortedColumnsInitialState());

  const changeSorting = (name: string) => {
    if (!sortedColumns.some(column => column.name === name)) {
      return;
    }

    const newSortedColumns = sortedColumns.map(column => {
      if (column.name !== name) {
        return column;
      }
      return {
        ...column,
        direction: getNewDirection(column.direction),
      };
    });

    setSortedColumns(newSortedColumns);
    sortValues(name);
  };

  const getNewDirection = (currentDirection?: Direction): Direction => (currentDirection === 'Desc' ? 'Asc' : 'Desc');

  const {certBetaTesters} = useContext(CertBetaTestersContext);
  const {prodBetaTesters} = useContext(ProdBetaTestersContext);

  const [sortedTesters, setSortedTesters] = useState(isProdTestersTable ? [...prodBetaTesters] : [...certBetaTesters]);

  const sortValues = (byField: string) => {
    let testers = isProdTestersTable ? [...prodBetaTesters] : [...certBetaTesters];
    if (byField === 'pcc') {
      testers.sort((a, b) => (a.pcc || '').localeCompare(b.pcc || ''));
    } else if (byField === 'created') {
      testers.sort((a, b) => +(a.creationDateTime || new Date()) - +(b.creationDateTime || new Date()));
    }

    if (sortedColumns.filter(column => column.name === byField)[0].direction === 'Desc') {
      testers.reverse();
    }

    setSortedTesters(testers);
  };

  return (
    <Table className="spark-table">
      <div className="spark-table__scroll">
        <table role="grid" className="spark-mar-b-1">
          <Headers headers={headers} sortedColumns={sortedColumns} changeSorting={changeSorting} />
          <Rows
            sortedTesters={sortedTesters}
            isProdTestersTable={isProdTestersTable}
            currentPaginationPage={currentPaginationPage || 0}
            paginationInterval={paginationInterval || 0}
            deleteTestersHandler={deleteTestersHandler}
          />
        </table>
      </div>
    </Table>
  );
};

const Table = styled.section`
  border-top: 2px solid ${colors.grey300};
  font-size: 1rem;
`;

export default TestersTable;
