export enum deviceWidths {
  xs = 543,
  sm = 795,
  md = 1047,
  lg = 1600,
}

export const maxMobileWidthQuery = {
  query: `(max-device-width: ${deviceWidths.sm}px)`,
};

export const maxTabletWidthQuery = {
  query: `(max-width: ${deviceWidths.md}px)`,
};
