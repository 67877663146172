import {Badge, Button, Message} from '@sabre/spark-react-core';
import {BadgeStatus, ButtonSize, MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  notificationBodyName,
  NotificationTemplateValues,
  recipientsBccName,
  recipientsName,
  subjectName,
} from './schema/NotificationTemplateValues';
import {UseFormWatch} from 'react-hook-form';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import React from 'react';
import {Separator} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';

const NotificationPreviewModal = ({
  watch,
  handleClose,
}: {
  watch: UseFormWatch<NotificationTemplateValues>;
  handleClose: () => void;
}) => {
  const {formatMessage} = useIntl();

  const generateBadge = (recipients: string) => {
    const recipientsList = recipients
      .trim()
      .split(',')
      .filter(item => item !== '');
    return recipientsList.map((recipient, index) => (
      <Badge
        key={index}
        className="spark-mar-b-1 spark-mar-r-.5"
        label={recipient}
        status={BadgeStatus.NEUTRAL}
        small
      />
    ));
  };

  return (
    <>
      <Message
        content={formatMessage({id: 'manageNotifications.details.modal.warning.content'})}
        role={MessageRole.ALERT}
        status={MessageStatus.WARNING}
        title={formatMessage({id: 'manageNotifications.details.modal.warning.title'})}
        type={MessageType.PAGE}
        titleHeadingLevel={2}
      />
      <EmailTemplateContainer className="spark-pad-1">
        <div className="spark-bold spark-mar-b-1">{watch(subjectName)}</div>
        <div>
          <div>
            <span className="spark-mar-r-.5">
              <FormattedMessage id="manageNotification.details.modal.content.to" />
            </span>
            {generateBadge(watch(recipientsName))}
          </div>
          <div>
            <span className="spark-mar-r-.5">
              <FormattedMessage id="manageNotification.details.modal.content.bcc" />
            </span>
            {generateBadge(watch(recipientsBccName))}
          </div>
        </div>
        <Separator className="spark-bold spark-large" />
        <div dangerouslySetInnerHTML={{__html: watch(notificationBodyName)}} />
      </EmailTemplateContainer>
      <nav className="spark-btn-group spark-mar-t-2">
        <Button onClick={handleClose} className="spark-btn-group-secondary" size={ButtonSize.SMALL} secondary>
          <FormattedMessage id="common.button.close" />
        </Button>
      </nav>
    </>
  );
};

const EmailTemplateContainer = styled.div`
  border: 1px solid ${colors.grey150};
`;

export default NotificationPreviewModal;
