/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductInfo } from './ProductInfo';
import {
    ProductInfoFromJSON,
    ProductInfoFromJSONTyped,
    ProductInfoToJSON,
} from './ProductInfo';
import type { ProvisioningType } from './ProvisioningType';
import {
    ProvisioningTypeFromJSON,
    ProvisioningTypeFromJSONTyped,
    ProvisioningTypeToJSON,
} from './ProvisioningType';

/**
 * 
 * @export
 * @interface Proposal
 */
export interface Proposal {
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Proposal
     */
    sellerId?: string;
    /**
     * 
     * @type {ProvisioningType}
     * @memberof Proposal
     */
    provisioningType: ProvisioningType;
    /**
     * 
     * @type {ProductInfo}
     * @memberof Proposal
     */
    productInfo: ProductInfo;
    /**
     * 
     * @type {number}
     * @memberof Proposal
     */
    proposalStep?: number;
}

/**
 * Check if a given object implements the Proposal interface.
 */
export function instanceOfProposal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "provisioningType" in value;
    isInstance = isInstance && "productInfo" in value;

    return isInstance;
}

export function ProposalFromJSON(json: any): Proposal {
    return ProposalFromJSONTyped(json, false);
}

export function ProposalFromJSONTyped(json: any, ignoreDiscriminator: boolean): Proposal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'sellerId': !exists(json, 'sellerId') ? undefined : json['sellerId'],
        'provisioningType': ProvisioningTypeFromJSON(json['provisioningType']),
        'productInfo': ProductInfoFromJSON(json['productInfo']),
        'proposalStep': !exists(json, 'proposalStep') ? undefined : json['proposalStep'],
    };
}

export function ProposalToJSON(value?: Proposal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'sellerId': value.sellerId,
        'provisioningType': ProvisioningTypeToJSON(value.provisioningType),
        'productInfo': ProductInfoToJSON(value.productInfo),
        'proposalStep': value.proposalStep,
    };
}

