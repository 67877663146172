import React, {AnchorHTMLAttributes, MouseEventHandler, ReactNode} from 'react';

export const ExternalLink = ({
  to,
  download,
  title,
  className,
  ariaLabel,
  children,
  onClick,
}: {
  to: string;
  download?: string;
  title?: string;
  className?: string;
  ariaLabel?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}) => (
  <a
    href={to}
    download={download}
    className={className}
    aria-label={`link to ${ariaLabel}`}
    title={title}
    target="_blank"
    role="link"
    rel="noopener noreferrer"
    onClick={onClick}
  >
    {children}
  </a>
);
