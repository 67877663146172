import React, {useContext, useState} from 'react';
import {Button, Icon} from '@sabre/spark-react-core';
import {FieldValues, SubmitHandler, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {format} from 'date-fns';
import {dateFormat, time} from '@scm/components/utils/common';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {
  DeleteTesterHandlerType,
  Tester,
} from '@scm/product-components/pages/productDetails/productTabs/testers/ProdTesters';
import {ProdTestersContext} from './ProdTesters';
import {TableCell} from '@scm/product-components/pages/productDetails/productTabs/bundles/BundleRow';
import {editedTester, isTesterAdding, testersTable, TestersValues} from './TestersValues';
import {
  EPR_MAX_LENGTH,
  EPR_MIN_LENGTH,
  EPR_REGEX,
  PCC_MAX_LENGTH,
  PCC_MIN_LENGTH,
  PCC_REGEX,
} from '@scm/components/pccEpr/pccEprSchema';
import {TesterTextInput} from '@scm/product-components/pages/productDetails/productTabs/testers/CertTesterRow';
import styled from 'styled-components';

enum FormButton {
  Cancel = 'Cancel',
  Add = 'Add',
}

const TesterTableRow = ({
  tester,
  deleteTestersHandler,
  isEditedRow,
}: {
  tester: Tester;
  deleteTestersHandler?: DeleteTesterHandlerType;
  isEditedRow?: boolean;
}) => {
  const {watchTesterForm, setValueTesterForm, handleSubmitTesterForm} = useContext(ProdTestersContext);
  const [isPccValid, setIsPccValid] = useState(false);
  const [isPccTouched, setIsPccTouched] = useState(false);
  const [isEprValid, setIsEprValid] = useState(false);
  const [isEprTouched, setIsEprTouched] = useState(false);

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldName = evt.target.name;
    (setValueTesterForm as UseFormSetValue<FieldValues>)(editedTester, {
      ...(watchTesterForm as UseFormWatch<FieldValues>)(editedTester),
      [fieldName]: evt.target.value.toUpperCase(),
    });

    if (fieldName === 'pcc' && !isPccTouched) {
      setIsPccTouched(true);
    }

    if (fieldName === 'sabreId' && !isEprTouched) {
      setIsEprTouched(true);
    }
  };

  const testerExists = (data: TestersValues) =>
    data.testersTable.some(
      (tester: Tester) => tester.pcc === data.editedTester?.pcc && tester.sabreId === data.editedTester.sabreId
    );

  const onSubmit: SubmitHandler<FieldValues> = () => {
    setValueTesterForm(isTesterAdding, false);
    setValueTesterForm(testersTable, [
      ...watchTesterForm(testersTable),
      {
        created: watchTesterForm(editedTester)?.created
          ? format(new Date((watchTesterForm(editedTester) as Tester).created), dateFormat) + time
          : new Date(),
        nr: watchTesterForm(editedTester)?.nr || '',
        pcc: watchTesterForm(editedTester)?.pcc || '',
        sabreId: watchTesterForm(editedTester)?.sabreId || '',
      },
    ]);
    setValueTesterForm(editedTester, null);
  };

  const cancelHandler = () => {
    setValueTesterForm(isTesterAdding, false);
    setValueTesterForm(editedTester, null);
  };

  return (
    <tr>
      {isEditedRow ? (
        <>
          <TableCell>
            <TesterTextInput
              value={tester.pcc}
              name="pcc"
              maxLength={PCC_MAX_LENGTH}
              minLength={PCC_MIN_LENGTH}
              onChange={e => changeHandler(e)}
              onValidate={setIsPccValid}
              validatePattern={PCC_REGEX.source}
              validateOnChange
              $isInputValid={!isPccValid && isPccTouched}
            />
          </TableCell>
          <TableCell>
            <TesterTextInput
              value={tester.sabreId}
              name="sabreId"
              maxLength={EPR_MAX_LENGTH}
              minLength={EPR_MIN_LENGTH}
              onChange={e => changeHandler(e)}
              onValidate={setIsEprValid}
              validatePattern={EPR_REGEX.source}
              validateOnChange
              $isInputValid={!isEprValid && isEprTouched}
            />
          </TableCell>
          <TableCell className="spark-table__actions spark-text-right">
            <Button secondary size={ButtonSize.EXTRA_SMALL} className="spark-mar-r-1" onClick={cancelHandler}>
              {FormButton.Cancel}
            </Button>
            <Button
              disabled={testerExists(watchTesterForm()) || !isPccValid || !isEprValid}
              size={ButtonSize.EXTRA_SMALL}
              type="button"
              onClick={handleSubmitTesterForm(onSubmit)}
            >
              {FormButton.Add}
            </Button>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell isMiddleColumn>{tester.pcc}</TableCell>
          <TableCell isMiddleColumn>{tester.sabreId}</TableCell>
          <TableCell className="spark-table__actions spark-text-right">
            <IconContainer
              role="button"
              onClick={() => (deleteTestersHandler as DeleteTesterHandlerType)(tester.nr)}
              onKeyDown={evt => {
                if (evt.key === 'Enter') {
                  (deleteTestersHandler as DeleteTesterHandlerType)(tester.nr);
                }
              }}
              tabIndex={0}
            >
              <Icon name="trash" />
            </IconContainer>
          </TableCell>
        </>
      )}
    </tr>
  );
};

const IconContainer = styled.span`
  padding-left: 10.113rem !important;
`;

export default TesterTableRow;
