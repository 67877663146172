import React, {useContext, useEffect, useMemo} from 'react';
import {Page, ProposalContext} from '@scm/proposal/pages/proposalForm/ProposalForm';
import {Message} from '@sabre/spark-react-core';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import {Loading} from '@scm/components';
import {useIntl} from 'react-intl';
import {createPortal} from 'react-dom';
import SummaryContent from '../../components/SummaryContent';

const Summary = () => {
  const {isLoading, isSuccess} = useContext(ProposalContext);
  const {formatMessage} = useIntl();
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  const summaryContent = useMemo(() => {
    if (isLoading) {
      return (
        <LoadingContainer>
          <Loading label={formatMessage({id: 'common.data.loading'})} />
        </LoadingContainer>
      );
    } else if (isSuccess !== undefined) {
      return (
        <>
          <Message
            content={''}
            role={isSuccess ? MessageRole.STATUS : MessageRole.ALERT}
            status={isSuccess ? MessageStatus.SUCCESS : MessageStatus.ERROR}
            title={formatMessage({id: isSuccess ? 'summary.success.title' : 'summary.error.title'})}
            type={MessageType.PAGE}
            titleHeadingLevel={2}
          />
          {domReady
            ? createPortal(
                <SummaryContent isSuccess={isSuccess} />,
                document.querySelector('[test-id="banner-content"]') as HTMLParagraphElement
              )
            : null}
        </>
      );
    }
  }, [domReady]);

  return <Page>{summaryContent}</Page>;
};

export default Summary;
