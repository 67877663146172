import React, {ReactElement} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import styled from 'styled-components';
import {Controller} from 'react-hook-form';
import {DatePicker} from '@scm/components';
import {dateFormat} from '@scm/components/utils/common';
import {
  blockOrderingAndHideProductDateName,
  blockOrderingAndHideProductImmediatelyName,
  RequestSunsetValues,
  sunsetDateAsapName,
  sunsetDateName,
} from './schema/RequestSunsetValues';
import {Control, UseFormWatch} from 'react-hook-form/dist/types/form';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';
import colors from '@scm/components/assets/colors';
import parse from 'html-react-parser';
import Legend from '@scm/components/accessibility/Legend';
import {isTodayOrFutureDate} from './SunsetDate';

const BlockOrderingAndHideProduct = ({
  watch,
  control,
}: {
  watch: UseFormWatch<RequestSunsetValues>;
  control: Control<RequestSunsetValues>;
}) => {
  const {formatMessage} = useIntl();

  const isDateValid = () => {
    const blockAndHideDate = watch(blockOrderingAndHideProductDateName);
    if (watch(blockOrderingAndHideProductImmediatelyName) === Confirmation.No && blockAndHideDate !== '') {
      if (!isTodayOrFutureDate(blockAndHideDate)) {
        return false;
      }
    }

    if (
      watch(blockOrderingAndHideProductImmediatelyName) === Confirmation.No &&
      watch(sunsetDateAsapName) === Confirmation.No
    ) {
      const sunsetDate = watch(sunsetDateName);
      if (blockAndHideDate !== '' && sunsetDate !== '') {
        return sunsetDate >= blockAndHideDate;
      }
    }

    return true;
  };

  return (
    <div className="spark-mar-t-2">
      <SectionLabel className="spark-bold spark-mar-t-1">
        <FormattedMessage id="requestSunset.section1.header" />
      </SectionLabel>
      <Paragraph>
        <FormattedMessage id="requestSunset.section1.label" />
      </Paragraph>
      <Paragraph className="spark-mar-t-1">
        <Controller
          name={blockOrderingAndHideProductImmediatelyName}
          control={control}
          render={({field: {onChange, name, value}}): ReactElement => (
            <RadioButtonGroup className="spark-mar-t-.5" name={name} value={value as string} onChange={onChange}>
              <Legend text={formatMessage({id: 'requestSunset.a11y.blockOrderingDate'})} />
              <RadioButton
                className="col-xs-12"
                label={formatMessage({
                  id: 'requestSunset.immediately.label',
                })}
                value={Confirmation.Yes}
              />
              <RadioButton
                className="col-xs-12"
                label={formatMessage({
                  id: 'requestSunset.date.label',
                })}
                value={Confirmation.No}
              />
            </RadioButtonGroup>
          )}
        />
      </Paragraph>
      {watch(blockOrderingAndHideProductImmediatelyName) === Confirmation.No ? (
        <div>
          <Controller
            name={blockOrderingAndHideProductDateName}
            control={control}
            render={({field: {onChange, value}}): ReactElement => (
              <ValidatedDatePicker
                changeHandler={onChange}
                label={formatMessage({id: 'requestSunset.date.label'})}
                value={value}
                className="spark-pad-0 spark-mar-0 date-picker"
                dateFormat={dateFormat}
                isValid={isDateValid()}
              />
            )}
          />
          {!isDateValid() ? (
            !isTodayOrFutureDate(watch(blockOrderingAndHideProductDateName)) ? (
              <ValidationLabel className="spark-input">
                {parse(formatMessage({id: 'requestSunset.pastDate.warning'}))}
              </ValidationLabel>
            ) : (
              <ValidationLabel className="spark-input">
                {parse(formatMessage({id: 'requestSunset.blockOrdering.warning'}))}
              </ValidationLabel>
            )
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export const SectionLabel = styled.h5`
  font-weight: 400;
  padding-bottom: 0.5rem;
  font-size: 1.45rem;
  margin-bottom: 0;
`;

export const ValidatedDatePicker = styled(DatePicker)<{isValid?: boolean}>`
  & .spark-input__fields {
    border-color: ${({isValid}) => (isValid ? colors.grey200 : colors.red)} !important;
    border-radius: ${({isValid}) => (isValid ? '3px' : '3px 3px 0px 0px')} !important;
  }
`;

export const Paragraph = styled.p`
  word-break: break-all;
  white-space: normal;
  margin-bottom: 0;
`;

export const ValidationLabel = styled.span`
  background-color: ${colors.red};
  color: ${colors.white};
  padding: 1rem;
  width: 60%;
  border-radius: 0 0 3px 3px;
  white-space: normal;
  font-size: 1.33rem;
`;

export default BlockOrderingAndHideProduct;
