import {Message, Tooltip} from '@sabre/spark-react-core';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {StatusesComponentsMapList} from '@scm/admin-centre/src/utils/statuses';
import parse from 'html-react-parser';
import {dateWithHourNoSeconds, redAppSupportEmail} from '@scm/components/utils/common';
import HrefElement from '@scm/components/messaging/HrefElement';
import {CmTask, Configuration, Environment, Status, VersionsApi} from '@scm/product-components/generated/certification';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {format} from 'date-fns';
import styled from 'styled-components';
import {MessageWrapper} from '@scm/ce-proposals/components/onboardingsDetails/header/HeaderMessage';
import colors from '@scm/components/assets/colors';

const fetchCmTasks = async (versionId: string) => {
  return new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
    })
  ).getCmTaskForVersion({id: versionId});
};

const StatusMessage = ({
  latestVersionStatus,
  missingItemsForPublishing,
  productError,
  productStatus,
  versionId,
  showProcessModal,
}: {
  latestVersionStatus: string;
  missingItemsForPublishing?: string[];
  productError?: string;
  productStatus: string;
  versionId: string;
  showProcessModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const {formatMessage} = useIntl();
  const prodTestsPassedMessage =
    missingItemsForPublishing !== undefined && missingItemsForPublishing.length > 0
      ? parse(
          formatMessage(
            {id: 'productDetails.statusMessage.actionRequired.prodTestPassedMissingItems'},
            {items: '<ul>' + missingItemsForPublishing.map(item => '<li>' + item + '</li>').join('') + '</ul>'}
          )
        )
      : formatMessage({id: 'productDetails.statusMessage.actionRequired.prodTestPassed'});
  const [cmTaskCert, setCmTaskCert] = useState<CmTask | undefined>();
  const [cmTaskProd, setCmTaskProd] = useState<CmTask | undefined>();

  useEffect(() => {
    if (latestVersionStatus === Status.BundleValidated || latestVersionStatus === Status.ApprovedForProd) {
      fetchCmTasks(versionId)
        .then(task => (task.environment === Environment.Cert ? setCmTaskCert(task) : setCmTaskProd(task)))
        .catch(function () {
          //do nothing
        });
    }
  }, [versionId, latestVersionStatus]);

  const statusesMessageMap: StatusesComponentsMapList = [
    {
      component: (
        <ObsoleteMessage
          content={formatMessage({id: 'productDetails.statusMessage.redStatus.productObsolete'})}
          role={MessageRole.STATUS}
          status={MessageStatus.ERROR}
          title={formatMessage({id: 'productDetails.statusMessage.redStatus.title.obsolete'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Obsolete'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.actionOnSabre.bundleSubmitted'})}
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'proposalView.message.actionOnSabre.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Bundle Submitted'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.actionRequired.readyForTestProd'})}
          role={MessageRole.ALERT}
          status={MessageStatus.WARNING}
          title={formatMessage({id: 'productDetails.statusMessage.actionRequired.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Ready for Test (PROD)'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.redStatus.testFailed'})}
          role={MessageRole.ALERT}
          status={MessageStatus.ERROR}
          title={formatMessage({id: 'productDetails.statusMessage.redStatus.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['PROD Tests Failed', 'CERT Tests Failed'],
    },
    {
      component: (
        <Message
          content={formatMessage(
            {id: 'productDetails.statusMessage.redStatus.rejectedForProd'},
            {
              email: <HrefElement email={redAppSupportEmail} />,
            }
          )}
          role={MessageRole.ALERT}
          status={MessageStatus.ERROR}
          title={formatMessage({id: 'productDetails.statusMessage.redStatus.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Rejected For PROD'],
    },
    {
      component: (
        <Message
          content={
            cmTaskCert === undefined
              ? formatMessage({id: 'productDetails.statusMessage.actionOnSabre.bundleValidated'})
              : formatMessage(
                  {id: 'productDetails.statusMessage.actionOnSabre.bundleValidated.freeze'},
                  {
                    date: format(cmTaskCert.earliestPossibleDate, dateWithHourNoSeconds),
                  }
                )
          }
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'productDetails.statusMessage.actionOnSabre.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Bundle Validated'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.greenStaticStatus.published'})}
          role={MessageRole.STATUS}
          status={MessageStatus.SUCCESS}
          title={formatMessage({id: 'productDetails.statusMessage.greenStatus.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Published'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.redStatus.validationFailed'})}
          role={MessageRole.ALERT}
          status={MessageStatus.ERROR}
          title={formatMessage({id: 'productDetails.statusMessage.redStatus.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Validation Failed'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.actionRequired.readyForTestCert'})}
          role={MessageRole.ALERT}
          status={MessageStatus.WARNING}
          title={formatMessage({id: 'productDetails.statusMessage.actionRequired.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Ready for Test (CERT)'],
    },
    {
      component: (
        <Message
          content={prodTestsPassedMessage}
          role={MessageRole.ALERT}
          status={MessageStatus.WARNING}
          title={formatMessage({id: 'productDetails.statusMessage.actionRequired.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['PROD Tests Passed'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.actionOnSabre.certTestPassed'})}
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'productDetails.statusMessage.actionOnSabre.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['CERT Tests Passed'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.actionRequired.cancelled'})}
          role={MessageRole.STATUS}
          status={MessageStatus.ERROR}
          title={formatMessage({id: 'productDetails.statusMessage.redStatus.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Cancelled'],
    },
    {
      component: (
        <Message
          content={
            cmTaskProd === undefined
              ? formatMessage({id: 'productDetails.statusMessage.actionOnSabre.approvedForProd'})
              : formatMessage(
                  {id: 'productDetails.statusMessage.actionOnSabre.approvedForProd.freeze'},
                  {
                    date: format(cmTaskProd.earliestPossibleDate, dateWithHourNoSeconds),
                  }
                )
          }
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'productDetails.statusMessage.actionOnSabre.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Approved For PROD'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.actionRequired.rollbackInProgress'})}
          role={MessageRole.STATUS}
          status={MessageStatus.INFO}
          title={formatMessage({id: 'productDetails.statusMessage.actionOnSabre.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Rollback in Progress'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'productDetails.statusMessage.actionRequired.rolledBack'})}
          role={MessageRole.STATUS}
          status={MessageStatus.ERROR}
          title={formatMessage({id: 'productDetails.statusMessage.redStatus.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: ['Rolled Back'],
    },
    {
      component: (
        <Message
          content={formatMessage({id: 'proposal.approved.message.content'})}
          role={MessageRole.ALERT}
          status={MessageStatus.WARNING}
          title={formatMessage({id: 'productDetails.statusMessage.actionRequired.title'})}
          type={MessageType.CONTAINER}
          animate
        />
      ),
      statusesList: [''],
    },
  ];

  const status = latestVersionStatus ?? (productError as string);
  const isObsolete = productStatus === 'Obsolete';

  const statusMessage =
    statusesMessageMap.filter(map => map.statusesList.includes(isObsolete ? 'Obsolete' : status))[0]?.component ?? null;

  return (
    statusMessage &&
    (isObsolete ? (
      <MessageWrapper>{statusMessage}</MessageWrapper>
    ) : (
      <MessageWrapperWithPointer onClick={() => showProcessModal(true)}>
        <Tooltip toggleEl={statusMessage}>
          <FormattedMessage id="productDetails.statusMessage.showCertificationProcess.label" />
        </Tooltip>
      </MessageWrapperWithPointer>
    ))
  );
};

export const MessageWrapperWithPointer = styled(MessageWrapper)`
  cursor: pointer;
`;

export const ObsoleteMessage = styled(Message)`
  & .spark-message__icon {
    background-color: ${colors.statusInfoGrey} !important;
    border: 2px solid ${colors.statusInfoGrey} !important;
  }

  & .spark-message--error {
    color: ${colors.statusInfoGrey} !important;
  }

  & .spark-message__content strong {
    color: ${colors.statusInfoGrey} !important;
  }
`;
export default StatusMessage;
