import useGetSubmit from './useGetSubmit';
import {Controller, useForm} from 'react-hook-form';
import {
  bundleFileName,
  BundleValues,
  initialDescriptionName,
  patchNotesName,
  securityWorksheetName,
  useCaseFileName,
  workflowDiagramName,
} from './bundleValues';
import React, {ReactElement, useContext, useState} from 'react';
import {Button, Icon, Popover, TextInput} from '@sabre/spark-react-core';
import {FormattedMessage, useIntl} from 'react-intl';
import {FileUpload, Star, TextEditor} from '@scm/components';
import {ButtonSize, TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {fileSize32, zipFileType} from '@scm/components/utils/common';
import TextFile from './TextFile';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import color from '@scm/components/assets/colors';
import HtmlToText from 'html-to-text';
import {yupResolver} from '@hookform/resolvers/yup';
import {bundleSchema} from './bundleSchema';
import useGetProductC from '../../../../hooks/useGetProductC';
import {CcrzEProductCSObjectCcrzProductStatusCEnum} from '../../../../generated/product';
import useIfDisable from './useIfDisable';
import {CertificationProductContext} from '../../../../provider/CertificationProductProvider';
import useGetIsPublic from '../../../../hooks/useGetIsPublic';
import {
  maxSimplifiedDescriptionLength,
  patchNotesMaxCharactersLength,
} from '@scm/product-components/schemas/bundleConstants';
import useIsMfaActive from '../../../../hooks/useIsMfaActive';

enum ButtonValues {
  Cancel = 'Cancel',
  Submit = 'Submit',
}

const CreateBundle = ({
  handleClose,
  isFirstBundle,
  latestVersionId,
  latestVersion,
  setLoadingProductDetails,
}: {
  handleClose: () => void;
  setLoadingProductDetails: (value: boolean) => void;
  isFirstBundle?: boolean;
  latestVersionId?: string;
  latestVersion?: string;
}) => {
  const {formatMessage} = useIntl();
  const {descriptionR, ccrzProductStatusC} = useGetProductC();
  const {certificationProduct} = useContext(CertificationProductContext);
  const [shouldUsesCasesBeCopied, setShouldUsesCasesBeCopied] = useState(false);
  const [shouldWorkflowDiagramBeCopied, setShouldWorkflowDiagramBeCopied] = useState(false);
  const [shouldSecurityWorksheetBeCopied, setShouldSecurityWorksheetBeCopied] = useState(false);
  const [patchNotesCharactersCounter, setPatchNotesCharactersCounter] = useState<number>(0);

  const {control, watch, setValue, getValues} = useForm<BundleValues>({
    mode: 'onChange',
    defaultValues: {
      [initialDescriptionName]: isFirstBundle
        ? HtmlToText.htmlToText(descriptionR?.ccrzValueRTC || '').substring(0, maxSimplifiedDescriptionLength)
        : '',
      [bundleFileName]: [],
      [useCaseFileName]: [],
      [securityWorksheetName]: [],
      [workflowDiagramName]: [],
    },
    resolver: yupResolver(bundleSchema),
  });

  const deleteFileHandler = (fileName: string) => {
    setValue(fileName as keyof BundleValues, [], {
      shouldDirty: true,
    });
  };

  const bundleHasPciOrPii = !!certificationProduct.processPiiOrPci;
  const releaseNotesVisible = ccrzProductStatusC === CcrzEProductCSObjectCcrzProductStatusCEnum.Released;

  const isPublic = useGetIsPublic();

  const isDisabled = useIfDisable({
    watch,
    patchNotesCharactersCounter,
    shouldUsesCasesBeCopied,
    isFirstBundle,
    releaseNotesVisible,
    bundleHasPciOrPii,
    shouldSecurityWorksheetBeCopied,
    shouldWorkflowDiagramBeCopied,
    isPublic,
  });

  const onSubmit = useGetSubmit({
    handleClose,
    setLoadingProductDetails,
    shouldUsesCasesBeCopied,
    shouldSecurityWorksheetBeCopied,
    shouldWorkflowDiagramBeCopied,
  });

  const isMfaActive = useIsMfaActive();

  return (
    <FormContainer onSubmit={e => e.preventDefault()}>
      {/* TODO: frontend changes are needed, to discuss with Marcin Ch.*/}
      {/*{!isMfaActive ? (*/}
      {/*  <AlertBox>*/}
      {/*    <AlertMessage error>*/}
      {/*      <Paragraph className="spark-mar-b-0">*/}
      {/*        <i className="spark-icon--fill spark-icon-alert-triangle spark-mar-r-1" />*/}
      {/*        {parse(*/}
      {/*          formatMessage({*/}
      {/*            id: config.envName === 'prod' ? 'newBundle.mfa.message.prod' : 'newBundle.mfa.message.cert',*/}
      {/*          })*/}
      {/*        )}*/}
      {/*      </Paragraph>*/}
      {/*    </AlertMessage>*/}
      {/*  </AlertBox>*/}
      {/*) : null}*/}
      {isFirstBundle && (
        <>
          <Controller
            name={initialDescriptionName}
            control={control}
            render={({field}): ReactElement => (
              <TextInput
                {...field}
                className="spark-mar-t-2"
                characterCount
                maxLength={maxSimplifiedDescriptionLength}
                label={formatMessage({id: 'newBundle.initialDescription.label'})}
                multiLine
              />
            )}
          />
          <p className="spark-small spark-mar-b-0">{formatMessage({id: 'newBundle.initialDescription.comment'})}</p>
        </>
      )}
      <HeaderContainer className="spark-flex">
        <LongText className="spark-mar-b-0 spark-caps">
          <FormattedMessage id="newBundle.upload.header" />
          <Star />
        </LongText>
        <Popover
          anchorX={TooltipDirectionX.CENTER}
          anchorY={TooltipDirectionY.MIDDLE}
          toggleEl={
            <InfoButton
              role="presentation"
              type="button"
              className="spark-btn--icon spark-icon spark-icon-info-circle spark-icon--fill"
            />
          }
          aria-label="Describe bundle requirements"
        >
          {
            <ul>
              {Array.from(Array(6).keys()).map(index => (
                <li key={index}>
                  <FormattedMessage id={`newBundle.info.listItem${index + 1}`} />
                </li>
              ))}
            </ul>
          }
        </Popover>
      </HeaderContainer>
      <FilesList className="spark-list--no-bullets spark-mar-l-0">
        {watch(bundleFileName).map((file: File) => (
          <li key={file.name} onClick={() => deleteFileHandler(bundleFileName)}>
            {file.name} <Icon name="trash" />
          </li>
        ))}
      </FilesList>
      <FileUpload
        filesNames={bundleFileName}
        setFiles={setValue as () => void}
        watch={watch}
        filesType={zipFileType}
        maxFiles={1}
        maxFileSize={fileSize32}
        additionalText={formatMessage({
          id: 'newBundle.upload.additionalText',
        })}
      />
      <TextFile
        fileName={useCaseFileName}
        watch={watch}
        setValue={setValue}
        deleteFileHandler={deleteFileHandler}
        latestVersionId={latestVersionId}
        latestVersion={latestVersion}
        shouldBeCopied={shouldUsesCasesBeCopied}
        setShouldBeCopied={setShouldUsesCasesBeCopied}
      />
      {bundleHasPciOrPii && (
        <TextFile
          fileName={securityWorksheetName}
          watch={watch}
          setValue={setValue}
          deleteFileHandler={deleteFileHandler}
          latestVersionId={latestVersionId}
          latestVersion={latestVersion}
          shouldBeCopied={shouldSecurityWorksheetBeCopied}
          setShouldBeCopied={setShouldSecurityWorksheetBeCopied}
        />
      )}
      {bundleHasPciOrPii && (
        <TextFile
          fileName={workflowDiagramName}
          watch={watch}
          setValue={setValue}
          deleteFileHandler={deleteFileHandler}
          latestVersionId={latestVersionId}
          latestVersion={latestVersion}
          shouldBeCopied={shouldWorkflowDiagramBeCopied}
          setShouldBeCopied={setShouldWorkflowDiagramBeCopied}
        />
      )}
      {releaseNotesVisible && (
        <Controller
          name={patchNotesName}
          control={control}
          render={(_): ReactElement => (
            <TextEditorContainer>
              <LongText className="spark-mar-t-2">
                <FormattedMessage id={`newBundle.patchNote.label`} />
                {isPublic && <Star />}
              </LongText>
              <TextEditor
                editorState={isPublic ? watch(patchNotesName) ?? '<ul><li></li></ul>' : watch(patchNotesName) ?? ''}
                setValue={setValue}
                valueName={patchNotesName}
                maxCharacters={patchNotesMaxCharactersLength}
                disabled={false}
                setCountedCharactersByEditor={setPatchNotesCharactersCounter}
              />
            </TextEditorContainer>
          )}
        />
      )}

      <ButtonContainer className="spark-flex spark-mar-t-2">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
          {ButtonValues.Cancel}
        </Button>
        <Button
          progress
          type="button"
          size={ButtonSize.SMALL}
          onClick={async (e, success, failure) => {
            if (success && failure) {
              await onSubmit(getValues(), success, failure, e);
            }
          }}
          disabled={isDisabled}
          tabIndex={0}
        >
          {ButtonValues.Submit}
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  & textarea {
    min-height: 12rem;
  }

  & .spark-input.active::before {
    transform: translate(-1rem, -50%);
  }
`;

const ButtonContainer = styled.div`
  justify-content: flex-end;
`;

const InfoButton = styled.a`
  transform: translateY(-12px);
`;

const HeaderContainer = styled.div`
  transform: translateY(2rem);
`;

const LongText = styled.p`
  font-size: 1.2rem;
`;

const TextEditorContainer = styled.div`
  max-width: 110rem;
`;

const FilesList = styled.ul`
  & .spark-icon {
    font-size: 1.8rem;
    vertical-align: -1px;
    color: ${colors.sparkBlue};
    cursor: pointer;
  }
`;

const AlertMessage = styled.p<{error?: boolean}>`
  color: ${props => (!!props.error ? color.red : color.warningDarkYellow)};
  margin-bottom: 0 !important;
`;

const AlertBox = styled.div`
  padding: 1rem;
  border-radius: 0.25rem;
  font-weight: bold;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
`;

export const Paragraph = styled.p`
  word-break: normal;
  white-space: normal;
  line-height: 2.25rem;
  margin-bottom: 0;
`;

export default CreateBundle;
