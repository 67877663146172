import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {Button, Message} from '@sabre/spark-react-core';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {useNavigate} from 'react-router-dom';
import {ButtonSize, MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import {
  Configuration,
  DeleteProposalRequest,
  HideProposalRequest,
  ProposalsApi,
  SmallProposalResponse,
} from '../generated/proposal';
import {getAccessToken, isAgencyAdminRole, isSubmitProposalRole} from '@scm/authentication/utils/authentication';
import colors from '@scm/components/assets/colors';
import {Configuration as PresentationConfiguration, PresentationApi} from '../generated/presentation';
import {apiBaseLink} from '../assets/apiUrl';
import React, {ChangeEvent, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {SellersContext} from '@scm/sellers/components/SellersProvider';
import {FormattedMessage, useIntl} from 'react-intl';
import {mapProductResponseToProductList} from '@scm/product-components/utils/mappers/ProductMapper';
import statuses, {StatusGroup} from '@scm/admin-centre/src/utils/statuses';
import {openToast} from '@scm/components/messaging/openToast';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {Loading} from '@scm/components';
import HrefElement from '@scm/components/messaging/HrefElement';
import {redAppSupportEmail} from '@scm/components/utils/common';
import {HeaderText} from '@scm/admin-centre/src/components/App';
import {useDebounce} from 'use-debounce';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';
import InteractiveMessageBox from '@scm/components/messaging/InteractiveMessageBox';
import Products from '../components/products/Products';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import ToggleSwitch from './spark/ToogleSwitch';
import AutoSuggest from './spark/AutoSuggest';

export interface Product {
  id: string;
  productName: string;
  productIcon?: string;
  type: string;
  updatedAt?: string;
  productStatus?: string;
  version?: string;
  isLatestVersionPatch?: boolean;
  status: string;
  isProposal?: boolean;
  sku: string;
  proposalId: string;
  isFavourite?: boolean;
}

export enum Filters {
  'All Products' = 'All Products',
  'Action Required' = 'Action Required',
}

const fetchProposals = async () => {
  return new ProposalsApi(
    new Configuration({basePath: apiBaseLink, accessToken: getAccessToken() || '', middleware: middleware})
  ).getProposals();
};

const fetchProducts = async () => {
  return new PresentationApi(
    new PresentationConfiguration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).getProducts();
};

const deleteProposal = async (deleteProposalRequest: DeleteProposalRequest) =>
  await new ProposalsApi(
    new Configuration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).deleteProposal(deleteProposalRequest);

const hideProposal = async (hideProposalRequest: HideProposalRequest) =>
  await new ProposalsApi(
    new Configuration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).hideProposal(hideProposalRequest);

export const MyProducts = () => {
  const {userSellers} = useContext(SellersContext);
  const {formatMessage} = useIntl();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchPhraseDebounced] = useDebounce(searchPhrase, 500);
  const [filter, setFilter] = useState(Filters['All Products']);
  const [items, setItems] = useState(Array<Product>());
  const [isBannerOpen, setIsBannerOpen] = useState(true);
  const [searchProducts, setSearchProducts] = useState(
    items.map(item => ({id: item.id, name: item.productName, updatedAt: item.updatedAt}))
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts()
      .then(productList => {
        setItems(prev => [...prev, ...mapProductResponseToProductList(productList)]);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      })
      .then(() => {
        fetchProposals()
          .then(proposals => {
            setItems(prev => [...prev, ...mergeProposalToProductInstance(proposals)]);
          })
          .catch(() => {
            setError(true);
          })
          .finally(() => {
            setItems(prev => prev.sort((a, b) => +new Date(b.updatedAt ?? 0) - +new Date(a.updatedAt ?? 0)));
            setLoading(false);
          });
      });

    const mergeProposalToProductInstance = (proposals: SmallProposalResponse[]): Array<Product> =>
      proposals.map(proposal => ({
        id: proposal.proposalId ?? '',
        productName: proposal.name ?? '',
        status: proposal.status ?? '',
        type: `${capitalizeFirstLetter(proposal.provisioningType ?? '')} ${proposal.productType ?? ''}`,
        updatedAt: proposal.lastModifiedAt?.toISOString() ?? '',
        isProposal: true,
        sku: '',
        proposalId: proposal.proposalId ?? '',
        isFavourite: proposal.isFavourite,
      }));
  }, []);

  const filterProductsList = useCallback(() => {
    const filteredItems = items.filter(
      item =>
        filter === Filters['All Products'] ||
        (statuses.get(item.status) === StatusGroup.ActionRequired && !item.isProposal)
    );
    if (searchPhraseDebounced === '') {
      return filteredItems.map(item => ({id: item.id, name: item.productName, updatedAt: item.updatedAt}));
    } else {
      return filteredItems
        .filter(item => item.productName.toLowerCase().includes(searchPhraseDebounced.toLowerCase()))
        .map(item => ({id: item.id, name: item.productName, updatedAt: item.updatedAt}));
    }
  }, [filter, items, searchPhraseDebounced]);

  useEffect(() => setSearchProducts(filterProductsList()), [searchPhraseDebounced, filterProductsList]);

  const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase((event?.target as HTMLInputElement).value);
  };
  const handleReset = () => {
    setSearchPhrase('');
  };

  const handleSetSearchItem = (newValue: string) => {
    setSearchPhrase(newValue);
  };
  const handleFilterChange = (event: ChangeEvent<HTMLDivElement>) => {
    setFilter((event?.target as HTMLInputElement).value as Filters);
  };

  const handleAddNewProductButton = () => {
    gaEvent('Click Add New Product');
    navigate(InternalUrls.AddNewProduct);
  };

  const deleteProposalCallback = useCallback(
    async (id: string, name: string) => {
      deleteProposal({
        id,
      })
        .then(() => {
          openToast(
            formatMessage({id: 'myProducts.action.proposal.delete.title'}),
            formatMessage({id: 'myProducts.action.proposal.delete.message'}, {name}),
            ToastType.POSITIVE,
            'spark-icon-check'
          );

          setItems(items.filter((item: any) => item.id !== id));
        })
        .catch(() =>
          openToast(
            formatMessage({id: 'myProducts.action.proposal.delete.notFound.title'}),
            formatMessage({id: 'myProducts.action.proposal.delete.notFound.description'}),
            ToastType.WARNING,
            'spark-icon-alert-triangle'
          )
        );
    },
    [items]
  );

  const removeProposalCallback = useCallback(async (id: string, name: string) => {
    hideProposal({id})
      .then(() =>
        openToast(
          formatMessage({id: 'myProducts.action.proposal.remove.title'}),
          formatMessage({id: 'myProducts.action.proposal.remove.message'}, {name}),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(() =>
        openToast(
          formatMessage({id: 'myProducts.action.proposal.delete.notFound.title'}),
          formatMessage({id: 'myProducts.action.proposal.delete.notFound.description'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      );
  }, []);

  const canAddNewProduct = useMemo(
    () =>
      (!!userSellers.filter(seller => seller.canSubmitProposal).length && isSubmitProposalRole()) ||
      isAgencyAdminRole(),
    [userSellers]
  );

  const searchProductsIds = useMemo(() => searchProducts.map(searchProduct => searchProduct.id), [searchProducts]);

  return loading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : error ? (
    <MessageContainer>
      <Message
        title={formatMessage({id: 'productDetails.fetch.errorTitle'})}
        content={
          formatMessage(
            {id: 'productDetails.fetch.error'},
            {
              email: <HrefElement email={redAppSupportEmail} />,
            }
          ) as string
        }
        role={MessageRole.ALERT}
        status={MessageStatus.ERROR}
        type={MessageType.PAGE}
        className="spark-pad-2"
      />
    </MessageContainer>
  ) : (
    <>
      <InteractiveMessageBox
        title="myProducts.announcement.title"
        content="myProducts.announcement.description"
        icon="announce"
        href={InternalUrls.Roadmap}
        setIsOpen={setIsBannerOpen}
      />
      <div className="spark-pad-2">
        <HeaderContainer className="spark-flex spark-mar-b-.5 spark-pad-r-1">
          <HeaderText className="spark-mar-b-0">
            <FormattedMessage id="myProducts.title" />
          </HeaderText>
          {canAddNewProduct && (
            <AddButton onClick={handleAddNewProductButton} size={ButtonSize.EXTRA_SMALL} secondary>
              <FormattedMessage id="myProducts.linkButton.label" />
            </AddButton>
          )}
        </HeaderContainer>
        <FilterContainer className="spark-flex spark-align-items-center">
          <ToggleSwitch
            name="filter"
            values={[Filters['All Products'], Filters['Action Required']]}
            labels={[
              formatMessage({id: 'myProducts.filterButton.firstLabel'}),
              formatMessage({id: 'myProducts.filterButton.secondLabel'}),
            ]}
            onChange={handleFilterChange}
          />
          <AutoSuggest
            name="searchBox"
            value={searchPhrase}
            label={formatMessage({id: 'myProducts.searchBox.label'})}
            searchTerms={items.map(item => item.productName)}
            onChange={handleSearch}
            onReset={handleReset}
            onSetSearchItem={handleSetSearchItem}
            icon
          />
        </FilterContainer>
        <Products
          items={items}
          setItems={setItems}
          searchProductsIds={searchProductsIds}
          deleteProposal={(id, name) => deleteProposalCallback(id, name)}
          removeProposal={(id, name) => removeProposalCallback(id, name)}
          isBannerOpen={isBannerOpen}
        />
      </div>
    </>
  );
};

export const HeaderContainer = styled.div`
  justify-content: space-between;
  @media (max-width: ${deviceWidths.md}px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const FilterContainer = styled.div`
  flex-wrap: wrap;

  & .spark-auto-suggest {
    width: 100%;
  }

  @media (min-width: ${deviceWidths.md}px) {
    justify-content: space-between;
    & .spark-auto-suggest {
      width: 30rem;
      transform: translate(2.3rem, -0.5rem) scale(0.8);
      z-index: 11;
    }

    & .spark-auto-suggest .spark-label,
    & .spark-auto-suggest .spark-auto-suggest__field {
      font-size: 1.7rem;
    }

    & .spark-auto-suggest.active .spark-label {
      font-size: 1.1rem;
    }
  }
`;

export const AddButton = styled(Button)`
  font-size: 1.4rem;
`;

const MessageContainer = styled.div`
  margin: 3rem;
  background-color: ${colors.white};
`;
