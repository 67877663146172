import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import React from 'react';

const ReviewList = ({items, isId}: {items?: string[]; isId?: boolean}) => {
  return (
    <StyledReviewList>
      {items?.map((item, index) => (
        <li key={index}>{isId ? <FormattedMessage id={item} /> : item}</li>
      ))}
    </StyledReviewList>
  );
};

const StyledReviewList = styled.ul`
  & li::before {
    content: '-';
    display: inline-block;
    margin-right: 1rem;
  }
  & li::marker {
    content: '';
  }
  & li {
    margin: 0;
  }
`;
export default ReviewList;
