import React, {ReactElement, useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Controller} from 'react-hook-form';
import {ProposalContext} from '../../../ProposalForm';
import {RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {developmentTypeName} from '../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {Star} from '@scm/components';
import Legend from '@scm/components/accessibility/Legend';

enum DevelopmentTypeValues {
  'in-house development' = 'In-house development',
  'outsourcing development' = 'Outsourcing development',
}

const DevelopmentType = () => {
  const {formatMessage} = useIntl();
  const {control, errors} = useContext(ProposalContext);
  const developmentTypeLabel = 'developmentType';

  return (
    <div className="row spark-align-items-center spark-mar-b-1 spark-mar-l-0">
      <p className="spark-mar-b-0 spark-mar-r-2" id={developmentTypeLabel}>
        <FormattedMessage id="definition.developmentType.label" />
        <Star hasColon />
      </p>
      <Controller
        name={developmentTypeName}
        control={control}
        render={({field: {onChange, name, value}}): ReactElement => (
          <RadioButtonGroup
            className="row spark-align-items-center spark-pad-t-.5"
            name={name}
            value={value}
            onChange={onChange}
            status={errors[name] ? MessageStatus.ERROR : undefined}
            statusMessage={errors[name] && errors[name]?.message}
            aria-labelledby={developmentTypeLabel}
          >
            <Legend text={formatMessage({id: 'definition.developmentType.label'})} />
            {Object.values(DevelopmentTypeValues).map(type => (
              <RadioButton key={type} label={type} value={type} className="spark-mar-l-2" />
            ))}
          </RadioButtonGroup>
        )}
      />
    </div>
  );
};

export default DevelopmentType;
