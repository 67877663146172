/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – User
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InternalRolesList,
} from '../models';
import {
    InternalRolesListFromJSON,
    InternalRolesListToJSON,
} from '../models';

export interface GetInternalRolesRequest {
    pcc: string;
    epr: string;
}

export interface UpdateInternalRolesRequest {
    pcc: string;
    epr: string;
    internalRolesList: InternalRolesList;
}

/**
 * 
 */
export class InternalRolesApi extends runtime.BaseAPI {

    /**
     * Endpoint which allows to get internal roles for user
     */
    async getInternalRolesRaw(requestParameters: GetInternalRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InternalRolesList>> {
        if (requestParameters.pcc === null || requestParameters.pcc === undefined) {
            throw new runtime.RequiredError('pcc','Required parameter requestParameters.pcc was null or undefined when calling getInternalRoles.');
        }

        if (requestParameters.epr === null || requestParameters.epr === undefined) {
            throw new runtime.RequiredError('epr','Required parameter requestParameters.epr was null or undefined when calling getInternalRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/pcc/{pcc}/epr/{epr}/internal-roles`.replace(`{${"pcc"}}`, encodeURIComponent(String(requestParameters.pcc))).replace(`{${"epr"}}`, encodeURIComponent(String(requestParameters.epr))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InternalRolesListFromJSON(jsonValue));
    }

    /**
     * Endpoint which allows to get internal roles for user
     */
    async getInternalRoles(requestParameters: GetInternalRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InternalRolesList> {
        const response = await this.getInternalRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint which allows to update internal roles for user
     */
    async updateInternalRolesRaw(requestParameters: UpdateInternalRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pcc === null || requestParameters.pcc === undefined) {
            throw new runtime.RequiredError('pcc','Required parameter requestParameters.pcc was null or undefined when calling updateInternalRoles.');
        }

        if (requestParameters.epr === null || requestParameters.epr === undefined) {
            throw new runtime.RequiredError('epr','Required parameter requestParameters.epr was null or undefined when calling updateInternalRoles.');
        }

        if (requestParameters.internalRolesList === null || requestParameters.internalRolesList === undefined) {
            throw new runtime.RequiredError('internalRolesList','Required parameter requestParameters.internalRolesList was null or undefined when calling updateInternalRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/pcc/{pcc}/epr/{epr}/internal-roles`.replace(`{${"pcc"}}`, encodeURIComponent(String(requestParameters.pcc))).replace(`{${"epr"}}`, encodeURIComponent(String(requestParameters.epr))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InternalRolesListToJSON(requestParameters.internalRolesList),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint which allows to update internal roles for user
     */
    async updateInternalRoles(requestParameters: UpdateInternalRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInternalRolesRaw(requestParameters, initOverrides);
    }

}
