import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import parse from 'html-react-parser';
import colors from '@scm/components/assets/colors';

const PartnerTiersHeader = () => {
  const {formatMessage} = useIntl();

  return (
    <Container>
      <h1 className="spark-mar-b-2">
        <FormattedMessage id="tiers.title" />
      </h1>
      <Text>{parse(formatMessage({id: 'tiers.subtitle'}))}</Text>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 0 4rem 0;
  margin: auto;
`;

const Text = styled.span.attrs(() => ({className: 'spark-delta'}))`
  color: ${colors.grey700};
`;

export default PartnerTiersHeader;
