import {Button, Calendar, DateInputField, SelectInput, TextInput, Toggle, ToggleGroup} from '@sabre/spark-react-core';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {RoadmapContext} from '../../components/RoadmapProvider';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import {
  descriptionName,
  releaseDateName,
  RoadmapDataValues,
  statusName,
  subjectName,
} from '../../utils/RoadmapDataValues';
import {yupResolver} from '@hookform/resolvers/yup';
import {basicRoadmapSchema} from '../../schema/basicRoadmapSchema';
import {Configuration, RoadmapApi, RoadmapRequest, RoadmapStatus} from '../../generated/roadmap';
import {roadmapApiBaseLink} from '../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {mapToRoadmapFields, mapToRoadmapRequest} from '../../components/mapper/roadmapMapper';
import {dateFormatEndWithYear} from '@scm/components/utils/common';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {TextArea} from '@scm/proposal/pages/proposalForm/steps/definitionPage/definitionComponents/Description';
import color from '@scm/components/assets/colors';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

export const addRoadmap = (roadmapRequest: RoadmapRequest) =>
  new RoadmapApi(
    new Configuration({
      basePath: roadmapApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).createRoadmap({roadmapRequest});

export const editRoadmap = (id: string, roadmapRequest: RoadmapRequest) =>
  new RoadmapApi(
    new Configuration({
      basePath: roadmapApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).updateRoadmap({id, roadmapRequest});

const AddNewRoadmap = ({isEdit = false}: {isEdit?: boolean}) => {
  const {handleClose, setIsLoading, chosenRoadmapId, roadmaps} = useContext(RoadmapContext);
  const {
    watch,
    setValue,
    reset,
    formState: {isValid, isDirty},
  } = useForm<RoadmapDataValues>({mode: 'onChange', resolver: yupResolver(basicRoadmapSchema)});
  const [isReleaseDate, setIsReleaseDate] = useState(false);
  const isReleaseDateName = 'isReleaseDate';

  const {formatMessage} = useIntl();

  const prepareEditData = () => {
    const mapRoadmap = roadmaps.find(roadmap => roadmap.id === chosenRoadmapId);
    if (!mapRoadmap) {
      return;
    }
    mapToRoadmapFields(mapRoadmap, setValue);
    setIsReleaseDate(!!watch(releaseDateName));
    reset(watch());
  };

  useEffect(() => {
    if (isEdit) {
      prepareEditData();
    } else {
      reset({});
      setValue(statusName, Object.values(RoadmapStatus)[0], {shouldValidate: true});
      setValue(releaseDateName, undefined, {shouldDirty: true});
    }
  }, []);

  const handleAdd = () =>
    addRoadmap(mapToRoadmapRequest(watch))
      .then(() =>
        openToast(
          createMessageString(formatMessage, 'roadmap.modal', true, false),
          createMessageString(formatMessage, 'roadmap.modal.edit', false, false),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(() =>
        openToast(
          createMessageString(formatMessage, 'roadmap.modal', true, true),
          createMessageString(formatMessage, 'roadmap.modal', false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      )
      .finally(() => setIsLoading(true));

  const handleEdit = () =>
    editRoadmap(chosenRoadmapId, mapToRoadmapRequest(watch))
      .then(() =>
        openToast(
          createMessageString(formatMessage, 'roadmap.modal', true, false),
          createMessageString(formatMessage, 'roadmap.modal.edit', false, false),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(() =>
        openToast(
          createMessageString(formatMessage, 'roadmap.modal', true, true),
          createMessageString(formatMessage, 'roadmap.modal', false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      )
      .finally(() => setIsLoading(true));

  const toggleReleaseDate = (value: string) => {
    const toggleValue = value === Confirmation.Yes;
    if (!toggleValue) {
      setValue(releaseDateName, undefined, {shouldValidate: true, shouldDirty: true});
    }
    if (toggleValue && !watch(releaseDateName)) {
      setValue(releaseDateName, new Date(), {shouldValidate: true, shouldDirty: true});
    }
    setIsReleaseDate(toggleValue);
  };

  const calendarRef = useRef(null);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarEl = document.querySelector('.spark-calendar[role=application]') as Element;
      calendarEl.setAttribute('role', 'presentation');
    }
  }, [isReleaseDate]);

  return (
    <div>
      <div>
        {(!isEdit || watch(subjectName) !== undefined) && (
          <TextInput
            name={subjectName}
            label={formatMessage({id: 'roadmap.form.subject'})}
            value={watch(subjectName)}
            onChange={(_, value) => setValue(subjectName, value, {shouldValidate: true, shouldDirty: true})}
            data-testid="newFeatureSubjectInput"
          />
        )}
        {(!isEdit || watch(descriptionName) !== undefined) && (
          <TextArea
            name={descriptionName}
            value={watch(descriptionName)}
            label={formatMessage({id: 'roadmap.form.provideDescription'})}
            maxLength={1000}
            className="spark-mar-b-1"
            characterCount
            multiLine
            onChange={(_, value) =>
              setValue(descriptionName, value, {
                shouldDirty: true,
                shouldValidate: true,
              })
            }
            data-testid="newFeatureDescriptionInput"
          />
        )}
        <InlineWrapper className="spark-flex">
          <div className="col-xs-5 spark-pad-l-0">
            <p className="spark-label spark-mar-0" id="accessibilityLabel">
              <FormattedMessage id="roadmap.form.roadmapQuestion" />
            </p>
            <ToggleGroup
              name={isReleaseDateName}
              value={isReleaseDate ? Confirmation.Yes : Confirmation.No}
              onChange={(_, value) => toggleReleaseDate(value)}
            >
              <Toggle
                name={`${isReleaseDateName}First`}
                className="col-xs-5"
                label={Confirmation.No}
                value={Confirmation.No}
                condensed
                ariaLabel={formatMessage({id: 'accessibility.confirmation.checkbox'}, {value: Confirmation.No})}
              />
              <Toggle
                name={`${isReleaseDateName}Second`}
                className="col-xs-5"
                label={Confirmation.Yes}
                value={Confirmation.Yes}
                condensed
                ariaLabel={formatMessage({id: 'accessibility.confirmation.checkbox'}, {value: Confirmation.Yes})}
              />
            </ToggleGroup>
          </div>
          <div className="col-xs-7 spark-pad-r-0">
            <SelectInput
              label={formatMessage({id: 'roadmap.form.status'})}
              value={watch(statusName)}
              name={statusName}
              options={Object.values(RoadmapStatus).map(value => ({value, label: value}))}
              onChange={(_, value) =>
                setValue(statusName, RoadmapStatus[value.replace(' ', '')], {
                  shouldValidate: true,
                  shouldDirty: true,
                })
              }
              required
            />
          </div>
        </InlineWrapper>
        {isReleaseDate && (
          <div ref={calendarRef}>
            <div className="row spark-mar-t-2">
              <div className="col-xs-12 col-md-8 col-lg-6 col-xl-6 spark-center-block">
                <DateInputField
                  value={watch(releaseDateName)}
                  onChange={(date): void =>
                    setValue(releaseDateName, date ?? new Date(), {shouldValidate: true, shouldDirty: true})
                  }
                  label={formatMessage({id: 'roadmap.form.releaseDate'})}
                  format={dateFormatEndWithYear}
                />
              </div>
            </div>
            <InputCalendar
              value={watch(releaseDateName)}
              onChange={(date): void =>
                setValue(releaseDateName, date ?? new Date(), {shouldValidate: true, shouldDirty: true})
              }
            />
          </div>
        )}
      </div>
      <div className="spark-btn-group spark-mar-t-2">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <SaveButton
          type="submit"
          disabled={!isValid || !isDirty}
          onClick={() => (isEdit ? handleEdit() : handleAdd())}
          size={ButtonSize.SMALL}
          tabIndex={0}
          data-testid="roadmapFeatureSaveButton"
        >
          <FormattedMessage id="common.save" />
        </SaveButton>
      </div>
    </div>
  );
};

export const InlineWrapper = styled.div`
  justify-content: space-between;
`;

const SaveButton = styled(Button)<{disabled?: boolean}>`
  color: ${({disabled}) => (disabled ? color.statusInfoGrey : color.white)} !important;
`;

const InputCalendar = styled(Calendar)`
  & .spark-calendar__day--number:not(.spark-calendar__day--selected .spark-calendar__day--number) {
    color: black;
  }
`;

export default AddNewRoadmap;
