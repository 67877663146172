import React from 'react';
import {useIntl} from 'react-intl';
import {Fields} from './EndMessage';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {Message} from '@sabre/spark-react-core';

const ErrorMessage = () => {
  const {formatMessage} = useIntl();

  return (
    <Fields>
      <Message
        content={formatMessage({id: 'joinUsPage.form.errorMessage.message'})}
        role={MessageRole.ALERT}
        status={MessageStatus.ERROR}
        title={formatMessage({id: 'joinUsPage.form.errorMessage.title'})}
        type={MessageType.PAGE}
        titleHeadingLevel={2}
      />
    </Fields>
  );
};

export default ErrorMessage;
