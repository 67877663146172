export const regionCodesName = 'regionCodes';
export const countryCodesName = 'countryCodes';
export const allRegionsName = 'global';
export const globalRegionalizationCheckboxName = 'globalRegionalizationCheckboxName';

export default interface RegionalizationDataValues {
  [regionCodesName]: Array<string>;
  [countryCodesName]: Array<string>;
  [allRegionsName]: boolean;
  [globalRegionalizationCheckboxName]: boolean;
}
