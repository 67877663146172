import React, {useState} from 'react';
import {Button, Icon, Modal} from '@sabre/spark-react-core';
import styled from 'styled-components';
import {FormattedMessage, useIntl} from 'react-intl';
import {ButtonSize} from '@sabre/spark-react-core/types';
import InfoColumn from './InfoColumn';
import {privateStandard} from '../translations/infoTexts/privateStandard';
import {publicStandard} from '../translations/infoTexts/publicStandard';
import {privateData} from '../translations/infoTexts/privateData';
import DoubleInfoTable from './DoubleInfoTable';
import colors from '../assets/colors';

const OnboardingInfo = ({onlyPrivate, onlyPublic}: {onlyPrivate?: boolean; onlyPublic?: boolean}) => {
  const [open, setOpen] = useState(false);
  const handleClose = (): void => setOpen(false);
  const handleOpen = (): void => setOpen(true);
  const {formatMessage} = useIntl();
  const isProposal = onlyPrivate || onlyPublic;

  return (
    <>
      <Container className={`spark-mar-r-1 ${isProposal ? 'spark-mar-b-1' : ''}`}>
        <Button secondary onClick={handleOpen} size={ButtonSize.SMALL} className={isProposal ? 'proposal-btn' : ''}>
          <Icon iconStyle="line" name="document-checklist" size="sm" className="spark-mar-r-.5" />
          <FormattedMessage id="onboarding.info.button.label" />
        </Button>
      </Container>
      <StyledModal
        onClose={handleClose}
        open={open}
        modalHeader={
          <ModalHeader className="spark-mar-l-1 spark-mar-t-2">
            {formatMessage({id: 'onboarding.info.button.label'})}
          </ModalHeader>
        }
        ariaLabel={formatMessage({id: 'onboarding.info.button.label'})}
      >
        <div className="row mar-t-2">
          {isProposal ? (
            <InfoColumn
              textItems={{
                standardInfo: Object.keys(onlyPrivate ? privateStandard : publicStandard),
                data: Object.keys(privateData),
              }}
            />
          ) : (
            <DoubleInfoTable
              publicTextItems={{
                standardInfo: Object.keys(publicStandard),
                data: Object.keys(privateData),
              }}
              privateTextItems={{
                standardInfo: Object.keys(privateStandard),
                data: Object.keys(privateData),
              }}
            />
          )}
        </div>
        <div className="spark-btn-group">
          <Button
            className="spark-btn-group-secondary spark-mar-r-1 spark-mar-b-1"
            size={ButtonSize.SMALL}
            secondary
            onClick={handleClose}
          >
            <FormattedMessage id="common.button.close" />
          </Button>
        </div>
      </StyledModal>
    </>
  );
};

const ModalHeader = styled.h3`
  font-weight: bold !important;
`;

const Container = styled.div`
  display: inline-block;

  & .proposal-btn {
    color: ${colors.grey700} !important;
    border-color: ${colors.grey700} !important;
    box-shadow: inset 0 -2px 0 ${colors.grey700} !important;
    &:focus {
      text-decoration: underline;
    }
  }

  & .spark-icon {
    vertical-align: text-bottom !important;

    &::before {
      font-size: 1.55rem;
      line-height: 1.55rem;
      font-weight: bold;
    }
  }
`;

const StyledModal = styled(Modal)`
  & .col-xl-5 {
    max-width: 58.333333% !important;
    flex: 0 0 58.333333% !important;
  }

  & .col-lg-6 {
    max-width: 66.666666% !important;
    flex: 0 0 66.666666% !important;
  }
`;

export default OnboardingInfo;
