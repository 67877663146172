import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import SparkAutoSuggest from '@sabre/spark/js/src/components/auto-suggest';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const AutoSuggest = ({
  name,
  label,
  searchTerms,
  value,
  icon,
  onChange,
  onReset,
  onSetSearchItem,
  maxLength = 50,
  showNoResultsFound = true,
  maxSuggestions = 2,
}: {
  name: string;
  label: string;
  searchTerms?: Array<string>;
  value?: string;
  icon?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onReset?: () => void;
  onSetSearchItem?: (value: string) => void;
  maxLength?: number;
  showNoResultsFound?: boolean;
  maxSuggestions?: number;
}) => {
  const autoSuggestRef = useRef(null);

  const [options] = useState({
    autoSuggestTerms: searchTerms || [],
    onSelection: onSetSearchItem,
    showNoResultsFound,
    maxSuggestions,
  });

  useEffect(() => {
    const sparkAutoSuggest = new SparkAutoSuggest(autoSuggestRef.current, options);

    return () => {
      sparkAutoSuggest.remove();
    };
  }, [autoSuggestRef, options]);

  return (
    <div
      className="spark-auto-suggest"
      role="combobox"
      aria-expanded="false"
      aria-controls="autosuggest-listbox"
      aria-labelledby="autosuggest-label"
      aria-haspopup="listbox"
      ref={autoSuggestRef}
    >
      <AutoSuggestLabel isIcon={icon}>
        <input
          name={name}
          className="spark-auto-suggest__field"
          aria-label={label}
          aria-labelledby="autosuggest-label"
          aria-controls="autosuggest-listbox"
          value={value}
          onChange={onChange}
          maxLength={maxLength}
        />
        <span id="autosuggest-label" className="spark-label">
          {label}
        </span>
        <button
          type="button"
          className="spark-auto-suggest__clear-btn spark-icon-close"
          aria-label="Clear contents"
          onClick={onReset}
        />
        <SuggestList
          id="autosuggest-listbox"
          className={`spark-auto-suggest__listbox ${searchTerms ? '' : 'hidden'}`}
          role="listbox"
        />
        {icon ? <AutoSuggestIcon aria-hidden="true" className="spark-icon-search spark-icon--md" /> : null}
      </AutoSuggestLabel>
    </div>
  );
};

const AutoSuggestIcon = styled.i`
  background-color: ${colors.transparent};
  transform: translate(1rem, -3.5rem);
  z-index: 6 !important;
`;
const AutoSuggestLabel = styled.label.attrs(({isIcon}: {isIcon: boolean}) => ({
  isIcon: isIcon,
}))`
  & .spark-auto-suggest__field {
    ${props => (props.isIcon ? 'padding-left: 4rem;' : '')};
    ${props => (props.isIcon ? ' z-index: 0;' : '')};
  }

  & .spark-label {
    ${props => (props.isIcon ? 'padding-left: 3rem;' : '')};
  }
`;

const SuggestList = styled.ul`
  &.hidden {
    visibility: hidden;
  }
`;

export default AutoSuggest;
