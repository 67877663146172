import React from 'react';
import Welcome from 'pages/home/welcome/Welcome';
import About from 'pages/home/about/About';
import Explore from 'pages/home/explore/Explore';
import Highlighted from 'pages/home/highlighted/Highlighted';

const Home = () => (
  <main>
    <Welcome />
    <About />
    <Explore />
    <Highlighted />
  </main>
);

export default Home;
