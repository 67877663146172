import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import HalfWidthContainer from 'components/styledComponents/HalfWidthContainer';
import AboutBackground from 'assets/images/what_is_sabre_partner_network_background.png';
import MediaQuery from 'react-responsive';
import AboutMobile from 'pages/home/about/AboutMobile';
import {maxMobileWidth, minDesktopWidth} from 'assets/deviceWidth';
import colors from '@scm/components/assets/colors';

const About = () => (
  <Container>
    <MediaQuery maxDeviceWidth={maxMobileWidth}>
      <AboutMobile />
    </MediaQuery>
    <MediaQuery minDeviceWidth={minDesktopWidth}>
      <AboutContainer>
        <HalfWidthContainer>
          <AboutTitle>
            <FormattedMessage id="homePage.welcome.title" />
          </AboutTitle>
          <AboutText>
            <FormattedMessage id="homePage.welcome.text" />
          </AboutText>
        </HalfWidthContainer>
        <HalfWidthContainer padding="0 0 0 7rem">
          <AboutImage src={AboutBackground} alt="about background" />
        </HalfWidthContainer>
      </AboutContainer>
    </MediaQuery>
  </Container>
);

const Container = styled.div`
  background-color: white;
`;

const AboutContainer = styled.div.attrs(() => ({
  className: 'spark-flex',
}))`
  max-width: 70vw;
  margin: auto;
  padding: 5rem 0 0 4rem;
`;

const AboutTitle = styled.div.attrs(() => ({
  className: 'spark-beta spark-mar-b-2',
}))`
  max-width: 70vw;
  margin: auto;
  text-align: left;
  color: ${colors.grey700};
  font-weight: 500;
`;

const AboutText = styled.div.attrs(() => ({
  className: 'spark-delta spark-pad-b-2',
}))``;

const AboutImage = styled.img`
  max-height: 80%;
  max-width: 80%;
`;

export default About;
