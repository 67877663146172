import {Toggle, ToggleGroup} from '@sabre/spark-react-core';
import {useContext} from 'react';
import {LevelAccessContext} from '../../../providers/LevelAccessProvider';
import styled from 'styled-components';

export enum AccessType {
  'Company Level Access' = 'Company Level Access',
  'Product Level Access' = 'Product Level Access',
}

export const LevelAccess = () => {
  const {state, setState} = useContext(LevelAccessContext);

  return (
    <ToggleWrapper className="col-xs-12 col-sm-5 spark-pad-b-1">
      <ToggleGroup
        name="test-toggle"
        role="group"
        value={state}
        onChange={(_, value) => !!value && setState(value as AccessType)}
      >
        {Object.values(AccessType).map(value => (
          <Toggle key={value} className="col-xs-12 col-lg-6" label={value} value={value} />
        ))}
      </ToggleGroup>
    </ToggleWrapper>
  );
};

const ToggleWrapper = styled.div`
  & .spark-label {
    font-size: 1.4rem;
    line-height: 120%;
    font-weight: 500;
  }
`;

export default LevelAccess;
