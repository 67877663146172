import React, {SetStateAction, useContext, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import TabName from './TabName';
import BundlesTable from './bundles/BundlesTable';
import {Icon} from '@sabre/spark-react-core';
import {Loading, SupportIcon} from '@scm/components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import ProdTesters from './testers/ProdTesters';
import ProposalData from './proposalData/ProposalData';
import {ProductCompatibilities} from '../../storefrontData/productDetailsUtils';
import RedAppDetails from './redAppDetails/RedAppDetails';
import Pricing from './tabs/pricing/Pricing';
import {
  BetaTester,
  CleanupResponse,
  Configuration as CertificationConfiguration,
  GetBetaTestersRequest,
  GetCertBetaTestersRequest,
  ProductsApi,
} from '../../../generated/certification';
import {certificationApiBaseLink, productApiBaseLink} from '../../../assets/apiBaseLink';
import {
  getAccessToken,
  isSabreAdminRole,
  isSabreEmployeeRole,
  isSecondLevelSupportRole,
} from '@scm/authentication/utils/authentication';
import {StorefrontDataContext} from '../../storefrontData/dataProvider/StorefrontDataProvider';
import {isPaidName, provisioningTypeName, typeName} from '../../storefrontData/interfaces/AppInfoDataValues';
import {certTestersName, testersName} from '../../storefrontData/interfaces/TesterDataValues';
import AuditLogs from '@scm/admin-centre/src/components/auditLog/AuditLogs';
import {
  AuditApi,
  AuditLog,
  Configuration as AuditConfiguration,
  GetAuditsObjectTypeEnum,
  ObjectType,
} from '@scm/proposal/generated/audit';
import {AuthenticationContext} from '@scm/authentication/provider/AuthenticationProvider';
import jwt_decode from 'jwt-decode';
import {UsersRoles} from '@scm/components/utils/usersRoles';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {useIntl} from 'react-intl';
import CertTesters from './testers/CertTesters';
import {Tester} from './testers/TesterTable';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

export interface TabData {
  name: string;
  content: JSX.Element | null;
  icon?: JSX.Element;
  numberOfItems?: number;
  hideElement?: boolean;
}

export interface Bundle {
  id: string;
  version: string;
  submitted: string;
  lastUpdated: string;
  status: string;
  compatibility?: ProductCompatibilities;
  webCompatibility: boolean;
  lastCleanup?: string;
  scheduledRemoval?: string;
  removalDate?: string;
  simplifiedDescription?: string;
  releaseNotes?: string;
  name?: string;
  isPatch: string;
  assignedCertificationEngineer?: string;
  cleanup?: CleanupResponse;
}

export type ActionBadgesDisplaysType = () => Array<string>;

const getProdBetaTesters = async (getBetaTestersRequest: GetBetaTestersRequest) =>
  new ProductsApi(
    new CertificationConfiguration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).getBetaTesters(getBetaTestersRequest);

const getCertBetaTesters = async (getCertBetaTestersRequest: GetCertBetaTestersRequest) =>
  new ProductsApi(
    new CertificationConfiguration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).getCertBetaTesters(getCertBetaTestersRequest);

const ProductTabsContainer = ({
  latestVersion,
  setCanPublishVersion,
  setMissingItemsForPublishing,
}: {
  latestVersion?: Bundle;
  setCanPublishVersion: React.Dispatch<SetStateAction<boolean>>;
  setMissingItemsForPublishing: React.Dispatch<SetStateAction<string[]>>;
}) => {
  const {
    methodsTesterForm,
    methodsCertTesterForm,
    methodsProductForm,
    sku,
    proposalData,
    reloadTestersTab,
    reloadCertTestersTab,
    setReloadTestersTab,
    setReloadCertTestersTab,
    cmCertConfigCreated,
  } = useContext(StorefrontDataContext);
  const navigate = useNavigate();
  const {watch: watchProductForm} = methodsProductForm;
  const {setValue: setValueTesterForm} = methodsTesterForm;
  const {setValue: setValueCertTesterForm} = methodsCertTesterForm;
  const isRedApp = watchProductForm(typeName) === 'Regular';
  const isPublic = isRedApp && watchProductForm(provisioningTypeName) === 'Public';
  const isPricingVisible = isPublic && watchProductForm(isPaidName) === 'Paid';
  const location = useLocation();
  const slash = '/';
  const activeTabName = location.pathname.slice(location.pathname.lastIndexOf(slash) + 1);
  const version = useLocation().state?.version;
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const {accessToken} = useContext(AuthenticationContext);
  const {roles} = accessToken ? jwt_decode<{roles: Array<string>}>(accessToken) : {roles: ['']};
  const allowedRoles = [
    UsersRoles['ROLE_SABRE_ADMIN'],
    UsersRoles['ROLE_SECOND_LEVEL_SUPPORT'],
    UsersRoles['ROLE_DEVELOPER_USER'],
    UsersRoles['ROLE_DEVELOPER_ADMIN'],
    UsersRoles['ROLE_AGENCY_ADMIN'],
    UsersRoles['ROLE_FIRST_LEVEL_SUPPORT'],
    UsersRoles['ROLE_ADP'],
  ];
  const hideProdTesters = !allowedRoles.some(allowedRole => roles.includes(allowedRole));
  const [reloadForm, setReloadForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {formatMessage} = useIntl();

  const isProdTestersFetching = useRef(false);
  const isCertTestersFetching = useRef(false);
  useEffect(() => {
    const getBetaTestersRequest: GetBetaTestersRequest = {
      id: sku,
    };

    if (sku && reloadTestersTab && !isProdTestersFetching.current) {
      isProdTestersFetching.current = true;
      getProdBetaTesters(getBetaTestersRequest)
        .then(res => {
          const testersArray: Array<BetaTester> = res.map((tester, index) => ({
            nr: `${index + 1}`,
            pcc: tester.pcc,
            sabreId: tester.epr,
            created: tester.creationDateTime,
            status: tester.status,
            errorReason: tester.errorReason,
          }));

          testersArray.sort((a, b) => (a.pcc || '').localeCompare(b.pcc || ''));

          setValueTesterForm(testersName, testersArray as Tester[]);
        })
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
          setReloadTestersTab(false);
          isProdTestersFetching.current = false;
        });
    }

    if (sku && reloadCertTestersTab && !isCertTestersFetching.current) {
      isCertTestersFetching.current = true;
      getCertBetaTesters(getBetaTestersRequest)
        .then(res => {
          const testersArray: Array<BetaTester> = res.map((tester, index) => ({
            nr: `${index + 1}`,
            pcc: tester.pcc,
            sabreId: tester.epr,
            created: tester.creationDateTime,
          }));

          testersArray.sort((a, b) => (a.pcc || '').localeCompare(b.pcc || ''));

          setValueCertTesterForm(certTestersName, testersArray as Tester[]);
        })
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
          setReloadCertTestersTab(false);
          isCertTestersFetching.current = false;
        });
    }
  }, [sku, getProdBetaTesters, reloadTestersTab, reloadCertTestersTab, getCertBetaTesters]);

  useEffect(() => {
    if ((sku && isSabreEmployeeRole()) || reloadForm) {
      fetchAuditLogs(sku, GetAuditsObjectTypeEnum.Product)
        .then(response => setAuditLogs(response))
        .catch(err => console.log(err))
        .finally(() => {
          setIsLoading(false);
          setReloadForm(false);
        });
    }
  }, [sku, version, reloadForm]);

  const developerTabs: Array<TabData> = useMemo(
    () => [
      {
        name: 'Versions',
        content: <BundlesTable />,
      },
      {
        name: 'Pricing',
        hideElement: !isPricingVisible,
        content: (
          <Pricing
            latestVersion={latestVersion}
            setCanPublishVersion={setCanPublishVersion}
            setMissingItemsForPublishing={setMissingItemsForPublishing}
          />
        ),
      },
      {
        name: 'Cert Testers',
        hideElement: !isRedApp || !cmCertConfigCreated,
        content: <CertTesters />,
      },
      {
        name: 'Prod Testers',
        hideElement: hideProdTesters,
        content: <ProdTesters />,
      },
      {
        name: 'Red App Details',
        icon: (
          <>
            <span className="spark-mar-r-1" />
            <Icon name="tools" />
          </>
        ),
        hideElement: !isSabreAdminRole() && !isSecondLevelSupportRole(),
        content: <RedAppDetails />,
      },
      {
        name: 'Proposal Data',
        hideElement: !proposalData,
        content: <ProposalData />,
      },
      {
        name: 'Audit Logs',
        icon: <SupportIcon />,
        hideElement: !isSabreEmployeeRole(),
        content: (
          <AuditLogs
            auditLogs={auditLogs}
            reloadForm={() => setReloadForm(true)}
            objectType={ObjectType.Product}
            id={sku}
          />
        ),
      },
    ],
    [isPricingVisible, auditLogs, proposalData]
  );

  const fetchAuditLogs = (id: string, objectType: GetAuditsObjectTypeEnum): Promise<AuditLog[]> => {
    return new AuditApi(
      new AuditConfiguration({
        basePath: productApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).getAudits({objectType, id});
  };

  const pricingName = 'Pricing';

  const generateHeaders = () => (
    <TabList>
      {developerTabs
        .filter(tab => !tab.hideElement)
        .map(({name, icon}) =>
          name === pricingName && !isPricingVisible
            ? undefined
            : name && (
                <TabListItem
                  key={name}
                  data-name={name}
                  onClick={() => navigate(convertToURL(name), {state: {version}})}
                >
                  <TabName activeTabName={activeTabName} tabName={name} icon={icon} />
                </TabListItem>
              )
        )}
    </TabList>
  );

  return (
    <>
      {isLoading || reloadForm ? (
        <LoadingContainer>
          <Loading label={formatMessage({id: 'common.data.loading'})} />
        </LoadingContainer>
      ) : (
        <Tabs className="spark-panel spark-mar-b-2">
          <div className="spark-panel__content">{generateHeaders()}</div>
          <div className="spark-pad-l-2 spark-pad-r-2 spark-pad-b-1">
            <Routes>
              {developerTabs
                .filter(tab => !tab.hideElement)
                .map(tab =>
                  tab.name === pricingName && !isPricingVisible
                    ? undefined
                    : tab && <Route key={tab.name} element={tab.content} path={convertToURL(tab.name)} />
                )}
            </Routes>
          </div>
        </Tabs>
      )}
    </>
  );
};

export const convertToURL = (str: string) => str.toLowerCase().replaceAll(' ', '');

export const Tabs = styled.div`
  min-height: 470px;
  position: relative;
  border: none;
`;

export const TabList = styled.ul`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

export const TabListItem = styled.li`
  list-style-type: none;
  margin: 0;
  min-width: 10rem;
  text-align: center;
  @media (min-width: ${deviceWidths.xs}px) {
    float: left;
  }
`;

export default ProductTabsContainer;
