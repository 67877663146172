const header = {
  'header.viewNavigation': 'View Navigation',
  'header.hideNavigation': 'Hide Navigation',
  'header.expand': 'Expand',
  'header.expandOrCollapse': 'Expand or Collapse Menu',
  'header.sabreCentral': 'Sabre Central',
  'header.marketPlace': 'Marketplace',
  'header.partnerNetwork': 'Developer Partner Network',
  'header.adminCentre': 'Admin Centre',
  'header.expandMenu.myProfile': 'My Profile',
  'header.expandMenu.notifications': 'Notifications',
  'header.expandMenu.password': 'Change My Password',
  'authentication.SignOut': 'Sign Out',
  'header.expandMenu.ariaDescription': 'accountMenu',
  'header.infoMessage.ariaLabel': 'DISMISS',
};

export default header;
