/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SellerInfo
 */
export interface SellerInfo {
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    scCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerInfo
     */
    billingCountryCode?: string;
}

/**
 * Check if a given object implements the SellerInfo interface.
 */
export function instanceOfSellerInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SellerInfoFromJSON(json: any): SellerInfo {
    return SellerInfoFromJSONTyped(json, false);
}

export function SellerInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SellerInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'scCode': !exists(json, 'scCode') ? undefined : json['scCode'],
        'billingCountryCode': !exists(json, 'billingCountryCode') ? undefined : json['billingCountryCode'],
    };
}

export function SellerInfoToJSON(value?: SellerInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'scCode': value.scCode,
        'billingCountryCode': value.billingCountryCode,
    };
}

