import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import {Loading} from '@scm/components';
import {HeaderContainer} from '@scm/product-components/pages/productDetails/actionForms/NewBundleForm';
import {HeaderText} from '@scm/admin-centre/src/components/App';
import styled from 'styled-components';
import {Configuration, NotificationApi, NotificationTemplateResponse} from '../../../generated/notification';
import {notificationApiBaseLink} from '../assets/apiBaseLink';
import {getAccessToken} from '../../../utils/authentication';
import {Button, Table, TableCell, TableRow} from '@sabre/spark-react-core';
import colors from '@scm/components/assets/colors';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {useNavigate} from 'react-router-dom';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

export const fetchNotificationTemplates = async () =>
  new NotificationApi(
    new Configuration({
      basePath: notificationApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).getNotificationTemplates();

const ManageNotificationsList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const {formatMessage} = useIntl();
  const [notificationTemplates, setNotificationTemplates] = useState<NotificationTemplateResponse[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchNotificationTemplates()
      .then(templates =>
        templates.sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return 0;
        })
      )
      .then(templates => setNotificationTemplates(templates))
      .finally(() => setIsLoading(false));
  }, []);

  return isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <div className="spark-pad-2">
      <HeaderContainer className="spark-flex spark-mar-b-2 spark-pad-r-1">
        <HeaderText>
          <FormattedMessage id="notificationSettings.title" />
        </HeaderText>
      </HeaderContainer>
      <ManageNotificationsContainer className="spark-panel spark-pad-2">
        <Table className="spark-table">
          {notificationTemplates.map(template => (
            <TableRowContainer key={template.id}>
              <TableCell>
                <div>{template.name}</div>
                <SmallText>{template.subject}</SmallText>
              </TableCell>
              <ButtonsContainer>
                <TableCell className="spark-pad-r-1 spark-pad-l-1">
                  <IconButtonContainer
                    icon="spark-icon-pencil spark-icon--fill"
                    onClick={(_): void => {
                      navigate(`${InternalUrls.ManageNotifications}/${template.id}`);
                    }}
                    ariaLabel="Pencil icon"
                    tooltip={formatMessage({id: 'common.edit'})}
                    tooltipDirectionX={TooltipDirectionX.RIGHT}
                    tooltipDirectionY={TooltipDirectionY.BOTTOM}
                  />
                </TableCell>
                <TableCell>
                  <IconButtonContainer
                    isNegative
                    icon="spark-icon-trash spark-icon--fill"
                    ariaLabel="Trash icon - not developed yet"
                    tooltip={formatMessage({id: 'manageNotifications.popup.notDeveloped'})}
                    tooltipDirectionX={TooltipDirectionX.RIGHT}
                    tooltipDirectionY={TooltipDirectionY.BOTTOM}
                  />
                </TableCell>
              </ButtonsContainer>
            </TableRowContainer>
          ))}
        </Table>
      </ManageNotificationsContainer>
    </div>
  );
};

const SmallText = styled.div`
  font-size: 0.8em;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const IconButtonContainer = styled(Button)<{
  isNegative?: boolean;
}>`
  background-color: ${props => (props.isNegative ? colors.red : colors.sparkBlue)} !important;
  padding: 0 1rem 0 1rem !important;
  font-size: 14px !important;
  color: ${colors.white} !important;
`;

const TableRowContainer = styled(TableRow)`
  &&:hover {
    background-color: ${colors.grey50};
  }
`;

export const ManageNotificationsContainer = styled.div`
  min-height: 50vh;
`;

export default ManageNotificationsList;
