const support = {
  'support.version': 'Version',
  'support.submitted': 'Submitted',
  'support.lastUpdated': 'Last Updated',
  'support.status': 'Status',
  'support.compatibility': 'Compatibility',
  'support.proposals': 'Proposals',
  'support.proposals.error.title': "Can't get proposals",
  'support.proposals.error.description': 'Please try again later',
};

export default support;
