/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyInformation } from './CompanyInformation';
import {
    CompanyInformationFromJSON,
    CompanyInformationFromJSONTyped,
    CompanyInformationToJSON,
} from './CompanyInformation';
import type { ContactDetails } from './ContactDetails';
import {
    ContactDetailsFromJSON,
    ContactDetailsFromJSONTyped,
    ContactDetailsToJSON,
} from './ContactDetails';
import type { OnboardingType } from './OnboardingType';
import {
    OnboardingTypeFromJSON,
    OnboardingTypeFromJSONTyped,
    OnboardingTypeToJSON,
} from './OnboardingType';
import type { SabreAccountInfo } from './SabreAccountInfo';
import {
    SabreAccountInfoFromJSON,
    SabreAccountInfoFromJSONTyped,
    SabreAccountInfoToJSON,
} from './SabreAccountInfo';
import type { SabreCustomerAccountInfo } from './SabreCustomerAccountInfo';
import {
    SabreCustomerAccountInfoFromJSON,
    SabreCustomerAccountInfoFromJSONTyped,
    SabreCustomerAccountInfoToJSON,
} from './SabreCustomerAccountInfo';

/**
 * 
 * @export
 * @interface AcceptTermsAndConditionsRequest
 */
export interface AcceptTermsAndConditionsRequest {
    /**
     * 
     * @type {ContactDetails}
     * @memberof AcceptTermsAndConditionsRequest
     */
    contactDetails: ContactDetails;
    /**
     * 
     * @type {CompanyInformation}
     * @memberof AcceptTermsAndConditionsRequest
     */
    companyInformation: CompanyInformation;
    /**
     * 
     * @type {SabreAccountInfo}
     * @memberof AcceptTermsAndConditionsRequest
     */
    sabreAccountInfo?: SabreAccountInfo;
    /**
     * 
     * @type {SabreCustomerAccountInfo}
     * @memberof AcceptTermsAndConditionsRequest
     */
    sabreCustomerAccountInfo?: SabreCustomerAccountInfo;
    /**
     * 
     * @type {OnboardingType}
     * @memberof AcceptTermsAndConditionsRequest
     */
    type?: OnboardingType;
}

/**
 * Check if a given object implements the AcceptTermsAndConditionsRequest interface.
 */
export function instanceOfAcceptTermsAndConditionsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactDetails" in value;
    isInstance = isInstance && "companyInformation" in value;

    return isInstance;
}

export function AcceptTermsAndConditionsRequestFromJSON(json: any): AcceptTermsAndConditionsRequest {
    return AcceptTermsAndConditionsRequestFromJSONTyped(json, false);
}

export function AcceptTermsAndConditionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptTermsAndConditionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactDetails': ContactDetailsFromJSON(json['contactDetails']),
        'companyInformation': CompanyInformationFromJSON(json['companyInformation']),
        'sabreAccountInfo': !exists(json, 'sabreAccountInfo') ? undefined : SabreAccountInfoFromJSON(json['sabreAccountInfo']),
        'sabreCustomerAccountInfo': !exists(json, 'sabreCustomerAccountInfo') ? undefined : SabreCustomerAccountInfoFromJSON(json['sabreCustomerAccountInfo']),
        'type': !exists(json, 'type') ? undefined : OnboardingTypeFromJSON(json['type']),
    };
}

export function AcceptTermsAndConditionsRequestToJSON(value?: AcceptTermsAndConditionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactDetails': ContactDetailsToJSON(value.contactDetails),
        'companyInformation': CompanyInformationToJSON(value.companyInformation),
        'sabreAccountInfo': SabreAccountInfoToJSON(value.sabreAccountInfo),
        'sabreCustomerAccountInfo': SabreCustomerAccountInfoToJSON(value.sabreCustomerAccountInfo),
        'type': OnboardingTypeToJSON(value.type),
    };
}

