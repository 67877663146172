import {RowData} from '../pages/productDetails/productTabs/tabs/pricing/PricingValues';

export abstract class PricingValidator {
  private static ADDITIONAL_INFO_MIN_LENGTH = 10;

  public static isPricingChecked = (pcc: boolean, transaction: boolean) => pcc || transaction;

  public static isAdditionalInfoCorrect = (transaction: boolean, pccNegotiable?: boolean, additionalInfo?: string) =>
    !((transaction || pccNegotiable) && (!additionalInfo || additionalInfo.length < this.ADDITIONAL_INFO_MIN_LENGTH));

  public static isPricingLevelCorrect = (pcc: boolean, pricingLevel?: string) => !pcc || pricingLevel !== undefined;

  public static isBillingPeriodCorrect = (pcc: boolean, pricingLevel?: string, selectedFeeRecurrence?: string) =>
    !pcc || !pricingLevel || selectedFeeRecurrence;

  public static isPriceDefinitionCorrect = (pricingType: boolean, negotiable?: boolean, type?: string) =>
    !pricingType || negotiable || type;

  public static isFixedPriceCorrect = (pricingList: Array<RowData>, type?: string) => {
    const priceNumber = +pricingList[0].price;
    const minimumPriceValue = 0;
    return !type?.toLowerCase().includes('fixed') || (!isNaN(priceNumber) && priceNumber > minimumPriceValue);
  };

  public static isRangePriceCorrect = (pricingList: Array<RowData>, type?: string) => {
    const minimumPriceValue = 0;
    if (type && type.toLowerCase().includes('ranges')) {
      const hasIncorrectPrice = pricingList.filter((row, index, list) => {
        const priceNumber = +row.price;
        const toNumber = +row.to;

        const checkIfIncorrectNumber = (num: number) => isNaN(num) || num <= minimumPriceValue;
        const checkIfIncorrectPrice = (num: number) =>
          checkIfIncorrectNumber(num) || (index > 0 && num >= parseFloat(list[index - 1].price));
        const checkIfIncorrectTo = (num: number) =>
          index < list.length - 1 && (checkIfIncorrectNumber(num) || num <= parseFloat(list[index].from));

        const incorrectPriceField = checkIfIncorrectPrice(priceNumber);
        const incorrectToField = checkIfIncorrectTo(toNumber);

        return incorrectPriceField || incorrectToField;
      }).length;

      return !hasIncorrectPrice;
    }

    return true;
  };
}
