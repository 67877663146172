import Modal from '@sabre/spark-react-core/modal';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {SellerDeveloper} from '../../../../generated/management';
import styled from 'styled-components';
import EditForm from '../../../assign-developer/EditForm';

const EditDeveloperModal = ({
  sellerId,
  sellerDeveloper,
  open,
  handleClose,
}: {
  sellerId: string;
  sellerDeveloper: SellerDeveloper;
  open: boolean;
  handleClose: () => void;
}) => {
  const {formatMessage} = useIntl();

  return (
    <Modal
      onClose={handleClose}
      open={open}
      title={formatMessage({id: 'editDeveloper.companyLevel.title'})}
      titleHeadingLevel={2}
    >
      <Description>
        <FormattedMessage id="assignSeller.companyLevel.description" />
      </Description>
      <EditForm sellerId={sellerId} sellerDeveloper={sellerDeveloper} handleClose={handleClose} />
    </Modal>
  );
};

const Description = styled.p`
  font-weight: 500;
  margin-top: 2rem;
`;

export default EditDeveloperModal;
