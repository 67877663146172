import React from 'react';
import {Developer as DeveloperValues} from '../../../generated/management';
import styled from 'styled-components';
import {Popover} from '@sabre/spark-react-core';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import colors from '@scm/components/assets/colors';
import ProductLevelActionMenu from '../../table/action-menu/product-level/ProductLevelActionMenu';

const Developer = ({developer, sku, productName}: {developer: DeveloperValues; sku: string; productName: string}) => (
  <DeveloperItem>
    <DeveloperInfoWrapper>
      <NameSpan className="spark-mar-r-2 spark-bold">{`${developer.firstName} ${developer.lastName}`}</NameSpan>
      <SpanWrapper>
        <span className="spark-mar-r-2 spark-pad-r-2">{developer.pcc}</span>
        <span className="spark-mar-r-2 spark-pad-r-2">{developer.epr}</span>
        <span className="spark-mar-r-2 spark-pad-r-2">{developer.email}</span>
      </SpanWrapper>
    </DeveloperInfoWrapper>
    <div>
      <Popover
        anchorX={TooltipDirectionX.LEFT}
        anchorY={TooltipDirectionY.MIDDLE}
        toggleEl={
          <MenuIcon
            className="spark-icon-menu-ellipsis-vertical spark-icon--fill spark-icon--sm"
            aria-label="Expand action menu"
            role="button"
            tabIndex={0}
          />
        }
      >
        <ProductLevelActionMenu developer={developer} sku={sku} productName={productName} />
      </Popover>
    </div>
  </DeveloperItem>
);

const NameSpan = styled.span`
  min-width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const SpanWrapper = styled.div`
  margin-left: 3rem;
  display: flex;
  justify-content: space-between;

  & span {
    padding: 0 1rem;
  }
`;

const DeveloperInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.08rem !important;
  margin-bottom: 0;
  margin-left: 1.5rem;
`;

const DeveloperItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;

  &:not(:first-of-type) {
    border-top: 1px solid ${colors.grey200};
    padding-top: 2rem;
  }
`;

const MenuIcon = styled.i`
  ::before {
    font-size: 1.5rem !important;
  }

  margin-right: 2rem;
  cursor: pointer;

  :hover,
  :focus {
    outline: 2px solid ${colors.grey200};
    outline-offset: 10px;
  }
`;

export default Developer;
