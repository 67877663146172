import {
  allRegionsName,
  connectToGdsName,
  countryCodesName,
  currentStepName,
  descriptionName,
  flowDiagramName,
  formTitleId,
  interactsWithEndTravelerName,
  isPaidName,
  ProposalValues,
  providePnrDocumentationName,
  providerName,
  redAppCategoryName,
  redAppName,
  regionCodesName,
  serviceAndSecurityValuesName,
  technologiesName,
} from './ProposalValues';
import {serviceInitialValues} from './steps/serviceAndSecurity/components/servicesComponents/serviceConstants';
import {securityInitialValues} from './steps/serviceAndSecurity/components/securityComponents/securityConstants';

export const initialValues: ProposalValues = {
  [providerName]: '',
  [formTitleId]: 'proposal.title',
  [currentStepName]: 1,
  [redAppName]: '',
  [descriptionName]: '',
  [redAppCategoryName]: [],
  [flowDiagramName]: [],
  [interactsWithEndTravelerName]: false,
  [providePnrDocumentationName]: false,
  [connectToGdsName]: false,
  [technologiesName]: ['Typescript'],
  [regionCodesName]: [],
  [countryCodesName]: [],
  [allRegionsName]: true,
  [isPaidName]: false,
  [serviceAndSecurityValuesName]: {...serviceInitialValues, ...securityInitialValues},
};
