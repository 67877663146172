import React, {ReactElement, useContext} from 'react';
import {useIntl} from 'react-intl';
import {ProposalContext} from '../../../../ProposalForm';
import {
  dataForwardedThirdPartyName,
  dataProtectionName,
  financialInformationName,
  personalInformationName,
  protocolName,
  thirdPartyNetworkName,
} from '../../ServiceAndSecurityValues';
import {Checkbox} from '@sabre/spark-react-core';
import {Controller} from 'react-hook-form';
import {TextArea} from '../../../definitionPage/definitionComponents/Description';
import {createNestedObjectName} from '../servicesComponents/Services';
import {serviceAndSecurityValuesName} from '../../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';

const ThirdPartyNetwork = () => {
  const {formatMessage} = useIntl();
  const {control, watch, errors, setValue} = useContext(ProposalContext);
  const descriptionMaxLength = 250;

  return (
    <>
      <Controller
        name={createNestedObjectName(thirdPartyNetworkName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (value) {
                setValue(createNestedObjectName(dataForwardedThirdPartyName), '', {shouldValidate: true});
                setValue(createNestedObjectName(protocolName), '', {shouldValidate: true});
                if (
                  !watch(createNestedObjectName(financialInformationName)) &&
                  !watch(createNestedObjectName(personalInformationName)) &&
                  !watch(createNestedObjectName(thirdPartyNetworkName))
                ) {
                  setValue(createNestedObjectName(dataProtectionName), '', {shouldValidate: true});
                }
              }
            }}
            checked={value}
            label={formatMessage({id: 'serviceAndSecurity.thirdPartyNetwork.label'})}
            className="spark-mar-b-0"
          />
        )}
      />
      {watch(createNestedObjectName(thirdPartyNetworkName)) && (
        <div className="spark-pad-l-2">
          <Controller
            name={createNestedObjectName(dataForwardedThirdPartyName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.dataForwardedThirdParty.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                status={
                  errors[serviceAndSecurityValuesName]?.[dataForwardedThirdPartyName] ? MessageStatus.ERROR : undefined
                }
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[dataForwardedThirdPartyName] &&
                  errors[serviceAndSecurityValuesName]?.[dataForwardedThirdPartyName]?.message
                }
              />
            )}
          />
          <Controller
            name={createNestedObjectName(protocolName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.protocol.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                status={errors[serviceAndSecurityValuesName]?.[protocolName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[protocolName] &&
                  errors[serviceAndSecurityValuesName]?.[protocolName]?.message
                }
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default ThirdPartyNetwork;
