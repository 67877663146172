const tabs = {
  'tabs.testers.description':
    'Provide up to 50 customers who will test your Red App on PRODUCTION environment. To successfully assign a tester, please make sure that tester\'s EPR is active. You can assign only testers from the same SC Code as yours. To assign testers from other SC Codes, please contact our support: <a href="mailto:scm-support@sabre.com">scm-support@sabre.com</a>.',
  'tabs.certtesters.description':
    'Provide up to 50 customers who will test your Red App on CERTIFICATION environment. To successfully assign a tester, please make sure that tester\'s EPR is active. You can assign only testers from the same SC Code as yours. To assign testers from other SC Codes, please contact our support: <a href="mailto:scm-support@sabre.com">scm-support@sabre.com</a>. Please be aware that only testers added via the new Admin Centre will be listed below.',
  'tabs.testers.version.label': 'CM CERT Version',
  'tabs.testers.addButton.label': '+ Add New Tester',
  'tabs.testers.addButton.tooltip': 'You have reached max number of beta customers',
  'tabs.regionalization.header': 'Regionalization',
  'tabs.regionalization.description': 'Provide regions or/and countries where your Red App will be available:',
  'tabs.regionalization.search.description': 'Search Regions & Countries',
  'tabs.regionalization.select.header': 'Select R&C',
  'tabs.regionalization.select.checkAllLabel': 'All Regions & Countries',
  'tabs.regionalization.noData': 'You must choose at least one item.',
  'tabs.regionalization.globalRegionalizationLabel':
    'My Red App access should be limited to specific countries or/and regions',
  'tabs.proposalData.header': 'Proposal Data',
  'tabs.proposalData.logs': 'Change Log',
  'tabs.proposalData.approval': 'Proposal Approval',
  'tabs.proposalData.review': 'In Review',
  'tabs.proposalData.accepted': 'Accepted',
  'tabs.proposalData.rejected': 'Rejected',
  'tabs.proposalData.obsolete': 'Obsolete',
  'tabs.proposalData.definition': 'Definition',
  'tabs.proposalData.regionalization': 'Regionalization',
  'tabs.proposalData.pricing': 'Pricing',
  'tabs.proposalData.services': 'Services and Technical Details',
  'tabs.proposalData.security': 'Security',
  'tabs.proposalData.status': 'Proposal Status:',
  'tabs.proposalData.submittedBy': 'Submitted By:',
  'tabs.proposalData.date': 'Date',
  'tabs.proposalData.changed': 'Changed By',
  'tabs.proposalData.error.title': 'Error',
  'tabs.proposalData.error.description': 'Proposal not found',
  'tabs.proposalData.appliedStatus': 'Applied Status',
  'tabs.bundle.svsErrors.openButton': 'Validation Errors',
  'tabs.bundle.compatibilityForm.openButton': 'Choose Red App Compatibility',
  'tabs.bundle.compatibilityForm.releaseNotes': 'Change Patch Release Notes',
  'tabs.bundle.compatibilityForm.title': 'Choose Red App Compatibility',
  'tabs.bundle.compatibilityForm.error.title': 'Something went wrong.',
  'tabs.bundle.compatibilityForm.description':
    "Select whether the version should be available on Sabre Red 360 (Desktop) and/or Sabre Red Web. If the 'Web' option is not available, this means that the Red App does not meet Sabre Red Web requirements.",
  'tabs.bundle.compatibilityForm.error.description': 'Please try again later.',
  'tabs.bundle.compatibilityForm.success.title': 'Success.',
  'tabs.bundle.compatibilityForm.success.description': 'Compatibility has been changed.',
  'tabs.releaseNotes.patchReleaseNotesForm.title': 'Change Patch Release Notes',
  'tabs.bundle.svsErrorsModal.title': 'Validation Errors',
  'tabs.releaseNotes.patchReleaseNotesForm.error.maxLength': 'This field cannot have more than {max} characters.',
  'tabs.releaseNotes.patchReleaseNotesForm.error.title': 'Validation error',
  'tabs.releaseNotes.patchReleaseNotesForm.description':
    "Please provide a brief description of the changes introduced in this version. This will be displayed in the 'What's New' tab in the Sabre Central Marketplace product view.",
  'tabs.bundle.simplifiedDescription.openButton': 'Change Sabre Red 360 Description',
  'tabs.bundle.cleanupDates.openButton': 'Cleanup Dates',
  'tabs.simplifiedDescription.changeSimplifiedDescriptionForm.title': 'Change Sabre Red 360 Description',
  'tabs.simplifiedDescription.description':
    'This description will be used in the Sabre Red 360 Agency Admin Tool only.',
  'tabs.cleanupDatesModal.title': 'Version {versionId} Cleanup Dates',
  'tabs.versionFilesDetails.title': 'Version Files Details - {versionId}',
  'tabs.versionFilesDetails.description': 'You can download the files provided to create this version',
  'tabs.versionFilesDetails.modal.submittedAt': 'Submitted at',
  'tabs.versionFilesDetails.modal.submittedBy': 'Submitted by',
  'tabs.versionFilesDetails.modal.hash': 'Bundle hash',
  'tabs.versionFilesDetails.modal.notDefined': 'Not defined',
  'tabs.versionFilesDetails.modal.notCalculated': 'Not calculated',
  'tabs.pricing.badge':
    'Pricing of this Red App has already been set and cannot be changed. In critical cases, contact scm-support@sabre.com, but requests for price changes and their legitimacy will be considered on a case-by-case basis.',
  'tabs.pricing.submitButton': 'Add Pricing',
  'tabs.pricing.deleteButton': 'Delete Pricing',
  'tabs.pricing.perUser.title': 'PRICING PER PCC/EPR',
  'tabs.pricing.perUser.description': 'A monetary charge for customers who order your Red App',
  'tabs.pricing.perTransaction.title': 'PRICING PER TRANSACTION',
  'tabs.pricing.perTransaction.description': 'Fee for transactions generated by Red App users',
  'tabs.pricing.level.label': 'PRICING LEVEL',
  'tabs.pricing.level.pcc': 'PCC level',
  'tabs.pricing.level.epr': 'EPR level',
  'tabs.pricing.level.fee': 'Fee Recurrence',
  'tabs.pricing.level.fee.ariaLabel': 'select fee recurrence',
  'tabs.pricing.pricingLevel.epr': 'EPRs',
  'tabs.pricing.pricingLevel.pcc': 'PCCs',
  'tabs.pricing.view.header': '{billingPeriod} Fee per {pricingLevel}',
  'tabs.pricing.view.transaction.header': 'Fee per transaction',
  'tabs.pricing.view.range': '{rangeFrom}-{rangeTo}',
  'tabs.price.definition.title': 'PRICE DEFINITION',
  'tabs.price.definition.fromLabel': 'FROM',
  'tabs.price.definition.toLabel': 'TO',
  'tabs.price.definition.priceLabel': 'PRICE (USD)',
  'tabs.price.value': 'USD',
  'tabs.price.definition.description': 'The price for my Red App should be Negotiable',
  'tabs.price.definition.negotiable': 'Negotiable',
  'tabs.price.definition.wrongPriceValueMessage': 'The price entered must be lower than in the earlier range',
  'tabs.price.definition.wrongToValueMessage': 'The TO value entered must be higher than the FROM value',
  'tabs.price.additionalInfo.title': 'ADDITIONAL PRICE INFORMATION',
  'tabs.price.additionalInfo.description':
    'If you have selected "Negotiable" or "Pricing per Transaction" please provide more details below. You can also provide additional information if you want Agents to see it on the Marketplace Red App page.',
  'tabs.price.additionalInfo.textInput.label': 'Additional Information',
  'tabs.price.additionalInfo.textInput.placeholder': 'Put additional information here.',
  'tabs.specialPricing.title': 'SPECIAL PRICING',
  'tabs.specialPricing.description':
    'If you wish to define special pricing for a particular country or SC Code, please write to scm-support@sabre.com with your request.',
  'tabs.pricing.negotiable.label': 'Negotiable',
  'tabs.pricing.transaction.label': 'Transaction',
  'tabs.bundle.versionFilesDetails.openButton': 'Version Files Details',
};

export default tabs;
