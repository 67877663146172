import {CreatePfKeysRequest, FileSource, NameConvention} from '@scm/pf-keys/generated/product';
import {fileSourceName, pfKeyFileName, pfKeysFilesNames, PfKeysValues} from '@scm/pf-keys/provider/pfKeysValues';
import {Tester} from '@scm/product-components/pages/productDetails/productTabs/testers/ProdTesters';
import {
  FileNameType as ProposalFileNameType,
  FileSource as ProposalFileSource,
} from '@scm/proposal/pages/proposalForm/ProposalValues';

export const pfKeysMapper = (data: PfKeysValues, name: string, betaTesters: Array<Tester>): CreatePfKeysRequest => {
  const request: CreatePfKeysRequest = {
    pfKeys: {
      name: name.trim(),
      fileSource: data[fileSourceName] === ProposalFileSource.Local ? FileSource.Local : FileSource.SabreRedServer,
      nameConvention:
        data[pfKeyFileName] === ProposalFileNameType['Red App Name']
          ? NameConvention.RedAppName
          : NameConvention.OriginalFileName,
      betaTesters: betaTesters.map(tester => ({pcc: tester.pcc, epr: tester.sabreId})),
    },
  };

  if (data[pfKeysFilesNames].length) {
    request.pfKeysFiles = data[pfKeysFilesNames];
  }

  return request;
};
