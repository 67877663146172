import MediaQuery from 'react-responsive';
import {minDesktopWidth} from 'assets/deviceWidth';
import PartnerCard from 'pages/partnerDetails/card/PartnerCard';
import PartnerDescription from 'pages/partnerDetails/PartnerDescription';
import ProductsCarousel from 'pages/partnerDetails/products/ProductsCarousel';
import PartnerTestimonials from 'pages/partnerDetails/PartnerTestimonials';
import React from 'react';
import styled from 'styled-components';
import {Maybe, Partner} from 'generated/graphql';

const PartnerDetailsDesktop = ({partner}: {partner: Maybe<Partner> | undefined}) =>
  !partner ? (
    <div />
  ) : (
    <MediaQuery minDeviceWidth={minDesktopWidth}>
      <MainRow>
        <PartnerCard partner={partner} />
        <PartnerDescription
          name={partner.name}
          longDescription={partner.longDescription}
          email={partner.contact.email}
        />
      </MainRow>
      {partner.products && partner.products.length > 0 && (
        <ProductsCarousel products={partner.products} name={partner.name} />
      )}
      {partner.testimonials && partner.testimonials.length > 0 && (
        <TestimonialsRow>
          <PartnerTestimonials partner={partner} />
        </TestimonialsRow>
      )}
    </MediaQuery>
  );

const MainRow = styled.div.attrs(() => ({
  className: 'spark-flex',
}))`
  padding: 2rem 4rem 4rem 4rem;
  justify-content: center;
`;

const TestimonialsRow = styled.div`
  padding: 0 11rem;
`;

export default PartnerDetailsDesktop;
