import React, {useState} from 'react';
import styled from 'styled-components';
import ProductCarouselItem from 'pages/partnerDetails/products/ProductCarouselItem';
import ProductsHeader from 'pages/partnerDetails/products/ProductsHeader';
import {Maybe, Product} from 'generated/graphql';
import ItemsCarousel from 'react-items-carousel';
import {CarouselChevron} from '../../home/highlighted/Highlighted';
import colors from '@scm/components/assets/colors';

const ProductsCarousel = ({products, name}: {products: Maybe<Array<Maybe<Product>>>; name?: Maybe<string>}) => {
  const productTiles = products?.map((product, index) => (
    <ProductCarouselItem
      key={index}
      name={product!.name!}
      sku={product!.sku!}
      shortDescription={product!.description!}
      logoUrl={product!.logoUrl!}
    />
  ));
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidthPx = 50;
  const numberOfCards = 3;
  const gutterPx = 20;
  const isCarousel = (productTiles || []).length > numberOfCards;

  return (
    <ProductsContainer isCarousel={isCarousel} chevronWidthPx={chevronWidthPx}>
      <ProductsHeader partnerName={name!} />
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        numberOfCards={numberOfCards}
        gutter={gutterPx}
        activeItemIndex={activeItemIndex}
        leftChevron={<CarouselChevron className="spark-icon spark-icon-arrow-chevron-left" aria-label="go left" />}
        rightChevron={<CarouselChevron className="spark-icon spark-icon-arrow-chevron-right" aria-label="go right" />}
        outsideChevron
        chevronWidth={chevronWidthPx}
      >
        {productTiles}
      </ItemsCarousel>
    </ProductsContainer>
  );
};

const ProductsContainer = styled.div<{isCarousel: boolean; chevronWidthPx: number}>`
  background-color: ${colors.white};
  padding: 2rem ${props => props.chevronWidthPx}px;

  ${props =>
    props.isCarousel
      ? ''
      : `
    & .spark-carousel__panel {
      margin-left: auto;
      margin-right: auto;
    }

    & [style] {
    display: flex;
    justify-content: center;
  }
  `}
`;

export default ProductsCarousel;
