export const roadmap = {
  'roadmap.title': 'SCM Admin Centre Features Roadmap',
  'roadmap.label.title': 'Vote on what we should build in the Admin Centre!',
  'roadmap.label.description':
    'You can see what we are currently working on and have planned. Have your say on what matters most to you by voting for existing feature ideas and sharing ideas in the comments.',
  'roadmap.form.status': 'Roadmap Status',
  'roadmap.form.subject': 'Subject',
  'roadmap.form.description': 'Description',
  'roadmap.form.releaseDate': 'Release Date',
  'roadmap.form.roadmapQuestion': 'Release date on roadmap?',
  'roadmap.form.provideDescription': 'Provide description for your roadmap.',
  'roadmap.error.error': 'Error while loading roadmaps. Please, reload page.',
  'roadmap.error.errorTitle': 'Error',
  'roadmap.item.releaseDate': 'Release Date:',
  'roadmap.item.targetReleaseDate': 'Target Release Date:',
  'roadmap.tab.all': 'All',
  'roadmap.tab.needFeedback': 'Needs Feedback',
  'roadmap.tab.inProgress': 'In Progress',
  'roadmap.tab.complete': 'Complete',
  'roadmap.tab.sortBy.mostVoted': 'Most Voted',
  'roadmap.tab.sortBy.mostRecent': 'Most Recent',
  'roadmap.modal.add.title': 'Add New Roadmap',
  'roadmap.modal.add.description': 'Add a new roadmap item to the list',
  'roadmap.modal.delete.title': 'Delete Roadmap',
  'roadmap.modal.delete.description': `Are you sure you want to delete <strong>{subject}</strong> from roadmap?`,
  'roadmap.modal.successTitle': 'Success',
  'roadmap.modal.delete.success': 'Roadmap item has been deleted successfully.',
  'roadmap.modal.edit.success': 'Roadmap has been saved successfully.',
  'roadmap.modal.delete.error': 'Error while deleting roadmap item. Please, try again.',
  'roadmap.modal.error': 'Something went wrong. Please, try again.',
  'roadmap.modal.errorTitle': 'Error',
  'roadmap.modal.edit.title': 'Edit Roadmap',
  'roadmap.modal.edit.description': 'Edit this roadmap item',
  'roadmap.modal.view.title': 'Roadmap Votes',
  'roadmap.modal.votes.content.voted': 'voted',
  'roadmap.modal.votes.content.on': 'on',
  'roadmap.model.votes.error.title': 'Error while loading votes.',
  'roadmap.model.votes.error.description': 'Please, reload page.',
  'roadmap.modal.votes.content.noVotes': 'No votes yet.',
  'roadmap.options.description': 'Options',
};
