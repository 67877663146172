import React from 'react';
import styled from 'styled-components';
import {Partner} from 'generated/graphql';
import {useMediaQuery} from 'react-responsive';
import SingleRowItem from './SingleRowItem';
import MultiRowItem from './MultiRowItem';
import MultiRowLinkItem from 'pages/partnerDetails/card/MultiRowLinkItem';
import AddressSection from 'pages/partnerDetails/card/AddressSection';
import Logo from 'components/Logo';
import NewPartnerBadge from 'components/styledComponents/NewPartnerBadge';
import PartnerTierLabel from 'components/PartnerTierLabel';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';
import {mapRegions} from 'utils/regionNameMapping';

const PartnerCard = ({
  partner: {
    name,
    contact: {contactName, email, website},
    tier,
    address,
    logoUrl,
    additionalComments,
    approvalDate,
    regions,
    categories,
  },
}: {
  partner: Partner;
}) => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const emails = email ? email.split(',') : [];
  const approvalDateFormatted = approvalDate
    ? new Date(approvalDate).toLocaleString('en-US', {month: 'long', year: 'numeric'})
    : '';

  const ofNullable = (input: string | null | undefined) => input || '';
  const prepareUrl = (url: string) => (url.startsWith('http') ? url : `http://${url}`);

  return (
    <PartnerCardContainer mobile={isMobile}>
      <div className="spark-panel__content">
        <NewPartnerBadge approvalDate={approvalDate} />
        <PartnerCardLogo
          logo={logoUrl || ''}
          name={ofNullable(name)}
          containerHeight="100%"
          className="spark-mar-b-1"
        />
        {tier && <PartnerTierLabel className="spark-mar-b-1" tier={tier} />}
        <div className="spark-mar-b-1">
          <SingleRowItem messageId="partnerPage.card.companyName" className="spark-mar-b-.5" value={ofNullable(name)} />
          <SingleRowItem
            messageId="partnerPage.card.contactName"
            className="spark-mar-b-.5"
            value={ofNullable(contactName)}
          />
          <MultiRowLinkItem
            messageId={emails.length > 1 ? 'partnerPage.card.emails' : 'partnerPage.card.email'}
            className="spark-mar-b-.5"
            values={emails}
            mailTo
          />
          <MultiRowLinkItem
            messageId="partnerPage.card.website"
            className="spark-mar-b-.5"
            values={[prepareUrl(ofNullable(website))]}
          />
        </div>
        <AddressSection address={address} />
        <MultiRowItem className="spark-mar-b-1" messageId="partnerPage.card.offerings" values={categories!} />
        <MultiRowItem
          className="spark-mar-b-1"
          messageId="partnerPage.card.availability"
          values={mapRegions(regions!)}
        />
        {additionalComments && (
          <MultiRowItem
            className="spark-mar-b-1"
            messageId="partnerPage.card.additionalComments"
            values={[additionalComments!]}
          />
        )}
        {approvalDateFormatted && (
          <SingleRowItem messageId="partnerPage.card.approvalDate" value={approvalDateFormatted!} />
        )}
      </div>
    </PartnerCardContainer>
  );
};

const PartnerCardContainer = styled.div.attrs(() => ({
  className: 'spark-panel spark-panel--card spark-mar-b-1',
}))<{mobile?: boolean}>`
  margin: ${props => (props.mobile ? '0 0 1rem 0' : '0 3rem 0 0')};
  min-width: 32rem;
`;

const PartnerCardLogo = styled(Logo)`
  max-width: 100%;
`;

export default PartnerCard;
