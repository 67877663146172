import React, {SetStateAction} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {Button} from '@sabre/spark-react-core';
import Modal from '@sabre/spark-react-core/modal';
import {Configuration, Result, ResultResultEnum, VersionsApi} from '@scm/product-components/generated/certification';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

async function postData(id: string, result: Result) {
  return await new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).submitCertificationResult({
    id,
    result,
  });
}

const CertificationTestResultModal = ({
  open,
  setOpen,
  id,
  action,
  result,
  setLoading,
  setLoadingProductDetails,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  id: string;
  action: string;
  result?: ResultResultEnum;
  setLoading: (flag: boolean) => void;
  setLoadingProductDetails: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const handleClose = (): void => setOpen(false);
  const {formatMessage} = useIntl();
  const productDetailsName = 'productDetails.fetch';

  const actionButtonHandler = async () => {
    setLoading(true);
    await postData(id, {result})
      .then(() =>
        openToast(
          createMessageString(formatMessage, productDetailsName, true),
          createMessageString(formatMessage, productDetailsName),
          ToastType.POSITIVE,
          'spark-icon-check'
        )
      )
      .catch(err => {
        err.response.json().catch(() => {});
        openToast(
          createMessageString(formatMessage, productDetailsName, true, true),
          createMessageString(formatMessage, productDetailsName, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      })
      .finally(() => {
        setLoadingProductDetails(true);
        setLoading(false);
      });

    handleClose();
  };

  return (
    <Modal onClose={handleClose} open={open} title={formatMessage({id: `certify.certification.${action}Modal.title`})}>
      <p>
        <FormattedMessage id={`certify.certification.${action}Modal.message`} />
      </p>
      <div className="spark-btn-group">
        <Button onClick={handleClose} className="spark-btn-group-secondary" size={ButtonSize.SMALL} secondary>
          <FormattedMessage id="common.cancelButton" />
        </Button>
        <Button className="spark-btn-group-primary" size={ButtonSize.SMALL} onClick={actionButtonHandler}>
          <FormattedMessage id={`certify.certification.${action}`} />
        </Button>
      </div>
    </Modal>
  );
};

export default CertificationTestResultModal;
