import Tooltip from '@sabre/spark-react-core/tooltip';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import styled from 'styled-components';
import useAddRoleToTooltip from '@scm/components/accessibility/hooks/useAddRoleToTooltip';

const StandardTooltip = ({
  text,
  isForSearchBox,
  children,
  hiddenTooltip,
}: {
  text: string;
  isForSearchBox?: boolean;
  children: JSX.Element;
  hiddenTooltip?: boolean;
}) => {
  const tooltipRef = useAddRoleToTooltip();

  return (
    <AlignedTooltip
      anchorX={TooltipDirectionX.CENTER}
      anchorY={TooltipDirectionY.BOTTOM}
      toggleEl={children}
      className="spark-mar-b-1"
      isForSearchBox={isForSearchBox}
      ref={tooltipRef}
      hiddenTooltip={hiddenTooltip}
    >
      {text}
    </AlignedTooltip>
  );
};

const AlignedTooltip = styled(Tooltip)<{isForSearchBox: boolean; hiddenTooltip: boolean}>`
  transform: translateY(${props => (props.isForSearchBox ? '-3rem' : '-1.4rem')});
  max-width: 23rem;
  white-space: break-spaces;
  line-height: 120%;
  text-align: center;
  ${props => (props.hiddenTooltip ? 'display: none' : '')}
`;

export default StandardTooltip;
