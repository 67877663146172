import {appInfoInitialValues} from './appInfoConstants';
import {regionalizationInitialValues} from './regionalizationConstants';
import {bundleInitialValues} from './bundleConstants';
import {pricingConstants} from './pricingConstants';
import StorefrontDataValues from '../interfaces/StorefrontDataValues';

export const storefrontDataInitialValues: StorefrontDataValues = {
  ...appInfoInitialValues,
  ...regionalizationInitialValues,
  ...bundleInitialValues,
  ...pricingConstants,
  textEditorLengthError: '',
};
