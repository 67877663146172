import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import React from 'react';
import Icon from '../../../components/icon/Icon';
import useGetProductC from '../../../hooks/useGetProductC';
import AppType from '../../../components/app-type/AppType';
import {ExternalLink} from '@scm/components';
import {config} from '@scm/authentication/config/config';
import {FormattedMessage} from 'react-intl';
import {CcrzEProductCSObjectCcrzProductStatusCEnum} from '../../../generated/product';
import {useParams} from 'react-router-dom';
import colors from '@scm/components/assets/colors';

const marketplaceProductUrl = '/marketplace/samProductDetails?sku=';
const NameAndIcon = ({viewOnMarketplaceLink}: {viewOnMarketplaceLink: string}) => {
  const {name, ccrzProductStatusC} = useGetProductC();
  const productNameValue = name ?? '';
  const productStatus = ccrzProductStatusC ?? '';
  const sku = useParams().appName;

  return (
    <IconContainer>
      <Icon />
      <Text className="spark-flex">
        <h1>{productNameValue}</h1>
        <p>
          <AppType />
          {(productStatus === CcrzEProductCSObjectCcrzProductStatusCEnum.Released ||
            productStatus === CcrzEProductCSObjectCcrzProductStatusCEnum.InCreation) && (
            <>
              <Separator />
              <ExternalLink to={`${config.marketplace.url}${marketplaceProductUrl}${sku}&checkLogin=true`}>
                <LinkText>
                  <FormattedMessage id={viewOnMarketplaceLink} />
                </LinkText>
              </ExternalLink>
            </>
          )}
        </p>
      </Text>
    </IconContainer>
  );
};

const IconContainer = styled.div`
  display: flex;
  height: 80px;
  @media (max-width: ${deviceWidths.xs}px) {
    transform: scale(0.8) translateX(-4rem);
  }
`;

const Text = styled.div`
  padding: 0.5rem;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  & h1 {
    margin-bottom: 0;
  }
`;

export const Separator = styled.span`
  &::after {
    content: '|';
    display: inline-block;
    margin: 0 0.7rem;
  }
`;

export const LinkText = styled.span`
  color: ${colors.sparkBlue};
`;

export default NameAndIcon;
