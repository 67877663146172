import {PricingLevel} from './Pricing';

export const pricingLevelName = 'pricingLevel';
export const perPccepr = 'perPccepr';
export const perPcceprType = 'perPcceprType';
export const perTransaction = 'perTransaction';
export const perTransactionType = 'perTransactionType';
export const rowsListPccepr = 'rowsListPccepr';
export const rowsListTransaction = 'rowsListTransaction';
export const additionalInfoName = 'additionalInfo';
export const selectedFeeRecurrenceName = 'selectedFeeRecurrence';

export interface RowData {
  from: string;
  to: string;
  price: string;
  disabledToValue: boolean;
}

export interface PricingValues {
  [pricingLevelName]?: PricingLevel;
  [perPccepr]: boolean;
  [perPcceprType]?: string;
  [perTransaction]: boolean;
  [perTransactionType]?: string;
  [rowsListPccepr]: Array<RowData>;
  [rowsListTransaction]: Array<RowData>;
  [additionalInfoName]?: string;
  [selectedFeeRecurrenceName]?: string;
}
