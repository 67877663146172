/* tslint:disable */
/* eslint-disable */
/**
 * Certification API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Environment = {
    Cert: 'CERT',
    Prod: 'PROD'
} as const;
export type Environment = typeof Environment[keyof typeof Environment];


export function EnvironmentFromJSON(json: any): Environment {
    return EnvironmentFromJSONTyped(json, false);
}

export function EnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Environment {
    return json as Environment;
}

export function EnvironmentToJSON(value?: Environment | null): any {
    return value as any;
}

