import React, {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {MultiSelect, MultiSelectMainProps} from '@scm/components/form/multiSelect/MultiSelect';
import {UseFormReturn} from 'react-hook-form';
import {ActionBadges, ExpandPanel} from '@scm/components';
import styled from 'styled-components';
import {RowsGroup} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/ProposalViewDataTab';
import {
  Label,
  Row,
} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {SvsValues} from '../SvsValues';

enum TaskExceptionsTypes {
  bundlesize = 'bundlesize',
  bytecode = 'bytecode',
  bytecodelogging = 'bytecodelogging',
  contactdetails = 'contactdetails',
  deprecatedapi = 'deprecatedapi',
  files = 'files',
  'entries-deprecated-oldextp' = 'entries-deprecated-oldextp',
  manifest = 'manifest',
  publishing = 'publishing',
  redapp = 'redapp',
  preferences = 'preferences',
  trustedsign = 'trustedsign',
  shachecksum = 'shachecksum',
  signaturealgorithmsconsistency = 'signaturealgorithmsconsistency',
  signing = 'signing',
  uniqueness = 'uniqueness',
  'webmodule-base-validation' = 'webmodule-base-validation',
  'webmodule-preparation' = 'webmodule-preparation',
  'webmodule-ts-annotation-validation' = 'webmodule-ts-annotation-validation',
  'webmodule-work-dir-validation' = 'webmodule-work-dir-validation',
}

const TaskExceptions = ({formMethods}: {formMethods: UseFormReturn<SvsValues>}) => {
  const multiSelectSize = 7;
  const taskExceptionsName = 'tasks';
  const {watch, setValue} = formMethods;
  const options = useMemo(
    () => Object.values(TaskExceptionsTypes).reduce((result, value) => Object.assign(result, {[value]: value}), {}),
    []
  );
  const multiSelectMainProps: MultiSelectMainProps = {
    firstLevelName: taskExceptionsName,
    setValue: setValue,
    watch: watch,
    options,
  };

  const {formatMessage} = useIntl();
  const tasksExceptionsDeleteHandler = (name: string) => {
    setValue(
      taskExceptionsName,
      watch(taskExceptionsName).filter((value: string) => value !== name)
    );
  };
  return (
    <RowsGroup className="spark-mar-b-2">
      <Row className="spark-mar-l-2">
        <Label className="spark-bold">
          <FormattedMessage id="svs.taskExceptions.label" />
        </Label>
        <TaskExceptionsContainer className="spark-mar-l-2">
          <ExpandPanel panelHeader={formatMessage({id: 'svs.taskExceptions.selectTasks.label'})}>
            <MultiSelect multiSelectMainProps={multiSelectMainProps} size={multiSelectSize} />
          </ExpandPanel>
          <ActionBadges
            badgeNames={watch(taskExceptionsName)}
            deleteHandler={tasksExceptionsDeleteHandler}
            className="spark-pad-l-0 spark-mar-t-2"
          />
        </TaskExceptionsContainer>
      </Row>
    </RowsGroup>
  );
};

const TaskExceptionsContainer = styled.div`
  & .spark-panel--expand {
    width: 100%;
  }
`;

export default TaskExceptions;
