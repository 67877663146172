import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import Status from './Status';
import AppData from './AppData';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import UserAction from './UserAction';

const BasicInfo = () => {
  return (
    <AppInfoWrapper className="spark-pad-l-2 spark-pad-r-2 ">
      <Status />
      <ProductInfo className="spark-flex spark-pad-1 spark-pad-t-1 spark-pad-b-.5">
        <AppData />
        <UserAction />
      </ProductInfo>
    </AppInfoWrapper>
  );
};

export const AppInfoWrapper = styled.div`
  font-weight: 400;

  & .toast-email-gray > strong {
    color: ${colors.statusInfoGrey} !important;
  }
`;

export const ProductInfo = styled.div`
  background-color: ${colors.grey100};
  justify-content: space-between;
  @media (max-width: ${deviceWidths.sm}px) {
    flex-direction: column;
    & > div {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
`;

export default BasicInfo;
