const validation = {
  'validation.fieldTooShort': 'Please provide at least {value, plural, one {# character} other {# characters}}',
  'validation.fieldTooLong': 'Please provide at most {value, plural, one {# character} other {# characters}}',
  'validation.fieldRequired': 'This field is required',
  'validation.fieldInvalid': 'Invalid value',
  'validation.fieldTooShort.firstName': 'Names are not that short',
  'validation.invalidEmail': 'Please provide valid email',
  'validation.invalidWebsite': 'Please provide valid url',
  'validation.fieldDoesNotMatchPattern': 'Provided value does not match required pattern {value}',
  'validation.fieldDoesNotMatchPattern.email': 'Invalid email format. Please use your company email address.',
  'validation.fieldDoesNotMatchPattern.website': 'Invalid url format. Please provide valid url.',
  'validation.fieldDoesNotMatchPattern.phoneNumber': 'Invalid format. Please insert the international dialling code.',
  'validation.fieldDoesNotMatchPattern.contactDetails.email': 'Please use your company email address.',
  'validation.fieldDoesNotMatchPattern.contactDetails.phone':
    'Invalid format. Please insert the international dialling code.',
  'validation.fieldDoesNotMatchPattern.sabreCustomerAccountInfo.pcc':
    'Invalid format. Please insert proper PCC number.',
  'validation.fieldDoesNotMatchPattern.sabreAccountInfo.pcc': 'Invalid format. Please insert proper PCC number.',
};

export default validation;
