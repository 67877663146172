import React, {ReactElement, useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Controller} from 'react-hook-form';
import {ProposalContext} from '../../../ProposalForm';
import {Checkbox} from '@sabre/spark-react-core';
import {connectToGdsDescriptionName, connectToGdsName} from '../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {TextArea} from './Description';

const ConnectGds = () => {
  const {formatMessage} = useIntl();
  const {watch, control, errors, setValue} = useContext(ProposalContext);
  const gdsDescriptionMaxLength = 250;

  return (
    <>
      <p className="spark-mar-t-2 spark-mar-b-1">
        <FormattedMessage id="definition.detailInformation.Gds.mainLabel" />
      </p>
      <Controller
        name={connectToGdsName}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (value) {
                setValue(connectToGdsDescriptionName, '', {shouldValidate: true});
              }
            }}
            checked={value}
            label={formatMessage({id: 'definition.detailInformation.Gds.label'})}
          />
        )}
      />
      {watch(connectToGdsName) && (
        <div className="spark-mar-b-2 spark-mar-r-1">
          <Controller
            name={connectToGdsDescriptionName}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'definition.detailInformationDescription.Gds.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                multiLine
                characterCount
                maxLength={gdsDescriptionMaxLength}
                status={errors[field.name] ? MessageStatus.ERROR : undefined}
                statusMessage={errors[field.name] && errors[field.name]?.message}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default ConnectGds;
