import React from 'react';
import {Product} from 'generated/graphql';
import ExternalLink from 'components/ExternalLink';
import ExternalUrls from '@scm/components/utils/externalUrls';
import Logo from 'components/Logo';
import parse from 'html-react-parser';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import colors from '@scm/components/assets/colors';

const ProductMobileItem = ({product: {sku, name, logoUrl, description}}: {product: Product}) => (
  <Content>
    <Header>
      <MobileLogo logo={logoUrl || ''} name={name!} containerHeight="5vh" containerMargin="0" />
      {name}
    </Header>
    <div>{parse(description || '')}</div>
    <ViewProduct
      to={`${ExternalUrls.ProductDetails}?sku=${sku}`}
      ariaLabel={name!}
      className="spark-btn spark-btn--sm spark-btn--secondary"
    >
      <FormattedMessage id="partner.products.viewProduct" />
    </ViewProduct>
  </Content>
);

const Content = styled.div`
  color: ${colors.grey700};
  font-weight: 300;

  border: 1px solid ${colors.grey200};

  max-height: 27vh;
  padding: 1.5rem;
  margin: 1rem 0;
`;

const Header = styled.div.attrs(() => ({
  className: 'spark-flex spark-align-items-center spark-delta spark-mar-b-1 spark-mar-t-1 spark-bold',
}))`
  color: ${colors.grey700};
`;

const MobileLogo = styled(Logo)`
  max-height: 5vh;
  margin: 0 1rem 0 0;
`;

const ViewProduct = styled(ExternalLink)`
  margin-top: 1rem;
`;

export default ProductMobileItem;
