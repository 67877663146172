import React from 'react';
import {FormattedMessage} from 'react-intl';
import PartnerCardLabel from 'pages/partnerDetails/card/PartnerCardLabel';

const SingleRowItem = ({
  messageId,
  value,
  className,
  href,
}: {
  messageId: string;
  value: string;
  className?: string;
  href?: string;
}) => (
  <div className={className}>
    <PartnerCardLabel className="spark-mar-r-.5 spark-inline-block">
      <FormattedMessage id={messageId} />
    </PartnerCardLabel>
    {href ? (
      <a className="spark-link" href={href}>
        {value}
      </a>
    ) : (
      <div className="spark-inline-block">{value}</div>
    )}
  </div>
);

export default SingleRowItem;
