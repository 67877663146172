import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {Button} from '@sabre/spark-react-core';
import Modal from '@sabre/spark-react-core/modal';
import colors from '@scm/components/assets/colors';
import styled from 'styled-components';
import {Configuration as ProductConfiguration, ProductApi} from '../../../generated/product';
import {apiBaseLink} from '../../../assets/apiBaseLink';

const obsoleteProduct = async (redAppId: string) =>
  new ProductApi(
    new ProductConfiguration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() ?? '',
    })
  ).obsoleteProduct({
    sku: redAppId,
  });

const MakeObsoleteProductModal = ({
  open,
  setOpen,
  sku,
  setDisableButton,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  sku: string;
  setDisableButton: (disable: boolean) => void;
}) => {
  const handleClose = (): void => setOpen(false);
  const {formatMessage} = useIntl();
  const toastMakeObsoleteProductId = 'sunset.makeProductObsolete';

  const clickHandlerMakeProductObsolete = () =>
    obsoleteProduct(sku)
      .then(() => {
        setDisableButton(true);
        openToast(
          createMessageString(formatMessage, toastMakeObsoleteProductId, true, false),
          createMessageString(formatMessage, toastMakeObsoleteProductId, false, false),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      })
      .catch(() =>
        openToast(
          createMessageString(formatMessage, toastMakeObsoleteProductId, true, true),
          createMessageString(formatMessage, toastMakeObsoleteProductId, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      )
      .finally(() => {
        handleClose();
        document.body.classList.remove('spark-modal-open');
      });

  return (
    <Modal onClose={handleClose} open={open} title={formatMessage({id: 'sunset.makeProductObsolete.modal.title'})}>
      <FormattedMessage id="sunset.makeProductObsolete.modal.description" />
      <div className="spark-btn-group spark-mar-t-2">
        <CancelButton className="spark-btn--secondary" onClick={handleClose} size={ButtonSize.SMALL}>
          <FormattedMessage id="common.button.cancel" />
        </CancelButton>
        <Button
          progress
          className="spark-btn-group-primary"
          style={{backgroundColor: colors.sparkGreen}}
          size={ButtonSize.SMALL}
          onClick={clickHandlerMakeProductObsolete}
          data-testid="makeProductObsoleteConfirmButton"
        >
          <FormattedMessage id="common.button.confirm" />
        </Button>
      </div>
    </Modal>
  );
};

const CancelButton = styled(Button)`
  background-color: ${colors.white} !important;
  border: 1px solid ${colors.sparkGreen} !important;
`;

export default MakeObsoleteProductModal;
