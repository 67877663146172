import React from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

export const BreadcrumbLink = ({text, intlId, href}: {text?: string; intlId?: string; href?: string}) => {
  const name = intlId ? <FormattedMessage id={intlId} /> : text;
  return href ? (
    <Link className={'spark-link'} to={href}>
      {name}
    </Link>
  ) : (
    <span>{name}</span>
  );
};
