const redAppDetails = {
  'redAppDetails.certCmVersion': 'Cert CM Version',
  'redAppDetails.productType': 'Product Type',
  'redAppDetails.productType.label': 'Product Type',
  'redAppDetails.provisioningType': 'Provisioning Type',
  'redAppDetails.provisioningType.label': 'Provisioning Type',
  'redAppDetails.pricingType': 'Pricing Type',
  'redAppDetails.hideProduct': 'Hide Product on Storefront',
  'redAppDetails.blockOrdering': 'Block Ordering',
  'redAppDetails.blockOrderingDescription': 'Block Ordering Description',
  'redAppDetails.mainPluginId': 'Main Plugin Id',
  'redAppDetails.mainPluginId.label': 'Main Plugin Id',
  'redAppDetails.mainPluginId.placeholder': 'Main Plugin Id',
  'redAppDetails.certCmVersion.description':
    'In case of any issues with adding CERT testers or removal of CERT roles, verify the role name in CM. The name should be aligned below to match the Product/Version name in CERT CM.',
  'redAppDetails.separator.storefrontVisibility': 'Storefront Visibility',
  'redAppDetails.message.advancedMaintenance':
    '[ADVANCED MAINTENANCE] This section should be used only for critical cases - PLEASE USE IT WITH CAUTION',
  'redAppDetails.separator.primaryConfiguration': 'Primary Configuration',
};

export default redAppDetails;
