import accessibility from './accessibility';

const common = {
  'common.carousel.pauseCarousel': 'Pause Carousel',
  'common.carousel.previousImage': 'Previous Image',
  'common.carousel.nextImage': 'Next Image',
  'common.carousel.moveBack': 'Move carousel back',
  'common.carousel.moveForward': 'Move carousel forward',
  'common.data.loading': 'Loading...',
  'common.data.fetchingFilters': 'Fetching filters...',
  'common.data.fetchingPartners': 'Fetching partners...',
  'common.data.fetchingPartnerDetails': 'Fetching partner details...',
  'common.breadcrumb.myProducts': 'My Products',
  'common.upload.error': 'Upload failed',
  'common.upload.error.maxFiles': 'Max limit of files for this field is {limit}',
  'common.upload.error.incorrectFileType': 'Incorrect file type.',
  'common.upload.error.incorrectFileSize': 'The uploaded file size is too large.',
  'common.upload.general': 'File upload',
  'common.upload.general.compression.error': "Error during file compression, file won't be uploaded",
  'common.upload.description': 'Drag & drop file here to upload or',
  'common.upload.button': 'Browse file',
  'common.button.close': 'Close',
  'common.button.confirm': 'Confirm',
  'common.button.cancel': 'Cancel',
  'common.button.remove': 'Remove',
  'common.sabre': 'Sabre',
  'common.accept': 'Accept',
  'common.reset': 'Reset',
  'common.new': 'New',
  'common.cookiesConsent':
    'We use cookies to improve performance and user experience of our website. Continue browsing as normal or click “Accept” to consent to the storage of cookies on your device. To learn more about the cookies we use, please visit our <externalLink>privacy policy</externalLink>.',
  'common.proceedButton': 'Proceed',
  'common.previousButton': 'Previous',
  'common.nextButton': 'Next',
  'common.submitButton': 'Submit',
  'common.findButton': 'Find',
  'common.cancelButton': 'Cancel',
  'common.createButton': 'Create',
  'common.continueButton': 'Continue',
  'common.backButton': 'Back',
  'common.manage': 'Manage',
  'common.download': 'Download ',
  'common.agree': 'Agree',
  'common.disagree': 'Disagree',
  'common.na': 'N/A',
  'common.save': 'Save',
  'common.assign': 'Assign',
  'common.preview': 'Preview',
  'common.saveAll': 'Save All',
  'common.delete': 'Delete',
  'common.edit': 'Edit',
  'common.add': 'Add',
  'common.text.remaining': 'Remaining: {count}',
  'common.text.long': 'Length limit is exceeded',
  'common.text.required': 'This field must have at least {count} characters.',
  'common.text.comingSoon': 'coming soon',
  'common.plus': '+',
  'common.minus': '-',
  'common.success': 'Success.',
  'common.error': 'Error.',
  'common.error.default': 'An error occurred. Please try again.',
  ...accessibility,
};

export default common;
