/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyAddress } from './CompanyAddress';
import {
    CompanyAddressFromJSON,
    CompanyAddressFromJSONTyped,
    CompanyAddressToJSON,
} from './CompanyAddress';
import type { CompanyYearsInBusinessType } from './CompanyYearsInBusinessType';
import {
    CompanyYearsInBusinessTypeFromJSON,
    CompanyYearsInBusinessTypeFromJSONTyped,
    CompanyYearsInBusinessTypeToJSON,
} from './CompanyYearsInBusinessType';
import type { NumberOfEmployeesType } from './NumberOfEmployeesType';
import {
    NumberOfEmployeesTypeFromJSON,
    NumberOfEmployeesTypeFromJSONTyped,
    NumberOfEmployeesTypeToJSON,
} from './NumberOfEmployeesType';

/**
 * 
 * @export
 * @interface CompanyInformation
 */
export interface CompanyInformation {
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    name: string;
    /**
     * 
     * @type {CompanyAddress}
     * @memberof CompanyInformation
     */
    address: CompanyAddress;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    businessProfile: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    website?: string;
    /**
     * 
     * @type {NumberOfEmployeesType}
     * @memberof CompanyInformation
     */
    numberOfEmployees?: NumberOfEmployeesType | null;
    /**
     * 
     * @type {CompanyYearsInBusinessType}
     * @memberof CompanyInformation
     */
    yearsInBusiness?: CompanyYearsInBusinessType | null;
    /**
     * Country as a two-letter, ISO 3166 code.
     * @type {string}
     * @memberof CompanyInformation
     */
    locationCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    locationProvince?: string;
}

/**
 * Check if a given object implements the CompanyInformation interface.
 */
export function instanceOfCompanyInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "businessProfile" in value;

    return isInstance;
}

export function CompanyInformationFromJSON(json: any): CompanyInformation {
    return CompanyInformationFromJSONTyped(json, false);
}

export function CompanyInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address': CompanyAddressFromJSON(json['address']),
        'businessProfile': json['businessProfile'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'numberOfEmployees': !exists(json, 'numberOfEmployees') ? undefined : NumberOfEmployeesTypeFromJSON(json['numberOfEmployees']),
        'yearsInBusiness': !exists(json, 'yearsInBusiness') ? undefined : CompanyYearsInBusinessTypeFromJSON(json['yearsInBusiness']),
        'locationCountry': !exists(json, 'locationCountry') ? undefined : json['locationCountry'],
        'locationProvince': !exists(json, 'locationProvince') ? undefined : json['locationProvince'],
    };
}

export function CompanyInformationToJSON(value?: CompanyInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': CompanyAddressToJSON(value.address),
        'businessProfile': value.businessProfile,
        'website': value.website,
        'numberOfEmployees': NumberOfEmployeesTypeToJSON(value.numberOfEmployees),
        'yearsInBusiness': CompanyYearsInBusinessTypeToJSON(value.yearsInBusiness),
        'locationCountry': value.locationCountry,
        'locationProvince': value.locationProvince,
    };
}

