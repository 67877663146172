import {FormattedMessage, useIntl} from 'react-intl';
import React, {useContext} from 'react';
import {RegionalizationContext} from '../../../../forms/RegionalizationContent';
import {Checkbox, CheckboxGroup} from '@sabre/spark-react-core';
import {regions} from '@scm/components/assets/regionsAndCountries';
import styled from 'styled-components';
import useSetRegionValue, {GLOBAL} from './useSetRegionValue';
import Legend from '@scm/components/accessibility/Legend';
import useGetIsObsolete from '../../../../hooks/useGetIsObsolete';

const regionsValues = Object.assign({Global: GLOBAL}, regions);
const Regions = () => {
  const isObsolete = useGetIsObsolete();
  const {regions} = useContext(RegionalizationContext);
  const setRegionValue = useSetRegionValue();
  const isGlobal = regions.includes(GLOBAL);
  const {formatMessage} = useIntl();

  return (
    <>
      <p className="spark-mar-b-1 spark-caps spark-bold">
        <FormattedMessage id="productDetails.regionalization.regions.header" />
      </p>
      <RegionsContainer name="checkbox" value={regions} onChange={(_, activeItems) => setRegionValue(activeItems)}>
        <Legend text={formatMessage({id: 'productDetails.regionalization.regions.header'})} />
        {Object.entries(regionsValues).map(([value, label]) =>
          isGlobal && label !== GLOBAL ? null : (
            <Checkbox label={label} value={value} key={label} className="spark-mar-r-2" disabled={isObsolete} />
          )
        )}
      </RegionsContainer>
    </>
  );
};

const RegionsContainer = styled(CheckboxGroup)`
  display: flex;
  flex-wrap: wrap;
`;

export default Regions;
