/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CcrzEPageLabelCSObject } from './CcrzEPageLabelCSObject';
import {
    CcrzEPageLabelCSObjectFromJSON,
    CcrzEPageLabelCSObjectFromJSONTyped,
    CcrzEPageLabelCSObjectToJSON,
} from './CcrzEPageLabelCSObject';

/**
 * 
 * @export
 * @interface CcrzETermCSObject
 */
export interface CcrzETermCSObject {
    /**
     * 
     * @type {string}
     * @memberof CcrzETermCSObject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzETermCSObject
     */
    ccrzTitleC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzETermCSObject
     */
    cCProductC?: string;
    /**
     * Defines which version of 'Terms and Conditions' is already active and displayed in marketplace. If option is marked, other versions assigned to the same product will be deactivated automatically.
     * @type {boolean}
     * @memberof CcrzETermCSObject
     */
    activeC?: boolean;
    /**
     * 
     * @type {CcrzEPageLabelCSObject}
     * @memberof CcrzETermCSObject
     */
    termsAndConditionsR?: CcrzEPageLabelCSObject;
}

/**
 * Check if a given object implements the CcrzETermCSObject interface.
 */
export function instanceOfCcrzETermCSObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CcrzETermCSObjectFromJSON(json: any): CcrzETermCSObject {
    return CcrzETermCSObjectFromJSONTyped(json, false);
}

export function CcrzETermCSObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CcrzETermCSObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'ccrzTitleC': !exists(json, 'ccrz__Title__c') ? undefined : json['ccrz__Title__c'],
        'cCProductC': !exists(json, 'CC_Product__c') ? undefined : json['CC_Product__c'],
        'activeC': !exists(json, 'Active__c') ? undefined : json['Active__c'],
        'termsAndConditionsR': !exists(json, 'Terms_And_Conditions__r') ? undefined : CcrzEPageLabelCSObjectFromJSON(json['Terms_And_Conditions__r']),
    };
}

export function CcrzETermCSObjectToJSON(value?: CcrzETermCSObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ccrz__Title__c': value.ccrzTitleC,
        'CC_Product__c': value.cCProductC,
        'Active__c': value.activeC,
        'Terms_And_Conditions__r': CcrzEPageLabelCSObjectToJSON(value.termsAndConditionsR),
    };
}

