import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {Button} from '@sabre/spark-react-core';
import Modal from '@sabre/spark-react-core/modal';
import colors from '@scm/components/assets/colors';
import styled from 'styled-components';
import {notificationsApiBaseLink} from '../../../assets/apiBaseLink';
import {
  ActiveOrdersNotificationType,
  Configuration as NotificationConfiguration,
  NotificationApi,
} from '../../../generated/notification';

const sendSunsetNotification = async (redAppId: string, subject: string, message: string) =>
  new NotificationApi(
    new NotificationConfiguration({
      basePath: notificationsApiBaseLink,
      accessToken: getAccessToken() ?? '',
    })
  ).sendNotificationToBuyersWithActiveOrders({
    sendNotificationToBuyersWithActiveOrdersRequest: {
      redAppId: redAppId,
      subject: subject,
      message: message,
      notificationType: ActiveOrdersNotificationType.Sunset,
    },
  });

const SunsetMessageModal = ({
  open,
  setOpen,
  redAppId,
  subject,
  message,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  redAppId: string;
  subject: string;
  message: string;
}) => {
  const handleClose = (): void => setOpen(false);
  const {formatMessage} = useIntl();
  const toastMessageSunsetId = 'sunset.sendMessageForm';

  const clickHandlerSendMessage = (success: () => void, failure: () => void) => {
    sendSunsetNotification(redAppId, subject, message)
      .then(() => {
        success();
        openToast(
          createMessageString(formatMessage, toastMessageSunsetId, true, false),
          createMessageString(formatMessage, toastMessageSunsetId, false, false),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      })
      .catch(() => {
        failure();
        openToast(
          createMessageString(formatMessage, toastMessageSunsetId, true, true),
          createMessageString(formatMessage, toastMessageSunsetId, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      })
      .finally(() => {
        handleClose();
        document.body.classList.remove('spark-modal-open');
      });
  };

  return (
    <Modal onClose={handleClose} open={open} title={formatMessage({id: 'sunset.sendMessage.modal.title'})}>
      <form onSubmit={e => e.preventDefault()}>
        <FormattedMessage id="sunset.sendMessage.modal.description" />
        <div className="spark-btn-group spark-mar-t-2">
          <CancelButton className="spark-btn--secondary" onClick={handleClose} size={ButtonSize.SMALL}>
            <FormattedMessage id="common.button.cancel" />
          </CancelButton>
          <Button
            progress
            className="spark-btn-group-primary"
            style={{backgroundColor: colors.sparkGreen}}
            size={ButtonSize.SMALL}
            onClick={async (_, success, failure) => {
              if (success && failure) {
                await clickHandlerSendMessage(success, failure);
              }
            }}
            data-testid="sendSunsetMessageConfirmButton"
          >
            <FormattedMessage id="common.button.confirm" />
          </Button>
        </div>
      </form>
    </Modal>
  );
};

const CancelButton = styled(Button)`
  background-color: ${colors.white} !important;
  border: 1px solid ${colors.sparkGreen} !important;
`;

export default SunsetMessageModal;
