import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};



export type Query = {
  __typename?: 'Query';
  getPartner: Partner;
  getPartnerAuth: Partner;
  getPartners: GetPartnersResult;
  getFilterOptions: FilterOptions;
};


export type QueryGetPartnerArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryGetPartnerAuthArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryGetPartnersArgs = {
  isFeatured?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  tiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  login: JwtAuthToken;
  refreshToken: JwtAuthToken;
};


export type MutationLoginArgs = {
  code?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  refreshToken?: Maybe<Scalars['String']>;
};

export type JwtAuthToken = {
  __typename?: 'JwtAuthToken';
  accessToken: Scalars['String'];
  expiresIn: Scalars['String'];
  tokenType: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
};

export type FilterOptions = {
  __typename?: 'FilterOptions';
  regions: Array<Scalars['String']>;
  categories: Array<Scalars['String']>;
  tiers: Array<Scalars['String']>;
};

export type Partner = {
  __typename?: 'Partner';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  rank?: Maybe<Scalars['Int']>;
  tier?: Maybe<Scalars['String']>;
  approvalDate?: Maybe<Scalars['Date']>;
  additionalComments?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<Product>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  testimonials?: Maybe<Array<Maybe<Testimonial>>>;
  contact: Contact;
  address: Address;
};

export type GetPartnersResult = {
  __typename?: 'GetPartnersResult';
  data: GetPartnersResultData;
  hasMore: Scalars['Boolean'];
};

export type GetPartnersResultData = {
  __typename?: 'GetPartnersResultData';
  partners: Array<Partner>;
  categories: Array<Scalars['String']>;
  regions: Array<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['ID']>;
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type Testimonial = {
  __typename?: 'Testimonial';
  review?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  contactName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  streetLine?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type OrderByInput = {
  rank?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Sort>;
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc'
}


export type GetAuthenticationTokensMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetAuthenticationTokensMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'JwtAuthToken' }
    & Pick<JwtAuthToken, 'accessToken' | 'refreshToken'>
  ) }
);

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken: (
    { __typename?: 'JwtAuthToken' }
    & Pick<JwtAuthToken, 'accessToken'>
  ) }
);

export type GetFeaturedPartnersQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
}>;


export type GetFeaturedPartnersQuery = (
  { __typename?: 'Query' }
  & { getPartners: (
    { __typename?: 'GetPartnersResult' }
    & Pick<GetPartnersResult, 'hasMore'>
    & { data: (
      { __typename?: 'GetPartnersResultData' }
      & { partners: Array<(
        { __typename?: 'Partner' }
        & Pick<Partner, 'id' | 'name' | 'logoUrl' | 'shortDescription'>
      )> }
    ) }
  ) }
);

export type GetFilterOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFilterOptionsQuery = (
  { __typename?: 'Query' }
  & { getFilterOptions: (
    { __typename?: 'FilterOptions' }
    & Pick<FilterOptions, 'regions' | 'categories' | 'tiers'>
  ) }
);

export type GetPartnerAuthQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetPartnerAuthQuery = (
  { __typename?: 'Query' }
  & { getPartnerAuth: (
    { __typename?: 'Partner' }
    & Pick<Partner, 'name' | 'logoUrl' | 'longDescription' | 'approvalDate' | 'additionalComments' | 'regions' | 'categories'>
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'name' | 'sku' | 'description' | 'logoUrl'>
    )>>>, testimonials?: Maybe<Array<Maybe<(
      { __typename?: 'Testimonial' }
      & Pick<Testimonial, 'review' | 'author'>
    )>>>, contact: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'contactName' | 'phoneNumber' | 'email' | 'website'>
    ), address: (
      { __typename?: 'Address' }
      & Pick<Address, 'streetLine' | 'city' | 'stateProvince' | 'postalCode' | 'country'>
    ) }
  ) }
);

export type GetPartnerDetailsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetPartnerDetailsQuery = (
  { __typename?: 'Query' }
  & { getPartner: (
    { __typename?: 'Partner' }
    & Pick<Partner, 'name' | 'logoUrl' | 'longDescription' | 'tier' | 'approvalDate' | 'additionalComments' | 'regions' | 'categories'>
    & { products?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'name' | 'sku' | 'description' | 'logoUrl'>
    )>>>, testimonials?: Maybe<Array<Maybe<(
      { __typename?: 'Testimonial' }
      & Pick<Testimonial, 'review' | 'author'>
    )>>>, contact: (
      { __typename?: 'Contact' }
      & Pick<Contact, 'contactName' | 'phoneNumber' | 'email' | 'website'>
    ), address: (
      { __typename?: 'Address' }
      & Pick<Address, 'streetLine' | 'city' | 'stateProvince' | 'postalCode' | 'country'>
    ) }
  ) }
);

export type GetPartnersQueryVariables = Exact<{
  isFeatured?: Maybe<Scalars['Boolean']>;
  searchTerm?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  tiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<OrderByInput>;
}>;


export type GetPartnersQuery = (
  { __typename?: 'Query' }
  & { getPartners: (
    { __typename?: 'GetPartnersResult' }
    & Pick<GetPartnersResult, 'hasMore'>
    & { data: (
      { __typename?: 'GetPartnersResultData' }
      & Pick<GetPartnersResultData, 'regions' | 'categories'>
      & { partners: Array<(
        { __typename?: 'Partner' }
        & Pick<Partner, 'id' | 'name' | 'logoUrl' | 'shortDescription' | 'tier' | 'approvalDate'>
      )> }
    ) }
  ) }
);


export const GetAuthenticationTokensDocument = gql`
    mutation GetAuthenticationTokens($code: String!) {
  login(code: $code) {
    accessToken
    refreshToken
  }
}
    `;
export type GetAuthenticationTokensMutationFn = Apollo.MutationFunction<GetAuthenticationTokensMutation, GetAuthenticationTokensMutationVariables>;

/**
 * __useGetAuthenticationTokensMutation__
 *
 * To run a mutation, you first call `useGetAuthenticationTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAuthenticationTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAuthenticationTokensMutation, { data, loading, error }] = useGetAuthenticationTokensMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetAuthenticationTokensMutation(baseOptions?: Apollo.MutationHookOptions<GetAuthenticationTokensMutation, GetAuthenticationTokensMutationVariables>) {
        return Apollo.useMutation<GetAuthenticationTokensMutation, GetAuthenticationTokensMutationVariables>(GetAuthenticationTokensDocument, baseOptions);
      }
export type GetAuthenticationTokensMutationHookResult = ReturnType<typeof useGetAuthenticationTokensMutation>;
export type GetAuthenticationTokensMutationResult = Apollo.MutationResult<GetAuthenticationTokensMutation>;
export type GetAuthenticationTokensMutationOptions = Apollo.BaseMutationOptions<GetAuthenticationTokensMutation, GetAuthenticationTokensMutationVariables>;
export const RefreshAccessTokenDocument = gql`
    mutation RefreshAccessToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    accessToken
  }
}
    `;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>) {
        return Apollo.useMutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(RefreshAccessTokenDocument, baseOptions);
      }
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;
export const GetFeaturedPartnersDocument = gql`
    query GetFeaturedPartners($take: Int = 10) {
  getPartners(isFeatured: true, take: $take) {
    hasMore
    data {
      partners {
        id
        name
        logoUrl
        shortDescription
      }
    }
  }
}
    `;

/**
 * __useGetFeaturedPartnersQuery__
 *
 * To run a query within a React component, call `useGetFeaturedPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedPartnersQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetFeaturedPartnersQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturedPartnersQuery, GetFeaturedPartnersQueryVariables>) {
        return Apollo.useQuery<GetFeaturedPartnersQuery, GetFeaturedPartnersQueryVariables>(GetFeaturedPartnersDocument, baseOptions);
      }
export function useGetFeaturedPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedPartnersQuery, GetFeaturedPartnersQueryVariables>) {
          return Apollo.useLazyQuery<GetFeaturedPartnersQuery, GetFeaturedPartnersQueryVariables>(GetFeaturedPartnersDocument, baseOptions);
        }
export type GetFeaturedPartnersQueryHookResult = ReturnType<typeof useGetFeaturedPartnersQuery>;
export type GetFeaturedPartnersLazyQueryHookResult = ReturnType<typeof useGetFeaturedPartnersLazyQuery>;
export type GetFeaturedPartnersQueryResult = Apollo.QueryResult<GetFeaturedPartnersQuery, GetFeaturedPartnersQueryVariables>;
export const GetFilterOptionsDocument = gql`
    query GetFilterOptions {
  getFilterOptions {
    regions
    categories
    tiers
  }
}
    `;

/**
 * __useGetFilterOptionsQuery__
 *
 * To run a query within a React component, call `useGetFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>) {
        return Apollo.useQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GetFilterOptionsDocument, baseOptions);
      }
export function useGetFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>) {
          return Apollo.useLazyQuery<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>(GetFilterOptionsDocument, baseOptions);
        }
export type GetFilterOptionsQueryHookResult = ReturnType<typeof useGetFilterOptionsQuery>;
export type GetFilterOptionsLazyQueryHookResult = ReturnType<typeof useGetFilterOptionsLazyQuery>;
export type GetFilterOptionsQueryResult = Apollo.QueryResult<GetFilterOptionsQuery, GetFilterOptionsQueryVariables>;
export const GetPartnerAuthDocument = gql`
    query GetPartnerAuth($name: String!) {
  getPartnerAuth(name: $name) {
    name
    logoUrl
    longDescription
    approvalDate
    additionalComments
    regions
    categories
    products {
      name
      sku
      description
      logoUrl
    }
    testimonials {
      review
      author
    }
    contact {
      contactName
      phoneNumber
      email
      website
    }
    address {
      streetLine
      city
      stateProvince
      postalCode
      country
    }
  }
}
    `;

/**
 * __useGetPartnerAuthQuery__
 *
 * To run a query within a React component, call `useGetPartnerAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerAuthQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetPartnerAuthQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerAuthQuery, GetPartnerAuthQueryVariables>) {
        return Apollo.useQuery<GetPartnerAuthQuery, GetPartnerAuthQueryVariables>(GetPartnerAuthDocument, baseOptions);
      }
export function useGetPartnerAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerAuthQuery, GetPartnerAuthQueryVariables>) {
          return Apollo.useLazyQuery<GetPartnerAuthQuery, GetPartnerAuthQueryVariables>(GetPartnerAuthDocument, baseOptions);
        }
export type GetPartnerAuthQueryHookResult = ReturnType<typeof useGetPartnerAuthQuery>;
export type GetPartnerAuthLazyQueryHookResult = ReturnType<typeof useGetPartnerAuthLazyQuery>;
export type GetPartnerAuthQueryResult = Apollo.QueryResult<GetPartnerAuthQuery, GetPartnerAuthQueryVariables>;
export const GetPartnerDetailsDocument = gql`
    query GetPartnerDetails($name: String!) {
  getPartner(name: $name) {
    name
    logoUrl
    longDescription
    tier
    approvalDate
    additionalComments
    regions
    categories
    products {
      name
      sku
      description
      logoUrl
    }
    testimonials {
      review
      author
    }
    contact {
      contactName
      phoneNumber
      email
      website
    }
    address {
      streetLine
      city
      stateProvince
      postalCode
      country
    }
  }
}
    `;

/**
 * __useGetPartnerDetailsQuery__
 *
 * To run a query within a React component, call `useGetPartnerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerDetailsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetPartnerDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnerDetailsQuery, GetPartnerDetailsQueryVariables>) {
        return Apollo.useQuery<GetPartnerDetailsQuery, GetPartnerDetailsQueryVariables>(GetPartnerDetailsDocument, baseOptions);
      }
export function useGetPartnerDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnerDetailsQuery, GetPartnerDetailsQueryVariables>) {
          return Apollo.useLazyQuery<GetPartnerDetailsQuery, GetPartnerDetailsQueryVariables>(GetPartnerDetailsDocument, baseOptions);
        }
export type GetPartnerDetailsQueryHookResult = ReturnType<typeof useGetPartnerDetailsQuery>;
export type GetPartnerDetailsLazyQueryHookResult = ReturnType<typeof useGetPartnerDetailsLazyQuery>;
export type GetPartnerDetailsQueryResult = Apollo.QueryResult<GetPartnerDetailsQuery, GetPartnerDetailsQueryVariables>;
export const GetPartnersDocument = gql`
    query GetPartners($isFeatured: Boolean, $searchTerm: String, $regions: [String], $categories: [String], $tiers: [String], $skip: Int, $take: Int, $orderBy: OrderByInput) {
  getPartners(isFeatured: $isFeatured, searchTerm: $searchTerm, regions: $regions, categories: $categories, tiers: $tiers, skip: $skip, take: $take, orderBy: $orderBy) {
    hasMore
    data {
      partners {
        id
        name
        logoUrl
        shortDescription
        tier
        approvalDate
      }
      regions
      categories
    }
  }
}
    `;

/**
 * __useGetPartnersQuery__
 *
 * To run a query within a React component, call `useGetPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnersQuery({
 *   variables: {
 *      isFeatured: // value for 'isFeatured'
 *      searchTerm: // value for 'searchTerm'
 *      regions: // value for 'regions'
 *      categories: // value for 'categories'
 *      tiers: // value for 'tiers'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPartnersQuery(baseOptions?: Apollo.QueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
        return Apollo.useQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, baseOptions);
      }
export function useGetPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPartnersQuery, GetPartnersQueryVariables>) {
          return Apollo.useLazyQuery<GetPartnersQuery, GetPartnersQueryVariables>(GetPartnersDocument, baseOptions);
        }
export type GetPartnersQueryHookResult = ReturnType<typeof useGetPartnersQuery>;
export type GetPartnersLazyQueryHookResult = ReturnType<typeof useGetPartnersLazyQuery>;
export type GetPartnersQueryResult = Apollo.QueryResult<GetPartnersQuery, GetPartnersQueryVariables>;