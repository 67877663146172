import {config} from '../../config/config';

interface Resource {
  name: string;
  title: string;
  image: string;
  url: string;
}

export const createBaseUrl = () =>
  config.envName === 'prod'
    ? 'https://static.marketplace.sabre.com'
    : config.envName === 'cert'
    ? 'https://static.marketplace.cert.sabre.com'
    : config.envName === 'dev'
    ? 'https://storage.googleapis.com/sab-dev-scm-main-9977-static'
    : 'https://storage.googleapis.com/sab-dev-scm-main-local-7530-static';

export const resources: Array<Resource> = [
  {
    name: 'proposal',
    title: 'Video Tutorial',
    image: `${createBaseUrl()}/media/resources/proposal-submission.webp`,
    url: 'https://static.marketplace.sabre.com/documents/ProposalSubmissionTutorial.mp4',
  },
  {
    name: 'certification',
    title: 'Video Tutorial',
    image: `${createBaseUrl()}/media/resources/certification.webp`,
    url: 'https://static.marketplace.sabre.com/documents/FirstBundleCertificationStorefrontContentTutorial.mp4',
  },
  {
    name: 'nextBundle',
    title: 'Video Tutorial',
    image: `${createBaseUrl()}/media/resources/add-next-bundle.webp`,
    url: 'https://static.marketplace.sabre.com/documents/SubmitNextBundleTutorial.mp4',
  },
  {
    name: 'redAppProvider',
    title: 'Quick Reference Guide',
    image: `${createBaseUrl()}/media/resources/provider-guide-to-red-apps.webp`,
    url: 'https://static.marketplace.sabre.com/documents/QuickReferenceGuide-ProviderGuidetoRedAppsinSabreCentralMarketplace.pdf',
  },
  {
    name: 'sabreRed',
    title: 'Website',
    image: `${createBaseUrl()}/media/resources/website.webp`,
    url: 'https://developer.sabre.com/sdks/travel-agency/sabre-red-360/resource',
  },
  {
    name: 'sr360',
    title: 'Quick Reference Guide',
    image: `${createBaseUrl()}/media/resources/turn-on-red-app-in-sr360.webp`,
    url: 'https://static.marketplace.sabre.com/documents/HowToTurnOnARedAppInSabreRed360.pdf',
  },
  {
    name: 'useCases',
    title: 'Downloads',
    image: `${createBaseUrl()}/media/resources/sample-use-cases.webp`,
    url: 'https://static.marketplace.sabre.com/documents/SampleUseCase.pdf',
  },
];
