import {fetchPartnersBatchSize} from 'pages/partnerDirectory/searchResults/SearchResults';
import Sorting from 'utils/sorting';
import {Sort} from 'generated/graphql';

const orderByInput = (sorting: Sorting) =>
  sorting === Sorting.FEATURED ? {rank: true} : {rank: false, name: sorting === Sorting.A_TO_Z ? Sort.Asc : Sort.Desc};

const SortValues = (
  sorting: Sorting,
  regions: string[],
  categories: string[],
  tiers: string[],
  searchTerm?: string
) => ({
  variables: {
    orderBy: orderByInput(sorting),
    take: fetchPartnersBatchSize,
    categories: categories,
    regions: regions,
    tiers: tiers,
    searchTerm: searchTerm,
  },
  notifyOnNetworkStatusChange: true,
});

export default SortValues;
