import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import ProposalViewDataDefinition from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {StorefrontDataContext} from '@scm/product-components/pages/storefrontData/dataProvider/StorefrontDataProvider';
import {General, RedAppInfo} from '@scm/proposal/generated/proposal';
import ProposalViewDataProvider from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/ProposalViewDataProvider';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {Message} from '@sabre/spark-react-core';
import {useIntl} from 'react-intl';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {getAccessToken, isSabreEmployeeRole} from '@scm/authentication/utils/authentication';
import AuditLogs from '@scm/admin-centre/src/components/auditLog/AuditLogs';
import {ProposalViewContainer} from '@scm/proposal/pages/proposalViewPage/ProposalViewPage';
import {AuditApi, AuditLog, Configuration as AuditConfiguration, ObjectType} from '@scm/proposal/generated/audit';
import {proposalApiBaseLink} from '@scm/proposal';
import ProposalViewDataSecurityPci from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/proposalViewDataSecurity/ProposalViewDataSecurityPci';
import ProposalViewDataSecurityPii from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/proposalViewDataSecurity/ProposalViewDataSecurityPii';
import ProposalViewDataSecurityGds from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/proposalViewDataSecurity/ProposalViewDataSecurityGds';
import ProposalViewDataSecurityAccounting from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/proposalViewDataSecurity/ProposalViewDataSecurityAccounting';
import {ProposalContext} from '@scm/product-details/provider/ProposalProvider';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

export interface Log {
  date: string;
  changeBy: string;
  action: string;
}

const ProposalData = ({hasProposalContext}: {hasProposalContext?: boolean}) => {
  const {formatMessage} = useIntl();
  const {proposalData: proposalFromStorefront, setReloadForm: setReloadFormFromStorefront} =
    useContext(StorefrontDataContext);
  const {proposal: proposalFromProvider, setLoading} = useContext(ProposalContext);
  const proposalData = hasProposalContext ? proposalFromProvider : proposalFromStorefront;
  const setReloadForm = hasProposalContext ? setLoading : setReloadFormFromStorefront;

  const [proposalResponseRedAppInfo, setProposalRedAppInfo] = useState<RedAppInfo>();
  const [error] = useState<boolean>(false);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);

  const fetchAuditLogs = async (id: string) => {
    return new AuditApi(
      new AuditConfiguration({
        basePath: proposalApiBaseLink,
        accessToken: getAccessToken() || '',
        middleware: middleware,
      })
    ).getAudits({objectType: 'proposal', id});
  };

  useEffect(() => {
    setProposalRedAppInfo(proposalData?.productInfoResponse as RedAppInfo);
    if (isSabreEmployeeRole()) {
      fetchAuditLogs(proposalData?.proposalId || '')
        .then(response => setAuditLogs(response))
        .catch();
    }
  }, [proposalData, setProposalRedAppInfo]);

  return error ? (
    <Message
      content={formatMessage({id: 'tabs.proposalData.error.description'})}
      role={MessageRole.ALERT}
      status={MessageStatus.ERROR}
      title={formatMessage({id: 'tabs.proposalData.error.title'})}
      type={MessageType.PAGE}
    />
  ) : (
    <ProposalViewWrapper data-testid="Proposal">
      <ProposalViewDataProvider>
        <ProposalViewDataDefinition
          proposalName={proposalData?.name}
          proposalId={proposalData?.proposalId}
          status={proposalData?.status}
          sellerid={proposalData?.sellerid}
          submittedBy={proposalData?.submittedBy}
          provisioningType={proposalData?.provisioningType}
          name={proposalData?.name}
          description={(proposalResponseRedAppInfo?.general as General)?.description}
          categories={(proposalResponseRedAppInfo?.general as General)?.categories}
          interactionWithEndTravelerComment={
            (proposalResponseRedAppInfo?.general as General)?.interactionWithEndTravelerComment
          }
          pnrDocumentationComment={(proposalResponseRedAppInfo?.general as General)?.pnrDocumentationComment}
          connectionToGdsComment={proposalResponseRedAppInfo?.security?.gds?.connectionToGdsComment}
          developmentType={(proposalResponseRedAppInfo?.general as General)?.developmentType}
          technologies={(proposalResponseRedAppInfo?.general as General)?.technologies}
          hasFlowDiagram={proposalResponseRedAppInfo?.general?.flowDiagramUrl !== undefined}
        />
        <ProposalViewDataSecurityPci
          data={proposalResponseRedAppInfo?.security?.pci?.data}
          panProtectionComment={proposalResponseRedAppInfo?.security?.pci?.panProtectionComment}
          utilizingThirdPartyProcessor={proposalResponseRedAppInfo?.security?.pci?.utilizingThirdPartyProcessor}
          pciComment={proposalResponseRedAppInfo?.security?.pci?.comment}
          retentionPeriod={proposalResponseRedAppInfo?.security?.pci?.retentionPeriod}
          usedBy={proposalResponseRedAppInfo?.security?.pci?.usedBy}
          isEditable={false}
        />
        <ProposalViewDataSecurityPii
          piiData={proposalResponseRedAppInfo?.security?.pii?.data}
          howIsForwarded={proposalResponseRedAppInfo?.security?.pii?.howIsForwarded}
          euNationals={proposalResponseRedAppInfo?.security?.pii?.euNationals}
          complianceWithGdpr={proposalResponseRedAppInfo?.security?.pii?.complianceWithGdpr}
          retentionPeriod={proposalResponseRedAppInfo?.security?.pii?.retentionPeriod}
          piiUsedBy={proposalResponseRedAppInfo?.security?.pii?.usedBy}
          sharedWithThirdParty={proposalResponseRedAppInfo?.security?.pii?.sharedWithThirdParty}
          isEditable={false}
        />
        <ProposalViewDataSecurityGds
          gdsHowIsForwarded={proposalResponseRedAppInfo?.security?.gds?.howIsForwarded}
          protocol={proposalResponseRedAppInfo?.security?.gds?.protocol}
          protectionComment={proposalResponseRedAppInfo?.security?.protectionComment}
          gdsComment={proposalResponseRedAppInfo?.security?.gds?.comment}
          numberOfTransaction={proposalResponseRedAppInfo?.security?.gds?.numberOfTransaction}
          relyOnFreeTextRemarksInPnr={proposalResponseRedAppInfo?.security?.gds?.relyOnFreeTextRemarksInPnr}
          patchTimeline={proposalResponseRedAppInfo?.security?.patchTimeline}
          isEditable={false}
        />
        <ProposalViewDataSecurityAccounting
          dataType={proposalResponseRedAppInfo?.security?.accounting?.dataType || []}
          attributesDescription={proposalResponseRedAppInfo?.security?.accounting?.attributesDescription || ''}
          isEditable={false}
        />
      </ProposalViewDataProvider>
      {isSabreEmployeeRole() && (
        <ProposalViewContainer className="spark-pad-2">
          <AuditLogs
            auditLogs={auditLogs}
            objectType={ObjectType.Proposal}
            id={proposalData?.proposalId}
            reloadForm={() => setReloadForm(true)}
          />
        </ProposalViewContainer>
      )}
    </ProposalViewWrapper>
  );
};

const buttonStyle = (color: string) => `
    background-color: ${color};
    border: ${color};
    box-shadow: inset 0 -2px 0 ${color};
`;

const deleteButtonStyle = () => `
    color: ${colors.red};
    border: solid 1px ${colors.red};
    background-color: ${colors.white};
    box-shadow: inset 0 -2px 0 ${colors.red};
`;

export const ButtonLine = styled.div`
  &.button-col {
    display: flex;
    flex-direction: column;
  }

  & .neutral {
    ${buttonStyle(colors.grey700)}
  }

  & .error {
    ${buttonStyle(colors.red)}
  }

  & .warning {
    ${buttonStyle(colors.warningYellow)}
  }

  & .delete {
    ${deleteButtonStyle()}
  }

  & .neutral:hover,
  & .error:hover,
  & .warning:hover,
  & .delete:hover {
    filter: brightness(93%);
  }
`;

const ProposalViewWrapper = styled.div`
  width: 75%;
  @media (max-width: ${deviceWidths.md}px) {
    width: calc(100% - 2rem);
    margin: 0 1rem;
  }
  margin-top: -2rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default ProposalData;
