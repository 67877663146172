/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Pricing
 */
export interface Pricing {
    /**
     * 
     * @type {string}
     * @memberof Pricing
     */
    comment?: string;
}

/**
 * Check if a given object implements the Pricing interface.
 */
export function instanceOfPricing(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PricingFromJSON(json: any): Pricing {
    return PricingFromJSONTyped(json, false);
}

export function PricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PricingToJSON(value?: Pricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
    };
}

