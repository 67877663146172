import React from 'react';
import {Button, Tooltip} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import useAddRoleToTooltip from '../accessibility/hooks/useAddRoleToTooltip';

const ButtonWithTooltip = ({
  isValid,
  handleContinue,
  messageId,
}: {
  isValid: boolean;
  handleContinue: (shouldResetSteps?: boolean) => void;
  messageId: string;
}) => {
  const tooltipRef = useAddRoleToTooltip();

  return (
    <>
      {!isValid && (
        <ButtonWithTooltipContainer>
          <Tooltip
            toggleEl={
              <TooltipHandler style={{}}>
                <Button size={ButtonSize.SMALL} onClick={() => {}} disabled>
                  <FormattedMessage id="common.continueButton" />
                </Button>
              </TooltipHandler>
            }
            ref={tooltipRef}
          >
            <FormattedMessage id={messageId} />
          </Tooltip>
        </ButtonWithTooltipContainer>
      )}
      {isValid && (
        <Button size={ButtonSize.SMALL} onClick={() => handleContinue(false)}>
          <FormattedMessage id="common.continueButton" />
        </Button>
      )}
    </>
  );
};

export const TooltipHandler = styled.span`
  position: relative;
  display: block;
  background-color: transparent;
  z-index: initial;
  cursor: not-allowed;
`;

export const ButtonWithTooltipContainer = styled.div`
  position: relative;
  background-color: transparent;
  z-index: 1;

  & button {
    z-index: -1;
  }
`;

export default ButtonWithTooltip;
