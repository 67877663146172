/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Required only for DevPartner
 * @export
 */
export const NumberOfEmployeesType = {
    _110: '1-10',
    _1150: '11-50',
    _51250: '51-250',
    MoreThan250: 'more than 250'
} as const;
export type NumberOfEmployeesType = typeof NumberOfEmployeesType[keyof typeof NumberOfEmployeesType];


export function NumberOfEmployeesTypeFromJSON(json: any): NumberOfEmployeesType {
    return NumberOfEmployeesTypeFromJSONTyped(json, false);
}

export function NumberOfEmployeesTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): NumberOfEmployeesType {
    return json as NumberOfEmployeesType;
}

export function NumberOfEmployeesTypeToJSON(value?: NumberOfEmployeesType | null): any {
    return value as any;
}

