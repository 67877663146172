import {pccEprSchema} from './pccEprSchema';

export const assignDeveloperSchema = pccEprSchema.test(
  'one-checked',
  'at least one option must be selected',
  function (value) {
    const {canManageCompany, canAccessAllProducts, canSubmitProposal} = value;
    return !!(canManageCompany || canAccessAllProducts || canSubmitProposal);
  }
);
