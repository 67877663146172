/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Authorization
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationErrors,
  AuthorizationResponse,
  TermsAndConditionsResponse,
} from '../models';
import {
    AuthorizationErrorsFromJSON,
    AuthorizationErrorsToJSON,
    AuthorizationResponseFromJSON,
    AuthorizationResponseToJSON,
    TermsAndConditionsResponseFromJSON,
    TermsAndConditionsResponseToJSON,
} from '../models';

export interface AcceptTermsAndConditionsRequest {
    version: string;
}

/**
 * 
 */
export class TermsAndConditionsApi extends runtime.BaseAPI {

    /**
     */
    async acceptTermsAndConditionsRaw(requestParameters: AcceptTermsAndConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizationResponse>> {
        if (requestParameters.version === null || requestParameters.version === undefined) {
            throw new runtime.RequiredError('version','Required parameter requestParameters.version was null or undefined when calling acceptTermsAndConditions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/terms-and-conditions/{version}/acceptances`.replace(`{${"version"}}`, encodeURIComponent(String(requestParameters.version))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationResponseFromJSON(jsonValue));
    }

    /**
     */
    async acceptTermsAndConditions(requestParameters: AcceptTermsAndConditionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizationResponse> {
        const response = await this.acceptTermsAndConditionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLatestTermsAndConditionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TermsAndConditionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/terms-and-conditions/latest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsAndConditionsResponseFromJSON(jsonValue));
    }

    /**
     */
    async getLatestTermsAndConditions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TermsAndConditionsResponse> {
        const response = await this.getLatestTermsAndConditionsRaw(initOverrides);
        return await response.value();
    }

}
