import React from 'react';
import ApolloErrorElement from 'components/error/ApolloErrorElement';
import {ApolloError} from '@apollo/client';

const renderApolloErrors = (error: ApolloError) => {
  if (error.graphQLErrors.length === 0 && error.networkError) {
    return <ApolloErrorElement type={'fetchingFailed'} />;
  }
  if (error.graphQLErrors.length !== 0) {
    const firstError = error.graphQLErrors[0];
    return <ApolloErrorElement type={firstError.extensions?.exceptionType} />;
  }
  return <ApolloErrorElement type='generic'/>
};

export default renderApolloErrors;
