/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TechnicalSupportType = {
    Unlimited: 'Unlimited',
    PaidPerIncident: 'Paid per incident'
} as const;
export type TechnicalSupportType = typeof TechnicalSupportType[keyof typeof TechnicalSupportType];


export function TechnicalSupportTypeFromJSON(json: any): TechnicalSupportType {
    return TechnicalSupportTypeFromJSONTyped(json, false);
}

export function TechnicalSupportTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicalSupportType {
    return json as TechnicalSupportType;
}

export function TechnicalSupportTypeToJSON(value?: TechnicalSupportType | null): any {
    return value as any;
}

