import React, {useEffect} from 'react';
import InternalUrls from 'utils/internalUrls';
import styled from 'styled-components';
import PartnerTiersHeader from 'pages/partnerTiers/PartnerTiersHeader';
import TiersContactBox from 'pages/partnerTiers/TiersContactBox';
import TiersDescription from 'pages/partnerTiers/TiersDescription';
import {useMediaQuery} from 'react-responsive';
import {BreadcrumbLink, BreadcrumbRowWrapper} from '@scm/components';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const PartnerTiers = () => {
  useEffect(() => window.scrollTo(0, 0));
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  return (
    <Container>
      <BreadcrumbRowWrapper>
        <BreadcrumbLink intlId="common.breadcrumb.home" href={InternalUrls.Home} />
        <BreadcrumbLink intlId="common.breadcrumb.tiers" />
      </BreadcrumbRowWrapper>
      <Content>
        <PartnerTiersHeader />
        <div className={isMobile ? '' : 'spark-flex'}>
          <TiersContactBox isMobile={isMobile} />
          <TiersDescription />
        </div>
      </Content>
    </Container>
  );
};

const Container = styled.main`
  background-color: ${colors.white};
  color: ${colors.grey700};
  min-height: 100vh;
`;

const Content = styled.div`
  padding: 3rem 6vw;
`;

export default PartnerTiers;
