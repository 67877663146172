import React, {useEffect, useRef, useState} from 'react';
import SparkHeader from '@sabre/spark/js/src/components/header';
import {FormattedMessage, useIntl} from 'react-intl';
import HeaderMenu from 'spark/header/dropdown/HeaderMenu';
import styled from 'styled-components';

const Header = () => {
  const headerRef = useRef(null);
  const [closeHamburgerMenuRef, setCloseHamburgerMenuRef] = useState<() => void>(() => {});
  useEffect(() => {
    const sparkHeader = new SparkHeader(headerRef.current, {fixed: false});
    setCloseHamburgerMenuRef(() => () => sparkHeader.closeHamburgerMenu());
    return () => {
      sparkHeader.remove();
    };
  }, [headerRef]);

  const {formatMessage} = useIntl();
  const viewNavigationLabel = formatMessage({id: 'header.viewNavigation'});

  return (
    <header className="spark-header" ref={headerRef}>
      <nav className="spark-header__nav" role="navigation">
        <button
          className="spark-menu__toggle spark-header__toggle"
          aria-label={viewNavigationLabel}
          title={viewNavigationLabel}
        >
          <i className="spark-icon--fill spark-icon-menu-hamburger spark-icon--md" />
        </button>
        <span className="spark-header__logo">
          <MainIcon className="spark-logo spark-logo--sabre spark-logo--sm">
            <FormattedMessage id="common.sabre" />
          </MainIcon>
        </span>
        <HeaderMenu closeHamburgerMenu={closeHamburgerMenuRef} />
      </nav>
    </header>
  );
};

const MainIcon = styled.i`
  font-size: 2.5rem;
`;

export default Header;
