import {Control, Controller} from 'react-hook-form';
import {ReactElement} from 'react';
import {Checkbox} from '@sabre/spark-react-core';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import {SellerDeveloper} from '../../../generated/management';
import colors from '@scm/components/assets/colors';

const Permissions = ({
  name,
  control,
}: {
  name: 'canAccessAllProducts' | 'canSubmitProposal' | 'canManageCompany';
  control: Control<SellerDeveloper>;
}) => {
  const {formatMessage} = useIntl();

  return (
    <CheckboxCell className="col-xs-4">
      <Controller
        name={name}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={onChange}
            checked={value}
            label={formatMessage({id: `assignSeller.companyLevel.${name}`})}
          />
        )}
      />
    </CheckboxCell>
  );
};

const CheckboxCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  & span {
    color: ${colors.highlightGrey} !important;
  }
  & .spark-checkbox {
    flex-direction: column-reverse;
    align-items: center;
    gap: 0.5rem;
  }
`;

export default Permissions;
