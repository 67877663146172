/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductPriceRange } from './ProductPriceRange';
import {
    ProductPriceRangeFromJSON,
    ProductPriceRangeFromJSONTyped,
    ProductPriceRangeToJSON,
} from './ProductPriceRange';
import type { ProductPricing } from './ProductPricing';
import {
    ProductPricingFromJSON,
    ProductPricingFromJSONTyped,
    ProductPricingToJSON,
} from './ProductPricing';

/**
 * 
 * @export
 * @interface Pricing
 */
export interface Pricing {
    /**
     * 
     * @type {ProductPricing}
     * @memberof Pricing
     */
    productPricing: ProductPricing;
    /**
     * 
     * @type {Array<ProductPriceRange>}
     * @memberof Pricing
     */
    transactionPricingRanges?: Array<ProductPriceRange>;
    /**
     * 
     * @type {Array<ProductPriceRange>}
     * @memberof Pricing
     */
    userPricingRanges?: Array<ProductPriceRange>;
}

/**
 * Check if a given object implements the Pricing interface.
 */
export function instanceOfPricing(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productPricing" in value;

    return isInstance;
}

export function PricingFromJSON(json: any): Pricing {
    return PricingFromJSONTyped(json, false);
}

export function PricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productPricing': ProductPricingFromJSON(json['productPricing']),
        'transactionPricingRanges': !exists(json, 'transactionPricingRanges') ? undefined : ((json['transactionPricingRanges'] as Array<any>).map(ProductPriceRangeFromJSON)),
        'userPricingRanges': !exists(json, 'userPricingRanges') ? undefined : ((json['userPricingRanges'] as Array<any>).map(ProductPriceRangeFromJSON)),
    };
}

export function PricingToJSON(value?: Pricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productPricing': ProductPricingToJSON(value.productPricing),
        'transactionPricingRanges': value.transactionPricingRanges === undefined ? undefined : ((value.transactionPricingRanges as Array<any>).map(ProductPriceRangeToJSON)),
        'userPricingRanges': value.userPricingRanges === undefined ? undefined : ((value.userPricingRanges as Array<any>).map(ProductPriceRangeToJSON)),
    };
}

