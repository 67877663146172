import React, {useEffect, useRef} from 'react';
import SparkMultiSelect from '@sabre/spark/js/src/components/multi-select-input';
import styled from 'styled-components';
import colors from '../../assets/colors';
import {UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {SingleLevelMultiSelect} from './SingleLevelMultiSelect';
import {TwoLevelMultiSelect} from './TwoLevelMultiSelect';

export interface CheckAllOptions {
  checkAllName: string;
  checkAllLabel: string;
}
export interface SecondLevelOptions {
  secondLevelName: string;
  optionsSecondLevel: Array<Object>;
}

export interface MultiSelectMainProps {
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  firstLevelName: string;
  options: {[key: string]: string};
}

export const filterList = (value: string, list: Array<string>) => list.filter(listValue => listValue !== value);
export const shouldDirty = {shouldDirty: true};

export const MultiSelect = ({
  multiSelectMainProps,
  enableCheckAll,
  checkAllOptions,
  secondLevelOptions,
  searchText = '',
  size = 10,
  disabled,
}: {
  multiSelectMainProps: MultiSelectMainProps;
  enableCheckAll?: boolean;
  checkAllOptions?: CheckAllOptions;
  secondLevelOptions?: SecondLevelOptions;
  searchText?: string;
  size?: number;
  disabled?: boolean;
}) => {
  const multiSelectRef = useRef(null);

  useEffect(() => {
    const sparkAccordion = new SparkMultiSelect(multiSelectRef.current);
    return () => {
      sparkAccordion.remove();
    };
  }, [multiSelectRef]);

  return (
    <MultiSelectContainer className="spark-multi-select" aria-label="Select Options" ref={multiSelectRef} size={size}>
      {checkAllOptions && secondLevelOptions ? (
        <TwoLevelMultiSelect
          multiSelectMainProps={multiSelectMainProps}
          secondLevelOptions={secondLevelOptions}
          enableCheckAll={enableCheckAll}
          checkAllOptions={checkAllOptions}
          searchText={searchText}
          disabled={disabled}
        />
      ) : (
        <SingleLevelMultiSelect {...multiSelectMainProps} disabled={disabled} />
      )}
    </MultiSelectContainer>
  );
};

export const CheckAll = styled.label`
  border-bottom: 1px solid ${colors.grey200};
`;

export const CheckboxLabel = styled.label<{hasText?: boolean}>`
  display: ${props => (props.hasText ? 'flex' : 'none')};
`;

export const Content = styled.div`
  &::before {
    content: '';
    display: block;
    margin-top: 1rem;
  }
`;

export const MultiSelectContainer = styled.div<{size: number}>`
  &.spark-multi-select {
    margin: 0;
  }

  &.spark-multi-select .spark-multi-select__container {
    padding-right: 0;
  }

  &.spark-multi-select:hover:not([disabled])[data-error] {
    border: none;
  }
`;
