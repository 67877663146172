import InternalUrls from 'utils/internalUrls';
import SearchPanel from 'pages/partnerDirectory/searchPanel/SearchPanel';
import SearchResults from 'pages/partnerDirectory/searchResults/SearchResults';
import MediaQuery from 'react-responsive';
import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import {maxMobileWidth} from 'assets/deviceWidth';
import {BreadcrumbLink, Breadcrumbs} from '@scm/components';
import colors from '@scm/components/assets/colors';

const PartnerDirectoryMobile = () => (
  <MediaQuery maxDeviceWidth={maxMobileWidth}>
    <Breadcrumbs>
      <BreadcrumbLink intlId="common.breadcrumb.home" href={InternalUrls.Home} />
      <BreadcrumbLink intlId="common.breadcrumb.partnerDirectory" />
    </Breadcrumbs>
    <AuthorizedDeveloperDirectory role="heading" aria-level={1}>
      <FormattedMessage id="partnerDirectoryPage.title" />
    </AuthorizedDeveloperDirectory>
    <BottomSection>
      <SearchPanel />
      <SearchResults />
    </BottomSection>
  </MediaQuery>
);

const AuthorizedDeveloperDirectory = styled.div.attrs(() => ({
  className: 'spark-flex spark-pad-1 spark-bold',
}))`
  align-items: center;
  max-width: 100vw;
  height: 18vh;

  background-color: ${colors.grey500};
  color: white;
  font-size: 3.4rem;
  line-height: 1.2;
`;

const BottomSection = styled.div.attrs(() => ({
  className: 'spark-flex',
}))`
  flex-direction: column;
`;

export default PartnerDirectoryMobile;
