/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { General } from './General';
import {
    GeneralFromJSON,
    GeneralFromJSONTyped,
    GeneralToJSON,
} from './General';
import type { Pricing } from './Pricing';
import {
    PricingFromJSON,
    PricingFromJSONTyped,
    PricingToJSON,
} from './Pricing';
import type { Regionalization } from './Regionalization';
import {
    RegionalizationFromJSON,
    RegionalizationFromJSONTyped,
    RegionalizationToJSON,
} from './Regionalization';
import type { Security } from './Security';
import {
    SecurityFromJSON,
    SecurityFromJSONTyped,
    SecurityToJSON,
} from './Security';
import type { Services } from './Services';
import {
    ServicesFromJSON,
    ServicesFromJSONTyped,
    ServicesToJSON,
} from './Services';

/**
 * 
 * @export
 * @interface RedAppInfo
 */
export interface RedAppInfo {
    /**
     * 
     * @type {string}
     * @memberof RedAppInfo
     */
    productType: string;
    /**
     * 
     * @type {General}
     * @memberof RedAppInfo
     */
    general?: General;
    /**
     * 
     * @type {Pricing}
     * @memberof RedAppInfo
     */
    pricing?: Pricing;
    /**
     * 
     * @type {Regionalization}
     * @memberof RedAppInfo
     */
    regionalization?: Regionalization;
    /**
     * 
     * @type {Services}
     * @memberof RedAppInfo
     */
    services?: Services;
    /**
     * 
     * @type {Security}
     * @memberof RedAppInfo
     */
    security?: Security;
}

/**
 * Check if a given object implements the RedAppInfo interface.
 */
export function instanceOfRedAppInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productType" in value;

    return isInstance;
}

export function RedAppInfoFromJSON(json: any): RedAppInfo {
    return RedAppInfoFromJSONTyped(json, false);
}

export function RedAppInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedAppInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productType': json['productType'],
        'general': !exists(json, 'general') ? undefined : GeneralFromJSON(json['general']),
        'pricing': !exists(json, 'pricing') ? undefined : PricingFromJSON(json['pricing']),
        'regionalization': !exists(json, 'regionalization') ? undefined : RegionalizationFromJSON(json['regionalization']),
        'services': !exists(json, 'services') ? undefined : ServicesFromJSON(json['services']),
        'security': !exists(json, 'security') ? undefined : SecurityFromJSON(json['security']),
    };
}

export function RedAppInfoToJSON(value?: RedAppInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productType': value.productType,
        'general': GeneralToJSON(value.general),
        'pricing': PricingToJSON(value.pricing),
        'regionalization': RegionalizationToJSON(value.regionalization),
        'services': ServicesToJSON(value.services),
        'security': SecurityToJSON(value.security),
    };
}

