import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Button} from '@sabre/spark-react-core';
import {FormattedMessage} from 'react-intl';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {UseFormWatch} from 'react-hook-form';
import LimitationsDataValues, {
  scCodeLimitationsName,
} from '@scm/product-components/pages/storefrontData/interfaces/LimitationsDataValues';
import LimitationTextInput from './LimitationTextInput';
import LimitationActionButton, {LimitationButtonOperation} from './LimitationActionButton';
import useGetIsObsolete from '../../../hooks/useGetIsObsolete';
import {PCC_MAX_LENGTH, PCC_MIN_LENGTH, PCC_REGEX, PCC_SHORT_MESSAGE} from '@scm/components/pccEpr/pccEprSchema';

const LimitationScCodeInput = ({
  scCode,
  watch,
  setScCode,
  handleAdd,
  handleRemove,
  formSubmitButton,
  handleSaveScCode,
  handleCancelScCode,
  isDirty,
}: {
  scCode: string;
  watch: UseFormWatch<LimitationsDataValues>;
  setScCode: (newValue: string) => void;
  handleAdd: (value: string) => void;
  handleRemove: (value: string) => void;
  formSubmitButton: string;
  handleSaveScCode: () => void;
  handleCancelScCode: (forceItems?: string[]) => void;
  isDirty: boolean;
}) => {
  const isObsolete = useGetIsObsolete();
  const [isDisableAddButton, setIsDisableAddButton] = useState(true);
  const [isDisableRemoveButton, setIsDisableRemoveButton] = useState(true);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setIsValid(scCode.length === 0 || PCC_REGEX.test(scCode));
    const isExistOnList = watch(scCodeLimitationsName).includes(scCode);
    setIsDisableAddButton(scCode.length === 0 || isExistOnList || !PCC_REGEX.test(scCode));
    setIsDisableRemoveButton(scCode.length === 0 || !isExistOnList);
  }, [scCode]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === formSubmitButton && !isDisableAddButton) {
      add();
    }
  };

  const add = () => handleAdd(scCode);

  const remove = () => handleRemove(scCode);

  return (
    <>
      <InputWrapper className="col-xs-4 spark-flex spark-pad-r-0">
        <div className="spark-flex">
          <LimitationTextInput
            name="scCode"
            setValue={setScCode}
            value={scCode}
            text="productDetails.limitations.storefront.scCode"
            length={PCC_MAX_LENGTH}
            minLength={PCC_MIN_LENGTH}
            errorMessage={isValid ? undefined : PCC_SHORT_MESSAGE}
            onKeyDown={onKeyDown}
          />
          <div className="spark-mar-l-1">
            <LimitationActionButton
              operation={LimitationButtonOperation.ADD}
              handleAction={add}
              disabled={isObsolete || isDisableAddButton}
              name="limitationScCodeAdd"
            />
            <LimitationActionButton
              operation={LimitationButtonOperation.REMOVE}
              handleAction={remove}
              disabled={isObsolete || isDisableRemoveButton}
              name="limitationScCodeRemove"
            />
          </div>
        </div>
        <div className="spark-pad-l-0 spark-pad-r-0 limitation-grp-btn">
          <Button
            size="xs"
            disabled={isObsolete || !isDirty}
            className="spark-mar-b-.5 col-sm-8"
            onClick={handleSaveScCode}
            data-testid="limitationScCodeSave"
          >
            <FormattedMessage id="common.saveAll" />
          </Button>
          <Button
            size="xs"
            secondary
            disabled={isObsolete || !isDirty}
            className="col-sm-8"
            onClick={() => handleCancelScCode()}
          >
            <FormattedMessage id="common.button.cancel" />
          </Button>
        </div>
      </InputWrapper>
    </>
  );
};

export const InputWrapper = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  .limitation-grp-input {
    width: 70%;
    flex-direction: column;
    justify-content: flex-start;
  }

  .limitation-grp-btn {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: ${deviceWidths.lg}px) {
      justify-content: space-between;
      flex-direction: row;
    }
  }
`;

export default LimitationScCodeInput;
