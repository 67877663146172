import {ReactNode} from 'react';

export const FULL_PAGE_MODAL_OPEN = 'FULL_PAGE_MODAL_OPEN';
export const FULL_PAGE_MODAL_CLOSE = 'FULL_PAGE_MODAL_CLOSE';

export interface FullPageModalState {
  isOpen: boolean;
  sizing?: string;
  children?: ReactNode;
}
