import {ProposalType} from './PagesMap';
import {ServiceAndSecurityValues} from './steps/serviceAndSecurity/ServiceAndSecurityValues';
import {TechnologiesType} from './steps/definitionPage/definitionComponents/Technologies';
import {Confirmation} from 'packages/product/pages/storefrontData/productDetailsUtils';

export const formTitleId = 'formTitleId';
export const currentStepName = 'currentStep';
export const proposalTypeName = 'proposalType';
export const proposalIdName = 'proposalId';
export const providerName = 'providerName';
export const termsAndConditionName = 'termsAndCondition';
export const redAppName = 'redAppName';
export const descriptionName = 'descriptionName';
export const redAppCategoryName = 'redAppCategory';
export const flowDiagramName = 'flowDiagram';
export const interactsWithEndTravelerName = 'interactsWithEndTraveler';
export const interactsWithEndTravelerDescriptionName = 'interactsWithEndTravelerDescription';
export const providePnrDocumentationName = 'providePnrDocumentation';
export const providePnrDocumentationDescriptionName = 'providePnrDocumentationDescription';
export const connectToGdsName = 'connectToGds';
export const connectToGdsDescriptionName = 'connectToGdsDescription';
export const developmentTypeName = 'developmentType';
export const technologiesName = 'technologies';
export const technologiesDescriptionName = 'technologiesDescription';
export const globalRegionalizationCheckboxName = 'globalRegionalizationCheckbox';
export const regionalizationDescriptionName = 'regionalizationDescription';
export const regionCodesName = 'regionCodes';
export const countryCodesName = 'countryCodes';
export const allRegionsName = 'allRegions';
export const isPaidName = 'isPaid';
export const isPaidDescriptionName = 'isPaidDescription';
export const serviceAndSecurityValuesName = 'serviceAndSecurityValues';
export const forceRender = 'forceRender';

export enum FileSource {
  'Sabre Red Server' = 'Sabre Red Server',
  'Local' = 'Local',
}

export enum FileNameType {
  'Red App Name' = 'Red App Name',
  'Original File Name' = 'Original File Name',
}

export interface ProposalValues {
  [formTitleId]: string;
  [currentStepName]: number;
  [proposalIdName]?: string;
  [proposalTypeName]?: ProposalType;
  [providerName]?: string;
  [termsAndConditionName]?: boolean;
  [redAppName]: string;
  [descriptionName]: string;
  [redAppCategoryName]: Array<string>;
  [flowDiagramName]: Array<File>;
  [interactsWithEndTravelerName]: boolean;
  [interactsWithEndTravelerDescriptionName]?: string;
  [providePnrDocumentationName]: boolean;
  [providePnrDocumentationDescriptionName]?: string;
  [connectToGdsName]: boolean;
  [connectToGdsDescriptionName]?: string;
  [developmentTypeName]?: string;
  [technologiesName]: Array<string | TechnologiesType>;
  [technologiesDescriptionName]?: string | null;
  [globalRegionalizationCheckboxName]: boolean | Confirmation;
  [regionalizationDescriptionName]?: string;
  [regionCodesName]: Array<string>;
  [countryCodesName]: Array<string>;
  [allRegionsName]: boolean;
  [isPaidName]: boolean;
  [isPaidDescriptionName]?: string;
  [serviceAndSecurityValuesName]: ServiceAndSecurityValues;
  //technical field for re-render forms
  [forceRender]: boolean;
}
