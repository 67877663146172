import {Modal} from '@sabre/spark-react-core';
import {Actors, IProcessScheme} from './schemes';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import colors from '../assets/colors';
import React from 'react';
import StepArrows from './StepArrows';

const ProcessScheme = ({
  processScheme,
  open,
  setOpen,
  currentStep,
  isFailed,
  isProposal,
  ariaLevel,
}: {
  processScheme: IProcessScheme;
  open: boolean;
  setOpen: (open: boolean) => void;
  currentStep?: number;
  isFailed?: boolean;
  isProposal: boolean;
  ariaLevel?: number;
}) => {
  const {formatMessage} = useIntl();
  const widthPercentPerStep = 10.5;

  return (
    <BiggerModal
      className="spark-align-items-center"
      open={open}
      title={formatMessage({id: processScheme.title})}
      onClose={() => setOpen(false)}
      width={widthPercentPerStep * processScheme.steps.length}
      titleHeadingLevel={ariaLevel}
    >
      <StepParagraph className="spark-small">
        <FormattedMessage id="processScheme.workingDay.label" />
      </StepParagraph>
      <StepsComponent>
        {processScheme.steps.map((step, index) => (
          <StepArrows
            key={index}
            stepNumber={index + 1}
            stepTitle={formatMessage({id: step.name})}
            stepDescription={step.action ? formatMessage({id: step.action}) : ''}
            isDescriptionOnTop={index % 2 === 0}
            sabreStep={step.actor === Actors.Sabre}
            stepTime={step.time ? formatMessage({id: step.time}) : ''}
            currentStep={currentStep}
            isFailed={isFailed}
          />
        ))}
      </StepsComponent>
      {isFailed && (
        <FailedInfo className="spark-pad-2">
          <FormattedMessage
            id={isProposal ? 'processScheme.proposal.failedInfo' : 'processScheme.product.failedInfo'}
          />
        </FailedInfo>
      )}
    </BiggerModal>
  );
};

const BiggerModal = styled(Modal)<{width: string}>`
  & .col-md-7,
  & .col-lg-6,
  & .col-xl-5 {
    max-width: ${props => props.width}% !important;
    flex: 0 0 ${props => props.width}% !important;
  }

  & .spark-step-indicator__item {
    max-width: 12rem !important;
  }

  & .spark-modal__content {
    min-width: 550px;
  }
`;

const StepsComponent = styled.div`
   {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    margin: 10rem 1rem 11rem 0.5rem;
  }
`;

const FailedInfo = styled.p`
  font-weight: bold !important;
  color: ${colors.red};
`;

const StepParagraph = styled.p`
  color: ${colors.neutralBlue};
`;

export default ProcessScheme;
