import React from 'react';
import styled from 'styled-components';
import {useMediaQuery} from 'react-responsive';

export const Breadcrumbs = ({children}: {children: Array<JSX.Element | null> | JSX.Element | null}) => {
  const separator = <span className="spark-pad-.5">/</span>;
  const isMobile = useMediaQuery({query: '(max-device-width: 795px)'});
  const items = React.Children.toArray(children);

  return (
    <BreadcrumbsContainer mobile={isMobile}>
      {items.reduce((total, current) => (
        <BreadcrumbLinks mobile={isMobile}>
          {total}
          {separator}
          {current}
        </BreadcrumbLinks>
      ))}
    </BreadcrumbsContainer>
  );
};

const BreadcrumbsContainer = styled.div.attrs(() => ({
  className: 'spark-pad-t-1 spark-pad-b-1 spark-caps',
}))<{mobile?: boolean}>`
  background: #fff;
  padding-left: ${props => (props.mobile ? '1rem' : '4rem')};
`;

const BreadcrumbLinks = styled.div.attrs(() => ({
  className: 'spark-flex spark-align-items-center spark-flex-wrap',
}))<{mobile?: boolean}>`
  ${props => (props.mobile ? 'flex-wrap: wrap;' : '')};
`;
