import React, {useState} from 'react';
import {Button, ProgressIndicator, TextInput} from '@sabre/spark-react-core';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {SubmitHandler, useForm} from 'react-hook-form';
import {
  Configuration as OnboardingsConfiguration,
  CreateSellerRequest,
  OnboardingRequestResponse,
  OnboardingRequestResponseStatusEnum,
  OnboardingsApi,
} from '../../../generated/onboardings';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {proposalsApiBaseLink} from '../../../assets/baseUrl';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {openToast} from '@scm/components/messaging/openToast';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const SC_CODE_MIN_LENGTH = 3;
const SC_CODE_MAX_LENGTH = 4;

const validationSchema = yup.object({
  scCode: yup.string().required().min(SC_CODE_MIN_LENGTH).max(SC_CODE_MAX_LENGTH),
});

const createScSeller = async (id: string, createSellerRequest: CreateSellerRequest) =>
  await new OnboardingsApi(
    new OnboardingsConfiguration({
      basePath: proposalsApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).createSeller({id, createSellerRequest});

const CreateSellerForm = ({
  handleClose,
  id,
  setDetails,
}: {
  handleClose: () => void;
  id?: string;
  setDetails: React.Dispatch<React.SetStateAction<OnboardingRequestResponse | undefined>>;
}) => {
  const {formatMessage} = useIntl();
  const {
    handleSubmit,
    watch,
    setValue,
    formState: {isValid, errors},
  } = useForm<CreateSellerRequest>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const scCodeName = 'scCode';
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<CreateSellerRequest> = async (values: CreateSellerRequest) => {
    try {
      if (id) {
        setIsLoading(true);
        await createScSeller(id, values);
        setDetails(prevState => ({...prevState, status: OnboardingRequestResponseStatusEnum.Approved}));
        openToast(
          formatMessage({id: 'proposals.sendScCode.success.title'}),
          formatMessage({id: 'proposals.sendScCode.success.description'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      }
    } catch (_) {
      openToast(
        formatMessage({id: 'proposals.sendScCode.error.title'}),
        formatMessage({id: 'proposals.sendScCode.error.description'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
    } finally {
      handleClose();
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        <FormattedMessage id="proposalDetails.createSeller.message" />
      </p>
      <TextInput
        name={scCodeName}
        value={watch(scCodeName)}
        onChange={(_, value) =>
          setValue(scCodeName, value, {shouldDirty: true, shouldValidate: true, shouldTouch: true})
        }
        label={formatMessage({id: 'proposalDetails.createSeller.placeholder'})}
        characterCount
        maxLength={SC_CODE_MAX_LENGTH}
        status={errors.scCode ? 'error' : undefined}
        statusMessage={formatMessage({id: 'proposalDetails.createSeller.error.message'})}
      />
      <nav className="spark-btn-group">
        <Button
          className="spark-btn-group-secondary"
          size={ButtonSize.SMALL}
          secondary
          onClick={(): void => {
            handleClose();
          }}
        >
          <FormattedMessage id="common.cancelButton" />
        </Button>
        {isLoading ? (
          <ProgressIndicator className="spark-mar-l-2 spark-mar-r-2" type="indeterminate" size="xs" />
        ) : (
          <Button
            type="submit"
            className="spark-btn-group-primary"
            size={ButtonSize.SMALL}
            disabled={!isValid}
            tabIndex={0}
          >
            <FormattedMessage id="common.createButton" />
          </Button>
        )}
      </nav>
    </form>
  );
};
export default CreateSellerForm;
