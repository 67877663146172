import {useContext} from 'react';
import {
  blockedCountriesName,
  countriesName,
  RegionalizationContext,
  regionsName,
} from '../../../../forms/RegionalizationContent';
import {getCountriesInRegion} from '../utilities/getCountiresInRegion';
import {regions} from '@scm/components/assets/regionsAndCountries';

export const GLOBAL = 'Global';
const useSetRegionValue = () => {
  const {setValue, watch, regions: regionsValues} = useContext(RegionalizationContext);
  const regionsLength = Object.keys(regions).filter(region => region !== GLOBAL).length;

  const setRegionValue = (activeItems: string[]) => {
    if (!activeItems.includes(GLOBAL) && regionsValues.includes('Global')) {
      setValue(blockedCountriesName, [], {shouldTouch: true, shouldValidate: true, shouldDirty: true});
      setValue(regionsName, activeItems, {shouldTouch: true, shouldValidate: true, shouldDirty: true});
    } else if (activeItems.length >= regionsLength || activeItems.includes(GLOBAL)) {
      setValue(regionsName, [GLOBAL], {shouldTouch: true, shouldValidate: true, shouldDirty: true});
      setValue(countriesName, [], {shouldTouch: true, shouldValidate: true, shouldDirty: true});
    } else {
      const prevRegions = regionsValues;

      if (activeItems.length > prevRegions.length) {
        const currentRegion = activeItems.filter(item => !prevRegions.includes(item));
        const prevCountries = watch(countriesName);
        const countriesInReg = getCountriesInRegion(currentRegion);
        const filteredCountries = prevCountries.filter(country => !countriesInReg.includes(country));
        setValue(countriesName, filteredCountries, {shouldTouch: true, shouldValidate: true, shouldDirty: true});
      } else {
        const currentRegion = prevRegions.filter(item => !activeItems.includes(item));
        const prevBlockedCountries = watch(blockedCountriesName);
        const countriesInReg = getCountriesInRegion(currentRegion);
        const filteredCountries = prevBlockedCountries.filter(country => !countriesInReg.includes(country));
        setValue(blockedCountriesName, filteredCountries, {shouldTouch: true, shouldValidate: true, shouldDirty: true});
      }

      setValue(regionsName, activeItems, {shouldTouch: true, shouldValidate: true, shouldDirty: true});
    }
  };

  return setRegionValue;
};

export default useSetRegionValue;
