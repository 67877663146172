import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import BenefitsRow from 'pages/benefits/BenefitsRow';
import colors from '@scm/components/assets/colors';

const BenefitsMain = () => {
  const {formatMessage} = useIntl();
  let rowsCount = parseInt(formatMessage({id: 'benefits.numberOfRows'}));

  return (
    <Container>
      <h1 className="spark-mar-b-2">
        <FormattedMessage id="benefits.title" />
      </h1>
      {Array.from(Array(rowsCount)).map((_, i) => (
        <BenefitsRow
          key={`benefits-${i + 1}`}
          iconId={`benefits.row${i + 1}.icon`}
          textId={`benefits.row${i + 1}.content`}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${colors.white};
  padding: 4rem 10vw;
  margin: auto;
`;

export default BenefitsMain;
