import React from 'react';
import {useIntl} from 'react-intl';
import {Message} from '@sabre/spark-react-core';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import styled from 'styled-components';

const EndMessage = () => {
  const {formatMessage} = useIntl();

  return (
    <Fields>
      <Message
        content={formatMessage({id: 'joinUsPage.form.endMessage.message'})}
        role={MessageRole.ALERT}
        status={MessageStatus.SUCCESS}
        title={formatMessage({id: 'joinUsPage.form.endMessage.title'})}
        type={MessageType.PAGE}
        titleHeadingLevel={2}
      />
    </Fields>
  );
};

export const Fields = styled.div`
  width: 70%;
`;

export default EndMessage;
