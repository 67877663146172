import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {Popover} from '@sabre/spark-react-core';
import {useIntl} from 'react-intl';
import {
  isAllowedToAddRemoveTesters,
  isAllowedToEditProducts,
  isVersionManageAllowedRoles,
} from '@scm/authentication/utils/authentication';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {Status, VersionResponse} from '../../../../../generated/certification';
import useGetProductC from '../../../../../hooks/useGetProductC';
import {CcrzEProductCSObjectRedAppTypeCEnum} from '../../../../../generated/product';
import ActionItem from './ActionItem';

const RowActions = ({
  showOptionsUp,
  bundleLink,
  bundleDetails,
  handleOpen,
  setSvsErrors,
  setEditedBundle,
  setEditedReleaseNotes,
  setEditedSimplifiedDescription,
  setEditedCleanupDates,
  setVersionFilesDetails,
}: {
  showOptionsUp: boolean;
  bundleLink: string;
  bundleDetails: VersionResponse;
  handleOpen: ((open: boolean) => void) | undefined;
  setSvsErrors?: (bundle: VersionResponse) => void;
  setEditedBundle?: (bundle: VersionResponse) => void;
  setEditedReleaseNotes?: (bundle: VersionResponse) => void;
  setEditedSimplifiedDescription?: (bundle: VersionResponse) => void;
  setEditedCleanupDates?: (bundle: VersionResponse) => void;
  setVersionFilesDetails?: (bundle: VersionResponse) => void;
}) => {
  const {formatMessage} = useIntl();
  const {redAppTypeC} = useGetProductC();
  const navigate = useNavigate();

  return (
    <Popover
      anchorX={TooltipDirectionX.LEFT}
      anchorY={showOptionsUp ? TooltipDirectionY.TOP : TooltipDirectionY.BOTTOM}
      dismissible
      contentCloseElQuery=".spark-popover__list-link"
      toggleEl={
        <button
          className="spark-btn--icon spark-icon spark-icon--fill spark-icon-menu-ellipsis-vertical"
          aria-label={formatMessage({id: 'accessibility.actionMenuLabel'})}
          type="button"
        />
      }
      aria-label="Version action menu"
    >
      <ul className="spark-popover__list">
        {isVersionManageAllowedRoles() && (
          <li className="spark-popover__list-item">
            <NoDecorationLink
              role="button"
              tabIndex={0}
              className="spark-popover__list-link spark-link"
              onClick={evt =>
                evt.ctrlKey ? window.open('/admin-centre' + bundleLink, '_blank') : navigate(bundleLink)
              }
            >
              {formatMessage({id: 'common.manage'})}
            </NoDecorationLink>
          </li>
        )}
        {isAllowedToEditProducts() &&
          redAppTypeC === CcrzEProductCSObjectRedAppTypeCEnum.Regular &&
          bundleDetails.status === Status.ValidationFailed && (
            <ActionItem
              handleOpen={handleOpen}
              edit={setSvsErrors}
              bundle={bundleDetails}
              messageId="tabs.bundle.svsErrors.openButton"
            />
          )}
        {isAllowedToAddRemoveTesters() && (
          <ActionItem
            handleOpen={handleOpen}
            edit={setEditedBundle}
            bundle={bundleDetails}
            messageId="tabs.bundle.compatibilityForm.openButton"
            disabled={
              bundleDetails.status === Status.BundleSubmitted || bundleDetails.status === Status.ValidationFailed
            }
          />
        )}
        {bundleDetails.isPatch && (
          <ActionItem
            handleOpen={handleOpen}
            edit={setEditedReleaseNotes}
            bundle={bundleDetails}
            messageId="tabs.bundle.compatibilityForm.releaseNotes"
          />
        )}
        <ActionItem
          handleOpen={handleOpen}
          edit={setEditedSimplifiedDescription}
          bundle={bundleDetails}
          messageId="tabs.bundle.simplifiedDescription.openButton"
        />
        {bundleDetails.cleanup && (
          <ActionItem
            handleOpen={handleOpen}
            edit={setEditedCleanupDates}
            bundle={bundleDetails}
            messageId="tabs.bundle.cleanupDates.openButton"
          />
        )}
        {isAllowedToEditProducts() && redAppTypeC === CcrzEProductCSObjectRedAppTypeCEnum.Regular && (
          <ActionItem
            handleOpen={handleOpen}
            edit={setVersionFilesDetails}
            bundle={bundleDetails}
            messageId="tabs.bundle.versionFilesDetails.openButton"
          />
        )}
      </ul>
    </Popover>
  );
};

const NoDecorationLink = styled.span`
  text-decoration: none !important;

  &:focus-visible {
    text-decoration: underline !important;
  }
`;

export default RowActions;
