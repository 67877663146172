const processScheme = {
  'processScheme.workingDay.label': 'WD - working day',
  'processScheme.redApp.button.text': 'See Red App publishing process',
  'processScheme.pfKeys.button.text': 'See PF Keys publishing process',
  'processScheme.scribes.button.text': 'See Scribe Script publishing process',
  'processScheme.proposal.failedInfo': 'This proposal has been rejected.',
  'processScheme.product.failedInfo':
    'Certification process for this version has failed or has been cancelled. Create and submit a new version bundle to start certification again.',
  'processScheme.redApp.title': 'Red App Publishing Process',
  'processScheme.redAppVersionCertification.title': 'Red App Version Certification Process',
  'processScheme.publicApp.step1': 'Submit Proposal',
  'processScheme.publicApp.step2': 'Proposal Review',
  'processScheme.publicApp.action2': 'action on Sabre side',
  'processScheme.publicApp.action2.time': '(up to 3 WD)',
  'processScheme.publicApp.step3': 'Submit Bundle + automated validation',
  'processScheme.publicApp.action3': "action on developer's side",
  'processScheme.publicApp.step4': 'Tests on CERT',
  'processScheme.publicApp.action4': "action on developer's side",
  'processScheme.publicApp.step5': 'Certification',
  'processScheme.publicApp.action5': 'action on Sabre side',
  'processScheme.publicApp.action5.time': '(up to 15 WD; 1-3 WD on average)',
  'processScheme.publicApp.step6': 'Tests on PROD',
  'processScheme.publicApp.action6': "action on developer's side",
  'processScheme.publicApp.step7': 'Complete storefront content',
  'processScheme.publicApp.action7': "action on developer's side",
  'processScheme.publicApp.step8': 'Publish on Sabre Central Marketplace',
  'processScheme.publicApp.action8': "action on developer's side",
  'processScheme.privateApp.title': 'Private Red App Publishing Process Scheme',
  'processScheme.privateApp.step1': 'Submit Proposal',
  'processScheme.privateApp.step2': 'Proposal Review',
  'processScheme.privateApp.action2': 'action on Sabre side',
  'processScheme.privateApp.action2.time': '(up to 3 WD)',
  'processScheme.privateApp.step3': 'Submit Bundle + automated validation',
  'processScheme.privateApp.action3': "action on developer's side",
  'processScheme.privateApp.step4': 'Tests on CERT',
  'processScheme.privateApp.action4': "action on developer's side",
  'processScheme.privateApp.step5': 'Certification',
  'processScheme.privateApp.action5': 'action on Sabre side',
  'processScheme.privateApp.action5.time': '(up to 15 WD; 1-3 WD on average)',
  'processScheme.privateApp.step6': 'Tests on PROD',
  'processScheme.privateApp.action6': "action on developer's side",
  'processScheme.privateApp.step7': 'Publish on Sabre Central Marketplace',
  'processScheme.privateApp.action7': "action on developer's side",
  'processScheme.pfKeys.title': 'PF Keys Publishing Process',
  'processScheme.pfKeys.step1': 'Submit PF Keys',
  'processScheme.pfKeys.action1': 'immediately',
  'processScheme.pfKeys.step2': 'Tests on PROD',
  'processScheme.pfKeys.action2': "action on developer's side",
  'processScheme.pfKeys.step3': 'Publish on Sabre Central Marketplace',
  'processScheme.pfKeys.action3': "action on developer's side",
  'processScheme.scribes.title': 'Scribes Publishing Process',
  'processScheme.scribes.step1': 'Submit Scribe Script',
  'processScheme.scribes.action1': 'immediately',
  'processScheme.scribes.step2': 'Tests on PROD',
  'processScheme.scribes.action2': "action on developer's side",
  'processScheme.scribes.step3': 'Publish on Sabre Central Marketplace',
  'processScheme.scribes.action3': "action on developer's side",
};

export default processScheme;
