export const environmentName = 'environment';
export const startDateName = 'startDate';
export const startHourName = 'startHour';
export const endDateName = 'endDate';
export const endHourName = 'endHour';
export const commentName = 'comment';

export interface FreezeCalendarValues {
  [environmentName]: Array<string>;
  [startDateName]: string;
  [startHourName]: string;
  [endDateName]: string;
  [endHourName]: string;
  [commentName]: string;
}
