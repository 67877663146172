import SortToggle from 'pages/partnerDirectory/searchResults/SortToggle';
import CenteredErrorMessage from 'components/styledComponents/CenteredErrorMessage';
import {useQuery} from '@apollo/client';
import CenterItems from 'components/styledComponents/CenterItems';
import React, {useCallback, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'utils/rootReducer';
import PartnerTile from 'pages/partnerDirectory/searchResults/PartnerTile';
import InternalUrls from 'utils/internalUrls';
import NoResultsFound from 'pages/partnerDirectory/NoResultsFound';
import {useIntl} from 'react-intl';
import {bindActionCreators} from 'redux';
import {setAvailableFilterOptions, setRefetch} from 'pages/partnerDirectory/store/actions';
import {GetPartnersDocument, GetPartnersQuery, GetPartnersQueryVariables} from 'generated/graphql';
import getPartnersQueryVariables from 'utils/getPartnersQueryVariables';
import {fetchPartnersBatchSize} from './SearchResults';
import InfiniteScroll from 'react-infinite-scroll-component';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';
import {Loading} from '@scm/components';

const SearchResultsBlock = () => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const {formatMessage} = useIntl();
  const {
    isFilterPanelExpanded,
    filters: {regions, categories, tiers},
    searchTerm,
    sorting,
  } = useSelector((state: AppState) => state.partnerDirectoryState);

  const {data, refetch, fetchMore, error, loading} = useQuery<GetPartnersQuery, GetPartnersQueryVariables>(
    GetPartnersDocument,
    getPartnersQueryVariables(sorting, regions, categories, tiers, searchTerm)
  );

  const partners = () => {
    const mappedPartners = data?.getPartners.data.partners.map((partner, index) => (
      <PartnerTile
        key={index}
        logoUrl={partner.logoUrl!}
        title={partner.name!}
        tier={partner.tier}
        shortDescription={partner.shortDescription!}
        approvalDate={partner.approvalDate}
        partnerLink={`${InternalUrls.Partners}/${encodeURIComponent(partner.name!)}`}
        isFullPageWidth={!isFilterPanelExpanded}
      />
    ));

    if (!data && loading)
      return (
        <CenterItems>
          <Loading label={formatMessage({id: 'common.data.fetchingPartners'})} />
        </CenterItems>
      );

    if (!error) {
      return mappedPartners?.length ? mappedPartners : <NoResultsFound />;
    }
  };

  const dispatch = useDispatch();
  const setRefetchAction = useCallback(() => bindActionCreators(setRefetch, dispatch), [dispatch]);
  const setAvailableAction = useCallback(() => bindActionCreators(setAvailableFilterOptions, dispatch), [dispatch]);

  useEffect(() => {
    setRefetchAction()(refetch);
    const regions = data?.getPartners.data.regions || [];
    const categories = data?.getPartners.data.categories || [];

    setAvailableAction()({regions, categories});
  }, [data, setRefetchAction, setAvailableAction, refetch]);

  const showMorePartners = async () => {
    await fetchMore({
      variables: {
        skip: data?.getPartners.data.partners.length,
        take: fetchPartnersBatchSize,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;

        fetchMoreResult.getPartners.data.partners = [
          ...prev.getPartners.data.partners,
          ...fetchMoreResult.getPartners.data.partners,
        ];
        return fetchMoreResult;
      },
    });
  };

  return (
    <SearchResultsBlockContainer mobile={isMobile}>
      {!isMobile && <SortToggle />}
      {error && <CenteredErrorMessage error={error} />}
      <InfiniteScroll
        dataLength={data?.getPartners.data.partners.length! ?? 0}
        next={showMorePartners}
        hasMore={data?.getPartners.hasMore ?? false}
        loader={
          <CenterItems>
            <Loading label={formatMessage({id: 'common.data.fetchingPartners'})} />
          </CenterItems>
        }
      >
        <Partners data-testid="partners">{partners()}</Partners>
      </InfiniteScroll>
      {!isMobile && (
        <div>
          <GoTop
            className="spark-icon-arrow-basic-up spark-icon--fill spark-icon--lg"
            onClick={() => window.scrollTo(0, 0)}
          />
        </div>
      )}
    </SearchResultsBlockContainer>
  );
};

const SearchResultsBlockContainer = styled.div.attrs(() => ({
  className: 'spark-flex',
}))<{mobile?: boolean}>`
  flex-direction: column;
  overflow-anchor: none;

  ${props => (props.mobile ? '' : 'box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);')};
  ${props => (props.mobile ? '' : `background: ${colors.white}`)};

  margin-top: ${props => (props.mobile ? '1rem' : '2rem')};
  padding: ${props => (props.mobile ? '' : '2rem')};
`;

const Partners = styled.div.attrs(() => ({
  className: 'spark-flex',
}))`
  flex-wrap: wrap;
  justify-content: center;
  min-height: 50vh;
`;

const GoTop = styled.i`
  position: relative;
  left: 95%;
  transition: top ease-in 0.1s;

  :hover {
    cursor: pointer;
    top: -0.5rem;
    transition: top ease-out 0.1s;
  }
`;

export default SearchResultsBlock;
