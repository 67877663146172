import {LoadingContainer} from '@scm/components/form/LoadingContainer';
import {Loading} from '@scm/components';
import {HeaderContainer} from '@scm/product-components/pages/productDetails/actionForms/NewBundleForm';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {useEffect, useRef, useState} from 'react';
import {ManageNotificationsContainer} from './ManageNotificationsList';
import {Accordion, Badge, Button, Drawer, Message, Modal, TextInput, Tooltip} from '@sabre/spark-react-core';
import {useForm} from 'react-hook-form';
import {
  notificationBodyName,
  notificationName,
  NotificationTemplateValues,
  recipientsBccName,
  recipientsName,
} from './schema/NotificationTemplateValues';
import {Configuration, NotificationApi, NotificationTemplateFields} from '../../../generated/notification';
import {notificationApiBaseLink} from '../assets/apiBaseLink';
import {getAccessToken} from '../../../utils/authentication';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {subjectName} from '@scm/roadmap/utils/RoadmapDataValues';
import {
  BadgeStatus,
  ButtonSize,
  MessageRole,
  MessageStatus,
  MessageType,
  ToastType,
  TooltipDirectionX,
  TooltipDirectionY,
} from '@sabre/spark-react-core/types';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {TextArea} from '@scm/proposal/pages/proposalForm/steps/definitionPage/definitionComponents/Description';
import NotificationPreviewModal from './NotificationPreviewModal';
import {HeaderText} from '@scm/admin-centre/src/components/App';
import {openToast} from '@scm/components/messaging/openToast';
import {mapToUpdateNotificationRequest, notificationResponseMapper} from './components/notificationMapper';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {
  Configuration as PresentationConfiguration,
  PresentationApi,
} from '@scm/product-components/generated/presentation';
import {apiBaseLink} from '@scm/my-products/assets/apiUrl';
import styled from 'styled-components';
import {DrawerHeading} from '@sabre/spark-react-core/accordion/drawer.types';

export const fetchNotificationTemplate = async (id: string) =>
  new NotificationApi(
    new Configuration({
      basePath: notificationApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware,
    })
  ).getNotificationTemplateById({id});

export const updateNotificationTemplate = async (id: string, data: NotificationTemplateFields) =>
  new NotificationApi(
    new Configuration({
      basePath: notificationApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware,
    })
  ).updateNotificationTemplate({id, notificationTemplateFields: data});

export const fetchNotificationDynamicValues = async (id: string) =>
  new PresentationApi(
    new PresentationConfiguration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware,
    })
  ).getNotificationParams({id});

const ManageNotification = () => {
  const {
    watch,
    setValue,
    reset,
    formState: {isDirty},
  } = useForm<NotificationTemplateValues>({
    mode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [modalKey, setModalKey] = useState(1);
  const [dynamicValues, setDynamicValues] = useState<string[][]>([]);

  const textRef = useRef<HTMLTextAreaElement>(null);

  const navigate = useNavigate();
  const {notificationId} = useParams<{
    notificationId: string;
  }>();

  useEffect(() => {
    fetchNotificationTemplate(notificationId ?? '')
      .then(response => {
        notificationResponseMapper(setValue, response);
        fetchNotificationDynamicValues(notificationId ?? '').then(response => setDynamicValues(response));
        reset(watch());
      })
      .catch(() => navigate(InternalUrls.ManageNotifications))
      .finally(() => setIsLoading(false));
  }, []);

  const handleOpen = () => {
    setModalKey(key => key + 1);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  const {formatMessage} = useIntl();

  const saveHandler = () => {
    if (!notificationId || !watch()) {
      return;
    }
    updateNotificationTemplate(notificationId ?? '', mapToUpdateNotificationRequest(watch))
      .then(() => {
        openToast(
          formatMessage({id: 'common.success'}),
          formatMessage({id: 'manageNotification.toast.success.description'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
        reset(watch());
      })
      .catch(() => {
        openToast(
          formatMessage({id: 'common.error'}),
          formatMessage({id: 'common.error.default'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      });
  };

  const cancelHandler = () => reset();

  const getDynamicValueName = (value: string[]) => {
    if (!value[0].includes('_')) return 'Additional Parameters';
    return value.length ? value[0].replaceAll(/[{}]/g, '').split('.')[1].split('_')[0] : '';
  };

  const readableValue = (value: string) => {
    const splitValue = value.replaceAll(/[{}]/g, '').split('.')[1].split('_');
    if (splitValue.length === 1) return splitValue[0];
    return splitValue.slice(1).join('.');
  };

  const handleBadgeClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, val: string) => {
    event.preventDefault();
    const input = textRef.current!;

    const {selectionStart, selectionEnd, value} = input;
    setValue(notificationBodyName, value.slice(0, selectionStart) + val + value.slice(selectionEnd), {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <>
      <Modal key={modalKey} onClose={handleClose} open={isOpen} title="Notification Preview" titleHeadingLevel={2}>
        <NotificationPreviewModal watch={watch} handleClose={handleClose} />
      </Modal>
      <div className="spark-pad-2">
        <HeaderContainer className="spark-flex spark-mar-b-2 spark-pad-r-1">
          <HeaderText>{watch(notificationName)}</HeaderText>
        </HeaderContainer>
        <ManageNotificationsContainer className="spark-panel spark-pad-2">
          <div>
            {dynamicValues.length ? (
              <Accordion className="spark-mar-b-1">
                <Drawer title="Dynamic Values" headingLevel={DrawerHeading.H2}>
                  <Accordion className="spark-mar-b-1">
                    {dynamicValues.map((val, index) => (
                      <Drawer key={index} title={getDynamicValueName(val)} headingLevel={DrawerHeading.H3}>
                        {val.map(value => (
                          <Tooltip
                            anchorX={TooltipDirectionX.CENTER}
                            anchorY={TooltipDirectionY.BOTTOM}
                            key={value}
                            toggleEl={
                              <BadgeWrapper
                                className="spark-mar-b-1 spark-mar-r-2 "
                                onClick={event => handleBadgeClick(event, value)}
                                label={readableValue(value)}
                                status={BadgeStatus.NEUTRAL}
                              />
                            }
                          >
                            <FormattedMessage
                              id="manageNotifications.details.tooltip"
                              values={{
                                value: value,
                                strong: (word: string) => <strong>{word}</strong>,
                              }}
                            />
                          </Tooltip>
                        ))}
                      </Drawer>
                    ))}
                  </Accordion>
                </Drawer>
              </Accordion>
            ) : (
              <Message
                content={formatMessage(
                  {id: 'manageNotifications.details.modal.warning.noDynamicParams.content'},
                  {
                    strong: (word: string) => <strong>{word}</strong>,
                  }
                )}
                role={MessageRole.ALERT}
                status={MessageStatus.WARNING}
                title={formatMessage({id: 'manageNotifications.details.modal.warning.noDynamicParams.title'})}
                type={MessageType.PAGE}
                titleHeadingLevel={2}
              />
            )}
          </div>
          <TextInput
            value={watch(subjectName)}
            label={formatMessage({id: 'manageNotifications.details.form.subject'})}
            name={subjectName}
            onChange={(_, value) => setValue(subjectName, value, {shouldDirty: true})}
          />
          <TextInput
            value={watch(recipientsName)}
            label={formatMessage({id: 'manageNotifications.details.form.recipients'})}
            name={recipientsName}
            onChange={(_, value) => setValue(recipientsName, value, {shouldDirty: true})}
          />
          <TextInput
            value={watch(recipientsBccName)}
            label={formatMessage({id: 'manageNotifications.details.form.recipientsBcc'})}
            name={recipientsName}
            onChange={(_, value) => setValue(recipientsBccName, value, {shouldDirty: true})}
          />
          <TextArea
            multiLine
            characterCount
            ref={textRef}
            value={watch(notificationBodyName)}
            label={formatMessage({id: 'manageNotifications.details.form.notificationBody'})}
            name={notificationBodyName}
            onChange={(_, value) => setValue(notificationBodyName, value, {shouldDirty: true})}
          />
          <div className="spark-mar-t-2 spark-btn-group">
            <Button secondary disabled={!isDirty} type="button" onClick={cancelHandler} size={ButtonSize.SMALL}>
              <FormattedMessage id="common.reset" />
            </Button>
            <Button className="spark-btn-group-primary" onClick={handleOpen} type="button" size={ButtonSize.SMALL}>
              <FormattedMessage id="common.preview" />
            </Button>
            <Button
              className="spark-btn-group-primary"
              disabled={!isDirty}
              onClick={saveHandler}
              type="button"
              size={ButtonSize.SMALL}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </div>
        </ManageNotificationsContainer>
      </div>
    </>
  );
};

const BadgeWrapper = styled(Badge)`
  cursor: pointer;
  user-select: none;
`;
export default ManageNotification;
