import React, {ReactElement, useContext} from 'react';
import {useIntl} from 'react-intl';
import {ProposalContext} from '../../../../ProposalForm';
import {alignSecureCodingPracticesName, patchTimelineName} from '../../ServiceAndSecurityValues';
import {Checkbox} from '@sabre/spark-react-core';
import {Controller} from 'react-hook-form';
import {TextArea} from '../../../definitionPage/definitionComponents/Description';
import {createNestedObjectName} from '../servicesComponents/Services';
import {serviceAndSecurityValuesName} from '../../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';

const SecureCodingPractices = () => {
  const {formatMessage} = useIntl();
  const {control, watch, errors, setValue} = useContext(ProposalContext);
  const descriptionMaxLength = 250;

  return (
    <>
      <Controller
        name={createNestedObjectName(alignSecureCodingPracticesName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (value) {
                setValue(createNestedObjectName(patchTimelineName), '', {shouldValidate: true});
              }
            }}
            checked={value}
            label={formatMessage({id: 'serviceAndSecurity.alignSecureCodingPractices.label'})}
            className="spark-mar-b-0 spark-mar-b-2"
          />
        )}
      />
      {watch(createNestedObjectName(alignSecureCodingPracticesName)) && (
        <div className="spark-pad-l-2">
          <Controller
            name={createNestedObjectName(patchTimelineName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.patchTimeline.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                status={errors[serviceAndSecurityValuesName]?.[patchTimelineName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[patchTimelineName] &&
                  errors[serviceAndSecurityValuesName]?.[patchTimelineName]?.message
                }
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default SecureCodingPractices;
