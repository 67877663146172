import {CSSTransition} from 'react-transition-group';
import React from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {AppState} from 'utils/rootReducer';
import Accordion from 'spark/Accordion';
import {CATEGORIES, REGIONS, TIERS} from 'pages/partnerDirectory/store/types';
import FiltersAccordionItem from 'pages/partnerDirectory/searchPanel/FiltersAccordionItem';
import {useQuery} from '@apollo/client';
import {GetFilterOptionsDocument, GetFilterOptionsQuery} from 'generated/graphql';
import CenterItems from 'components/styledComponents/CenterItems';
import {useIntl} from 'react-intl';
import {useMediaQuery} from 'react-responsive';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';
import {Loading} from '@scm/components';

const Filters = () => {
  const {isFilterPanelExpanded} = useSelector((state: AppState) => state.partnerDirectoryState);
  const nodeRef = React.useRef(null);
  const {formatMessage} = useIntl();
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  const {data, loading} = useQuery<GetFilterOptionsQuery>(GetFilterOptionsDocument);
  const swapGlobal = (regions?: string[]) => {
    const isGlobal = (region: string) => region === 'Global';
    const globalIndex = regions?.findIndex(isGlobal);
    return globalIndex && regions ? [regions[globalIndex], ...regions.filter(region => !isGlobal(region))] : regions;
  };

  return (
    <StyledTransition mobile={isMobile}>
      <CSSTransition nodeRef={nodeRef} in={isFilterPanelExpanded} timeout={200} classNames="filters" unmountOnExit>
        <SearchFilters ref={nodeRef} mobile={isMobile}>
          {loading ? (
            <CenterItems className="spark-pad-t-2">
              <Loading sizing={'md'} label={formatMessage({id: 'common.data.fetchingFilters'})} />
            </CenterItems>
          ) : (
            <Accordion>
              <FiltersAccordionItem
                id="regions"
                title={formatMessage({id: 'partnerDirectoryPage.regions'})}
                options={swapGlobal(data?.getFilterOptions?.regions)}
                filterBy={REGIONS}
              />
              <FiltersAccordionItem
                id="categories"
                title={formatMessage({id: 'partnerDirectoryPage.categories'})}
                options={data?.getFilterOptions?.categories}
                filterBy={CATEGORIES}
              />
              <FiltersAccordionItem
                id="tiers"
                title={formatMessage({id: 'partnerDirectoryPage.tiers'})}
                options={data?.getFilterOptions?.tiers}
                filterBy={TIERS}
              />
            </Accordion>
          )}
        </SearchFilters>
      </CSSTransition>
    </StyledTransition>
  );
};

const StyledTransition = styled.div<{mobile?: boolean}>`
  .filters-enter {
    width: 0;
    opacity: 0;
    height: 100%;
  }

  .filters-enter-active {
    width: ${props => (props.mobile ? '100%' : '20vw')};
    height: 100%;
    opacity: 1;
    transition: width 0.25s ease-out, opacity 0.35s ease-in;
  }

  .filters-exit {
    width: 0;
    opacity: 0;
    transition: width 0.25s ease-in, opacity 0.15s ease-in;
  }
`;

const SearchFilters = styled.div<{mobile?: boolean}>`
  background-color: ${colors.white};

  width: ${props => (props.mobile ? '100%' : '20vw')};
  ${props => (props.mobile ? '' : 'height: 100%;')};
`;

export default Filters;
