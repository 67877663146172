const error = {
  'error.title.generic': 'Something went wrong',
  'error.message.generic': 'Please try again later...',
  'error.title.PARTNER_NOT_FOUND_EXCEPTION': 'Something went wrong',
  'error.message.PARTNER_NOT_FOUND_EXCEPTION': "Sorry, it looks like we couldn't find any matching partner...",
  'error.title.fetchingFailed': 'Failed to fetch data',
  'error.message.fetchingFailed': 'Please try again later...',
  'error.title.notSupportedBrowser': "We've detected that you are using Internet Explorer",
  'error.message.notSupportedBrowser':
    'Most of our functionalities are not supported by this browser. Please open Sabre Partner Network in Google Chrome or Mozilla Firefox.',
  'error.message.lazyImport': 'Could not find module {module}',
};

export default error;
