import {
  bargainFinderMaxName,
  externalServicesDescriptionName,
  externalServicesName,
  hostCommandsDescriptionName,
  newSabreRedTechnologiesDescriptionName,
  newSabreRedTechnologiesName,
  sabreServicesName,
  utilizedMethodName,
} from '../../ServiceAndSecurityValues';

export const serviceInitialValues = {
  [sabreServicesName]: false,
  [hostCommandsDescriptionName]: '',
  [bargainFinderMaxName]: '',
  [externalServicesName]: false,
  [externalServicesDescriptionName]: '',
  [utilizedMethodName]: '',
  [newSabreRedTechnologiesName]: false,
  [newSabreRedTechnologiesDescriptionName]: '',
};
