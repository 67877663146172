export const userSellersName = 'userSellers';
export const allSellersName = 'allSellers';

export interface Seller {
  id: string;
  name: string;
  canSubmitProposal: boolean;
}

export interface SellersValues {
  [userSellersName]: Array<Seller>;
  [allSellersName]: Array<Seller>;
}
