import * as yup from 'yup';

export default yup
  .object()
  .required()
  .shape({
    firstName: yup.string().required().max(100),
    lastName: yup.string().required().max(100),
    titleInCompany: yup.string().required().max(100),
    email: yup
      .string()
      .required()
      .max(100)
      .min(6)
      .email()
      .matches(
        /^((?!(@[yY][aA][hH][oO][oO]|@[gG][mM][aA][iI][lL]|@[oO][uU][tT][lL][oO][oO][kK]|@[hH][oO][tT][mM][aA][iI][lL]|@[mM][sS][nN])).)*$/
      ),
    phone: yup
      .string()
      .required()
      .max(100)
      .min(6)
      .matches(/^(\()?(\+)?\d+(\))?(\s*\d+\s*\d+)*$/),
  });
