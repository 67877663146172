const regionalization = {
  'regionalization.title': 'Regionalization',
  'regionalization.additionalStatement':
    'After proposal is accepted, you will be able to modify this information any time on Regionalization Tab.',
  'regionalization.checkbox.mainLabel': 'Provide regions or countries where your Red App will be available:',
  'regionalization.checkbox.globalRegionalizationLabel':
    'My Red App access should be limited to specific countries or/and regions',
  'regionalization.details.mainLabel':
    'Please provide any additional information about your current customers, your target market, or any future markets, if applicable.',
  'regionalization.details.label': 'Comments',
  'regionalization.details.placeholder': 'Put your comments here.',
  'regionalization.checkAll.label': 'All Regions & Countries',
  'regionalization.search.label': 'Search Regions & Countries',
  'regionalization.select.header': 'Countries',
};

export default regionalization;
