import React, {useContext, useEffect, useState} from 'react';
import {RadioButton, RadioButtonGroup, SelectInput, TextInput} from '@sabre/spark-react-core';
import styled from 'styled-components';
import {ProposalDataViewContext} from './ProposalViewDataProvider';
import Category from '../../proposalForm/steps/definitionPage/definitionComponents/Category';
import {allName, countryCodesName, redAppCategoryName, regionCodesName} from './ProposalDataValues';
import {countriesInRegion, regions} from '@scm/components/assets/regionsAndCountries';
import {ActionBadgesDisplaysType} from '@scm/product-components/pages/productDetails/productTabs/ProductTabsContainer';
import {shouldDirty} from '@scm/components/form/multiSelect/MultiSelect';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {descriptionMaxLength, nameMaxLength, requiredArrayMessage} from '../../../schemas/proposalConstants';
import {descriptionName} from '../../proposalForm/ProposalValues';
import {maxPricingLength} from '../../../schemas/updateProposalSchema';
import Regionalization from '../../proposalForm/steps/definitionPage/definitionComponents/Regionalization';
import InlineButton from '@scm/admin-centre/src/components/InlineButton';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

export enum DataType {
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  Text = 'Text',
  TextList = 'TextList',
  File = 'File',
  Checkbox = 'Checkbox',
  Radio = 'Radio',
}

export interface SelectOptions {
  label: string;
  value: string;
}

export interface ProposalDataOptions {
  name: string;
  label: string;
  defaultValue: string;
  selectOptions: Array<SelectOptions>;
  placeholder: string;
  value: string | boolean;
  firstLevelValues?: string[];
  secondLevelValues?: string[];
  link: string;
  isChecked: boolean;
  disabled: boolean;
  clickHandler: () => void;
}

export const ProposalDataItem = ({dataType, options}: {dataType: DataType; options: ProposalDataOptions}) => {
  const {setValue, watch, errors} = useContext(ProposalDataViewContext);

  if (dataType === DataType.Select) {
    const {defaultValue, label, name, selectOptions, disabled} = options;
    return (
      <SelectInput
        value={watch(name) ?? defaultValue}
        onChange={(e, value) => !disabled && setValue(name, value, {shouldDirty: true})}
        disabled={disabled}
        label={label}
        name={name}
        options={selectOptions}
      />
    );
  } else if (dataType === DataType.MultiSelect) {
    const {name, firstLevelValues, secondLevelValues, disabled} = options;

    const setListEmpty = (list: string) => setValue(list, [], shouldDirty);
    const allRegionsCodes = Object.keys(regions);
    const allCountries = Object.values(countriesInRegion);
    const allCountriesCodes = allCountries.reduce((result, countries) => result.concat(Object.keys(countries)), []);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
      setValue(name, firstLevelValues);
      if (name === regionCodesName) setValue(countryCodesName, secondLevelValues);
      if (firstLevelValues[0] === 'Global' && watch(allName) !== true) {
        setValue(allName, true);
        setValue(regionCodesName, allRegionsCodes);
        setValue(countryCodesName, allCountriesCodes);
      }
    }, [setValue]);

    const deleteGlobal = () => {
      setListEmpty(regionCodesName);
      setListEmpty(countryCodesName);
      setValue(allName, false, shouldDirty);
    };

    const deleteRegion = (region: string, name: string) => {
      setValue(
        regionCodesName,
        watch(regionCodesName).filter((code: string) => (regions as {[key: string]: string})[code] !== name),
        shouldDirty
      );
      setValue(
        countryCodesName,
        watch(countryCodesName).filter(
          (currentCode: string) =>
            !Object.keys((countriesInRegion as unknown as {[key: string]: Object})[region]).includes(currentCode)
        ),
        shouldDirty
      );
    };

    const deleteCountry = (name: string) => {
      setValue(
        countryCodesName,
        watch(countryCodesName).filter(
          (code: string) =>
            (
              allCountries.reduce((previousValue, currentValue) => Object.assign(previousValue, currentValue), {}) as {
                [key: string]: string;
              }
            )[code] !== name
        ),
        shouldDirty
      );
    };

    const deleteCountryOrRegion = (name: string) => {
      const region = Object.keys(regions)[Object.values(regions).indexOf(name)];
      if (name?.toLowerCase() === allName) {
        deleteGlobal();
      } else if (region) {
        deleteRegion(region, name);
      } else {
        deleteCountry(name);
      }
    };

    const actionBadgesDisplays: ActionBadgesDisplaysType = () => {
      if (watch(allName)) {
        return ['Global'];
      }

      const selectedRegions = [
        ...watch(regionCodesName).map((code: string) => (regions as {[key: string]: string})[code]),
      ];
      const selectedCountriesNotInSelectedRegions = Object.keys(regions)
        .filter(regionCode => !watch(regionCodesName).includes(regionCode))
        .map(regionCode => (countriesInRegion as unknown as {[key: string]: Object})[regionCode])
        .map(countries => Object.keys(countries).filter(countryCode => watch(countryCodesName).includes(countryCode)))
        .flat()
        .map(
          (code: string) =>
            (
              allCountries.reduce((previousValue, currentValue) => Object.assign(previousValue, currentValue), {}) as {
                [key: string]: string;
              }
            )[code]
        );

      return [...selectedRegions, ...selectedCountriesNotInSelectedRegions];
    };
    const checkIfEmpty = () => !watch(regionCodesName).length && !watch(countryCodesName).length;

    return (
      <div className="spark-pad-l-0">
        {name === redAppCategoryName && (
          <Category
            firstLevelName={name}
            watch={watch}
            setValue={setValue}
            className="col-xs-8 spark-mar-t-1 spark-mar-b-1"
            disabled={disabled}
            errors={errors}
          />
        )}
        {name === regionCodesName && (
          <>
            <Regionalization
              setSearchText={setSearchText}
              searchText={searchText}
              setValue={setValue}
              watch={watch}
              actionBadgesDisplays={actionBadgesDisplays}
              countriesReduced={allCountries}
              deleteCountryOrRegion={deleteCountryOrRegion}
              className="spark-pad-l-0 col-lg-10 col-xs-8 spark-mar-t-1"
              disabled={disabled}
              error={checkIfEmpty() ? {message: requiredArrayMessage} : undefined}
            />
          </>
        )}
      </div>
    );
  } else if (dataType === DataType.Text) {
    const {label, name, placeholder, defaultValue, disabled} = options;
    return (
      <TextInput
        value={watch(name) ?? defaultValue}
        onChange={(e, value) =>
          !disabled && setValue(name, value, {shouldDirty: true, shouldValidate: true, shouldTouch: true})
        }
        label={label}
        name={name}
        disabled={disabled}
        placeHolder={placeholder}
        characterCount
        maxLength={nameMaxLength}
        status={errors[name] ? MessageStatus.ERROR : undefined}
        statusMessage={errors[name] && errors[name]?.message}
      />
    );
  } else if (dataType === DataType.TextList) {
    const {label, name, value, disabled} = options;

    return (
      <TextInput
        label={label}
        multiLine
        name={name}
        value={watch(name) ?? value}
        onChange={(e, value) =>
          !disabled && setValue(name, value, {shouldDirty: true, shouldValidate: true, shouldTouch: true})
        }
        disabled={disabled}
        className="spark-mar-b-1"
        characterCount
        maxLength={name === descriptionName ? descriptionMaxLength : maxPricingLength}
        status={errors[name] ? MessageStatus.ERROR : undefined}
        statusMessage={errors[name] && errors[name]?.message}
      />
    );
  } else if (dataType === DataType.File) {
    const {clickHandler} = options;
    return (
      <div className="spark-pad-t-1">
        <InlineButton
          id="proposalDetails.download.flowDiagram"
          clickHandler={clickHandler}
          className="spark-btn spark-mar-r-1 spark-mar-b-1"
        />
      </div>
    );
  } else if (dataType === DataType.Radio) {
    const {name, value, disabled} = options;
    return (
      <RadioButtonContainer>
        <RadioButtonGroup
          className="row spark-mar-l-2"
          name={name}
          onChange={(e, value) => setValue(name, value, {shouldDirty: true})}
          value={typeof value === 'boolean' ? (value ? Confirmation.Yes : Confirmation.No) : value}
          aria-label={name}
        >
          <RadioButton
            className="col-xs-6"
            label={Confirmation.Yes}
            value={Confirmation.Yes}
            aria-label={`Question response - ${Confirmation.Yes}`}
            disabled={disabled}
          />
          <RadioButton
            className="col-xs-6"
            label={Confirmation.No}
            value={Confirmation.No}
            aria-label={`Question response - ${Confirmation.No}`}
            disabled={disabled}
          />
        </RadioButtonGroup>
      </RadioButtonContainer>
    );
  }
};

const RadioButtonContainer = styled.div`
  max-width: 220px;
  @media (min-width: 1048px) {
    transform: translateY(2rem);
  }
  && [checked] + span {
    background-color: transparent !important;
  }
`;
