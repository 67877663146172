import {IconSize} from '@sabre/spark-react-core/types';
import React, {useState} from 'react';
import useGetProductC from '../../../../hooks/useGetProductC';
import {Icon} from '@sabre/spark-react-core';
import Modal from '@sabre/spark-react-core/modal';
import useGetRecordType from '../../../../hooks/useGetRecordType';
import {useIntl} from 'react-intl';
import StandardProductChangeNameForm from './StandardProductChangeNameForm';
import {createPortal} from 'react-dom';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const NameHeading = () => {
  const {formatMessage} = useIntl();

  const {name, ccrzSKUC} = useGetProductC();
  const recordType = useGetRecordType();

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  return (
    <>
      <span aria-describedby={name}>{name}</span>
      {recordType.name === 'Standard' ? (
        <span>
          <IconWrapper
            name="pencil"
            className="spark-pad-.5 spark-mar-r-.5 spark-icon--fill"
            onClick={handleOpenModal}
          />
          {createPortal(
            <Modal
              onClose={handleCloseModal}
              open={openModal}
              title={formatMessage({id: 'productDetails.icon.changeName.modal.title'})}
            >
              <StandardProductChangeNameForm
                handleCloseModal={handleCloseModal}
                sku={ccrzSKUC as string}
                currentName={name as string}
              />
            </Modal>,
            document.body
          )}
        </span>
      ) : null}
    </>
  );
};

const IconWrapper = styled(Icon)`
  color: ${colors.sparkBlue} !important;
  cursor: pointer;
  font-size: 1.2rem !important;
  line-height: 1.2rem !important;

  &::before {
    font-size: 1.2rem !important;
    line-height: 1.2rem !important;
  }
`;

export default NameHeading;
