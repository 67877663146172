import InternalUrls from 'utils/internalUrls';
import SearchPanel from 'pages/partnerDirectory/searchPanel/SearchPanel';
import SearchResults from 'pages/partnerDirectory/searchResults/SearchResults';
import MediaQuery from 'react-responsive';
import React from 'react';
import styled from 'styled-components';
import PartnerDirectoryBackground from 'assets/images/partner_directory_background.png';
import {minDesktopWidth} from 'assets/deviceWidth';
import {BreadcrumbLink, Breadcrumbs} from '@scm/components';

const PartnerDirectoryDesktop = () => (
  <MediaQuery minDeviceWidth={minDesktopWidth}>
    <BackgroundImage />
    <Breadcrumbs>
      <BreadcrumbLink intlId="common.breadcrumb.home" href={InternalUrls.Home} />
      <BreadcrumbLink intlId="common.breadcrumb.partnerDirectory" />
    </Breadcrumbs>
    <div className="spark-flex">
      <SearchPanel />
      <SearchResults />
    </div>
  </MediaQuery>
);

const BackgroundImage = styled.div`
  background: url(${PartnerDirectoryBackground}) no-repeat;
  background-size: cover;
  max-width: 100vw;
  height: 18vh;
`;

export default PartnerDirectoryDesktop;
