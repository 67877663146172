import React, {ReactElement, useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContext} from '../../../../ProposalForm';
import {Controller} from 'react-hook-form';
import {
  dataForwardedName,
  dataProtectionName,
  euDataName,
  financialInformationName,
  gdprCompliantName,
  localDataName,
  personalInformationName,
  piiDataName,
  piiDataRetentionAdminName,
  piiDataRetentionName,
  thirdPartyNetworkName,
} from '../../ServiceAndSecurityValues';
import {Checkbox, CheckboxGroup} from '@sabre/spark-react-core';
import {ExternalLink, Star} from '@scm/components';
import ExternalUrls from '@scm/components/utils/externalUrls';
import styled from 'styled-components';
import {TextArea} from '../../../definitionPage/definitionComponents/Description';
import {createNestedObjectName} from '../servicesComponents/Services';
import {serviceAndSecurityValuesName} from '../../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import Legend from '@scm/components/accessibility/Legend';

enum PiiDataValues {
  'Social Security Number' = 'Social Security Number',
  'Driver’s License Number' = 'Driver’s License Number',
  'Passport Number' = 'Passport Number',
  'Date of Birth' = 'Date of Birth',
  'Other Data' = 'Address, phone number, country, or National Identification, etc',
}

const PersonalInformation = () => {
  const {formatMessage} = useIntl();
  const {control, watch, errors, setValue} = useContext(ProposalContext);
  const descriptionMaxLength = 250;

  return (
    <>
      <Controller
        name={createNestedObjectName(personalInformationName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (value) {
                setValue(createNestedObjectName(dataForwardedName), '', {shouldValidate: true});
                setValue(createNestedObjectName(euDataName), '', {shouldValidate: true});
                setValue(createNestedObjectName(gdprCompliantName), '', {shouldValidate: true});
                setValue(createNestedObjectName(piiDataRetentionName), '', {shouldValidate: true});
                setValue(createNestedObjectName(piiDataRetentionAdminName), '', {shouldValidate: true});
                setValue(createNestedObjectName(localDataName), '', {shouldValidate: true});
                setValue(createNestedObjectName(piiDataName), [], {shouldValidate: true});
                if (
                  !watch(createNestedObjectName(financialInformationName)) &&
                  !watch(createNestedObjectName(personalInformationName)) &&
                  !watch(createNestedObjectName(thirdPartyNetworkName))
                ) {
                  setValue(createNestedObjectName(dataProtectionName), '', {shouldValidate: true});
                }
              }
            }}
            checked={value}
            label={formatMessage({id: 'serviceAndSecurity.personalInformation.label'})}
            className="spark-mar-b-0"
          />
        )}
      />
      <p className="spark-mar-l-2 spark-pad-l-1 spark-small">
        <FormattedMessage
          id="serviceAndSecurity.personalInformation.additionalText"
          values={{link: word => <ExternalLink to={ExternalUrls.SabrePrivacy}>{word}</ExternalLink>}}
        />
      </p>
      {watch(createNestedObjectName(personalInformationName)) && (
        <div className="spark-pad-l-2">
          <p className="spark-mar-b-1 spark-mar-r-2">
            <FormattedMessage id="serviceAndSecurity.piiData.label" />
            <Star hasColon />
          </p>
          <Controller
            name={createNestedObjectName(piiDataName)}
            control={control}
            render={({field: {onChange, name, value}}): ReactElement => (
              <CheckboxGroup
                name={name}
                value={value}
                onChange={(_, activeItems): void => onChange(activeItems)}
                status={errors[serviceAndSecurityValuesName]?.[piiDataName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[piiDataName] &&
                  errors[serviceAndSecurityValuesName]?.[piiDataName]?.message
                }
              >
                <Legend text={formatMessage({id: 'serviceAndSecurity.piiData.label'})} />
                {Object.values(PiiDataValues).map(technology => (
                  <Checkbox key={technology} label={technology} value={technology} />
                ))}
              </CheckboxGroup>
            )}
          />
          <PiiAdditionalText className="spark-mar-l-2 spark-pad-l-1 spark-mar-b-1 spark-small">
            <FormattedMessage id="serviceAndSecurity.piiData.additionalText" />
          </PiiAdditionalText>
          {[
            dataForwardedName,
            euDataName,
            gdprCompliantName,
            piiDataRetentionName,
            piiDataRetentionAdminName,
            localDataName,
          ].map(name => (
            <Controller
              key={name}
              name={createNestedObjectName(name)}
              control={control}
              render={({field}): ReactElement => (
                <TextArea
                  {...field}
                  label={formatMessage(
                    {id: `serviceAndSecurity.${name}.label`},
                    {star: word => <span className="spark-danger">{word}</span>}
                  )}
                  characterCount
                  multiLine
                  maxLength={descriptionMaxLength}
                  className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                  status={errors[serviceAndSecurityValuesName]?.[name] ? MessageStatus.ERROR : undefined}
                  statusMessage={
                    errors[serviceAndSecurityValuesName]?.[name] &&
                    errors[serviceAndSecurityValuesName]?.[name]?.message
                  }
                />
              )}
            />
          ))}
        </div>
      )}
    </>
  );
};

const PiiAdditionalText = styled.p`
  transform: translateY(-50%);
`;

export default PersonalInformation;
