import React, {ChangeEvent, useContext} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import StandardTooltip from '../../tooltip/StandardTooltip';
import {SearchContext} from '../../../providers/SearchProvider';
import {LevelAccessContext} from '../../../providers/LevelAccessProvider';
import {AccessType} from '../level-access/LevelAccess';
import AutoSuggest from '@scm/my-products/components/spark/AutoSuggest';
import {DevelopersContext} from '../../../providers/DevelopersProvider';
import {ProductsContext} from '../../../providers/ProductsPovider';
import {Developer, SellerDeveloper} from '../../../generated/management';

const Search = () => {
  const {formatMessage} = useIntl();
  const {state, setState} = useContext(SearchContext);
  const {state: levelAccess} = useContext(LevelAccessContext);
  const {developers, loading} = useContext(DevelopersContext);
  const {products, loading: productLoading} = useContext(ProductsContext);
  const isStateLoading = loading || productLoading;
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => setState((event?.target as HTMLInputElement).value);

  const handleReset = () => setState('');

  const handleSetSearchItem = (newValue: string) => setState(newValue);

  const isProductLevel = levelAccess === AccessType['Product Level Access'];
  const mapDeveloper = (developer: SellerDeveloper | Developer) => [
    developer.firstName || '',
    developer.lastName || '',
    developer.pcc || '',
    developer.epr || '',
    developer.email || '',
  ];

  const searchTerms = isProductLevel
    ? products
        .map(product => [product.name || '', product.developers?.map(developer => mapDeveloper(developer)) || []])
        .flat(4)
    : developers.map(developer => mapDeveloper(developer)).flat(3);

  return (
    <SearchWrapper className="col-xs-12 col-lg-3 spark-pad-b-1">
      <StandardTooltip
        text={formatMessage({
          id: `assignSeller.companyLevel.search${isProductLevel ? '.product' : ''}.tooltip`,
        })}
        isForSearchBox
        hiddenTooltip={!!state?.length}
      >
        {isStateLoading ? (
          <AutoSuggest
            key="placeholder"
            name="searchBox"
            value={state}
            label={formatMessage({id: 'myProducts.searchBox.label'})}
            onChange={handleSearch}
            onReset={handleReset}
            onSetSearchItem={handleSetSearchItem}
            icon
          />
        ) : (
          <AutoSuggest
            key={isProductLevel ? 'products' : 'developers'}
            name="searchBox"
            value={state}
            label={formatMessage({id: 'myProducts.searchBox.label'})}
            searchTerms={searchTerms}
            onChange={handleSearch}
            onReset={handleReset}
            onSetSearchItem={handleSetSearchItem}
            icon
          />
        )}
      </StandardTooltip>
    </SearchWrapper>
  );
};

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (min-width: ${deviceWidths.md}px) {
    & .spark-auto-suggest {
      width: 120%;
      transform: translate(-2.8rem, -0.7rem) scale(0.8);
      z-index: 11;
    }

    & .spark-auto-suggest .spark-label,
    & .spark-auto-suggest .spark-auto-suggest__field {
      font-size: 1.7rem;
      font-weight: 400;
    }

    & .spark-auto-suggest.active .spark-label {
      font-size: 1.1rem;
    }
  }
`;

export default Search;
