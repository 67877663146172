import React from 'react';
import {Message} from '@sabre/spark-react-core';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {OnboardingRequestResponseStatusEnum, OnboardingRequestResponseTypeEnum} from '../../../generated/onboardings';
import {useIntl} from 'react-intl';
import HrefElement from '@scm/components/messaging/HrefElement';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const MessageContent = {
  'provider - submitted': {
    title: 'proposals.onboardingRequestDetails.rapSubmitted.title',
    content: 'proposals.onboardingRequestDetails.rapSubmitted.content',
  },
  'provider - approved': {
    title: 'proposals.onboardingRequestDetails.rapApproved.title',
    content: 'proposals.onboardingRequestDetails.rapApproved.content',
  },
  'developer partner - submitted': {
    title: 'proposals.onboardingRequestDetails.devPartnerSubmitted.title',
    content: 'proposals.onboardingRequestDetails.devPartnerSubmitted.content',
  },
  'developer partner - approved': {
    title: 'proposals.onboardingRequestDetails.devPartnerApproved.title',
    content: 'proposals.onboardingRequestDetails.devPartnerApproved.content',
  },
  rejected: {
    title: 'proposals.onboardingRequestDetails.rejected.title',
    content: 'proposals.onboardingRequestDetails.rejected.content',
  },
} as const;

const getKey = (
  type: OnboardingRequestResponseTypeEnum,
  status: OnboardingRequestResponseStatusEnum
): keyof typeof MessageContent => {
  if (type === OnboardingRequestResponseTypeEnum.RedAppProvider) {
    if (status === OnboardingRequestResponseStatusEnum.Submitted) {
      return 'provider - submitted';
    }

    if (status === OnboardingRequestResponseStatusEnum.Approved) {
      return 'provider - approved';
    }
  } else if (type === OnboardingRequestResponseTypeEnum.DeveloperPartner) {
    if (status === OnboardingRequestResponseStatusEnum.Submitted) {
      return 'developer partner - submitted';
    }

    if (status === OnboardingRequestResponseStatusEnum.Approved) {
      return 'developer partner - approved';
    }
  }
  return 'rejected';
};

const HeaderMessage = ({
  type,
  status,
}: {
  type: OnboardingRequestResponseTypeEnum;
  status: OnboardingRequestResponseStatusEnum;
}) => {
  const {formatMessage} = useIntl();
  const isRejected = status === OnboardingRequestResponseStatusEnum.Rejected;
  const isApproved = status === OnboardingRequestResponseStatusEnum.Approved;
  const messageContentKey = getKey(type, status);
  const {content, title} = MessageContent[messageContentKey];

  return (
    <MessageWrapper>
      <Message
        content={
          formatMessage(
            {id: content},
            {
              email: text => <HrefElement email={text.toString()} />,
            }
          ) as string
        }
        title={formatMessage({id: title})}
        role={isRejected ? MessageRole.ALERT : MessageRole.STATUS}
        status={(() => {
          if (isRejected) {
            return MessageStatus.ERROR;
          }
          return isApproved ? MessageStatus.WARNING : MessageStatus.INFO;
        })()}
        type={MessageType.CONTAINER}
        animate
      />
    </MessageWrapper>
  );
};

export const MessageWrapper = styled.div`
  background-color: ${colors.grey100};
  padding: 1rem 2rem 0.5rem;
  margin-bottom: 1rem;

  strong:not(:first-child) {
    color: ${colors.statusInfoGrey};
  }

  & .toast-email-gray > strong {
    color: ${colors.statusInfoGrey} !important;
  }
`;

export default HeaderMessage;
