import {FormattedMessage} from 'react-intl';
import AboutBackground from 'assets/images/what_is_sabre_partner_network_background.png';
import React from 'react';
import styled from 'styled-components';

const AboutMobile = () => (
  <AboutMobileContainer>
    <AboutMobileImage src={AboutBackground} alt="about background" />
    <div className="spark-gamma">
      <FormattedMessage id="homePage.welcome.text" />
    </div>
  </AboutMobileContainer>
);

const AboutMobileContainer = styled.div.attrs(() => ({
  className: 'spark-flex',
}))`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
`;

const AboutMobileImage = styled.img`
  max-height: 65%;
  max-width: 65%;
`;

export default AboutMobile;
