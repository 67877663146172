import React from 'react';
import {FormattedMessage} from 'react-intl';
import FinancialInformation from './securityComponents/FinancialInformation';
import PersonalInformation from './securityComponents/PersonalInformation';
import ThirdPartyNetwork from './securityComponents/ThirdPartyNetwork';
import Accounting from './securityComponents/Accounting';
import DataProtection from './securityComponents/DataProtection';
import CoreData from './securityComponents/CoreData';
import SecureCodingPractices from './securityComponents/SecureCodingPractices';
import PageHeader from '../../../../../components/content/PageHeader';

const SecurityContainer = () => {
  return (
    <div>
      <PageHeader id="serviceAndSecurity.security.title" />
      <p className="spark-mar-t-1 spark-mar-b-1 spark-mar-r-2">
        <FormattedMessage id="serviceAndSecurity.detailInformation.label" />
      </p>
      <FinancialInformation />
      <PersonalInformation />
      <ThirdPartyNetwork />
      <Accounting />
      <DataProtection />
      <CoreData />
      <SecureCodingPractices />
    </div>
  );
};

export default SecurityContainer;
