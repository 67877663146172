import React, {useEffect, useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import SelectInput from '@sabre/spark/js/src/components/select-input';
import {getError} from './SparkInput';
import {Optional} from './SparkInput';
import {Star} from '../text/Star';

const DropDown = ({
  name,
  label,
  options,
  isRequired,
  defaultValue,
}: {
  name: string;
  label: string;
  options: {[key: string]: string};
  errorMessage?: string;
  isRequired?: boolean;
  defaultValue?: string;
}) => {
  const inputRef = useRef(null);
  const {
    register,
    formState: {errors},
  } = useFormContext();
  useEffect(() => {
    const selectInput = new SelectInput(inputRef.current);
    return () => {
      selectInput.remove();
    };
  }, [inputRef]);
  const error = getError(name, errors);

  const selectComponent = (
    <select className="spark-select__input" {...register(name)} defaultValue={defaultValue}>
      {!isRequired && <option />}
      <>
        {Object.entries(options).map((element, index) => (
          <option key={index} value={element[0]}>
            {element[1]}
          </option>
        ))}
      </>
    </select>
  );

  const requiredLabel = isRequired ? <Star /> : <Optional />;

  return (
    <label ref={inputRef} className="spark-select" {...(error && {'data-error': !!error})}>
      {selectComponent}
      <span className="spark-label">
        {label}
        {requiredLabel}
      </span>
      {error && <span className="spark-select__message">{error.message}</span>}
    </label>
  );
};

export default DropDown;
