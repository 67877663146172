export const standardProduct = {
  'standardProduct.status.actionRequired.content':
    "Please make sure that all required storefront fields for the product are completed. Once this is done, you will be able to publish the product to Sabre Central Marketplace by clicking on the 'Publish' button. Remaining content items to be completed before publication:{items}",
  'standardProduct.modal.confirmationForm.title': 'Confirmation',
  'standardProduct.modal.confirmationForm.description': 'Are you sure you want to publish a new standard product?',
  'standardProduct.modal.confirmationForm.message.successTitle': 'Published',
  'standardProduct.modal.confirmationForm.message.success': 'The standard product has been successfully published.',
  'standardProduct.modal.confirmationForm.message.errorTitle': 'Error',
  'standardProduct.modal.confirmationForm.message.error': 'An error occurred while publishing the standard product.',
};
