import React, {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';
import {Controller, UseFormReturn} from 'react-hook-form';
import {Toggle, ToggleGroup} from '@sabre/spark-react-core';
import {RowsGroup} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/ProposalViewDataTab';
import {Label} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import styled from 'styled-components';
import {skipCertificateCheckName, SvsValues} from '../SvsValues';

export interface CompareRequestValues {
  skipCompareRequest: Confirmation;
}

const SkipCertificateCheck = ({formMethods}: {formMethods: UseFormReturn<SvsValues>}) => {
  const skipCertificateCheckRequestName = skipCertificateCheckName;
  const {control, watch} = formMethods;

  return (
    <RowsGroup className="spark-mar-b-2">
      <ToggleWrapper className="spark-mar-l-2">
        <Label className="spark-bold">
          <FormattedMessage id="svs.skipCertificateCheck.label" />
        </Label>
        <div className="spark-mar-l-2">
          <Controller
            name={skipCertificateCheckRequestName}
            control={control}
            render={({field: {onChange, onBlur, value, name}}): ReactElement => (
              <ToggleGroup
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                defaultValue={watch(skipCertificateCheckName)}
                className="spark-mar-t-1 col-xs-6"
              >
                {Object.values(Confirmation).map(value => (
                  <Toggle key={value} label={value} value={value} aria-label={`Confirmation toggle ${value}`} />
                ))}
              </ToggleGroup>
            )}
          />
          <p className="spark-small spark-mar-b-0 spark-mar-l-1 spark-mar-t-1">
            <FormattedMessage id="svs.skipCertificateCheck.additionalText" />
          </p>
        </div>
      </ToggleWrapper>
    </RowsGroup>
  );
};

export const ToggleWrapper = styled.div`
  display: flex;

  & > p + *:not(a),
  & label {
    width: 100%;
  }

  & .row {
    flex-wrap: nowrap;
  }
`;
export default SkipCertificateCheck;
