/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PccEprsInner } from './PccEprsInner';
import {
    PccEprsInnerFromJSON,
    PccEprsInnerFromJSONTyped,
    PccEprsInnerToJSON,
} from './PccEprsInner';

/**
 * 
 * @export
 * @interface OrderingLimitationsResponse
 */
export interface OrderingLimitationsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderingLimitationsResponse
     */
    scCodes?: Array<string>;
    /**
     * 
     * @type {Array<PccEprsInner>}
     * @memberof OrderingLimitationsResponse
     */
    pccEprs?: Array<PccEprsInner>;
}

/**
 * Check if a given object implements the OrderingLimitationsResponse interface.
 */
export function instanceOfOrderingLimitationsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderingLimitationsResponseFromJSON(json: any): OrderingLimitationsResponse {
    return OrderingLimitationsResponseFromJSONTyped(json, false);
}

export function OrderingLimitationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderingLimitationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scCodes': !exists(json, 'scCodes') ? undefined : json['scCodes'],
        'pccEprs': !exists(json, 'pccEprs') ? undefined : ((json['pccEprs'] as Array<any>).map(PccEprsInnerFromJSON)),
    };
}

export function OrderingLimitationsResponseToJSON(value?: OrderingLimitationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scCodes': value.scCodes,
        'pccEprs': value.pccEprs === undefined ? undefined : ((value.pccEprs as Array<any>).map(PccEprsInnerToJSON)),
    };
}

