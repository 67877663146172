import {PanelMenu} from '@scm/components';
import React, {MouseEventHandler, useContext, useState} from 'react';
import {CcrzEProductCSObjectRedAppTypeCEnum} from '../../../generated/product';
import {isAllowedToViewLimitation} from '@scm/authentication/utils/authentication';
import useGetProductC from '@scm/product-details/hooks/useGetProductC';
import GeneralInformation from '@scm/product-details/components/storefront-content/general-information/GeneralInformation';
import Limitations from '@scm/product-details/components/storefront-content/limitations/Limitations';
import Media, {RequiredMediaFields} from '@scm/product-details/components/storefront-content/media/Media';
import Regionalization from '@scm/product-details/components/storefront-content/regionalization/Regionalization';
import Content, {RequiredContentFields} from '@scm/product-details/components/storefront-content/content/Content';
import {
  ContentContainer,
  MenuContainer,
  SectionContainer,
} from '@scm/product-details/page/content/product-form/ProductForm';
import {InternalProviderContext} from '@scm/product-details/provider/InternalProvider';

enum PanelMenuItems {
  'General Information' = 'General Information',
  'Content' = 'Content',
  'Media' = 'Media',
  'Regionalization' = 'Regionalization',
  'SC Code & EPR Limitation' = 'SC Code & EPR Limitation',
}

const ProductForm = () => {
  const {redAppTypeC} = useGetProductC();
  const isRegularApp = redAppTypeC === CcrzEProductCSObjectRedAppTypeCEnum.Regular;
  const {isInternalProvider} = useContext(InternalProviderContext);
  const isAllowedToViewScCodeEprLimitation = isAllowedToViewLimitation() || !!isInternalProvider.isInternalProvider;
  const [currentPanel, setCurrentPanel] = useState(PanelMenuItems['General Information']);
  const panelMenuLinkHandler: MouseEventHandler<HTMLAnchorElement> = event => {
    setCurrentPanel(PanelMenuItems[(event.target as HTMLAnchorElement).textContent as keyof typeof PanelMenuItems]);
  };

  const requiredContentFields: RequiredContentFields = {
    description: true,
    shortDescription: true,
    termsAndConditions: true,
    keyBenefits: true,
    features: true,
  };

  const requiredMediaFields: RequiredMediaFields = {
    screenshots: true,
  };

  const createContent = () => {
    switch (currentPanel) {
      case PanelMenuItems['General Information']:
        return <GeneralInformation />;
      case PanelMenuItems.Content:
        return <Content requiredFields={requiredContentFields} />;
      case PanelMenuItems.Media:
        return <Media requiredFields={requiredMediaFields} />;
      case PanelMenuItems.Regionalization:
        return <Regionalization />;
      case PanelMenuItems['SC Code & EPR Limitation']:
        return <Limitations />;
    }
  };

  return (
    <div className="spark-pad-l-2 spark-pad-r-2 spark-pad-b-2">
      <SectionContainer className="spark-flex">
        <MenuContainer>
          <PanelMenu
            labels={Object.keys(PanelMenuItems).map(
              panelMenuItem => PanelMenuItems[panelMenuItem as keyof typeof PanelMenuItems]
            )}
            onClick={panelMenuLinkHandler}
            isAllowedToViewLimitation={isAllowedToViewScCodeEprLimitation}
            isRegularApp={isRegularApp}
          />
        </MenuContainer>
        <ContentContainer>{createContent()}</ContentContainer>
      </SectionContainer>
    </div>
  );
};

export default ProductForm;
