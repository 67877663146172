import Sorting from 'utils/sorting';

export const PARTNER_DIRECTORY_TOGGLE_FILTERS_ACTION = 'PARTNER_DIRECTORY_TOGGLE_FILTERS_ACTION';

export const PARTNER_DIRECTORY_ADD_FILTER_ACTION = 'PARTNER_DIRECTORY_ADD_FILTER_ACTION';
export const PARTNER_DIRECTORY_REMOVE_FILTER_ACTION = 'PARTNER_DIRECTORY_REMOVE_FILTER_ACTION';

export const PARTNER_DIRECTORY_SET_REFETCH_ACTION = 'PARTNER_DIRECTORY_SET_REFETCH_ACTION';
export const PARTNER_DIRECTORY_REFETCH_ACTION = 'PARTNER_DIRECTORY_REFETCH_ACTION';

export const PARTNER_DIRECTORY_SET_AVAILABLE_FILTER_OPTIONS_ACTION =
  'PARTNER_DIRECTORY_SET_AVAILABLE_FILTER_OPTIONS_ACTION';

export const PARTNER_DIRECTORY_SEARCH_ACTION = 'PARTNER_DIRECTORY_SEARCH_ACTION';
export const PARTNER_DIRECTORY_SORT_ACTION = 'PARTNER_DIRECTORY_SORT_ACTION';

export const CATEGORIES = 'categories';
export const REGIONS = 'regions';
export const TIERS = 'tiers';

export interface PartnerDirectoryState {
  isFilterPanelExpanded: boolean;
  filters: {
    regions: Array<string>;
    categories: Array<string>;
    tiers: Array<string>;
  };
  available: {
    regions: string[];
    categories: string[];
  };
  refetch?: (variables?: {}) => void;
  searchTerm?: string;
  sorting: Sorting;
}
