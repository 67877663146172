import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import styled from 'styled-components';

const InlineButton = ({
  id,
  clickHandler,
  type = 'button',
  className,
  disabled,
  secondary,
}: {
  id: string;
  clickHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: string;
  className: string;
  disabled?: boolean;
  secondary?: boolean;
}) => (
  <FocusableButton
    type={type as 'button' | 'submit' | 'reset' | undefined}
    onClick={clickHandler}
    className={className}
    size={ButtonSize.SMALL}
    disabled={disabled}
    secondary={secondary}
  >
    <FormattedMessage id={id} />
  </FocusableButton>
);

const FocusableButton = styled(Button)`
  &:focus-visible {
    text-decoration: underline;
  }
`;

export default InlineButton;
