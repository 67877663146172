import React from 'react';
import styled from 'styled-components';
import {Icon} from '@sabre/spark-react-core';
import {FormattedMessage} from 'react-intl';

export interface TextItems {
  standardInfo: string[];
  data: string[];
}

const InfoContent = ({textItems}: {textItems: TextItems}) => {
  return (
    <>
      <SectionHeader className="spark-mar-b-2 spark-mar-t-1">
        <FormattedMessage id="onboarding.info.standardInfo.title" />
      </SectionHeader>
      <ul>
        {textItems.standardInfo.map(value => (
          <CheckListItem key={value} className="spark-flex">
            <CheckIcon iconStyle="fill" name="check" size="sm" className="spark-mar-r-.5" />
            <ItemText>
              <FormattedMessage id={value} />
            </ItemText>
          </CheckListItem>
        ))}
      </ul>
      <SectionHeader className="spark-mar-b-2 spark-mar-t-2">
        <FormattedMessage id="onboarding.info.data.title" />
      </SectionHeader>
      <ul>
        {textItems.data.map(value => (
          <CheckListItem key={value} className="spark-flex">
            <CheckIcon iconStyle="fill" name="check" size="sm" className="spark-mar-r-.5" />
            <ItemText>
              <FormattedMessage id={value} />
            </ItemText>
          </CheckListItem>
        ))}
      </ul>
    </>
  );
};

export const boldFont = 'font-weight: bold !important;';

export const SectionHeader = styled.h5`
  ${boldFont}
`;

export const ItemText = styled.span`
  font-size: 1.2rem;
`;

export const CheckListItem = styled.li`
  list-style: none;
`;

export const CheckIcon = styled(Icon)`
  font-size: 1rem !important;
  line-height: 1rem !important;

  &::before {
    font-size: 1rem !important;
    line-height: 1rem !important;
    transform: translateY(6px);
  }
`;

export default InfoContent;
