import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  position: relative;
  min-height: calc(100vh - 146px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none;
`;
