import {TestingEnvironment} from '@scm/product-components/pages/productDetails/actionForms/BundleTestingForm';
import useGetProductC from '../../hooks/useGetProductC';
import {useContext} from 'react';
import {VersionContext} from '../../provider/VersionProvider';

enum Statuses {
  'Ready for Test (CERT)' = 'Ready for Test (CERT)',
  'Ready for Test (PROD)' = 'Ready for Test (PROD)',
  'CERT Tests Passed' = 'CERT Tests Passed',
  'PROD Tests Passed' = 'PROD Tests Passed',
}

const useGetActionTitle = () => {
  const {name, redAppTypeC} = useGetProductC();
  const {bundles} = useContext(VersionContext);

  const bundleAddingTitle = redAppTypeC === 'Regular' ? `Submit a Bundle for ${name}` : `Submit a Version for ${name}`;

  if (!bundles.length) {
    return bundleAddingTitle;
  }

  const lastVersion = bundles[0];
  const {status} = lastVersion;
  const isReadyForCert = status === Statuses['Ready for Test (CERT)'];
  const isReadyForProd = status === Statuses['Ready for Test (PROD)'];
  const isCertTestsPassed = status === Statuses['CERT Tests Passed'];
  const isTestPassed = status === Statuses['PROD Tests Passed'];

  const bundleTestingTitle = `Proceed with ${name} ${
    isReadyForCert ? TestingEnvironment.CERT : TestingEnvironment.PROD
  } Testing`;

  const cancelTitle = `Cancel ${name} ${lastVersion.name} Certification`;

  return isTestPassed
    ? ''
    : isReadyForCert || isReadyForProd
    ? bundleTestingTitle
    : isCertTestsPassed
    ? cancelTitle
    : bundleAddingTitle;
};

export default useGetActionTitle;
