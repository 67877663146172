import {CreateScribeScriptsRequest} from '../generated/product';
import {
  activeListeningFilesNames,
  hiddenUploadedScribeScriptFilesNames,
  resourcesFilesNames,
  ScribeScriptsValues,
  uploadedScribeScriptFilesNames,
} from '../provider/ScribeScriptsValues';
import {Tester} from '@scm/product-components/pages/productDetails/productTabs/testers/ProdTesters';
import {SubmitScribeScriptsVersionRequest} from '@scm/product-components/generated/certification';

export const addOptionalFields = (
  data: ScribeScriptsValues,
  request: CreateScribeScriptsRequest | SubmitScribeScriptsVersionRequest
) => {
  if (data[hiddenUploadedScribeScriptFilesNames].length) {
    request.hiddenScribeScriptFiles = data[hiddenUploadedScribeScriptFilesNames];
  }

  if (data[resourcesFilesNames].length) {
    request.resources = data[resourcesFilesNames];
  }

  if (data[activeListeningFilesNames].length) {
    request.activeListeningConfig = data[activeListeningFilesNames][0];
  }
};

export const scribeScriptsMapper = (
  data: ScribeScriptsValues,
  name: string,
  betaTesters: Array<Tester>
): CreateScribeScriptsRequest => {
  const request: CreateScribeScriptsRequest = {
    scribeScript: {
      betaTesters: betaTesters.map(tester => ({pcc: tester.pcc, epr: tester.sabreId})),
      name: name.trim(),
    },
    visibleScribeScriptFiles: data[uploadedScribeScriptFilesNames],
  };

  addOptionalFields(data, request);

  return request;
};
