import {Product, Status} from '../../../generated/management';
import React from 'react';
import {AppIcon, AppIconPlaceholder} from '@scm/my-products/components/products/Product';
import getAppType from './getAppType';
import Icon from '@scm/components/icons/Icon';
import styled from 'styled-components';
import Developers from '../developers-list/Developers';
import getPartialDeveloper from '../../table/utils/getPartialDeveloper';

const mapTableData = (products: Product[], debouncedState: string) =>
  products
    .filter(product => {
      const partialProduct = {
        name: product.name,
        developers: product.developers?.map(developer => getPartialDeveloper(developer)),
      };

      return debouncedState === ''
        ? true
        : JSON.stringify(partialProduct).toLowerCase().includes(debouncedState.toLowerCase());
    })
    .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0))
    .map(product => ({
      productName: (
        <>
          {product.logoUrl ? (
            <LeftIcon src={product.logoUrl} alt={product.name} loading="lazy" />
          ) : (
            <AppIconPlaceholder />
          )}
          {product.name}
        </>
      ),
      type: getAppType(product.recordType, product.provisioningType, product.redAppType),
      published: (
        <Icon
          className={`spark-icon-check spark-icon--fill ${product.status !== Status.Released ? 'hidden' : ''}`}
          aria-label="check"
        />
      ),
      developers: (
        <div className="spark-flex">
          <Icon className="spark-icon-users" aria-label="developers" />
          {product.developers?.length}
        </div>
      ),
      children: (
        <Developers developers={product.developers || []} sku={product.sku || ''} productName={product.name || ''} />
      ),
      sku: product.sku,
    }));

const LeftIcon = styled(AppIcon)`
  float: left;
`;

export default mapTableData;
