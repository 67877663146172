import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import Headers from './Headers';
import Rows from './rows/Rows';

const Versions = () => (
  <Table className="spark-table">
    <div className="spark-table__scroll">
      <table role="grid" className="spark-mar-b-1">
        <Headers />
        <Rows />
      </table>
    </div>
  </Table>
);

const Table = styled.section`
  border-top: 2px solid ${colors.grey300};
  font-size: 1rem;
`;

export default Versions;
