import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Maybe} from 'graphql/jsutils/Maybe';
import PartnerCardLabel from './PartnerCardLabel';
import ExternalLink from 'components/ExternalLink';

const MultiRowLinkItem = ({
  messageId,
  values,
  className,
  mailTo,
}: {
  messageId: string;
  className?: string;
  values?: Maybe<string>[];
  mailTo?: boolean;
}) => (
  <div className={className}>
    <PartnerCardLabel>
      <FormattedMessage id={messageId} />
    </PartnerCardLabel>
    {values?.map((value, index) => (
      <div key={index}>
        <ExternalLink
          to={mailTo ? `mailto:${value}` : `${value}`}
          className="spark-link"
          children={value}
          ariaLabel={value ?? undefined}
        />
      </div>
    ))}
  </div>
);

export default MultiRowLinkItem;
