import proposalDetails from './proposalDetails';

const proposalView = {
  'proposalView.proposalType': '{provisioningType} Red App',
  'proposalView.message.actionOnSabre.title': "Action on Sabre's side.",
  'proposalView.message.actionRequired.title': 'Action Required.',
  'proposalView.message.content':
    "Your proposal is being reviewed. We'll get back to you within <strong>72 business hours</strong> and let you know if your concept is approved.",
  'proposalView.message.rejected.content':
    'Your Red App proposal has been rejected. If you have any questions, please contact {email}.',
  'proposalView.message.approved.content':
    'Your proposal was approved. When your product is created, you can start editing storefront content and set up the Red App details, as well as submit the first version bundle. <info>Additional info: {reasonRejected}</info>',
  'proposalView.message.rejected.additionalInfo': 'Additional info: {reasonRejected}',
  'proposalView.message.rejected': 'Reason for Rejection',
  'proposalView.proposalDate': 'Proposal Date',
  'proposalView.proposalStatus': 'Status',
  'proposalView.proposal.status.value': 'Proposal {statusMessage}',
  'proposalView.proposal.status.inReview.value': 'Under Review',
  'proposalView.generalInformation.title': 'General Information',
  'proposalView.detail.additionalText':
    'You will be able to edit the Red App content and settings when your proposal is approved.',
  'proposalView.changeName.button': 'Change Red App Name',
  'proposalView.changeName.modal.title': 'Change Red App Name',
  'proposalView.changeName.additionalText': 'Red App name can be only changed before the proposal is approved.',
  'proposalView.description.label': 'Description:',
  'proposalView.categories.label': 'Category:',
  'proposalView.redAppName.description.firstParagraph':
    'Your Red App should be descriptive enough for customers to figure out what the app is/does in one or two words (maximum of three). Please take into account that "Red App" always follows the name.',
  'proposalView.redAppName.description.secondParagraph':
    'Company names usually work well. If there is a need to add an attribute, make sure it covers the whole scope of the capabilities offered.',
  'proposalView.redAppName.description.thirdParagraph':
    'The Red App name should not limit the possibilities for other players who may have similar Red App offerings.',
  'proposalView.redAppName.title': 'Red App Name',
  'proposalView.redAppName.label': 'Red App Name',
  'proposalView.proposalData.header': 'Proposal Data',
  'proposalView.proposalData.additionalText': 'Here you can see the information provided in the proposal.',
  'proposalView.button.triggerProductCreation': 'Trigger CC Product creation',
  'proposalView.triggerProductCreation.successTitle': 'Triggered',
  'proposalView.triggerProductCreation.success': 'CC Product creation was triggered successfully',
  'proposalView.triggerProductCreation.errorTitle': 'Error',
  'proposalView.triggerProductCreation.error': 'Something went wrong with creating product',
  'proposalView.error': 'Proposal not found.',
  'proposalView.errorTitle': 'Failed.',
  ...proposalDetails,
};

export default proposalView;
