import {createContext} from 'react';
import logEvent from '../googleAnalytics/logEvent';
interface GaFeatures {
  logEvent: typeof logEvent;
}

const initialValues: GaFeatures = {
  logEvent,
};
export const GaContext = createContext<GaFeatures>(initialValues);
const GaProvider = ({children}: {children: JSX.Element}) => (
  <GaContext.Provider value={initialValues}>{children}</GaContext.Provider>
);

export default GaProvider;
