import TabName from '@scm/product-components/pages/productDetails/productTabs/TabName';
import React from 'react';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {TabData} from './VersionTabs';
import {useNavigate} from 'react-router';
import {useParams} from 'react-router-dom';

const VersionTabsHeader = ({supportTabs}: {supportTabs: Array<TabData>}) => {
  const navigate = useNavigate();
  const {version = ''} = useParams();
  const slash = '/';
  const activeTabName = location.pathname.slice(location.pathname.lastIndexOf(slash) + 1);

  return (
    <TabList>
      {supportTabs
        .filter(tab => !tab.hideElement)
        .map(
          ({name, icon}) =>
            name && (
              <TabListItem
                key={name}
                data-name={name}
                onClick={() => navigate(convertToURL(name), {state: {version}})}
                onKeyDown={evt => {
                  if (evt.key === 'Enter') {
                    navigate(convertToURL(name), {state: {version}});
                  }
                }}
              >
                <TabName activeTabName={activeTabName} tabName={name} icon={icon} />
              </TabListItem>
            )
        )}
    </TabList>
  );
};

export const convertToURL = (str: string) => str.toLowerCase().replaceAll(' ', '');

const TabList = styled.ul`
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const TabListItem = styled.li`
  list-style-type: none;
  margin: 0;
  min-width: 10rem;
  text-align: center;
  @media (min-width: ${deviceWidths.xs}px) {
    float: left;
  }
`;

export default VersionTabsHeader;
