import React from 'react';
import {FormattedMessage} from 'react-intl';
const ProposalInsuranceText = ({liRef}: {liRef: React.MutableRefObject<HTMLLIElement | undefined>}) => (
  <>
    <p className="spark-mar-b-1">
      <FormattedMessage id="insurance.intro" />
    </p>
    <ul className="spark-list--no-bullets">
      <li>
        <p>
          <FormattedMessage id="insurance.address.name" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.address.department" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.address.firstLine" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.address.secondLine" />
        </p>
      </li>
    </ul>
    <p className="spark-mar-b-1">
      <FormattedMessage id="insurance.requirements" />
    </p>
    <p className="spark-mar-b-1">
      <FormattedMessage id="insurance.commercialGeneralLiability" />
    </p>
    <ol className="spark-mar-l-2 spark-pad-l-.5">
      <li>
        <p>
          <FormattedMessage id="insurance.firstLiability" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.secondLiability" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.thirdLiability" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.fourthLiability" />
        </p>
      </li>
    </ol>
    <p className="spark-mar-b-1">
      <strong>
        <FormattedMessage id="insurance.coverageLimits" />
      </strong>
    </p>
    <ol className="spark-mar-l-2 spark-pad-l-.5">
      <li>
        <p>
          <FormattedMessage id="insurance.firstLimit" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.secondLimit" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.thirdLimit" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.fourthLimit" />
        </p>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.fifthLimit" />
        </p>
        <ol className="spark-list--no-bullets">
          <li>
            <FormattedMessage id="insurance.firstAmount" />
          </li>
          <li>
            <FormattedMessage id="insurance.secondAmount" />
          </li>
          <li>
            <FormattedMessage id="insurance.thirdAmount" />
          </li>
        </ol>
      </li>
      <li>
        <p>
          <FormattedMessage id="insurance.sixthLimit" />
        </p>
      </li>
      <li ref={liRef}>
        <p>
          <FormattedMessage id="insurance.seventhLimit" />
        </p>
      </li>
    </ol>
  </>
);
export default ProposalInsuranceText;
