import React from 'react';
import colors from '../assets/colors';
import styled from 'styled-components';
import {Icon} from '@sabre/spark-react-core';

const StepArrows = ({
  stepNumber,
  stepTitle,
  stepDescription,
  isDescriptionOnTop,
  stepTime,
  sabreStep,
  currentStep,
  isFailed,
}: {
  stepNumber: number;
  stepTitle: string;
  stepDescription: string;
  isDescriptionOnTop: boolean;
  stepTime?: string;
  sabreStep?: boolean;
  currentStep?: number;
  isFailed?: boolean;
}) => {
  const determineColor = (stepNumber: number, sabreStep?: boolean, currentStep?: number, isFailed?: boolean) => {
    if (currentStep === undefined || currentStep === stepNumber) {
      return isFailed ? colors.red : sabreStep ? colors.grey700 : colors.warningYellow;
    }

    return currentStep > stepNumber ? colors.highlightGrey : colors.highlightLightGrey;
  };

  const bgcColor = determineColor(stepNumber, sabreStep, currentStep, isFailed);

  const determineStepNumberColor = () => (bgcColor === colors.warningYellow ? colors.warningDarkYellow : colors.white);

  return (
    <>
      <PauseArrow />
      <StepArrow color={bgcColor}>
        {isDescriptionOnTop && (
          <StepDescription>
            <strong className="spark-mar-b-1">{stepTitle}</strong>
            <br />
            <span className="spark-mar-b-1">{stepDescription}</span>
            <br />
            {!!stepTime && <span className="spark-small">{stepTime}</span>}
          </StepDescription>
        )}
        <StepNumber color={determineStepNumberColor()}>
          {currentStep && currentStep > stepNumber ? (
            <Icon name="check" iconStyle="fill" className="spark-icon--md" />
          ) : (
            <span className="spark-bold">{stepNumber}</span>
          )}
        </StepNumber>
        {!isDescriptionOnTop && (
          <StepDescription>
            <strong className="spark-mar-b-1">{stepTitle}</strong>
            <br />
            <span className="spark-mar-b-1">{stepDescription}</span>
            <br />
            {!!stepTime && <span className="spark-small">{stepTime}</span>}
          </StepDescription>
        )}
      </StepArrow>
    </>
  );
};

const PauseArrow = styled.div`
   {
    display: flex;
    float: left;
    height: 5rem;
    background: ${colors.grey200};
    text-align: center;
    padding: 1.5rem 1.5rem 0 1.5rem;
    position: relative;
    margin: 0 1rem 0 0;
    font-size: 2rem;
    text-decoration: none;
    color: #fff;
  }

  ::before,
  ::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  ::before {
    content: '';
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 31px solid white;
    position: absolute;
    left: 0;
    top: 0;
  }

  ::after {
    content: '';
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 31px solid ${colors.grey200};
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 1;
  }
`;

const StepArrow = styled.div<{color: string}>`
   {
    display: flex;
    float: left;
    height: 5rem;
    background: ${props => props.color};
    text-align: center;
    padding: 1.5rem 3rem 0 4rem;
    position: relative;
    margin: 0 1rem 0 0;
    font-size: 2rem;
    text-decoration: none;
    color: #fff;
  }

  ::before,
  ::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  ::before {
    content: '';
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    left: 0;
    top: 0;
  }

  ::after {
    content: '';
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 31px solid ${props => props.color};
    position: absolute;
    right: -30px;
    top: 0;
    z-index: 1;
  }
`;

const StepNumber = styled.div<{color: string}>`
   {
    position: absolute;
    color: ${props => props.color};
  }
`;

const StepDescription = styled.div`
   {
    position: absolute;
    font-size: 14px;
    width: 180%;
    text-align: center;
    color: ${colors.highlightGrey};
    left: -30px;
    line-height: normal;
    font-weight: normal;
    background-color: ${colors.white};
  }

  :nth-child(odd) {
    bottom: 60px;
    padding-bottom: 10px;
  }

  :nth-child(even) {
    top: 60px;
    padding-top: 10px;
  }
`;

export default StepArrows;
