import React, {useEffect, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import SparkTooltip from '@sabre/spark/js/src/components/tooltip';
import styled from 'styled-components';
import {generateUniqueID} from 'web-vitals/dist/modules/lib/generateUniqueID';

const DisabledTooltip = () => {
  const tooltipRef = useRef(null);

  useEffect(() => {
    const sparkAccordion = new SparkTooltip(tooltipRef.current);
    return () => {
      sparkAccordion.remove();
    };
  }, [tooltipRef]);

  const accessibilityId = generateUniqueID();
  const noResultId = `no-result-${accessibilityId}`;

  return (
    <Tooltip aria-label="Settings" aria-describedby={noResultId}>
      <span className="spark-tooltip__content--left" id={noResultId} role="tooltip" ref={tooltipRef}>
        <FormattedMessage id="partnerDirectoryPage.filterDisabledTooltip" />
        <span className="spark-tooltip__caret" />
      </span>
    </Tooltip>
  );
};

const Tooltip = styled.button.attrs(() => ({
  className: 'spark-btn--icon spark-icon spark-icon-info-circle spark-tooltip',
}))`
  font-size: 1.333337rem !important;
  border: none !important;
  padding: 0 0.5rem !important;
`;

export default DisabledTooltip;
