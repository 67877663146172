const products = {
  'products.header.productName': 'Product Name',
  'products.header.type': 'Type',
  'products.header.updatedAt': 'Last Updated',
  'products.header.released': 'Released',
  'products.header.version': 'Latest Version',
  'products.header.status': 'Latest Status',
  'products.actions.viewDetails': 'View Details',
  'products.actions.requestSunset': 'Request a Sunset',
  'products.actions.preview': 'Preview On Marketplace',
  'products.actions.delete': 'Delete',
  'products.actions.submit': 'Submit a Bundle',
  'products.actions.remove': 'Remove from Marketplace',
  'products.actions.remove.draft': 'Remove',
  'products.remove.draft.title': 'Remove Draft?',
  'products.remove.draft.content':
    'Are you sure you want to remove this proposal draft? If you delete it, you will no longer be able to retrieve the saved information.',
};

export default products;
