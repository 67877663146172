import React from 'react';
import {ExternalLink} from '../navigation/ExternalLink';
import styled from 'styled-components';
import colors from '../assets/colors';

export const FooterLinkListItem = ({
  to,
  ariaLabel,
  title,
  icon,
  label,
}: {
  to: string;
  ariaLabel: string;
  title: string;
  icon?: string;
  label?: string;
}) => (
  <li className="spark-footer__list-item">
    <LinkWithIcon className="spark-footer__list-link" to={to} ariaLabel={ariaLabel} title={title}>
      {icon && <i className={`${icon} spark-icon--fill spark-icon--md`} />}
      {label}
    </LinkWithIcon>
  </li>
);

export const LinkWithIcon = styled(ExternalLink)`
  &:focus i {
    filter: brightness(1.5);
    border-bottom: 1px solid ${colors.tierSilver};
  }
`;
