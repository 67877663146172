import React, {ReactElement, useContext, useEffect, useState} from 'react';
import {ProposalContainer} from '../ProposalTypePage';
import {ButtonContainerInsurance} from './InsurancePage';
import {Button, Checkbox} from '@sabre/spark-react-core';
import {ButtonSize, MessageStatus} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContext} from '../ProposalForm';
import PageHeader from '../../../components/content/PageHeader';
import AdditionalStatement from '../../../components/content/AdditionalStatement';
import {Controller} from 'react-hook-form';
import {TextArea} from './definitionPage/definitionComponents/Description';
import {isPaidDescriptionName, isPaidName} from '../ProposalValues';
import ButtonWithTooltip from '@scm/components/form/ButtonWithTooltip';
import {basicTextFieldMinLength, minDescriptionMessage} from '@scm/proposal/schemas/proposalConstants';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';

const PricingPage = () => {
  const {formatMessage} = useIntl();
  const {handleContinue, handleCancel, handleSaveDraft, control, watch, setValue, isOnboarding} =
    useContext(ProposalContext);
  const pricingDescriptionLength = 250;
  const validationOptions = {shouldDirty: true, shouldValidate: true, shouldTouch: true};
  const paidDescription = watch(isPaidDescriptionName) ?? '';
  const paidDescriptionLength = paidDescription.length;
  const isValid = !watch(isPaidName) || paidDescriptionLength >= basicTextFieldMinLength;
  const [shouldShowBanner, setShouldShowBanner] = useState(!isValid);

  const handlePricingContinue = () => {
    gaEvent('Completed Pricing Step (Public App Proposal)');
    handleContinue(false);
  };

  useEffect(() => {
    setValue('forceRender', true, validationOptions);
  }, []);

  return (
    <>
      <ProposalContainer className="spark-panel spark-pad-l-2">
        <PageHeader id="pricing.title" />
        <AdditionalStatement id="pricing.additionalStatement" />
        <Controller
          name={isPaidName}
          control={control}
          render={({field: {onChange, onBlur, value}}): ReactElement => (
            <Checkbox
              onBlur={onBlur}
              onChange={event => {
                onChange(event);
                if (value) {
                  setShouldShowBanner(false);
                  setValue(isPaidDescriptionName, '', {shouldValidate: true});
                }
              }}
              checked={value}
              label={formatMessage({id: 'pricing.isPaid.label'})}
              className="spark-mar-t-2"
            />
          )}
        />
        {watch(isPaidName) && (
          <div className="col-xs-12 spark-mar-t-2">
            <p className="spark-mar-t-0 spark-mar-b-1">
              {formatMessage(
                {id: 'pricing.isPaid.description.label'},
                {star: word => <span className="spark-danger">{word}</span>}
              )}
            </p>
            <Controller
              name={isPaidDescriptionName}
              control={control}
              render={({field}): ReactElement => (
                <TextArea
                  {...field}
                  label={formatMessage({id: 'pricing.details.label'})}
                  placeHolder={formatMessage({id: 'pricing.details.placeholder'})}
                  multiLine
                  characterCount
                  maxLength={pricingDescriptionLength}
                  status={!isValid && shouldShowBanner ? MessageStatus.ERROR : undefined}
                  statusMessage={minDescriptionMessage}
                  onChange={(_, value) => {
                    setShouldShowBanner(true);
                    setValue(isPaidDescriptionName, value, validationOptions);
                  }}
                />
              )}
            />
          </div>
        )}
      </ProposalContainer>
      <ButtonContainerInsurance className="spark-mar-t-1 spark-flex">
        <Button secondary size={ButtonSize.SMALL} onClick={handleCancel} className="spark-mar-r-1">
          <FormattedMessage id="common.backButton" />
        </Button>
        <div className="row spark-mar-r-.5">
          {!isOnboarding && (
            <Button size={ButtonSize.SMALL} onClick={handleSaveDraft} className="spark-mar-r-1" secondary>
              <FormattedMessage id="proposal.saveDraft" />
            </Button>
          )}
          <ButtonWithTooltip
            handleContinue={handlePricingContinue}
            isValid={isValid}
            messageId="proposal.requiredFields.message"
          />
        </div>
      </ButtonContainerInsurance>
    </>
  );
};

export default PricingPage;
