/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductPricing
 */
export interface ProductPricing {
    /**
     * 
     * @type {string}
     * @memberof ProductPricing
     */
    pricingLevel?: ProductPricingPricingLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductPricing
     */
    billingPeriod?: ProductPricingBillingPeriodEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductPricing
     */
    additionalInformation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPricing
     */
    isPricingPerTransactionEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPricing
     */
    isPricingPerTransactionNegotiable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPricing
     */
    isPricingPerUserEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPricing
     */
    isPricingPerUserNegotiable?: boolean;
}


/**
 * @export
 */
export const ProductPricingPricingLevelEnum = {
    Pcc: 'PCC',
    Epr: 'EPR'
} as const;
export type ProductPricingPricingLevelEnum = typeof ProductPricingPricingLevelEnum[keyof typeof ProductPricingPricingLevelEnum];

/**
 * @export
 */
export const ProductPricingBillingPeriodEnum = {
    Monthly: 'Monthly',
    OneTime: 'One-time',
    Annual: 'Annual'
} as const;
export type ProductPricingBillingPeriodEnum = typeof ProductPricingBillingPeriodEnum[keyof typeof ProductPricingBillingPeriodEnum];


/**
 * Check if a given object implements the ProductPricing interface.
 */
export function instanceOfProductPricing(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductPricingFromJSON(json: any): ProductPricing {
    return ProductPricingFromJSONTyped(json, false);
}

export function ProductPricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pricingLevel': !exists(json, 'pricingLevel') ? undefined : json['pricingLevel'],
        'billingPeriod': !exists(json, 'billingPeriod') ? undefined : json['billingPeriod'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'isPricingPerTransactionEnabled': !exists(json, 'isPricingPerTransactionEnabled') ? undefined : json['isPricingPerTransactionEnabled'],
        'isPricingPerTransactionNegotiable': !exists(json, 'isPricingPerTransactionNegotiable') ? undefined : json['isPricingPerTransactionNegotiable'],
        'isPricingPerUserEnabled': !exists(json, 'isPricingPerUserEnabled') ? undefined : json['isPricingPerUserEnabled'],
        'isPricingPerUserNegotiable': !exists(json, 'isPricingPerUserNegotiable') ? undefined : json['isPricingPerUserNegotiable'],
    };
}

export function ProductPricingToJSON(value?: ProductPricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pricingLevel': value.pricingLevel,
        'billingPeriod': value.billingPeriod,
        'additionalInformation': value.additionalInformation,
        'isPricingPerTransactionEnabled': value.isPricingPerTransactionEnabled,
        'isPricingPerTransactionNegotiable': value.isPricingPerTransactionNegotiable,
        'isPricingPerUserEnabled': value.isPricingPerUserEnabled,
        'isPricingPerUserNegotiable': value.isPricingPerUserNegotiable,
    };
}

