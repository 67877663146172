import {productApiBaseLink} from '@scm/product-details/assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ButtonSize, MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import {useIntl} from 'react-intl';
import {useState} from 'react';
import gaEvent from '@scm/admin-centre/src/googleAnalytics/googleAnalyticsEvent';
import {Button, Message} from '@sabre/spark-react-core';
import {Form} from '@scm/product-details/components/app-action/actions/cancel/Cancel';
import {ButtonContainer} from '@scm/product-components/pages/productDetails/actionForms/BundleTestingForm';
import {Configuration, StandardProductApi} from '../../generated/product';

enum ButtonValues {
  No = 'No',
  Yes = 'Yes',
}

export const publishStandardApp = async (sku: string) =>
  new StandardProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).publishProduct({sku});

const StandardConfirmation = ({
  handleClose,
  sku,
  canPublishProduct,
  setLoadingProductDetails,
}: {
  handleClose: () => void;
  sku: string;
  canPublishProduct: boolean;
  setLoadingProductDetails: (value: boolean) => void;
}) => {
  const {formatMessage} = useIntl();
  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const confirmationFormMessage = 'standardProduct.modal.confirmationForm.message';

  const onSubmit = async (failure: () => void) => {
    if (!isSubmitPending && sku) {
      setIsSubmitPending(true);
      publishStandardApp(sku)
        .then(() => {
          openToast(
            createMessageString(formatMessage, confirmationFormMessage, true, false),
            createMessageString(formatMessage, confirmationFormMessage, false, false),
            ToastType.POSITIVE,
            'spark-icon-check'
          );
        })
        .catch(err => {
          failure();
          err.response.json().catch(() => {});
          openToast(
            createMessageString(formatMessage, confirmationFormMessage, true, true),
            createMessageString(formatMessage, confirmationFormMessage, false, true),
            ToastType.WARNING,
            'spark-icon-alert-triangle',
            true
          );
        })
        .finally(() => {
          handleClose();
          setLoadingProductDetails(true);
          setIsSubmitPending(false);
        });
      gaEvent('Published a new product of the Standard Product');
    }
  };

  return (
    <Form>
      <Message
        content={formatMessage({id: 'standardProduct.modal.confirmationForm.description'})}
        role={MessageRole.ALERT}
        status={MessageStatus.WARNING}
        title={formatMessage({id: 'standardProduct.modal.confirmationForm.title'})}
        type={MessageType.PAGE}
        titleHeadingLevel={3}
      />
      <ButtonContainer className="spark-flex">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1" aria-label="Cancel">
          {ButtonValues.No}
        </Button>
        <Button
          progress
          type="button"
          size={ButtonSize.SMALL}
          onClick={async (_, success, failure) => {
            if (success && failure) {
              await onSubmit(failure);
            }
          }}
          disabled={!canPublishProduct}
          aria-label="Submit"
          tabIndex={0}
        >
          {ButtonValues.Yes}
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export default StandardConfirmation;
