import {config} from '../config/config';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';

export const url =
  config.ignoreSso || config.mockSso ? '/admin-centre' + InternalUrls.MockSsoLogin : config.salesforceClient.url;

export const options = (host = window.location.origin) => {
  return config.mockSso || config.ignoreSso
    ? ''
    : `&redirect_uri=${host}/authCallback&response_type=code&state=${window.location.href}`;
};
