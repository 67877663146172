import {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Configuration, VersionResponse, VersionsApi} from '../../../../generated/certification';
import {certificationApiBaseLink} from '../../../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import styled from 'styled-components';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const handleCancelRequest = (id: string) =>
  new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).cancelVersion({id});

const Cancel = ({
  handleClose,
  latestVersion,
  setLoadingProductDetails,
}: {
  handleClose: () => void;
  latestVersion: VersionResponse;
  setLoadingProductDetails: (value: boolean) => void;
}) => {
  const {formatMessage} = useIntl();
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false);

  const onSubmit = async (failure: () => void) => {
    if (!isSubmitPending && latestVersion.id) {
      setIsSubmitPending(true);
      handleCancelRequest(latestVersion.id)
        .then(() => {
          openToast(
            createMessageString(formatMessage, 'version.cancel', true, false),
            createMessageString(formatMessage, 'version.cancel', false, false),
            ToastType.POSITIVE,
            'spark-icon-check'
          );
        })
        .catch(_ => {
          failure();
          openToast(
            createMessageString(formatMessage, 'version', true, true),
            createMessageString(formatMessage, 'version.cancel', false, true),
            ToastType.WARNING,
            'spark-icon-alert-triangle',
            true
          );
        })
        .finally(() => {
          handleClose();
          setLoadingProductDetails(true);
          setIsSubmitPending(false);
        });
    }
  };

  return (
    <Form>
      <div className="spark-pad-b-1">
        <FormattedMessage id="cancelForm.description" values={{version: latestVersion.name}} />
      </div>
      <ButtonContainer className="spark-flex">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1" aria-label="Cancel">
          <FormattedMessage id="common.button.close" />
        </Button>
        <Button
          progress
          type="button"
          size={ButtonSize.SMALL}
          onClick={async (_, success, failure) => {
            if (success && failure) {
              await onSubmit(failure);
            }
          }}
          aria-label="Confirm"
          tabIndex={0}
        >
          <FormattedMessage id="common.button.confirm" />
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export const Form = styled.div`
  & .spark-message {
    border: none;
  }

  & .spark-panel__content {
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  justify-content: flex-end;
`;

export default Cancel;
