import React, {useContext} from 'react';
import {ProposalContainer} from '../../ProposalTypePage';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormattedMessage} from 'react-intl';
import {ButtonContainerInsurance} from '../InsurancePage';
import {ProposalContext} from '../../ProposalForm';
import PageHeader from '../../../../components/content/PageHeader';
import {proposalTypeName} from '../../ProposalValues';
import {ProposalType} from '../../PagesMap';
import DefinitionReview from './reviewComponents/DefinitionReview';
import InsuranceReview from './reviewComponents/InsuranceReview';
import RegionalizationReview from './reviewComponents/RegionalizationReview';
import PricingReview from './reviewComponents/PricingReview';
import ServiceAndSecurityReview from './reviewComponents/ServiceAndSecurityReview';
import SecurityReview from './reviewComponents/SecurityReview';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const ReviewPage = ({
  onboardingFieldsReview,
  isPublicRedApp,
}: {
  onboardingFieldsReview?: JSX.Element;
  isPublicRedApp?: boolean;
}) => {
  const {handleCancel, handleSaveDraft, watch, onboardingHandleSubmit, isOnboarding} = useContext(ProposalContext);
  const isPublic = isPublicRedApp || watch(proposalTypeName) === ProposalType['Public Red App'];
  const isPrivate = watch(proposalTypeName) === ProposalType['Private Red App'];

  return (
    <>
      <ProposalContainer className="spark-panel spark-pad-l-2 spark-pad-r-2 spark-pad-b-1 spark-mar-b-2">
        <PageHeader id="review.title" />
        <Text>
          <FormattedMessage id="review.additionalStatement" />
        </Text>
        {isOnboarding && onboardingFieldsReview}
        {isPublic && <InsuranceReview isOnboarding={isOnboarding} />}
        <DefinitionReview />
        {isPublic && <RegionalizationReview />}
        {isPublic && <PricingReview />}
        {isPublic && <ServiceAndSecurityReview />}
        {!isPublic && <SecurityReview />}
      </ProposalContainer>
      <ButtonContainerInsurance className="spark-mar-t-1 spark-flex">
        <Button secondary size={ButtonSize.SMALL} onClick={handleCancel} className="spark-mar-r-1">
          <FormattedMessage id="common.backButton" />
        </Button>
        <div>
          {(!isOnboarding && isPublic) ||
            (isPrivate && (
              <Button
                size={ButtonSize.SMALL}
                onClick={handleSaveDraft as () => void}
                className="spark-mar-r-1"
                secondary
              >
                <FormattedMessage id="proposal.saveDraft" />
              </Button>
            ))}
          {isOnboarding ? (
            <Button
              size={ButtonSize.SMALL}
              progress
              onClick={(_, success, failure) => {
                if (success && failure) {
                  !!onboardingHandleSubmit && onboardingHandleSubmit(success, failure);
                }
              }}
              data-testid="Submit"
            >
              <FormattedMessage id="common.submitButton" />
            </Button>
          ) : (
            <Button
              size={ButtonSize.SMALL}
              type="submit"
              onClick={() => !!onboardingHandleSubmit && onboardingHandleSubmit()}
            >
              <FormattedMessage id="common.submitButton" />
            </Button>
          )}
        </div>
      </ButtonContainerInsurance>
    </>
  );
};

export const Text = styled.p`
  color: ${colors.grey700};
  font-weight: bold;
  font-size: 1.3333333333rem;
  margin-bottom: 1rem;

  &[data-margin-sm] {
    margin-bottom: 1rem;
  }
`;

export default ReviewPage;
