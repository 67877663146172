import {FormattedMessage} from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {VersionResponse} from '../../../../../generated/certification';

const ActionItem = ({
  handleOpen,
  edit,
  bundle,
  messageId,
  disabled,
}: {
  handleOpen?: (open: boolean) => void;
  edit?: (bundle: VersionResponse) => void;
  bundle: VersionResponse;
  messageId: string;
  disabled?: boolean;
}) => {
  const button = disabled ? (
    <InactiveListItem className="spark-popover__list-link spark-link" aria-disabled>
      <FormattedMessage id={messageId} />
    </InactiveListItem>
  ) : (
    <span
      onClick={() => {
        edit?.(bundle);
        handleOpen?.(true);
      }}
      onKeyDown={evt => {
        if (evt.key === 'Enter') {
          edit?.(bundle);
          handleOpen?.(true);
        }
      }}
      className="spark-popover__list-link spark-link"
      role="button"
      tabIndex={0}
    >
      <FormattedMessage id={messageId} />
    </span>
  );

  return (
    <>
      <li className="spark-popover__list-item">{button}</li>
    </>
  );
};

const InactiveListItem = styled.span`
  color: ${colors.disabledGrey};
  cursor: not-allowed;
`;
export default ActionItem;
