/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NameConvention = {
    RedAppName: 'Red App name',
    OriginalFileName: 'Original file name'
} as const;
export type NameConvention = typeof NameConvention[keyof typeof NameConvention];


export function NameConventionFromJSON(json: any): NameConvention {
    return NameConventionFromJSONTyped(json, false);
}

export function NameConventionFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameConvention {
    return json as NameConvention;
}

export function NameConventionToJSON(value?: NameConvention | null): any {
    return value as any;
}

