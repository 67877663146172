export enum ProductCategories {
  'Agent Booking Tool' = 'Agent Booking Tool',
  'Air' = 'Air',
  'APIs' = 'APIs',
  'Automation and Efficiency' = 'Automation and Efficiency',
  'Corporate Booking Tool' = 'Corporate Booking Tool',
  'Content Aggregator' = 'Content Aggregator',
  'CRM / Marketing Services' = 'CRM / Marketing Services',
  'Data & Analytics' = 'Data & Analytics',
  'Expense Management' = 'Expense Management',
  'Ground' = 'Ground',
  'Groups Bookings' = 'Groups Bookings',
  'Itinerary Management' = 'Itinerary Management',
  'Lodging' = 'Lodging',
  'Mid- and Back-Office' = 'Mid- and Back-Office',
  'NDC' = 'NDC',
  'Online Booking Tool' = 'Online Booking Tool',
  'Other' = 'Other',
  'Payments' = 'Payments',
  'Profiles' = 'Profiles',
  'Revenue Optimization' = 'Revenue Optimization',
  'Sabre Red 360' = 'Sabre Red 360',
  'Sea' = 'Sea',
  'Special Programs' = 'Special Programs',
  'Travel Disruption / Duty of Care' = 'Travel Disruption / Duty of Care',
  'Traveler Communications' = 'Traveler Communications',
}
