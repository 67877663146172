import {FormattedMessage, useIntl} from 'react-intl';
import StatusIcon from '@scm/admin-centre/src/components/styledComponents/StatusIcon';
import styled from 'styled-components';
import useGetProductC from '../../../hooks/useGetProductC';
import {CcrzEProductCSObjectRedAppTypeCEnum} from '../../../generated/product';
import {Configuration, ProductApi} from '@scm/product-components/generated/product';
import {productApiBaseLink} from '@scm/product-components';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {useEffect, useState} from 'react';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ToastType} from '@sabre/spark-react-core/types';
import {useParams} from 'react-router-dom';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

export const fetchSellerName = async (sku: string) =>
  new ProductApi(
    new Configuration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).getSellerData({sku});

const productDetailsName = 'productDetails.seller';

const AppData = () => {
  const {formatMessage} = useIntl();
  const {redAppIdC, ccrzProductStatusC, redAppTypeC} = useGetProductC();
  const productStatus = ccrzProductStatusC ?? '';
  const shouldDisplaySellerName = redAppTypeC === CcrzEProductCSObjectRedAppTypeCEnum.Regular;
  const [sellerName, setSellerName] = useState('');
  const [sellerLoading, setSellerLoading] = useState(shouldDisplaySellerName);
  const sku = useParams().appName ?? '';
  const getSeller = async () => {
    try {
      const seller = await fetchSellerName(sku);
      if (seller.name) {
        setSellerName(seller.name);
      } else {
        throw new Error('No seller name');
      }
    } catch (_) {
      openToast(
        createMessageString(formatMessage, productDetailsName, true, true),
        createMessageString(formatMessage, productDetailsName, false, true),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
    } finally {
      setSellerLoading(false);
    }
  };

  useEffect(() => {
    if (shouldDisplaySellerName && !sellerName) {
      getSeller();
    }
  }, [shouldDisplaySellerName]);

  return (
    <ul className="spark-list spark-list--no-bullets spark-mar-b-0">
      <InfoListItem className="spark-mar-0">
        <ProductInfoLabel className="spark-no-select">
          <FormattedMessage id="productDetails.appId" />
        </ProductInfoLabel>
        {redAppIdC}
      </InfoListItem>
      <InfoListItem className="spark-mar-0">
        <ProductInfoLabel>
          <FormattedMessage id="productDetails.statusName" />
        </ProductInfoLabel>
        <StatusIcon statusName={productStatus} />
        <StatusType className="spark-inline-block spark-mar-l-1" data-testid="productStatus">
          {productStatus}
        </StatusType>
      </InfoListItem>
      {shouldDisplaySellerName && (
        <InfoListItem className="spark-mar-0">
          <ProductInfoLabel className="spark-no-select">
            <FormattedMessage id="productDetails.provider" />
          </ProductInfoLabel>
          {sellerLoading ? <FormattedMessage id="common.data.loading" /> : sellerName}
        </InfoListItem>
      )}
    </ul>
  );
};

export const InfoListItem = styled.li`
  padding: 0.15rem 0;

  &:last-of-type {
    transform: translateY(-0.4rem);
  }

  & .spark-icon-tools {
    transform: scale(0.7);
  }
`;

export const ProductInfoLabel = styled.span`
  &::after {
    content: ':';
    display: inline-block;
    margin-right: 1rem;
  }
`;

export const StatusType = styled.span`
  transform: translateX(-1rem);
`;

export default AppData;
