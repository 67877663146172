import {Maybe} from 'graphql/jsutils/Maybe';

const regionToDisplayName: Record<string, string> = {
  'APAC-South Asia': 'South Asia',
  'APAC-South East Asia': 'South East Asia',
  'APAC-North Asia': 'North Asia',
  'APAC-Oceania': 'Oceania',
  'EMEA-Africa': 'Africa',
  'EMEA-Europe': 'Europe',
  'EMEA-Middle East': 'Middle East',
  'LAC-South and Central America': 'South and Central America',
  'NAM-North America': 'North America',
};

export const mapRegions = (regions: Maybe<string>[]) =>
  regions.map(region => regionToDisplayName[region ?? ''] ?? region);

export const mapRegionName = (region: string) => regionToDisplayName[region] ?? region;
