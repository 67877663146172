import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Separator} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import InlineButton from '@scm/admin-centre/src/components/InlineButton';
import MakeObsoleteProductModal from './MakeObsoleteProductModal';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {Container} from './SunsetProcedure';
import RemoveCmCertConfigModal from './RemoveCmCertConfigModal';

const ManageProduct = ({sku, isObsolete}: {sku?: string; isObsolete: boolean}) => {
  const [openMakeObsoleteProductModal, setOpenMakeObsoleteProductModal] = useState(false);
  const [openRemoveCmCertConfigModal, setOpenRemoveCmCertConfigModal] = useState(false);
  const [disableObsoleteButton, setDisableObsoleteButton] = useState(false);

  return (
    <Container>
      <Separator className="spark-mar-l-2 spark-mar-b-1 spark-pad-l-0 spark-mar-r-0 spark-pad-r-0 spark-bold spark-large col-sm-10">
        <FormattedMessage id="sunset.separator.manageProduct" />
      </Separator>
      <ButtonsColumn className="spark-mar-t-1">
        <SunsetItemButton
          id="sunset.button.makeProductObsolete"
          className="button-width neutral spark-mar-l-2"
          clickHandler={() => setOpenMakeObsoleteProductModal(true)}
          type="submit"
          disabled={isObsolete || disableObsoleteButton}
          data-testid="makeProductObsoleteButton"
        />
        <SunsetItemButton
          id="sunset.button.removeCertSR360Config"
          className="button-width neutral spark-mar-l-2"
          clickHandler={() => setOpenRemoveCmCertConfigModal(true)}
          type="submit"
          disabled={isObsolete || disableObsoleteButton}
          data-testid="removCertConfigButton"
        />
      </ButtonsColumn>
      <MakeObsoleteProductModal
        open={openMakeObsoleteProductModal}
        setOpen={setOpenMakeObsoleteProductModal}
        setDisableButton={setDisableObsoleteButton}
        sku={sku || ''}
      />
      <RemoveCmCertConfigModal
        open={openRemoveCmCertConfigModal}
        setOpen={setOpenRemoveCmCertConfigModal}
        sku={sku || ''}
      />
    </Container>
  );
};

export const SunsetItemButton = styled(InlineButton)`
  background-color: ${colors.grey700} !important;
  border: none;
  outline: none;
  margin-bottom: 1rem;
  width: 22rem;

  &::after,
  &::before {
    display: none !important;
  }
`;

export const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ManageProduct;
