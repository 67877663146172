import header from './en/header';
import common from '@scm/components/translations/common';
import footer from './en/footer';
import menu from './en/menu';
import myProducts from './en/myProducts';
import products from './en/products';
import productDetails from './en/productDetails';
import tabs from './en/tabs';
import bundleTesting from './en/bundleTesting';
import support from './en/support';
import cleanup from './en/cleanup';
import components from './en/components';
import certificationEngineerDashboard from './en/certificationEngineerDashboard';
import cleanupDashboard from './en/cleanupDashboard';
import redAppDetails from './en/redAppDetails';
import versionDetails from './en/versionDetails';
import freezeCalendar from './en/freezeCalendar';
import svs from './en/svs';
import resources from './en/resources';
import {logout} from './en/logout';
import {authentication} from '@scm/authentication/translations/authentication';
import {auditLog} from './en/auditLog';
import proposal from '@scm/proposal/translations/proposal';
import {sellers} from './en/sellers';
import termsAndConditions from './en/termsAndConditions';
import notificationSettings from './en/notificationSettings';
import {certify} from './en/certify';
import proposals from './en/proposals';
import {roadmap} from './en/roadmap';
import sunset from './en/sunset';
import requestSunset from './en/requestSunset';
import {companyManagement} from './en/companyManagement';
import users from './en/users';
import {standardProduct} from './en/standardProduct';

const translations = {
  ...proposal,
  ...authentication,
  ...common,
  ...header,
  ...footer,
  ...menu,
  ...myProducts,
  ...products,
  ...productDetails,
  ...tabs,
  ...bundleTesting,
  ...support,
  ...cleanup,
  ...components,
  ...certificationEngineerDashboard,
  ...cleanupDashboard,
  ...redAppDetails,
  ...versionDetails,
  ...freezeCalendar,
  ...certify,
  ...svs,
  ...resources,
  ...logout,
  ...auditLog,
  ...sellers,
  ...termsAndConditions,
  ...notificationSettings,
  ...proposals,
  ...roadmap,
  ...sunset,
  ...requestSunset,
  ...companyManagement,
  ...users,
  ...standardProduct,
};

export default translations;
