import ReactGA from 'react-ga4';
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export const useAnalytics = () => {
  const trackPage = (page: string) => {
    ReactGA.set({page});
    ReactGA.send({ hitType: 'pageview', page });
  };
  const location = useLocation();
  useEffect(() => {
    trackPage(location.pathname);
  }, [location]);
}
