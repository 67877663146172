import React, {useEffect, useState} from 'react';
import {getAccessToken, isAdpOrSabreAdminRole} from '@scm/authentication/utils/authentication';
import {proposalsApiBaseLink} from '../assets/baseUrl';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {Loading} from '@scm/components';
import {FormattedMessage, useIntl} from 'react-intl';
import {Proposals} from './Proposals';
import {openToast} from '@scm/components/messaging/openToast';
import {ToastType} from '@sabre/spark-react-core/types';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {ProposalStatus, Configuration, ProposalsApi, SmallProposalResponse} from '@scm/proposal/generated/proposal';
import {CeData, Tabs} from '@scm/admin-centre/src/pages/certificationEngineerDashboard/CertificationEngineerDashboard';
import {
  TabData,
  TabList,
  TabListItem,
  convertToURL,
} from '@scm/product-components/pages/productDetails/productTabs/ProductTabsContainer';
import TabName from '@scm/product-components/pages/productDetails/productTabs/TabName';
import {proposalMapper} from '@scm/admin-centre/src/pages/certificationEngineerDashboard/mappers/proposalMapper';
import {HeaderText} from '@scm/admin-centre/src/components/App';
import {
  Configuration as OnboardingsConfiguration,
  OnboardingsApi,
  OnboardingStatus,
  OnboardingType,
} from '@scm/ce-proposals/generated/onboardings';
import OnboardingRequests from './OnboardingRequests';
import {onboardingRequestsMapper} from './mappers/onboardingRequestsMapper';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

enum ProposalTabsNames {
  'Pending Proposals' = 'Pending Proposals',
  'Onboarding Requests' = 'Onboarding Requests',
  'Troubleshooting' = 'Troubleshooting',
  'Rejected Proposals' = 'Rejected Proposals',
}

export interface OnboardingRequestData {
  companyName?: string;
  companyCountry?: string;
  type?: OnboardingType;
  submitted?: Date;
  status?: OnboardingStatus;
  id?: string;
}

const fetchProposals = async () =>
  await new ProposalsApi(
    new Configuration({
      basePath: proposalsApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).getProposals();

const fetchOnboardingRequests = async () =>
  await new OnboardingsApi(
    new OnboardingsConfiguration({
      basePath: proposalsApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).getOnboardingBasicDetailsRequests();

export const CertificationProposals = () => {
  const [proposals, setProposals] = useState<SmallProposalResponse[]>([]);
  const [pendingProposalData, setPendingProposalData] = useState<CeData[]>([]);
  const [troubleProposalData, setTroubleProposalData] = useState<CeData[]>([]);
  const [rejectedProposalData, setRejectedProposalData] = useState<CeData[]>([]);
  const [onboardingRequestsData, setOnboardingRequestsData] = useState<OnboardingRequestData[]>();
  const [isLoading, setIsLoading] = useState(true);
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;

  if (url === InternalUrls.ProposalsCertification) {
    navigate(InternalUrls.ProposalsCertification + '/' + convertToURL(ProposalTabsNames['Pending Proposals']));
  }

  const fetchData = async () => {
    await fetchProposals()
      .then(proposalList => setProposals(proposalList))
      .catch(() => {
        openToast(
          formatMessage({id: 'support.proposals.error.title'}),
          formatMessage({id: 'support.proposals.error.description'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
        navigate(InternalUrls.MyProducts);
      });

    fetchOnboardingRequests()
      .then(requests => setOnboardingRequestsData(onboardingRequestsMapper(requests)))
      .catch(() => {
        openToast(
          formatMessage({id: 'proposals.onboardingRequests.error.title'}),
          formatMessage({id: 'proposals.onboardingRequests.error.description'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      });
  };

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, []);

  const proposalStatuses: Array<string> = [ProposalStatus.InReview, ProposalStatus.Submitted];

  useEffect(() => {
    if (proposals.length) {
      setPendingProposalData(
        proposals
          .filter(proposal => proposalStatuses.includes(proposal.status as string))
          .map(item => proposalMapper(item))
      );

      setRejectedProposalData(
        proposals.filter(proposal => proposal.status === ProposalStatus.Rejected).map(item => proposalMapper(item))
      );

      setTroubleProposalData(
        proposals
          .filter(proposal => proposal.status === ProposalStatus.Approved && !proposal.proposalSku)
          .map(item => proposalMapper(item))
      );
    }
  }, [proposals, setPendingProposalData]);

  const proposalsTabs: Array<TabData> = [
    {
      name: ProposalTabsNames['Pending Proposals'],
      numberOfItems: pendingProposalData.length,
      content: () => <Proposals items={pendingProposalData} isPendingProposalTab />,
    },
    {
      name: ProposalTabsNames['Onboarding Requests'],
      content: () => <OnboardingRequests onboardingRequests={onboardingRequestsData} />,
      numberOfItems: onboardingRequestsData?.length || 0,
      hideElement: !isAdpOrSabreAdminRole(),
    },
    {
      name: ProposalTabsNames['Troubleshooting'],
      numberOfItems: troubleProposalData.length,
      content: () => <Proposals items={troubleProposalData} />,
    },
    {
      name: ProposalTabsNames['Rejected Proposals'],
      numberOfItems: rejectedProposalData.length,
      content: () => <Proposals items={rejectedProposalData} />,
    },
  ];

  const generateHeaders = () => (
    <TabList>
      {proposalsTabs.map(({name, numberOfItems, hideElement}) => {
        const tabName = (name: string, numberOfItems: number | undefined) => `${name} (${numberOfItems})`;
        return (
          !hideElement && (
            <TabListItem
              key={name}
              data-name={name}
              onClick={() => navigate(convertToURL(name))}
              onKeyDown={evt => {
                if (evt.key === 'Enter') {
                  navigate(convertToURL(name));
                }
              }}
            >
              <TabName
                key={name}
                activeTabName={tabName(location.pathname.slice(location.pathname.lastIndexOf('/') + 1), numberOfItems)}
                tabName={tabName(name, numberOfItems)}
              />
            </TabListItem>
          )
        );
      })}
    </TabList>
  );

  return isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <div className="spark-pad-2">
      <HeaderText className="spark-mar-b-2">
        <FormattedMessage id="support.proposals" />
      </HeaderText>
      <Tabs className="spark-panel">
        <div className="spark-panel__content">{generateHeaders()}</div>
        <Routes>
          {proposalsTabs.map(({name, content}) => (
            <Route key={name} element={content()} path={convertToURL(name)} />
          ))}
        </Routes>
      </Tabs>
    </div>
  );
};
