import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {useNavigate} from 'react-router-dom';
import InternalUrls from 'utils/internalUrls';
import colors from '@scm/components/assets/colors';

const PartnerTierLabel = ({tier, className}: {tier: string; className?: string}) => {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={getTheme(tier)}>
      <Tier className={className} onClick={() => navigate(InternalUrls.Tiers)}>
        {tier}
      </Tier>
    </ThemeProvider>
  );
};

const getTheme = (tier: string) => {
  switch (tier) {
    case 'Platinum':
      return {labelColor: colors.tierPlatinum, labelColorHover: colors.tierPlatinumHover, textColor: colors.white};
    case 'Gold':
      return {labelColor: colors.tierGold, labelColorHover: colors.tierGoldHover, textColor: colors.white};
    case 'Silver':
    default:
      return {labelColor: colors.tierSilver, labelColorHover: colors.tierSilverHover, textColor: colors.grey700};
  }
};

const Tier = styled.span.attrs<{className?: string}>(className => ({
  className: `${className} spark-badge spark-badge--sm`,
}))`
  background-color: ${props => props.theme.labelColor};
  color: ${props => props.theme.textColor};
  border: none;

  :hover {
    color: ${props => props.theme.textColor};
    background-color: ${props => props.theme.labelColorHover};
  }
`;

export default PartnerTierLabel;
