import React from 'react';
import SectionHeader from './SectionHeader';
import ReviewRow from './ReviewRow';
import {termsAndConditionName} from '../../../ProposalValues';
import ReviewParagraphs from '@scm/components/form/review/ReviewParagraphs';

const InsuranceReview = ({isOnboarding}: {isOnboarding?: boolean}) => (
  <>
    <SectionHeader id="review.insurance.title" />
    {isOnboarding ? (
      <ReviewParagraphs label={['joinUsPage.form.reviewAndSubmit.termsAndCondition.description']} />
    ) : (
      <ReviewRow name={termsAndConditionName} />
    )}
  </>
);

export default InsuranceReview;
