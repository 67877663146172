import React, {useContext} from 'react';
import {ProductIconContext} from '../../../../forms/ProductIcon';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import IconHeadings from './IconHeadings';
import colors from '@scm/components/assets/colors';
import UploadIcon from '../upload-icon/UploadIcon';
import { BlankIcon } from '@scm/version/components/VersionHeader';

const Icon = ({productNameTooltipValue}: {productNameTooltipValue: string}) => {
  const {icon} = useContext(ProductIconContext);

  return (
    <IconContainer>
      {!!icon ? <AppIcon url={icon} /> : <BlankIcon />}
      <div className="spark-pad-l-1">
        <IconHeadings productNameTooltipValue={productNameTooltipValue} />
        <UploadIcon />
      </div>
    </IconContainer>
  );
};

const IconContainer = styled.div`
  display: flex;
  height: 80px;
  @media (max-width: ${deviceWidths.xs}px) {
    transform: scale(0.8) translateX(-4rem);
  }
`;

const AppIcon = styled.div.attrs(({url, mainIcon}: {url: string; mainIcon?: boolean}) => ({
  url,
  mainIcon,
}))`
  width: 80px;
  flex-shrink: 0;
  background-color: ${colors.transparent};
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-clip: content-box;
`;

export default Icon;
