import styled from 'styled-components';

const CenterItems = styled.div.attrs<{className?: string}>(({className}) => ({
  className: `${className} spark-flex`,
}))`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none;
`;

export default CenterItems;
