import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const Star = styled.span<{starColor?: string}>`
  &:after {
    font-weight: bold;
    color: ${props => (props.starColor ? props.starColor : colors.red)};
    margin: 0 3px;
    vertical-align: -2px;
    content: '*';
  }
`;

export default Star;
