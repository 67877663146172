import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import useId from '@scm/components/accessibility/hooks/useId';

const ButtonContent = ({
  handleOpen,
  messageId,
  hasTopMargin,
  disabled,
  negative,
}: {
  handleOpen: () => void;
  messageId: string;
  hasTopMargin?: boolean;
  disabled?: boolean;
  negative?: boolean;
}) => {
  const modalId = useId('openModal');
  return (
    <ButtonContainer>
      <Button
        id={modalId}
        onClick={handleOpen}
        className={hasTopMargin ? 'spark-mar-t-2' : 'spark-mar-r-1'}
        size={ButtonSize.SMALL}
        disabled={disabled}
        negative={negative}
      >
        <FormattedMessage id={messageId} />
      </Button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  & button[disabled] {
    background-color: ${colors.grey200} !important;
  }
`;

export default ButtonContent;
