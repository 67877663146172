import React, {useContext} from 'react';
import styled from 'styled-components';
import {StorefrontDataContext} from '../../storefrontData/dataProvider/StorefrontDataProvider';

export enum TestingEnvironment {
  'CERT' = 'CERT',
  'PROD' = 'PROD',
}

// Legacy code - need to import StorefrontDataContext before proposal usage
const BundleTestingForm = () => {
  const methods = useContext(StorefrontDataContext);

  return <></>;
};
export const ButtonContainer = styled.div`
  justify-content: flex-end;
`;
