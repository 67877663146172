const sunset = {
  'sunset.separator.sendMessageToBuyers': 'Send Message to Buyers',
  'sunset.button.sendMessageToBuyers': 'Send',
  'sunset.sendMessageForm.subject.label': 'Subject',
  'sunset.sendMessageForm.message.label': `In below message "Hello" and "Best regards" part will be added to message automatically, please specify only message content here.`,
  'sunset.sendMessageForm.successTitle': 'Success',
  'sunset.sendMessageForm.success': 'Sunset message to buyers has been sent successfully.',
  'sunset.sendMessageForm.errorTitle': 'Error',
  'sunset.sendMessageForm.error': 'An error occurred while trying to send sunset message to buyers.',
  'sunset.separator.manageProduct': 'Manage Product',
  'sunset.button.makeProductObsolete': 'Make Product Obsolete',
  'sunset.makeProductObsolete.modal.title': 'Make Product Obsolete',
  'sunset.makeProductObsolete.modal.description':
    'Please confirm that you want to make this product obsolete. After that, the product will no longer be available in SCM. Also, some Salesforce triggers (e.g. related to orders) may be started. Please consider this action irreversible.',
  'sunset.makeProductObsolete.successTitle': 'Success',
  'sunset.makeProductObsolete.success': 'Product has been made obsolete.',
  'sunset.makeProductObsolete.errorTitle': 'Error',
  'sunset.makeProductObsolete.error': 'An error occurred while trying to make product obsolete.',
  'sunset.button.removeCertSR360Config': 'Remove CERT SR360 Config',
  'sunset.removeCertSR360Config.modal.title': 'Remove CERT SR360 Config',
  'sunset.removeCertSR360Config.modal.description':
    'Please confirm that you want to remove CERT SR360 configuration (CM roles, product, update sites) for this Red App.',
  'sunset.removeCertSR360Config.successTitle': 'Success',
  'sunset.removeCertSR360Config.success': 'CERT SR360 configuration has been removed.',
  'sunset.removeCertSR360Config.errorTitle': 'Error',
  'sunset.removeCertSR360Config.error': 'An error occurred while trying to remove CERT SR360 configuration.',
  'sunset.separator.sunsetProcedure': 'Sunset Procedure',
  'sunset.button.sunsetProcedure': 'See Sunset Procedure',
  'sunset.description.item1':
    'Agree the details for sunset with Red App owner. Such data should be already provided, if customer requested sunset using Admin Centre form. // WILL BE IMPLEMENTED SOON',
  'sunset.description.item2': 'Details that should be known before procedure are:',
  'sunset.description.item2.item1': 'if Red App should be blocked for ordering and/or hidden in SCM, and if so, when?',
  'sunset.description.item2.item2':
    'if communication to Red App buyers should be sent? What information should be included? It is recommended to send message about the sunset at least 30 days before.',
  'sunset.description.item2.item3': 'what date Red App should be removed from Sabre Red 360?',
  'sunset.description.item3':
    'Hide Red App and/or block ordering (if applicable) using options in "Red App Details" tab.',
  'sunset.description.item4': 'Send message to buyers (if applicable) using form in Sunset tab.',
  'sunset.description.item5':
    'Configure cleanup for all Published versions of the Red App. As in fact this is sunset procedure, it is not recommended to send cleanup notifications, so "With Notification" option in cleanup should be set to "No". "SR360 Config Removal Date" field should be set to the date agreed with Red App owner, same for all versions.',
  'sunset.description.item6': 'Configure cleanup for all not published versions.',
  'sunset.description.item7':
    'After "SR360 Config Removal Date" all versions should be removed from Sabre Red 360, means agents lose the access to Red App.',
  'sunset.description.item8':
    'Full Version Removal Date will be automatically set for all versions after successful removal from Sabre Red 360.',
  'sunset.description.item9': `When all versions are removed from Sabre Red 360, it is possible to set Red App status to 'Obsolete' - it should be done manually as soon as possible after Sabre Red 360 cleanup, as this action also triggers all related orders cancellation. Status can be set to 'Obsolete' using 'Make Product Obsolete' button in Sunset tab. At this step, CERT SR360 Config should be also removed.`,
  'sunset.sendMessage.modal.title': 'Send Message to Buyers',
  'sunset.sendMessage.modal.description':
    'Please confirm that you want to send message to buyers. After that, the message will be sent to all active buyers of this product.',
};

export default sunset;
