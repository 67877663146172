import React from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {FormattedMessage, useIntl} from 'react-intl';
import {Message} from '@sabre/spark-react-core';
import NewThreshold from './NewThreshold';
import {UseFormReturn} from 'react-hook-form';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {ActionAttribute, Thresholds, UsedService} from '@scm/product-components/generated/certification';
import {SvsValues, thresholdsName} from '../SvsValues';

const ServiceUsage = ({
  formMethods,
  services,
  acceptedThresholds,
}: {
  formMethods: UseFormReturn<SvsValues>;
  services: UsedService[];
  acceptedThresholds: Thresholds[];
}) => {
  const {formatMessage} = useIntl();
  const {getValues} = formMethods;

  const createTextContent = (actionAttribute: ActionAttribute) => (
    <p className="spark-mar-b-0">
      {formatMessage(
        {id: 'svs.servicesUsage.serviceContent'},
        {threshold: actionAttribute.threshold, metric: actionAttribute.metric}
      )}
      {!!actionAttribute.thresholdExceeded &&
        formatMessage({id: 'svs.servicesUsage.serviceContent.exceeded'}, {exceeded: String(true)})}
    </p>
  );
  const createThresholdExceptionContent = (thresholdException: number | null) => (
    <p className="spark-mar-l-2">
      {formatMessage(
        {id: 'svs.servicesUsage.thresholdExceptionContent'},
        {
          count: thresholdException,
          strong: word => <strong>{word}</strong>,
        }
      )}
    </p>
  );

  const thresholdException = (action: ActionAttribute, acceptedItem: Thresholds): number | null => {
    if (action && acceptedItem) {
      if (acceptedItem.threshold) {
        return acceptedItem.threshold;
      }
      const acceptedAction = acceptedItem.actions?.filter(act => act.name === action.name)[0];
      if (acceptedAction) {
        return acceptedAction.threshold ?? 0;
      }
    }
    return null;
  };

  const ActionItem = ({
    action,
    acceptedItem,
    service,
  }: {
    action: ActionAttribute;
    acceptedItem: Thresholds;
    service: UsedService;
  }) => {
    const thresholdAccepted = thresholdException(action, acceptedItem);
    return (
      <ActionContainer className="spark-mar-t-1">
        <ActionTitle className="spark-bold spark-mar-b-0">{action.name}</ActionTitle>
        <ServiceContent className="spark-pad-l-0 spark-pad-t-.5">
          {createTextContent(action)}
          {thresholdAccepted && createThresholdExceptionContent(thresholdAccepted)}
          {thresholdAccepted === null && action.thresholdExceeded && (
            <NewThreshold
              key={service.name + (action.name ?? '')}
              service={service}
              acceptedThresholds={acceptedThresholds}
              action={action}
            />
          )}
        </ServiceContent>
      </ActionContainer>
    );
  };

  const areArraysEqual = (array1: string[], array2: string[]): boolean => {
    if (array1.length !== array2.length) {
      return false;
    }

    const sortedArray1 = array1.sort();
    const sortedArray2 = array2.sort();

    return sortedArray1.every((element, index) => element === sortedArray2[index]);
  };

  const allExceededActionsAreCoveredByAcceptedItem = (service: UsedService, acceptedItem: Thresholds): boolean => {
    function isThereNoExceededAction() {
      return service.actions !== undefined && service.actions.every(action => !action.thresholdExceeded);
    }

    function isTheOnlyActionCoveredByAcceptedItem() {
      return service.actions?.length === 1 && acceptedItem !== undefined;
    }

    function areAllActionsCoveredByAcceptedItems() {
      return areArraysEqual(
        service.actions
          ? service.actions.filter(action => action.thresholdExceeded).map(action => action.name ?? '')
          : [],
        acceptedItem && acceptedItem.actions ? acceptedItem.actions.map(action => action.name ?? '') : []
      );
    }

    return isThereNoExceededAction() || isTheOnlyActionCoveredByAcceptedItem() || areAllActionsCoveredByAcceptedItems();
  };

  const hasAcceptedExceptions = (service: UsedService): Thresholds => {
    const thresholds: Array<Thresholds> = getValues(thresholdsName);
    return thresholds.filter(threshold => threshold.service === service.name)[0];
  };

  const Actions = ({service, acceptedItem}: {service: UsedService; acceptedItem: Thresholds}) => (
    <>
      {service.actions && service.actions.length > 1 && (
        <p className="spark-mar-t-1 spark-mar-b-1 spark-large">
          <FormattedMessage id="svs.servicesUsage.actionsTitle" />
        </p>
      )}
      <ul className="spark-list--no-bullets spark-mar-0 spark-mar-l-2">
        {service.actions?.map(action => (
          <ActionItem
            key={`${action.name}${service.name}`}
            action={action}
            acceptedItem={acceptedItem}
            service={service}
          />
        ))}
      </ul>
    </>
  );

  const ServiceItem = ({service, acceptedItem}: {service: UsedService; acceptedItem: Thresholds}) => (
    <ServiceContainer className="spark-mar-2">
      <ServiceTitle exceeded={!allExceededActionsAreCoveredByAcceptedItem(service, acceptedItem)}>
        {service.name}
      </ServiceTitle>
      <ServiceContent>{service.actions && <Actions service={service} acceptedItem={acceptedItem} />}</ServiceContent>
    </ServiceContainer>
  );

  return (
    <>
      {services?.length ? (
        <ul className="spark-list--no-bullets spark-mar-0">
          {services?.map(service => (
            <ServiceItem key={service.name} service={service} acceptedItem={hasAcceptedExceptions(service)} />
          ))}
        </ul>
      ) : (
        <Message
          role={MessageRole.ALERT}
          status={MessageStatus.WARNING}
          content={formatMessage({id: 'svs.servicesUsage.message'})}
          type={MessageType.CONTAINER}
          className="spark-mar-2"
        />
      )}
    </>
  );
};

const ActionTitle = styled.p`
  overflow-wrap: break-word;
`;

const ActionContainer = styled.li`
  margin: 0;
  &:not(:last-of-type) {
    border-bottom: 0.1rem solid ${colors.grey100};
  }
`;

const ServiceContainer = styled.li`
  border: 0.1rem solid ${colors.grey100};
`;

const ServiceTitle = styled.div<{exceeded?: boolean}>`
  background-color: ${props => (props.exceeded ? colors.red : colors.grey100)};
  color: ${props => (props.exceeded ? colors.white : colors.black)};
  font-weight: bold;
  padding-left: 1rem;
  line-height: 300%;
  overflow-wrap: break-word;
`;
const ServiceContent = styled.div`
  padding: 1rem 2rem;
  color: ${colors.black};
  display: flex;
  align-items: center;
`;

export default ServiceUsage;
