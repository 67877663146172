import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ButtonSize, MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import {Button, Message} from '@sabre/spark-react-core';
import Modal from '@sabre/spark-react-core/modal';
import colors from '@scm/components/assets/colors';
import color from '@scm/components/assets/colors';
import styled from 'styled-components';
import {SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  blockOrderingAndHideProductDateName,
  blockOrderingAndHideProductImmediatelyName,
  RequestSunsetValues,
  sendNotificationConfirmationName,
  sendNotificationDateAsapName,
  sendNotificationDateName,
  sendNotificationTextName,
  sunsetDateAsapName,
  sunsetDateName,
} from './schema/RequestSunsetValues';
import {requestSunsetSchema} from './schema/requestSunsetSchema';
import {initialValues} from './schema/requestSunsetConstants';
import BlockOrderingAndHideProduct from './BlockOrderingAndHideProduct';
import SunsetDate, {isTodayOrFutureDate} from './SunsetDate';
import SendNotification from './SendNotification';
import parse from 'html-react-parser';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';
import {Configuration as ProductConfiguration, ProductApi} from '@scm/product-components/generated/product';
import {productApiBaseLink} from '@scm/product-components';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {RequestSunsetRequest} from '../../../generated/product';

enum ModalSteps {
  'form' = 'form',
  'confirmation' = 'confirmation',
}

const sendRequestSunsetRequest = async (sku: string, requestSunsetRequest: RequestSunsetRequest) =>
  new ProductApi(
    new ProductConfiguration({
      basePath: productApiBaseLink,
      accessToken: getAccessToken() as string,
    })
  ).requestSunset({sku, requestSunsetRequest});

const RequestSunsetModal = ({open, setOpen, sku}: {open: boolean; setOpen: (open: boolean) => void; sku: string}) => {
  const {formatMessage} = useIntl();
  const [currentStep, setCurrentStep] = useState<ModalSteps>(ModalSteps.form);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: {isValid},
  } = useForm<RequestSunsetValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(requestSunsetSchema),
  });

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const areDatesValid = () => {
    let isValid = true;

    if (watch(sunsetDateAsapName) === Confirmation.No) {
      if (watch(sunsetDateName) === '') {
        return false;
      } else if (!isTodayOrFutureDate(watch(sunsetDateName))) {
        return false;
      }
    }

    if (watch(blockOrderingAndHideProductImmediatelyName) === Confirmation.No) {
      if (watch(blockOrderingAndHideProductDateName) === '') {
        return false;
      } else if (!isTodayOrFutureDate(watch(blockOrderingAndHideProductDateName))) {
        return false;
      }
    }

    if (
      watch(sendNotificationDateAsapName) === Confirmation.No &&
      watch(sendNotificationConfirmationName) === Confirmation.Yes
    ) {
      if (watch(sendNotificationDateName) === '') {
        return false;
      } else if (!isTodayOrFutureDate(watch(sendNotificationDateName))) {
        return false;
      }
    }

    if (
      watch(blockOrderingAndHideProductImmediatelyName) === Confirmation.No &&
      watch(sunsetDateAsapName) === Confirmation.No
    ) {
      const blockAndHideDate = watch(blockOrderingAndHideProductDateName);
      const sunsetDate = watch(sunsetDateName);
      if (blockAndHideDate !== '' && sunsetDate !== '') {
        isValid = sunsetDate >= blockAndHideDate;
        if (!isValid) {
          return false;
        }
      }
    }

    if (
      watch(sunsetDateAsapName) === Confirmation.No &&
      watch(sendNotificationDateAsapName) === Confirmation.No &&
      watch(sendNotificationConfirmationName) === Confirmation.Yes
    ) {
      const sunsetDate = watch(sunsetDateName);
      const notificationDate = watch(sendNotificationDateName);
      if (sunsetDate !== '' && notificationDate !== '') {
        isValid = sunsetDate >= notificationDate;
        if (!isValid) {
          return false;
        }
      }
    }

    return isValid;
  };

  const onSubmit: SubmitHandler<RequestSunsetValues> = data => {
    const requestSunsetRequest = {
      blockOrderingAndHideProductImmediately: data[blockOrderingAndHideProductImmediatelyName] === Confirmation.Yes,
      blockOrderingAndHideProductDate:
        data[blockOrderingAndHideProductDateName] !== ''
          ? new Date(data[blockOrderingAndHideProductDateName])
          : undefined,
      sunsetDateAsap: data[sunsetDateAsapName] === Confirmation.Yes,
      sunsetDate: data[sunsetDateName] !== '' ? new Date(data[sunsetDateName]) : undefined,
      sendNotification: data[sendNotificationConfirmationName] === Confirmation.Yes,
      sendNotificationAsap: data[sendNotificationDateAsapName] === Confirmation.Yes,
      sendNotificationDate:
        data[sendNotificationDateName] !== '' ? new Date(data[sendNotificationDateName]) : undefined,
      additionalNotificationContent: data[sendNotificationTextName],
    };

    sendRequestSunsetRequest(sku, requestSunsetRequest)
      .then(() => {
        openToast(
          createMessageString(formatMessage, 'requestSunset.send', true, false),
          createMessageString(formatMessage, 'requestSunset.send', false, false),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      })
      .catch(() => {
        openToast(
          createMessageString(formatMessage, 'requestSunset.send', true, true),
          createMessageString(formatMessage, 'requestSunset.send', false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      })
      .finally(() => {
        reset();
        onClose();
        setCurrentStep(ModalSteps.form);
      });
  };

  return (
    <ModalContainer onClose={onClose} open={open} title={formatMessage({id: 'requestSunset.header'})}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {currentStep === ModalSteps.form && (
          <>
            <SunsetDate watch={watch} control={control} />
            <BlockOrderingAndHideProduct watch={watch} control={control} />
            <SendNotification watch={watch} control={control} />
            <AlertMessage className="spark-mar-t-2">
              <i className="spark-icon--fill spark-icon-alert-triangle spark-mar-r-1" />
              {parse(formatMessage({id: 'requestSunset.section2.alert'}))}
            </AlertMessage>
          </>
        )}
        {currentStep === ModalSteps.confirmation && (
          <Message
            role={MessageRole.ALERT}
            status={MessageStatus.WARNING}
            content={formatMessage({id: 'requestSunset.confirmation.message'})}
            type={MessageType.PAGE}
            className="spark-mar-t-2"
          />
        )}
        <ButtonsContainer className="spark-btn-group">
          <CancelButton
            className="spark-btn--secondary"
            onClick={() => {
              if (currentStep === ModalSteps.form) {
                onClose();
              } else if (currentStep === ModalSteps.confirmation) {
                setCurrentStep(ModalSteps.form);
              }
            }}
            size={ButtonSize.SMALL}
          >
            <FormattedMessage id={currentStep === ModalSteps.form ? 'common.button.cancel' : 'common.backButton'} />
          </CancelButton>
          <Button
            className="spark-btn-group-primary"
            size={ButtonSize.SMALL}
            type={currentStep === ModalSteps.confirmation ? 'submit' : 'button'}
            onClick={() => {
              if (currentStep === ModalSteps.form) {
                setCurrentStep(ModalSteps.confirmation);
              }
            }}
            disabled={!areDatesValid() || !isValid}
            progress
            data-testid="sendSunsetRequestSubmitButton"
            key={currentStep}
          >
            <FormattedMessage id="requestSunset.button.sendSunsetRequest" />
          </Button>
        </ButtonsContainer>
      </Form>
    </ModalContainer>
  );
};

const ModalContainer = styled(Modal)`
  & .spark-modal__body {
    padding: 3rem;
  }
`;

const Form = styled.form`
  & .date-picker {
    width: 60%;
  }
`;

const AlertMessage = styled.p<{error?: boolean}>`
  text-align: justify;
  text-justify: inter-word;
  white-space: normal;
  color: ${props => (!!props.error ? color.red : color.warningDarkYellow)};
`;

const ButtonsContainer = styled.div`
  margin-top: 2rem;
`;

const CancelButton = styled(Button)`
  background-color: ${colors.white} !important;
  border: 1px solid ${colors.sparkGreen} !important;
`;

export default RequestSunsetModal;
