import {Table} from '@scm/product-components/pages/productDetails/productTabs/bundles/BundlesTable';
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button, Icon} from '@sabre/spark-react-core';
import {FormattedMessage} from 'react-intl';
import colors from '@scm/components/assets/colors';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {ProductMedia} from '../../content/utilities/getStorefrontImages';
import {format} from 'date-fns';
import {dateFormat} from '@scm/components/utils/common';
import {createBaseUrl} from '@scm/admin-centre/src/pages/resources/resourcesConfig';
import {additionalFileName, MediaContext} from '../../../../forms/Media';
import {ImageFile} from '@scm/components/files/FileUpload';
import useGetIsObsolete from '../../../../hooks/useGetIsObsolete';

const AdditionalGuidesList = ({items}: {items?: ProductMedia[]}) => {
  const isObsolete = useGetIsObsolete();
  const {setAdditionalFilesToDelete, setAdditionalFilesToEdit, setValue} = useContext(MediaContext);
  const [sortFilesByGuideNameAsc, setSortFilesByGuideNameAsc] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [tempFileName, setTempFileName] = useState<string | null>(null);
  const cancelEditRef = useRef(null);

  const handleUpdateAdditionalFile = (file: ProductMedia) => {
    const oldName = file.name;
    file.name = tempFileName ?? '';
    setAdditionalFilesToEdit(prev => [...prev, file]);
    setValue(
      additionalFileName,
      items?.map(item => (item.name === oldName ? file : item)),
      {
        shouldDirty: true,
        shouldTouch: true,
      }
    );
    setEditingId(null);
  };

  const isNewFile = (file: File | ProductMedia) => (file as ProductMedia & ImageFile).shouldUpload;

  useEffect(() => {
    if (editingId) {
      setTempFileName(editingId);
    }
  }, [editingId]);

  const sortBy = (key: string) => {
    if (key === 'guideName') {
      setSortFilesByGuideNameAsc(!sortFilesByGuideNameAsc);
    }
  };

  const sortedValues = useMemo(() => {
    if (sortFilesByGuideNameAsc) {
      return items?.sort((a, b) => a.name.localeCompare(b.name));
    }
    return items;
  }, [items, sortFilesByGuideNameAsc]);

  const listWithoutFile = (file: ProductMedia | File) => {
    return items?.filter(item => item.name !== file.name) ?? [];
  };

  const removeLocalFile = (file: File) => {
    setValue(additionalFileName, listWithoutFile(file));
  };

  const removeAdditionalGuideHandler = (item: ProductMedia) => {
    if (!isNewFile(item)) {
      setAdditionalFilesToDelete(prev => [...prev, item]);
      setValue(additionalFileName, listWithoutFile(item), {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const createTableHeader = () => {
    return (
      <thead>
        <tr>
          <TableHeader
            key="guideName"
            data-sort="desc"
            sortAsc={sortFilesByGuideNameAsc}
            onClick={() => sortBy('guideName')}
            direction="left"
          >
            <FormattedMessage id="productDetails.media.additionalFiles.table.header.guideName" />
          </TableHeader>
          <TableHeader key="uploadDate">
            <FormattedMessage id="productDetails.media.additionalFiles.table.header.uploadDate" />
          </TableHeader>
          <TableHeader key="lastModified">
            <FormattedMessage id="productDetails.media.additionalFiles.table.header.lastModified" />
          </TableHeader>
          <TableHeader key="actions" />
        </tr>
      </thead>
    );
  };

  const createTableBody = () => {
    return (
      <tbody>
        {sortedValues?.map(item => (
          <tr key={item.name}>
            <ColumnWrapper direction="left">
              <Icon name="documents" className="spark-pad-r-.5" />
              {editingId === item.name ? (
                <EditingInput
                  onKeyPress={event => {
                    if (event.key === 'Enter' || event.key === 'Escape') {
                      handleUpdateAdditionalFile(item);
                    }
                  }}
                  onBlur={e => {
                    if (e.relatedTarget !== cancelEditRef.current) {
                      handleUpdateAdditionalFile(item);
                    }
                  }}
                  type="text"
                  value={tempFileName ?? ''}
                  onChange={e => setTempFileName(e.target.value)}
                />
              ) : (
                <>{item.name}</>
              )}
            </ColumnWrapper>
            <ColumnWrapper>
              {format(item.createdDate ? new Date(item.createdDate) : new Date(), dateFormat)}
            </ColumnWrapper>
            <ColumnWrapper>
              {format(item.modifiedDate ? new Date(item.modifiedDate) : new Date(), dateFormat)}
            </ColumnWrapper>
            {!isObsolete ? (
              <ColumnWrapper direction="right">
                {editingId !== item.name ? (
                  <Button
                    combo={!isNewFile(item)}
                    negative={isNewFile(item)}
                    onClick={e => {
                      if (
                        e.target instanceof HTMLButtonElement &&
                        e.target.className.includes('spark-popover__toggle')
                      ) {
                        return;
                      }
                      if (isNewFile(item)) {
                        removeLocalFile(item);
                        return;
                      }
                      setEditingId(item.name);
                    }}
                    actionLinks={[
                      {
                        label: 'View',
                        onClick: () => window.open(`${createBaseUrl()}/${item.uri}`, '_blank'),
                      },
                      {label: 'Remove', onClick: () => removeAdditionalGuideHandler(item)},
                    ]}
                    size={ButtonSize.EXTRA_SMALL}
                  >
                    <FormattedMessage id={isNewFile(item) ? 'Remove' : 'Edit'} />
                  </Button>
                ) : (
                  <Button
                    ref={cancelEditRef}
                    size={ButtonSize.EXTRA_SMALL}
                    onClick={(e): void => {
                      e.stopPropagation();
                      setEditingId(null);
                    }}
                  >
                    <FormattedMessage id="Cancel" />
                  </Button>
                )}
              </ColumnWrapper>
            ) : (
              <ColumnWrapper direction="right" />
            )}
          </tr>
        ))}
      </tbody>
    );
  };
  return (
    !!items?.length && (
      <TableWrapper className="spark-table spark-mar-t-1">
        <div className="spark-table__scroll">
          <TableContent role="presentation" className="spark-mar-b-1">
            {createTableHeader()}
            {createTableBody()}
          </TableContent>
        </div>
      </TableWrapper>
    )
  );
};

const EditingInput = styled.input`
  padding: 1px !important;
  margin-left: 0.5rem !important;
  width: auto !important;
  border-radius: 0 !important;
`;

const TableContent = styled.table`
  & th {
    white-space: normal !important;
    padding: 1rem 2rem !important;
  }

  & * {
    font-size: 1rem !important;
  }
`;

const ColumnWrapper = styled.td<{direction?: 'left' | 'right'}>`
  text-align: ${props => props.direction || 'left'} !important;
  background-image: none !important;
  padding: 0 2rem !important;
  border: none !important;
  border-bottom: 1px solid ${colors.grey300} !important;

  tr:hover & {
    background-color: ${colors.grey100};
  }
`;

const TableWrapper = styled(Table)`
  border-top: none !important;
  margin: 0 -2rem;
`;

const TableHeader = styled.th<{sortAsc?: boolean; direction?: 'left' | 'right'}>`
  &&& {
    font-size: 12px;
    font-weight: bold;
    text-align: ${props => props.direction || 'left'} !important;
    background-color: ${colors.white} !important;
    background-image: none !important;
    color: ${colors.black} !important;
    border: none !important;
    border-bottom: 1px solid ${colors.grey300} !important;
  }

  &::after {
    margin-left: 0.5rem;
    vertical-align: 1px !important;
    transition: 0.3s;
    transform: rotate(${props => (props.sortAsc ? '180deg' : '0deg')}) !important;
  }
`;

export default AdditionalGuidesList;
