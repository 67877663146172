import {useEffect, useState} from 'react';
import {
  Configuration as CertificationConfiguration,
  GetValidationResultsRequest,
  ValidationIssue,
  VersionsApi,
} from '@scm/product-components/generated/certification';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {Loading} from '@scm/components';
import {LoadingContainer} from '@scm/product-components/pages/productDetails/productTabs/proposalData/ProposalData';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const SvsErrorsModal = ({versionId}: {versionId: string}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [svsWarnings, setSvsWarnings] = useState<Array<ValidationIssue>>([]);
  const [svsErrors, setSvsErrors] = useState<Array<ValidationIssue>>([]);

  const fetchValidationResults = (request: GetValidationResultsRequest) =>
    new VersionsApi(
      new CertificationConfiguration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() as string,
        middleware: middleware,
      })
    ).getValidationResults(request);

  useEffect(() => {
    if (loading) {
      fetchValidationResults({id: versionId})
        .then(res => {
          setSvsWarnings(
            (res.validation?.warnings?.length !== 0 ? res.validation?.warnings : res.compare?.warnings) ?? []
          );
          setSvsErrors((res.validation?.errors?.length !== 0 ? res.validation?.errors : res.compare?.errors) ?? []);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [loading]);

  const generateContent = (data: Array<ValidationIssue>) =>
    data.length > 0 && (
      <>
        <ul>
          {data.map(item => (
            <li key={item.code} className="spark-pad-l-.5 spark-pad-r-.5">
              {Object.entries(item)
                .filter(([_, value]) => !!value)
                .map(([key, value]) => (
                  <div key={value}>
                    <p>{key}:</p>
                    <p>{value}</p>
                  </div>
                ))}
            </li>
          ))}
        </ul>
      </>
    );

  return loading ? (
    <LoadingContainer>
      <Loading sizing="md" />
    </LoadingContainer>
  ) : (
    <SvsErrorsContainer className="spark-pad-t-2 spark-pad-r-1 spark-mar-t-2">
      {generateContent([...svsErrors, ...svsWarnings])}
    </SvsErrorsContainer>
  );
};

const SvsErrorsContainer = styled.div`
  background: ${colors.grey100};
  border: solid 1px ${colors.grey200};
  border-radius: 0.25rem;
  color: ${colors.grey900};
  font-weight: 400;
  max-height: 50vh;
  overflow-y: scroll;

  div {
    display: flex;
  }

  div > p:first-child {
    text-wrap: nowrap;
  }

  p {
    margin: 0.25rem;
  }

  li:not(:last-child)::after {
    content: '';
    display: block;
    border-top: 1px solid ${colors.lightGreyHr};
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  & li {
    list-style-type: none;
    margin: 0;
  }
`;

export default SvsErrorsModal;
