import {Configuration, RoadmapApi, RoadmapVotesResponse, Vote} from '../../generated/roadmap';
import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {FormattedDateParts, FormattedMessage, useIntl} from 'react-intl';
import {Button, Icon} from '@sabre/spark-react-core';
import {ButtonSize, IconSize, ToastType} from '@sabre/spark-react-core/types';
import {RoadmapContext} from '../../components/RoadmapProvider';
import {roadmapApiBaseLink} from '../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {openToast} from '@scm/components/messaging/openToast';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {Loading} from '@scm/components';

const fetchRoadmapVotes = (id: string) =>
  new RoadmapApi(
    new Configuration({
      basePath: roadmapApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).readVotesForRoadmap({id});

const VotesModal = () => {
  const {handleClose, chosenRoadmapId} = useContext(RoadmapContext);
  const [votes, setVotes] = useState<RoadmapVotesResponse[]>();
  const [isLoading, setIsLoading] = useState(true);

  const {formatMessage} = useIntl();

  useEffect(() => {
    fetchRoadmapVotes(chosenRoadmapId)
      .then(response => setVotes(response))
      .catch(() =>
        openToast(
          formatMessage({id: 'roadmap.model.votes.error.title'}),
          formatMessage({id: 'roadmap.model.votes.error.description'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        )
      )
      .finally(() => setIsLoading(false));
  }, []);

  const votedIcon = (voteFor?: Vote) => {
    const IconWrapper = styled.div`
      color: ${voteFor === Vote.For ? colors.successGreen : colors.red};
    `;

    return (
      <IconWrapper className="spark-mar-r-1">
        <Icon
          className="spark-icon--fill"
          size={IconSize.DEFAULT}
          name={voteFor === Vote.For ? 'math-add' : 'math-subtract'}
        />
      </IconWrapper>
    );
  };

  return (
    <div>
      {isLoading ? (
        <LoadingContainer>
          <Loading label={formatMessage({id: 'common.data.loading'})} />
        </LoadingContainer>
      ) : !votes?.length ? (
        <FormattedMessage id="roadmap.modal.votes.content.noVotes" />
      ) : (
        votes?.map(vote => (
          <VoteContainer
            key={vote.federationId}
            className="spark-pad-1 spark-mar-.5 spark-flex spark-align-items-center"
          >
            <div>
              {vote.federationId}
              <span className="spark-mar-r-.5 spark-mar-l-.5">
                <FormattedMessage id="roadmap.modal.votes.content.voted" />
              </span>
              <VoteWrapper voteFor={vote.vote} className="spark-bold">
                {vote.vote}
              </VoteWrapper>
              <DateWrapper className="spark-mar-l-.5">
                <span className="spark-mar-r-.5">
                  <FormattedMessage id="roadmap.modal.votes.content.on" />
                </span>
                <FormattedDateParts value={vote.date} year="numeric" month="long" day="numeric">
                  {({2: {value: day}, 0: {value: month}, 4: {value: year}}) => (
                    <span>
                      {day} {month} {year}
                    </span>
                  )}
                </FormattedDateParts>
              </DateWrapper>
            </div>
            {votedIcon(vote.vote)}
          </VoteContainer>
        ))
      )}
      <nav className="spark-btn-group spark-mar-t-2">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
          <FormattedMessage id="common.button.cancel" />
        </Button>
      </nav>
    </div>
  );
};

const VoteContainer = styled.div`
  border: 2px solid ${colors.grey150};
  background: ${colors.grey50};
  justify-content: space-between;

  &&:hover {
    background: ${colors.grey100};
  }
`;

const VoteWrapper = styled.span<{voteFor?: Vote}>`
  color: ${props => (props.voteFor === Vote.For ? colors.successGreen : colors.red)};
  text-transform: uppercase;
`;

const DateWrapper = styled.span`
  color: ${colors.grey500};
`;

export default VotesModal;
