import {isSabreAdminRole, isSecondLevelSupportRole} from '@scm/authentication/utils/authentication';
import {FormattedMessage, useIntl} from 'react-intl';
import {Message, SelectInput, TextInput, Toggle, ToggleGroup} from '@sabre/spark-react-core';
import {isPaidName, provisioningTypeName, typeName} from '../../../storefrontData/interfaces/AppInfoDataValues';
import {
  CcrzEProductCSObjectProvisioningTypeCEnum,
  CcrzEProductCSObjectRedAppTypeCEnum,
  CcrzEProductCSObjectSabrePricingTypeCEnum,
} from '../../../../generated/product';
import {EnumType} from 'typescript';
import {isAdminRole} from '@scm/components/utils/common';
import React from 'react';
import {Label, ToggleWrapper} from './RedAppDetails';
import styled from 'styled-components';
import {Confirmation} from '../../../storefrontData/productDetailsUtils';
import {Separator} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';

const PrimaryConfiguration = ({
  isPricingTypeDisabled,
  isProvisioningTypeDisabled,
  isPaid,
  appTypeName,
  provisioningType,
  versionName,
  changeHandler,
  changeVersionNameHandler,
}: {
  isPricingTypeDisabled: boolean;
  isProvisioningTypeDisabled: boolean;
  isPaid?: CcrzEProductCSObjectSabrePricingTypeCEnum | Confirmation;
  appTypeName: string;
  provisioningType: string;
  versionName: string;
  changeHandler: (fieldName: string, newValue: string) => void;
  changeVersionNameHandler: (value: string) => void;
}) => {
  const {formatMessage} = useIntl();

  const createSelectOptions = (EnumType: EnumType): Array<{label: string; value: string}> =>
    Object.values(EnumType).map(provisioningTypeValue => ({
      label: provisioningTypeValue,
      value: provisioningTypeValue,
    }));

  return (
    <>
      {(isSabreAdminRole() || isSecondLevelSupportRole()) && (
        <>
          <Separator className="spark-mar-l-2 spark-mar-b-1 spark-pad-l-0 spark-mar-t-2 spark-mar-r-0 spark-pad-r-0 spark-bold spark-large col-sm-10">
            <FormattedMessage id="redAppDetails.separator.primaryConfiguration" />
          </Separator>
          <Message
            content=""
            role={MessageRole.ALERT}
            status={MessageStatus.WARNING}
            title={formatMessage({id: 'redAppDetails.message.advancedMaintenance'})}
            type={MessageType.CONTAINER}
            className="spark-mar-l-2 spark-mar-b-2 spark-pad-r-2"
          />
          <div className="spark-mar-l-2 row">
            <Label className="spark-bold spark-align-items-center spark-flex">
              <FormattedMessage id="redAppDetails.certCmVersion" />
            </Label>
            <VersionNameInput className="col-lg-7">
              <p className="spark-small spark-mar-b-0 spark-flex">
                <FormattedMessage id="redAppDetails.certCmVersion.description" />
              </p>
              <TextInput
                name="versionName"
                label={formatMessage({id: 'tabs.testers.version.label'})}
                value={versionName}
                onChange={(_, value) => changeVersionNameHandler(value)}
              />
            </VersionNameInput>
          </div>
        </>
      )}
      <div className="spark-mar-l-2 row">
        <Label className="spark-bold spark-align-items-center spark-flex">
          <FormattedMessage id="redAppDetails.productType" />
        </Label>
        <SelectInput
          name={typeName}
          label={formatMessage({id: 'redAppDetails.productType.label'})}
          onChange={(_, productValue) => changeHandler(typeName, productValue)}
          value={appTypeName}
          options={createSelectOptions(CcrzEProductCSObjectRedAppTypeCEnum as unknown as EnumType)}
          className="col-lg-7"
          disabled={!isAdminRole}
        />
      </div>
      <div className="spark-mar-l-2 row">
        <Label className="spark-bold spark-align-items-center spark-flex">
          <FormattedMessage id="redAppDetails.provisioningType" />
        </Label>
        <SelectInput
          name={provisioningTypeName}
          label={formatMessage({id: 'redAppDetails.provisioningType.label'})}
          onChange={(_, provisioningValue) => changeHandler(provisioningTypeName, provisioningValue)}
          value={provisioningType}
          options={createSelectOptions(CcrzEProductCSObjectProvisioningTypeCEnum as unknown as EnumType)}
          className="col-lg-7"
          disabled={!isAdminRole || isProvisioningTypeDisabled}
        />
      </div>
      <div className="spark-mar-l-2 row">
        <Label className="spark-bold spark-align-items-center spark-flex">
          <FormattedMessage id="redAppDetails.pricingType" />
        </Label>
        <ToggleWrapper>
          <ToggleGroup
            name={isPaidName}
            onChange={(_, toggleValue) => changeHandler(isPaidName, toggleValue)}
            value={isPaid}
            condensed
          >
            <Toggle
              id={`${isPaidName}First`}
              label={CcrzEProductCSObjectSabrePricingTypeCEnum.Paid}
              value={CcrzEProductCSObjectSabrePricingTypeCEnum.Paid}
              ariaLabel={formatMessage(
                {id: 'accessibility.confirmation.checkbox'},
                {value: CcrzEProductCSObjectSabrePricingTypeCEnum.Paid}
              )}
              disabled={!isAdminRole || isPricingTypeDisabled}
            />
            <Toggle
              id={`${isPaidName}Second`}
              label={CcrzEProductCSObjectSabrePricingTypeCEnum.Free}
              value={CcrzEProductCSObjectSabrePricingTypeCEnum.Free}
              ariaLabel={formatMessage(
                {id: 'accessibility.confirmation.checkbox'},
                {value: CcrzEProductCSObjectSabrePricingTypeCEnum.Free}
              )}
              disabled={!isAdminRole || isPricingTypeDisabled}
            />
          </ToggleGroup>
        </ToggleWrapper>
      </div>
    </>
  );
};

export default PrimaryConfiguration;

const VersionNameInput = styled.div`
  margin-bottom: 1rem;
  padding: 0 !important;
`;
