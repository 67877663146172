import React, {useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {HeaderText} from '@scm/admin-centre/src/components/App';
import RoadmapItem from './roadmapItems/RoadmapItem';
import {Loading} from '@scm/components';
import {LoadingContainer} from '@scm/authentication/components/Login';
import colors from '@scm/components/assets/colors';
import RoadmapTab from './roadmapTabs/RoadmapTab';
import RoadmapSelectableTab from './roadmapTabs/RoadmapSelectableTab';
import {FloatingActionButton, Modal} from '@sabre/spark-react-core';
import {RoadmapContext} from '../components/RoadmapProvider';
import AddNewRoadmap from './modals/AddNewRoadmap';
import VotesModal from './modals/VotesModal';
import DeleteRoadmap from './modals/DeleteRoadmap';
import {isSabreAdminRole} from '@scm/authentication/utils/authentication';

export interface RoadmapTabI {
  name: string;
  isActive: boolean;
}

export const selectOptions: RoadmapTabI[] = [
  {
    name: 'roadmap.tab.sortBy.mostVoted',
    isActive: false,
  },
  {name: 'roadmap.tab.sortBy.mostRecent', isActive: true},
];

export enum RoadmapModalType {
  ADD = 'add',
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const Roadmap = () => {
  const {
    chosenRoadmapId,
    roadmaps,
    modalKey,
    isLoading,
    isOpen,
    chosenModal,
    display,
    handleChangeTab,
    handleSortBy,
    handleClose,
    handleOpenAdd,
    activeTab,
  } = useContext(RoadmapContext);

  const {formatMessage} = useIntl();

  const tabs = ['All', 'Needs Feedback', 'In Progress', 'Completed'];

  const findRoadmapById = (id: string) => roadmaps.find(roadmap => roadmap.id === id);

  const roadmapModals = {
    add: <AddNewRoadmap />,
    view: <VotesModal roadmapId={chosenRoadmapId ?? ''} />,
    edit: <AddNewRoadmap isEdit />,
    delete: <DeleteRoadmap roadmapSubject={findRoadmapById(chosenRoadmapId)?.subject ?? ''} />,
  };

  return (
    <div className="spark-pad-2">
      <Modal
        key={modalKey}
        onClose={handleClose}
        open={isOpen}
        title={formatMessage({id: `roadmap.modal.${chosenModal}.title`})}
        titleHeadingLevel={2}
      >
        {roadmapModals[chosenModal ?? 'add']}
      </Modal>
      {!isOpen && isSabreAdminRole() && (
        <FloatingButtonContainer>
          <FloatingActionButton
            icon="math-add"
            label="Add New"
            onClick={handleOpenAdd}
            data-testid="addFeatureButton"
          />
        </FloatingButtonContainer>
      )}
      <HeaderText>
        <FormattedMessage id="roadmap.title" />
      </HeaderText>
      {isLoading ? (
        <LoadingContainer>
          <Loading label={formatMessage({id: 'common.data.loading'})} />
        </LoadingContainer>
      ) : (
        <RoadmapWrapper className="col-md-12 col-lg-9 spark-pad-2 spark-mar-t-2">
          <RoadmapNavigationWrapper className="spark-flex spark-pad-1 spark-mar-b-1">
            <div className="spark-flex">
              {tabs.map(tab => (
                <RoadmapTab
                  tab={{
                    name: tab,
                    isActive: activeTab.name === tab,
                  }}
                  handler={handleChangeTab}
                />
              ))}
            </div>
            <div className="spark-flex">
              <RoadmapSelectableTab
                handler={handleSortBy}
                name={selectOptions.filter(option => option.isActive)[0].name}
              />
            </div>
          </RoadmapNavigationWrapper>

          {display()?.map(roadmap => (
            <RoadmapItem roadmap={roadmap} key={roadmap.id} />
          ))}
        </RoadmapWrapper>
      )}
    </div>
  );
};

const RoadmapWrapper = styled.div`
  margin: auto;

  div {
    background: ${colors.white};
    margin-bottom: 2px;
    border-radius: 4px;
  }
`;

const RoadmapNavigationWrapper = styled.div`
  justify-content: space-between;

  div:first-child {
    flex-direction: row;
  }
`;

const FloatingButtonContainer = styled.span`
  & .spark-fab {
    margin-bottom: 60px !important;
  }
`;
