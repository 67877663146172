import {FormattedMessage} from 'react-intl';
import React from 'react';
import {Toggle, ToggleGroup} from '@sabre/spark-react-core';
import {UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {FileSource} from '@scm/proposal/pages/proposalForm/ProposalValues';
import PfKeysFileUpload from './PfKeysFileUpload';
import {fileSourceName, PfKeysValues} from '../../provider/pfKeysValues';

const PfKeysFileSource = ({
  id,
  watch,
  setValue,
}: {
  id: string;
  watch: UseFormWatch<PfKeysValues>;
  setValue: UseFormSetValue<PfKeysValues> | (() => void);
}) => {
  const removeWhiteSpaces = (value: string) => value.replace(/\s/g, '');

  const fileSourceDescriptionId = `definition.pfKeys.fileSource.${removeWhiteSpaces(
    watch(fileSourceName)
  )}.description`;

  return (
    <>
      <p className="spark-mar-t-2">
        <FormattedMessage id={id} values={{star: (word: string) => <span className="spark-danger">{word}</span>}} />
      </p>
      <ToggleGroup
        name={fileSourceName}
        onChange={(_, val) =>
          setValue(fileSourceName, FileSource[val as keyof typeof FileSource], {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: true,
          })
        }
        value={watch(fileSourceName)}
        condensed
      >
        {Object.values(FileSource).map(value => (
          <Toggle key={value} label={value} value={value} checked={value === FileSource['Sabre Red Server']} />
        ))}
      </ToggleGroup>
      <p className="spark-mar-t-2 spark-mar-b-2 spark-mar-r-2">
        <FormattedMessage id={fileSourceDescriptionId} values={{em: (word: string) => <em>{word}</em>}} />
      </p>
      {watch(fileSourceName) === FileSource['Local'] && <PfKeysFileUpload watch={watch} setValue={setValue} />}
    </>
  );
};

export default PfKeysFileSource;
