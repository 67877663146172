import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import parse from 'html-react-parser';
import colors from '@scm/components/assets/colors';

const BenefitsRow = ({iconId, textId}: {iconId: string; textId: string}) => {
  const {formatMessage} = useIntl();
  return (
    <Container>
      <Icon className={formatMessage({id: iconId})} />
      <Text>{parse(formatMessage({id: textId}))}</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: 2rem 0;
`;

const Icon = styled.i.attrs(({className}) => ({className: `${className}`}))`
  font-size: 4rem;
  padding: 2rem 4rem 2rem 2rem;
  color: ${colors.grey700};
`;

const Text = styled.span.attrs(() => ({
  className: 'spark-delta',
}))`
  color: ${colors.grey700};
`;

export default BenefitsRow;
