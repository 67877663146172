import React, {ReactElement, useContext, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContext} from '../../../../ProposalForm';
import {Checkbox, CheckboxGroup, RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {Controller} from 'react-hook-form';
import {
  Confirmation,
  dataAdminName,
  dataProtectionName,
  financialInformationName,
  panRenderedName,
  pciAdditionalDetailsName,
  pciCertifiedName,
  pciDataName,
  personalInformationName,
  retentionPeriodName,
  thirdPartyNetworkName,
} from '../../ServiceAndSecurityValues';
import {ExternalLink, Star} from '@scm/components';
import {TextArea} from '../../../definitionPage/definitionComponents/Description';
import ExternalUrls from '@scm/components/utils/externalUrls';
import {createNestedObjectName} from '../servicesComponents/Services';
import {serviceAndSecurityValuesName} from '../../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import Legend from '@scm/components/accessibility/Legend';

enum PciDataValues {
  'Primary Account Number' = 'Primary Account Number',
  'Cardholder Name' = 'Cardholder Name',
  'Service Code or Expiration Date' = 'Service Code or Expiration Date',
  'Magnetic Stripe Data or CVV/CVC Data' = 'Magnetic Stripe Data or CVV/CVC Data',
  'PINs/PIN Blocks or Any Track-1 or Track-2 Data' = 'PINs/PIN Blocks or Any Track-1 or Track-2 Data',
}

const FinancialInformation = () => {
  const {formatMessage} = useIntl();
  const {control, watch, errors, setValue} = useContext(ProposalContext);
  const descriptionMaxLength = 250;
  const pciCertifiedId = 'pciCertified';
  const financialInformationValue = watch(createNestedObjectName(financialInformationName));

  useEffect(() => {
    if (!financialInformationValue) {
      setValue(createNestedObjectName(pciCertifiedName), '', {shouldValidate: true});
    }
  }, [financialInformationValue, setValue]);

  return (
    <>
      <Controller
        name={createNestedObjectName(financialInformationName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (!event.target.checked) {
                setValue(createNestedObjectName(pciAdditionalDetailsName), '', {shouldValidate: true});
                setValue(createNestedObjectName(panRenderedName), '', {shouldValidate: true});
                setValue(createNestedObjectName(retentionPeriodName), '', {shouldValidate: true});
                setValue(createNestedObjectName(dataAdminName), '', {shouldValidate: true});
                setValue(createNestedObjectName(pciCertifiedName), '', {shouldValidate: true});
                setValue(createNestedObjectName(pciDataName), [], {shouldValidate: true});
                if (
                  !watch(createNestedObjectName(financialInformationName)) &&
                  !watch(createNestedObjectName(personalInformationName)) &&
                  !watch(createNestedObjectName(thirdPartyNetworkName))
                ) {
                  setValue(createNestedObjectName(dataProtectionName), '', {shouldValidate: true});
                }
              } else {
                setValue(createNestedObjectName(pciCertifiedName), Confirmation.No, {shouldValidate: true});
              }
            }}
            checked={value}
            label={formatMessage({id: 'serviceAndSecurity.financialInformation.label'})}
            className="spark-mar-b-0"
          />
        )}
      />
      <p className="spark-mar-l-2 spark-pad-l-1 spark-small">
        <FormattedMessage
          id="serviceAndSecurity.financialInformation.additionalText"
          values={{link: word => <ExternalLink to={ExternalUrls.PciDataSecurityStandards}>{word}</ExternalLink>}}
        />
      </p>
      {financialInformationValue && (
        <div className="spark-pad-l-2">
          <p className="spark-mar-b-1 spark-mar-r-2">
            <FormattedMessage id="serviceAndSecurity.pciData.label" />
            <Star hasColon />
          </p>
          <Controller
            name={createNestedObjectName(pciDataName)}
            control={control}
            render={({field: {onChange, name, value}}): ReactElement => (
              <CheckboxGroup
                name={name}
                value={value}
                onChange={(_, activeItems): void => onChange(activeItems)}
                status={errors[serviceAndSecurityValuesName]?.[pciDataName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[pciDataName] &&
                  errors[serviceAndSecurityValuesName]?.[pciDataName]?.message
                }
              >
                <Legend text={formatMessage({id: 'serviceAndSecurity.pciData.label'})} />
                {Object.values(PciDataValues).map(technology => (
                  <Checkbox key={technology} label={technology} value={technology} />
                ))}
              </CheckboxGroup>
            )}
          />
          <p className="spark-mar-b-1 spark-mar-r-2 spark-mar-t-1" id={pciCertifiedId}>
            <FormattedMessage id="serviceAndSecurity.pciCertified.label" />
            <Star />
          </p>
          <Controller
            name={createNestedObjectName(pciCertifiedName)}
            control={control}
            render={({field: {onChange, name, value}}): ReactElement => (
              <RadioButtonGroup name={name} value={value} onChange={onChange} aria-labelledby={pciCertifiedId}>
                <Legend text={formatMessage({id: 'serviceAndSecurity.pciCertified.label'})} />
                {Object.values(Confirmation).map(value => (
                  <RadioButton
                    key={value}
                    label={value}
                    value={value}
                    aria-label={formatMessage({id: 'serviceAndSecurity.confirmationButtons.label'}, {value})}
                  />
                ))}
              </RadioButtonGroup>
            )}
          />
          <Controller
            name={createNestedObjectName(pciAdditionalDetailsName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.pciAdditionalDetails.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                status={
                  errors[serviceAndSecurityValuesName]?.[pciAdditionalDetailsName] ? MessageStatus.ERROR : undefined
                }
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[pciAdditionalDetailsName] &&
                  errors[serviceAndSecurityValuesName]?.[pciAdditionalDetailsName]?.message
                }
              />
            )}
          />
          <Controller
            name={createNestedObjectName(panRenderedName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.panRendered.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                status={errors[serviceAndSecurityValuesName]?.[panRenderedName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[panRenderedName] &&
                  errors[serviceAndSecurityValuesName]?.[panRenderedName]?.message
                }
              />
            )}
          />
          <p className="spark-small spark-mar-b-1">
            <FormattedMessage id="serviceAndSecurity.panRendered.additionalText" />
          </p>
          <Controller
            name={createNestedObjectName(retentionPeriodName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.retentionPeriod.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                status={errors[serviceAndSecurityValuesName]?.[retentionPeriodName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[retentionPeriodName] &&
                  errors[serviceAndSecurityValuesName]?.[retentionPeriodName]?.message
                }
              />
            )}
          />
          <Controller
            name={createNestedObjectName(dataAdminName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.dataAdmin.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                status={errors[serviceAndSecurityValuesName]?.[dataAdminName] ? MessageStatus.ERROR : undefined}
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[dataAdminName] &&
                  errors[serviceAndSecurityValuesName]?.[dataAdminName]?.message
                }
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default FinancialInformation;
