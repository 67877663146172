/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Gds
 */
export interface Gds {
    /**
     * 
     * @type {string}
     * @memberof Gds
     */
    howIsForwarded?: string;
    /**
     * 
     * @type {string}
     * @memberof Gds
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof Gds
     */
    numberOfTransaction?: string;
    /**
     * 
     * @type {string}
     * @memberof Gds
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof Gds
     */
    connectionToGdsComment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Gds
     */
    relyOnFreeTextRemarksInPnr?: boolean;
}

/**
 * Check if a given object implements the Gds interface.
 */
export function instanceOfGds(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GdsFromJSON(json: any): Gds {
    return GdsFromJSONTyped(json, false);
}

export function GdsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'howIsForwarded': !exists(json, 'howIsForwarded') ? undefined : json['howIsForwarded'],
        'protocol': !exists(json, 'protocol') ? undefined : json['protocol'],
        'numberOfTransaction': !exists(json, 'numberOfTransaction') ? undefined : json['numberOfTransaction'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'connectionToGdsComment': !exists(json, 'connectionToGdsComment') ? undefined : json['connectionToGdsComment'],
        'relyOnFreeTextRemarksInPnr': !exists(json, 'relyOnFreeTextRemarksInPnr') ? undefined : json['relyOnFreeTextRemarksInPnr'],
    };
}

export function GdsToJSON(value?: Gds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'howIsForwarded': value.howIsForwarded,
        'protocol': value.protocol,
        'numberOfTransaction': value.numberOfTransaction,
        'comment': value.comment,
        'connectionToGdsComment': value.connectionToGdsComment,
        'relyOnFreeTextRemarksInPnr': value.relyOnFreeTextRemarksInPnr,
    };
}

