import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import limitCharacters from 'utils/limitCharacters';
import Logo from 'components/Logo';
import NewPartnerBadge from 'components/styledComponents/NewPartnerBadge';
import {Link} from 'react-router-dom';
import {GetPartnerDetailsDocument, GetPartnerDetailsQuery, GetPartnerDetailsQueryVariables} from 'generated/graphql';
import {useLazyQuery} from '@apollo/client';
import {useMediaQuery} from 'react-responsive';
import {debounce} from 'debounce';
import PartnerTierLabel from 'components/PartnerTierLabel';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const PartnerTile = ({
  logoUrl,
  title,
  tier,
  shortDescription,
  approvalDate,
  partnerLink,
  isFullPageWidth,
}: {
  logoUrl: string;
  title: string;
  tier: string | null | undefined;
  shortDescription: string;
  approvalDate: string;
  partnerLink: string;
  isFullPageWidth: boolean;
}) => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const [getPartnerDetails, {called}] = useLazyQuery<GetPartnerDetailsQuery, GetPartnerDetailsQueryVariables>(
    GetPartnerDetailsDocument
  );
  return (
    <StyledPartnerTile
      $isFullPageWidth={isFullPageWidth}
      to={partnerLink}
      aria-label={partnerLink}
      onMouseOver={debounce(() => !called && getPartnerDetails({variables: {name: title}}), 100)}
      $mobile={isMobile}
    >
      <NewPartnerBadge approvalDate={approvalDate} />
      <Logo logo={logoUrl} name={title} />
      <FullWidthDiv>
        <Title mobile={isMobile}>{title}</Title>
      </FullWidthDiv>
      <FullWidthDiv>
        {tier && <PartnerTierLabel className="spark-mar-b-1" tier={tier} />}
        <Description mobile={isMobile}>{parse(limitCharacters(shortDescription, 130) || '')}</Description>
      </FullWidthDiv>
    </StyledPartnerTile>
  );
};

const StyledPartnerTile = styled(Link).attrs(() => ({
  className: 'spark-panel spark-panel--card spark-flex spark-pad-2',
}))<{
  $isFullPageWidth: boolean;
  $mobile: boolean;
}>`
  flex-direction: column;
  align-items: center;

  border-width: 8px 1.5px 1.5px 1.5px !important;
  border-style: solid ${colors.grey200};
  border-radius: 0.25rem;
  border-top-color: ${colors.grey700};

  position: relative;
  transition: top ease-in 0.1s;
  top: 0;

  margin: ${props => (props.$mobile ? '1rem 0' : '2rem')};

  :hover,
  :focus {
    cursor: pointer;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
    transition: top ease-out 0.1s;
    top: -0.7rem;
  }

  ${props => (props.$mobile ? '' : 'flex: 0.3;')};
  ${props => (props.$isFullPageWidth ? `min-width: 20%` : `min-width: 25%`)}
`;

const FullWidthDiv = styled.div`
  width: 100%;
`;

const Title = styled.div.attrs(() => ({
  className: 'spark-mar-b-1 spark-bold',
}))<{mobile?: boolean}>`
  color: ${colors.grey700};
  font-size: 1.58333rem;
  ${props => (props.mobile ? 'text-align: center;' : '')};
`;

const Description = styled.div<{mobile?: boolean}>`
  color: ${colors.grey900};
  font-size: 1.58333rem;
  font-weight: 400;
  ${props => (props.mobile ? 'text-align: justify;' : '')};
`;

export default PartnerTile;
