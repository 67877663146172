import React from 'react';
import {FileUpload} from '@scm/components';
import {propertiesType, resourcesFiles, scribeScriptsType} from '@scm/components/utils/common';
import {Icon, RadioButton, RadioButtonGroup} from '@sabre/spark-react-core';
import {FormattedMessage, useIntl} from 'react-intl';
import {UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {ScribeScriptsValues} from '../../provider/ScribeScriptsValues';
import {Confirmation} from '@scm/proposal/pages/proposalForm/steps/serviceAndSecurity/ServiceAndSecurityValues';
import {FilesList} from '@scm/pf-keys/definitionPage/definitionComponents/PfKeysFileUpload';

const fileTypeMapper = {
  scribeScripts: scribeScriptsType,
  hiddenScribeScripts: scribeScriptsType,
  resources: resourcesFiles,
  activeListening: propertiesType,
};

const ScribeScriptUpload = ({
  id,
  filesNames,
  fileType,
  radioButton,
  name,
  watch,
  setValue,
}: {
  id: string;
  filesNames: keyof ScribeScriptsValues;
  fileType: string;
  radioButton?: boolean;
  name?: string;
  watch: UseFormWatch<ScribeScriptsValues>;
  setValue: UseFormSetValue<ScribeScriptsValues>;
}) => {
  const {formatMessage} = useIntl();
  const maxFiles = 100;
  const activeListeningMaxFiles = 1;
  const confirmationFieldsetLabel = 'confirmation';
  const activeListeningFile = 'activeListening';
  const setValueOptions = {
    shouldDirty: true,
    shouldTouch: true,
    shouldValidate: true,
  };
  const deleteFileHandler = (name?: string) => {
    if (name) {
      setValue(
        filesNames,
        (watch(filesNames) as Array<File>).filter((file: File) => file.name !== name),
        setValueOptions
      );
    } else {
      setValue(filesNames, [], setValueOptions);
    }
  };

  return (
    <>
      <div className="row spark-mar-t-2 spark-mar-b-0">
        <p className="spark-mar-l-1">
          <FormattedMessage
            id={id}
            values={{
              strong: (word: string) => <strong>{word}</strong>,
              star: (word: string) => <span className="spark-danger">{word}</span>,
            }}
          />
        </p>
        {radioButton && (
          <RadioButtonGroup
            className="row"
            name={name as string}
            value={watch(name as keyof ScribeScriptsValues) as string}
            onChange={(_, val) => {
              setValue(name as keyof ScribeScriptsValues, val, setValueOptions);
              if (val === Confirmation.No) {
                deleteFileHandler();
              }
            }}
            aria-label={confirmationFieldsetLabel}
          >
            {Object.values(Confirmation).map(choice => (
              <RadioButton
                key={choice}
                label={choice}
                value={choice}
                aria-label="Question response - Yes/No"
                className="spark-mar-l-2"
              />
            ))}
          </RadioButtonGroup>
        )}
      </div>
      {(!radioButton || (watch(name as keyof ScribeScriptsValues) as unknown as string) === Confirmation['Yes']) && (
        <div className="spark-mar-r-1 spark-mar-t-0 spark-mar-b-2">
          <p className="spark-mar-t-0">
            <FormattedMessage
              id={`definition.${fileType}.fileUpload.description`}
              values={{em: (word: string) => <em>{word}</em>}}
            />
          </p>
          {fileType === activeListeningFile && (
            <p className="spark-mar-t-0">
              <FormattedMessage
                id={`definition.${fileType}.fileUpload.description.secondParagraph`}
                values={{em: (word: string) => <em>{word}</em>}}
              />
            </p>
          )}
          <FilesList className="spark-list--no-bullets spark-mar-l-0">
            {(watch(filesNames) as Array<File>).map((file: File) => (
              <li key={file.name} onClick={() => deleteFileHandler(file.name)}>
                {file.name}
                <Icon name="trash" />
              </li>
            ))}
          </FilesList>
          <FileUpload
            filesNames={filesNames}
            setFiles={setValue as () => void}
            watch={watch}
            filesType={fileTypeMapper[fileType as keyof typeof fileTypeMapper]}
            maxFiles={fileType !== 'activeListening' ? maxFiles : activeListeningMaxFiles}
            additionalText={formatMessage({
              id: `definition.${fileType}.fileUpload.additionalText`,
            })}
          />
        </div>
      )}
    </>
  );
};

export default ScribeScriptUpload;
