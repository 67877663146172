import bundleTesting from './bundleTesting';
import support from './support';

const productDetails = {
  ...bundleTesting,
  ...support,
  'productDetails.marketplaceLink': 'View On Marketplace',
  'productDetails.statusName': 'Product Status',
  'productDetails.appId': 'Red App ID',
  'productDetails.sku': 'SKU',
  'productDetails.sellerName': 'Red App Provider',
  'productDetails.provider': 'Provider',
  'productDetails.submitBundleButton': 'Submit a bundle',
  'productDetails.createNewVersion': 'Create a new Version',
  'productDetails.publishBundleButton': 'Publish',
  'productDetails.cancelCertificationButton': 'Cancel Certification',
  'productDetails.name.info':
    'You can change your Red App name before the first bundle is published. Because your proposal was accepted, to change the name please contact {email}.',
  'productDetails.name.standard-info': 'If you want to change product name, please contact scm-management@sabre.com',
  'productDetails.icon.changeProductIcon': 'Change Product Icon',
  'productDetails.icon.uploadProductIcon': 'Upload Product Icon',
  'productDetails.icon.info':
    '<line>If possible, the logo <strong>should be the Product logo</strong>, not a Company/Developer logo</line> <line>The logo should be <strong>square. Minimal size is 50x50px</strong></line> <line>The <strong>maximum file size is 20 kB</strong></line> <line>Acceptable formats are <strong>.gif .jpg .jpeg .png .svg .webp</strong></line> <line>Logo should have <strong>good quality</strong> (clear, readable, not pixelated)</line>',
  'productDetails.icon.warning.title': 'Icon upload failed',
  'productDetails.icon.warning.details': 'File can not be larger than 20kB',
  'productDetails.icon.changeName.modal.title': 'Change Product Name',
  'productDetails.icon.tooltip.title': 'Product name',
  'productDetails.icon.tooltip.description':
    "Your product name should be descriptive enough for customers to figure out what the app is/does. The name of the product should be between 3 and 50 characters, allowed special characters are: ().,:'-@",
  'productDetails.icon.change.error': 'Product name cannot be change, please try again or contact {email}.',
  'productDetails.icon.change.errorTitle': 'Update failed.',
  'productDetails.icon.change.success': 'Product name has been changed correctly.',
  'productDetails.icon.change.successTitle': 'Saved.',
  'productDetails.icon.change.label': 'Product Name',
  'productDetails.icon.alert1': `Please be aware that any product change must be reported to and approved by the Taxonomy team. If you have not already done so, <strong>BEFORE</strong> you change the name report it and get confirmation from <a href="mailto:kiera.miller@sabre.com">kiera.miller@sabre.com</a>.`,
  'productDetails.icon.alert2':
    'If you change the name without confirmation then be aware that we may change it to the previous name if it does not comply with Taxonomy.',
  'productDetails.category.label': 'Select Category',
  'productDetails.compatibility.label': 'Compatibility',
  'productDetails.orderInformation.description': 'Is seller confirmation required to order this red app?',
  'productDetails.orderInformation.required.label':
    'You will need to approve all orders in the My Sales section in Sabre Central Marketplace before the Red App is provisioned.',
  'productDetails.content.storefront.label': 'Storefront Description',
  'productDetails.content.storefront.additionalText':
    'Please provide a description of your product that will be available on your storefront in the Marketplace.',
  'productDetails.content.shortDescription.label': 'Storefront Short Description',
  'productDetails.content.shortDescription.additionalText':
    'Please describe your product in a few words. The short description is displayed on the product tiles, which can be seen, for example, when browsing products in a category.',
  'productDetails.content.tabs.showOnlyToLoggedInUsers.label': 'Show only to logged in users.',
  'productDetails.content.keyBenefits.label': 'Key Benefits',
  'productDetails.content.keyBenefits.additionalText': 'What is the value your product brings to its users?',
  'productDetails.content.features.label': 'Features',
  'productDetails.content.features.additionalText': 'Please share what features are included in your product.',
  'productDetails.content.documents.label': 'Documents',
  'productDetails.content.documents.additionalText': 'Please share URLs to additional documents for your product.',
  'productDetails.content.support.label': 'Support',
  'productDetails.content.support.additionalText':
    'Please share information on the support service available for your product.',
  'productDetails.content.technicalRequirements.label': 'Technical Requirements',
  'productDetails.content.technicalRequirements.additionalText':
    'What are the technical requirements for your product?',
  'productDetails.content.additionalInformation.label': 'Additional Information',
  'productDetails.content.additionalInformation.additionalText':
    'If you wish, please share additional information about your product.',
  'productDetails.content.terms&conditions.label': 'Product Terms And Conditions',
  'productDetails.content.terms&conditions.additionalText':
    'Once the Terms and Conditions are published and accepted by customers, be aware that any new change will require those customers to reaccept the Terms and Conditions.',
  'productDetails.content.ytLink.label': 'YouTube Video Link',
  'productDetails.content.youtube.error':
    'Please make sure you provide correct YouTube link (for example https://youtu.be/abcd1233 or https://www.youtube.com/watch?v=abcd1234)',
  'productDetails.content.screenshots.label': 'Product Screenshots/Video',
  'productDetails.content.screenshots.fileAdditionalText':
    'Upload up to 10 *.gif, *.jpg, *.jpeg, *.png, *.svg or *.webp files, each up to 5MB',
  'productDetails.content.screenshots.additionalText':
    'We recommend that each product has at least one screenshot or YouTube video uploaded. Please be aware that all screenshots over 500kB and 1680x1050px will be compressed to this size.',
  'productDetails.content.pdf.label': 'Upload a Quick Reference Guide',
  'productDetails.media.additionalFiles.label': 'Upload Additional Guides',
  'productDetails.media.additionalFiles.additionalText':
    'Here you can upload additional guides for your product. It can be any manual in .pdf format that you want to provide to the user.',
  'productDetails.media.additionalFiles.table.header.guideName': 'Guide Name',
  'productDetails.media.additionalFiles.table.header.uploadDate': 'Upload Date',
  'productDetails.media.additionalFiles.table.header.lastModified': 'Last Modified',
  'productDetails.content.pdf.additionalText':
    'You can only add one main Quick Reference Guide. If you want to add a new one, delete the current one first.',
  'productDetails.content.patchRelease.label': 'Patch Release Notes (newest published bundle)',
  'productDetails.content.respondMessage.label': 'Message to each buyer',
  'productDetails.content.respondMessage.description':
    'If you want to send information automatically to each buyer of your Red App, type the generic text above. For example, you may want to send information  or links about how to use your Red App. After we entitle the users in Sabre Red 360, we will send the buyer your text above in a message on your behalf.',
  'productDetails.submitInfo': 'You currently have unsaved changes. Click "Save" to confirm them.',
  'productDetails.submitButton': 'Save',
  'productDetails.submitButton.error':
    'Changes cannot be saved because the required data was not completed correctly. Please review the forms and complete the required items.',
  'productDetails.resetButton': 'Cancel',
  'productDetails.content.fileAdditionalText': 'Upload *.pdf file up to 10MB',
  'productDetails.orderInformation.confirmation.automatically.label': 'Automatically approve all orders',
  'productDetails.orderInformation.confirmation.approval.label': 'Require approval for all orders',
  'productDetails.orderInformation.blockOrdering.title': 'Block Red App ordering on SC code level',
  'productDetails.orderInformation.blockOrdering.label': 'Block SC Code level ordering',
  'productDetails.statusMessage.actionRequired.title': 'Action Required.',
  'productDetails.statusMessage.actionRequired.readyForTestProd':
    'Your Red App version is ready in the Sabre Red 360 PROD environment. After you test it on PROD, click "Proceed" and mark the testing result as either "Passed" or "Failed".',
  'productDetails.statusMessage.actionRequired.provider':
    'Your Red App has been successfully published to the CERT environment. After you test it on CERT, click "Proceed" and mark the testing result as either "Passed" or "Failed".',
  'productDetails.statusMessage.actionRequired.readyForTestCert':
    'Your Red App version is ready in the Sabre Red 360 CERT environment. After you test it on CERT, click "Proceed" and mark the testing result as either "Passed" or "Failed".',
  'productDetails.statusMessage.actionRequired.prodTestPassed':
    'Your Red App version has passed tests in the PROD environment. Please make sure all required fields related to the Red App storefront are completed and click the "Publish" button to publish the new version, or Red App.',
  'productDetails.statusMessage.actionRequired.prodTestPassedMissingItems':
    'Your Red App version has passed tests in the PROD environment. Please make sure all required fields related to the Red App storefront are completed and click the "Publish" button to publish the new version, or Red App. <br /><br /> Remaining content items to be completed before publication:{items}',
  'productDetails.statusMessage.actionRequired.cancelled':
    'Certification of this bundle was manually cancelled. Please apply the necessary changes and submit the new version bundle.',
  'productDetails.statusMessage.actionRequired.rollbackInProgress':
    'Rollback in Progress. The latest version of the Red App is being rolled back, and should be removed from Sabre Red within seconds.',
  'productDetails.statusMessage.actionRequired.rolledBack':
    'Rolled Back. Your latest Red App version has been removed from Sabre Red. If you want to update your Red App, you can prepare and submit a new version.',
  'productDetails.statusMessage.actionOnSabre.certTestPassed':
    "Your Red App version has passed tests in the CERT environment. After positive validation from Sabre (it can take up to 15 working days, usually 1-3 days), you will be able to proceed to test the version on the PROD environment. If you want to cancel the certification and submit a new bundle, please use 'Cancel certification' button below.",
  'productDetails.statusMessage.actionOnSabre.title': "Action on Sabre's side.",
  'productDetails.statusMessage.actionOnSabre.bundleSubmitted':
    'Your Red App bundle is being validated, it can take few minutes. After validation, you will be able to start testing the bundle.',
  'productDetails.statusMessage.actionOnSabre.approvedForProd':
    'Your Red App bundle has passed automatic validation. It will soon be ready for testing on the PROD environment.',
  'productDetails.statusMessage.actionOnSabre.approvedForProd.freeze':
    'Your Red App bundle has passed automatic validation. Due to freeze, new version will be available for testing on the PROD environment at {date}  (UTC-6).',
  'productDetails.statusMessage.redStatus.title': 'Failed.',
  'productDetails.statusMessage.redStatus.title.obsolete': 'Obsolete Product.',
  'productDetails.statusMessage.greenStatus.title': 'Success.',
  'productDetails.statusMessage.redStatus.rejectedForProd':
    'Your Red App version was rejected after manual verification, please contact {email} in case of any questions.',
  'productDetails.statusMessage.actionOnSabre.bundleValidated':
    'Your Red App bundle has passed automatic validation. It will soon be available for testing on the CERT environment.',
  'productDetails.statusMessage.actionOnSabre.bundleValidated.freeze':
    'Your Red App bundle has passed automatic validation. Due to freeze, new version will be available for testing on the CERT environment at {date} (UTC-6).',
  'productDetails.statusMessage.redStatus.testFailed':
    'It was indicated that this bundle did not pass the test. Apply the necessary changes and submit the next version bundle.',
  'productDetails.statusMessage.redStatus.validationFailed':
    'Your Red App bundle has failed automated validation. You can check validation errors in context menu on version level. Please fix them and submit the next version bundle.',
  'productDetails.statusMessage.redStatus.productObsolete':
    'This product is already obsolete. It is no longer available for ordering in the Sabre Central Marketplace.',
  'productDetails.statusMessage.greenStaticStatus.published':
    'Your latest Red App version is published and available to users. If you want to update your Red App, you can prepare and submit a new version.',
  'productDetails.statusMessage.showCertificationProcess.label': 'Click here to see the certification process',
  'productDetails.tab.accessibilityLabel': 'Move to {tabName} tab',
  'productDetails.fetch.errorTitle': 'Something went wrong',
  'productDetails.fetch.error': 'Please contact Sabre support team: {email}',
  'productDetails.fetch.successTitle': 'Data updated.',
  'productDetails.fetch.success': 'Your updates were saved.',
  'productDetails.fetch.notFound.description': 'Product not found',
  'productDetails.fetch.testerListUpdateFailed.description':
    'PROD testers update failed. Please check if you have provided an existing EPR and try again. If you have any questions, please contact {email}.',
  'productDetails.fetch.testerListUpdateFailedDueToNoPccAccess.description':
    'You can assign only testers from the same SC Code as yours. To assign testers from other SC Codes, please contact our support: scm-support@sabre.com.',
  'productDetails.fetch.certTesterListUpdateFailed.description':
    'CERT testers update failed. Please check if you have provided an existing EPR and try again. If you have any questions, please contact {email}.',
  'productDetails.fetch.testerExists.description':
    'Sorry, this tester is already assigned, in case of any problems please contact our support',
  'bundle.error': 'Updates cannot be saved, please try again or contact {email}.',
  'bundle.errorTitle': 'Updates failed.',
  'bundle.success.freeze':
    'Bundle Submitted. Due to the CERT freeze, it will be available for testing after the freeze is finished.',
  'bundle.success': 'Bundle Submitted. It will be validated and ready to test on CERT within a few seconds',
  'bundle.successTitle': 'Saved.',
  'bundle.customError': 'There were issues with the bundle you try to submit:',
  'version.success': 'Version Submitted. It will be validated and ready to test on CERT within a few seconds.',
  'version.successTitle': 'Saved.',
  'version.error': 'Updates cannot be saved, please try again or contact {email}.',
  'version.errorTitle': 'Updates failed.',
  'version.checkIfCanBePublished.error': 'Cannot determine if version is ready to be published.',
  'version.publishVersion.error': 'Version cannot be published.',
  'version.publishVersion.patch.successTitle': 'Saved.',
  'version.publishVersion.new.successTitle': 'Published.',
  'version.publishVersion.new.success': 'Your new Red App has been published in the Sabre Central Marketplace.',
  'version.publishVersion.patch.success': 'Version Published.',
  'version.cancel.successTitle': 'Version Cancelled.',
  'version.cancel.success': 'Your version was successful cancelled.',
  'version.cancel.error': 'There was a problem with cancellation.',
  'scribeScriptsAndPfKeys.version.successTitle': 'Saved.',
  'scribeScriptsAndPfKeys.version.success':
    'Version created. It will be validated and ready to test on PROD within a few minutes.',
  'scribeScriptsAndPfKeys.version.error': 'Updates cannot be saved, please try again or contact {email}.',
  'scribeScriptsAndPfKeys.version.errorTitle': 'Updates failed.',
  //TODO delete if not used
  'productDetails.error': 'Something went wrong, please try again in few minutes.',
  'productDetails.view.error': 'Product not found.',
  'productDetails.view.errorTitle': 'Failed.',
  'productDetails.updates.error': 'Updates cannot be saved, please try again or contact {email}.',
  'productDetails.updates.errorTitle': 'Updates failed.',
  'productDetails.updates.success':
    'Your storefront changes have been saved. You can check the changes by clicking on "(Pre)View on Marketplace".',
  'productDetails.updates.successTitle': 'Saved.',
  'productDetails.changeSimplifiedDescription.error': 'Changing simplified description failed',
  'productDetails.changeSimplifiedDescription.errorTitle': 'Failed',
  'productDetails.changeSimplifiedDescription.success': 'Simplified description changed successfully',
  'productDetails.changeSimplifiedDescription.successTitle': 'Changed',
  'productDetails.changeReleaseNotes.error': 'Changing release notes failed',
  'productDetails.changeReleaseNotes.errorTitle': 'Failed',
  'productDetails.changeReleaseNotes.success': 'Release notes changed successfully',
  'productDetails.changeReleaseNotes.successTitle': 'Changed',
  'productDetails.seller.error': 'Provider account not found.',
  'productDetails.seller.errorTitle': 'Failed',
  'productDetails.certTesters.setNewVersionName.error': 'New CM CERT version name cannot be set',
  'productDetails.certTesters.setNewVersionName.success': 'New CM CERT version name has been set',
  'productDetails.limitations.storefront.description.scCode':
    'Please indicate if there are specific SC Codes that should have the exclusive right to see and order this product in Sabre Central Marketplace.',
  'productDetails.limitations.storefront.description.epr':
    'Please indicate if there are specific EPRs that should have the exclusive right to see and order this product in Sabre Central Marketplace.',
  'productDetails.limitations.storefront.scCodeLimitation': 'SC CODE LIMITATION',
  'productDetails.limitations.storefront.eprLimitation': 'EPR LIMITATION',
  'productDetails.limitations.storefront.epr': 'EPR',
  'productDetails.limitations.storefront.epr.invalid': 'Invalid EPR',
  'productDetails.limitations.storefront.pcc': 'PCC',
  'productDetails.limitations.storefront.pcc.invalid': 'Invalid PCC',
  'productDetails.limitations.storefront.scCode': 'SC CODE',
  'productDetails.limitations.storefront.scCode.invalid': 'Invalid SC Code',
  'productDetails.limitations.storefront.add': '+',
  'productDetails.limitations.storefront.remove': '-',
  'productDetails.limitations.storefront.scCodeLimitation.error':
    'A saving error occurred. Please make sure the given SC Code(s) are not already added (or are added, if they are supposed to be removed) and try again. If the problem persists contact <a href="mailto:scm-support@sabre.com">scm-support@sabre.com</a>.',
  'productDetails.limitations.storefront.eprLimitation.error':
    'A saving error occurred. Please make sure the given EPR(s) are not already added (or are added, if they are supposed to be removed) and try again. If the problem persists contact <a href="mailto:scm-support@sabre.com">scm-support@sabre.com</a>.',
  'productDetails.limitations.storefront.eprLimitation.successRemove': 'EPR(s) have been successfully removed',
  'productDetails.limitations.storefront.scCodeLimitation.successRemove': 'SC Code(s) have been successfully removed.',
  'productDetails.limitations.storefront.eprLimitation.successAdd': 'EPR(s) have been successfully saved.',
  'productDetails.limitations.storefront.scCodeLimitation.successAdd': 'SC Code(s) have been successfully saved.',
  'productDetails.regionalization.regions.header': 'Available in Regions',
  'productDetails.regionalization.countries.header': 'Available in Countries',
  'productDetails.regionalization.blockedCountries.header': 'Blocked in Countries',
  'productDetails.regionalization.countries.search': 'Search countries',
  'productDetails.regionalization.output.header': 'Product availability in countries',
  'productDetails.regionalization.info.header': 'Regionalization',
  'productDetails.regionalization.info.content':
    'Define your product\'s availability by countries and (or) regions. Select "Global" for worldwide sales, or choose specific countries and (or) regions where ordering should be allowed or restricted.',
};

export default productDetails;
