import React, {useContext, useEffect} from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import useGetProductC from '../../hooks/useGetProductC';
import {ProductIconContext} from '../../forms/ProductIcon';

const Icon = () => {
  const {icon} = useContext(ProductIconContext);
  const productIconValue = icon ?? '';

  return productIconValue ? <AppIcon url={productIconValue} mainIcon /> : <BlankIcon />;
};

const AppIcon = styled.div.attrs(({url, mainIcon}: {url: string; mainIcon?: boolean}) => ({
  url,
  mainIcon,
}))`
  width: 80px;
  flex-shrink: 0;
  background-color: ${colors.transparent};
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-clip: content-box;
`;

const BlankIcon = styled.div`
  width: 80px;
  flex-shrink: 0;
  padding: 0.5rem;
  border-radius: 25%;
  background-color: ${colors.grey300};
  border: 1px solid ${colors.disabledGrey};
`;

export default Icon;
