/* tslint:disable */
/* eslint-disable */
/**
 * Proposal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    RedAppInfoResponse,
    instanceOfRedAppInfoResponse,
    RedAppInfoResponseFromJSON,
    RedAppInfoResponseFromJSONTyped,
    RedAppInfoResponseToJSON,
} from './RedAppInfoResponse';

/**
 * @type ProductInfoResponse
 * 
 * @export
 */
export type ProductInfoResponse = { productType: 'Red App' } & RedAppInfoResponse;

export function ProductInfoResponseFromJSON(json: any): ProductInfoResponse {
    return ProductInfoResponseFromJSONTyped(json, false);
}

export function ProductInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['productType']) {
        case 'Red App':
            return {...RedAppInfoResponseFromJSONTyped(json, true), productType: 'Red App'};
        default:
            throw new Error(`No variant of ProductInfoResponse exists with 'productType=${json['productType']}'`);
    }
}

export function ProductInfoResponseToJSON(value?: ProductInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['productType']) {
        case 'Red App':
            return RedAppInfoResponseToJSON(value);
        default:
            throw new Error(`No variant of ProductInfoResponse exists with 'productType=${value['productType']}'`);
    }

}

