import {FormattedMessage} from 'react-intl';
import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {SellerDeveloper} from '../../../../generated/management';
import {SellersSelectContext} from '../../../../providers/SellersProvider';
import RemoveDeveloperModal from './RemoveDeveloperModal';
import EditDeveloperModal from './EditDeveloperModal';

const CompanyLevelActionMenu = ({sellerDeveloper}: {sellerDeveloper: SellerDeveloper}) => {
  const [removeDeveloperModalOpen, setRemoveDeveloperModalOpen] = useState(false);
  const handleRemoveDeveloperModalOpen = (): void => setRemoveDeveloperModalOpen(true);
  const [editDeveloperModalOpen, setEditDeveloperModalOpen] = useState(false);
  const handleEditDeveloperModalOpen = (): void => setEditDeveloperModalOpen(true);
  const {currentSeller} = useContext(SellersSelectContext);
  const handleClose = () => setEditDeveloperModalOpen(false);

  return (
    <ul className="spark-popover__list spark-pad-1">
      <li className="spark-popover__list-item">
        <ActionItem
          className="spark-popover__list-item spark-popover__list-link"
          onClick={handleEditDeveloperModalOpen}
        >
          <FormattedMessage id="companyManagement.action.edit" />
        </ActionItem>
        <EditDeveloperModal
          sellerId={currentSeller.id}
          sellerDeveloper={sellerDeveloper}
          open={editDeveloperModalOpen}
          handleClose={handleClose}
        />
      </li>
      <li className="spark-popover__list-item">
        <ActionItem
          className="spark-popover__list-item spark-popover__list-link"
          onClick={handleRemoveDeveloperModalOpen}
        >
          <FormattedMessage id="companyManagement.action.remove" />
        </ActionItem>
        <RemoveDeveloperModal
          sellerId={currentSeller.id}
          pcc={sellerDeveloper.pcc}
          epr={sellerDeveloper.epr}
          firstName={sellerDeveloper.firstName || ''}
          lastName={sellerDeveloper.lastName || ''}
          sellerName={currentSeller.name}
          open={removeDeveloperModalOpen}
          setOpen={setRemoveDeveloperModalOpen}
        />
      </li>
    </ul>
  );
};

const ActionItem = styled.li`
  cursor: pointer;
`;

export default CompanyLevelActionMenu;
