import React, {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import HalfWidthContainer from 'components/styledComponents/HalfWidthContainer';
import CenterItems from 'components/styledComponents/CenterItems';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import {maxMobileWidth, minDesktopWidth} from 'assets/deviceWidth';
import colors from '@scm/components/assets/colors';

const ExploreItem = ({
  icon,
  title,
  text,
  button,
  className,
}: {
  icon: string;
  title: string;
  text: ReactNode;
  button?: ReactNode;
  className?: string;
}) => {
  const itemLayout = (
    <>
      <CenterItems>
        <Icon className={icon} />
      </CenterItems>
      <Title className="spark-mar-b-1">
        <FormattedMessage id={title} />
      </Title>
      <Text>{text}</Text>
      {button && button}
    </>
  );

  return (
    <>
      <MediaQuery maxDeviceWidth={maxMobileWidth}>
        <ExploreItemMobileContainer className={className}>{itemLayout}</ExploreItemMobileContainer>
      </MediaQuery>
      <MediaQuery minDeviceWidth={minDesktopWidth}>
        <HalfWidthContainer className={className} padding="0 4rem">
          {itemLayout}
        </HalfWidthContainer>
      </MediaQuery>
    </>
  );
};

const ExploreItemMobileContainer = styled.div.attrs<{className?: string}>(className => ({
  className: `spark-flex spark-gamma ${className}`,
}))`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  .spark-btn {
    padding: 2rem 8.25rem;
  }
`;

const Title = styled.div.attrs<{className?: string}>(({className}) => ({
  className: `${className} spark-mar-b-1`,
}))`
  font-weight: 800;
  color: ${colors.grey700};
  text-align: center;
`;

const Icon = styled.i.attrs(({className}) => ({className: `${className}`}))`
  font-size: 7rem;
  padding: 5rem;
  color: ${colors.grey700};
`;

const Text = styled.div.attrs(() => ({
  className: 'spark-mar-b-2',
}))`
  max-height: 25%;
  text-align: justify;
`;

export default ExploreItem;
