enum ExternalUrls {
  SabrePrivacy = 'https://www.sabre.com/about/privacy/',
  SabreCentral = 'https://central.sabre.com',
  Marketplace = 'https://central.sabre.com/marketplace',
  ProductDetails = 'https://central.sabre.com/marketplace/samProductDetails',
  PrivacyPolicy = 'https://www.sabre.com/about/privacy/',
  Sabre = 'https://www.sabre.com',
  SabreTwitter = 'https://twitter.com/sabre_corp',
  SabreFacebook = 'https://www.facebook.com/SabreCorporation/',
  SabreLinkedIn = 'https://www.linkedin.com/company/sabre-corporation',
  SabreYoutube = 'https://www.youtube.com/user/SabreNewsTV',
  PciDataSecurityStandards = 'https://www.pcisecuritystandards.org/',
  PrivacyStandards = 'https://www.sabre.com/about/privacy/',
  DevStudioHomePage = 'https://developer.sabre.com/guides/travel-agency/sdks/sabre-red-360-developer-kit',
  DevRegistration = 'https://developer.sabre.com/user/register',
  MarketplaceMySales = 'https://central.sabre.com/marketplace/ccrz__MyAccount?viewState=mySales&checkLogin=true',
  DeveloperSolutionHowTo = 'https://developer.sabre.com/solutions/how-to-videos',
  MySalesAndReportsDevCert = 'https://sabre--cert.sandbox.my.site.com/marketplace/ccrz__MyAccount?viewState=mySales&checkLogin=true',
  MySalesAndReportsProd = 'https://central.sabre.com/marketplace/ccrz__MyAccount?viewState=mySales&checkLogin=true',
  MyAgencyOrdersDevCert = 'https://sabre--cert.sandbox.my.site.com/marketplace/ccrz__MyAccount?viewState=mySabreOrders&checkLogin=true',
  MyAgencyOrdersProd = 'https://central.sabre.com/marketplace/ccrz__MyAccount?viewState=mySabreOrders&checkLogin=true',
}

export default ExternalUrls;
