import {
  activeListeningFilesNames,
  activeListeningName,
  hiddenUploadedScribeScriptFilesNames,
  hiddenUploadedScribeScriptName,
  resourcesFilesNames,
  resourcesName,
  ScribeScriptsValues,
  uploadedScribeScriptFilesNames,
  uploadedScribeScriptName,
} from './ScribeScriptsValues';

export const initialValues: ScribeScriptsValues = {
  [uploadedScribeScriptFilesNames]: [],
  [hiddenUploadedScribeScriptFilesNames]: [],
  [resourcesFilesNames]: [],
  [activeListeningFilesNames]: [],
  [uploadedScribeScriptName]: '',
  [hiddenUploadedScribeScriptName]: 'No',
  [resourcesName]: 'No',
  [activeListeningName]: 'No',
};
