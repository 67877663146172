import React, {useContext} from 'react';
import {useIntl} from 'react-intl';
import ReviewHeader from '../../components/reviewComponents/ReviewHeader';
import {ProposalContext} from '@scm/proposal/pages/proposalForm/ProposalForm';
import styled from 'styled-components';
import {RedAppPricingTypePricingTypeEnum} from '../../../generated/onboardings';
import ReviewParagraphs from '@scm/components/form/review/ReviewParagraphs';
import {Confirmation} from '@scm/proposal/pages/proposalForm/steps/serviceAndSecurity/ServiceAndSecurityValues';

const OnboardingFieldsReview = ({pricingType, pcc, epr}: {pricingType?: string; pcc?: string; epr?: string}) => {
  const {formatMessage} = useIntl();
  const {submitRequest: onboardingFormData} = useContext(ProposalContext);

  const isRevenuePricing = !!pricingType && pricingType === RedAppPricingTypePricingTypeEnum.RevenueShare;

  return (
    <PageWrapper>
      <div className="spark-mar-t-2">
        <ReviewHeader content="joinUsPage.form.reviewAndSubmit.generalInformation" />
        <ReviewHeader content="joinUsPage.form.reviewAndSubmit.contactDetails.title" type="h4" />
      </div>
      <ReviewParagraphs
        label={[
          'joinUsPage.form.contactDetails.firstName.label',
          'joinUsPage.form.contactDetails.lastName.label',
          'joinUsPage.form.contactDetails.companyTitle.label',
          'joinUsPage.form.contactDetails.email.label',
          'joinUsPage.form.contactDetails.phone.label',
          'joinUsPage.form.contactDetails.fullCompanyName.label',
        ]}
        content={[
          onboardingFormData.contactDetails.firstName,
          onboardingFormData.contactDetails.lastName,
          onboardingFormData.contactDetails.titleInCompany,
          onboardingFormData.contactDetails.email,
          onboardingFormData.contactDetails.phone,
          onboardingFormData.companyInformation.name,
        ]}
      />
      <div className="spark-mar-t-2">
        <ReviewHeader content="joinUsPage.form.reviewAndSubmit.contactDetails.companyAddress.title" type="h4" />
      </div>
      <ReviewParagraphs
        label={Object.keys(onboardingFormData.companyInformation.address)
          .map(item => `joinUsPage.form.contactDetails.${item}.label`)
          .concat(['joinUsPage.form.contactDetails.url.label'])}
        content={Object.values<string>(onboardingFormData.companyInformation.address).concat([
          onboardingFormData.companyInformation.website,
        ])}
      />
      <div className="spark-mar-t-2">
        <ReviewHeader content="joinUsPage.form.reviewAndSubmit.contactDetails.companyLocation.title" type="h4" />
      </div>
      <ReviewParagraphs
        label={[
          'joinUsPage.form.contactDetails.countryCode.label',
          'joinUsPage.form.contactDetails.provinceLocated.placeholder',
          'joinUsPage.form.contactDetails.solutionDescription.placeholder',
        ]}
        content={[
          onboardingFormData.companyInformation.address.country,
          onboardingFormData.companyInformation.address.province,
          onboardingFormData.companyInformation.businessProfile,
        ]}
      />
      <div className="spark-mar-t-2">
        <ReviewHeader content="joinUsPage.form.reviewAndSubmit.termsAndCondition.title" />
      </div>
      <ReviewParagraphs label={['joinUsPage.form.reviewAndSubmit.termsAndCondition.description']} />
      <div className="spark-mar-t-2">
        <ReviewHeader content="joinUsPage.form.reviewAndSubmit.pricingDetails.title" />
      </div>
      <ReviewParagraphs
        label={['joinUsPage.form.pricing.certificationFee.title', 'joinUsPage.form.pricing.monthlyFee.title']}
        content={[
          formatMessage({id: 'pricingDetails.certificationFee.perEachRedApp'}),
          formatMessage({id: `pricingDetails.monthlyFee.${isRevenuePricing ? 'revenueShare' : 'eprFee'}`}),
        ]}
      />
      <div className="spark-mar-t-2">
        <ReviewHeader content="joinUsPage.form.reviewAndSubmit.addidionalInformation.title" />
      </div>
      <ReviewParagraphs
        label={['joinUsPage.form.sabreAccountInfo.pcc.label', 'joinUsPage.form.sabreAccountInfo.epr.label']}
        content={[
          !!pcc ? `${Confirmation.Yes} (${pcc})` : Confirmation.No,
          !!epr ? `${Confirmation.Yes} (${epr})` : Confirmation.No,
        ]}
      />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  margin-bottom: 2rem;
  h3,
  h4 {
    font-weight: 300 !important;
  }
`;

export default OnboardingFieldsReview;
