import MediaQuery, {useMediaQuery} from 'react-responsive';
import {minDesktopWidth} from 'assets/deviceWidth';
import {LeftBar, TasksIcon} from 'pages/partnerDirectory/searchPanel/StyledFiltersComponents';
import Filters from 'pages/partnerDirectory/searchPanel/Filters';
import React from 'react';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const SearchPanelDesktop = ({onClickHandler}: {onClickHandler: () => void}) => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  return (
    <MediaQuery minDeviceWidth={minDesktopWidth}>
      <LeftBar onClick={onClickHandler} className="spark-pad-.5" mobile={isMobile}>
        <TasksIcon className="spark-icon--fill" mobile={isMobile} />
      </LeftBar>
      <Filters />
    </MediaQuery>
  );
};

export default SearchPanelDesktop;
