import React, {useContext} from 'react';
import Breadcrumbs from './Breadcrumbs';
import NameAndIcon from './NameAndIcon';
import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import useGetProductC from '../../../hooks/useGetProductC';
import {VersionContext} from '../../../provider/VersionProvider';

const Header = () => {
  const {ccrzProductStatusC} = useGetProductC();
  const {bundles} = useContext(VersionContext);
  const productStatus = ccrzProductStatusC ?? '';

  const viewOnMarketplaceTextFormatter = () =>
    productStatus === 'In Creation' && !bundles.find(bundle => bundle.isPatch)
      ? 'products.actions.preview'
      : 'productDetails.marketplaceLink';

  return (
    <>
      <Breadcrumbs />
      <Container className="spark-flex spark-pad-l-2 spark-pad-r-2 spark-pad-t-2">
        <NameAndIcon viewOnMarketplaceLink={viewOnMarketplaceTextFormatter()} />
      </Container>
    </>
  );
};

export const Container = styled.div`
  justify-content: space-between;
  @media (max-width: ${deviceWidths.sm}px) {
    flex-wrap: wrap;
  }
  margin-bottom: 2rem;
`;

export default Header;
