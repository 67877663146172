const resources = {
  'resources.title': 'SCM Admin Centre and Red Apps Resources',
  'resources.subHeader':
    'Discover useful resources that explain how to use Admin Centre, and can make Red Apps development easier.',
  'resources.resource.referenceGuide': 'Quick Reference Guide',
  'resources.resource.referenceGuide.title': 'SCM Admin Centre Quick Reference Guide',
  'resources.resource.referenceGuide.description':
    'In this guide you will find step-by-step descriptions of all the functionalities available in the Sabre Central Marketplace Admin Centre.',
  'resources.resource.proposal.title': 'Submit a proposal',
  'resources.resource.proposal.description':
    'In this tutorial you will learn step-by-step how to add a proposal for the public and private Red App.',
  'resources.resource.certification.title': 'First Bundle, Certification & Storefront Content Tutorial',
  'resources.resource.certification.description':
    'In this tutorial you will learn step-by-step how to upload your first bundle, how its certification goes, and how to upload storefront content and publish your Red App.',
  'resources.resource.nextBundle.title': 'Add Next Bundle',
  'resources.resource.nextBundle.description':
    'In this tutorial you will see how to upload the next bundle for your Red App.',
  'resources.resource.redAppProvider.title': 'Red App Provider SCM Guide',
  'resources.resource.redAppProvider.description':
    'This guide will explain how to check orders for your Red Apps in the Sabre Central Marketplace, accept and reject them, how to generate reports on orders for your Red Apps and how you can customise the notifications we send you there.',
  'resources.resource.sabreRed.title': 'Sabre Red 360 Resources',
  'resources.resource.sabreRed.description': 'Discover useful Red App developer resources on this page in Dev Studio.',
  'resources.resource.sr360.title': 'How to turn on a Red App in SR360?',
  'resources.resource.sr360.description':
    'If you have Red App orders with the manual activation method, in this guide you will learn how to activate Red Apps in Sabre Red 360.',
  'resources.other': 'Other Resources',
  'resources.main.link': 'View Resource',
  'resources.resource.useCases.title': 'Sample Use Cases File',
  'resources.resource.useCases.description': "Check how a sample 'Use Cases File' for your Red App should look like.",
};

export default resources;
