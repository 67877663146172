/* tslint:disable */
/* eslint-disable */
/**
 * Certification API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Status = {
    BundleSubmitted: 'Bundle Submitted',
    ValidationFailed: 'Validation Failed',
    BundleValidated: 'Bundle Validated',
    ReadyForTestCert: 'Ready for Test (CERT)',
    CertTestsPassed: 'CERT Tests Passed',
    Cancelled: 'Cancelled',
    CertTestsFailed: 'CERT Tests Failed',
    ReadyForTestProd: 'Ready for Test (PROD)',
    RejectedForProd: 'Rejected For PROD',
    ApprovedForProd: 'Approved For PROD',
    ProdTestsFailed: 'PROD Tests Failed',
    ProdTestsPassed: 'PROD Tests Passed',
    Published: 'Published',
    RollbackInProgress: 'Rollback in Progress',
    RolledBack: 'Rolled Back'
} as const;
export type Status = typeof Status[keyof typeof Status];


export function StatusFromJSON(json: any): Status {
    return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
    return json as Status;
}

export function StatusToJSON(value?: Status | null): any {
    return value as any;
}

