import React, {useState} from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import FormattedLinkMessage from 'components/formattedMessages/FormattedLinkMessage';
import ExternalUrls from '@scm/components/utils/externalUrls';
import colors from '@scm/components/assets/colors';

export const cookiesConsentKey = 'cookiesConsent';

const CookiesConsent = () => {
  const [isCookiesConsent, setCookiesConsent] = useState(!!localStorage.getItem(cookiesConsentKey));

  const accept = () => {
    localStorage.setItem(cookiesConsentKey, String(true));
    setCookiesConsent(true);
  };

  return isCookiesConsent ? null : (
    <Container>
      <Text>
        <FormattedLinkMessage id="common.cookiesConsent" href={ExternalUrls.SabrePrivacy} />
      </Text>
      <Accept onClick={accept} data-testid="Cookies">
        <FormattedMessage id="common.accept" />
      </Accept>
    </Container>
  );
};

const Container = styled.div.attrs(() => ({
  className: 'spark-flex spark-align-items-center',
}))`
  position: fixed;
  flex-direction: column;
  text-align: center;
  padding: 2em 10vw;

  bottom: 0;
  z-index: 100;
  width: 100%;

  background-color: ${colors.black};
  color: ${colors.grey50};
  opacity: 0.9;
`;

const Text = styled.div`
  color: ${colors.grey200};

  & > a {
    color: ${colors.white};
    :hover {
      text-decoration: underline;
    }
  }
`;

const Accept = styled.div.attrs(() => ({
  className: 'spark-pad-t-1',
}))`
  color: ${colors.white};
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export default CookiesConsent;
