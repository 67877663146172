import React from 'react';
import {useIntl} from 'react-intl';
import {ActionBadges, ExpandPanel, MultiSelect} from '@scm/components';
import {MultiSelectMainProps} from '@scm/components/form/multiSelect/MultiSelect';
import {ProductCategories} from '@scm/components/assets/productCategories';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {FieldError, FieldValues, UseFormSetValue, UseFormWatch} from 'react-hook-form';

const Category = ({
  firstLevelName,
  watch,
  setValue,
  errors,
  className,
  disabled,
}: {
  firstLevelName: string;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors?: FieldError;
  className?: string;
  disabled?: boolean;
}) => {
  const {formatMessage} = useIntl();
  const multiSelectSize = 3;

  const createOptions = () =>
    Object.values(ProductCategories).reduce((result, value) => Object.assign(result, {[value]: value}), {});

  const multiSelectMainProps: MultiSelectMainProps = {
    firstLevelName: firstLevelName,
    setValue: setValue,
    watch: watch,
    options: createOptions(),
  };

  const categoryDeleteHandler = (name: string) => {
    setValue(
      firstLevelName,
      watch(firstLevelName).filter((value: string) => value !== name),
      {shouldDirty: true, shouldTouch: true, shouldValidate: true}
    );
  };

  return (
    <div className={`spark-pad-l-0 ${className}`}>
      {!disabled && (
        <MultiSelectContainer>
          <ExpandPanel
            panelHeader={formatMessage(
              {id: 'definition.category.label'},
              {
                star: word => <span className="spark-danger">{word}</span>,
              }
            )}
            disabled={disabled}
          >
            <MultiSelect
              multiSelectMainProps={multiSelectMainProps}
              size={multiSelectSize}
              disabled={watch(firstLevelName).length > multiSelectSize}
            />
          </ExpandPanel>
          {errors && errors[firstLevelName] && (
            <MultiselectErrorMessage>{errors[firstLevelName]?.message}</MultiselectErrorMessage>
          )}
        </MultiSelectContainer>
      )}

      <ActionBadges
        badgeNames={watch(firstLevelName)}
        deleteHandler={categoryDeleteHandler}
        className="spark-pad-l-0 spark-mar-t-2"
        disabled={disabled}
      />
    </div>
  );
};

export const MultiSelectContainer = styled.div`
  width: 17rem;
  margin: 1.5rem 0;
`;

export const MultiselectErrorMessage = styled.span`
  display: block;
  color: ${colors.white};
  background-color: ${colors.red};
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0.75rem 1rem;
  font-size: 1.3333333333rem;
  font-weight: 700;
  width: 150%;
`;

export default Category;
