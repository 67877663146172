const tiers = {
  'tiers.title': 'Sabre Partner Tiers',
  'tiers.subtitle':
    'Sabre Authorized Developer has been rebranded as <strong>Sabre Developer Partner</strong>. As part of Sabre’s ongoing commitment to providing the very best support to our partners, we are pleased to announce the launch of Partner Tier system as part of the Sabre Developer Partner program.',
  'tiers.contactBox.effectiveDate': 'Effective Since',
  'tiers.contactBox.effectiveDateSubtitle': 'January 2021',
  'tiers.contactBox.newToProgram': 'New to the Program?',
  'tiers.contactBox.newToProgramSubtitle': 'We will get back to you within 3-5 working days',
  'tiers.contactBox.contactUsButton': 'Join Us',
  'tiers.contactBox.currentPartner': 'Current Sabre Developer Partner?',
  'tiers.contactBox.currentPartnerSubtitle':
    'Contact your Sabre Developer Account Team or Partner Assist <emailAddress>PartnerAssist@sabre.com</emailAddress> for more information.',
  'tiers.contactBox.apac.name': 'APAC Region',
  'tiers.contactBox.apac.email': 'adp-apac@sabre.com',
  'tiers.contactBox.emea.name': 'EMEA Region',
  'tiers.contactBox.emea.email': 'adp-emea@sabre.com',
  'tiers.contactBox.namlac.name': 'NAM/LAC Region',
  'tiers.contactBox.namlac.email': 'adp-americas@sabre.com',
  'tiers.description.title': 'What is Sabre Developer Partner versus Sabre Developer Partner Tier?',
  'tiers.description.noOfParagraph': '4',
  'tiers.description.paragraph1':
    'Developer Partners who develop and deploy solutions using the Sabre platform must subscribe to the Sabre Developer Partner program. Once a developer becomes a partner in the Sabre Developer Partner program, the correct tier will be applied automatically, and the Developer Partner will be notified.',
  'tiers.description.paragraph2':
    'Tiers have been established to enable Sabre to optimize our resources and strengthen partnerships with developers. Sabre Developer Partners are assigned to a tier based on their level of investment with Sabre and enjoy the benefits associated with their respective tier. As a Developer Partner grows with Sabre, so does our investment and partnership.',
  'tiers.description.paragraph3':
    'All Sabre Developer Partners receive resources to ensure optimal onboarding as well as easy integration with the Sabre Platform, along with faster adoption of Sabre innovations, and access to the Sabre network of agencies. Depending on their tier, Developer Partners may also receive a dedicated account director, technical consulting, and free promotional opportunities.',
  'tiers.description.paragraph4':
    'Ultimately, we support Sabre Developer Partners as they evolve their portfolio to better serve customers and expand their network.',
};

export default tiers;
