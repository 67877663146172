import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {ToastType} from '@sabre/spark-react-core/types';
import {productApiBaseLink} from '@scm/product-components';
import {Configuration, StandardProductApi} from '../generated/product';

export async function changeStandardProductName(sku: string, newName: string) {
  return await new StandardProductApi(
    new Configuration({basePath: productApiBaseLink, accessToken: getAccessToken() || '', middleware: middleware})
  ).updateProductName({
    sku,
    productNameRequest: {name: newName},
  });
}

const useChangeProductName = (sku: string, setLoading: (flag: boolean) => void, handleCloseModal: () => void) => {
  const {formatMessage} = useIntl();

  const changeProductName = useCallback(
    async (name: string) => {
      const updateMessageName = 'productDetails.icon.change';
      try {
        await changeStandardProductName(sku, name);
        openToast(
          createMessageString(formatMessage, updateMessageName, true),
          createMessageString(formatMessage, updateMessageName),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
        setLoading(true);
        handleCloseModal();
      } catch (err) {
        openToast(
          createMessageString(formatMessage, updateMessageName, true, true),
          createMessageString(formatMessage, updateMessageName, false, true),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      }
    },
    [sku, setLoading, handleCloseModal, formatMessage]
  );

  return {changeProductName};
};

export default useChangeProductName;
