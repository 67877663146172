import React, {SetStateAction, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Certification from './Certification';
import Maintenance from './Maintenance';
import {Separator} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {Loading} from '@scm/components';
import {Configuration, VersionsApi} from '@scm/product-components/generated/certification';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {LoadingContainer} from '@scm/authentication/components/Login';
import useDetailedVersion from '@scm/product-components/pages/productDetails/productTabs/useDetailedVersion';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

export const getDetailedVersion = async (id: string) =>
  new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).getVersion({id});

const Certify = ({
  setLoadingProductDetails,
  chosenVersionId,
  isVersionPatch,
}: {
  setLoadingProductDetails: React.Dispatch<SetStateAction<boolean>>;
  chosenVersionId: string;
  isVersionPatch: boolean;
}) => {
  const [status, setStatus] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const {formatMessage} = useIntl();
  const watch = useDetailedVersion(chosenVersionId, setLoading, setStatus);

  return loading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <>
      <Separator className="spark-mar-l-2 spark-mar-b-2 spark-pad-b-1 spark-bold spark-large">
        <FormattedMessage id="certify.certification.header" />
      </Separator>
      <Certification
        status={status}
        id={chosenVersionId}
        setLoadingProductDetails={setLoadingProductDetails}
        setLoading={setLoading}
        watch={watch}
      />
      <Separator className="spark-mar-l-2 spark-mar-b-2 spark-pad-b-1 spark-bold spark-large">
        <FormattedMessage id="certify.maintenance.header" />
      </Separator>
      <Maintenance
        setLoadingProductDetails={setLoadingProductDetails}
        status={status}
        currentVersionId={chosenVersionId}
        isVersionPatch={isVersionPatch}
        setLoading={setLoading}
      />
    </>
  );
};
export default Certify;
