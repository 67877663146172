import React, {createContext} from 'react';
import {getAccessToken, isLoggedIn} from '../utils/authentication';
import {authenticationConstants} from './authenticationConstants';

export const AuthenticationContext = createContext(authenticationConstants);

const AuthenticationProvider = ({children}: {children: JSX.Element}) => {
  const value = {
    accessToken: isLoggedIn() ? getAccessToken() : null,
  };

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export default AuthenticationProvider;
