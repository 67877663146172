export enum Actors {
  Developer,
  Sabre,
}

interface IStep {
  name: string;
  action?: string;
  actor: Actors;
  time?: string;
}

export interface IProcessScheme {
  title: string;
  steps: IStep[];
}

const ProcessSchemes: {[key: string]: IProcessScheme} = {
  PublicApp: {
    title: 'processScheme.redApp.title',
    steps: [
      {
        name: 'processScheme.publicApp.step1',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step2',
        action: 'processScheme.publicApp.action2',
        actor: Actors.Sabre,
        time: 'processScheme.publicApp.action2.time',
      },
      {
        name: 'processScheme.publicApp.step3',
        action: 'processScheme.publicApp.action3',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step4',
        action: 'processScheme.publicApp.action4',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step5',
        action: 'processScheme.publicApp.action5',
        actor: Actors.Sabre,
        time: 'processScheme.publicApp.action5.time',
      },
      {
        name: 'processScheme.publicApp.step6',
        action: 'processScheme.publicApp.action6',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step7',
        action: 'processScheme.publicApp.action7',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step8',
        action: 'processScheme.publicApp.action8',
        actor: Actors.Developer,
      },
    ],
  },
  PublicAppPatch: {
    title: 'processScheme.redAppVersionCertification.title',
    steps: [
      {
        name: 'processScheme.publicApp.step3',
        action: 'processScheme.publicApp.action3',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step4',
        action: 'processScheme.publicApp.action4',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step5',
        action: 'processScheme.publicApp.action5',
        actor: Actors.Sabre,
        time: 'processScheme.publicApp.action5.time',
      },
      {
        name: 'processScheme.publicApp.step6',
        action: 'processScheme.publicApp.action6',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step8',
        action: 'processScheme.publicApp.action8',
        actor: Actors.Developer,
      },
    ],
  },
  PublicAppProposalApproved: {
    title: 'processScheme.redAppVersionCertification.title',
    steps: [
      {
        name: 'processScheme.publicApp.step3',
        action: 'processScheme.publicApp.action3',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step4',
        action: 'processScheme.publicApp.action4',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step5',
        action: 'processScheme.publicApp.action5',
        actor: Actors.Sabre,
        time: 'processScheme.publicApp.action5.time',
      },
      {
        name: 'processScheme.publicApp.step6',
        action: 'processScheme.publicApp.action6',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step7',
        action: 'processScheme.publicApp.action7',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.publicApp.step8',
        action: 'processScheme.publicApp.action8',
        actor: Actors.Developer,
      },
    ],
  },
  PrivateApp: {
    title: 'processScheme.redApp.title',
    steps: [
      {
        name: 'processScheme.privateApp.step1',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step2',
        action: 'processScheme.privateApp.action2',
        actor: Actors.Sabre,
        time: 'processScheme.privateApp.action2.time',
      },
      {
        name: 'processScheme.privateApp.step3',
        action: 'processScheme.privateApp.action3',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step4',
        action: 'processScheme.privateApp.action4',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step5',
        action: 'processScheme.privateApp.action5',
        actor: Actors.Sabre,
        time: 'processScheme.privateApp.action5.time',
      },
      {
        name: 'processScheme.privateApp.step6',
        action: 'processScheme.privateApp.action6',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step7',
        action: 'processScheme.privateApp.action7',
        actor: Actors.Developer,
      },
    ],
  },
  PrivateAppPatch: {
    title: 'processScheme.redAppVersionCertification.title',
    steps: [
      {
        name: 'processScheme.privateApp.step3',
        action: 'processScheme.privateApp.action3',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step4',
        action: 'processScheme.privateApp.action4',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step6',
        action: 'processScheme.privateApp.action6',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step7',
        action: 'processScheme.privateApp.action7',
        actor: Actors.Developer,
      },
    ],
  },
  PrivateAppProposalApproved: {
    title: 'processScheme.redAppVersionCertification.title',
    steps: [
      {
        name: 'processScheme.privateApp.step3',
        action: 'processScheme.privateApp.action3',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step4',
        action: 'processScheme.privateApp.action4',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step5',
        action: 'processScheme.privateApp.action5',
        actor: Actors.Sabre,
        time: 'processScheme.privateApp.action5.time',
      },
      {
        name: 'processScheme.privateApp.step6',
        action: 'processScheme.privateApp.action6',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.privateApp.step7',
        action: 'processScheme.privateApp.action7',
        actor: Actors.Developer,
      },
    ],
  },
  PfKeys: {
    title: 'processScheme.pfKeys.title',
    steps: [
      {
        name: 'processScheme.pfKeys.step1',
        action: 'processScheme.pfKeys.action1',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.pfKeys.step2',
        action: 'processScheme.pfKeys.action2',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.pfKeys.step3',
        actor: Actors.Developer,
      },
    ],
  },
  Scribes: {
    title: 'processScheme.scribes.title',
    steps: [
      {
        name: 'processScheme.scribes.step1',
        action: 'processScheme.scribes.action1',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.scribes.step2',
        action: 'processScheme.scribes.action2',
        actor: Actors.Developer,
      },
      {
        name: 'processScheme.scribes.step3',
        actor: Actors.Developer,
      },
    ],
  },
};

export default ProcessSchemes;
