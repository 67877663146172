const partnerContact = {
  'partnerContact.firstName': 'First Name',
  'partnerContact.lastName': 'Last Name',
  'partnerContact.email': 'Email',
  'partnerContact.jobTitle': 'Job Title',
  'partnerContact.company': 'Company',
  'partnerContact.phoneNumber': 'Phone Number',
  'partnerContact.tellUsMore': 'Tell us more',
  'partnerContact.title': 'Interested in learning more about {partnerName}?',
  'partnerContact.subtitle': 'Please provide the following information and we’ll respond shortly.',
  'partnerContact.readPrivacyPolicy': 'Please take a moment to read our ',
  'partnerContact.includeSender': 'Send a copy to my email address',
  'partnerContact.privacyPolicy': 'privacy policy',
  'partnerContact.cancelButton': 'Cancel',
  'partnerContact.sendButton': 'Send',
  'partnerContact.emailSentHeader': 'An email has been sent!',
  'partnerContact.emailSentText': 'Please wait for contact from your partner representative',
  'partnerContact.email.subject': 'Sabre Developer Partner Network – inquiry about {name}',
};

export default partnerContact;
