import WhiteText from 'components/styledComponents/WhiteText';
import React, {ReactNode} from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';

const WelcomeItem = ({image, mobileText}: {image?: string; mobileText?: ReactNode}) => (
  <Container>
    <Item mobile={!!mobileText}>
      {image && <img alt="" src={image} />}
      {mobileText && (
        <WhiteText className="spark-mega spark-text-center" fontSize="4rem">
          {mobileText}
        </WhiteText>
      )}
    </Item>
  </Container>
);

const Container = styled.div`
  overflow: hidden;
`;

const Item = styled.div.attrs(() => ({className: 'spark-flex'}))<{
  mobile?: boolean;
}>`
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 30vh;
  background-color: ${colors.grey500};
  & img {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
  }
`;

export default WelcomeItem;
