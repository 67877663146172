/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CcrzEProductMediaCSObject
 */
export interface CcrzEProductMediaCSObject {
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductMediaCSObject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductMediaCSObject
     */
    ccrzFilePathC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductMediaCSObject
     */
    ccrzProductC?: string;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductMediaCSObject
     */
    ccrzMediaTypeC?: CcrzEProductMediaCSObjectCcrzMediaTypeCEnum;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductMediaCSObject
     */
    ccrzProductMediaSourceC?: CcrzEProductMediaCSObjectCcrzProductMediaSourceCEnum;
    /**
     * 
     * @type {string}
     * @memberof CcrzEProductMediaCSObject
     */
    ccrzURIC?: string;
    /**
     * 
     * @type {Date}
     * @memberof CcrzEProductMediaCSObject
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CcrzEProductMediaCSObject
     */
    lastModifiedDate?: Date;
}


/**
 * @export
 */
export const CcrzEProductMediaCSObjectCcrzMediaTypeCEnum = {
    AlternateImages: 'Alternate Images',
    AlternateThumbnails: 'Alternate Thumbnails',
    Datasheets: 'Datasheets',
    SafetyDocuments: 'Safety Documents',
    SalesBrochures: 'Sales Brochures',
    ProductSearchImage: 'Product Search Image',
    OwnersManuals: 'Owner\'s Manuals',
    AssemblyGuides: 'Assembly Guides',
    JpgImage: 'JPG Image',
    ProductImage: 'Product Image',
    ProductImageThumbnail: 'Product Image Thumbnail',
    FlashFiles: 'Flash Files',
    Media: 'Media',
    SvgInteractiveDiagram: 'SVG Interactive Diagram',
    YouTubeVideo: 'YouTube Video',
    StorefrontImage: 'Storefront Image'
} as const;
export type CcrzEProductMediaCSObjectCcrzMediaTypeCEnum = typeof CcrzEProductMediaCSObjectCcrzMediaTypeCEnum[keyof typeof CcrzEProductMediaCSObjectCcrzMediaTypeCEnum];

/**
 * @export
 */
export const CcrzEProductMediaCSObjectCcrzProductMediaSourceCEnum = {
    Attachment: 'Attachment',
    StaticResource: 'Static Resource',
    Uri: 'URI'
} as const;
export type CcrzEProductMediaCSObjectCcrzProductMediaSourceCEnum = typeof CcrzEProductMediaCSObjectCcrzProductMediaSourceCEnum[keyof typeof CcrzEProductMediaCSObjectCcrzProductMediaSourceCEnum];


/**
 * Check if a given object implements the CcrzEProductMediaCSObject interface.
 */
export function instanceOfCcrzEProductMediaCSObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CcrzEProductMediaCSObjectFromJSON(json: any): CcrzEProductMediaCSObject {
    return CcrzEProductMediaCSObjectFromJSONTyped(json, false);
}

export function CcrzEProductMediaCSObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CcrzEProductMediaCSObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'Id') ? undefined : json['Id'],
        'ccrzFilePathC': !exists(json, 'ccrz__FilePath__c') ? undefined : json['ccrz__FilePath__c'],
        'ccrzProductC': !exists(json, 'ccrz__Product__c') ? undefined : json['ccrz__Product__c'],
        'ccrzMediaTypeC': !exists(json, 'ccrz__MediaType__c') ? undefined : json['ccrz__MediaType__c'],
        'ccrzProductMediaSourceC': !exists(json, 'ccrz__ProductMediaSource__c') ? undefined : json['ccrz__ProductMediaSource__c'],
        'ccrzURIC': !exists(json, 'ccrz__URI__c') ? undefined : json['ccrz__URI__c'],
        'createdDate': !exists(json, 'CreatedDate') ? undefined : (new Date(json['CreatedDate'])),
        'lastModifiedDate': !exists(json, 'LastModifiedDate') ? undefined : (new Date(json['LastModifiedDate'])),
    };
}

export function CcrzEProductMediaCSObjectToJSON(value?: CcrzEProductMediaCSObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Id': value.id,
        'ccrz__FilePath__c': value.ccrzFilePathC,
        'ccrz__Product__c': value.ccrzProductC,
        'ccrz__MediaType__c': value.ccrzMediaTypeC,
        'ccrz__ProductMediaSource__c': value.ccrzProductMediaSourceC,
        'ccrz__URI__c': value.ccrzURIC,
        'CreatedDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'LastModifiedDate': value.lastModifiedDate === undefined ? undefined : (value.lastModifiedDate.toISOString()),
    };
}

