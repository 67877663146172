export const initialDescriptionName = 'initialDescription';
export const bundleFileName = 'bundleFile';
export const useCaseFileName = 'useCaseFile';
export const patchNotesName = 'patchNotes';
export const securityWorksheetName = 'securityWorksheet';
export const workflowDiagramName = 'workflowDiagram';

export interface BundleValues {
  [initialDescriptionName]: string;
  [bundleFileName]: Array<File>;
  [useCaseFileName]: Array<File>;
  [securityWorksheetName]?: Array<File>;
  [workflowDiagramName]?: Array<File>;
  [patchNotesName]?: string;
}
