/* tslint:disable */
/* eslint-disable */
/**
 * Product API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PccEprsInner
 */
export interface PccEprsInner {
    /**
     * 
     * @type {string}
     * @memberof PccEprsInner
     */
    pcc?: string;
    /**
     * 
     * @type {string}
     * @memberof PccEprsInner
     */
    epr?: string;
}

/**
 * Check if a given object implements the PccEprsInner interface.
 */
export function instanceOfPccEprsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PccEprsInnerFromJSON(json: any): PccEprsInner {
    return PccEprsInnerFromJSONTyped(json, false);
}

export function PccEprsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PccEprsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pcc': !exists(json, 'pcc') ? undefined : json['pcc'],
        'epr': !exists(json, 'epr') ? undefined : json['epr'],
    };
}

export function PccEprsInnerToJSON(value?: PccEprsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pcc': value.pcc,
        'epr': value.epr,
    };
}

