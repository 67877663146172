import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import ExploreItem from 'pages/home/explore/ExploreItem';
import FormattedLinkMessage from 'components/formattedMessages/FormattedLinkMessage';
import InternalUrls from 'utils/internalUrls';
import {Link} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import onJoinUsItemClicked from '../../../googleAnalytics/onJoinUsItemClicked';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';

const Explore = () => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const directoryButton = (
    <InternalLinkButton to={InternalUrls.Directory}>
      <button role="presentation" className="spark-btn spark-btn--block">
        <FormattedMessage id="homePage.about.agencies.button" />
      </button>
    </InternalLinkButton>
  );
  const partnerFormButton = (
    <InternalLinkButton to={InternalUrls.JoinUs}>
      <button role="presentation" className="spark-btn spark-btn--block" onClick={onJoinUsItemClicked}>
        <FormattedMessage id="homePage.about.partners.button" />
      </button>
    </InternalLinkButton>
  );
  const tiersButton = (
    <InternalLinkButton to={InternalUrls.Tiers}>
      <button role="presentation" className="spark-btn spark-btn--block">
        <FormattedMessage id="homePage.about.tiers.button" />
      </button>
    </InternalLinkButton>
  );
  const benefitsButton = (
    <InternalLinkButton to={InternalUrls.Benefits}>
      <button role="presentation" className="spark-btn spark-btn--block">
        <FormattedMessage id="homePage.about.benefits.button" />
      </button>
    </InternalLinkButton>
  );

  return (
    <div>
      <ExploreContainer mobile={isMobile}>
        <ExploreItem
          className={isMobile ? 'spark-pad-2' : ''}
          button={isMobile && directoryButton}
          icon="spark-icon-terminal"
          title="homePage.about.agencies.title"
          text={<FormattedMessage id="homePage.about.agencies.text" />}
        />
        <ExploreItem
          className={isMobile ? 'spark-pad-2' : ''}
          button={isMobile && partnerFormButton}
          icon="spark-icon-users-global"
          title="homePage.about.partners.title"
          text={
            <>
              <FormattedLinkMessage id="homePage.about.partners.text" href={InternalUrls.JoinUs} />
              <FormattedLinkMessage id="homePage.about.partners.text.second" href={InternalUrls.Benefits} />
            </>
          }
        />
      </ExploreContainer>
      {!isMobile && (
        <ButtonsRow mobile={isMobile}>
          {directoryButton}
          {partnerFormButton}
        </ButtonsRow>
      )}
      <ExploreContainer mobile={isMobile}>
        <ExploreItem
          className={isMobile ? 'spark-pad-2' : ''}
          button={isMobile && tiersButton}
          icon="spark-icon-star"
          title="homePage.about.tiers.title"
          text={<FormattedMessage id="homePage.about.tiers.text" />}
        />
        <ExploreItem
          className={isMobile ? 'spark-pad-2' : ''}
          button={isMobile && benefitsButton}
          icon="spark-icon-hand-gift"
          title="homePage.about.benefits.title"
          text={<FormattedMessage id="homePage.about.benefits.text" />}
        />
      </ExploreContainer>
      {!isMobile && (
        <ButtonsRow mobile={isMobile}>
          {tiersButton}
          {benefitsButton}
        </ButtonsRow>
      )}
    </div>
  );
};

const ExploreContainer = styled.div.attrs(() => ({
  className: 'spark-flex',
}))<{mobile?: boolean}>`
  padding-top: 2rem;
  text-align: justify;
  margin: ${props => `${props.mobile ? '0' : 'auto'}`};
  ${props => `${props.mobile ? '' : 'max-width: 70vw;'}`};
  ${props => (props.mobile ? 'flex-direction: column;' : '')}
`;

const ButtonsRow = styled.div.attrs(() => ({
  className: 'spark-flex spark-btn-group',
}))<{mobile?: boolean}>`
  padding-bottom: 4rem;
  justify-content: space-around;
  max-width: 70vw;
  margin: auto;
`;

const InternalLinkButton = styled(Link)`
  margin: 0 4rem;
  min-width: 27vw;
  @media (max-width: 1047px) {
    min-width: 50vw;
  }
  @media (max-width: 543px) {
    min-width: 90vw;
  }
`;

export default Explore;
