import React from 'react';

const Message = ({
  type,
  message,
  className,
  title = '',
  size = 'spark-message--lg',
}: {
  type: MessageType;
  message: string;
  className?: string;
  title?: string;
  size?: string;
}) => (
  <div className={`spark-message ${size} ${getClass(type)} ${className} spark-panel spark-message--animate`}>
    <div className="spark-panel__content">
      <i aria-hidden="true" className={`spark-message__icon ${getIcon(type)} spark-icon--fill`} />
      <div className="spark-message__content">
        <h4 className="spark-message__heading">{title}</h4>
        <p>{message}</p>
      </div>
    </div>
  </div>
);

export enum MessageType {
  success,
  warning,
  error,
  info,
}

const getClass = (type: MessageType) => {
  switch (type) {
    case MessageType.success:
      return 'spark-message--success';
    case MessageType.warning:
      return 'spark-message--warning';
    case MessageType.error:
      return 'spark-message--error';
    case MessageType.info:
      return 'spark-message--info';
  }
};

const getIcon = (type: MessageType) => {
  switch (type) {
    case MessageType.success:
      return 'spark-icon-check';
    case MessageType.warning:
      return 'spark-icon-alert-triangle';
    case MessageType.error:
      return 'spark-icon-ban';
    case MessageType.info:
      return 'spark-icon-note';
  }
};

export default Message;
