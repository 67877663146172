import {FileNameType, FileSource} from '@scm/proposal/pages/proposalForm/ProposalValues';

export const fileSourceName = 'fileSourceName';
export const pfKeysFilesNames = 'pfKeysFilesNames';
export const pfKeyFileName = 'pfKeyFileName';

export interface PfKeysValues {
  [fileSourceName]: FileSource;
  [pfKeysFilesNames]: Array<File>;
  [pfKeyFileName]: FileNameType;
}
