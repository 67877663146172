import {useFormContext} from 'react-hook-form';
import React, {useEffect, useRef} from 'react';
import SparkInput from '@sabre/spark/js/src/components/text-input';
import styled from 'styled-components';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import {Star} from '../text/Star';
import colors from '../assets/colors';

const Input = ({
  name,
  label,
  placeholder,
  value,
  errorMessage,
  onChange,
  onBlur,
  isMultiline,
  isRequired,
  maxLength = 255,
  hasCharacterCounter = false,
  disabled,
  icon,
  width,
}: {
  name: string;
  label: string;
  placeholder?: string;
  value?: string;
  errorMessage?: string;
  isMultiline?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isRequired?: boolean;
  maxLength?: number;
  hasCharacterCounter?: boolean;
  disabled?: boolean;
  icon?: boolean;
  width?: string;
}) => {
  const {
    register,
    formState: {errors},
  } = useFormContext();
  const inputRef = useRef(null);
  useEffect(() => {
    const sparkInput = new SparkInput(inputRef.current);
    return () => {
      sparkInput.remove();
    };
  }, [inputRef]);

  const requiredLabel = (isRequired?: boolean) =>
    typeof isRequired === 'undefined' ? <></> : isRequired ? <Star /> : <Optional />;

  const eventsProps = (
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onBlur?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  ) => {
    let eventsProps: any = {};
    if (onChange) {
      eventsProps.onChange = onChange;
    }
    if (onBlur) {
      eventsProps.onBlur = onBlur;
    }
    return eventsProps;
  };

  const inputComponent = isMultiline ? (
    <StyledTextArea
      {...register(name)}
      {...eventsProps(onChange, onBlur)}
      className="spark-input__field"
      placeholder={placeholder}
      maxLength={maxLength}
      data-maxlength-soft={maxLength}
      defaultValue={value}
      disabled={disabled}
    />
  ) : (
    <input
      {...register(name)}
      {...eventsProps(onChange, onBlur)}
      className="spark-input__field"
      placeholder={placeholder}
      maxLength={maxLength}
      defaultValue={value}
      disabled={disabled}
    />
  );

  const error = getError(name, errors);

  return (
    <div className={`spark-mar-b-1 ${width}`}>
      <label
        ref={inputRef}
        data-characters-remaining={hasCharacterCounter ? true : undefined}
        className="spark-input"
        {...(error && {'data-error': !!error})}
      >
        {inputComponent}
        <span className="spark-label">
          {label}
          {requiredLabel(isRequired)}
        </span>
        {icon ? (
          <div className="spark-input__addon">
            <i aria-hidden="true" className="spark-icon-search spark-icon--md" />
          </div>
        ) : undefined}
        {error && <span className="spark-input__message">{errorMessage}</span>}
      </label>
    </div>
  );
};

const StyledTextArea = styled.textarea`
  min-height: 10rem;
`;

export default Input;

export const getError = (name: string, errors: FieldErrors<Record<string, any>>) =>
  name.split('.').reduce((errors, name) => errors?.[name], errors);

export const Optional = styled.span`
  &:after {
    color: ${colors.grey500};
    margin: 0 3px;
    font-weight: 300;
    content: '(Optional)';
  }
`;
