import * as yup from 'yup';
import {maxArrayLength, minArrayLength, requiredArrayMessage} from '@scm/proposal/schemas/proposalConstants';
import {categoryName, ytLinkName} from '../pages/storefrontData/interfaces/AppInfoDataValues';
import {youtubePatternTest} from './productConstants';
export const textEditorLengthErrorName = 'textEditorLengthError';

export const updateProductSchema = yup.object().shape({
  [categoryName]: yup.array().min(minArrayLength, requiredArrayMessage).max(maxArrayLength),
  [ytLinkName]: yup.string().test('', 'productDetails.content.youtube.error', youtubePatternTest),
  [textEditorLengthErrorName]: yup.string().max(0),
});
