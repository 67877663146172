import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {PanelMenu} from '@scm/components';
import React, {MouseEventHandler, useContext, useState} from 'react';
import useGetProductC from '../../../hooks/useGetProductC';
import {CcrzEProductCSObjectRedAppTypeCEnum} from '../../../generated/product';
import {isAllowedToViewLimitation} from '@scm/authentication/utils/authentication';
import {InternalProviderContext} from '../../../provider/InternalProvider';
import GeneralInformation from '../../../components/storefront-content/general-information/GeneralInformation';
import OrderingSettings from '../../../components/storefront-content/ordering-settings/OrderingSettings';
import Content, {RequiredContentFields} from '../../../components/storefront-content/content/Content';
import Media, {RequiredMediaFields} from '../../../components/storefront-content/media/Media';
import Regionalization from '../../../components/storefront-content/regionalization/Regionalization';
import useGetIsTextRequired from '../../../components/storefront-content/content/utilities/useGetIsTextRequired';
import useGetIsCreatedAfter from '../../../components/storefront-content/content/utilities/useGetIsCreatedAfter';
import {CONTENT_TABS_REQUIRED_SINCE_DATE} from '@scm/components/utils/common';
import Limitations from '../../../components/storefront-content/limitations/Limitations';

enum PanelMenuItems {
  'General Information' = 'General Information',
  'Ordering Settings' = 'Ordering Settings',
  'Content' = 'Content',
  'Media' = 'Media',
  'Regionalization' = 'Regionalization',
  'SC Code & EPR Limitation' = 'SC Code & EPR Limitation',
}

const ProductForm = () => {
  const {redAppTypeC, provisioningTypeC} = useGetProductC();
  const isRegularApp = redAppTypeC === CcrzEProductCSObjectRedAppTypeCEnum.Regular;
  const {isInternalProvider} = useContext(InternalProviderContext);
  const isAllowedToViewScCodeEprLimitation = isAllowedToViewLimitation() || !!isInternalProvider?.isInternalProvider;
  const [currentPanel, setCurrentPanel] = useState(PanelMenuItems['General Information']);
  const panelMenuLinkHandler: MouseEventHandler<HTMLAnchorElement> = event => {
    setCurrentPanel(PanelMenuItems[(event.target as HTMLAnchorElement).textContent as keyof typeof PanelMenuItems]);
  };

  const isRegularAndPublic =
    redAppTypeC === CcrzEProductCSObjectRedAppTypeCEnum.Regular && provisioningTypeC === 'Public';
  const isTextRequired = useGetIsTextRequired() && useGetIsCreatedAfter(CONTENT_TABS_REQUIRED_SINCE_DATE);

  const requiredContentFields: RequiredContentFields = {
    description: isRegularAndPublic,
    shortDescription: isRegularAndPublic,
    termsAndConditions: isRegularAndPublic,
    keyBenefits: isTextRequired && isRegularAndPublic,
    features: isTextRequired && isRegularAndPublic,
  };

  const requiredMediaFields: RequiredMediaFields = {
    screenshots: isRegularAndPublic,
  };

  const createContent = () => {
    switch (currentPanel) {
      case PanelMenuItems['General Information']:
        return <GeneralInformation productNameTooltipValue="productDetails.name.info" />;
      case PanelMenuItems['Ordering Settings']:
        return <OrderingSettings />;
      case PanelMenuItems.Content:
        return <Content requiredFields={requiredContentFields} />;
      case PanelMenuItems.Media:
        return <Media requiredFields={requiredMediaFields} />;
      case PanelMenuItems.Regionalization:
        return <Regionalization />;
      case PanelMenuItems['SC Code & EPR Limitation']:
        return <Limitations />;
    }
  };

  return (
    <div className="spark-pad-l-2 spark-pad-r-2 spark-pad-b-2">
      <SectionContainer className="spark-flex">
        <MenuContainer>
          <PanelMenu
            labels={Object.keys(PanelMenuItems).map(
              panelMenuItem => PanelMenuItems[panelMenuItem as keyof typeof PanelMenuItems]
            )}
            onClick={panelMenuLinkHandler}
            isAllowedToViewLimitation={isAllowedToViewScCodeEprLimitation}
            isRegularApp={isRegularApp}
          />
        </MenuContainer>
        <ContentContainer>{createContent()}</ContentContainer>
      </SectionContainer>
    </div>
  );
};

export const SectionContainer = styled.div`
  background-color: ${colors.white};
  border-top: 2px solid ${colors.grey150};
  @media (max-width: ${deviceWidths.xs}px) {
    flex-direction: column;
  }
  min-height: 33.33rem;
`;

export const MenuContainer = styled.div`
  flex-basis: 20%;
  border-right: 2px solid ${colors.grey150};
`;

export const ContentContainer = styled.div`
  flex-basis: 80%;
  padding: 2rem;
  font-weight: 400;
  border: none;
  overflow: auto;
`;

export default ProductForm;
