import styled from 'styled-components';

export const AdditionalText = styled.p`
  padding-left: 4rem;
  transform: translateY(-1rem);
`;

export const HeaderContainer = styled.div`
  transform: translateY(2rem);
`;
