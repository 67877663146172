import React, {useEffect, useRef, useState} from 'react';
import {Button, Message, Modal} from '@sabre/spark-react-core';
import {ButtonSize, MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import parse from 'html-react-parser';
import FreezeTable from './FreezeTable';
import {Calendar, dateFnsLocalizer} from 'react-big-calendar';
import {format, getDay, startOfWeek} from 'date-fns';
import {enUS} from 'date-fns/locale';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import color from '@scm/components/assets/colors';
import {Loading} from '@scm/components';
import ErrorMessage from './ErrorMessage';
import NoData from './NoData';
import {
  Configuration as ConfigurationFreezeCalendar,
  Environment,
  FreezeCalendarEntriesApi,
} from '@scm/product-components/generated/certification';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import AddNewFreezeForm from './AddNewFreezeForm';
import {freezeCalendarMapper, IFreezeCalendar} from './freezeCalendarMapper';
import {HeaderText} from '../../components/App';
import FreezeCalendarDeleteModal from './FreezeCalendarDeleteModal';

interface Event {
  title: string;
  start: Date;
  end: Date;
}

const FreezeCalendar = () => {
  const [open, setOpen] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [isError] = useState(false);
  const [isEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [freezeCalendars, setFreezeCalendars] = useState<IFreezeCalendar[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentFreezeId, setCurrentFreezeId] = useState('');
  const calendarRef = useRef(null);

  useEffect(() => {
    if (openCalendar) {
      const calendar = calendarRef.current;
      const calendarButtons = calendar.querySelectorAll('button');
      calendarButtons.forEach(button => {
        if (button.textContent.trim() === 'Day') {
          button.setAttribute('aria-label', 'Click to see day view');
        }
      });
    }
  }, [openCalendar]);

  useEffect(() => {
    if (openCalendar) {
      setTimeout(() => {
        const calendar = calendarRef.current;
        const calendarButtons = calendar.querySelectorAll('.rbc-button-link');
        calendarButtons.forEach(button => {
          console.log(button);
          button.setAttribute('role', 'link');
          button.setAttribute('aria-label', `Open day plan for ${button.textContent}`);
        });
      }, 1000);
    }
  }, [openCalendar]);

  const fetchFreezeCalendars = async () =>
    await new FreezeCalendarEntriesApi(
      new ConfigurationFreezeCalendar({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() ?? '',
      })
    ).getFreezeCalendarEntries();

  useEffect(() => {
    if (isLoading) {
      fetchFreezeCalendars()
        .then(freezeCalendarEntries => {
          freezeCalendarEntries.sort((a, b) => a.startDateTime.getDate() - b.startDateTime.getDate());
          setFreezeCalendars(freezeCalendarMapper(freezeCalendarEntries));
          setIsDataAvailable(true);
        })
        .catch(function () {
          //do nothing
        })
        .finally(() => setIsLoading(false));
    }
  }, [isLoading]);

  const handleClose = () => {
    setIsLoading(true);
    setOpen(false);
  };
  const handleCloseCalendar = () => setOpenCalendar(false);
  const handleOpen = () => setOpen(true);
  const handleOpenCalendar = () => setOpenCalendar(true);

  const {formatMessage} = useIntl();
  const addNewFormId = 'addNewForm';
  const calendarId = 'calendarModal';
  const headers = [
    formatMessage({id: 'freezeCalendar.addForm.environment.label'}),
    formatMessage({id: 'freezeCalendar.addForm.startDate.label'}),
    formatMessage({id: 'freezeCalendar.addForm.endDate.label'}),
    formatMessage({id: 'freezeCalendar.addForm.comment.header'}),
  ];

  const openFormModalButton = (isCentered = true) => (
    <Button
      id={addNewFormId}
      onClick={handleOpen}
      className={`${isCentered ? 'spark-center-block' : ''} spark-mar-b-2`}
      size="sm"
    >
      <FormattedMessage id="freezeCalendar.addButton.label" />
    </Button>
  );

  const createEvents: () => Array<Event> = () => {
    const result: Array<Event> = [];
    if (isDataAvailable) {
      freezeCalendars.forEach(item => {
        if (item.environments?.includes(Environment.Prod) && item.environments?.includes(Environment.Cert)) {
          result.push({
            title: Environment.Prod,
            start: item.startDateTime ?? new Date(),
            end: item.endDateTime ?? new Date(),
          });
          result.push({
            title: Environment.Cert,
            start: item.startDateTime ?? new Date(),
            end: item.endDateTime ?? new Date(),
          });
        } else if (item.environments?.length === 1) {
          result.push({
            title: item.environments[0],
            start: item.startDateTime ?? new Date(),
            end: item.endDateTime ?? new Date(),
          });
        }
      });
    }
    return result;
  };

  const locales = {
    'en-US': enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const CalendarContent = () => (
    <>
      <Message
        content={parse(formatMessage({id: 'freezeCalendar.datesWarning.content'})) as string}
        role={MessageRole.ALERT}
        status={MessageStatus.WARNING}
        type={MessageType.CONTAINER}
        className="spark-mar-b-2"
      />
      {openFormModalButton(false)}
      <Button id={calendarId} onClick={handleOpenCalendar} size={ButtonSize.SMALL} className="spark-mar-l-1">
        <FormattedMessage id="freezeCalendar.calendar.button" />
      </Button>
      <FreezeTable
        headers={headers}
        freezeData={freezeCalendars}
        addNewFormId={addNewFormId}
        setDeleteModalOpen={setDeleteModalOpen}
        setCurrentFreezeId={setCurrentFreezeId}
      />
      <Modal
        onClose={handleCloseCalendar}
        open={openCalendar}
        title={formatMessage({id: 'freezeCalendar.calendar.title'})}
        titleHeadingLevel={2}
      >
        <CalendarContainer ref={calendarRef} className="spark-pad-t-2">
          <Calendar localizer={localizer} events={createEvents()} />
        </CalendarContainer>
        <ul className="spark-list--no-bullets">
          <li className="spark-list-item--no-bullet">
            <LegendColorSquare color={colors.tierGold} className="spark-mar-r-1" />
            <FormattedMessage id="freezeCalendar.calendar.certLabel" />
          </li>
          <li className="spark-list-item--no-bullet">
            <LegendColorSquare color={colors.primaryActionGreen} className="spark-mar-r-1" />
            <FormattedMessage id="freezeCalendar.calendar.prodLabel" />
          </li>
        </ul>
      </Modal>
      <FreezeCalendarDeleteModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        freezeCalendarId={currentFreezeId}
        setIsLoading={setIsLoading}
      />
    </>
  );

  const createFreezeCalendarContent = () => {
    if (isLoading) {
      return (
        <LoadingContainer>
          <Loading label={formatMessage({id: 'common.data.loading'})} />
        </LoadingContainer>
      );
    } else if (isDataAvailable) {
      return <CalendarContent />;
    } else if (isEmpty) {
      return <NoData openFormModalButton={openFormModalButton} />;
    } else if (isError) {
      return <ErrorMessage />;
    }
  };

  return (
    <>
      <HeaderText className="spark-mar-2">
        <FormattedMessage id="freezeCalendar.title" />
      </HeaderText>
      <div className="spark-pad-2">{createFreezeCalendarContent()}</div>
      <Modal
        onClose={handleClose}
        open={open}
        title={formatMessage({id: 'freezeCalendar.addForm.title'})}
        titleHeadingLevel={2}
      >
        <AddNewFreezeForm onClose={handleClose} />
      </Modal>
    </>
  );
};

const LegendColorSquare = styled.div<{color: string}>`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-color: ${props => props.color};
`;

const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;

  & .rbc-calendar {
    height: 41.66rem;
    background-color: ${colors.white};
    flex-grow: 1;
  }

  & .rbc-event {
    padding: 0;
    background-color: ${colors.primaryActionGreen};
  }

  & .rbc-event-content {
    padding: 0.17rem 0.42rem;
  }

  & .rbc-event-content[title=${Environment.Cert}] {
    background-color: ${colors.tierGold};
    border-radius: 0.42rem;
  }

  & .rbc-button-link {
    color: ${color.disabledGrey};
  }

  & .rbc-off-range-bg {
    background-color: ${color.grey100};
  }

  & [role='columnheader'] {
    color: ${colors.black};
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 5vh 0;
`;

export default FreezeCalendar;
