import {useForm} from 'react-hook-form';
import {SellerDeveloper} from '../../generated/management';
import {yupResolver} from '@hookform/resolvers/yup';
import {assignDeveloperSchema} from '../../schemas/assignDeveloperSchema';
import PccEprInput from './form-fields/PccEprInput';
import Permissions from './form-fields/Permissions';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import SubmitButton from './form-fields/SubmitButton';
import useIfSellerIsAssigned from './useIfSellerIsAssigned';
import colors from '@scm/components/assets/colors';

const initialValues = {
  pcc: '',
  epr: '',
  canManageCompany: false,
  canSubmitProposal: false,
  canAccessAllProducts: false,
};

const AssignForm = ({handleClose}: {handleClose: () => void}) => {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: {errors, isValid},
  } = useForm<SellerDeveloper>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(assignDeveloperSchema),
  });

  const currentState = watch();
  const isAssigned = useIfSellerIsAssigned(currentState);

  const close = () => {
    reset();
    handleClose();
  };

  return (
    <form onSubmit={event => event.preventDefault()}>
      <div className="row spark-pad-t-1">
        <PccEprInput name="pcc" control={control} errors={errors} />
        <PccEprInput name="epr" control={control} errors={errors} />
      </div>
      <div className="row spark-mar-t-2 spark-pad-t-1">
        <Permissions name="canAccessAllProducts" control={control} />
        <Permissions name="canSubmitProposal" control={control} />
        <Permissions name="canManageCompany" control={control} />
      </div>
      <ErrorMessage className="spark-mar-t-1 spark-mar-b-1 spark-text-right" isVisible={isAssigned}>
        <FormattedMessage id="assignSeller.companyLevel.developerAssigned" />
      </ErrorMessage>
      <ButtonContainer className="row spark-mar-t-2">
        <Button type="button" secondary onClick={close} size={ButtonSize.SMALL} className="spark-mar-r-1">
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <SubmitButton isValid={isValid} close={close} handleSubmit={handleSubmit} isAssigned={isAssigned} />
      </ButtonContainer>
    </form>
  );
};

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ErrorMessage = styled.p<{isVisible: boolean}>`
  color: ${colors.red};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;

export default AssignForm;
