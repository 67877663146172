/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Onboardings
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DistributionType } from './DistributionType';
import {
    DistributionTypeFromJSON,
    DistributionTypeFromJSONTyped,
    DistributionTypeToJSON,
} from './DistributionType';
import type { RegionType } from './RegionType';
import {
    RegionTypeFromJSON,
    RegionTypeFromJSONTyped,
    RegionTypeToJSON,
} from './RegionType';
import type { TechnicalSupportType } from './TechnicalSupportType';
import {
    TechnicalSupportTypeFromJSON,
    TechnicalSupportTypeFromJSONTyped,
    TechnicalSupportTypeToJSON,
} from './TechnicalSupportType';

/**
 * 
 * @export
 * @interface PartnerPricingDetails
 */
export interface PartnerPricingDetails {
    /**
     * 
     * @type {TechnicalSupportType}
     * @memberof PartnerPricingDetails
     */
    technicalSupport: TechnicalSupportType;
    /**
     * 
     * @type {DistributionType}
     * @memberof PartnerPricingDetails
     */
    distributionType: DistributionType;
    /**
     * 
     * @type {Array<RegionType>}
     * @memberof PartnerPricingDetails
     */
    regions?: Array<RegionType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartnerPricingDetails
     */
    countryCodes?: Array<string>;
}

/**
 * Check if a given object implements the PartnerPricingDetails interface.
 */
export function instanceOfPartnerPricingDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "technicalSupport" in value;
    isInstance = isInstance && "distributionType" in value;

    return isInstance;
}

export function PartnerPricingDetailsFromJSON(json: any): PartnerPricingDetails {
    return PartnerPricingDetailsFromJSONTyped(json, false);
}

export function PartnerPricingDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerPricingDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'technicalSupport': TechnicalSupportTypeFromJSON(json['technicalSupport']),
        'distributionType': DistributionTypeFromJSON(json['distributionType']),
        'regions': !exists(json, 'regions') ? undefined : ((json['regions'] as Array<any>).map(RegionTypeFromJSON)),
        'countryCodes': !exists(json, 'countryCodes') ? undefined : json['countryCodes'],
    };
}

export function PartnerPricingDetailsToJSON(value?: PartnerPricingDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'technicalSupport': TechnicalSupportTypeToJSON(value.technicalSupport),
        'distributionType': DistributionTypeToJSON(value.distributionType),
        'regions': value.regions === undefined ? undefined : ((value.regions as Array<any>).map(RegionTypeToJSON)),
        'countryCodes': value.countryCodes,
    };
}

