import {useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {
  activeListeningFilesNames,
  activeListeningName,
  hiddenUploadedScribeScriptFilesNames,
  hiddenUploadedScribeScriptName,
  resourcesFilesNames,
  resourcesName,
  ScribeScriptsValues,
  uploadedScribeScriptFilesNames,
  uploadedScribeScriptName,
} from '@scm/scribe-scripts/provider/ScribeScriptsValues';
import {initialValues} from '@scm/scribe-scripts/provider/scribeScriptsConstants';
import {yupResolver} from '@hookform/resolvers/yup';
import {scribeScriptsSchema} from '@scm/scribe-scripts/schema/scribeScriptsSchema';
import ScribeScriptUpload from '@scm/scribe-scripts/definitionPage/definitionComponents/ScribeScriptUpload';
import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import styled from 'styled-components';
import {AnyObjectSchema} from 'yup';
import useGetSubmit from './useGetSubmit';

enum ButtonValues {
  Cancel = 'Cancel',
  Submit = 'Submit',
}

const CreateScribeBundle = ({
  handleClose,
  setLoadingProductDetails,
}: {
  handleClose: () => void;
  setLoadingProductDetails: (value: boolean) => void;
}) => {
  const {
    watch,
    setValue,
    getValues,
    formState: {isValid},
  } = useForm<ScribeScriptsValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(scribeScriptsSchema as unknown as AnyObjectSchema),
  });

  const formProps = useMemo(() => ({watch, setValue}), []);

  const onSubmit = useGetSubmit({handleClose, setLoadingProductDetails});

  return (
    <>
      <div className="spark-panel spark-pad-l-2">
        <ScribeScriptUpload
          id="definition.scribeScripts.fileUpload.label"
          filesNames={uploadedScribeScriptFilesNames}
          fileType="scribeScripts"
          name={uploadedScribeScriptName}
          {...formProps}
        />
        <ScribeScriptUpload
          id="definition.hiddenScribeScripts.fileUpload.label"
          filesNames={hiddenUploadedScribeScriptFilesNames}
          fileType="hiddenScribeScripts"
          name={hiddenUploadedScribeScriptName}
          radioButton
          {...formProps}
        />
        <ScribeScriptUpload
          id="definition.resources.fileUpload.label"
          filesNames={resourcesFilesNames}
          fileType="resources"
          name={resourcesName}
          radioButton
          {...formProps}
        />
        <ScribeScriptUpload
          id="definition.activeListening.fileUpload.label"
          filesNames={activeListeningFilesNames}
          fileType="activeListening"
          name={activeListeningName}
          radioButton
          {...formProps}
        />
      </div>
      <ButtonContainer className="spark-flex spark-mar-t-2">
        <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
          {ButtonValues.Cancel}
        </Button>
        <Button
          progress
          type="button"
          size={ButtonSize.SMALL}
          onClick={async (_, success, failure) => {
            if (success && failure) {
              await onSubmit(getValues(), success, failure);
            }
          }}
          disabled={!isValid}
          tabIndex={0}
        >
          {ButtonValues.Submit}
        </Button>
      </ButtonContainer>
    </>
  );
};

const ButtonContainer = styled.div`
  justify-content: flex-end;
`;

export default CreateScribeBundle;
