import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import colors from '../assets/colors';
import InfoContent, {boldFont, TextItems} from './InfoContent';

const DoubleInfoTable = ({
  publicTextItems,
  privateTextItems,
}: {
  publicTextItems: TextItems;
  privateTextItems: TextItems;
}) => {
  return (
    <Table className="spark-mar-2" role="presentation">
      <thead>
        <tr>
          <th>
            <ColumnHeader className="spark-text-center spark-mar-b-0">
              <FormattedMessage id="onboarding.info.public.title" />
            </ColumnHeader>
          </th>
          <th>
            <ColumnHeader className="spark-text-center spark-mar-b-0">
              <FormattedMessage id="onboarding.info.private.title" />
            </ColumnHeader>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <InfoContent textItems={publicTextItems} />
          </td>
          <td>
            <InfoContent textItems={privateTextItems} />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;

  & td,
  th {
    border: 1px solid ${colors.grey300};
    padding: 2rem;
    vertical-align: baseline !important;
  }
`;

const ColumnHeader = styled.h4`
  ${boldFont}
`;

export default DoubleInfoTable;
