import {Message} from '@sabre/spark-react-core';

export enum CustomMessageTypes {
  ERROR = 'Error',
  SUCCESS = 'Success',
}

const CustomMessageContainer = ({type, content}: {type: CustomMessageTypes; content: string}) => (
  <div className="col-xs-12 row spark-mar-0 spark-pad-0" data-testid="limitationMessage">
    <Message animate content={content} role="alert" status={type.toLowerCase()} title={type} type="container" />
  </div>
);

export default CustomMessageContainer;
