import React from 'react';
import {useFormContext} from 'react-hook-form';
import styled from 'styled-components';

const RadioButtons = ({
  name,
  labels,
  values,
  isInline,
  checked,
  onClick,
  disabled,
}: {
  name: string;
  labels: Array<string | JSX.Element[] | JSX.Element>;
  values: Array<string>;
  isInline?: boolean;
  checked?: boolean;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}) => {
  const {register} = useFormContext();

  return (
    <div className="row">
      {values.map((value, index) => (
        <label className={`col-xs-${isInline ? 12 / values.length : 12} spark-radio`} key={index}>
          <input
            type="radio"
            className="spark-radio__input"
            {...register(name)}
            value={value}
            checked={checked}
            onClick={onClick}
            disabled={disabled}
          />
          <span className="spark-radio__box" />
          <SparkLabel className="spark-label">{labels[index] ?? labels[0]}</SparkLabel>
        </label>
      ))}
    </div>
  );
};

const SparkLabel = styled.span`
  & strong {
    display: inline-block;
    width: 100%;
  }
`;

export default RadioButtons;
