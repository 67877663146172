import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import useGetProductC from '../../../hooks/useGetProductC';
import React, {useContext, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {
  blockOrderingDescriptionName,
  blockOrderingName,
  hideProductName,
  isPaidName,
  provisioningTypeName,
  typeName,
} from '@scm/product-components/pages/storefrontData/interfaces/AppInfoDataValues';
import {CertificationProductContext} from '../../../provider/CertificationProductProvider';
import {Configuration, ProductsApi, SetCertCmConfigVersionRequest} from '../../../generated/certification';
import {apiBaseLink, certificationApiBaseLink} from '../../../assets/apiBaseLink';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {useParams} from 'react-router-dom';
import {ErrorStatuses} from '../../../error/ErrorStatuses';
import {ProductContext} from '../../../provider/ProductProvider';
import {VersionContext} from '../../../provider/VersionProvider';
import {Button} from '@sabre/spark-react-core';
import {Configuration as ProductConfiguration, Product, ProductApi} from '../../../generated/product';
import PrimaryConfiguration from '@scm/product-components/pages/productDetails/productTabs/redAppDetails/PrimaryConfiguration';
import StorefrontVisibility from '@scm/product-components/pages/productDetails/productTabs/redAppDetails/StorefrontVisibility';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const updateProduct = async (id: string, product: Product) =>
  new ProductApi(
    new ProductConfiguration({
      basePath: apiBaseLink,
      accessToken: getAccessToken() ?? '',
    })
  ).patchProduct({sku: id, product});

const RedAppDetails = () => {
  const productDetailsName = 'productDetails.fetch';

  const {
    redAppTypeC,
    provisioningTypeC,
    sabrePricingTypeC,
    sabreHideProductC,
    blockOrderingC,
    descriptionWhenOrderingBlockedR,
  } = useGetProductC();
  const isPaidInit = sabrePricingTypeC;
  const {certificationProduct} = useContext(CertificationProductContext);
  const {cmCertVersion} = certificationProduct;
  const [isSaveActive, setIsSaveActive] = useState(false);
  const {formatMessage} = useIntl();
  const [isProvisioningTypeDisabled, setIsProvisioningTypeDisabled] = useState(false);
  const [isPricingTypeDisabled, setIsPricingTypeDisabled] = useState(false);

  const [appTypeName, setAppTypeName] = useState(redAppTypeC);
  const [provisioningType, setProvisioningType] = useState(provisioningTypeC);
  const [isPaid, setIsPaid] = useState(isPaidInit);
  const [hideProduct, setHideProduct] = useState(sabreHideProductC ? Confirmation.Yes : Confirmation.No);
  const [blockOrdering, setBlockOrdering] = useState(blockOrderingC ? Confirmation.Yes : Confirmation.No);
  const [versionName, setVersionName] = useState(cmCertVersion);
  const [blockOrderingDescription, setBlockOrderingDescription] = useState(
    descriptionWhenOrderingBlockedR?.ccrzValueRTC
  );

  useEffect(() => {
    const isProvisioningTypeDisabled = appTypeName !== 'Regular';
    const isPricingTypeDisabled = isProvisioningTypeDisabled || provisioningType === 'Private';
    setIsProvisioningTypeDisabled(isProvisioningTypeDisabled);
    setIsPricingTypeDisabled(isPricingTypeDisabled);
    isPricingTypeDisabled && setIsPaid('Free');
    isProvisioningTypeDisabled && setProvisioningType('Private');

    if (appTypeName === redAppTypeC && provisioningType === provisioningTypeC && isPaid === isPaidInit) {
      setIsSaveActive(false);
    }
  }, [provisioningType, appTypeName, isPaid, isPricingTypeDisabled, isProvisioningTypeDisabled]);

  const changeVersionNameHandler = (value: string) => {
    setVersionName(value);
    setIsSaveActive(cmCertVersion !== value);
  };

  //  TODO: Fix type script typing
  const changeHandler = (fieldName: string, newValue: string) => {
    switch (fieldName) {
      case typeName:
        setAppTypeName(newValue as typeof appTypeName);
        break;
      case provisioningTypeName:
        setProvisioningType(newValue as typeof provisioningType);
        break;
      case isPaidName:
        setIsPaid(newValue as typeof isPaid);
        break;
      case hideProductName:
        setHideProduct(newValue as typeof hideProduct);
        break;
      case blockOrderingName:
        setBlockOrdering(newValue as typeof blockOrdering);
        break;
      case blockOrderingDescriptionName:
        setBlockOrderingDescription(newValue);
        break;
      default:
        throw new Error('Action does not exist');
    }
    setIsSaveActive(true);
  };

  const setCertCmConfigVersion = async (setCertCmConfigVersionRequest: SetCertCmConfigVersionRequest) =>
    await new ProductsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() || '',
        middleware: middleware,
      })
    ).setCertCmConfigVersion(setCertCmConfigVersionRequest);

  const sku = useParams().appName || '';
  const {setLoading} = useContext(ProductContext);
  const {setLoading: setLoadingVersion} = useContext(VersionContext);
  const {setLoading: setCertificationProductLoading} = useContext(CertificationProductContext);

  const reload = () => {
    setLoading(true);
    setLoadingVersion(true);
    setCertificationProductLoading(true);
  };

  const setCertCmConfigVersionForProduct = async () => {
    const setCertCmConfigVersionRequest = {
      id: sku,
      versionName: versionName ?? '',
    };

    try {
      await setCertCmConfigVersion(setCertCmConfigVersionRequest);
      openToast(
        formatMessage({id: 'productDetails.fetch.successTitle'}),
        formatMessage({id: 'productDetails.certTesters.setNewVersionName.success'}),
        ToastType.POSITIVE,
        'spark-icon-check'
      );
    } catch (err) {
      err.response.json().catch(() => {});
      if (err.response.status === ErrorStatuses['Unprocessable Content']) {
        openToast(
          createMessageString(formatMessage, productDetailsName, true, true),
          formatMessage({id: 'productDetails.certTesters.setNewVersionName.error'}),
          ToastType.WARNING,
          'spark-icon-alert-triangle'
        );
      }
      reload();
    }
  };

  const submitProduct = async () => {
    const productRequest: Product = {
      ccrzEProductC: {
        redAppTypeC: appTypeName,
        provisioningTypeC: provisioningType,
        sabrePricingTypeC: isPaid,
        sabreHideProductC: hideProduct === Confirmation.Yes,
        blockOrderingC: blockOrdering === Confirmation.Yes,
        descriptionWhenOrderingBlockedR: {
          ccrzValueRTC: blockOrderingDescription,
        },
      },
    };

    openToast(
      createMessageString(formatMessage, productDetailsName, true),
      createMessageString(formatMessage, productDetailsName),
      ToastType.POSITIVE,
      'spark-icon-check'
    );
    try {
      await updateProduct(sku, productRequest);
    } catch (_) {
      openToast(
        createMessageString(formatMessage, productDetailsName, true, true),
        createMessageString(formatMessage, productDetailsName, false, true),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
      reload();
    }
  };

  return (
    <div>
      <RedAppDetailsHeader>
        <SaveButtonContainer>
          <Button
            progress
            onClick={async (e, success, failure) => {
              if (success && failure) {
                await setCertCmConfigVersionForProduct();
                await submitProduct();
                success();
                reload();
              }
            }}
            size={ButtonSize.SMALL}
            disabled={!isSaveActive}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </SaveButtonContainer>
      </RedAppDetailsHeader>
      <Form className="spark-mar-l-.5">
        <PrimaryConfiguration
          appTypeName={appTypeName || ''}
          provisioningType={provisioningType || ''}
          changeHandler={changeHandler}
          changeVersionNameHandler={changeVersionNameHandler}
          versionName={versionName}
          isPricingTypeDisabled={isPricingTypeDisabled}
          isProvisioningTypeDisabled={isProvisioningTypeDisabled}
          isPaid={isPaid}
        />
        <StorefrontVisibility
          blockOrdering={blockOrdering}
          blockOrderingDescription={blockOrderingDescription}
          hideProduct={hideProduct}
          changeHandler={changeHandler}
        />
      </Form>
    </div>
  );
};

const RedAppDetailsHeader = styled.div`
  position: relative;
`;

const SaveButtonContainer = styled.div`
  position: absolute;
  right: 6rem;
  top: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const Form = styled.form`
  width: 90%;
  margin: 0 auto;
  p.spark-pad-t-1-5 {
    padding-top: 1.5rem;
  }
  @media (max-width: ${deviceWidths.md}px) {
    p {
      padding-bottom: 0;
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
  }
`;

export default RedAppDetails;
