export enum Confirmation {
  'Yes' = 'Yes',
  'No' = 'No',
}

export enum AccountingDataType {
  'Accounting data' = 'Accounting data',
  'Back-office data' = 'Back-office data',
}

export const sabreServicesName = 'sabreServices';
export const hostCommandsDescriptionName = 'hostCommandsDescription';
export const bargainFinderMaxName = 'bargainFinderMax';
export const externalServicesName = 'externalServices';
export const externalServicesDescriptionName = 'externalServicesDescription';
export const utilizedMethodName = 'utilizedMethod';
export const newSabreRedTechnologiesName = 'newSabreRedTechnologies';
export const newSabreRedTechnologiesDescriptionName = 'newSabreRedTechnologiesDescription';
export const financialInformationName = 'financialInformation';
export const pciDataName = 'pciData';
export const pciCertifiedName = 'pciCertified';
export const pciAdditionalDetailsName = 'pciAdditionalDetails';
export const panRenderedName = 'panRendered';
export const retentionPeriodName = 'retentionPeriod';
export const dataAdminName = 'dataAdmin';
export const personalInformationName = 'personalInformation';
export const piiDataName = 'piiData';
export const dataForwardedName = 'dataForwarded';
export const euDataName = 'euData';
export const gdprCompliantName = 'gdprCompliant';
export const piiDataRetentionName = 'piiDataRetention';
export const piiDataRetentionAdminName = 'piiDataRetentionAdmin';
export const localDataName = 'localData';
export const thirdPartyNetworkName = 'thirdPartyNetwork';
export const dataForwardedThirdPartyName = 'dataForwardedThirdParty';
export const protocolName = 'protocol';
export const dataProtectionName = 'dataProtection';
export const coreDataName = 'coreData';
export const freeTextRemarksName = 'freeTextRemarks';
export const freeTextRemarksDescriptionName = 'freeTextRemarksDescription';
export const numberOfTransactionName = 'numberOfTransaction';
export const alignSecureCodingPracticesName = 'alignSecureCodingPractices';
export const patchTimelineName = 'patchTimeline';
export const accountingName = 'accounting';
export const accountingDataTypeName = 'dataType';
export const accountingAttributesDescriptionName = 'attributesDescription';

export interface ServiceAndSecurityValues {
  [sabreServicesName]: boolean;
  [hostCommandsDescriptionName]: string;
  [bargainFinderMaxName]: string;
  [externalServicesName]: boolean;
  [externalServicesDescriptionName]: string;
  [utilizedMethodName]: string;
  [newSabreRedTechnologiesName]: boolean;
  [newSabreRedTechnologiesDescriptionName]: string;
  [financialInformationName]: boolean;
  [pciDataName]: Array<string>;
  [pciCertifiedName]: string;
  [pciAdditionalDetailsName]: string;
  [panRenderedName]: string;
  [retentionPeriodName]: string;
  [dataAdminName]: string;
  [personalInformationName]: boolean;
  [piiDataName]: Array<string>;
  [dataForwardedName]: string;
  [euDataName]: string;
  [gdprCompliantName]: string;
  [piiDataRetentionName]: string;
  [piiDataRetentionAdminName]: string;
  [localDataName]: string;
  [thirdPartyNetworkName]: boolean;
  [dataForwardedThirdPartyName]: string;
  [protocolName]: string;
  [dataProtectionName]: string;
  [coreDataName]: boolean;
  [freeTextRemarksName]: string;
  [freeTextRemarksDescriptionName]: string;
  [numberOfTransactionName]: string;
  [alignSecureCodingPracticesName]: boolean;
  [patchTimelineName]: string;
  [accountingName]: boolean;
  [accountingDataTypeName]: Array<AccountingDataType>;
  [accountingAttributesDescriptionName]: string;
}
