import styled from 'styled-components';
import React, {useContext, useMemo} from 'react';
import VersionTabsHeader from './VersionTabsHeader';
import VersionTabsContent from './VersionTabsContent';
import {useParams} from 'react-router-dom';
import {VersionContext} from '../../provider/VersionProvider';
import {SupportIcon} from '@scm/components';
import {isSabreEmployeeRole} from '@scm/authentication/utils/authentication';
import AuditLogs from '@scm/admin-centre/src/components/auditLog/AuditLogs';
import {ObjectType} from '@scm/proposal/generated/audit';
import Certify from '../pages/certify/Certify';
import CleanupTab from '../pages/cleanup/CleanupTab';
import Svs from '../pages/svs/Svs';

export interface TabData {
  name: string;
  content: JSX.Element | null;
  icon?: JSX.Element;
  numberOfItems?: number;
  hideElement?: boolean;
}

const VersionTabs = () => {
  const {version = ''} = useParams();
  const {bundles, auditLogs, setLoadingProductDetails} = useContext(VersionContext);
  const latestVersion = bundles[0];
  const currentBundle = bundles.filter(bundle => bundle.name === version);
  const versionId = currentBundle[0]?.id || '';
  const isVersionPatch = !!currentBundle[0]?.isPatch;

  const supportTabs: Array<TabData> = useMemo(
    () => [
      {
        name: 'Certify',
        icon: <SupportIcon />,
        content: (
          <Certify
            chosenVersionId={versionId}
            setLoadingProductDetails={setLoadingProductDetails}
            isVersionPatch={isVersionPatch}
          />
        ),
      },
      {
        name: 'svs',
        icon: <SupportIcon />,
        content: <Svs chosenVersionId={versionId} hideButtons={versionId !== latestVersion?.id} />,
      },
      {
        name: 'Cleanup',
        icon: <SupportIcon />,
        content: <CleanupTab chosenVersionId={versionId} />,
      },
      {
        name: 'Audit Logs',
        icon: <SupportIcon />,
        hideElement: !isSabreEmployeeRole(),
        content: (
          <AuditLogs
            auditLogs={auditLogs}
            reloadForm={() => setLoadingProductDetails(true)}
            objectType={ObjectType.Version}
            id={versionId}
          />
        ),
      },
    ],
    [version, latestVersion, auditLogs, versionId]
  );

  return !!versionId ? (
    <div className="spark-mar-r-2 spark-mar-l-2 spark-mar-t-2">
      <Tabs className="spark-panel spark-mar-b-2">
        <div className="spark-panel__content">
          <VersionTabsHeader supportTabs={supportTabs} />
        </div>
        <VersionTabsContent supportTabs={supportTabs} />
      </Tabs>
    </div>
  ) : null;
};

const Tabs = styled.div`
  min-height: 470px;
  position: relative;
  border: none;
`;

export default VersionTabs;
