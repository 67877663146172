import React from 'react';
import styled from 'styled-components';
import CenterItems from 'components/styledComponents/CenterItems';
import parse from 'html-react-parser';
import limitCharacters from 'utils/limitCharacters';
import Logo from 'components/Logo';
import {useMediaQuery} from 'react-responsive';
import {minLargeScreen} from 'assets/deviceWidth';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';
import {useNavigate} from 'react-router-dom';

const HighlightedPartner = ({
  logoUrl,
  name,
  shortDescription,
  partnerLink,
}: {
  logoUrl: string;
  name: string;
  shortDescription: string;
  partnerLink: string;
}) => {
  const isMobile = useMediaQuery(maxMobileWidthQuery);
  const isTablet = useMediaQuery({query: `(max-width: ${minLargeScreen}px`});
  const navigate = useNavigate();

  return (
    <div>
      <Content mobile={isMobile} onClick={() => navigate(partnerLink)} isTablet={isTablet}>
        <CenterItems>
          <Logo logo={logoUrl} name={name} />
          <Text className={isMobile ? 'spark-delta' : 'spark-epsilon'}>
            {parse(limitCharacters(shortDescription, 120) || '')}
          </Text>
        </CenterItems>
      </Content>
    </div>
  );
};

const Content = styled.div.attrs(() => ({
  className: 'spark-carousel__panel spark-mar-t-0 spark-mar-b-0',
}))<{mobile?: boolean; isTablet?: boolean}>`
  height: ${props => (props.mobile ? '40vh' : '34vh')};
  min-height: 22rem;
  margin: ${props => (props.mobile ? '1rem 2rem !important;' : '1px 1.5rem !important;')};
  padding: ${props => (props.mobile ? '2rem 1.5rem !important' : '0 1.5rem !important;')};
  ${props => (props.mobile ? '' : 'width: 20vw;')};
  overflow-y: hidden;
  ${props => (props.isTablet ? 'width: 40vw;' : '')};

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.div.attrs(className => ({
  className: `${className}`,
}))`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export default HighlightedPartner;
