import styled from 'styled-components';
import colors from '../assets/colors';

export const Star = styled.span<{ starColor?: string; hasColon?: boolean; marginTop?: number }>`
  margin-top: ${props => (props.marginTop ? `${props.marginTop}px` : '0')};
  &:before {
    font-weight: bold;
    color: ${props => (props.starColor ? props.starColor : colors.red)};
    margin: 0 0.1rem 0 0.3rem;
    vertical-align: -2px;
    content: '*';
  }
  ${props =>
  props.hasColon
    ? ` &:after {
    vertical-align: ${props.marginTop ? `${props.marginTop + 1}px` : '0'};
    content: ':';
  }`
    : ''}
`;
