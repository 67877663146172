import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';

export const AsideContainer = styled.div`
  background-color: ${colors.grey900};
  width: 20rem;
  flex-shrink: 0;
  overflow: auto;
  @media (max-width: ${deviceWidths.xs}px) {
    display: none;
  }
`;
export const AsideMenuItem = styled.li`
  &&& .spark-menu__list-link {
    text-decoration: none;
    line-height: 200%;
    color: ${colors.white};
    font-weight: 300 !important;
    background-color: ${colors.grey900};
    white-space: nowrap;
  }

  & .spark-icon::before {
    transform: translateY(-3px);
  }

  & .disabled {
    cursor: not-allowed;
    color: ${colors.disabledMenuItem}!important;
  }

  && .spark-menu__list-link.active {
    background-color: ${colors.black};
    font-weight: 300;
  }

  && .spark-menu__list-link:is(:hover, :focus) {
    background-color: ${colors.highlightGrey};
  }
`;
