import {RoadmapStatus} from '../generated/roadmap';

export const subjectName = 'subject';
export const descriptionName = 'description';
export const releaseDateName = 'releaseDate';
export const statusName = 'status';

export interface RoadmapDataValues {
  [subjectName]: string;
  [descriptionName]: string;
  [releaseDateName]?: Date;
  [statusName]: RoadmapStatus;
}
