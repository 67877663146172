import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Maybe} from 'graphql/jsutils/Maybe';
import PartnerCardLabel from './PartnerCardLabel';

const MultiRowItem = ({
  messageId,
  values,
  className,
}: {
  messageId: string;
  values?: Maybe<string>[];
  className: string;
}) => (
  <div className={className}>
    <PartnerCardLabel className="spark-block">
      <FormattedMessage id={messageId} />
    </PartnerCardLabel>
    {values?.map((value, index) => (
      <div key={index}>{value}</div>
    ))}
  </div>
);

export default MultiRowItem;
