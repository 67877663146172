import ReactGA from 'react-ga4';
import {config} from 'config/config';

if (config.googleAnalyticsPn && config.googleAnalyticsPn.id.length) {
  ReactGA.initialize(config.googleAnalyticsPn.id, {
    gaOptions: {
      cookieDomain: 'none',
      cookieFlags: 'SameSite=None; Secure',
    },
  });
  ReactGA.set({anonymizeIp: true});
  ReactGA.set({allowAdFeatures: false});

  window.onerror = function (
    message: Event | string,
    source?: string,
    lineNumber?: number,
    columnNumber?: number,
    error?: Error
  ) {
    const errorMessage = `${error?.name} thrown from ${source} (${lineNumber}:${columnNumber}): ${message}`;
    ReactGA.event('exception', {description: errorMessage, fatal: true});
  };
}
