import React, {ReactElement, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {Button, Toggle, ToggleGroup} from '@sabre/spark-react-core';
import {FormattedMessage, useIntl} from 'react-intl';
import {DatePicker, Loading} from '@scm/components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {certificationApiBaseLink} from '@scm/product-components/assets/apiBaseLink';
import {getAccessToken, isSabreAdminRole} from '@scm/authentication/utils/authentication';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {format, isValid} from 'date-fns';
import {dateFormat} from '@scm/components/utils/common';
import ShortUniqueId from 'short-unique-id';
import {useNavigate, useParams} from 'react-router-dom';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {LoadingContainer} from '@scm/authentication/components/Login';
import ConfirmRemovalModal from './ConfirmRemovalModal';
import {CleanupConfiguration, Configuration, VersionsApi} from '../../../generated/certification';
import {Status} from '@scm/product-components/generated/certification';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

const saveConfiguration = async (id: string, cleanupConfigurationRequest: CleanupConfiguration) =>
  new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).createCleanupConfiguration({id, cleanupConfigurationRequest});

const deleteVersion = async (id: string) =>
  new VersionsApi(
    new Configuration({
      basePath: certificationApiBaseLink,
      accessToken: getAccessToken() as string,
      middleware: middleware,
    })
  ).deleteVersion({id});

const CleanupForm = ({
  id,
  cleanup,
  isProd,
  status,
}: {
  id: string;
  cleanup?: CleanupConfiguration;
  isProd?: boolean;
  status?: string;
}) => {
  const {appName} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const notifyBuyersName = 'notifyBuyers';
  const onHoldName = 'onHold';
  const sabreRedConfigurationRemovedName = 'sabreRedConfigurationRemoved';
  const sabreRedConfigurationRemovalDateName = 'sabreRedConfigurationRemovalDate';
  const versionRemovalDateName = 'versionRemovalDate';
  const {handleSubmit, setValue, watch, control} = useForm<CleanupConfiguration>({
    defaultValues: cleanup || {},
    mode: 'onChange',
  });

  const cleanupId = 'cleanup';
  const cleanupDeleteId = 'cleanup.delete';
  const handleSave = async (data: CleanupConfiguration) => {
    try {
      await saveConfiguration(id, {
        sabreRedConfigurationRemovalDate:
          data.sabreRedConfigurationRemovalDate !== undefined
            ? new Date(data.sabreRedConfigurationRemovalDate as unknown as string)
            : undefined,
        versionRemovalDate:
          data.versionRemovalDate !== undefined ? new Date(data.versionRemovalDate as unknown as string) : undefined,
        notifyBuyers: data.notifyBuyers,
        sabreRedConfigurationRemoved: data.sabreRedConfigurationRemoved,
        onHold: data.onHold,
      });

      openToast(
        createMessageString(formatMessage, cleanupId, true, false),
        createMessageString(formatMessage, cleanupId, false, false),
        ToastType.POSITIVE,
        'spark-icon-check'
      );
    } catch (e) {
      openToast(
        createMessageString(formatMessage, cleanupId, true, true),
        createMessageString(formatMessage, cleanupId, false, true),
        ToastType.WARNING,
        'spark-icon-alert-triangle',
        true
      );
    }
  };
  const onSubmit: SubmitHandler<CleanupConfiguration> = data => handleSave(data);
  const {formatMessage} = useIntl();
  const [open, setOpen] = useState(false);
  const sabreRedConfigurationRemoved = watch(sabreRedConfigurationRemovedName);
  const canRemoveConfig = !isProd || sabreRedConfigurationRemoved;
  const canChangeSabreRedConfigurationRemoved = isSabreAdminRole();

  const uid = new ShortUniqueId({length: 10});
  const editableId = useMemo(() => uid.rnd(), []);
  const disabledId = useMemo(() => uid.rnd(), []);
  const createDisabledDatePicker = () => {
    return canRemoveConfig ? (
      <Controller
        name={versionRemovalDateName}
        control={control}
        render={({field: {onChange, value}}): ReactElement => (
          <DatePicker
            changeHandler={onChange}
            label={formatMessage({id: 'cleanup.versionRemoval'})}
            value={isValid(value) ? format(value || new Date(), dateFormat) : undefined}
            className="col-xs-12 col-lg-8"
            dateFormat={dateFormat}
            key={editableId}
          />
        )}
      />
    ) : (
      <Controller
        name={versionRemovalDateName}
        control={control}
        render={({field: {onChange, value}}): ReactElement => (
          <DatePicker
            changeHandler={onChange}
            label={formatMessage({id: 'cleanup.versionRemoval'})}
            value={isValid(value) ? format(value || new Date(), dateFormat) : undefined}
            className="col-xs-12 col-lg-8"
            dateFormat={dateFormat}
            readOnly
            key={disabledId}
          />
        )}
      />
    );
  };

  const handleRemoveVersion = async () => {
    try {
      openToast(
        formatMessage({id: 'cleanup.deletionInfoTitle'}),
        formatMessage({id: 'cleanup.deletionInfo'}),
        ToastType.NEUTRAL,
        'spark-icon-check'
      );
      setIsLoading(true);
      await deleteVersion(id);
      navigate(`${InternalUrls.MyProducts}/${appName}/versions`);
      openToast(
        createMessageString(formatMessage, cleanupId, true, false),
        createMessageString(formatMessage, cleanupDeleteId, false, false),
        ToastType.POSITIVE,
        'spark-icon-check'
      );
      document.body.classList.remove('spark-modal-open');
    } catch (_) {
      openToast(
        createMessageString(formatMessage, cleanupId, true, true),
        createMessageString(formatMessage, cleanupDeleteId, false, true),
        ToastType.WARNING,
        'spark-icon-alert-triangle',
        true
      );
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="spark-pad-t-2">
        <div className="spark-mar-l-2 row">
          <p className="spark-bold col-xs-12 col-lg-4 spark-pad-t-1">
            <FormattedMessage id="cleanup.onHold" />
          </p>
          <ToggleGroup
            className="col-xs-12 col-lg-8"
            name={onHoldName}
            onChange={(_event, value) => setValue(onHoldName, value === Confirmation.Yes)}
            value={watch(onHoldName) ? Confirmation.Yes : Confirmation.No}
          >
            <Toggle
              id={`${onHoldName}First`}
              className="col-xs-4 col-sm-2"
              label={Confirmation.Yes}
              value={Confirmation.Yes}
              ariaLabel={formatMessage({id: 'common.agree'})}
            />
            <Toggle
              id={`${onHoldName}Second`}
              className="col-xs-4 col-sm-2"
              label={Confirmation.No}
              value={Confirmation.No}
              ariaLabel={formatMessage({id: 'common.disagree'})}
            />
          </ToggleGroup>
        </div>
        {status === Status.Published ? (
          <div className="spark-mar-l-2 spark-mar-t-1 row">
            <p className="spark-bold col-xs-12 col-lg-4 spark-pad-t-1">
              <FormattedMessage id="cleanup.notification" />
            </p>
            <ToggleGroup
              className="col-xs-12 col-lg-8"
              name={notifyBuyersName}
              onChange={(_event, value) => setValue(notifyBuyersName, value === Confirmation.Yes)}
              value={watch(notifyBuyersName) ? Confirmation.Yes : Confirmation.No}
            >
              <Toggle
                id={`${notifyBuyersName}First`}
                className="col-xs-4 col-sm-2"
                label={Confirmation.Yes}
                value={Confirmation.Yes}
                ariaLabel={formatMessage({id: 'common.agree'})}
              />
              <Toggle
                id={`${notifyBuyersName}Second`}
                className="col-xs-4 col-sm-2"
                label={Confirmation.No}
                value={Confirmation.No}
                ariaLabel={formatMessage({id: 'common.disagree'})}
              />
            </ToggleGroup>
          </div>
        ) : undefined}
        <div className="spark-mar-l-2 spark-mar-t-1 row">
          <p className="spark-bold col-xs-12 col-lg-4 spark-pad-t-1">
            <FormattedMessage id="cleanup.sabreRedConfigurationRemoved" />
          </p>
          <ToggleGroup
            className="col-xs-12 col-lg-8"
            name={sabreRedConfigurationRemovedName}
            value={watch(sabreRedConfigurationRemovedName) ? Confirmation.Yes : Confirmation.No}
            onChange={(_event, value) => setValue(sabreRedConfigurationRemovedName, value === Confirmation.Yes)}
          >
            <Toggle
              id={`${sabreRedConfigurationRemovedName}First`}
              className="col-xs-4 col-sm-2"
              label={Confirmation.Yes}
              value={Confirmation.Yes}
              ariaLabel={formatMessage({id: 'common.agree'})}
              disabled={!canChangeSabreRedConfigurationRemoved && !watch(sabreRedConfigurationRemovedName)}
            />
            <Toggle
              id={`${sabreRedConfigurationRemovedName}Second`}
              className="col-xs-4 col-sm-2"
              label={Confirmation.No}
              value={Confirmation.No}
              ariaLabel={formatMessage({id: 'common.disagree'})}
              disabled={!canChangeSabreRedConfigurationRemoved && watch(sabreRedConfigurationRemovedName)}
            />
          </ToggleGroup>
        </div>
        <div className="spark-mar-l-2 row spark-mar-t-1">
          <p className="spark-bold col-xs-12 col-lg-4 spark-pad-t-1-5">
            <FormattedMessage id="cleanup.removalDate" />
          </p>
          <Controller
            name={sabreRedConfigurationRemovalDateName}
            control={control}
            render={({field: {onChange, value}}): ReactElement => (
              <DatePicker
                changeHandler={onChange}
                label={formatMessage({id: 'cleanup.removalDate'})}
                value={isValid(value) ? format((value as Date) || new Date(), dateFormat) : undefined}
                className="col-xs-12 col-lg-8"
                dateFormat={dateFormat}
              />
            )}
          />
        </div>
        <div className="spark-mar-l-2 row spark-mar-b-2 spark-mar-t-2">
          <p className="spark-bold col-xs-12 col-lg-4 spark-pad-t-1-5">
            <FormattedMessage id="cleanup.versionRemoval" />
          </p>
          {createDisabledDatePicker()}
        </div>
        <ButtonContainer className="spark-mar-b-2 spark-mar-t-2 spark-pad-t-2">
          <Button
            className="col-xs-12 col-lg-4"
            size={ButtonSize.SMALL}
            type="button"
            onClick={() => setOpen(true)}
            disabled={!canRemoveConfig}
          >
            <FormattedMessage id="cleanup.removeNow" />
          </Button>
          <Button className="col-xs-12 col-lg-4" size={ButtonSize.SMALL} type="submit">
            <FormattedMessage id="cleanup.save" />
          </Button>
        </ButtonContainer>
      </Form>
      <ConfirmRemovalModal onClose={() => setOpen(false)} open={open} onRemove={handleRemoveVersion} />
    </>
  );
};

export const Form = styled.form`
  width: 90%;
  margin: 0 auto;

  p.spark-pad-t-1-5 {
    padding-top: 1.5rem;
  }

  @media (max-width: ${deviceWidths.md}px) {
    p {
      padding-bottom: 0;
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 3rem;
  @media (max-width: ${deviceWidths.md}px) {
    flex-wrap: wrap;
    & button {
      margin-bottom: 1rem;
    }
  }
`;

export default CleanupForm;
