import {Tester} from '@scm/product-components/pages/productDetails/productTabs/testers/ProdTesters';

export const testersTable = 'testersTable';
export const isTesterAdding = 'isTesterAdding';
export const editedTester = 'editedTester';

export interface TestersValues {
  [testersTable]: Array<Tester>;
  [isTesterAdding]: boolean;
  [editedTester]: Tester | null;
}
