import React, {useContext} from 'react';
import {ProposalContext} from '../../../ProposalForm';
import SectionHeader from './SectionHeader';
import ReviewRow from './ReviewRow';
import {ProposalValues, serviceAndSecurityValuesName} from '../../../ProposalValues';
import {ServiceAndSecurityValues} from '../../serviceAndSecurity/ServiceAndSecurityValues';
import {securityInitialValues} from '../../serviceAndSecurity/components/securityComponents/securityConstants';
import {serviceInitialValues} from '../../serviceAndSecurity/components/servicesComponents/serviceConstants';

const ServiceAndSecurityReview = () => {
  const {watch} = useContext(ProposalContext);
  const isSectionVisible =
    Object.values(watch(serviceAndSecurityValuesName)).filter(value => value && value.length > 0).length > 0;

  return isSectionVisible ? (
    <>
      <SectionHeader id="review.servicesAndSecurity.title" />
      {Object.keys({...serviceInitialValues, ...securityInitialValues})
        .filter(value => value !== 'accounting')
        .map(
          name =>
            watch(serviceAndSecurityValuesName)[name as keyof ServiceAndSecurityValues] !== '' && (
              <ReviewRow key={name} name={name as keyof ProposalValues} isSecurity />
            )
        )}
    </>
  ) : null;
};
export default ServiceAndSecurityReview;
