const partnerDirectoryPage = {
  'partnerDirectoryPage.title': 'Sabre Developer Partner Directory',
  'partnerDirectoryPage.description':
    'Discover Sabre Developer Partner Directory that provide products to complement your portfolio. Simply filter by location and desired solutions to generate custom and authorized third-party resources.',
  'partnerDirectoryPage.search': 'Search',
  'partnerDirectoryPage.showFilters': 'Show filters',
  'partnerDirectoryPage.hideFilters': 'Hide filters',
  'partnerDirectoryPage.searchTooltip': 'Search using partner name...',
  'partnerDirectoryPage.viewPartner': 'View Partner',
  'partnerDirectoryPage.filterDisabledTooltip': 'No results',
  'partnerDirectoryPage.showMore': 'Show more',
  'partnerDirectoryPage.regions': 'agency location',
  'partnerDirectoryPage.categories': 'categories',
  'partnerDirectoryPage.tiers': 'partner tiers',
  'partnerDirectoryPage.noResultsFoundTitle': '<bold>No Results Found</bold>',
  'partnerDirectoryPage.noResultsFoundText': 'Sorry, there were no results found based on the current criteria.',
  'sorting.featured': 'Featured',
  'sorting.a_to_z': 'A to Z',
  'sorting.z_to_a': 'Z to A',
};

export default partnerDirectoryPage;
