import React, {useEffect, useRef} from 'react';
import {useFormContext} from 'react-hook-form';
import SparkToggleSwitch from '@sabre/spark/js/src/components/toggle-switch';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {maxMobileWidth} from '@scm/partner-network/src/assets/deviceWidth';

const ToggleSwitch = ({
  name,
  values,
  labels,
  disabled,
  title,
}: {
  name: string;
  values: Array<string>;
  labels: Array<string | JSX.Element | JSX.Element[]>;
  disabled?: boolean;
  title?: string;
}) => {
  const {register} = useFormContext();

  const toggleSwitchRef = useRef(null);

  useEffect(() => {
    const sparkAccordion = new SparkToggleSwitch(toggleSwitchRef.current);
    return () => {
      sparkAccordion.remove();
    };
  }, [toggleSwitchRef]);

  return (
    <div className="row spark-toggle-group spark-mar-b-2" ref={toggleSwitchRef}>
      {labels.map((_, index) => (
        <SparkLabel className="spark-toggle" key={index} data-testid={`Technical Support ${index}`}>
          <input
            className="spark-toggle__input"
            type="radio"
            title={title}
            {...register(name)}
            value={values[index]}
            disabled={disabled}
          />
          <SparkSpan className="spark-label">{labels[index]}</SparkSpan>
        </SparkLabel>
      ))}
    </div>
  );
};

const SparkLabel = styled.label`
  margin: 0 50px 0 0;
  outline: 4px solid ${colors.grey100};
  outline-offset: 2px;
  @media (max-width: ${maxMobileWidth}px) {
    width: 100%;
  }
`;

const SparkSpan = styled.span`
  &.spark-label {
    padding: 0;
    display: flex;
    flex-direction: column;
    border: none;
  }
`;

export default ToggleSwitch;
