const cleanup = {
  'cleanup.notScheduled.message': 'There is no cleanup scheduled for this version',
  'cleanup.notScheduled.buttonContent': 'Configure cleanup options',
  'cleanup.onHold': 'On Hold',
  'cleanup.notification': 'With Notification',
  'cleanup.removalDate': 'SR360 Config Removal Date',
  'cleanup.fullCleanupDate': 'Full Cleanup Date',
  'cleanup.changeLog': 'Change Log',
  'cleanup.removeNow': 'Remove Now',
  'cleanup.save': 'Save',
  'cleanup.cancel': 'Cancel',
  'cleanup.confirm': 'Confirm',
  'cleanup.confirmRemoval.title': 'Please confirm the version removal',
  'cleanup.confirmRemoval.message':
    'This action is irreversible, all data related to the version will be removed (version entry in the database, files provided by the developer, update site files, entry in CERT Sabre Red CM, audit logs). The version should be removed from Sabre Red CM PROD beforehand.',
  'cleanup.sabreRedConfigurationRemoved': 'SR360 Config Removed',
  'cleanup.versionRemoval': 'Version Removal Date',
  'cleanup.error': 'Cannot save cleanup configuration.',
  'cleanup.delete.error': 'Cannot remove version.',
  'cleanup.delete.success': 'Version was removed successfully.',
  'cleanup.deletionInfo': 'Version removal might take a few seconds.',
  'cleanup.deletionInfoTitle': 'Processing.',
  'cleanup.errorTitle': 'Error.',
  'cleanup.success': 'Cleanup configuration saved successfully.',
  'cleanup.successTitle': 'Success.',
  'cleanup.SR360RemovalDate.label': 'SR360 REMOVAL DATE',
  'cleanup.SR360RemovalDate.description':
    "On this date, the version is removed from the Sabre Red 360 configuration, meaning that it is unavailable to any user (only applicable to versions created in SR360 PROD and therefore in 'Ready for Test (PROD)' status or later).",
  'cleanup.VersionRemovalDate.label': 'VERSION REMOVAL DATE',
  'cleanup.VersionRemovalDate.description':
    'On this date, the version is removed from the SCM Admin Centre along with all associated data. This action is irreversible.',
};

export default cleanup;
