import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import StepIndicator from 'spark/StepIndicator';
import StepItem from 'pages/benefits/StepItem';
import InternalUrls from 'utils/internalUrls';
import onJoinUsItemClicked from '../../googleAnalytics/onJoinUsItemClicked';
import colors from '@scm/components/assets/colors';

const AuthorizationProcess = () => (
  <Container>
    <h2>
      <FormattedMessage id="benefits.process.title" />
    </h2>
    <StepsPanel>
      <StepsPanelContent>
        <StepIndicator>
          <StepItem
            number="1"
            labelId="benefits.step1.title"
            subLabelId="benefits.step1.subtitle"
            href={InternalUrls.JoinUs}
            onClick={onJoinUsItemClicked}
          />
          <StepItem number="2" labelId="benefits.step2.title" subLabelId="benefits.step2.subtitle" />
          <StepItem number="3" labelId="benefits.step3.title" subLabelId="benefits.step3.subtitle" />
          <StepItem number="4" labelId="benefits.step4.title" subLabelId="benefits.step4.subtitle" />
        </StepIndicator>
      </StepsPanelContent>
    </StepsPanel>
  </Container>
);

const Container = styled.div`
  padding: 3rem 10vw;
  margin: auto;
`;

const StepsPanel = styled.div.attrs(() => ({
  className: 'spark-panel spark-panel--card',
}))`
  background-color: ${colors.grey700};
`;

const StepsPanelContent = styled.div.attrs(() => ({
  className: 'spark-panel__content',
}))`
  padding-bottom: 3rem;
`;

export default AuthorizationProcess;
