import {
  currentStepName,
  proposalTypeName,
  serviceAndSecurityValuesName,
  technologiesName,
} from '../pages/proposalForm/ProposalValues';
import {TechnologiesType} from '../pages/proposalForm/steps/definitionPage/definitionComponents/Technologies';
import {
  Confirmation,
  coreDataName,
  dataProtectionName,
  financialInformationName,
  freeTextRemarksName,
  personalInformationName,
  thirdPartyNetworkName,
} from '../pages/proposalForm/steps/serviceAndSecurity/ServiceAndSecurityValues';

export const basicTextFieldMaxLength = 500;
export const basicTextFieldMinLength = 20;
export const nameMinLength = 3;
export const largeTextFieldMaxLength = 1000;
export const descriptionMaxLength = 3000;

export const largeTextFieldMinLength = 150;
export const priceAdditionalInfoMinLength = 10;
export const nameMaxLength = 50;
export const minArrayLength = 1;
export const maxArrayLength = 4;
export const maxArraySecurityLength = 5;
const proposalTypeToSecurityStepId = new Map<string, number>([
  ['Public Red App', 6],
  ['Private Red App', 3],
]);
export const schemaFirstItem = 1;

export const requiredArrayMessage = 'You must choose at least one item.';
export const requiredItemMessage = 'You must upload one item.';
export const standardCorrectFieldMessage = 'Correct.';
const descriptionMessage = (value: number) => `This field must have at least ${value} characters.`;
export const minDescriptionMessage = descriptionMessage(basicTextFieldMinLength);
export const minDescriptionLargeMessage = descriptionMessage(largeTextFieldMinLength);
export const minNameMessage = descriptionMessage(nameMinLength);
export const minAdditionalInfoMessage = descriptionMessage(priceAdditionalInfoMinLength);

export const fieldGroupTest = (fieldName: string) =>
  function (this: {parent: {[key: string]: string}}, item?: string) {
    return !this.parent[fieldName] || !!(item && item?.length >= basicTextFieldMinLength);
  };

export function technologiesTest(this: {parent: {[key: string]: string}}, item?: string) {
  return (
    !this.parent[technologiesName]?.includes(TechnologiesType.Other) ||
    !!(item && item?.length >= basicTextFieldMinLength)
  );
}

export const servicesAndSecurityTextFieldTest = (checkboxName: string, fieldName: string, isRadio?: boolean) =>
  function (_?: string, schema?: any) {
    const serviceOrServiceAndSecurityStep = proposalTypeToSecurityStepId.get(
      schema.from[schemaFirstItem].value[proposalTypeName]
    );

    const validationNotRequired =
      schema.from[schemaFirstItem].value[currentStepName] !== serviceOrServiceAndSecurityStep &&
      !schema.from[schemaFirstItem].value['shouldValidateServiceAndSecurity'];

    return (
      validationNotRequired ||
      !schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][checkboxName] ||
      schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][fieldName].length >=
        (!!isRadio ? 1 : basicTextFieldMinLength)
    );
  };

export const servicesAndSecurityArrayFieldTest = (checkboxName: string, fieldName: string) =>
  function (_?: string, schema?: any) {
    const serviceOrServiceAndSecurityStep = proposalTypeToSecurityStepId.get(
      schema.from[schemaFirstItem].value[proposalTypeName]
    );

    const validationNotRequired =
      schema.from[schemaFirstItem].value[currentStepName] !== serviceOrServiceAndSecurityStep &&
      !schema.from[schemaFirstItem].value['shouldValidateServiceAndSecurity'];

    return (
      validationNotRequired ||
      !schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][checkboxName] ||
      (schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][fieldName].length >= minArrayLength &&
        schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][fieldName].length <= maxArraySecurityLength)
    );
  };

export function dataProtectionTest(_?: string, schema?: any) {
  const serviceOrServiceAndSecurityStep = proposalTypeToSecurityStepId.get(
    schema.from[schemaFirstItem].value[proposalTypeName]
  );

  const isAnyOfRequiredCheckboxChecked =
    schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][financialInformationName] ||
    schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][personalInformationName] ||
    schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][thirdPartyNetworkName];

  if (!isAnyOfRequiredCheckboxChecked) {
    return true;
  }

  const validationNotRequired =
    schema.from[schemaFirstItem].value[currentStepName] !== serviceOrServiceAndSecurityStep &&
    !schema.from[schemaFirstItem].value['shouldValidateServiceAndSecurity'];

  return (
    validationNotRequired ||
    schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][dataProtectionName].length >=
      largeTextFieldMinLength
  );
}

export const freeTextRemarksFieldsTest = (fieldName: string) =>
  function (_?: string, schema?: any) {
    const serviceOrServiceAndSecurityStep = proposalTypeToSecurityStepId.get(
      schema.from[schemaFirstItem].value[proposalTypeName]
    );

    const validationNotRequired =
      schema.from[schemaFirstItem].value[currentStepName] !== serviceOrServiceAndSecurityStep &&
      !schema.from[schemaFirstItem].value['shouldValidateServiceAndSecurity'];

    return (
      validationNotRequired ||
      !schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][coreDataName] ||
      !(schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][freeTextRemarksName] === Confirmation.Yes) ||
      schema.from[schemaFirstItem].value[serviceAndSecurityValuesName][fieldName].length >= basicTextFieldMinLength
    );
  };
