import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Configuration as ProposalConfiguration,
  ProductInfoResponse,
  Proposal,
  ProposalResponse,
  ProposalsApi,
  RedAppInfo,
} from '../../generated/proposal';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {BreadcrumbLink, BreadcrumbRowWrapper, Loading} from '@scm/components';
import styled from 'styled-components';
import {FormattedMessage, IntlProvider, useIntl} from 'react-intl';
import proposal from '../../translations/proposal';
import ProposalViewHeader from './proposalViewHeader/ProposalViewHeader';
import ProposalViewInfo from './proposalViewHeader/ProposalViewInfo';
import ProposalViewPanel from './proposalViewContent/ProposalViewPanel';
import ProposalViewContent from './proposalViewContent/ProposalViewContent';
import {proposalApiBaseLink} from '../../assets/apiBaseLink';
import {Button, Message} from '@sabre/spark-react-core';
import {ButtonSize, MessageRole, MessageStatus, MessageType, ToastType} from '@sabre/spark-react-core/types';
import ProposalViewDataTab from './proposalViewDataTab/ProposalViewDataTab';
import {language} from '../../assets/appLanguage';
import ProposalViewDataProvider, {ProposalDataViewContext} from './proposalViewDataTab/ProposalViewDataProvider';
import {SubmitHandler} from 'react-hook-form';
import {
  countryCodesName,
  descriptionName,
  flowDiagramName,
  globalRegionalizationCheckboxName,
  isPaidDescriptionName,
  proposalTypeName,
  redAppCategoryName,
  regionalizationDescriptionName,
  regionCodesName,
} from '../proposalForm/ProposalValues';
import {getAccessToken, isSabreEmployeeRole} from '@scm/authentication/utils/authentication';
import {LoadingContainer} from '@scm/authentication/components/Login';
import {proposalResponseMapper} from './proposalChangeNameForm/proposalResponseMapper';
import {isPaidName} from '@scm/product-components/pages/storefrontData/interfaces/AppInfoDataValues';
import {createMessageString, openToast} from '@scm/components/messaging/openToast';
import {redAppSupportEmail} from '@scm/components/utils/common';
import {AuditApi, AuditLog, Configuration as AuditConfiguration, ObjectType} from '../../generated/audit';
import AuditLogs from '@scm/admin-centre/src/components/auditLog/AuditLogs';
import {createCountriesWithoutRegions} from '@scm/components/assets/regionsAndCountries';
import HrefElement from '@scm/components/messaging/HrefElement';
import {richTextShortener} from '@scm/product-components/utils/richTextShortener';
import ProcessScheme from '@scm/components/processScheme/ProcessScheme';
import {determineProcessSchemeAndCurrentStep} from '@scm/components/processScheme/determineProcessSchemeAndCurrentStep';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import HtmlToText from 'html-to-text';
import {SubmitButtonContainer, SubmitInfo} from '@scm/product-details/components/submit/Submit';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

const fetchProposal = async (id: string) => {
  return new ProposalsApi(
    new ProposalConfiguration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).getProposal({id});
};

const fetchAuditLogs = async (id: string) => {
  return new AuditApi(
    new AuditConfiguration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).getAudits({objectType: 'proposal', id});
};

const fetchProductSku = async (id: string) =>
  new ProposalsApi(
    new ProposalConfiguration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).getProductSkuByProposalId({id});

async function updateData(id: string, proposal: Proposal, flowDiagram?: Blob) {
  return await new ProposalsApi(
    new ProposalConfiguration({
      basePath: proposalApiBaseLink,
      accessToken: getAccessToken() || '',
      middleware: middleware,
    })
  ).updateProposal({
    id,
    proposal,
    flowDiagram,
  });
}

async function handleData(
  id: string,
  proposal: Proposal,
  setLoading: (flag: boolean) => void,
  formatMessage: (obj: {id: string}) => string,
  flowDiagram?: Blob
) {
  const proposalName = 'proposal.approved';
  try {
    const proposalRequest: Proposal & {productInfoResponse?: ProductInfoResponse; sellerid?: string} = {...proposal};
    proposalRequest.productInfo = proposalRequest.productInfoResponse || {productType: 'Red App'};
    proposalRequest.sellerId = proposalRequest.sellerid || '';
    delete proposalRequest.productInfoResponse;
    delete proposalRequest.sellerid;
    await updateData(id, proposalRequest, flowDiagram);
    openToast(
      createMessageString(formatMessage, proposalName, true),
      createMessageString(formatMessage, proposalName),
      ToastType.POSITIVE,
      'spark-icon-check'
    );
    setLoading(true);
  } catch (e) {
    openToast(
      createMessageString(formatMessage, proposalName, true, true),
      createMessageString(formatMessage, proposalName, false, true),
      ToastType.WARNING,
      'spark-icon-alert-triangle'
    );
  }
}

const ProposalViewPageContent = ({
  proposal,
  auditLogs,
  id,
  loading,
  error,
  setLoading,
  setError,
  setIsApproved,
  setReloadForm,
}: {
  proposal: ProposalResponse;
  auditLogs: AuditLog[];
  id: string;
  loading: boolean;
  error: boolean;
  setLoading: (flag: boolean) => void;
  setIsApproved: (flag: boolean) => void;
  setError: (flag: boolean) => void;
  setReloadForm: Dispatch<SetStateAction<boolean>>;
}) => {
  const {formatMessage} = useIntl();

  const {
    name,
    provisioningType,
    createdAt,
    status,
    productInfoResponse: {general: {description, categories}} = {general: {description: '', categories: []}},
  } = proposal || {};

  const {watch, reset, setValue, isDirty, handleSubmit, isValid} = useContext(ProposalDataViewContext);
  const isDescriptionError = HtmlToText.htmlToText(watch(descriptionName)).length < 150;
  const [showProcessSchemeModal, setShowProcessSchemeModal] = useState(false);
  const {processScheme, currentStep, isFailed} = determineProcessSchemeAndCurrentStep(
    `${provisioningType} Red App`,
    status ?? '',
    true,
    false
  );

  if (proposal.productInfoResponse && !watch(descriptionName)) {
    setValue(descriptionName, proposal.productInfoResponse?.general?.description);
  }

  const submitHandler: SubmitHandler<FormData> = async data => {
    const proposalRequest = {...proposal};

    if (data[descriptionName]) {
      (proposalRequest.productInfoResponse as RedAppInfo).general.description = data[descriptionName];
      (proposalRequest.productInfoResponse as RedAppInfo).general.shortDescription = richTextShortener(
        data[descriptionName]
      );
    }

    if (data[redAppCategoryName]) {
      (proposalRequest.productInfoResponse as RedAppInfo).general.categories = data[redAppCategoryName];
    }

    if (data?.global || data[globalRegionalizationCheckboxName] === Confirmation.No) {
      (proposalRequest.productInfoResponse as RedAppInfo).regionalization.availableRegions = ['Global'];
      (proposalRequest.productInfoResponse as RedAppInfo).regionalization.availableCountries = [];
    } else {
      if (data[regionCodesName]) {
        (proposalRequest.productInfoResponse as RedAppInfo).regionalization.availableRegions = data[regionCodesName];
      }
      if (data[countryCodesName]) {
        (proposalRequest.productInfoResponse as RedAppInfo).regionalization.availableCountries =
          createCountriesWithoutRegions(data[regionCodesName], data[countryCodesName]);
      }
    }

    if (data[isPaidName] === Confirmation.No) {
      (proposalRequest.productInfoResponse as RedAppInfo).pricing.comment = null;
    } else if (data[isPaidDescriptionName]) {
      (proposalRequest.productInfoResponse as RedAppInfo).pricing.comment = data[isPaidDescriptionName];
    }

    if (regionalizationDescriptionName in data) {
      (proposalRequest.productInfoResponse as RedAppInfo).regionalization.regionalConstraintsComment =
        data[regionalizationDescriptionName];
    }

    if (data[proposalTypeName]) {
      proposalRequest.provisioningType = data[proposalTypeName];
    }

    reset();
    await handleData(
      id,
      proposalResponseMapper(proposalResponseMapper(proposalRequest)) as unknown as Proposal,
      setLoading,
      formatMessage,
      watch(flowDiagramName)?.[0]
    );
  };

  return error ? (
    <Message
      content={
        formatMessage(
          {id: 'review.error.content'},
          {
            email: <HrefElement email={redAppSupportEmail} />,
          }
        ) as string
      }
      role={MessageRole.ALERT}
      status={MessageStatus.ERROR}
      title={formatMessage({id: 'review.error.title'})}
      type={MessageType.PAGE}
    />
  ) : loading || !proposal ? (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  ) : (
    <>
      <form>
        <BreadcrumbRowWrapper>
          <BreadcrumbLink intlId="common.breadcrumb.myProducts" href={InternalUrls.MyProducts} />
          <BreadcrumbLink text={name} />
        </BreadcrumbRowWrapper>
        <ProposalViewContainer className="spark-pad-2">
          <ProposalViewHeader name={name} provisioningType={provisioningType} />
          <ProposalViewInfo
            createdAt={createdAt}
            status={status}
            reasonRejected={proposal.reasonRejected}
            setShowProcessSchemeModal={setShowProcessSchemeModal}
          />
          <ActionPageContainer className="spark-flex">
            <ProposalViewPanel />
            <ProposalViewContent
              description={description}
              categories={categories}
              proposal={proposal}
              setError={setError}
              setLoading={setLoading}
            />
          </ActionPageContainer>
        </ProposalViewContainer>
        <ProposalViewContainer className="spark-pad-2">
          <ProposalViewDataTab proposal={proposal} setLoading={setLoading} setIsApproved={setIsApproved} />
        </ProposalViewContainer>
        {isSabreEmployeeRole() && (
          <ProposalViewContainer className="spark-pad-2">
            <AuditLogs
              auditLogs={auditLogs}
              reloadForm={() => setReloadForm(true)}
              objectType={ObjectType.Proposal}
              id={id}
            />
          </ProposalViewContainer>
        )}
        {isDirty && (
          <SubmitButtonContainer>
            <SubmitInfo>
              <FormattedMessage id="productDetails.submitInfo" />
            </SubmitInfo>
            <Button
              secondary
              type="button"
              onClick={() => setLoading(true)}
              size={ButtonSize.SMALL}
              className="spark-mar-r-1"
            >
              <FormattedMessage id="common.reset" />
            </Button>
            <Button
              disabled={!isValid || isDescriptionError}
              onClick={handleSubmit(submitHandler)}
              type="button"
              size={ButtonSize.SMALL}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </SubmitButtonContainer>
        )}
      </form>
      <ProcessScheme
        processScheme={processScheme}
        open={showProcessSchemeModal}
        setOpen={setShowProcessSchemeModal}
        currentStep={currentStep}
        isFailed={isFailed}
        isProposal
      />
    </>
  );
};

const ActionPageContainer = styled.div`
  background-color: ${colors.white};
  border-top: 2px solid ${colors.grey150};
  @media (max-width: ${deviceWidths.xs}px) {
    flex-direction: column;
  }
  min-height: 33.33rem;
`;

export const ProposalViewPage = () => {
  const [proposalResponse, setProposal] = useState<ProposalResponse>();
  const [auditLog, setAuditLog] = useState<AuditLog[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [productSku, setProductSku] = useState<string>();
  const [isApproved, setIsApproved] = useState(false);
  const id = useParams().proposalId;
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const canFetchProductSku = !productSku && isApproved && !!proposalResponse;
  const [reloadForm, setReloadForm] = useState(false);

  const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    if (loading || reloadForm) {
      fetchProposal(id || '')
        .then(proposal => setProposal(proposal))
        .catch(() => setError(true))
        .finally(() => {
          setLoading(false);
          setReloadForm(false);
        });
      if (isSabreEmployeeRole()) {
        fetchAuditLogs(id || '')
          .then(response => setAuditLog(response))
          .catch();
      }
    }
  }, [loading, reloadForm]);

  useEffect(() => {
    if (error) {
      openToast(
        createMessageString(formatMessage, 'proposalView', true, true),
        createMessageString(formatMessage, 'proposalView', false, true),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
      navigate(InternalUrls.MyProducts);
    }
  }, [error]);

  useEffect(() => {
    if (canFetchProductSku) {
      timeout(2500).then(() => {
        fetchProductSku(id || '')
          .then(sku => setProductSku(sku))
          .catch(err => {
            if (err.response.status !== 404) {
              openToast(
                formatMessage({id: 'proposal.approved.skuExists.error.title'}),
                formatMessage({id: 'proposal.approved.skuExists.error.definition'}),
                ToastType.WARNING,
                'spark-icon-alert-triangle'
              );
            }
          });
        setIsApproved(false);
      });
    }
  }, [canFetchProductSku, setProductSku, setIsApproved]);

  useEffect(() => {
    fetchProductSku(id ?? '')
      .then(productSku => {
        if (productSku) {
          navigate(`${InternalUrls.MyProducts}/${productSku}/versions`);
          navigate(0);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (productSku) {
      navigate(`${InternalUrls.MyProducts}/${productSku}/versions`, {
        state: {approved: true},
      });
    }
  }, [productSku]);

  return (
    <React.StrictMode>
      <IntlProvider locale={language} key={language} messages={proposal}>
        {isApproved || loading || !proposalResponse || reloadForm ? (
          <LoadingContainer>
            <Loading label={formatMessage({id: 'common.data.loading'})} />
          </LoadingContainer>
        ) : (
          <ProposalViewDataProvider>
            <ProposalViewPageContent
              id={id || ''}
              auditLogs={auditLog}
              loading={loading}
              error={error}
              proposal={proposalResponse}
              setError={setError}
              setLoading={setLoading}
              setIsApproved={setIsApproved}
              setReloadForm={setReloadForm}
            />
          </ProposalViewDataProvider>
        )}
      </IntlProvider>
    </React.StrictMode>
  );
};

export const ProposalViewContainer = styled.div`
  & *:not(strong, button, h1) {
    font-weight: 400;
  }
`;
