import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import CenterItems from 'components/styledComponents/CenterItems';
import IconQuotesGreen from 'assets/images/icon-quotes-slateblue.png';
import {Partner} from 'generated/graphql';
import colors from '@scm/components/assets/colors';

const PartnerTestimonials = ({partner: {testimonials}}: {partner: Partner}) => (
  <PartnerLeadsContainer>
    <div className="spark-panel__content">
      <CenterItems>
        <Icon src={IconQuotesGreen} alt="quotes" />
        <div className="spark-alpha spark-bold spark-mar-b-2">
          <FormattedMessage id="partnerPage.testimonials.title" />
        </div>
      </CenterItems>
      <div>
        {testimonials?.map((testimonial, index) => (
          <ItemWithSeparator key={index}>
            <Testimonial>“{testimonial!.review}”</Testimonial>
            <Author>
              <strong>{testimonial!.author}</strong>
            </Author>
          </ItemWithSeparator>
        ))}
      </div>
    </div>
  </PartnerLeadsContainer>
);

const PartnerLeadsContainer = styled.div.attrs(() => ({
  className: 'spark-panel spark-panel--card',
}))`
  background: ${colors.grey100};
`;

const Icon = styled.img`
  font-size: 5rem;
  padding: 2rem;
`;

const ItemWithSeparator = styled.div`
  padding: 1rem 0;
  margin: 0 1rem;
  border-top: 1px solid ${colors.grey200};
  &:first-child {
    border-top: 0;
  }
`;

const Testimonial = styled.div`
  padding-right: 2rem;
`;

const Author = styled.div`
  text-align: right;
  color: ${colors.grey700};
`;

export default PartnerTestimonials;
