import React, {Dispatch, SetStateAction, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';
import {format} from 'date-fns';
import {Product as ProductValues} from '../MyProducts';
import StatusIcon from '@scm/admin-centre/src/components/styledComponents/StatusIcon';
import Icon from '@scm/components/icons/Icon';
import colors from '@scm/components/assets/colors';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import {dateFormat} from '@scm/components/utils/common';
import {Popover, Tooltip} from '@sabre/spark-react-core';
import ActionMenu from './ActionMenu';
import {TooltipDirectionX, TooltipDirectionY} from '@sabre/spark-react-core/types';
import {useNavigate} from 'react-router-dom';
import AddToFavourite from './AddToFavourite';
import {IProcessScheme} from '@scm/components/processScheme/schemes';
import {determineProcessSchemeAndCurrentStep} from '@scm/components/processScheme/determineProcessSchemeAndCurrentStep';

type ProposalItem = ProductValues & {
  deleteProposal: (id: string, name: string) => Promise<void>;
  removeProposal: (id: string, name: string) => Promise<void>;
  setItems: Dispatch<SetStateAction<Array<ProductValues>>>;
  setProcessScheme: Dispatch<SetStateAction<IProcessScheme>>;
  setShowProcessSchemeModal: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setIsFailed: Dispatch<SetStateAction<boolean>>;
  setIsProposal: Dispatch<SetStateAction<boolean>>;
};

const Product = ({
  productName,
  productIcon,
  type,
  updatedAt,
  version,
  isLatestVersionPatch,
  productStatus,
  status,
  isProposal,
  id,
  sku,
  deleteProposal,
  removeProposal,
  setItems,
  isFavourite,
  setProcessScheme,
  setShowProcessSchemeModal,
  setCurrentStep,
  setIsFailed,
  setIsProposal,
}: ProposalItem) => {
  const currentRow = useRef(null);
  const pageNavigate = useNavigate();
  const productLink =
    type == 'Standard' ? `${InternalUrls.MyProducts}/standard/${sku}` : `${InternalUrls.MyProducts}/${sku}/versions`;
  const innerLink = isProposal
    ? `${status === 'Draft' ? InternalUrls.AddNewProduct : InternalUrls.Proposal}/${id}`
    : productLink;
  const externalLink = `${window.location.origin}/admin-centre${innerLink}`;
  const navigate = (newTab: boolean = false) => {
    if (newTab) {
      window.open(externalLink, '_blank');
    } else {
      pageNavigate(innerLink);
      pageNavigate(0);
    }
  };

  const handleShowProcessSchemeModal = () => {
    const {processScheme, currentStep, isFailed} = determineProcessSchemeAndCurrentStep(
      type,
      status,
      isProposal,
      isLatestVersionPatch
    );
    setProcessScheme(processScheme);
    setCurrentStep(currentStep);
    setIsFailed(!!isFailed);
    setShowProcessSchemeModal(true);
    setIsProposal(!!isProposal);
  };

  useEffect(() => {
    const cells = (currentRow.current ?? document).getElementsByTagName('td');
    Array.from(cells)
      .filter((cell: any) => !cell.id.includes('StatusCell'))
      .slice(0, -1)
      .forEach((cell: any) => cell.addEventListener('click', (event: KeyboardEvent) => navigate(event.ctrlKey)));
  }, [currentRow]);

  const mapType = (type: string): string => {
    switch (type) {
      case 'PfKeys':
        return 'PF Keys';
      case 'Scribe':
        return 'Scribe Script';
      case 'Standard':
        return 'Standard Product';
      default:
        return type;
    }
  };

  const published = productStatus === 'Released';

  const icon = productIcon ? <AppIcon src={productIcon} alt={productName} loading="lazy" /> : <AppIconPlaceholder />;

  const getStatus = () => {
    if (productStatus === 'Obsolete') {
      return <FormattedMessage id={`myProducts.statuses.ProductObsolete`} />;
    }
    if (status) {
      return <FormattedMessage id={`myProducts.statuses.${status}`} />;
    } else {
      return <FormattedMessage id={`myProducts.statuses.Waiting for First Version`} />;
    }
  };

  return (
    <ProductRow ref={currentRow} isFavourite={!!isFavourite}>
      <ProductCell>
        <div className="spark-flex spark-align-items-center">
          {icon}
          <AppName>{productName}</AppName>
        </div>
      </ProductCell>
      <ProductCell>
        <TypeCellContent>{mapType(type)}</TypeCellContent>
      </ProductCell>
      <ProductCell>
        {updatedAt && <time dateTime={updatedAt}>{format(new Date(updatedAt), dateFormat)}</time>}
      </ProductCell>
      <ProductCell className="spark-text-center">
        {published && <Icon className="spark-icon-check spark-icon--fill" aria-label="check" />}
      </ProductCell>
      <ProductCell>{version}</ProductCell>
      <ProductCell id={`${productName}StatusCell`}>
        {type !== 'Standard' && (
          <StatusCellContent>
            <Tooltip
              toggleEl={
                <div onClick={() => handleShowProcessSchemeModal()}>
                  <StatusIcon statusName={productStatus === 'Obsolete' ? productStatus : status} />
                  {getStatus()}
                </div>
              }
              anchorX={TooltipDirectionX.LEFT}
              anchorY={TooltipDirectionY.MIDDLE}
            >
              <FormattedMessage
                id={
                  type === 'PfKeys' || type === 'Scribe'
                    ? 'myProducts.seePublishingProcess.tooltip'
                    : 'myProducts.seeCertificationProcess.tooltip'
                }
              />
            </Tooltip>
          </StatusCellContent>
        )}
      </ProductCell>
      <ProductCell>
        <div className="spark-flex">
          <AddToFavourite id={id} isFavourite={!!isFavourite} isProposal={!!isProposal} setItems={setItems} />
          <Popover
            anchorX={TooltipDirectionX.LEFT}
            anchorY={TooltipDirectionY.MIDDLE}
            toggleEl={
              <button
                className="spark-btn--icon spark-icon spark-icon--fill spark-icon-menu-ellipsis-vertical"
                aria-label="Expand action menu"
              />
            }
          >
            <ActionMenu
              link={innerLink}
              status={status}
              proposalId={id}
              proposalName={productName}
              published={!!published}
              deleteProposal={(id, name) => deleteProposal(id, name)}
              removeProposal={(id, name) => removeProposal(id, name)}
              productSku={sku}
              isProposal={isProposal}
              isRedApp={type !== 'Standard'}
              setItems={setItems}
            />
          </Popover>
        </div>
      </ProductCell>
    </ProductRow>
  );
};

const ProductRow = styled.tr<{
  isFavourite: boolean;
}>`
  & td {
    ${props => (props.isFavourite ? `background-color: ${colors.grey100} !important;` : '')}
  }
`;

const ProductCell = styled.td`
  &&& {
    border-left: 0;
    border-right: 0;
    font-size: 12px;
  }
`;

export const AppIcon = styled.img`
  display: inline-block;
  margin: 0 1rem 0 0.5rem;
  width: 24px;
  height: 24px;
  object-fit: contain;
  vertical-align: -7px;
`;

export const AppIconPlaceholder = styled.div`
  display: inline-block;
  margin: 0 1rem 0 0.5rem;
  width: 24px;
  height: 24px;
  background-color: ${colors.transparent};
  vertical-align: -7px;
`;

const AppName = styled.span`
  display: inline-block;
  font-weight: bold;
  @media (max-width: ${deviceWidths.md}px) {
    margin-top: 1rem;
  }
`;

const StatusCellContent = styled.div`
  display: flex;
  align-items: center;
  min-width: 15rem;
`;

const TypeCellContent = styled.div`
  display: flex;
  min-width: 10rem;
`;

export default Product;
