import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import parse from 'html-react-parser';
import color from '@scm/components/assets/colors';

const FormAlertMessage = () => {
  const {formatMessage} = useIntl();

  return (
    <AlertMessage className="spark-mar-t-1 spark-pad-r-.5 spark-pad-l-.5">
      <div>
        <i className="spark-icon--fill spark-icon-alert-triangle spark-mar-r-1" />
        {parse(formatMessage({id: 'productDetails.icon.alert1'}))}
      </div>
      <p className="spark-mar-t-.5">{parse(formatMessage({id: 'productDetails.icon.alert2'}))}</p>
    </AlertMessage>
  );
};

const AlertMessage = styled.p`
  text-align: justify;
  text-justify: inter-word;
  white-space: normal;
  color: ${color.warningDarkYellow};
`;

export default FormAlertMessage;
