import React from 'react';
import styled, {css} from 'styled-components';
import {useIntl} from 'react-intl';
import colors from '@scm/components/assets/colors';

const StepItem = ({
  number,
  labelId,
  subLabelId,
  href,
  onClick,
}: {
  number: string;
  labelId: string;
  subLabelId?: string;
  href?: string;
  onClick?: () => void;
}) => {
  const {formatMessage} = useIntl();

  return (
    <Container onClick={onClick}>
      <StepIcon>
        <StepNumber>{number}</StepNumber>
      </StepIcon>
      {href ? (
        <StepLabelWithLink {...(href ? {href: href} : {})}>
          <WhiteText>{formatMessage({id: labelId})}</WhiteText>
          <StepSubLabel>{formatMessage({id: subLabelId})}</StepSubLabel>
        </StepLabelWithLink>
      ) : (
        <StepLabel>
          <WhiteText>{formatMessage({id: labelId})}</WhiteText>
          <StepSubLabel>{formatMessage({id: subLabelId})}</StepSubLabel>
        </StepLabel>
      )}
    </Container>
  );
};

const WhiteText = styled.span`
  color: ${colors.white};
`;

const Container = styled.a.attrs(() => ({
  className: 'spark-step-indicator__item spark-step-indicator__item--current',
  role: 'presentation',
}))`
  background: transparent;
  margin: 0 2rem;
  cursor: auto;
  border: none;
  &:after {
    background: transparent;
    top: 3rem;
    left: calc(50% + 4rem);
    width: calc(100% - 4rem);
  }
`;

const StepIcon = styled.span.attrs(() => ({
  className: 'spark-step-indicator__icon',
}))`
  background-color: ${colors.grey800} !important;
  border-color: ${colors.grey800} !important;
  height: 6rem;
  width: 6rem;
`;

const StepNumber = styled.span`
  font-size: 3rem !important;
  font-weight: 400 !important;
`;

const StepLabelWithLink = styled.a.attrs(() => ({
  className: 'spark-step-indicator__text',
}))`
  &&& {
    color: ${colors.white} !important;
    width: 14rem;
    border: none;
  }

  &:hover,
  &:focus {
    ${props =>
      props.href
        ? css`
            text-decoration: underline;
            cursor: pointer;
          `
        : css`
            cursor: auto;
          `}
  }
`;

const StepLabel = styled.span.attrs(() => ({
  className: 'spark-step-indicator__text',
}))`
  color: ${colors.white} !important;
  width: 14rem;
`;

const StepSubLabel = styled.span`
  font-weight: 200 !important;
  width: 14rem;
  color: ${colors.white};
`;

export default StepItem;
