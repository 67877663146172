import styled from 'styled-components';
import {deviceWidths} from '@scm/components/assets/deviceWidth';
import colors from '@scm/components/assets/colors';

export const StyledHeaderItemSpan = styled.span`
  .child-active & {
    background-color: ${colors.transparent} !important;
    border: none !important;
  }

  @media (min-width: ${deviceWidths.sm}px) {
    &.spark-menu__list-link {
      color: ${colors.white};
      font-size: 2rem;
      font-weight: 400;
      text-transform: none;
    }
  }
`;

export const DropdownList = styled.ul`
  width: 26rem !important;
`;

export const ActiveItem = styled.li.attrs(() => ({
  className: 'spark-menu__list-item',
}))<{mobile?: boolean}>`
  & > .spark-menu__list-links > a {
    color: ${colors.sparkBlue} !important;
    font-weight: bold;
    background-color: ${props => (props.mobile ? '' : 'white !important;')};

    :hover {
      background-color: ${colors.grey100} !important;
    }
  }
}
`;

export const ExpandPanelContent = styled.div`
  padding: 0 !important;
  position: absolute;
  right: 20px;
  @media (max-width: ${deviceWidths.xs}px) {
    top: 65px;
  }

  & .spark-list-item--no-bullet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${colors.black};
    border-bottom: 1px solid ${colors.grey100};
    padding: 0 2rem;
    height: 5rem;
    margin: 0;
  }

  & .spark-list-item--no-bullet:last-of-type {
    border-bottom: none;
  }
`;

export const ExpandPanelCaret = styled.span`
  left: 8rem;
  top: 0;
`;

export const SabreLogo = styled.span`
  &::after {
    height: calc(100% - 0.8px);
  }
`;

export const AvatarText = styled.span`
  margin-left: 1.5rem;
  @media (max-width: ${deviceWidths.xs}px) {
    display: none;
  }
`;

export const AvatarLabel = styled.span`
  color: ${colors.white};

  & .spark-icon {
    font-size: 0.9rem;
    margin-left: 0.3rem;
  }
}
`;

export const SparkPopoverContainer = styled.span`
  @media (max-width: ${deviceWidths.xs}px) {
    & .spark-menu__list-link--avatar {
      height: 60px;
      line-height: 40px;
    }
  }
`;
