import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import Product from './Product';
import SparkTable from '@sabre/spark/js/src/components/table';
import colors from '@scm/components/assets/colors';
import useResponsiveTable from './useResponsiveTable';
import {Product as ProductValues} from '../MyProducts';
import ProcessSchemes from '@scm/components/processScheme/schemes';
import ProcessScheme from '@scm/components/processScheme/ProcessScheme';

const NUMBER_OF_ITEMS_TO_SCROLL = 8;
const LAZY_LOADING_INTERVAL = 15;
const INITIAL_NUMBER_OF_PRODUCT_ITEMS = 45;

type SortColumn = 'Name' | 'Date' | undefined;

const ProductsList = memo(
  ({
    items,
    setItems,
    searchProductsIds,
    deleteProposal,
    removeProposal,
    isBannerOpen,
  }: {
    items: Array<ProductValues>;
    setItems: React.Dispatch<React.SetStateAction<Array<ProductValues>>>;
    searchProductsIds: Array<string>;
    deleteProposal: (id: string, name: string) => Promise<void>;
    removeProposal: (id: string, name: string) => Promise<void>;
    isBannerOpen: boolean;
  }) => {
    const [sortNameAsc, setSortNameAsc] = useState(false);
    const [sortDateAsc, setSortDateAsc] = useState(true);
    const [sortColumn, setSortColumn] = useState<SortColumn>('Date');
    const [lazyLoadingLength, setLazyLoadingLength] = useState(INITIAL_NUMBER_OF_PRODUCT_ITEMS);
    const [processScheme, setProcessScheme] = useState(ProcessSchemes.PublicAppProposalApproved);
    const [showProcessSchemeModal, setShowProcessSchemeModal] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isProposal, setIsProposal] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const itemList = useMemo(
      () =>
        items
          .filter(item => searchProductsIds.includes(item.id))
          .map((product, index) => ({
            jsx: (
              <Product
                key={`${product.productName} ${index}`}
                {...product}
                deleteProposal={deleteProposal}
                removeProposal={removeProposal}
                setItems={setItems}
                isFavourite={!!product?.isFavourite}
                setProcessScheme={setProcessScheme}
                setShowProcessSchemeModal={setShowProcessSchemeModal}
                setCurrentStep={setCurrentStep}
                setIsFailed={setIsFailed}
                setIsProposal={setIsProposal}
              />
            ),
            id: product.id,
            updatedAt: product.updatedAt,
            name: product.productName,
            isFavourite: !!product?.isFavourite,
          })),
      [items, searchProductsIds]
    );

    const sortedValues = useMemo(() => {
      const sortCopy = [...itemList];
      const favouritesRows = sortCopy.filter(item => item.isFavourite);
      const noFavouritesRows = sortCopy.filter(item => !item.isFavourite);

      const sort = (arr: typeof favouritesRows) => {
        if (sortColumn === 'Name') {
          return arr.sort((a, b) => (+sortNameAsc || -1) * b.name.localeCompare(a.name));
        } else if (sortColumn === 'Date') {
          if (!sortDateAsc) {
            return arr.sort((a, b) => +new Date(a.updatedAt ?? 0) - +new Date(b.updatedAt ?? 0));
          } else {
            return arr.sort((a, b) => +new Date(b.updatedAt ?? 0) - +new Date(a.updatedAt ?? 0));
          }
        }

        return arr;
      };

      const sortedFavouritesRows = sort(favouritesRows);
      const sortedNoFavouritesRows = sort(noFavouritesRows);

      return [...sortedFavouritesRows, ...sortedNoFavouritesRows];
    }, [sortColumn, sortNameAsc, sortDateAsc, itemList]);

    const tableBody = useMemo(
      () =>
        sortedValues.length ? (
          <>
            {sortedValues
              .filter(item => searchProductsIds.includes(item.id as string))
              .slice(0, lazyLoadingLength)
              .map(item => item.jsx)}
          </>
        ) : (
          <EmptyRow />
        ),
      [sortedValues, lazyLoadingLength]
    );

    const tableRef = useRef(null);

    function sortAlphabetically() {
      setSortColumn('Name');
      setSortNameAsc(!sortNameAsc);
    }

    function sortByDate() {
      setSortColumn('Date');
      setSortDateAsc(!sortDateAsc);
    }

    useEffect(() => {
      const sparkTable = new SparkTable(tableRef.current);
      return () => {
        sparkTable.remove();
      };
    }, [tableRef]);

    useResponsiveTable(tableRef, isBannerOpen);

    return (
      <>
        <TableContainer ref={tableRef} numberOfItems={sortedValues.length}>
          <section className="spark-table spark-table--condensed col-xs-12">
            <div
              className="spark-table__scroll"
              onScroll={evt => {
                const element = evt.target as HTMLDivElement;
                const isBottom = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 50;
                (document.querySelector('.spark-popover__toggle.active') as HTMLAnchorElement)?.click();
                if (isBottom) {
                  setLazyLoadingLength(prevState => prevState + LAZY_LOADING_INTERVAL);
                }
              }}
            >
              <table role="presentation">
                <thead>
                  <tr>
                    <ProductHeader data-sort="desc" sortAsc={sortNameAsc} onClick={sortAlphabetically}>
                      <FormattedMessage id="products.header.productName" />
                    </ProductHeader>
                    <ProductHeader>
                      <FormattedMessage id="products.header.type" />
                    </ProductHeader>
                    <ProductHeader data-sort="desc" sortAsc={!sortDateAsc} onClick={sortByDate}>
                      <FormattedMessage id="products.header.updatedAt" />
                    </ProductHeader>
                    <ProductHeader className="spark-text-center">
                      <FormattedMessage id="products.header.released" />
                    </ProductHeader>
                    <ProductHeader>
                      <FormattedMessage id="products.header.version" />
                    </ProductHeader>
                    <ProductHeader colSpan={2}>
                      <FormattedMessage id="products.header.status" />
                    </ProductHeader>
                  </tr>
                </thead>
                <tbody>{tableBody}</tbody>
              </table>
            </div>
          </section>
        </TableContainer>

        <ProcessScheme
          processScheme={processScheme}
          open={showProcessSchemeModal}
          setOpen={setShowProcessSchemeModal}
          currentStep={currentStep}
          isFailed={isFailed}
          isProposal={isProposal}
        />
      </>
    );
  }
);

const EmptyRow = () => {
  const colSpan = 6;
  return (
    <tr>
      <td colSpan={colSpan} className="spark-text-center spark-caps">
        <FormattedMessage id="myProducts.emptyList.message" />
      </td>
    </tr>
  );
};

const SPARK_TABLE_BCG = '#f6f6f7';

const TableContainer = styled.div<{numberOfItems: number}>`
  background-color: ${colors.white};

  & .col-xs-12 {
    padding: 0;
  }

  & td {
    padding-bottom: 0.4rem !important;
    padding-top: 0.4rem !important;
  }

  & tr.active td {
    background-image: none !important;
    border-style: solid !important;
    border-color: ${colors.grey300} !important;
  }

  & tr.active:not(:first-child) td {
    background-image: none !important;
  }

  & .spark-table tbody tr:first-child td {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 1rem) !important;
  }

  & .spark-table tbody tr:hover td {
    background-image: linear-gradient(to bottom, ${SPARK_TABLE_BCG} 0, ${SPARK_TABLE_BCG} 100%) !important;
  }

  & table {
    position: relative !important;
    width: 100%;
  }

  & thead {
    overflow: hidden;
    transform: translateY(-0.1rem);
    position: sticky !important;
    top: 0;
    z-index: 1 !important;
    ${({numberOfItems}) =>
      numberOfItems > NUMBER_OF_ITEMS_TO_SCROLL
        ? `box-shadow: 0 0.25rem 0.45rem -0 rgba(0, 0, 0, 0.5) !important;
          -webkit-box-shadow: 0 0.25rem 0.45rem 0 rgba(0, 0, 0, 0.5) !important;
          -moz-box-shadow: 0 0.25rem 0.45rem 0 rgba(0, 0, 0, 0.5) !important;`
        : ''}
  }
`;

const ProductHeader = styled.th<{sortAsc?: boolean}>`
  &&& {
    border-left: 0;
    border-right: 0;
    border-top: 1px solid ${colors.grey300};
    border-bottom: 1px solid ${colors.grey300};
    background-color: ${colors.white} !important;
    color: ${colors.black};
    font-size: 12px;
    font-weight: 400;
  }

  &::after {
    margin-left: 0.5rem;
    vertical-align: 1px !important;
    transition: 0.3s;
    transform: rotate(${props => (props.sortAsc ? '180deg' : '0deg')}) !important;
  }
`;
export default ProductsList;
