import {Icon} from '@sabre/spark-react-core';
import styled from 'styled-components';
import order from './images/order.jpg';
import redApp from './images/red-app.jpg';
import colors from '@scm/components/assets/colors';

const NotAuthorized = () => {
  return (
    <Container>
      <Header>
        <Icon name="lock-close" className="spark-icon--lg" />
        <HeaderHeading>You are not authorized</HeaderHeading>
        <HeaderDescription>
          You are not authorized to use the Sabre Central Marketplace Admin Centre. If you should be, and you see this
          message, please contact <a href="mailto:scm-support@sabre.com">scm-support@sabre.com</a>.
        </HeaderDescription>
      </Header>
      <Cards>
        <Card>
          <Link href="https://marketplace.sabre.com/">
            <Panel>
              <Masthead>
                <OrderImage />
              </Masthead>
              <PanelContent>
                <p>Order and browse Red Apps and other products in Sabre Central Marketplace.</p>
              </PanelContent>
              <PanelFooter>
                <Button>Sabre Central Marketplace</Button>
              </PanelFooter>
            </Panel>
          </Link>
        </Card>
        <Card>
          <Link href="https://partners.sabre.com/join-us">
            <Panel>
              <Masthead>
                <RedAppImage />
              </Masthead>
              <PanelContent>
                <p>Do you want to join us and become a Sabre Red App Provider?</p>
              </PanelContent>
              <PanelFooter>
                <Button>Join Us</Button>
              </PanelFooter>
            </Panel>
          </Link>
        </Card>
      </Cards>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  margin-top: 7rem;
  padding: 4% 1rem;
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 75%;
  --bgc-color: ${colors.grey50};
  --text-heading: ${colors.black};
  --text-card: ${colors.highlightGrey};
  --text: ${colors.grey900};
  --red: ${colors.sparkGreen};
  --gray: ${colors.grey50};
  --white: ${colors.white};
  --button-hover: ${colors.sparkGreen};
  --button-hover-text: ${colors.white};
  --button-red: ${colors.sparkGreen};
  --button-light-red: ${colors.sparkGreen};
`;

const Header = styled.header`
  text-align: center;
  max-width: 840px;
  margin: 0 auto;
`;

const HeaderHeading = styled.h1`
  position: relative;
  font-size: 2.5833333333rem;
  line-height: 3rem;
  font-feature-settings: 'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1;
  margin-bottom: 1rem;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: var(--text-heading);
  font-family: Roboto Condensed, sans-serif;
  font-weight: 700;

  ::after {
    content: '';
    position: static;
    display: block;
    height: 0.5rem;
    background: var(--red);
    width: 25%;
    min-width: 70px;
    max-width: 100px;
    margin: 1rem auto 2rem auto;
    border-radius: 0.5rem;
  }
`;

const HeaderDescription = styled.p`
  font-feature-settings: 'kern' 1, 'liga' 0;
  font-size: 1.3333333333rem;
  line-height: 2rem;
`;

const Cards = styled.section`
  padding-top: 4rem;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
`;

const Card = styled.div`
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 70%;
  justify-content: flex-start;
  display: -webkit-box;
  display: flex;
  flex-direction: column;
  transition: all 100ms ease-in-out;
  margin: auto;

  :hover {
    transform: translateY(-0.75rem);
  }
`;

const Link = styled.a`
  position: relative;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
  transition: color 150ms, background-color 150ms, padding-bottom 150ms, border-bottom 150ms;
`;

const Panel = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-bottom: 2rem;
  transition: all 100ms ease-in-out;
  border: 1px solid var(--gray);
  border-radius: 1rem;
  background-color: var(--white);
  box-shadow: 0 1px 12px 0 rgba(18, 19, 21, 0.08);
`;

const Masthead = styled.div`
  min-height: 8rem;
  overflow: hidden;
  padding-top: 50%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 0;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
`;

const Image = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: 0;
`;

const OrderImage = styled(Image)`
  background-image: url(${order});
`;

const RedAppImage = styled(Image)`
  background-image: url(${redApp});
`;

const PanelContent = styled.div`
  font-feature-settings: 'kern' 1, 'liga' 0;
  font-size: 1.3333333333rem;
  line-height: 2rem;
  text-align: center;
  padding: 1.5rem 5rem 1rem 5rem;
  color: var(--text-card);
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`;

const PanelFooter = styled.div`
  margin-top: auto;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
`;

const Button = styled.div`
  display: inline-block;
  margin: 1rem 2rem;
  position: relative;
  z-index: 1;
  font-size: 1.3333333333rem;
  line-height: calc(3rem - 3px);
  padding: 0 2rem;
  border-radius: 0.5rem;
  background-image: linear-gradient(225deg, var(--button-red) 0, var(--button-light-red) 100%);
  font-family: Roboto Condensed, sans-serif;
  font-weight: 700;
  transition: background-color 0.1s ease-in-out, background-image 0.1s ease-in-out, color 0.1s ease-in-out;
  background-color: var(--button-light-red);
  border: 3px solid var(--button-red);
  box-shadow: none;
  color: var(--white);
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  white-space: nowrap;

  :hover {
    background-image: linear-gradient(225deg, var(--button-hover) 0, var(--button-red) 100%);
    background-color: var(--button-hover);
    border: 3px solid var(--button-hover);
    color: var(--button-hover-text);
  }
`;

export default NotAuthorized;
