import React from 'react';
import {ContactDetails} from '../../../generated/onboardings';
import {
  Row,
  Separator,
} from '@scm/proposal/pages/proposalViewPage/proposalViewDataTab/proposalViewDataFields/ProposalViewDataDefinition';
import {FormattedMessage, useIntl} from 'react-intl';
import {TextInput} from '@sabre/spark-react-core';

const Contact = ({contactDetails}: {contactDetails: ContactDetails}) => {
  const {formatMessage} = useIntl();
  const {titleInCompany, phone, firstName, lastName, email} = contactDetails;
  return (
    <>
      <Separator className="spark-mar-l-2 spark-mar-b-2 spark-mar-t-2 spark-pad-b-1 spark-bold spark-large">
        <FormattedMessage id="proposals.onboardingRequestDetails.contact" />
      </Separator>
      <Row className="spark-mar-l-2">
        <TextInput
          value={firstName}
          name="firstName"
          disabled
          label={formatMessage({id: 'proposalDetails.firstName'})}
        />
      </Row>
      <Row className="spark-mar-l-2 spark-pad-t-1">
        <TextInput value={lastName} name="lastName" disabled label={formatMessage({id: 'proposalDetails.lastName'})} />
      </Row>
      <Row className="spark-mar-l-2 spark-pad-t-1">
        <TextInput
          value={titleInCompany}
          name="titleInCompany"
          disabled
          label={formatMessage({id: 'proposalDetails.titleInCompany'})}
        />
      </Row>
      <Row className="spark-mar-l-2 spark-pad-t-1">
        <TextInput value={email} name="email" disabled label={formatMessage({id: 'proposalDetails.email'})} />
      </Row>
      <Row className="spark-mar-l-2 spark-pad-t-1">
        <TextInput value={phone} name="phone" disabled label={formatMessage({id: 'proposalDetails.phone'})} />
      </Row>
    </>
  );
};
export default Contact;
