const benefits = {
  'benefits.title': 'Benefits of the Sabre Developer Partner Program',
  'benefits.numberOfRows': '5',
  'benefits.row1.icon': 'spark-icon-users',
  'benefits.row1.content':
    'The Sabre Developer Partner Program enables third-parties to develop & deploy solutions for Sabre connected travel agencies, by utilizing Sabre data and APIs. Authorization and prior approval must be given by Sabre.',
  'benefits.row2.icon': 'spark-icon-terminal',
  'benefits.row2.content':
    'Access to <a href="https://developer.sabre.com" target="_blank">Dev Studio</a> - Sabre\'s <strong>portfolio of services and tools</strong> that allows developers to build solutions. Access to a full suite of SOAP and REST APIs, testing tools, documentation, prototypes, sample code and more.',
  'benefits.row3.icon': 'spark-icon-users-global',
  'benefits.row3.content': 'Access to Sabre Global Marketplace with over <strong>425,000 travel agencies</strong>.',
  'benefits.row4.icon': 'spark-icon-data-bars',
  'benefits.row4.content':
    'Increase <strong>revenue opportunity</strong> with Sabre Central Marketplace - a self-service marketplace to find, buy and sell innovative travel solutions.',
  'benefits.row5.icon': 'spark-icon-user-documents',
  'benefits.row5.content':
    'Promotion and sales opportunity - participating in the Sabre Developer Partner Network provides exposure to our agency network and sales team, with the goal to find a potential partner for their business needs.',
  'benefits.process.title': 'Authorization Process',
  'benefits.step1.title': 'PROPOSAL',
  'benefits.step1.subtitle': 'Submit Solution Concept',
  'benefits.step2.title': 'SABRE/DEVELOPER INTRODUCTION',
  'benefits.step2.subtitle': 'Solution Overview',
  'benefits.step3.title': 'COMMERCIAL',
  'benefits.step3.subtitle': 'Review and Approval',
  'benefits.step4.title': 'APPROVAL',
  'benefits.step4.subtitle': 'Developer Agreement Signature and Access',
};

export default benefits;
