import HtmlToText from 'html-to-text';

const ELLIPSIS = '...';
const MAX_LENGTH = 140;
const CUT_LENGTH = MAX_LENGTH - ELLIPSIS.length;

export const richTextShortener = (richText: string) => {
  const el = document.createElement('div');
  el.innerHTML = richText;
  richText = Array.from(el.children).reduce((a, b) => a + b.textContent, '');
  richText = HtmlToText.htmlToText(richText);
  richText = richText.replace(/["']/g, '');
  richText = richText.replace(/(\r\n|\n|\r)/gm, '');

  return richText.length > MAX_LENGTH ? richText.slice(0, CUT_LENGTH) + ELLIPSIS : richText;
};
