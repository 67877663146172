import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ActionBadges, ExpandPanel, MultiSelect} from '@scm/components';
import {TextInput} from '@sabre/spark-react-core';
import {regions} from '@scm/components/assets/regionsAndCountries';
import styled from 'styled-components';
import {FieldValues, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import {CheckAllOptions, MultiSelectMainProps, SecondLevelOptions} from '@scm/components/form/multiSelect/MultiSelect';
import {MultiselectErrorMessage} from '@scm/proposal/pages/proposalForm/steps/definitionPage/definitionComponents/Category';
import {allName} from '../../../../proposalViewPage/proposalViewDataTab/ProposalDataValues';
import {countryCodesName, regionCodesName} from '../../../ProposalValues';
import {ActionBadgesDisplaysType} from '@scm/product-components/pages/productDetails/productTabs/ProductTabsContainer';
import colors from '@scm/components/assets/colors';

const Regionalization = ({
  actionBadgesDisplays,
  deleteCountryOrRegion,
  searchText,
  setSearchText,
  watch,
  setValue,
  countriesReduced,
  className,
  header,
  isDarkTheme,
  disabled,
  error,
}: {
  actionBadgesDisplays: ActionBadgesDisplaysType;
  deleteCountryOrRegion: (name: string) => void;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  countriesReduced: Array<Object>;
  className?: string;
  header?: boolean;
  isDarkTheme?: boolean;
  disabled?: boolean;
  error?: {message: string};
}) => {
  const {formatMessage} = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const checkAllOptions: CheckAllOptions = {
    checkAllLabel: formatMessage({
      id: 'tabs.regionalization.select.checkAllLabel',
    }),
    checkAllName: allName,
  };
  const secondLevelOptions: SecondLevelOptions = {
    optionsSecondLevel: countriesReduced,
    secondLevelName: countryCodesName,
  };
  const multiSelectMainProps: MultiSelectMainProps = {
    firstLevelName: regionCodesName,
    setValue: setValue as () => void,
    watch: watch,
    options: regions,
  };

  useEffect(() => {
    if (isOpen) {
      const searchInput = document.querySelector('input[name=searchText]') as HTMLInputElement;
      searchInput.focus();
    }
  }, [isOpen]);

  return (
    <>
      {header && (
        <>
          <p className="spark-mar-l-2 spark-mar-b-1 spark-bold spark-large">
            <FormattedMessage id="tabs.regionalization.header" />
          </p>
          <p className="spark-mar-l-2">
            <FormattedMessage id="tabs.regionalization.description" />
          </p>
        </>
      )}
      <ActionBadges badgeNames={actionBadgesDisplays()} deleteHandler={deleteCountryOrRegion} disabled={disabled} />
      <CountriesContainer className={className} onClick={evt => evt.stopPropagation()}>
        <TextInput
          name="searchText"
          value={searchText}
          label={formatMessage({
            id: 'tabs.regionalization.search.description',
          })}
          onChange={(_, value) => {
            setSearchText(value);
            setIsOpen(!!value);
          }}
        />
        <ExpandPanelContainer>
          <ExpandPanel
            panelHeader={formatMessage({
              id: 'tabs.regionalization.select.header',
            })}
            isDarkTheme={isDarkTheme}
            isOpen={isOpen}
          >
            <MultiSelect
              multiSelectMainProps={multiSelectMainProps}
              checkAllOptions={checkAllOptions}
              secondLevelOptions={secondLevelOptions}
              searchText={searchText.toLowerCase()}
              disabled={disabled}
            />
          </ExpandPanel>
          {error && (
            <MultiselectErrorMessage className="proposalViewComponent">{error.message}</MultiselectErrorMessage>
          )}
        </ExpandPanelContainer>
      </CountriesContainer>
    </>
  );
};

export const CountriesContainer = styled.div`
  & input {
    border: 1px solid ${colors.grey200} !important;
    border-right: none !important;
    border-bottom: none !important;
    border-radius: 0.25rem 0 0 0.25rem;
    box-shadow: 0 -5px 0 0 white;
    transform: translateX(-11px) translateY(1px);
  }
`;

export const ExpandPanelContainer = styled.div`
  position: relative;
  top: -71px;
  left: -11px;
  width: 81%;
  z-index: 0;

  & .spark-panel__header {
    border: 1px solid ${colors.grey200} !important;
  }
`;

export default Regionalization;
