import {Developer} from '../../../generated/management';
import DeveloperRow from './Developer';
import styled from 'styled-components';

const Developers = ({developers, sku, productName}: {developers: Developer[]; sku: string; productName: string}) => (
  <DevelopersList>
    {developers
      .sort((a, b) => a.pcc.localeCompare(b.pcc) || a.epr.localeCompare(b.epr))
      .map(developer => (
        <DeveloperRow
          key={`${developer.epr}-${developer.pcc}`}
          developer={developer}
          sku={sku}
          productName={productName}
        />
      ))}
  </DevelopersList>
);

const DevelopersList = styled.ul`
  list-style: none;
  margin: 0;
`;

export default Developers;
