import React, {useEffect, useState} from 'react';
import {authorizationApiBaseLink} from '../assets/apiLink';
import {AuthTokenApi, Configuration} from '../generated/authorization';
import {useIntl} from 'react-intl';
import {Loading} from '@scm/components';
import {HOME_URL} from '../utils/urls';
import qs from 'qs';
import styled from 'styled-components';
import {setToken} from '../utils/authentication';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {Message} from '@sabre/spark-react-core';
import HrefElement from '@scm/components/messaging/HrefElement';
import {redAppSupportEmail} from '@scm/components/utils/common';
import {useNavigate} from 'react-router-dom';
import InternalUrls from '@scm/components/utils/adminCentreUtils/internalUrls';

const getAuthToken = async (accessCode: string) => {
  return new AuthTokenApi(new Configuration({basePath: authorizationApiBaseLink})).getAuthToken({
    accessCode,
  });
};

export const Login = ({to = HOME_URL}: {to?: string}) => {
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  let query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const accessCode = query.code?.toString() ?? '';
  if (!loading) window.location.href = query.state?.toString() ?? to;

  const {formatMessage} = useIntl();

  async function handleData() {
    try {
      if (loading) {
        return await getAuthToken(accessCode);
      }
    } catch (e) {
      if (e.response.status === 400) {
        navigate(InternalUrls.IncorrectProfile);
      }
      if (e.response.status === 403) {
        navigate(InternalUrls.NotAuthorized);
      }
      e.response.json().catch(() => {});
      setIsError(true);
    }
  }

  useEffect(() => {
    if (accessCode) {
      handleData().then(login => {
        if (login?.jwtAccessToken) {
          setToken(login.jwtAccessToken);
          setLoading(false);
        }
      });
    }
  }, []);

  return isError && !loading ? (
    <Message
      content={formatMessage(
        {id: `login.error.description`},
        {
          email: <HrefElement email={redAppSupportEmail} />,
        }
      )}
      role={MessageRole.STATUS}
      status={MessageStatus.ERROR}
      title={formatMessage({id: `login.error.title`})}
      type={MessageType.PAGE}
      animate
    />
  ) : (
    <LoadingContainer>
      <Loading label={formatMessage({id: 'common.data.loading'})} />
    </LoadingContainer>
  );
};

export const LoadingContainer = styled.div`
  display: flex;
  position: relative;
  min-height: calc(100vh - 146px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none;
`;
