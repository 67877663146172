import React from 'react';
import styled from 'styled-components';
import {useIntl} from 'react-intl';

export const Pagination = ({
  allDataLength,
  currentElement,
  clickHandler,
  paginationInterval,
}: {
  allDataLength: number;
  currentElement: number;
  clickHandler: (evt: React.MouseEvent<HTMLAnchorElement>, moveIndex?: number) => void;
  paginationInterval: number;
}) => {
  const howManyTabs = Math.ceil(allDataLength / paginationInterval);
  const createPages = () => {
    let result = [];
    for (let i = 1; i <= howManyTabs; i++) {
      result.push(
        <a
          className={`spark-pagination__item ${i === currentElement + 1 ? 'spark-pagination__item--active' : ''}`}
          href="#"
          aria-label={`Go to page ${i}`}
          key={i}
          onClick={evt => {
            evt.preventDefault();
            clickHandler(evt);
          }}
        >
          {i}
        </a>
      );
    }
    return result;
  };

  const {formatMessage} = useIntl();

  return (
    <nav className="spark-pagination" aria-label="Tester Navigation">
      <div className="spark-pagination__pages spark-mar-r-0">
        {!!currentElement && (
          <a
            className="spark-pagination__arrow spark-pagination__arrow--previous"
            href="#"
            aria-label="Previous page"
            onClick={evt => {
              evt.preventDefault();
              if (!!currentElement) {
                clickHandler(evt, -1);
              }
            }}
          >
            <span className="spark-pagination__arrow-text">{formatMessage({id: 'common.previousButton'})}</span>
          </a>
        )}

        {createPages()}
        {currentElement !== userIndexToArrayIndex(howManyTabs) && (
          <a
            className="spark-pagination__arrow spark-pagination__arrow--next"
            href="#"
            aria-label="Next page"
            onClick={evt => {
              evt.preventDefault();
              if (currentElement < userIndexToArrayIndex(howManyTabs)) {
                clickHandler(evt, 1);
              }
            }}
          >
            <span className="spark-pagination__arrow-text">{formatMessage({id: 'common.nextButton'})}</span>
          </a>
        )}
      </div>
      <Results className="spark-pagination__results spark-mar-l-0">
        <div className="spark-pagination__summary">
          {`${paginationInterval * currentElement + 1} - ${
            currentElement < userIndexToArrayIndex(howManyTabs)
              ? paginationInterval * (currentElement + 1)
              : allDataLength
          } of ${allDataLength} results
          `}
        </div>
      </Results>
    </nav>
  );
};

const userIndexToArrayIndex = (index: number) => index - 1;

const Results = styled.div`
  min-width: 200px;
`;
