import {FormattedMessage, useIntl} from 'react-intl';
import {Star, TextEditor} from '@scm/components';
import React, {useContext} from 'react';
import {isAllowedToEditProducts} from '@scm/authentication/utils/authentication';
import useGetIsTextRequired from '../../utilities/useGetIsTextRequired';
import {Checkbox} from '@sabre/spark-react-core';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import useGetIsCreatedAfter from '../../utilities/useGetIsCreatedAfter';
import {
  keyBenefitsErrorName,
  keyBenefitsName,
  KeyBenefitsTabContext,
} from '../../../../../forms/product-tabs-forms/KeyBenefitsTab';
import {tabMaxLength} from '../additional-information/AdditionalInformation';
import {CONTENT_TABS_REQUIRED_SINCE_DATE} from '@scm/components/utils/common';
import useGetIsObsolete from '../../../../../hooks/useGetIsObsolete';

const KeyBenefits = ({isRequired}: {isRequired: boolean}) => {
  const keyBenefitsMinLength = 150;

  const isObsolete = useGetIsObsolete();
  const {formatMessage} = useIntl();
  const isAllowedToEdit = !isObsolete && isAllowedToEditProducts();
  const {
    setValue,
    watch,
    setKeyBenefitsRichTextInitialValue,
    keyBenefitsShowToLoggedInitialValue,
    setKeyBenefitsShowToLoggedInitialValue,
    isKeyBenefitsTouched,
  } = useContext(KeyBenefitsTabContext);
  const isTextRequired =
    useGetIsTextRequired() && useGetIsCreatedAfter(CONTENT_TABS_REQUIRED_SINCE_DATE) && isKeyBenefitsTouched;
  const minCharacter =
    useGetIsTextRequired() && useGetIsCreatedAfter(CONTENT_TABS_REQUIRED_SINCE_DATE) ? keyBenefitsMinLength : undefined;

  return (
    <>
      <p className="spark-mar-b-0 spark-caps">
        <FormattedMessage id="productDetails.content.keyBenefits.label" />
        {isRequired && <Star />}
      </p>
      <p className="spark-mar-b-1 spark-small">
        <FormattedMessage id="productDetails.content.keyBenefits.additionalText" />
      </p>
      <StyledCheckbox
        className="spark-mar-l-.5 spark-small"
        label={formatMessage({
          id: 'productDetails.content.tabs.showOnlyToLoggedInUsers.label',
        })}
        onChange={() => setKeyBenefitsShowToLoggedInitialValue(!keyBenefitsShowToLoggedInitialValue)}
        checked={keyBenefitsShowToLoggedInitialValue ?? false}
        disabled={!isAllowedToEdit}
      />
      <TextEditor
        editorState={watch(keyBenefitsName)}
        setValue={setValue}
        valueName={keyBenefitsName}
        maxCharacters={tabMaxLength}
        minCharacters={minCharacter}
        disabled={!isAllowedToEdit}
        errorName={keyBenefitsErrorName}
        isRequired={isTextRequired}
        setInitialState={setKeyBenefitsRichTextInitialValue}
        enableHyperLinks
      />
    </>
  );
};

export const StyledCheckbox = styled(Checkbox)`
  & .spark-label {
    color: ${colors.grey900} !important;
  }
`;

export default KeyBenefits;
