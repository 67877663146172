const definition = {
  'definition.title': 'Definition',
  'definition.additionalStatement': 'Please provide basic information about your Red App.',
  'definition.redAppName.label': 'Red App Name <star>*</star>',
  'definition.redAppName.placeholder': 'This name cannot be changed after your proposal is approved.',
  'definition.redAppName.title': 'Red App Name',
  'definition.redAppName.notUniqueOrInvalidName': 'This name is invalid or claimed already.',
  'definition.redAppName.firstParagraph':
    "Your Red App name should be descriptive enough for customers to figure out what the app is/does. The name of the Red App should be between 3 and 50 characters, allowed special characters are: ().,:'-@",
  'definition.redAppName.firstParagraph.scribeScriptPfKeys':
    'Use a name that is descriptive enough for customers to understand what the app does in 1-3 word. Note that “Red App” always follows the name.',
  'definition.redAppName.secondParagraph':
    'Company names usually work well. If there is a need to add an attribute, make sure it covers the whole scope of the capabilities offered.',
  'definition.redAppName.thirdParagraph':
    'The Red App name should not limit the possibilities for other players who may have similar Red App offerings.',
  'definition.redAppName.nameVerificationErrorTitle': 'Warning',
  'definition.redAppName.nameVerificationErrorMessage': 'Something went wrong, please contact our support team.',
  'definition.popover.ariaLabel': 'Open popover button.',
  'definition.description.label': 'Description',
  'definition.description.onboarding.additionalText':
    'Please provide a description for your Red App (what it will be used for, what functionalities it has, key benefits and who the target user is).',
  'definition.description.additionalText':
    'Please provide a description of your Red App that will be available on your storefront in the Marketplace.',
  'definition.category.label': 'Select category <star>*</star>',
  'definition.flowDiagram.label': 'Please upload a flow diagram of how an agency would use your red app',
  'definition.flowDiagram.additionalText': 'Upload *.doc, *.docx or *.pdf file up to 10MB.',
  'definition.detailInformation.RedApp.mainLabel': 'Provide detail information if it applies to your Red App:',
  'definition.detailInformation.RedApp.firstLabel': 'My Red App interacts with end traveler in any way',
  'definition.detailInformationDescription.RedApp.firstLabel':
    'Please describe how will this app interact with the agency and/or traveler <star>*</star>',
  'definition.detailInformation.RedApp.secondLabel': 'My Red App provides/executes PNR documentation',
  'definition.detailInformationDescription.RedApp.secondLabel':
    'Please describe how will this app provides/executes PNR documentation <star>*</star>',
  'definition.detailInformation.Gds.mainLabel': 'Provide detail information if it applies to you:',
  'definition.detailInformation.Gds.label': 'I connect to any GDS today',
  'definition.detailInformationDescription.Gds.label': 'Please provide the details <star>*</star>',
  'definition.developmentType.label': 'My development type',
  'definition.technologies.label': 'Select the technologies you will be using to build your Red App',
  'definition.technologiesDescription.label': 'Provide other technologies, not listed above <star>*</star>',
  'definition.scribeScripts.fileUpload.label':
    'Upload the <strong>visible Scribe Scripts</strong> file. <star>*</star>',
  'definition.scribeScripts.fileUpload.additionalText': 'Upload *.ssc files up to 1MB.',
  'definition.hiddenScribeScripts.fileUpload.additionalText': 'Upload *.ssc files up to 1MB.',
  'definition.resources.fileUpload.additionalText': 'Upload *.csv, .txt, .xml, .xls, .xlsx, .mbd files up to 1MB.',
  'definition.activeListening.fileUpload.additionalText': 'Upload *.properties files up to 1MB.',
  'definition.hiddenScribeScripts.fileUpload.label':
    'Do you need to upload a <strong>hidden Scribe Scripts</strong> file?',
  'definition.resources.fileUpload.label': 'Do you need to upload <strong>resources</strong>?',
  'definition.activeListening.fileUpload.label': 'Do any of the scripts use <strong>active listening</strong>?',
  'definition.scribeScripts.fileUpload.description': '<em>It will be available to all users.</em>',
  'definition.hiddenScribeScripts.fileUpload.description':
    '<em>Hidden Scribe Scripts are not available to users, only other Scribe Scripts can use them.</em>',
  'definition.resources.fileUpload.description':
    '<em>If your Scribe Script uses resources, like a .csv, .txt or other file, you need to upload them as well. Path to these files is "/Scripts/Resourcess/", so relative path from script is "../Resourcess"</em>',
  'definition.activeListening.fileUpload.description':
    '<em>The Active Listening feature allows emulator formats (commands) and host responses to trigger predefined Scribe Scripts. Formats or responses that should trigger a script are configured in an Active Listening Configuration File. The Active Listening Configuration File is a standard Java properties file that contains information on mapping between commands and Scribe Scripts.</em>',
  'definition.activeListening.fileUpload.description.secondParagraph':
    "<em>Please provide your command.properties file. If you don't have it ask your Script developer to provide it. Each single configuration needs to be in separate line.</em>",
  'definition.prodTesters.title': 'PROD TESTERS',
  'definition.prodTesters.info': 'Provide 0 - 50 beta customers who will test your Red App on PRODUCTION environment.',
  'definition.prodTesters.addButton.label': '+ Add New Tester',
  'definition.scribeScripts.submit.title': 'Thank you for submitting the Scribe Scripts',
  'definition.scribeScripts.submit.message':
    'Within a few minutes you should get a message that your Scribe Scripts is ready for testing on PROD.',
  'definition.scribeScripts.error.title': 'Updates failed',
  'definition.pfKeys.error.message':
    'PF Keys not defined, or cannot be downloaded automatically. Please export configuration from Sabre Red 360 and upload files manually',
  'definition.scribeScripts.error.message': 'Updates cannot be saved, please try again or contact {email}',
  'definition.pfKeys.fileSource.label': 'Select the PF Keys File Source <star>*</star>:',
  'definition.pfKeys.fileSource.SabreRedServer.description':
    '<em>PF Keys that are associated with your PCC and EPR will be automatically downloaded from Sabre Red 360 server. Make sure that your PF Keys are saved in the Sabre Red 360. If you want to upload a local PF Keys file, please select "Local" option.</em>',
  'definition.pfKeys.fileSource.Local.description':
    '<em>You can upload your PF Keys files. Use "Export" option from PF Keys panel in Sabre Red 360 to obtain the PF Keys file.</em>',
  'definition.pfKeys.fileNameChoice.label':
    'Which name should be displayed in Sabre Red 360 for the PF Keys file? <star>*</star>',
  'definition.pfKeys.fileNameChoice.info.title': 'Displayed Names',
  'definition.pfKeys.fileNameChoice.info.firstParagraph':
    'Pretend the name of your Red app is <strong>Best Red App</strong>, and you upload two PF Keys files named <strong>First.pfkey</strong> and <strong>Second.pfkey</strong>.',
  'definition.pfKeys.fileNameChoice.info.secondParagraph':
    'The names of the PF Keys files in Sabre Red 360 would be displayed as:',
  'definition.pfKeys.fileNameChoice.info.thirdParagraph':
    'If you choose <em>Red App Name</em>: <strong>Best Red App 1</strong> and <strong>Best Red App 2</strong> (Numbers are automatically added to the end for multiple files.)',
  'definition.pfKeys.fileNameChoice.info.fourthParagraph': 'Or',
  'definition.pfKeys.fileNameChoice.info.fifthParagraph':
    'If you choose <em>Original File Name</em>: <strong>First</strong> and <strong>Second</strong>.',
  'definition.pfKeys.fileUpload.description': 'Upload *.pfkey files up to 1MB',
  'definition.pfKeys.submit.title': 'Thank you for submitting the PF Keys',
  'definition.pfKeys.submit.message':
    'Within a few minutes you should get a message that your PF Keys is ready for testing on PROD.',
};

export default definition;
