import React, {ReactElement, useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProposalContext} from '../../../../ProposalForm';
import {Controller} from 'react-hook-form';
import {createNestedObjectName} from '../servicesComponents/Services';
import {
  accountingAttributesDescriptionName,
  AccountingDataType,
  accountingDataTypeName,
  accountingName,
} from '../../ServiceAndSecurityValues';
import {Checkbox, CheckboxGroup} from '@sabre/spark-react-core';
import {Star} from '@scm/components';
import {serviceAndSecurityValuesName} from '../../../../ProposalValues';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {TextArea} from '../../../definitionPage/definitionComponents/Description';
import Legend from '@scm/components/accessibility/Legend';
const Accounting = () => {
  const {formatMessage} = useIntl();
  const {control, watch, errors, setValue} = useContext(ProposalContext);
  const descriptionMaxLength = 250;

  return (
    <>
      <Controller
        name={createNestedObjectName(accountingName)}
        control={control}
        render={({field: {onChange, onBlur, value}}): ReactElement => (
          <Checkbox
            onBlur={onBlur}
            onChange={event => {
              onChange(event);
              if (value) {
                setValue(createNestedObjectName(accountingAttributesDescriptionName), '', {shouldValidate: true});
                setValue(createNestedObjectName(accountingDataTypeName), [], {shouldValidate: true});
              }
            }}
            checked={value}
            label={formatMessage({id: 'serviceAndSecurity.accounting.label'})}
            className="spark-mar-b-2 spark-mar-t-2"
          />
        )}
      />
      {watch(createNestedObjectName(accountingName)) && (
        <div className="spark-pad-l-2">
          <p className="spark-mar-b-1 spark-mar-r-2">
            <FormattedMessage id="serviceAndSecurity.accountingData.label" />
            <Star hasColon />
          </p>
          <Controller
            name={createNestedObjectName(accountingDataTypeName)}
            control={control}
            render={({field: {onChange, name, value}}): ReactElement => (
              <CheckboxGroup
                name={name}
                value={value}
                onChange={(_, activeItems): void => onChange(activeItems)}
                status={
                  errors[serviceAndSecurityValuesName]?.[accountingDataTypeName] ? MessageStatus.ERROR : undefined
                }
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[accountingDataTypeName] &&
                  errors[serviceAndSecurityValuesName]?.[accountingDataTypeName]?.message
                }
              >
                <Legend text={formatMessage({id: 'serviceAndSecurity.accountingData.label'})} />
                {Object.values(AccountingDataType).map(technology => (
                  <Checkbox key={technology} label={technology} value={technology} />
                ))}
              </CheckboxGroup>
            )}
          />
          <Controller
            name={createNestedObjectName(accountingAttributesDescriptionName)}
            control={control}
            render={({field}): ReactElement => (
              <TextArea
                {...field}
                label={formatMessage(
                  {id: 'serviceAndSecurity.description.label'},
                  {star: word => <span className="spark-danger">{word}</span>}
                )}
                characterCount
                multiLine
                maxLength={descriptionMaxLength}
                className="spark-mar-r-1 spark-mar-b-1 spark-mar-t-1"
                status={
                  errors[serviceAndSecurityValuesName]?.[accountingAttributesDescriptionName]
                    ? MessageStatus.ERROR
                    : undefined
                }
                statusMessage={
                  errors[serviceAndSecurityValuesName]?.[accountingAttributesDescriptionName] &&
                  errors[serviceAndSecurityValuesName]?.[accountingAttributesDescriptionName]?.message
                }
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default Accounting;
