/* tslint:disable */
/* eslint-disable */
/**
 * Developer Partner Network – Authorization
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationErrors,
  AuthorizationResponse,
} from '../models';
import {
    AuthorizationErrorsFromJSON,
    AuthorizationErrorsToJSON,
    AuthorizationResponseFromJSON,
    AuthorizationResponseToJSON,
} from '../models';

export interface GetAuthTokenRequest {
    accessCode: string;
}

/**
 * 
 */
export class AuthTokenApi extends runtime.BaseAPI {

    /**
     */
    async getAuthTokenRaw(requestParameters: GetAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizationResponse>> {
        if (requestParameters.accessCode === null || requestParameters.accessCode === undefined) {
            throw new runtime.RequiredError('accessCode','Required parameter requestParameters.accessCode was null or undefined when calling getAuthToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.accessCode !== undefined) {
            queryParameters['accessCode'] = requestParameters.accessCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationResponseFromJSON(jsonValue));
    }

    /**
     */
    async getAuthToken(requestParameters: GetAuthTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizationResponse> {
        const response = await this.getAuthTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
