import React from 'react';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {convertToURL} from './ProductTabsContainer';
import {useIntl} from 'react-intl';

const TabName = ({activeTabName, tabName, icon}: {activeTabName: string; tabName: string; icon?: JSX.Element}) => {
  const isActiveTab = () => convertToURL(activeTabName) === convertToURL(tabName);
  const {formatMessage} = useIntl();

  return (
    <Name
      isActiveTab={isActiveTab()}
      aria-label={formatMessage({id: 'productDetails.tab.accessibilityLabel'}, {tabName})}
    >
      {tabName}
      {icon}
    </Name>
  );
};

const Name = styled.a.attrs(() => ({
  className: 'spark-caps spark-epsilon',
  tabIndex: 0,
  role: 'link',
}))<{
  isActiveTab: boolean;
}>`
  display: block;
  padding: 1rem;
  border-radius: 0.16666rem;
  background-color: ${props => (props.isActiveTab ? `${colors.grey700} !important` : colors.white)};
  color: ${props => (props.isActiveTab ? colors.white : colors.highlightGrey)} !important;
  font-weight: 500;
  :hover {
    background-color: ${colors.grey100};
  }
`;

export default TabName;
