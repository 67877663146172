import {Confirmation} from '@scm/product-components/pages/storefrontData/productDetailsUtils';

export const blockOrderingAndHideProductImmediatelyName = 'blockOrderingAndHideProductImmediately';
export const blockOrderingAndHideProductDateName = 'blockOrderingAndHideProductDate';
export const sunsetDateAsapName = 'sunsetDateAsap';
export const sunsetDateName = 'sunsetDate';
export const sendNotificationConfirmationName = 'sendNotificationConfirmation';
export const sendNotificationDateAsapName = 'sendNotificationDateAsap';
export const sendNotificationDateName = 'sendNotificationDate';
export const sendNotificationTextName = 'sendNotificationText';

export interface RequestSunsetValues {
  [blockOrderingAndHideProductImmediatelyName]: Confirmation | null;
  [blockOrderingAndHideProductDateName]: string;
  [sunsetDateAsapName]: Confirmation | null;
  [sunsetDateName]: string;
  [sendNotificationConfirmationName]: Confirmation | null;
  [sendNotificationDateAsapName]: Confirmation;
  [sendNotificationDateName]: string;
  [sendNotificationTextName]: string;
}
