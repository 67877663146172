import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
const AdditionalStatement = ({id}: {id: string}) => (
  <Statement className="spark-mar-b-1">
    <FormattedMessage id={id} />
  </Statement>
);

const Statement = styled.p`
  color: ${colors.grey700};
  font-weight: bold;
  font-size: 1.3333333333rem;
  margin-bottom: 1rem;
`;

export default AdditionalStatement;
