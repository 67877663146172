import * as yup from 'yup';
import contactDetails from './schema-parts/contactDetails.schema';
import companyInformation from './schema-parts/companyInformation.schema';
import {PCC_MAX_LENGTH, PCC_REGEX} from '@scm/components/pccEpr/pccEprSchema';
// this is fixed schema of OnboardDeveloperPartner request body, it must be changed manually, accordingly to changes in onboardings-api.yaml,
// because script that generates yup definitions cannot process multipart/mixed requestBody, only application/json

export default yup.object().shape({
  contactDetails,
  companyInformation: yup
    .object()
    .required()
    .shape({
      ...companyInformation,
      website: yup
        .string()
        .required()
        .max(100)
        .matches(/^(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)$/),
    }),
  sabreCustomerAccountInfo: yup.object().shape({
    pcc: yup.string().required().matches(PCC_REGEX).max(PCC_MAX_LENGTH),
    name: yup.string().required().max(100).min(1),
  }),
  partnerPricingDetails: yup.object().shape({
    technicalSupport: yup.mixed().oneOf(['Unlimited', 'Paid per incident']).required(),
    distributionType: yup.mixed().oneOf(['Global', 'Regional', 'Market']).required(),
    regions: yup
      .array()
      .max(3)
      .of(yup.mixed().oneOf(['APAC', 'EMEA', 'LAC', 'NAM'])),
    countryCodes: yup
      .array()
      .max(2)
      .of(yup.string().matches(/^[A-Z]{2}$/)),
  }),
});
