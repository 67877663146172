import {ProdBetaTestersContext, testerWithNumber} from '../../../provider/ProdBetaTestersProvider';
import {useIntl} from 'react-intl';
import React, {useContext, useState} from 'react';
import {NewTesterContext} from '../../sections/prod-testers/ProdTesters';
import {AddBetaTesterRequest, BetaTesterStatusEnum, Configuration, ProductsApi} from '../../../generated/certification';
import {certificationApiBaseLink} from '../../../assets/apiBaseLink';
import {getAccessToken, isAllowedToAddRemoveTesters} from '@scm/authentication/utils/authentication';
import {useParams} from 'react-router-dom';
import {closeCurrentToasts, createMessageString, openToast} from '@scm/components/messaging/openToast';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {ErrorStatuses} from '../../../error/ErrorStatuses';
import {hrefStringCreator} from '@scm/components/messaging/HrefElement';
import {dateWithHourNoSeconds, redAppSupportEmail} from '@scm/components/utils/common';
import {Button, Icon, TextInput} from '@sabre/spark-react-core';
import Tooltip from '@sabre/spark-react-core/tooltip';
import {format} from 'date-fns';
import styled from 'styled-components';
import colors from '@scm/components/assets/colors';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';
import {
  EPR_MAX_LENGTH,
  EPR_MIN_LENGTH,
  EPR_REGEX,
  PCC_MAX_LENGTH,
  PCC_MIN_LENGTH,
  PCC_REGEX,
} from '@scm/components/pccEpr/pccEprSchema';

enum FormButton {
  Cancel = 'Cancel',
  Submit = 'Submit',
}

const ProdTesterRow = ({
  tester,
  deleteTestersHandler,
  isEditedRow,
}: {
  tester: testerWithNumber;
  deleteTestersHandler: (nr: string) => void;
  isEditedRow?: boolean;
}) => {
  const productDetailsName = 'productDetails.updates';

  const {formatMessage} = useIntl();
  const [isPccValid, setIsPccValid] = useState(false);
  const [isPccTouched, setIsPccTouched] = useState(false);
  const [isSabreIdValid, setIsSabreIdValid] = useState(false);
  const [isSabreIdTouched, setIsSabreIdTouched] = useState(false);
  const {newTester, setNewTester} = useContext(NewTesterContext);
  const {setLoading, prodBetaTesters} = useContext(ProdBetaTestersContext);

  function chooseCorrectMessage(description: String) {
    return description === 'No access to PCC'
      ? 'productDetails.fetch.testerListUpdateFailedDueToNoPccAccess.description'
      : 'productDetails.fetch.testerListUpdateFailed.description';
  }

  function determineIconFromStatus(status: BetaTesterStatusEnum) {
    switch (status) {
      case BetaTesterStatusEnum.WaitingForTestProd:
        return <Tooltip toggleEl={<Icon name="clock" data-testid="waitingStatus" />}>{status}</Tooltip>;
      case BetaTesterStatusEnum.AssignedOnProd:
        return (
          <Tooltip
            toggleEl={<Icon name="check" data-testid="assignedStatus" className="spark-success" iconStyle="fill" />}
          >
            {status}
          </Tooltip>
        );
      case BetaTesterStatusEnum.Error:
        return (
          <Tooltip toggleEl={<Icon name="close" className="spark-danger" iconStyle="fill" />}>
            {'EPR could not be assigned'}
          </Tooltip>
        );
      default:
        return <div></div>;
    }
  }

  const changeHandler = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fieldName = evt.target.name;
    if (newTester) {
      setNewTester({...newTester, [fieldName]: evt.target.value.toUpperCase()});
    }

    if (fieldName === 'pcc' && !isPccTouched) {
      setIsPccTouched(true);
    }

    if (fieldName === 'epr' && !isSabreIdTouched) {
      setIsSabreIdTouched(true);
    }
  };

  const deleteHandler = async () => deleteTestersHandler(tester.nr);

  async function addBetaTester(addBetaTesterRequest: AddBetaTesterRequest) {
    return new ProductsApi(
      new Configuration({
        basePath: certificationApiBaseLink,
        accessToken: getAccessToken() || '',
        middleware: middleware,
      })
    ).addBetaTester(addBetaTesterRequest);
  }

  const testerExists = () =>
    prodBetaTesters.some(tester => tester.pcc === newTester?.pcc && tester.epr === newTester?.epr);

  const sku = useParams().appName || '';

  const onSubmit = async () => {
    closeCurrentToasts();
    if (testerExists() || !newTester?.pcc || !newTester?.epr) {
      openToast(
        formatMessage({id: 'productDetails.fetch.errorTitle'}),
        formatMessage({id: 'productDetails.fetch.testerExists.description'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
      return;
    }

    const addBetaTesterOperationRequest = {
      id: sku,
      betaTesterRequest: {
        pcc: newTester?.pcc,
        epr: newTester?.epr,
      },
    };

    try {
      await addBetaTester(addBetaTesterOperationRequest);
    } catch (err) {
      const data = await err.response.json();
      const errorDescription = data?.description;
      if (err.response.status === ErrorStatuses['Unprocessable Content']) {
        openToast(
          createMessageString(formatMessage, productDetailsName, true, true),
          formatMessage(
            {id: chooseCorrectMessage(errorDescription)},
            {
              email: hrefStringCreator(redAppSupportEmail),
            }
          ),
          ToastType.WARNING,
          'spark-icon-alert-triangle',
          true
        );
      }
    } finally {
      setLoading(true);
    }
  };

  return (
    <tr>
      {isEditedRow ? (
        <>
          <TableCell isMiddleColumn>
            <TesterTextInput
              value={tester.pcc}
              name="pcc"
              maxLength={PCC_MAX_LENGTH}
              minLength={PCC_MIN_LENGTH}
              onChange={changeHandler}
              validatePattern={PCC_REGEX.source}
              validateOnChange
              onValidate={setIsPccValid}
              $isInputValid={!isPccValid && isPccTouched}
              label="Tester pcc"
            />
          </TableCell>
          <TableCell isMiddleColumn>
            <TesterTextInput
              value={tester.epr}
              name="epr"
              maxLength={EPR_MAX_LENGTH}
              minLength={EPR_MIN_LENGTH}
              onChange={changeHandler}
              validatePattern={EPR_REGEX.source}
              validateOnChange
              onValidate={setIsSabreIdValid}
              $isInputValid={!isSabreIdValid && isSabreIdTouched}
              label="Tester epr"
            />
          </TableCell>
          <TableCell>
            {tester.creationDateTime && format(new Date(tester.creationDateTime), dateWithHourNoSeconds)}
          </TableCell>
          <TableCell>{tester.status && determineIconFromStatus(tester.status)}</TableCell>
          <TableCell className="spark-table__actions spark-text-right">
            <>
              <Button
                secondary
                size={ButtonSize.EXTRA_SMALL}
                className="spark-mar-r-1"
                onClick={() => setNewTester(null)}
              >
                {FormButton.Cancel}
              </Button>
              <Button
                size={ButtonSize.EXTRA_SMALL}
                type="button"
                disabled={!isPccValid || !isSabreIdValid}
                progress
                onClick={async (_, success, failure) => {
                  if (success && failure) {
                    try {
                      await onSubmit();
                      success();
                    } catch (_) {
                      failure();
                    }
                  }
                }}
                data-testid="testersSubmitButton"
              >
                {FormButton.Submit}
              </Button>
            </>
          </TableCell>
        </>
      ) : (
        <>
          <TableCell isMiddleColumn>{tester.pcc}</TableCell>
          <TableCell isMiddleColumn>{tester.epr}</TableCell>
          <TableCell>
            {tester.creationDateTime && format(new Date(tester.creationDateTime), dateWithHourNoSeconds)}
          </TableCell>
          <TableCell>{tester.status && determineIconFromStatus(tester.status)}</TableCell>
          <TableCell isMiddleColumn className="spark-table__actions spark-text-right">
            {isAllowedToAddRemoveTesters() && (
              <IconContainer
                onClick={deleteHandler}
                role="button"
                aria-label="Delete"
                tabIndex={0}
                onKeyDown={evt => {
                  if (evt.key === 'Enter') {
                    deleteHandler();
                  }
                }}
              >
                <Icon name="trash" />
              </IconContainer>
            )}
          </TableCell>
        </>
      )}
    </tr>
  );
};

const TableCell = styled.td<{
  isMiddleColumn?: boolean;
}>`
  border: none !important;
  border-bottom: 1px solid ${colors.grey300} !important;
  background-image: none !important;
  min-width: ${props => (props.isMiddleColumn ? '270px !important;' : '')}

  tr:hover & {
    background-color: ${colors.grey100} !important;
  }

  & input {
    width: 50% !important;
  }
`;

const TesterTextInput = styled(TextInput)<{$isInputValid?: boolean}>`
  input {
    padding: 1.25rem 1rem !important;
    height: 4rem;
    text-transform: uppercase;
    font-size: 1rem;
    border-color: ${props => (props.$isInputValid ? colors.red : colors.tierSilver)};
  }

  .spark-label {
    opacity: 0;
  }
`;

const IconContainer = styled.div`
  padding-left: 121.356px !important;
`;

export default ProdTesterRow;
