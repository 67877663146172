import styled from 'styled-components';
import React from 'react';
import Headers from './headers/Headers';
import {useSections} from './sections';
import {isSupportRole} from '@scm/authentication/utils/authentication';
import AuditLogsProvider from '@scm/product-details/provider/AuditLogsProvider';
import {convertToURL} from '@scm/product-details/page/content/sections/headers/Headers';
import SectionsLoading from './loading/SectionsLoading';

const SectionsContainer = () => (
  <AuditLogsProvider>
    <SectionsLoading>
      <div className="spark-mar-r-2 spark-mar-l-2 spark-mar-t-2">
        {isSupportRole() && (
          <Tabs className="spark-panel spark-mar-b-2">
            <div className="spark-panel__content">
              <Headers />
            </div>
            <div className="spark-pad-l-2 spark-pad-r-2 spark-pad-b-1">
              <Sections />
            </div>
          </Tabs>
        )}
      </div>
    </SectionsLoading>
  </AuditLogsProvider>
);

const slash = '/';

const Sections = () => {
  const sections = useSections();
  const activeTabName = location.pathname.slice(location.pathname.lastIndexOf(slash) + 1);

  return sections.find(section => convertToURL(section.name) === convertToURL(activeTabName))?.content ?? null;
};

const Tabs = styled.div`
  min-height: 470px;
  position: relative;
  border: none;
`;

export default SectionsContainer;
