import styled from 'styled-components';
import React from 'react';
import ExternalUrls from '@scm/components/utils/externalUrls';
import {useIntl} from 'react-intl';
import InternalUrls from 'utils/internalUrls';
import ExternalLink from 'components/ExternalLink';
import {useMediaQuery} from 'react-responsive';
import colors from '@scm/components/assets/colors';
import {maxMobileWidthQuery} from '@scm/components/assets/deviceWidth';
import {config} from "../../../config/config";
import {domains} from "@scm/components/utils/domains";

const {SabreCentral, Marketplace} = ExternalUrls;
const {Home, PartnerNetwork, AdminCentre} = InternalUrls;

const DropdownItems = ({onClick}: {onClick: () => void}) => {
  const {formatMessage} = useIntl();
  const isMobile = useMediaQuery(maxMobileWidthQuery);

  const sabreCentralLabel = formatMessage({id: 'header.sabreCentral'});
  const marketPlaceLabel = formatMessage({id: 'header.marketPlace'});
  const partnerNetworkLabel = formatMessage({id: 'header.partnerNetwork'});
  const adminCentreLabel = formatMessage({id: 'header.adminCentre'});

  const partnerNetworkLink = domains.includes(window.location.origin) ? PartnerNetwork : Home;

  return (
    <ul className="spark-menu__list spark-menu__list--main spark-header__list spark-header__list--overflow">
      <DropdownItem to={SabreCentral} label={sabreCentralLabel} ariaLabel={sabreCentralLabel} onClick={onClick} />
      <DropdownItem to={Marketplace} label={marketPlaceLabel} ariaLabel={marketPlaceLabel} onClick={onClick} />
      {/*TODO: For now its hidden on prod*/}
      {config.envName !== 'prod' && <DropdownItem to={AdminCentre} label={adminCentreLabel} ariaLabel={adminCentreLabel} onClick={onClick}/>
      }
      <ActiveItem mobile={isMobile}>
        <a className="spark-menu__list-link spark-caps" href={partnerNetworkLink}>
          {partnerNetworkLabel}
        </a>
      </ActiveItem>
    </ul>
  );
};

const DropdownItem = ({
  to,
  label,
  ariaLabel,
  onClick,
}: {
  to: string;
  label: string;
  ariaLabel: string;
  onClick?: () => void;
}) => (
  <li className="spark-menu__list-item" role="menuitem" onClick={onClick}>
    <ExternalLink to={to} className="spark-menu__list-link spark-caps" ariaLabel={ariaLabel}>
      {label}
    </ExternalLink>
  </li>
);

const ActiveItem = styled.li.attrs(() => ({
  className: 'spark-menu__list-item',
}))<{mobile?: boolean}>`
  a {
    color: ${colors.sparkBlue} !important;
    font-weight: bold;
    background-color: ${props => (props.mobile ? '' : 'white !important;')};

      :hover {
        background-color: ${colors.grey100} !important;
      }
    }
  }
`;

export default DropdownItems;
