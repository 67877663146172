import {Action} from 'redux';
import {makeActionCreator} from 'utils/reduxUtils';
import {
  PARTNER_DIRECTORY_ADD_FILTER_ACTION,
  PARTNER_DIRECTORY_REFETCH_ACTION,
  PARTNER_DIRECTORY_REMOVE_FILTER_ACTION,
  PARTNER_DIRECTORY_SEARCH_ACTION,
  PARTNER_DIRECTORY_SET_AVAILABLE_FILTER_OPTIONS_ACTION,
  PARTNER_DIRECTORY_SET_REFETCH_ACTION,
  PARTNER_DIRECTORY_SORT_ACTION,
  PARTNER_DIRECTORY_TOGGLE_FILTERS_ACTION,
} from 'pages/partnerDirectory/store/types';
import Sorting from 'utils/sorting';

export const toggleFilters: () => Action = makeActionCreator(PARTNER_DIRECTORY_TOGGLE_FILTERS_ACTION);

export const addFilter: (filterType: string, filter: string) => Action = makeActionCreator(
  PARTNER_DIRECTORY_ADD_FILTER_ACTION,
  'filterType',
  'filter'
);

export const removeFilter: (filterType: string, filter: string) => Action = makeActionCreator(
  PARTNER_DIRECTORY_REMOVE_FILTER_ACTION,
  'filterType',
  'filter'
);

export const setRefetch: (refetch: () => void) => Action = makeActionCreator(
  PARTNER_DIRECTORY_SET_REFETCH_ACTION,
  'refetch'
);
export const callRefetch: () => Action = makeActionCreator(PARTNER_DIRECTORY_REFETCH_ACTION);

export const setAvailableFilterOptions: (available: {
  regions: string[];
  categories: string[];
}) => Action = makeActionCreator(PARTNER_DIRECTORY_SET_AVAILABLE_FILTER_OPTIONS_ACTION, 'available');

export const searchAction: (searchTerm: string) => Action = makeActionCreator(
  PARTNER_DIRECTORY_SEARCH_ACTION,
  'searchTerm'
);

export const sortAction: (sorting: Sorting) => Action = makeActionCreator(PARTNER_DIRECTORY_SORT_ACTION, 'sorting');
