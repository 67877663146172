import PricingDataValues, {
  perPccepr,
  perTransaction,
  rowsListPccepr,
  rowsListTransaction,
} from '../interfaces/PricingDataValues';

export const pricingConstants: PricingDataValues = {
  [perPccepr]: false,
  [perTransaction]: false,
  [rowsListPccepr]: [
    {from: '1', to: '', price: '', disabledToValue: false},
    {from: '', to: 'Unlimited', price: '', disabledToValue: true},
  ],
  [rowsListTransaction]: [
    {from: '1', to: '', price: '', disabledToValue: false},
    {from: '', to: 'Unlimited', price: '', disabledToValue: true},
  ],
};
