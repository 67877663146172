const servicesAndSecurity = {
  'serviceAndSecurity.title': 'Services & Technical Details',
  'serviceAndSecurity.services.label': 'Please select the services with which your Red App will communicate:',
  'serviceAndSecurity.sabreRed360.label':
    'Please select if your Red App will be built using new Sabre Red 360 technologies:',
  'serviceAndSecurity.servicesCheckbox.label': 'Sabre Services (Host, Sabre APIs)',
  'serviceAndSecurity.hostCommandsDescription.label':
    'Please provide which Host commands, Sabre APIs, etc. will be used and why they are needed? <star>*</star>',
  'serviceAndSecurity.bargainFinderMax.label': 'If using APIs, does it connect to Bargain Finder Max?',
  'serviceAndSecurity.externalServices.label': 'External Services',
  'serviceAndSecurity.externalServicesDescription.label':
    'Which External services (please be specific, e.g., Google Analytics, your own services etc.) would your Red App communicate to and why are they needed? <star>*</star>',
  'serviceAndSecurity.utilizedMethod.label': 'What is the method utilized to authenticate service? <star>*</star>',
  'serviceAndSecurity.newSabreRedTechnologies.label':
    'My Red App will be built using new Sabre Red 360 technologies (such as <link>Nudge, Widget, Human Tasks etc.</link>)',
  'serviceAndSecurity.newSabreRedTechnologiesDescription.label':
    'What Sabre Red 360 technologies will your Red App be built with? <star>*</star>',
  'serviceAndSecurity.security.title': 'Security',
  'serviceAndSecurity.detailInformation.label': 'Provide detail information if it applies to your Red App:',
  'serviceAndSecurity.financialInformation.label':
    'My Red App will process, transmit, or store credit card, debit card, or any other financial information',
  'serviceAndSecurity.financialInformation.additionalText':
    'All Red Apps must be compliant with <link>PCI Data Security Standards.</link>',
  'serviceAndSecurity.pciData.label': 'Please select all PCI data that Red App processes, stores or transmits',
  'serviceAndSecurity.pciCertified.label':
    'If utilizing a third-party processor, are they PCI certified by an auditor?',
  'serviceAndSecurity.pciAdditionalDetails.label':
    'If necessary, please list additional details for truncation, masking or tokenization of PCI data <star>*</star>',
  'serviceAndSecurity.panRendered.label':
    'Please describe how the PAN is rendered unreadable everywhere it is stored <star>*</star>',
  'serviceAndSecurity.panRendered.additionalText':
    'Encryption algorithms and key strengths used wherever cardholder or personal identifying data is stored, transmitted or received over open, public networks (such as SSH, VPN, IPSEC or SSL/TLS).',
  'serviceAndSecurity.retentionPeriod.label':
    'What will the retention period and purging process for any stored cardholder or personal identifying data be? <star>*</star>',
  'serviceAndSecurity.dataAdmin.label': 'Who will process the data and how? Please list the names <star>*</star>',
  'serviceAndSecurity.personalInformation.label':
    'My Red App will process, transmit, or store personal information such as passport number, date of birth, address, phone, etc.',
  'serviceAndSecurity.personalInformation.additionalText': 'Please refer to the <link>Sabre Privacy Statement</link>.',
  'serviceAndSecurity.piiData.label': 'Please select all PII data that the Red App process stores or transmits',
  'serviceAndSecurity.piiData.additionalText': 'Please refer to Security Policy ITS105 Data Protection.',
  'serviceAndSecurity.dataForwarded.label':
    'How is that data forwarded (email, Web Services call, etc.)? <star>*</star>',
  'serviceAndSecurity.euData.label':
    'Will the Red App store or process data for EU nationals? If so, will the data be stored or processed outside of the EU? Please list the names of locations that will be utilized for this app: <star>*</star>',
  'serviceAndSecurity.gdprCompliant.label':
    'Is the Red App compliant with GDPR? Please describe how compliance is managed: <star>*</star>',
  'serviceAndSecurity.piiDataRetention.label':
    'How long will this data be stored? What’s the default data retention? <star>*</star>',
  'serviceAndSecurity.piiDataRetentionAdmin.label': 'Who can manage the data retention? <star>*</star>',
  'serviceAndSecurity.localData.label':
    'Will any of the locally saved data be shared with a third-party service? <star>*</star>',
  'serviceAndSecurity.thirdPartyNetwork.label':
    'My Red App retrieves PII from GDS and forwards to a third-party network',
  'serviceAndSecurity.dataForwardedThirdParty.label':
    'How is this forwarded (email, web services, call to third-party hosted system, other)? <star>*</star>',
  'serviceAndSecurity.protocol.label':
    'What is the protocol (https/http/sftp/VPN) between the client and third-party? <star>*</star>',
  'serviceAndSecurity.dataProtection.mainLabel':
    'Describe how your Red App data will be protected at rest and in motion',
  'serviceAndSecurity.dataProtection.pictureTitle': 'Data at rest and in motion protection',
  'serviceAndSecurity.dataProtection.buttonLabel': 'See data protection picture',
  'serviceAndSecurity.dataProtection.pictureAltText': 'Data at rest and in motion protection',
  'serviceAndSecurity.dataProtection.label':
    'How will your Red App data be protected at rest and in the motion, both for PII and PCI data? <star>*</star>',
  'serviceAndSecurity.coreData.label': 'My Red App adds data not already defined, collected as core GDS information',
  'serviceAndSecurity.coreData.additionalText':
    'Core data already has name, contact methods like address, email, and phone numbers, loyalty programs, passport or cross border data, traveler preferences.',
  'serviceAndSecurity.freeTextRemarks.label': 'Does it rely on a free text remarks field in the PNR?',
  'serviceAndSecurity.freeTextRemarksDescription.label': 'Please specify: <star>*</star>',
  'serviceAndSecurity.numberOfTransaction.label':
    'Projected number of transactions to be processed (private use vs global): <star>*</star>',
  'serviceAndSecurity.alignSecureCodingPractices.label':
    'My Red App development will align with secure coding best practices (i.e. OWASP guidelines)',
  'serviceAndSecurity.patchTimeline.label':
    'What is the default timeline for patching vulnerabilities discovered in the code by automated scanning tools or manual processes, identified as a high, medium or low risk? <star>*</star>',
  'serviceAndSecurity.confirmationButtons.label': 'Confirmation button: {value}',
  'serviceAndSecurity.accounting.label': 'My Red App will involve/provide accounting or back-office data',
  'serviceAndSecurity.accountingData.label': 'Which element will be utilized (accounting or back-office data)',
  'serviceAndSecurity.description.label': 'Which specific data attributes which will be leveraged? <star>*</star>',
};

export default servicesAndSecurity;
