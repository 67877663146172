import React from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';

const SectionHeader = ({id, ariaLevel = 3}: {id: string; ariaLevel?: number}) => (
  <StyledHeader aria-level={ariaLevel} className="spark-mar-t-2">
    <FormattedMessage id={id} />
  </StyledHeader>
);

const StyledHeader = styled.h3`
  font-weight: 300 !important;
`;

export default SectionHeader;
