import React, {useContext, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Button} from '@sabre/spark-react-core';
import Modal from '@sabre/spark-react-core/modal';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {AuthenticationContext} from '@scm/authentication/provider/AuthenticationProvider';
import jwt_decode from 'jwt-decode';
import {UsersRoles} from '@scm/components/utils/usersRoles';
import {ProposalStatus} from '../../generated/proposal';
import {config} from '@scm/authentication/config/config';
import ProposalActionModal from '@scm/proposal/components/ProposalActionModal';
import {Product} from '../MyProducts';
import RequestSunsetModal from './request-sunset-modal/RequestSunsetModal';

const ActionMenu = ({
  link,
  status,
  deleteProposal,
  removeProposal,
  proposalId,
  proposalName,
  published,
  productSku,
  isProposal,
  isRedApp,
  setItems,
}: {
  link: string;
  status: string;
  deleteProposal: (id: string, name: string) => Promise<void>;
  removeProposal: (id: string, name: string) => Promise<void>;
  proposalId: string;
  proposalName: string;
  published: boolean;
  productSku: string;
  isProposal?: boolean;
  isRedApp?: boolean;
  setItems: React.Dispatch<React.SetStateAction<Array<Product>>>;
}) => {
  const tabIndex = 0;
  const isDraft = status === 'Draft';
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const {formatMessage} = useIntl();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [requestSunsetModalOpen, setRequestSunsetModalOpen] = useState(false);
  const handleDeleteModalOpen = (): void => setDeleteModalOpen(true);
  const handleRemoveModalOpen = (): void => setRemoveModalOpen(true);
  const handleRequestSunsetModalOpen = (): void => setRequestSunsetModalOpen(true);

  const {accessToken} = useContext(AuthenticationContext);
  const {roles} = accessToken ? jwt_decode<{roles: string}>(accessToken) : {roles: ''};
  const canDelete =
    isProposal &&
    proposalId &&
    (roles.includes(UsersRoles['ROLE_SABRE_ADMIN']) || roles.includes(UsersRoles['ROLE_ADP'])) &&
    [`${ProposalStatus.Rejected}`, `${ProposalStatus.Submitted}`, `${ProposalStatus.InReview}`].includes(status);

  const canRemove =
    isProposal &&
    proposalId &&
    roles.includes(UsersRoles['ROLE_RED_APP_DEVELOPER']) &&
    [`${ProposalStatus.Submitted}`, `${ProposalStatus.Rejected}`, `${ProposalStatus.InReview}`].includes(status);

  const marketplaceUrl = `${config.marketplace.url}/marketplace/samProductDetails?sku=${productSku}&checkLogin=true`;

  async function handleDelete() {
    deleteProposal(proposalId, proposalName).catch(e => console.log(e));
    handleClose();
  }

  const actionName = isDraft ? 'removeDraft' : 'remove';

  return (
    <>
      <ul className="spark-popover__list">
        {isDraft ? (
          <li className="spark-popover__list-item">
            <OpenFormButton
              onClick={handleOpen}
              onKeyDown={evt => {
                if (evt.key === 'Enter') {
                  handleOpen();
                }
              }}
              className="spark-popover__list-link"
              role="button"
              tabIndex={tabIndex}
            >
              <FormattedMessage id="products.actions.remove.draft" />
            </OpenFormButton>
          </li>
        ) : (
          <>
            <li className="spark-popover__list-item">
              <ActionItem to={link} tabIndex={tabIndex} className="spark-popover__list-link" target="_parent">
                <FormattedMessage id="products.actions.viewDetails" />
              </ActionItem>
            </li>
            {!isProposal && isRedApp && published && (
              <li className="spark-popover__list-item">
                <RequestSunsetAction
                  className="spark-popover__list-item spark-popover__list-link"
                  onClick={handleRequestSunsetModalOpen}
                >
                  <FormattedMessage id="products.actions.requestSunset" />
                </RequestSunsetAction>
                <RequestSunsetModal
                  open={requestSunsetModalOpen}
                  setOpen={setRequestSunsetModalOpen}
                  sku={productSku}
                />
              </li>
            )}
            {!isProposal && (
              <li className="spark-popover__list-item">
                <ActionItem
                  to={marketplaceUrl}
                  target="_blank"
                  tabIndex={tabIndex}
                  className="spark-popover__list-link"
                >
                  <FormattedMessage id={published ? 'productDetails.marketplaceLink' : 'products.actions.preview'} />
                </ActionItem>
              </li>
            )}
            {canDelete && (
              <li className="spark-popover__list-item">
                <DeleteItemAction
                  className="spark-popover__list-item spark-popover__list-link"
                  onClick={handleDeleteModalOpen}
                  onKeyDown={evt => {
                    if (evt.key === 'Enter') {
                      handleDeleteModalOpen();
                    }
                  }}
                  role="button"
                  tabIndex={tabIndex}
                >
                  <FormattedMessage id="products.actions.delete" />
                </DeleteItemAction>
                <ProposalActionModal
                  open={deleteModalOpen}
                  setOpen={setDeleteModalOpen}
                  proposalId={proposalId}
                  proposalName={proposalName}
                  action="deleteProposal"
                  setItems={setItems}
                />
              </li>
            )}
            {canRemove && (
              <li className="spark-popover__list-item">
                <DeleteItemAction
                  className="spark-popover__list-item spark-popover__list-link"
                  onClick={handleRemoveModalOpen}
                  onKeyDown={evt => {
                    if (evt.key === 'Enter') {
                      handleRemoveModalOpen();
                    }
                  }}
                  role="button"
                  tabIndex={tabIndex}
                >
                  <FormattedMessage id="products.actions.remove.draft" />
                </DeleteItemAction>
                <ProposalActionModal
                  open={removeModalOpen}
                  setOpen={setRemoveModalOpen}
                  proposalId={proposalId}
                  proposalName={proposalName}
                  action={actionName}
                  setItems={setItems}
                  removeProposal={(id, name) => removeProposal(id, name)}
                />
              </li>
            )}
          </>
        )}
      </ul>
      <Modal onClose={handleClose} open={open} title={formatMessage({id: 'products.remove.draft.title'})}>
        <ModalText>
          <FormattedMessage id="products.remove.draft.content" />
          <ButtonContainer className="spark-flex">
            <Button secondary onClick={handleClose} size={ButtonSize.SMALL} className="spark-mar-r-1">
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button type="submit" onClick={handleDelete} size={ButtonSize.SMALL}>
              <FormattedMessage id="common.button.remove" />
            </Button>
          </ButtonContainer>
        </ModalText>
      </Modal>
    </>
  );
};

const OpenFormButton = styled.span`
  cursor: pointer;

  &:focus {
    text-decoration: underline;
  }
`;

const ActionItem = styled(Link)`
  text-decoration: none !important;

  &:focus-visible {
    text-decoration: underline !important;
  }
`;

const DeleteItemAction = styled.li`
  cursor: pointer;
`;

const RequestSunsetAction = styled.li`
  cursor: pointer;
`;

const ModalText = styled.p`
  white-space: normal;
`;

const ButtonContainer = styled.div`
  justify-content: flex-end;
`;

export default ActionMenu;
