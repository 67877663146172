import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import parse from 'html-react-parser';

const SummaryContent = ({isSuccess}: {isSuccess: boolean}) => {
  const {formatMessage} = useIntl();

  return (
    <>
      {isSuccess ? (
        <>
          <Paragraph>{formatMessage({id: 'summary.success.content.paragraph1'})}</Paragraph>
          <Paragraph>{formatMessage({id: 'summary.success.content.paragraph2'})}</Paragraph>
          <Paragraph>{formatMessage({id: 'summary.success.content.paragraph3'})}</Paragraph>
          <BoldParagraph>{formatMessage({id: 'summary.success.content.resources'})}</BoldParagraph>
          <br />
          <ListItem>{parse(formatMessage({id: 'summary.success.content.resources.item1'}))}</ListItem>
          <br />
          <ListItem>{parse(formatMessage({id: 'summary.success.content.resources.item2'}))}</ListItem>
          <br />
          <ListItem>{parse(formatMessage({id: 'summary.success.content.resources.item3'}))}</ListItem>
        </>
      ) : (
        <Paragraph>{parse(formatMessage({id: 'summary.error.content'}))}</Paragraph>
      )}
    </>
  );
};

const Paragraph = styled.span`
  display: inline-block;
  margin-top: 2rem;
`;

const BoldParagraph = styled.span`
  display: inline-block;
  margin-top: 2rem;
  font-weight: bold !important;
`;

const ListItem = styled.span`
  display: inline-block;
`;

export default SummaryContent;
