import {isDevEnv} from './env';

export interface Config {
  salesforceClient: {
    url: string;
  };
  googleAnalyticsPn?: {
    id: string;
  },
  googleReCaptcha?: {
    siteKey: string;
  },
  envName: string
}

export const devConfig = {
  googleAnalyticsPn: {
    id: "",
  },
  googleReCaptcha: {
    siteKey: "6LfT58IbAAAAAEzLih8nwAEUYnBfxiYCZ9RAx616",
  },
  salesforceClient: {
    url: "https://sabre--cert.sandbox.my.site.com/marketplace/services/oauth2/authorize?client_id=" +
      "3MVG9MX2WYUwuVwN413zXvBy1RcXJFMALxTLGslRy_qJOdPhr6dKCQ8lHyKASkMEHvAhmwFHU5p2qInmdZgQv",
    logoutUrl: 'https://sabre--cert.sandbox.my.site.com/marketplace/secur/logout.jsp',
  },
  envName: 'local'
}
export const config: Config = isDevEnv ? devConfig : __CONFIG__;
