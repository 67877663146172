import styled from 'styled-components';
import React from 'react';
import {Breadcrumbs} from './Breadcrumbs';

export const BreadcrumbRowWrapper = ({children}: {children: Array<JSX.Element | null> | JSX.Element | null}) => (
  <BreadcrumbRowContainer>
    <Breadcrumbs>{children}</Breadcrumbs>
  </BreadcrumbRowContainer>
);

const BreadcrumbRowContainer = styled.div`
  position: relative;
  z-index: 10;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
`;
