const homePage = {
  'homePage.welcome.title': 'What is Sabre Developer Partner Network',
  'homePage.welcome.text':
    'Sabre Developer Partner Network is an open platform which enables the intersection of supply and demand between Sabre Developer Partners and Sabre connected agencies. Through it, agencies can easily find Developer Partners which support their specific business needs and requirements. For Sabre Developer Partners, the Developer Partner Network provides an opportunity to promote themselves and offer personalized services, increasing revenue and business opportunities.',
  'homePage.welcome.mobile.text': 'Welcome to Sabre <bold>Developer Partner Network</bold>',
  'homePage.about.agencies.title': 'Agencies - Looking for a Developer Partner to extend your agency solutions?',
  'homePage.about.agencies.text':
    'Discover Sabre Developer Partners and their solutions to complement your business. Simply filter by region and categories to generate custom and authorized third-party resources. Or start reviewing our featured partners.',
  'homePage.about.agencies.button': 'Developer Partner Directory',
  'homePage.about.partners.title': 'Developer Partners - Want to build applications for travel agencies?',
  'homePage.about.partners.text': 'Become a <link>Sabre Developer Partner</link>. ',
  'homePage.about.partners.button': 'Join Us',
  'homePage.about.partners.text.second':
    'Receive dedicated support, access to our Sabre Dev Studio API portfolio and the opportunity to promote your products to our agency customers, all as a partner in our <link>Sabre Developer Partner program</link>.',
  'homePage.about.tiers.title': 'Developer Partners – Sabre Developer Partner Tiers',
  'homePage.about.tiers.text':
    'As part of Sabre’s ongoing commitment to providing the very best support to our partners, we are pleased to announce the launch of Partner Tier system as part of the Sabre Developer Partner program.',
  'homePage.about.tiers.button': 'Learn about Partner Tiers',
  'homePage.about.benefits.title': 'Developer Partners – Value Propositions',
  'homePage.about.benefits.text':
    'Keen to learn more about Sabre Developer Partner Program? Click the below button and see what are the other benefits for Developer Partners besides accessing our global marketplace and robust Sabre APIs portfolio.',
  'homePage.about.benefits.button': 'Learn about the Benefits',
  'homePage.featured.header.title': 'Featured Developer Partners',
  'homePage.featured.header.viewAll': 'View All Developer Partners',
};

export default homePage;
