import React from 'react';
import {
  Configuration as OnboardingsConfiguration,
  OnboardingRequestResponse,
  OnboardingRequestResponseStatusEnum,
  OnboardingsApi,
  RejectOnboardingRequest,
} from '../../../generated/onboardings';
import {SubmitHandler, useForm} from 'react-hook-form';
import {openToast} from '@scm/components/messaging/openToast';
import {ButtonSize, ToastType} from '@sabre/spark-react-core/types';
import {FormattedMessage, useIntl} from 'react-intl';
import {proposalsApiBaseLink} from '../../../assets/baseUrl';
import {getAccessToken} from '@scm/authentication/utils/authentication';
import {Button, TextInput} from '@sabre/spark-react-core';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {middleware} from '@scm/admin-centre/src/middleware/middlewareConfig';

const REASON_MAX_LENGTH = 1000;

const validationSchema = yup.object({
  scCode: yup.string().max(REASON_MAX_LENGTH),
});

const rejectOnboarding = async (id: string, rejectOnboardingRequest: RejectOnboardingRequest) =>
  new OnboardingsApi(
    new OnboardingsConfiguration({
      basePath: proposalsApiBaseLink,
      accessToken: getAccessToken() ?? '',
      middleware: middleware,
    })
  ).rejectOnboarding({id: id, rejectOnboardingRequest});

const RejectOnboardingForm = ({
  handleClose,
  id,
  setDetails,
}: {
  handleClose: () => void;
  id?: string;
  setDetails: React.Dispatch<React.SetStateAction<OnboardingRequestResponse | undefined>>;
}) => {
  const {formatMessage} = useIntl();

  const {
    handleSubmit,
    watch,
    setValue,
    formState: {errors},
  } = useForm<RejectOnboardingRequest>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });
  const reasonName = 'reason';

  const onSubmit: SubmitHandler<RejectOnboardingRequest> = async (request: RejectOnboardingRequest) => {
    try {
      if (id) {
        await rejectOnboarding(id, request);
        setDetails(prevState => ({...prevState, status: OnboardingRequestResponseStatusEnum.Rejected}));
        openToast(
          formatMessage({id: 'proposals.sendScCode.success.title'}),
          formatMessage({id: 'proposals.rejectOnboarding.success.description'}),
          ToastType.POSITIVE,
          'spark-icon-check'
        );
      }
    } catch (_) {
      openToast(
        formatMessage({id: 'proposals.rejectOnboarding.error.title'}),
        formatMessage({id: 'proposals.sendScCode.error.description'}),
        ToastType.WARNING,
        'spark-icon-alert-triangle'
      );
    } finally {
      handleClose();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>
        <FormattedMessage id="proposalDetails.rejectOnboarding.message" />
      </p>
      <TextInput
        name={reasonName}
        value={watch(reasonName)}
        onChange={(_, value) =>
          setValue(reasonName, value, {shouldDirty: true, shouldValidate: true, shouldTouch: true})
        }
        label={formatMessage({id: 'proposalDetails.reasonRejected.placeholder'})}
        characterCount
        maxLength={REASON_MAX_LENGTH}
        status={errors.reason ? 'error' : undefined}
        statusMessage={formatMessage({id: 'proposalDetails.reasonRejected.error.message'})}
      />
      <nav className="spark-btn-group">
        <Button className="spark-btn-group-secondary" size={ButtonSize.SMALL} secondary onClick={handleClose}>
          <FormattedMessage id="common.cancelButton" />
        </Button>
        <Button type="submit" className="spark-btn-group-primary" size={ButtonSize.SMALL} tabIndex={0}>
          <FormattedMessage id="proposalDetails.reject.button" />
        </Button>
      </nav>
    </form>
  );
};

export default RejectOnboardingForm;
